import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
//
import { useDispatch, useSelector } from "react-redux"
import { selectDashSlice } from "../dashboard/DashboardSlice"
import {
  getProposals,
  listNfse,
  resetAll,
  removeNfse,
  selectNfse,
  exportingNfse,
  getExportingNfse,
  resetNFSEeToExportStatus,
} from "./nfseSlice"
import StateStatus from "../../utils/stateStatus"
//
import { Col, Divider, Dropdown, Form, Menu, Row, Select } from "antd"
import { BlueButton, OutlinedButton } from "../../common/components/Button"
import Icons from "../../common/components/Icons"
import Loading from "../../common/components/Loading"
import RoudedBox from "../../common/components/RoudedBox"
import BaseModal from "../../common/components/Modal"
import ModalProgressDownload from "../../common/components/ModalProgressDownload"
//
import { formatDate, numberToReal } from "../../utils/formaters"
import emptyImg from "../../common/assets/images/contratos.png"
import "./styles.scss"
import { Tooltip } from "../../common/components/Tooltip"
import { translateNFSErrorReason } from "../../utils/nfse"
import Search from "antd/lib/transfer/search"
import { FlattedDropdownInput } from "../../common/components/FlattedInput"
import { useObserverElement } from "../../utils/observer"
import { DatePicker } from "../../common/components/DatePicker"
import ErrorMessage from "../../common/components/ErrorMessage"

const { useForm } = Form

const Nfse = () => {
  const dispatch = useDispatch()
  const slice = useSelector(selectNfse)
  const dash = useSelector(selectDashSlice)
  const history = useHistory()
  //
  const status = slice.status
  //
  const role = dash.mode?.toUpperCase()
  const loading = status.listNfse === StateStatus.loading
  //
  const [form] = useForm()

  //

  const [startDateExport, updateStartDateExport] = useState()
  const [endDateExport, updateEndDateExport] = useState()
  const [showStartExportDatePicker, updateShowStartExportDatePicker] =
    useState()
  const [showEndExportDatePicker, updateShowEndExportDatePicker] = useState()
  const [showModalExportingNfse, setShowModalExportingNfse] = useState(
    localStorage.getItem("dimiss-modal-export-nfse") !== "close"
  )
  //

  const [showDropdownMenu, updateShowDropdownMenu] = useState()
  const [showNewNfe, updateShowNewNfe] = useState(false)
  // const [showModalExportingNfse, updateModalExportingNfse] = useState(false)
  const [idDeleteNfe, updateIdDeleteNfe] = useState()
  const [intervalGetExporting, updateIntervalGetExporting] = useState()
  //
  const [selectedMonth, updateSelectedMonth] = useState()
  const [selectedYear, updateSelectedYear] = useState()
  const [selectedDate, updateSelectedDate] = useState()
  const [showDropdownDate, updateShowDropdownDate] = useState(false)
  // infinityScroll
  const [scroollTriggerRef, isScroollTriggerVisible] = useObserverElement({
    root: null,
    rootMargin: "0px",
    threshold: 1.0,
  })

  const columns = [
    {
      title: "Data de emissão",
      span: 4,
      getContent: (item) =>
        item.emitted_at ? formatDate(new Date(item.emitted_at)) : "...",
    },
    {
      title: "Nome da proposta",
      span: 5,
      getContent: (item) => (item.proposal ? item.proposal.title : "..."),
    },
    {
      title: (
        <Tooltip
          title={
            role === "CONTRACTOR"
              ? "Quem recebe e faz o serviço"
              : "Quem paga e recebe o serviço"
          }
          placement="bottomLeft"
        >
          {role === "CONTRACTOR" ? "Contratado" : "Contratante"}
        </Tooltip>
      ),

      span: 4,
      getContent: (item) => {
        let companyName = ""
        if (role === "CONTRACTOR") {
          companyName = item.proposal?.contracted?.company_name ?? "..."
        } else {
          companyName = item.proposal?.contractor?.company_name ?? "..."
        }

        return companyName.slice(0, 35)
      },
      // getClassName: (item) => "not-overflow",
    },
    {
      title: "Status",
      span: 4,
      getContent: (item) => {
        if (item.status === "error") {
          return (
            <Tooltip
              title={translateNFSErrorReason(item.error_reason)}
              placement="top"
            >
              {item.status_name}
            </Tooltip>
          )
        } else {
          return <>{item.status_name}</>
        }
      },
      getClassName: (item) => `status status-${item.status.replace("_", "-")}`,
    },
    {
      title: "Valor da NF-e",
      span: 5,
      getContent: (item) =>
        item.value ? numberToReal((item.value ?? 0) / 100) : "...",
    },
    {
      title: "  ",
      span: 1,
      getContent: (item) => (
        <Dropdown
          disabled={item.status === "canceled"}
          visible={showDropdownMenu === item.id}
          trigger={["click"]}
          placement="bottomRight"
          onVisibleChange={(visible) => {
            updateShowDropdownMenu(visible ? item.id : null)
          }}
          overlay={
            <Menu className="card-proposal-menu">
              <Menu.Item
                key={`${item.id}-1-${new Date().getTime()}`}
                onClick={() => {
                  history.push(`/dashboard/nfse/${item.id}/resume`)
                }}
              >
                <span className="icon-menu">
                  <Icons icon="eye-opened" />
                </span>
                Ver Nota Fiscal
              </Menu.Item>

              {item.status === "completed" && item.public_url && (
                <Menu.Item
                  key={`${item.id}-2-${new Date().getTime()}`}
                  onClick={() => {
                    const link = document.createElement("a")
                    link.href = item.public_url
                    link.setAttribute("download", item.filename)
                    link.setAttribute("target", "__blank")
                    document.body.appendChild(link)
                    link.click()
                  }}
                >
                  <span className="icon-menu">
                    <Icons icon="download" />
                  </span>
                  Baixar Nota Fiscal
                </Menu.Item>
              )}

              {item.status === "error" && !item.is_file_upload && (
                <Menu.Item
                  key={`${item.id}-2-${new Date().getTime()}`}
                  onClick={() => {
                    history.push(
                      `/dashboard/nfse/create/${item.proposal.id}/edit-emitter`
                    )
                  }}
                >
                  <span className="icon-menu">
                    <Icons icon="edit" />
                  </span>
                  Editar emissor
                </Menu.Item>
              )}
              {role === "CONTRACTED" ? (
                <Menu.Item
                  key={`${item.id}-3-${new Date().getTime()}`}
                  onClick={() => {
                    updateShowDropdownMenu(null)
                    updateIdDeleteNfe(item.id)
                  }}
                >
                  <span className="icon-menu">
                    <Icons icon="circle-close" />
                  </span>
                  Cancelar Nota Fiscal
                </Menu.Item>
              ) : null}
            </Menu>
          }
        >
          <Icons
            icon="more"
            className={item.status === "canceled" ? "disabled" : ""}
          />
        </Dropdown>
      ),
    },
  ]

  const oders = [
    { label: "Data", icon: "outline-arrow-top", key: "emitted_at" },
    { label: "Data", icon: "outline-arrow-down", key: "-emitted_at" },
    { label: "Maior preço", icon: null, key: "-value" },
    { label: "Menor preço", icon: null, key: "value" },
  ]

  const buildFilters = (additionalFilters) => {
    const filters = form.getFieldsValue()

    delete filters.date

    return {
      ...filters,
      role,
      month: selectedMonth ? parseInt(selectedMonth) : null,
      year: selectedYear ? parseInt(selectedYear) : null,
      ...additionalFilters,
    }
  }

  const onFinish = () => {
    dispatch(resetAll())
    const data = buildFilters({ page: 1 })
    dispatch(listNfse(data))
  }

  useEffect(() => {
    dispatch(resetAll())
    const query = new URLSearchParams(window.location.hash.split("?")[1])
    const proposalId = query.get("proposal_id")

    if (proposalId) {
      form.setFieldsValue({ filter: proposalId })
    }

    history.replace("/dashboard/nfse")

    const data = buildFilters({ page: 1 })
    dispatch(listNfse(data))
    dispatch(getProposals({ role }))
    // dispatch(getExportingNfse({ role }))
  }, [])

  useEffect(() => {
    if (slice.status.exportingNfse === StateStatus.succeeded) {
      updateStartDateExport(null)
      updateEndDateExport(null)
      localStorage.setItem("dimiss-modal-export-nfse", "open")
      setShowModalExportingNfse(true)

      if (!intervalGetExporting) {
        const interval = setInterval(() => {
          dispatch(getExportingNfse({ role }))
        }, 2 * 1000) // 2s

        updateIntervalGetExporting(interval)

        return () => clearInterval(interval)
      }
      dispatch(resetNFSEeToExportStatus())
    }
  }, [slice.status.exportingNfse])

  useEffect(() => {
    if (status.removeNfse === StateStatus.succeeded) {
      updateIdDeleteNfe(null)
    }
  }, [status.removeNfse])

  useEffect(() => {
    const text =
      selectedMonth && selectedYear
        ? `${selectedMonth}/${selectedYear}`
        : selectedMonth || selectedYear || null

    form.setFieldsValue({ date: text })
  }, [selectedMonth, selectedYear])

  useEffect(() => {
    if (isScroollTriggerVisible) {
      if (
        slice.page < slice.totalPages &&
        slice.page >= 1 &&
        slice.status.listNfse !== StateStatus.loading
      ) {
        dispatch(
          listNfse(
            buildFilters({
              page: slice.page + 1,
            })
          )
        )
      }
    }
  }, [isScroollTriggerVisible])

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Verifica se o clique foi fora do componente Dropdown
      if (showStartExportDatePicker) {
        updateShowStartExportDatePicker(false) // Fecha o DatePicker
      } else if (showEndExportDatePicker) {
        updateShowEndExportDatePicker(false)
      }
    }

    // Adiciona o listener de clique no documento
    document.addEventListener("click", handleClickOutside)

    // Remove o listener quando o componente é desmontado
    return () => {
      document.removeEventListener("click", handleClickOutside)
    }
  }, [showStartExportDatePicker, showEndExportDatePicker])

  return (
    <div
      className="root-container nfse-page"
      onClick={() => updateShowDropdownDate(false)}
    >
      {/* <LoadingCarousel /> */}
      <h1 className="normal-24-500">Notas fiscais</h1>
      <p className="normal-18">Veja, crie e acompanhe suas notas fiscais.</p>

      <Row align="middle">
        <div className="export-row">
          <span className="export-text">Exportar NF do período:</span>
          <Dropdown
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              updateShowStartExportDatePicker(!showStartExportDatePicker)
              updateShowEndExportDatePicker(false)
            }}
            menu={{
              className: "menu-date-nfse-filter",
              items: [
                {
                  label: (
                    <DatePicker
                      width="350px"
                      className="floating"
                      date={startDateExport}
                      maxDate={endDateExport}
                      onChange={(date) => {
                        dispatch(resetNFSEeToExportStatus())
                        updateStartDateExport(date)
                        const newDate = formatDate(date)
                        console.log(newDate)
                        updateShowStartExportDatePicker(false)
                      }}
                      onClick={(e) => e.stopPropagation()}
                    />
                  ),
                },
              ],
              onClick: (e) => {
                e.domEvent.preventDefault()
                e.domEvent.stopPropagation()
              },
            }}
            open={showStartExportDatePicker}
            trigger={["click"]}
            placement="bottomRight"
          >
            <span className="text-date">
              {!startDateExport ? "--/--/--" : formatDate(startDateExport)}
            </span>
          </Dropdown>

          <span className="text-date-a">à</span>

          <Dropdown
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              updateShowEndExportDatePicker(!showEndExportDatePicker)
              updateShowStartExportDatePicker(false)
            }}
            menu={{
              className: "menu-date-nfse-filter",
              items: [
                {
                  label: (
                    <DatePicker
                      className="floating"
                      date={endDateExport}
                      minDate={startDateExport}
                      onChange={(date) => {
                        dispatch(resetNFSEeToExportStatus())
                        updateEndDateExport(date)
                        updateShowEndExportDatePicker(false)
                      }}
                      onClick={(e) => e.stopPropagation()}
                    />
                  ),
                },
              ],
              onClick: (e) => {
                e.domEvent.preventDefault()
                e.domEvent.stopPropagation()
              },
            }}
            open={showEndExportDatePicker}
            trigger={["click"]}
            placement="bottomRight"
          >
            <span className="text-date">
              {!endDateExport ? "--/--/--" : formatDate(endDateExport)}
            </span>
          </Dropdown>
        </div>
        <div style={{ width: 149, paddingLeft: 24 }}>
          <OutlinedButton
            disabled={!startDateExport || !endDateExport}
            isLoading={slice.status.exportingNfse === StateStatus.loading}
            className="export-button"
            onClick={() => {
              dispatch(
                exportingNfse({
                  role,
                  emitted_starts_at: formatDate(startDateExport),
                  emitted_ends_at: formatDate(endDateExport),
                })
              )
            }}
          >
            Exportar
          </OutlinedButton>
        </div>

        {role === "CONTRACTED" ? (
          <BlueButton onClick={() => updateShowNewNfe(true)}>
            <Icons icon="plus" />
            Nova Nota Fiscal
          </BlueButton>
        ) : null}
      </Row>

      <ErrorMessage
        text={slice.errMessage ?? ""}
        isVisible={slice.status.exportingNfse === StateStatus.failed}
      />

      <RoudedBox className="filter-box">
        <Form
          form={form}
          onFinish={onFinish}
          layout="horizontal"
          className="filter-form"
        >
          <Row
            className="row-inputs"
            justify="space-between"
            align="middle"
            gutter={[16, 0]}
          >
            <Col span={9}>
              <Form.Item name="filter" initialValue={undefined}>
                <Search
                  placeholder="Pesquise pelo nome, número da proposta ou empresa"
                  handleClear={() => {
                    onFinish()
                  }}
                />
              </Form.Item>
            </Col>
            <Divider type="vertical" />
            <Col span={4}>
              <Dropdown
                className="tetse"
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()

                  updateShowDropdownDate(true)
                }}
                menu={{
                  className: "menu-date-nfse-filter",
                  items: [
                    {
                      label: (
                        <DatePicker
                          minDetail="decade"
                          maxDetail="year"
                          width="350px"
                          className="floating"
                          date={slice.startDate}
                          initialValue={selectedDate}
                          maxDate={null}
                          onClickMonth={(date) => {
                            if (date) {
                              updateSelectedDate(date)
                              updateSelectedYear(date.getFullYear())
                              updateSelectedMonth(date.getMonth() + 1)
                              updateShowDropdownDate(false)
                            }
                          }}
                          onChange={(date) => {}}
                        />
                      ),
                    },
                  ],
                  onClick: (e) => {
                    // not close datepicker everytime
                    e.domEvent.preventDefault()
                    e.domEvent.stopPropagation()
                  },
                }}
                open={showDropdownDate}
                trigger={["click"]}
                placement="bottomRight"
              >
                <Form.Item
                  name="date"
                  className="filter-item filter-date"
                  initialValue={""}
                >
                  <FlattedDropdownInput
                    placeholder="Selecionar data"
                    style={{ width: "100%" }}
                    readOnly
                    showClear={selectedMonth || selectedYear}
                    onClear={() => {
                      updateSelectedYear(null)
                      updateSelectedMonth(null)
                      form.setFieldsValue({ date: "" })
                      form.submit()
                    }}
                  />
                </Form.Item>
              </Dropdown>
            </Col>
            <Divider type="vertical" />

            <Col span={6}>
              <Form.Item
                style={{ textAlign: "left", padding: "0", margin: "0" }}
                name="sort_by"
                label="Ordenar por"
                className="item-order"
                initialValue="-emitted_at"
              >
                <Select>
                  {oders.map((item, index) => (
                    <Select.Option
                      value={item.key}
                      key={index}
                      className={"sort-nfse-item"}
                    >
                      {item.label}
                      {item.icon && <Icons icon={item.icon} />}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4} style={{ textAlign: "right", padding: "0" }}>
              <OutlinedButton
                isLoading={slice.status.listNfse === StateStatus.loading}
              >
                Filtrar
              </OutlinedButton>
            </Col>
          </Row>
        </Form>
      </RoudedBox>

      {status.listNfse === StateStatus.succeeded && slice.list.length < 1 && (
        <div className="empty-card">
          <img
            src={emptyImg}
            alt="imagem para simbolizar que a lista está vazia"
          />
          <span className="normal-14-400">
            Você não possui notas ficais emitidas
          </span>
        </div>
      )}

      {slice.list.length > 0 && (
        <div className="list-nfse">
          <Row justify="space-between" className="list-header">
            {columns.map((item, index) => (
              <Col key={index} span={item.span}>
                {item.title}
              </Col>
            ))}
          </Row>

          <Col justify="space-around" className="list-body">
            {slice.list.map((nfse, index) => (
              <Row
                key={index}
                justify="space-between"
                className={"list-body-card"}
                ref={index === slice.list.length - 1 ? scroollTriggerRef : null}
              >
                {columns.map((item, idx) => (
                  <Col
                    key={`${index}-${idx}`}
                    span={item.span}
                    className={
                      item.getClassName != null && item.getClassName(nfse)
                    }
                  >
                    {item.getContent(nfse)}
                  </Col>
                ))}
              </Row>
            ))}
          </Col>
        </div>
      )}

      {status.listNfse === StateStatus.failed && (
        <p className="text-error">{slice.errMessage}</p>
      )}

      {/* {status.listNfse === StateStatus.loading && <Loading />} */}

      <div className="loading-page-nfse">{loading ? <Loading /> : null}</div>

      <Tooltip
        placement="bottom"
        title={
          <>
            <b>
              {slice.list.length} / {slice.count} Notas fiscais
            </b>
          </>
        }
      >
        <div style={{ width: "100%", height: "30px" }}></div>
      </Tooltip>

      {/* TODO: verificar se o container de ações em multiplas notas irá existir */}
      {/* <div className={`container-invoice-selects ${nfseSelecteds.length > 0 ? "open" : "close"}`}>
      </div> */}

      <BaseModal
        isVisible={showNewNfe}
        className="modal-new-nfse"
        onCancel={() => updateShowNewNfe(false)}
      >
        <p className="title">Emissão de Nota Fiscal</p>

        <p className="subtitle">
          Selecione uma proposta especifica para emitir a nota fiscal.
        </p>

        <Form
          layout="vertical"
          onFinish={({ proposalId }) => {
            history.push(`nfse/create/${proposalId}/upload`)
          }}
        >
          <Form.Item
            name="proposalId"
            label="Selecionar proposta *"
            rules={[
              {
                required: true,
                message: (
                  <div className="normal-12">
                    Selecione uma proposta para continuar
                  </div>
                ),
              },
            ]}
          >
            <Select placeholder="Selecionar proposta *">
              {slice.proposals.map((proposal, index) => (
                <Select.Option value={proposal.id} key={index}>
                  {proposal.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <br />
          <br />
          <BlueButton>Continuar</BlueButton>
        </Form>
      </BaseModal>

      <BaseModal
        isVisible={idDeleteNfe != null}
        isLoading={status.removeNfse === StateStatus.loading}
        title="Cancelar Nota Fiscal"
        subtitle="Deseja realmente cancelar sua Nota Fiscal? O prazo para cancelamento e ate 24 horas apos a emissao da nota fiscal."
        className="modal-cancel-nfse"
        okButtonText="Cancelar NF-e"
        onOk={() => dispatch(removeNfse(idDeleteNfe))}
        onCancel={() => updateIdDeleteNfe(null)}
      ></BaseModal>

      {showModalExportingNfse && slice.getExportingNfse?.length > 0 ? (
        <div className="container-progress-modal">
          <ModalProgressDownload
            files={slice.getExportingNfse}
            onClose={() => {
              localStorage.setItem("dimiss-modal-export-nfse", "close")
              setShowModalExportingNfse(false)

              let allFilesIsReady = true
              slice.getExportingNfse.forEach((item) => {
                if (item.status === "ready") {
                  localStorage.setItem(item.id, "showed")
                  localStorage.setItem(item.id, "downloaded")
                } else {
                  allFilesIsReady = false
                }
              })

              if (intervalGetExporting && allFilesIsReady) {
                clearInterval(intervalGetExporting)
                updateIntervalGetExporting(null)
              }
            }}
            filesLenght={slice.getExportingNfse.length}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  )
}

export default Nfse
