import React from "react"
import "./styles.scss"
import PropTypes from "prop-types"

const ErrorMessage = ({ text, isVisible, ...props }) => {
  return (
    <div className="error-message" data-visible={isVisible} {...props}>
      {text}
    </div>
  )
}

ErrorMessage.propTypes = {
  text: PropTypes.string,
  isVisible: PropTypes.bool,
}

export default ErrorMessage
