import React from "react"
import PropTypes from "prop-types"
import ReactAudioPlayer from "react-h5-audio-player"
import "react-h5-audio-player/lib/styles.css"
import "./styles.scss"

const AudioPlayer = ({ url, info }) => {
  return (
    <div className="audio-player-wrapper">
      <ReactAudioPlayer
        src={url}
        customAdditionalControls={[]}
        customVolumeControls={[]}
        showJumpControls={false}
        layout="horizontal-reverse"
      />
      <div className="audio-info">{info}</div>
    </div>
  )
}

AudioPlayer.propTypes = {
  url: PropTypes.string,
  info: PropTypes.string,
}

export default AudioPlayer
