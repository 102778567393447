import React, { useEffect, useState } from "react"
import { Menu, Checkbox, Badge } from "antd"
import RadioButton from "../../../../common/components/RadioButton"
import Icons from "../../../../common/components/Icons"
// import PropTypes from "prop-types"
import "./styles.scss"
import {
  addTag,
  allTags,
  saveSelectedFilters,
  selectListProposalsState,
} from "../../listProposalsSlice"
import StateStatus from "../../../../utils/stateStatus"
import { LoadingOutlined } from "@ant-design/icons"
import { useDispatch, useSelector } from "react-redux"

const FilterProposalsMenu = (props) => {
  const dispatch = useDispatch()
  const listProposalsState = useSelector(selectListProposalsState)

  const selectedTags = listProposalsState.selectedFilters.tags
  const selectedStatus = listProposalsState.selectedFilters.status
  const selectedValue = listProposalsState.selectedFilters.value
  const selectedOwner = listProposalsState.selectedFilters.owner
  const selectedCreatedIn = listProposalsState.selectedFilters.createdIn

  const [tagsMenuItem, updateTagsMenuItem] = useState()
  const [showTagInput, updateShowTagInput] = useState(false)
  const [openFilter, updateOpenFilter] = useState()

  // const cleanFilters = () => {
  //   dispatch(
  //     saveSelectedFilters({
  //       tags: [],
  //       owner: null,
  //       status: null,
  //       value: null,
  //       createdIn: null,
  //       filter: "",
  //     })
  //   )
  // }

  const buildItemOptions = ({
    name,
    filterName,
    items = [],
    selected,
    onClick,
  }) => {
    return (
      <>
        <div
          className="option-name"
          onClick={() =>
            updateOpenFilter(openFilter === filterName ? null : filterName)
          }
        >
          <span>
            <span className="text">{name}</span>
            <span>
              <Badge
                className="badget-item-selected"
                dot={selected}
                style={{
                  backgroundColor: "#0018e8",
                  position: "relative",
                  left: "5px",
                  top: "3px",
                }}
              ></Badge>
            </span>
          </span>
          <span
            className={`arrow ${openFilter === filterName ? "open" : ""}`}
          />
        </div>

        <div className={`options ${openFilter === filterName ? "open" : ""}`}>
          {items.map((item) => {
            return (
              <RadioButton
                key={item.key}
                value={item.name}
                checked={selected === item.key}
                onClick={(event) => {
                  event.preventDefault()
                  event.stopPropagation()

                  onClick(selected === item.key ? null : item.key)
                }}
              >
                {item.name}
              </RadioButton>
            )
          })}
        </div>
      </>
    )
  }

  const buildTagsMenu = () => {
    return (
      <Menu.Item
        className={`menu-item-tags ${
          listProposalsState.tags.length > 4 ? "scroll" : ""
        }`}
        key="menu-item-tags"
      >
        {listProposalsState.tags.map((item) => {
          return (
            <div
              key={item.name}
              className="menu-item-tag"
              onClick={(event) => {
                event.preventDefault()
                event.stopPropagation()

                let tags = []
                if (selectedTags.some((tag) => tag.id === item.id)) {
                  tags = selectedTags.filter(
                    (selected) => item.id !== selected.id
                  )
                } else {
                  tags = [...selectedTags, item]
                }

                dispatch(saveSelectedFilters({ tags }))
              }}
            >
              <Checkbox
                value={item.name}
                checked={selectedTags.some((tag) => tag.id === item.id)}
              >
                {item.name}
              </Checkbox>
            </div>
          )
        })}
      </Menu.Item>
    )
  }

  const ownerOptions = [
    { name: "Minhas", key: "BY_MYSELF" },
    { name: "Outros", key: "BY_OTHERS" },
  ]
  const statusOptions = {
    waiting: [
      { name: "Rascunho", key: "draft" },
      { name: "Aguardando aceite", key: "waiting_accept" },
    ],
    accepted: [
      { name: "Aceita, aguardando envio do contrato", key: "accepted" },
      {
        name: "Aguardando assinaturas do contrato",
        key: "waiting_sign_contract",
      },
      { name: "Aguardando nota fiscal", key: "waiting_nfs" },
      // { name: "Finalizada", key: "finalized" },
    ],
    expired: [
      { name: "Expirada", key: "expired" },
      { name: "Cancelada", key: "canceled" },
      { name: "Finalizada", key: "finalized" },
      { name: "Recusada", key: "refused" },
    ],
  }
  const createdInOptions = [
    { name: "Mais recentes", key: "-created_at" },
    { name: "Mais antigas", key: "created_at" },
  ]
  const valueOptions = [
    { name: "Maior valor", key: "-total_value" },
    { name: "Menor valor", key: "total_value" },
  ]

  useEffect(() => {
    dispatch(allTags())
  }, [])

  useEffect(() => {
    const item = buildTagsMenu()
    updateTagsMenuItem(item)
  }, [listProposalsState.status.allTags, selectedTags])

  return (
    <Menu className="config-menu">
      <Menu.Item key="my-tags" disabled={true} className="title-menu">
        Meus marcadores
      </Menu.Item>
      {tagsMenuItem}
      <Menu.Item key="add-tag" className="add-tag">
        {!showTagInput ? (
          <div
            className="junction"
            onClick={(event) => {
              if (!showTagInput) updateShowTagInput(true)

              event.preventDefault()
              event.stopPropagation()
            }}
          >
            <span className="icon-menu">
              <Icons icon="plus" />
            </span>
            <span className="text-new-tag">Novo marcador</span>
          </div>
        ) : (
          <>
            <input
              type="text"
              className="input-add-tag"
              placeholder="Informar nome da tag"
              readOnly={
                listProposalsState.status.addTag === StateStatus.loading
              }
              onKeyDown={(event) => {
                if (
                  event.key === "Enter" &&
                  event.target.value &&
                  event.target.value.length > 0 &&
                  listProposalsState.status.addTag !== StateStatus.loading
                ) {
                  dispatch(addTag({ name: event.target.value })).then(() => {
                    dispatch(allTags())
                  })

                  event.preventDefault()
                  event.stopPropagation()
                  event.target.value = ""
                }
              }}
              onClick={(event) => {
                event.preventDefault()
                event.stopPropagation()
              }}
            />
            {listProposalsState.status.addTag === StateStatus.loading && (
              <LoadingOutlined />
            )}
          </>
        )}
      </Menu.Item>

      {/*  */}
      <Menu.Divider />
      <Menu.Item key="only" disabled={true} className="title-menu">
        Mostrar somente
      </Menu.Item>
      <Menu.Item
        key="owner-status"
        disabled={true}
        className="subtitle-menu item-options"
      >
        {buildItemOptions({
          name: "Proprietário",
          filterName: "owner",
          items: ownerOptions,
          selected: selectedOwner,
          onClick: (key) => dispatch(saveSelectedFilters({ owner: key })),
        })}
        {buildItemOptions({
          name: "Etapa",
          filterName: "status",
          items: statusOptions[listProposalsState.tab],
          selected: selectedStatus,
          onClick: (key) => dispatch(saveSelectedFilters({ status: key })),
        })}
      </Menu.Item>

      {/*  */}
      <Menu.Divider />
      <Menu.Item key="tag" disabled={true} className="title-menu">
        Levar ao topo
      </Menu.Item>
      <Menu.Item
        key="create-value"
        disabled={true}
        className="subtitle-menu item-options"
      >
        {buildItemOptions({
          name: "Criado em",
          filterName: "create_in",
          items: createdInOptions,
          selected: selectedCreatedIn,
          onClick: (key) => dispatch(saveSelectedFilters({ createdIn: key })),
        })}
        {buildItemOptions({
          name: "Valor",
          filterName: "value",
          items: valueOptions,
          selected: selectedValue,
          onClick: (key) => dispatch(saveSelectedFilters({ value: key })),
        })}
      </Menu.Item>
    </Menu>
  )
}

FilterProposalsMenu.propTypes = {}

export default FilterProposalsMenu
