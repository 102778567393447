import React, { useEffect, useMemo, useState } from "react"
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
  Link,
} from "react-router-dom"

//
import { useSelector, useDispatch } from "react-redux"
import {
  getProfile,
  selectConfigState,
  selectProfileData,
} from "../configurations/configurationsSlice"
import {
  selectRegister,
  updStep,
  resetData,
  updData,
  getAddress,
  updMaxStep,
  resetStatus,
  getSubscription,
  getPlanDetails,
} from "./RegisterSlice"
import StateStatus from "../../utils/stateStatus"
//
import { Checkbox, Col, Form, Row } from "antd"
import { BlueButton, OutlinedButton } from "../../common/components/Button"
import Loading from "../../common/components/Loading"
import Stepper from "./components/Stepper"
// //
import InputCoupon from "./components/InputCoupon"
import BaseModal from "../../common/components/Modal"
import PersonalDataForm from "./steps/PersonalDataForm"
import PlanDescription from "./components/PlanDescription"
import AddressForm from "./steps/AddressForm"
import PaymentForm from "./steps/PaymentForm"
import FeedbackForm from "./steps/Feedback"
//
import logoImg from "../../common/assets/logo/propozall.svg"
import { beautifyCpfCnpj, parsePhone } from "../../utils/formaters"
import "./styles.scss"
import ModalTermsOfUse from "./components/Contract"
import { isMobileDevice, isWebviewApp } from "../../utils/mobile"
import mixpanel from "../../mixPanel"
import FinishedRegister from "./steps/FinishedRegister"
// import { useQuery } from "../../utils/query"

const Register = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  //
  const register = useSelector(selectRegister)
  const profile = useSelector(selectProfileData)
  const configState = useSelector(selectConfigState)
  //
  const { path } = useRouteMatch()
  const loading =
    register.status.saveAccount === StateStatus.loading ||
    register.status.saveAddress === StateStatus.loading ||
    register.status.sendPayment === StateStatus.loading
  //
  const [form] = Form.useForm()
  const [modalVisible, setModalVisible] = useState(false)
  const [acceptTerms, updateAcceptTerms] = useState(false)
  const [applyWelcomeStyles, setApplyWelcomeStyles] = useState(false)
  const [showContract, updShowContract] = useState({
    visible: false,
    submit: false,
  })
  //
  const steps = [
    "Dados pessoais",
    "Endereço",
    "Pagamento",
    "Cadastro finalizado",
  ]
  const stepsPlanFree = ["Dados pessoais", "Endereço", "Cadastro finalizado"]
  const buttonOptions = useMemo(
    () => ({
      1: {
        back:
          !(register.planIdentifier === "starter" && isMobileDevice()) &&
          "Escolher outro plano",
        onBack: () => {
          window.open("https://propozall.com/planos/")
        },
        next: "Próxima etapa",
        onNext: () => {
          if (!isMobileDevice()) {
            mixpanel.track("Sign up via WEB")
          }
          form.submit()
        },
        isDisabledNext: () => false,
      },
      2: {
        back: "Voltar ao Cadastro",
        onBack: () => history.push("/register/1"),
        next:
          register.planIdentifier === "starter"
            ? "Entrar no Propozall"
            : "Próxima etapa",
        onNext: () => {
          form.validateFields().then(() => {
            if (register.planIdentifier === "starter") {
              const values = form.getFieldsValue()
              dispatch(updData(values))
              if (acceptTerms) {
                form.submit()
              }
              // updShowContract({ visible: true, submit: true })
            } else {
              form.submit()
            }
          })
        },
        isDisabledNext: () => {
          const hasAddress = !!register.isAddress
          return (
            !hasAddress ||
            (register.planIdentifier === "starter" && !acceptTerms)
          )
        },
      },
      3: {
        back: "Voltar ao Endereço",
        onBack: () => history.push("/register/2"),
        next: "Finalizar compra",
        onNext: () => {
          form.validateFields().then(() => {
            const values = form.getFieldsValue()
            dispatch(updData(values))
            if (acceptTerms) {
              form.submit()
            }
            // updShowContract({ visible: true, submit: true })
          })
        },
        isDisabledNext: () => {
          return (
            !acceptTerms || register.status.verifyCoupon === StateStatus.failed
          )
        },
      },
      4: {
        back: "Voltar a tela inicial",
        onBack: () => history.push("/register/3"),
        next: "Entrar no Propozall",
        onNext: () => {
          history.push("/register/5")

          dispatch(resetData())
        },
        isDisabledNext: () => false,
      },
      5: {
        back: "Voltar a tela inicial",
        onBack: () => history.push("/register/3"),
        next: "Entrar no Propozall",
        onNext: () => {
          history.push("/register/5")

          dispatch(resetData())
        },
        isDisabledNext: () => false,
      },
    }),
    [register]
  )

  const isEmpty = (text) => !text || /^\s*$/.test(text)

  const verifyStep = (number) => {
    if (number < register.stepActive) return number

    if (register.stepActive !== 4) {
      // if (number >= 2 && !register.data.cpf_cnpj) return 1

      if (
        number >= 3 &&
        register.data.address &&
        isEmpty(register.data.address.city)
      )
        return 2
    }

    return number
  }

  const changeStep = (number) => {
    const stepNumber = verifyStep(number)

    dispatch(updStep(stepNumber))
    history.push(`/register/${stepNumber}`)
  }

  useEffect(() => {}, [])

  useEffect(() => {
    const query = new URLSearchParams(location.search)
    const identifier = query.get("plan_identifier")
    const associeatedId = query.get("associated_id")

    const key = "query_params_new_account_propozall"
    const entries = Object.fromEntries(query.entries())
    if (localStorage.getItem(key) == null && Object.keys(entries).length > 0) {
      localStorage.setItem(key, JSON.stringify(entries))
    }

    if (associeatedId) {
      localStorage.setItem("associated_id", associeatedId)
    }

    if (identifier) {
      dispatch(getPlanDetails(identifier))
    } else if (!register.planIdentifier) {
      dispatch(getPlanDetails("starter"))
    }

    const email = query.get("email")
    if (email && email.length > 0) {
      dispatch(updData({ email }))
    }
  }, [window.location.hash])

  useEffect(() => {
    window.clarity("set", "from_mobile_device", isMobileDevice())
    window.clarity("set", "from_webview_app", isWebviewApp(path))
    if (localStorage.getItem("access_token")) {
      dispatch(getProfile())
    }
  }, [])

  // useEffect(() => {
  //   document.body.style.overflow = register.showContract ? "hidden" : "auto"
  // }, [register.showContract])

  useEffect(() => {
    if (register.status.saveAccount === StateStatus.succeeded) {
      dispatch(getProfile())
    }
  }, [register.status.saveAccount])

  useEffect(() => {
    if (
      register.msg != null &&
      (register.status.saveAccount === StateStatus.failed ||
        register.status.saveAddress === StateStatus.failed ||
        register.status.sendPayment === StateStatus.failed ||
        register.status.verifyCoupon === StateStatus.failed ||
        register.status.getPlanDetails === StateStatus.failed)
    )
      setModalVisible(true)
  }, [
    register.status.saveAccount,
    register.status.saveAddress,
    register.status.sendPayment,
    register.status.verifyCoupon,
    register.status.getPlanDetails,
  ])

  useEffect(() => {
    if (configState.status.getProfile === StateStatus.succeeded) {
      const fPhone = profile.phone
        ? parsePhone(profile.phone).formatted.replace("+55 ", "")
        : null

      const payload = {
        name: profile.name,
        email: profile.email,
        confirm_email: profile.email,
        phone: fPhone,
        company_name: profile.company_name,
        cpf_cnpj: beautifyCpfCnpj(profile.cpf_cnpj),
      }
      dispatch(updData(payload))
      dispatch(updMaxStep(3))

      if (
        profile.status === "processing_payment" ||
        profile.status === "approved"
      ) {
        dispatch(getSubscription())
        history.push("/register/5")
        dispatch(resetData())
      } else if (profile.status === "waiting_address") {
        dispatch(getAddress())
        changeStep(2)
      } else if (profile.status === "waiting_payment") {
        dispatch(getAddress())
        changeStep(3)
      }
    }

    if (configState.status.getProfile === StateStatus.failed) {
      history.push("/register/1")
    }
  }, [configState.status.getProfile])

  useEffect(() => {
    if (register.stepActive && !path.includes(register.stepActive)) {
      window.location = `/#/register/${register.stepActive}`
    }
  }, [register.stepActive])

  useEffect(() => {
    const step = location.pathname.split("register/", 2)[1]

    if (
      step &&
      location.pathname.includes("register") &&
      register.maxStep <= 4
    ) {
      const number = parseFloat(step.replace(/[^0-9]+/g, ""))

      changeStep(number)
    }
  }, [location.pathname])

  useEffect(() => {
    if (
      (register.planIdentifier === "starter" && register.stepActive === 3) ||
      register.stepActive === 5
    ) {
      setApplyWelcomeStyles(true)
    } else {
      setApplyWelcomeStyles(false)
    }
  }, [register.stepActive, register.planIdentifier])

  return (
    <div className="register-container">
      <div className="register-header">
        <header className="header">
          <Link to={"/"}>
            <img
              src={logoImg}
              alt="Logo Propozall"
              width="300"
              className="logo"
            />
          </Link>

          <BlueButton
            type="button"
            style={{ width: "150px" }}
            onClick={() => {
              dispatch(resetData())
              form.resetFields()
              window.localStorage.clear()
              history.push("/")
            }}
          >
            Fazer login
          </BlueButton>
        </header>
      </div>
      {register.stepActive !== 5 ? (
        <>
          <Stepper
            steps={
              register.planIdentifier !== "starter" ? steps : stepsPlanFree
            }
            active={register.stepActive}
            maxStep={4}
            onChange={(step) => {
              if (
                register.status.saveAccount !== StateStatus.loading &&
                register.status.saveAddress !== StateStatus.loading &&
                register.status.sendPayment !== StateStatus.loading
              )
                if (step < register.maxStep) changeStep(step)
                else form.submit()
            }}
          />
        </>
      ) : null}

      <div className={`form-side ${applyWelcomeStyles ? "welcome" : ""}`}>
        {window.localStorage.getItem("access_token") &&
          profile.status === StateStatus.loading && (
            <>
              {" "}
              <br />
              <br /> <Loading />{" "}
            </>
          )}

        <Switch>
          <Route
            exact
            path={[path, `${path}/1`]}
            component={() => (
              <PersonalDataForm
                isCreated={Object.keys(profile).length > 2}
                rel={form}
              />
            )}
          />

          <Route
            path={`${path}/2`}
            component={() => <AddressForm rel={form} />}
          ></Route>

          <Route
            path={`${path}/3`}
            component={() => <PaymentForm rel={form} />}
          ></Route>

          <Route path={`${path}/4`} component={() => <FeedbackForm />}></Route>

          <Route
            path={`${path}/5`}
            component={() => <FinishedRegister />}
          ></Route>
        </Switch>

        {!applyWelcomeStyles ? (
          <>
            <PlanDescription
              isLoading={register.status.getPlanDetails === StateStatus.loading}
              plan={register.planData}
              coupon={register.data.coupon}
            />
            {register.status.getPlanDetails === StateStatus.loading ? null : (
              <div className="actions">
                {register.stepActive === 3 && (
                  <div className="coupon-terms">
                    <InputCoupon />
                  </div>
                )}

                {[2, 3].includes(register.stepActive) && (
                  <div className="coupon-terms">
                    <Checkbox
                      checked={acceptTerms}
                      onClick={() => {
                        const values = form.getFieldsValue()
                        dispatch(updData(values))
                        updateAcceptTerms(!acceptTerms)
                      }}
                    >
                      Li e concordo com os
                      <Link
                        to="#"
                        onClick={() => updShowContract({ visible: true })}
                      >
                        {" "}
                        termos de uso{" "}
                      </Link>
                      e
                      <Link
                        to="#"
                        onClick={() => updShowContract({ visible: true })}
                      >
                        {" "}
                        políticas de privacidade{" "}
                      </Link>
                      .
                    </Checkbox>
                  </div>
                )}

                {register.stepActive !== 4 &&
                  register.status.getSubscription !== StateStatus.loading && (
                    <Row justify={"space-between"} gutter={[16, 16]}>
                      <Col sm={24} md={12}>
                        {buttonOptions[register.stepActive].back ? (
                          <OutlinedButton
                            onClick={() =>
                              buttonOptions[register.stepActive].onBack()
                            }
                          >
                            {buttonOptions[register.stepActive].back}
                          </OutlinedButton>
                        ) : (
                          <div></div>
                        )}
                      </Col>
                      <Col sm={24} md={12}>
                        <BlueButton
                          disabled={buttonOptions[
                            register.stepActive
                          ].isDisabledNext()}
                          isLoading={loading}
                          onClick={() => {
                            if (!loading)
                              buttonOptions[register.stepActive].onNext()
                          }}
                        >
                          {buttonOptions[register.stepActive].next}
                        </BlueButton>
                      </Col>
                    </Row>
                  )}
              </div>
            )}
          </>
        ) : null}
      </div>
      {showContract?.visible && (
        <ModalTermsOfUse
          isVisible={showContract.visible}
          onAccept={() => {
            updShowContract({ visible: false })
            updateAcceptTerms(true)

            if (showContract.submit) {
              form.submit()
            }
          }}
          onClose={() => {
            updShowContract({ visible: false })
          }}
        />
      )}

      <BaseModal
        isVisible={modalVisible}
        title="Alerta"
        okButtonText="Fechar"
        onOk={() => {
          dispatch(resetStatus())
          setModalVisible(false)
        }}
      >
        {register.msg}
      </BaseModal>
    </div>
  )
}

export default Register
