import React from "react"

export const ProposalStatusWaitingSignatureIcon = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        width="34"
        height="34"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_2158_58234"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="34"
          height="34"
        >
          <rect width="34" height="34" fill="white" />
        </mask>
        <g mask="url(#mask0_2158_58234)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25.4068 13.6144C25.3591 13.5093 25.2997 13.4091 25.2159 13.3238L19.3722 7.37381C19.2884 7.28853 19.1901 7.22804 19.0868 7.17944C19.0576 7.16556 19.0264 7.15763 18.9953 7.14672C18.9134 7.11895 18.8297 7.1011 18.743 7.09614C18.7216 7.09416 18.7031 7.08325 18.6826 7.08325H11.8649C10.7906 7.08325 9.91699 7.97278 9.91699 9.06659V24.9333C9.91699 26.0271 10.7906 26.9166 11.8649 26.9166H23.5524C24.6267 26.9166 25.5003 26.0271 25.5003 24.9333V14.0249C25.5003 14.0041 25.4896 13.9853 25.4877 13.9634C25.4828 13.8762 25.4662 13.7909 25.439 13.7076C25.4283 13.6759 25.4195 13.6451 25.4068 13.6144ZM19.6566 13.0333V10.4688L22.1752 13.0333H19.6566ZM11.8649 9.06659H17.7087V14.0249C17.7087 14.5733 18.144 15.0166 18.6826 15.0166H23.5524L23.5544 24.9333H11.8649V9.06659Z"
            fill="#5B7CD7"
          />
        </g>
        <path
          d="M26.0312 29.75C29.2587 29.75 31.875 27.1337 31.875 23.9062C31.875 20.6788 29.2587 18.0625 26.0312 18.0625C22.8038 18.0625 20.1875 20.6788 20.1875 23.9062C20.1875 27.1337 22.8038 29.75 26.0312 29.75Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.0312 19.125C23.3906 19.125 21.25 21.2656 21.25 23.9062C21.25 26.5469 23.3906 28.6875 26.0312 28.6875C28.6719 28.6875 30.8125 26.5469 30.8125 23.9062C30.8125 21.2656 28.6719 19.125 26.0312 19.125ZM19.125 23.9062C19.125 20.092 22.217 17 26.0312 17C29.8455 17 32.9375 20.092 32.9375 23.9062C32.9375 27.7205 29.8455 30.8125 26.0312 30.8125C22.217 30.8125 19.125 27.7205 19.125 23.9062Z"
          fill="#002999"
        />
        <path
          d="M27.7844 26.2437L26.3737 24.833C26.1545 24.6138 26.0313 24.3166 26.0312 24.0067V20.3999"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.0312 19.3374C26.6181 19.3374 27.0937 19.8131 27.0937 20.3999V24.0064C27.0938 24.0346 27.105 24.0616 27.1249 24.0816L28.5357 25.4924C28.9506 25.9073 28.9506 26.58 28.5357 26.995C28.1207 27.4099 27.448 27.4099 27.0331 26.995L25.6225 25.5844C25.6225 25.5843 25.6225 25.5844 25.6225 25.5844C25.2041 25.1661 24.9689 24.5986 24.9688 24.0069V20.3999C24.9688 19.8131 25.4444 19.3374 26.0312 19.3374Z"
          fill="#002999"
        />
      </svg>
    </span>
  )
}
