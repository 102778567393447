import React from "react"
import ReactPlayer from "react-player"
import PropTypes from "prop-types"
// import "./styles.scss"

const MultiPlayer = ({ url, width, height }) => {
  return (
    <div className="player-wrapper">
      <ReactPlayer
        className="react-player"
        url={url}
        width={width}
        height={height}
        controls
      />
    </div>
  )
}

MultiPlayer.defaultProps = {
  width: 400,
  height: 200,
}

MultiPlayer.propTypes = {
  url: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
}

export default MultiPlayer
