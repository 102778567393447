import React from "react"
import "./styles.scss"
import { Checkbox } from "antd"

const CircleCheckBox = ({ ...props }) => {
  return (
    <div className="circle-check-box">
      <Checkbox {...props}></Checkbox>
    </div>
  )
}

export { CircleCheckBox }
