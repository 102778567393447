import React from "react"
import Slider from "react-slick"
//
import Onboarding1 from "../../assets/onboarding_1_.png"
import Onboarding2 from "../../assets/onboarding_2_.png"

import Onboarding3 from "../../assets/onboarding_3_.png"
//
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import "./styles.scss"

const SliderBeforeLogin = () => {
  const slider = [
    {
      img: Onboarding1,
      title: "Bem-vindo ao Propozall!",
      text: "Crie, organize e gerencie suas propostas de forma prática.",
    },
    {
      img: Onboarding2,
      title: "O seu assistente virtual na palma da sua mão.",
      text: null,
    },
    {
      img: Onboarding3,
      title: "Acompanhe as suas propostas",
      text: "Veja o volume de propostas enviadas diariamente.",
    },
  ]

  const settings = {
    speed: 500,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <Slider {...settings}>
      {slider.map((item, index) => (
        <div className={`item item-${index}`} key={index}>
          <img
            src={item.img}
            key={item.img.toString()}
            alt={item.img.toString()}
          />
          <p className="title">{item.title}</p>
          {item.text && <p className="text">{item.text}</p>}
        </div>
      ))}
    </Slider>
  )
}

export default SliderBeforeLogin
