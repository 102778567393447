import React from "react"
//
import { Col, Row } from "antd"
import { Route, Switch } from "react-router"
//
import { ChangePassword } from "./change"
import { ForgotPassword } from "./forgot"
//
import "./styles.scss"
import SliderBeforeLogin from "../../common/components/SliderBeforeLogin"

export const Password = () => {
  return (
    <Row justify="center" className="password-container">
      <Col sm={0} md={12} lg={12} className="left-side">
        <SliderBeforeLogin />
      </Col>
      <Col sm={24} md={12} lg={12} className="right-side">
        <div>
          <Switch>
            <Route exact path="/password" component={ForgotPassword} />
            <Route path="/password/change" component={ChangePassword} />
            <Route path="/password/forgot" component={ForgotPassword} />
          </Switch>
        </div>
      </Col>
    </Row>
  )
}
