import React from "react"

export const ArrowRightIcon = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        width="30"
        height="33"
        viewBox="0 0 30 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_1273_54922"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="30"
          height="33"
        >
          <rect width="30" height="32.1018" fill="white" />
        </mask>
        <g mask="url(#mask0_1273_54922)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.7875 14.7133L14.2625 9.91137L12.5 8.02539L5 16.0508L12.5 24.0763L14.2625 22.1903L9.7875 17.3884L25 17.3884V14.7133L9.7875 14.7133Z"
            fill="#676767"
          />
        </g>
      </svg>
    </span>
  )
}
