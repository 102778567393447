import api from "../../service/api"
import apiRoutes from "../../service/apiRoutes"

export const profile = () => api.get(`${apiRoutes.profile}`)
export const profileEdit = (data) => api.put(`${apiRoutes.profile}`, data)
export const avatarUpload = (data) =>
  api.post(`${apiRoutes.profile}/avatar`, data)
export const address = () => api.get(`${apiRoutes.address}`)
export const addAddress = (data) => api.post(`${apiRoutes.address}`, data)
export const editAddress = (addressId, data) =>
  api.put(`${apiRoutes.address}/${addressId}`, data)
export const editPassword = (data) => api.put(`${apiRoutes.password}`, data)
export const getNotificationSettings = () =>
  api.post(`${apiRoutes.notificationsSettings}`)
export const editNotificationSettings = (data) =>
  api.put(`${apiRoutes.notificationsSettings}`, data)
export const contacts = (page, data) =>
  api.post(`${apiRoutes.contact}/${page}`, data)
export const companies = (filter) =>
  api.get(`${apiRoutes.company}`, {
    params: { filter },
  })
export const deleteContact = (contactId) =>
  api.delete(`${apiRoutes.contact}/${contactId}`)
export const deleteCompany = (companyId) =>
  api.delete(`${apiRoutes.company}/${companyId}`)
export const favCompany = (companyId) =>
  api.put(`${apiRoutes.company}/${companyId}/favorite`)
export const bankAccounts = (filter) =>
  api.get(`${apiRoutes.bankAccount}`, {
    params: { filter },
  })
export const editBankAccount = (bankAccountId, data) =>
  api.put(`${apiRoutes.bankAccount}/${bankAccountId}`, data)
export const addBankAccount = (data) => api.post(apiRoutes.bankAccount, data)
export const deleteBankAccount = (bankAccountId) =>
  api.delete(`${apiRoutes.bankAccount}/${bankAccountId}`)
export const banks = () => api.get(apiRoutes.banks)
export const findAddress = (params) =>
  api.get(apiRoutes.findAddress, { params })

// INVOICES
export const updPayment = (data) =>
  api.put(`${apiRoutes.create_payment}/credit-card`, data)
export const getPaymentCC = (data) =>
  api.get(`${apiRoutes.create_payment}/credit-card`, data)
export const plans = () => api.get(apiRoutes.plans)
export const editPlan = (data) => api.put(apiRoutes.plan, data)
export const subscription = () => api.get(apiRoutes.subscription)
export const updSubscription = () => api.put(apiRoutes.subscription)
export const cancelPlan = () => api.post(apiRoutes.cancelPlan)
export const confirmEditEmail = (data) =>
  api.put(apiRoutes.confirmEditEmail, data)
export const editEmail = (data) => api.put(apiRoutes.editEmail, data)
