import React from "react"
import PropTypes from "prop-types"
import { Col, Row } from "antd"
import Balloon from "../../../../common/assets/create-proposal/balloon-background.svg"
import Pencil from "../../../../common/assets/create-proposal/pencil-draft.svg"
import { BlueButton, WhiteButton } from "../../../../common/components/Button"
import { useHistory } from "react-router-dom/cjs/react-router-dom"
import { resetProposalState, selectProposalState } from "../../proposalSlice"
import { useDispatch, useSelector } from "react-redux"
import { selectProfileData } from "../../../configurations/configurationsSlice"
import "./styles.scss"
import { ProposalStatusEnum } from "../../../../utils/constants"

const FirstCreatedPropozall = ({ form }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const profile = useSelector(selectProfileData)
  const proposalState = useSelector(selectProposalState)

  const planName = profile.plan_name
  const status = proposalState.data.proposal.status
  const isFirstProposal = proposalState.data.isFirstProposal
  const activeProposals = Number(
    profile.proposals_limit - profile.count_active_proposals
  )

  return (
    <div>
      <Row className="first-proposal-content-box">
        <Col style={{ paddingTop: "32px" }}>
          {status === ProposalStatusEnum.waiting_accept ? (
            <img
              height="234px"
              width="379px"
              src={Balloon}
              onError={(event) => {
                event.target.src = Balloon
              }}
            />
          ) : status === ProposalStatusEnum.draft ? (
            <img src={Pencil} />
          ) : null}
        </Col>
        <Col className="row-texts">
          {status === ProposalStatusEnum.draft ? (
            <>
              <div className="normal-18 first-text">
                Seu rascunho foi salvo!
              </div>
              <div className="normal-12 second-text">
                Para encontrar seus rascunhos salvos, pode ir em suas propostas
                na aba pendentes.
              </div>
            </>
          ) : status === ProposalStatusEnum.waiting_accept &&
            isFirstProposal ? (
            <>
              <div className="normal-18 first-text">
                Deu certo!
                <br /> Você criou sua primeira proposta
              </div>
              <div className="normal-12 second-text">
                Aproveite o máximo do seu{" "}
                <span className="plan-name-bold normal-12">
                  plano {planName}
                </span>
                , você ainda possui mais {activeProposals} propostas para criar.
              </div>
            </>
          ) : status === ProposalStatusEnum.waiting_accept ? (
            <>
              <div className="normal-18 first-text">
                Deu certo!
                <br /> Sua proposta foi criada.
              </div>
            </>
          ) : null}
        </Col>
        <Row className="content-buttons">
          <div>
            <WhiteButton onClick={() => history.replace("/dashboard")}>
              Voltar para página inicial
            </WhiteButton>
          </div>
          <div className="blue-button">
            <BlueButton
              onClick={() => {
                dispatch(resetProposalState())
                form.resetFields()
                history.replace("/dashboard/proposal/1")
              }}
            >
              Criar nova proposta
            </BlueButton>
          </div>
        </Row>
      </Row>
    </div>
  )
}

FirstCreatedPropozall.propTypes = {
  form: PropTypes.object,
}

export default FirstCreatedPropozall
