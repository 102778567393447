import axios from "axios"

const getAddressByCep = (cep) => {
  const url = `https://viacep.com.br/ws/${cep}/json`

  return axios
    .get(url)
    .then((response) => {
      return response.data
    })
    .catch((response) => response)
}

export default getAddressByCep
