import React, { useMemo, useState } from "react"
// import PropTypes from "prop-types"
//
// import Icons from "../../../../common/components/Icons"
import { selectProposalState, updateAux } from "../../../proposal/proposalSlice"
import ErrorMessage from "../../../../common/components/ErrorMessage"
import { FileUploadBox } from "../../../../common/components/FileUploadBox"
//
import "./styles.scss"
import { Row } from "antd"
import { useDispatch, useSelector } from "react-redux"

const SelectFile = () => {
  const proposalState = useSelector(selectProposalState)
  const dispatch = useDispatch()
  const [error, setError] = useState()

  const customRequest = ({ file, onProgress, onSuccess, onError }) => {
    const newFile = {
      uid: proposalState.data.aux.files.length.toString(),
      name: file.name,
      url: window.URL.createObjectURL(new Blob([file], { type: file.type })),
      status: "done",
      response: null,
      mimeType: file.type,
      file,
    }
    dispatch(updateAux({ file: newFile }))
  }

  const beforeUpload = (file) => {
    setError(null)
    const isLessThen5M = file.size / 1000000 < 5

    if (!isLessThen5M) {
      setError("O Arquivo precisa ser menor que 5Mb!")
      return false
    }
    return true
  }

  const removeFile = (uid) => {
    const list = []
    proposalState.data.aux.files.forEach((item) => {
      if (item.uid !== uid) list.push(item)
    })
    dispatch(updateAux({ files: list }))
  }

  const files = useMemo(() => {
    return proposalState.data.aux.files.map((item) => {
      return {
        uid: item.uid,
        name: item.name,
        status: item.status,
        url: item.url,
        response: item.response, // msg de erro se status == "error"
      }
    })
  }, [proposalState.data.aux.files])

  return (
    <>
      <Row justify="center">
        <ErrorMessage text={error} isVisible={!!error} />
      </Row>
      <FileUploadBox
        // onDrop={onDropFiles}
        // maxSize={5000000} // 5MB
        // onDropRejected={onDropRejected}
        // accept=".pdf, .png, .jpg, .jpeg"
        // disabled={disabled}
        beforeUpload={beforeUpload}
        fileList={files}
        customRequest={customRequest}
        onRemove={(remove) => removeFile(remove.uid)}
      />
    </>
  )
}

export default SelectFile
