import React from "react"

export const OnboardingDocExport = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="16"
        viewBox="0 0 20 16"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.07812 15.8333C1.50087 15.8333 1.0102 15.6313 0.60612 15.2272C0.20204 14.8231 0 14.3325 0 13.7552V11.0833H2.375V0H14.25V8.41699H13.0625V1.1875H3.5625V11.0833H11.2812V13.7552C11.2812 14.0076 11.3664 14.2191 11.5368 14.3898C11.7072 14.5605 11.9183 14.6458 12.1701 14.6458C12.422 14.6458 12.6337 14.5605 12.8052 14.3898C12.9767 14.2191 13.0625 14.0076 13.0625 13.7552V12.417H14.25V13.7552C14.25 14.3325 14.048 14.8231 13.6439 15.2272C13.2398 15.6313 12.7491 15.8333 12.1719 15.8333H2.07812ZM4.75 4.75V3.5625H11.875V4.75H4.75ZM4.75 7.125V5.9375H11.875V7.125H4.75ZM2.05833 14.6458H10.0938V12.2708H1.1875V13.7552C1.1875 14.0076 1.27326 14.2191 1.44479 14.3898C1.61632 14.5605 1.82083 14.6458 2.05833 14.6458ZM16.7012 6.93685C16.436 6.61865 15.9631 6.57565 15.6449 6.84083C15.3267 7.106 15.2837 7.57892 15.5488 7.89713L17.0237 9.66699H12.625C12.2108 9.66699 11.875 10.0028 11.875 10.417C11.875 10.8312 12.2108 11.167 12.625 11.167H17.0237L15.5488 12.9369C15.2837 13.2551 15.3267 13.728 15.6449 13.9932C15.9631 14.2583 16.436 14.2153 16.7012 13.8971L19.2012 10.8971C19.4329 10.619 19.4329 10.215 19.2012 9.93685L16.7012 6.93685Z"
          fill="#002999"
        />
      </svg>
    </span>
  )
}
