import React from "react"
import { ShowIcon } from "./components/ShowIcon"
import { ShareIcon } from "./components/ShareIcon"
import { UploadIcon } from "./components/UploadIcon"
import { ExitIcon } from "./components/ExitIcon"
import { DeleteIcon } from "./components/DeleteIcon"
import { EditIcon } from "./components/EditIcon"
import { MoreIcon } from "./components/MoreIcon"
import { MailIcon } from "./components/MailIcon"
import { CircleArrowUpIcon } from "./components/CircleArrowUpIcon"
import { PlusCopyIcon } from "./components/PlusCopyIcon"
import { LocationIcon } from "./components/LocationIcon"
import { TextDescriptionIcon } from "./components/TextDescriptionIcon"
import { UserPlusIcon } from "./components/UserPlusIcon"
import { VideoconferenceIcon } from "./components/VideoconferenceIcon"
import { CircleHelpIcon } from "./components/CircleHelp"
import { BellDotIcon } from "./components/BellDotIcon"
import { BellIcon } from "./components/BellIcon"
import { ImageIcon } from "./components/ImageIcon"
import { ExcelIcon } from "./components/ExcelIcon"
import { CalendarIcon } from "./components/CalendarIcon"
import { SignatureIcon } from "./components/signatureIcon"
import { ArrowRightIcon } from "./components/arrowRightIcon"
import { UserIcon } from "./components/UserIcon"
import { PencilIcon } from "./components/pencilIcon"
import { WatchIcon } from "./components/WatchIcon"
import { TagIcon } from "./components/TagIcon"
import { FileExportIcon } from "./components/FileExportIcon"
import { DownloadDocumentsIcon } from "./components/DownloadDocumentsIcon"
import { AlertIcon } from "./components/alertIcon"
import { CircleCloseIcon } from "./components/CircleCloseIcon"
import { BellConfigIcon } from "./components/bellConfigIcon"
import { CircleAlertIcon } from "./components/CircleAlertIcon"
import { SendMessageIcon } from "./components/SendMessage"
import { LampIcon } from "./components/Lamp"
import { NavigationFlagIcon } from "./components/NavigationFlag"
import { MessagesIcon } from "./components/Messages"
// menu
import { HomeIcon } from "./components/HomeIcon"
import { FileIcon } from "./components/FileIcon"
import { ContractIcon } from "./components/ContractIcon"
import { ExtractIcon } from "./components/ExtractIcon"
import { CreditCardIcon } from "./components/CreditCardIcon"
import { NfeIcon } from "./components/NfeIcon"

// proposal status
import { ProposalStatusAcceptIcon } from "./components/ProposalStatusAcceptIcon"
import { ProposalStatusDraftIcon } from "./components/ProposalStatusDraftIcon"
import { ProposalStatusFinalizedIcon } from "./components/ProposalStatusFinalizedIcon"
import { ProposalStatusWaitingAcceptIcon } from "./components/ProposalStatusWaitingAcceptIcon"
import { ProposalStatusWaitingInvoiceIcon } from "./components/ProposalStatusWaitingInvoiceIcon"
import { ProposalStatusWaitingSignatureIcon } from "./components/ProposalStatusWaitingSignatureIcon"
import { ProposalStatusCanceledIcon } from "./components/ProposalStatusCanceledIcon"
import { ProposalStatusExpiredIcon } from "./components/ProposalStatusExpiredIcon"

// contract status
import { ContractStatusDraftIcon } from "./components/ContractStatusDraft"
import { ContractStatusWaitingIcon } from "./components/ContractStatusWaiting"
import { ContractStatusUnsignedIcon } from "./components/ContractStatusUnsigned"
import { ContractStatusSignedIcon } from "./components/ContractStatusSigned"
import { ContractStatusExpiredIcon } from "./components/ContractStatusExpired"
import { ContractStatusCanceledIcon } from "./components/ContractStatusCanceled"
import { ContractStatusFinishIcon } from "./components/ContractStatusFinish"

import {
  FallOutlined,
  RiseOutlined,
  EyeFilled,
  EyeInvisibleFilled,
  DownOutlined,
  CheckCircleOutlined,
  PlayCircleOutlined,
  PlusCircleOutlined,
  HeartOutlined,
  SearchOutlined,
  PlusOutlined,
  SettingOutlined,
  CheckCircleFilled,
  MinusCircleFilled,
  CloseCircleFilled,
  CloseOutlined,
  CopyOutlined,
  DownloadOutlined,
  SendOutlined,
  PaperClipOutlined,
  MinusCircleOutlined,
  RightOutlined,
  UpOutlined,
  LeftOutlined,
  ArrowLeftOutlined,
  CheckOutlined,
  ClockCircleOutlined,
  UploadOutlined,
  LockOutlined,
} from "@ant-design/icons"
import PropTypes from "prop-types"
import { Tooltip } from "../../components/Tooltip"
import { ConfigFilters } from "./components/ConfigFilters"
import { ProposalsIcon } from "./components/ProposalsIcon"
import { ReportsIcon } from "./components/ReportsIcon"
import { InvoiceIcon } from "./components/InvoiceIcon"
import { SuitcaseIcon } from "./components/suitcaseIcon"
import { LongArrowRight } from "./components/longArrowRightIcon"
import { RoundedExitIcon } from "./components/roundedExitIcon"
import { InterpriseIcon } from "./components/interpriseIcon"
import { InfoIcon } from "./components/infoIcon"
// dashboard onboarding
import { OnboardingDocument } from "./components/onboardingDocument"
import { OnboardingDocExport } from "./components/onboardingDocExport"
import { OnboardingAssign } from "./components/onboardingAssign"
import { OnboardingDocNfse } from "./components/onboardingDocNfse"
import { CurrencyIcon } from "./components/CurrencyIcon"
import { HistoryIcon } from "./components/HistoryIcon"
import { DescriptionIcon } from "./components/DescriptionIcon"
import { ContractorIcon } from "./components/ContractorIcon"
import { LinkIcon } from "./components/LinkIcon"
import { WhatsappIcon } from "./components/WhatsappIcon"
import { PenIcon } from "./components/PenIcon"
import { ProposalFreelaIcon } from "./components/ProposalFreelaIcon"
import { ProposalInfluencerIcon } from "./components/ProposalInfluencerIcon"
import { ProposalServiceProviderIcon } from "./components/ProposalServiceProviderIcon"
import { ProposalSimpleIcon } from "./components/ProposalSimpleIcon"
import { DocPlusIcon } from "./components/docPlus"
import { HomeLampIcon } from "./components/HomeLampIcon"
import { MoneyIcon } from "./components/MoneyIcon"
import { UploadLogoType } from "./components/uploadLogotype"
import { BenefitsIcon } from "./components/BenefitsIcon"

const Icons = ({ icon, title, placement, className, ...props }) => {
  return (
    <Tooltip title={title} placement={placement}>
      <Icon
        icon={icon}
        className={`${icon}-icon ${className ?? ""}`}
        {...props}
        title={props?.alt}
      />
    </Tooltip>
  )
}

export const Icon = ({ icon, ...props }) => {
  switch (icon) {
    case "alert":
      return <AlertIcon {...props} />
    case "show":
      return <ShowIcon {...props} />
    case "share":
      return <ShareIcon {...props} />
    case "upload-cloud":
      return <UploadIcon {...props} />
    case "upload-circle":
      return <CircleArrowUpIcon {...props} />
    case "download-documents":
      return <DownloadDocumentsIcon {...props} />
    case "file-export":
      return <FileExportIcon {...props} />
    case "exit":
      return <ExitIcon {...props} />
    case "delete":
      return <DeleteIcon {...props} />
    case "edit":
      return <EditIcon {...props} />
    case "sign":
      return <EditIcon {...props} />
    case "more":
      return <MoreIcon {...props} />
    case "benefits":
      return <BenefitsIcon {...props} />
    case "mail":
      return <MailIcon {...props} />
    case "duplicate":
      return <PlusCopyIcon {...props} />
    case "copy":
      return <CopyOutlined {...props} />
    case "videoconference":
      return <VideoconferenceIcon {...props} />
    case "add-user":
      return <UserPlusIcon {...props} />
    case "text-description":
      return <TextDescriptionIcon {...props} />
    case "location":
      return <LocationIcon {...props} />
    case "circle-help":
      return <CircleHelpIcon {...props} />
    case "bell-alert":
      return <BellDotIcon {...props} />
    case "bell":
      return <BellIcon {...props} />
    case "bell-config":
      return <BellConfigIcon {...props} />
    case "config-filters":
      return <ConfigFilters {...props} />
    case "user":
      return <UserIcon {...props} />
    case "pencil":
      return <PencilIcon {...props} />
    case "pen":
      return <PenIcon {...props} />
    case "suitcase":
      return <SuitcaseIcon {...props} />
    case "roundedExitIcon":
      return <RoundedExitIcon {...props} />
    case "interpriseIcon":
      return <InterpriseIcon {...props} />
    case "messages":
      return <MessagesIcon {...props} />
    case "lamp":
      return <LampIcon {...props} />
    case "navigation-flag":
      return <NavigationFlagIcon {...props} />
    case "send-message":
      return <SendMessageIcon {...props} />
    case "history":
      return <HistoryIcon {...props} />
    case "currency":
      return <CurrencyIcon {...props} />
    case "description-service":
      return <DescriptionIcon {...props} />
    case "contractor":
      return <ContractorIcon {...props} />
    case "link":
      return <LinkIcon {...props} />
    case "whatsapp":
      return <WhatsappIcon {...props} />
    case "proposal-type-freela":
      return <ProposalFreelaIcon {...props} />
    case "proposal-type-influencer":
      return <ProposalInfluencerIcon {...props} />
    case "proposal-type-service-provider":
      return <ProposalServiceProviderIcon {...props} />
    case "proposal-type-simple":
      return <ProposalSimpleIcon {...props} />

    // proposal status
    case "proposal-draft":
      return <ProposalStatusDraftIcon {...props} />
    case "proposal-refused":
      return <ProposalStatusCanceledIcon {...props} />
    case "proposal-waiting_accept":
      return <ProposalStatusWaitingAcceptIcon {...props} />
    case "proposal-waiting_review":
      return <ProposalStatusWaitingAcceptIcon {...props} />
    case "proposal-accepted":
      return <ProposalStatusAcceptIcon {...props} />
    case "proposal-waiting_sign_contract":
      return <ProposalStatusWaitingSignatureIcon {...props} />
    case "proposal-waiting_nfs":
      return <ProposalStatusWaitingInvoiceIcon {...props} />
    case "proposal-finalized":
      return <ProposalStatusFinalizedIcon {...props} />
    case "proposal-canceled":
      return <ProposalStatusCanceledIcon {...props} />
    case "proposal-expired":
      return <ProposalStatusExpiredIcon {...props} />

    // arrow
    case "outline-arrow-top":
      return <UpOutlined {...props} />
    case "outline-arrow-down":
      return <DownOutlined {...props} />
    case "outline-arrow-left":
      return <LeftOutlined {...props} />
    case "outline-arrow-right":
      return <RightOutlined {...props} />
    case "longArrowRight":
      return <LongArrowRight {...props} />

    // contract status
    case "contract-draft":
      return <ContractStatusDraftIcon {...props} />
    case "contract-created": // TODO(domith): Pedir icone de contrato criado
      return <ContractStatusDraftIcon {...props} />
    case "contract-refused":
      return <ContractStatusCanceledIcon {...props} />
    case "contract-waiting_sign":
      return <ContractStatusWaitingIcon {...props} />
    case "contract-signing":
      return <ContractStatusUnsignedIcon {...props} />
    case "contract-signed":
      return <ContractStatusSignedIcon {...props} />
    case "contract-expired":
      return <ContractStatusExpiredIcon {...props} />
    case "contract-canceled":
      return <ContractStatusCanceledIcon {...props} />
    case "contract-finalized":
      return <ContractStatusFinishIcon {...props} />

    /** ******  antd icons  ****** **/
    case "send":
      return <SendOutlined {...props} />
    case "lock":
      return <LockOutlined {...props} />
    case "cancel":
      return <MinusCircleOutlined {...props} />
    case "clips":
      return <PaperClipOutlined {...props} />
    case "arrow-fall":
      return <FallOutlined {...props} />
    case "arrow-rise":
      return <RiseOutlined {...props} />
    case "down":
      return <DownOutlined {...props} />
    case "download":
      return <DownloadOutlined {...props} />
    case "upload":
      return <UploadOutlined {...props} />
    case "signature":
      return <SignatureIcon {...props} />
    case "eye-closed":
      return <EyeInvisibleFilled {...props} />
    case "eye-opened":
      return <EyeFilled {...props} />
    case "tag":
      return <TagIcon {...props} />
    case "heart":
      return <HeartOutlined {...props} />
    case "circle-check":
      return <CheckCircleOutlined {...props} />
    case "circle-check-filled":
      return <CheckCircleFilled {...props} />
    case "check":
      return <CheckOutlined {...props} />
    case "clock":
      return <ClockCircleOutlined {...props} />
    case "arrow-right":
      return <ArrowRightIcon {...props} />
    case "arrow-left":
      return <ArrowLeftOutlined {...props} />
    case "flat-arrow-right":
      return <RightOutlined {...props} />
    case "flat-arrow-down":
      return <DownOutlined {...props} />
    case "circle-minus-filled":
      return <MinusCircleFilled {...props} />
    case "circle-close-filled":
      return <CloseCircleFilled {...props} />
    case "circle-close":
      return <CircleCloseIcon {...props} />
    case "circle-alert":
      return <CircleAlertIcon {...props} />
    case "circle-info":
      return <InfoIcon {...props} />
    case "circle-play":
      return <PlayCircleOutlined {...props} />
    case "circle-plus":
      return <PlusCircleOutlined {...props} />
    case "circle-up":
      return <CircleArrowUpIcon {...props} />
    case "search":
      return <SearchOutlined {...props} />
    case "close":
      return <CloseOutlined {...props} />
    case "calendar":
      return <CalendarIcon {...props} />
    // menu
    case "home":
      return <HomeIcon {...props} />
    case "file":
      return <FileIcon {...props} />
    case "plus":
      return <PlusOutlined {...props} />
    case "contract":
      return <ContractIcon {...props} />
    case "proposal":
      return <ProposalsIcon {...props} />
    case "reports":
      return <ReportsIcon {...props} />
    case "file-blank":
      return <ExtractIcon {...props} />
    case "credit-card":
      return <CreditCardIcon {...props} />
    case "nfe":
      return <NfeIcon {...props} />
    case "invoice":
      return <InvoiceIcon {...props} />

    case "image":
      return <ImageIcon {...props} />
    case "watch":
      return <WatchIcon {...props} />
    case "excel":
      return <ExcelIcon {...props} />

    case "settings":
      return <SettingOutlined {...props} />
    // ----------Logotype--------------------//
    case "upload-logotype":
      return <UploadLogoType {...props} />

    // ---------Dashboard Onboard----------///

    case "onboardingDocument":
      return <OnboardingDocument {...props} />
    case "onboardingDocExport":
      return <OnboardingDocExport {...props} />
    case "onboardingAssign":
      return <OnboardingAssign {...props} />
    case "onboardingDocNfse":
      return <OnboardingDocNfse {...props} />
    case "onboardingDocPlus":
      return <DocPlusIcon {...props} />
    case "onboardingLamp":
      return <HomeLampIcon {...props} />
    case "onboardingMoneyIcon":
      return <MoneyIcon {...props} />
    default:
      return <span>{icon}</span>
  }
}

Icons.propTypes = {
  icon: PropTypes.string,
  alt: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  placement: PropTypes.string,
}

Icon.propTypes = {
  icon: PropTypes.string,
}

export default Icons
