import React from "react"
import { Route, Switch } from "react-router-dom"
import { Dashboard } from "../features/dashboard/Dashboard"
import { Login } from "../features/login/Login"
import Register from "../features/register/Register"
import { ProposalAccept } from "../features/proposalAccept/proposalAccept"
import { InvoiceUpload } from "../features/invoiceUpload/invoiceUpload"
import { ProtectedRoute } from "./ProtectedRoute"
import { Password } from "../features/password/password"
import Contract from "../features/contract/contract"
import { ConfirmAccount } from "../features/confirmAccount/confirmaAccount"
import DownloadReports from "../features/reports/Reports"
import MenuUpdatePlan from "../features/configurations/components/payment/components/menuUpdatePlan"
import { ChatProposalMessagePage } from "../features/chatProposal"
import DeleteAccountTutorialPage from "../features/deleteAccountTutorial"

export const Routes = () => {
  return (
    <Switch>
      <Route exact path="/" component={Login} />
      {/* Rota adicionada para a webview no APP */}
      <Route path="/webview/update-plan" component={MenuUpdatePlan} />
      <Route
        path="/webview/chat-proposal/:id"
        component={ChatProposalMessagePage}
      />
      {/** Rota usada para retornar access_token para o app */}
      <Route path="/webview_access" component={Login} />
      {/** Rota usada para identificar acesso via app */}
      <Route path="/webview/register" component={Register} />
      <Route path="/register" component={Register} />
      <Route path="/password" component={Password} />
      <Route path="/confirm-account" component={ConfirmAccount} />
      <Route path="/proposal/:id/invoice" component={InvoiceUpload} />
      <Route path="/contract/:id" component={Contract} />
      <Route path="/proposal/:id" component={ProposalAccept} />
      <Route path="/download-reports" component={DownloadReports} />
      <Route path="/como-excluir-conta" component={DeleteAccountTutorialPage} />
      <ProtectedRoute path="/dashboard" component={Dashboard} />
      <Route path="*" component={() => "404 NOT FOUND"} />
    </Switch>
  )
}
