import React, { useState, useEffect } from "react"
import { Route, Switch, useHistory, useParams } from "react-router-dom"
import propotypes from "prop-types"
//
import { useDispatch, useSelector } from "react-redux"
import {
  createProposal,
  selectProposalState,
  updateProposal,
  updateAux,
} from "./proposalSlice"
// import { loginProvider } from "../login/LoginSlice"
import { selectDashSlice } from "../dashboard/DashboardSlice"
//
import {
  Divider,
  Row,
  Select,
  Form,
  Col,
  Dropdown,
  Checkbox,
  message,
  Radio,
} from "antd"
import {
  BlueButton,
  IconButton,
  OutlinedButton,
  PlainButton,
  WhiteButton,
} from "../../common/components/Button"
import { CircleCheckBox } from "../../common/components/CheckBox"
import { DatePicker } from "../../common/components/DatePicker"
import FlattedInput, {
  FlattedInputCurrency,
  FlattedInputNumber,
  FlattedLabelInput,
  FlattedMaskedInput,
  FlattedPhoneInputForm,
  FlattedTextArea,
} from "../../common/components/FlattedInput"
import Icons from "../../common/components/Icons"
//
import SelectBank from "./components/SelectBank"
import SelectContact from "./components/SelectContact"
import SelectCNPJ from "./components/SelectCNPJ"
import SelectFile from "./components/SelectFile"
import RadioScheduleMeeting from "./components/RadioScheduleMeeting"
import ProposalCreateResume from "./components/ProposalCreateResume"
//
import moment from "moment"
import { formatDate } from "../../utils/formaters"
import dynamicResume from "../../common/mocks/resumeProposalJson"
//
import "./styles.scss"
import { HelpBox } from "../../common/components/Help"
import SelectPaymentMethod from "./components/SelectPaymentMethod"
import { cnpj, cpf } from "cpf-cnpj-validator"
import { fileUpload, findAccount } from "./proposalService"
import InputEditLabel from "../../common/components/InputEditLabel"
import StateStatus from "../../utils/stateStatus"
import BaseModal from "../../common/components/Modal"
import InputAccountEmail from "./components/InputAccountEmail"
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min"
// import { useGoogleLogin } from "../../service/googleAuth"
import RadioButton from "../../common/components/RadioButton"
import ShareProposalOptions from "./components/ShareProposalOptions"
import { ProposalStatusEnum } from "../../utils/constants"
// import StyledSwitch from "../../common/components/Switch"
import SelectProposalType from "./components/SelectProposalType"
import ShareProposalEmail from "./components/ShareProposalEmail"
import FirstCreatedPropozall from "./components/FirstCreatePropozall"

const JsonForm = ({ json, data, proposalType, form }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  //
  const { id } = useParams()
  const { params } = useRouteMatch()
  const dashState = useSelector(selectDashSlice)
  const proposalState = useSelector(selectProposalState)
  const [modal, updateModal] = useState({})
  //
  const [state, setState] = useState({})
  const isEditProposal = Boolean(id)
  const currentStep = parseInt(params.page) ?? 1
  // const [formData, setFormData] = useState({})
  //

  let isComponentDisable = false
  //
  const role = dashState.mode?.toUpperCase()
  // const googlesign = useGoogleLogin({
  //   clientId: process.env.REACT_APP_GOOGLE_ID,
  //   scope:
  //     // "https://www.googleapis.com/auth/calendar " +
  //     "https://www.googleapis.com/auth/userinfo.email " +
  //     "https://www.googleapis.com/auth/userinfo.profile ",
  //   onSuccess: (data) => {
  //     const meeting = { ...proposalState.data.aux.meeting }
  //     meeting.token = data.accessToken
  //     meeting.account_email = data.profileObj.email
  //     dispatch(updateAux({ meeting }))
  //     updateModal({})
  //   },
  //   onFailure: (err) => {
  //     console.log("FAILED LOGIN GOOGLE")
  //     console.error(err)
  //     updateModal({})
  //   },
  // })

  const uploadFileIfRequired = async (item) => {
    if (!item.payload) {
      try {
        const data = new FormData()
        data.append("file", item.file)
        const response = await fileUpload(data)
        return {
          ...item,
          uid: response.data.data.id,
          payload: response.data.data,
        }
      } catch (err) {
        message.error(`Não foi possível anexar o arquivo ${item.name}.`)
        return { ...item, status: "error", response: err.response.data.message }
      }
    }

    return item
  }

  const sendProposal = async ({ isDraft, fromGoogleState }) => {
    // const proposal = { ...formData, ...state }
    const proposal = fromGoogleState ? { ...fromGoogleState } : { ...state }

    if (!proposal.files) proposal.files = []

    if (proposal.items && proposal.items.length > 0) {
      proposal.items = proposal.items.map((item) => {
        return {
          quantity: item.quantity,
          type: item.type,
          unit: item.unit ?? "",
          unit_value: item.unit_value || 1,
        }
      })
    }

    proposal.role = role
    proposal.type = proposalType
    proposal.is_draft = isDraft

    dispatch(updateAux({ is_draft: isDraft }))

    // Upload dos aquivos sem payload.
    const files = await Promise.all(
      proposalState.data.aux.files.map(uploadFileIfRequired)
    )

    dispatch(updateAux({ files }))

    proposal.files = []

    files.forEach((item) => {
      if (item.payload) proposal.files.push(item.payload.id)
    })

    if (proposal.cpf_cnpj === "MEU_CPF") {
      proposal.cpf_cnpj = form.getFieldValue("user_cpf")
    }

    // proposal.meeting = proposalState.data.aux.meeting

    // proposal.schedule_meeting && delete proposal.schedule_meeting
    proposal.created_by && delete proposal.created_by
    proposal.status && delete proposal.status
    proposal.status_name && delete proposal.status_name
    proposal.owner && delete proposal.owner
    proposal.user_cpf && delete proposal.user_cpf

    if (!isEditProposal) {
      dispatch(createProposal(proposal))
    } else {
      dispatch(updateProposal({ proposalId: id, data: proposal }))
    }
  }

  const handleContactForm = (contact) => {
    return new Promise((resolve, reject) => {
      if (contact?.email) {
        findAccount({ email: contact.email })
          .then(() => resolve())
          .catch((err) => reject(err))
      }
    })
  }

  const returnElement = (elem, parentElem, index) => {
    const valueToUse = (value) => {
      if (value.indexOf("state.") !== -1) {
        const field = value.split(".")[1]
        return state[field]
      } else if (value.indexOf("form.") !== -1) {
        return form.getFieldsValue()[value.split(".")[1]]
      } else {
        return value
      }
    }

    const storageProxy = (value, data, formatter = null) => {
      const field = value.split(".")[1]
      const tempState = {}

      tempState[field] = formatter ? formatter(data) : data

      if (value.indexOf("state.") !== -1) {
        setState({ ...state, ...tempState })
      } else if (value.indexOf("form.") !== -1) {
        form.setFieldsValue(tempState)
      }
    }

    // Create a ramdom key for props element
    const createKeyForElem = () => {
      let parentKey = ""
      if (parentElem && parentElem.props && parentElem.props.key)
        parentKey = parentElem.props.key

      return `${parentKey}$-${elem.type}-${index}-${new Date().getTime()}`
    }

    if (elem.conditional) {
      const keys = Object.keys(elem.conditional)
      const value = form.getFieldValue(elem.conditional.name)

      const hasConditional =
        (keys.includes("equal") && value !== elem.conditional.equal) || // TODO: ver se logica ta invertida
        (keys.includes("diff") && value === elem.conditional.diff)

      if (hasConditional) {
        if (
          elem.props?.name &&
          (state[elem.props.name] || form.getFieldValue(elem.props.name))
        ) {
          storageProxy(`state.${elem.props.name}`, undefined)
          storageProxy(`form.${elem.props.name}`, undefined)
        }

        if (elem.conditional.then === "disabled") {
          isComponentDisable = true
        } else if (elem.conditional.then === "hide") {
          return null
        } else {
          return null
        }
      }
    }

    // Create a key for props element
    const elem_ = {
      ...elem,
      props: {
        ...(elem.props ?? {}),
        key: (elem.props ?? {}).key ?? createKeyForElem(),
      },
    }

    switch (elem.type) {
      // ---------------
      // ---- ROUTE ----
      // ---------------
      case "Route": {
        return (
          <Route
            {...elem_.props}
            component={() => (
              <>
                <div className={`step ${elem_.props?.className ?? ""}`}>
                  {elem.elements.map((item, index) =>
                    returnElement(item, elem_, index)
                  )}
                  {/* {index !== 0 &&
                index &&
                proposalState.status.saveProposal !== StateStatus.succeeded ? (
                  <button
                    type="button"
                    className="btn-back"
                    onClick={() =>
                      history.push({
                        pathname: id
                          ? `/dashboard/proposal/${index}/${id}`
                          : `/dashboard/proposal/${index}`,
                      })
                    }
                  >
                    <Icons icon="arrow-right" />
                    <span className="button-text">Voltar</span>
                  </button>
                ) : null} */}
                </div>
                <Row justify="space-between" align="middle">
                  {elem.actions?.map((item, index) =>
                    returnElement(item, elem_, index)
                  )}
                </Row>
              </>
            )}
          />
        )
      }

      // --------------
      // ---- TAGS ----
      // --------------
      case "div":
        return (
          <div {...elem_.props}>
            {elem.elements.map((item, index) =>
              returnElement(item, elem_, index)
            )}
          </div>
        )

      case "text":
        return elem.default

      case "p":
        return (
          <p style={{ margin: 0 }} {...elem_.props}>
            {elem.default}
          </p>
        )

      // ----------------
      // ----- ANTD -----
      // ----------------
      case "Col":
        return (
          <Col {...elem_.props}>
            {elem.elements.map((item, index) =>
              returnElement(item, elem_, index)
            )}
          </Col>
        )

      case "Divider":
        return <Divider {...elem_.props} />

      case "Space":
        return <br {...elem_.props} />

      case "DoubleSpace":
        return <div {...elem_.props} style={{ marginTop: 40 }} />

      case "Row":
        return (
          <Row {...elem_.props}>
            {elem.elements.map((item, index) =>
              returnElement(item, elem_, index)
            )}
          </Row>
        )

      // ----------------
      // -- COMPONENTS --
      // ----------------
      case "BlueButton":
        return (
          <Row justify="end" key={elem_.props.key}>
            <BlueButton {...elem_.props}>
              {elem.elements.map((item, index) =>
                returnElement(item, elem_, index)
              )}
            </BlueButton>
          </Row>
        )

      case "ButtonPreviousStep":
        return (
          <Col
            xs={24}
            sm={24}
            md={elem_.props?.span}
            className="previous-button"
          >
            {!isEditProposal || currentStep > 1 ? (
              <WhiteButton
                {...elem_.props}
                type="button"
                onClick={() => history.goBack()}
              >
                {elem.elements.map((item, index) =>
                  returnElement(item, elem_, index)
                )}
              </WhiteButton>
            ) : null}
          </Col>
        )

      case "ButtonNextStep":
        return (
          <Col xs={24} sm={24} md={elem_.props?.span} className="submit-button">
            <BlueButton {...elem_.props} type="submit">
              {elem.elements.map((item, index) =>
                returnElement(item, elem_, index)
              )}
            </BlueButton>
          </Col>
        )

      case "CheckBox":
        return (
          <Checkbox
            {...elem_.props}
            className="checkbox-square"
            onChange={(e) => {
              if (elem_.props.storeValue) {
                storageProxy(elem_.props.storeValue, e.target.checked)
              }
            }}
          >
            {elem.text}
          </Checkbox>
        )

      // case "StyledSwitch":
      //   return (
      //     <StyledSwitch
      //       {...elem_.props}
      //       onChange={(checked) => {
      //         if (elem_.props.storeValue) {
      //           storageProxy(elem_.props.storeValue, checked)
      //         }
      //       }}
      //     />
      //   )

      case "CircleCheckBox":
        return <CircleCheckBox {...elem_.props}>{elem.text}</CircleCheckBox>

      case "Dropdown":
        return (
          <Dropdown
            {...elem_.props}
            overlay={
              elem_.props.overlay
                ? returnElement(elem_.props.overlay, elem_, 0)
                : null
            }
            disabled={isComponentDisable}
          >
            {returnElement(elem.element, elem_, 1)}
          </Dropdown>
        )

      case "DatePicker": {
        let date = valueToUse(elem_.props.defaultValue)

        if (date && typeof date === "string") {
          const dateMomentObject = moment(date, "DD/MM/YYYY")
          date = dateMomentObject.toDate()
        } else {
          date = new Date()
        }

        let minDate = elem_.props.minDate

        if (typeof minDate === "string") {
          if (minDate.startsWith("form.")) minDate = valueToUse(minDate)
          const dateMomentObject = moment(minDate, "DD/MM/YYYY")
          minDate = dateMomentObject.toDate()
        } else if (minDate === undefined) {
          minDate = new Date()
          if (date && date <= new Date().getTime()) {
            minDate = date
          }
        } else {
          minDate = null
        }

        return (
          <DatePicker
            {...elem_.props}
            defaultValue={date}
            minDate={minDate}
            onChange={(data) => {
              if (elem_.onupdate?.length > 0) {
                elem_.onupdate.forEach((x) => {
                  if (x.type === "clean") {
                    storageProxy(x.storeValue, null, null)
                  }
                })
                setState({ ...state })
              }
              storageProxy(elem_.props.storeValue, data, formatDate)
              setState({ ...state })
            }}
          />
        )
      }

      case "FlattedInput":
        return <FlattedInput {...elem_.props} />

      case "InputAccountEmail":
        return <InputAccountEmail form={form} {...elem_.props} />

      case "InputEditLabel":
        return <InputEditLabel {...elem_.props} />

      case "FlattedInputCurrency":
        return <FlattedInputCurrency {...elem_.props} />

      case "FlattedInputNumber":
        return <FlattedInputNumber {...elem_.props} />

      case "FlattedLabelInput":
        return (
          <FlattedLabelInput disabled={isComponentDisable} {...elem_.props} />
        )

      case "FlattedMaskedInput":
        return <FlattedMaskedInput {...elem_.props} />

      case "FlattedInputCNPJ":
        return (
          <Form.Item
            {...elem_.props}
            rules={[
              ...(elem_.props?.rules || []),
              () => ({
                validator(_, val) {
                  const value = (val || "").replace(/[^0-9]+/g, "")

                  if (
                    value.length === 0 ||
                    cnpj.isValid(value) ||
                    value.replace(/[^0]/g, "").length === 14
                  ) {
                    return Promise.resolve()
                  } else {
                    return Promise.reject(new Error())
                  }
                },
                message: <div className="normal-12"> CNPJ inválido </div>,
              }),
            ]}
          >
            <FlattedMaskedInput
              mask="00.000.000/0000-00"
              placeholder={elem_?.props?.placeholder || "00.000.000/0000-00"}
            />
          </Form.Item>
        )

      /* case "FlattedPhoneInput": {
        return (
          <FlattedPhoneInput
            {...elem_.props}
            phone={buildPhoneValue(parentProps.name)}
            onChange={(value) => {
              if (value.isValid) {
                storageProxy(`form.${parentProps.name}`, value.phone)
              }
            }}
          />
        )
      } */

      case "FlattedPhoneInputForm": {
        return <FlattedPhoneInputForm {...elem_.props} />
      }

      case "IconButton":
        return (
          <IconButton {...elem_.props}>
            {elem.elements.map((item, index) =>
              returnElement(item, elem_, index)
            )}
          </IconButton>
        )

      case "Icons":
        return <Icons {...elem_.props} />

      case "OutlinedButton":
        return (
          <Row justify="end">
            <OutlinedButton {...elem_.props}>
              {elem.elements.map((item, index) =>
                returnElement(item, elem_, index)
              )}
            </OutlinedButton>
          </Row>
        )

      case "WhiteButton":
        return (
          <WhiteButton {...elem_.props}>
            {elem.elements.map((item, index) =>
              returnElement(item, elem_, index)
            )}
          </WhiteButton>
        )

      case "PlainButton":
        return (
          <PlainButton {...elem_.props}>
            {elem.elements.map((item, index) =>
              returnElement(item, elem_, index)
            )}
          </PlainButton>
        )

      case "ShareProposalStep":
        return <ShareProposalOptions />

      // case "SelectProposalType":
      //   return <SelectProposalType goNextPage={goNextPage} />

      case "ProposalCreateResume":
        return (
          <ProposalCreateResume
            values={state}
            elements={dynamicResume[role][proposalType]}
            onClickSend={() => {
              const proposalAlreadySent = Boolean(
                proposalState.data.proposal.id &&
                  proposalState.data.proposal.status ===
                    ProposalStatusEnum.waiting_accept
              )

              // const requireLoginGoogleForMeeting = Boolean(
              //   proposalState.data.aux.meeting &&
              //     !proposalState.data.aux.meeting.token
              // )

              if (proposalAlreadySent) {
                sendProposal({ isDraft: false })
                // } else if (requireLoginGoogleForMeeting) {
                //   updateModal({ type: "schedule-meeting" })
              } else {
                sendProposal({ isDraft: false })
              }
            }}
            onClickDraft={() => sendProposal({ isDraft: true })}
          />
        )

      case "Radio":
        return (
          <CircleCheckBox {...elem_.props}>
            {returnElement(elem.element, elem_, 0)}
          </CircleCheckBox>
        )

      case "RadioButton":
        return (
          <RadioButton {...elem_.props}>
            {returnElement(elem.element, elem_, 0)}
          </RadioButton>
        )

      case "RadioGroup":
        return (
          <Radio.Group {...elem_.props}>
            {elem.elements.map((item, index) =>
              returnElement(item, elem_, index)
            )}
          </Radio.Group>
        )

      case "RadioScheduleMeeting":
        return (
          <RadioScheduleMeeting
            dataMeeting={(data) => {
              storageProxy("state.meeting", data)
            }}
          />
        )

      case "RoudedBox":
        return (
          <div {...elem_.props}>
            {elem.elements.map((item, index) =>
              returnElement(item, elem_, index)
            )}
          </div>
        )

      case "Select":
        return (
          <Select {...elem_.props}>
            {elem.options.map((item) => (
              <Select.Option
                key={`${elem_.props.key}-${item.value}`}
                value={item.value}
              >
                {item.label}
              </Select.Option>
            ))}
          </Select>
        )

      case "SelectContact":
        return (
          <SelectContact
            form={form}
            role={role}
            onSelected={(contact) => {
              storageProxy("state.contact", contact)
              storageProxy("form.contact", contact)
            }}
            {...elem_.props}
          />
        )

      case "SelectPaymentMethod":
        return <SelectPaymentMethod form={form} {...elem_.props} />

      case "SelectCpfCnpj":
        return (
          <SelectCNPJ
            form={form}
            role={role}
            value={state[elem_.props.name]}
            onSelected={(data) => {
              storageProxy(`state.${elem_.props.name}`, data.cpf_cnpj)
              storageProxy(`form.${elem_.props.name}`, data.cpf_cnpj)

              dispatch(
                updateAux({
                  cpf_cnpj: data.cpf_cnpj,
                  company_name: data.company_name,
                  owner: {
                    ...(proposalState.data.aux?.owner || {}),
                    cpf_cnpj: data.cpf_cnpj,
                    company_name: data.company_name,
                  },
                })
              )
            }}
            {...elem_.props}
          />
        )

      case "SelectBank":
        return <SelectBank form={form} {...elem_.props} />

      case "FlattedInputCpfCnpj":
        return (
          <Form.Item
            {...elem_.props}
            rules={[
              ...(elem_.props?.rules || []),
              () => ({
                validator(_, value) {
                  const val = (value || "").replace(/[^0-9]+/g, "")

                  if (val.length > 11) {
                    if (cnpj.isValid(val)) return Promise.resolve()
                    if (
                      val.length === 14 &&
                      val.replace(/[^0]/g, "").length === 14
                    )
                      return Promise.resolve()

                    return Promise.reject(new Error("CNPJ inválido"))
                  }

                  if (val.length > 0 && !cpf.isValid(val))
                    return Promise.reject(new Error("CPF inválido"))

                  return Promise.resolve()
                },
              }),
            ]}
          >
            <FlattedMaskedInput
              style={{ width: "100%" }}
              maskOptions={{
                dispatch: function (appended, dynamicMasked) {
                  const value = dynamicMasked.unmaskedValue
                  const isCPF = value.length <= 11 || cpf.isValid(value)
                  return dynamicMasked.compiledMasks[isCPF ? 0 : 1]
                },
              }}
              mask={[
                {
                  mask: "000.000.000-000?",
                  lazy: true,
                },
                {
                  mask: "00.000.000/0000-00",
                  lazy: true,
                },
              ]}
              placeholder={
                (elem_.props && elem_.props.placeholder) ?? "CPF ou CNPJ"
              }
            />
          </Form.Item>
        )

      case "SelectFile":
        return (
          <SelectFile
            files={valueToUse(elem_.props.storeValue)}
            setFiles={(list) => {
              storageProxy(elem_.props.storeValue, list)
            }}
            {...elem_.props}
          />
        )

      case "TextArea":
        return <FlattedTextArea {...elem_.props} />

      case "HelpBox":
        return <HelpBox {...elem_.props} />

      // ----------------
      // ----- FORM -----
      // ----------------
      case "Form.Item":
        return (
          <Form.Item form={form} {...elem_.props}>
            {returnElement(elem.element, elem_, index)}
          </Form.Item>
        )

      case "Form.List":
        return (
          <Form.List {...elem_.props}>
            {(fields, { add, remove }, { errors }) => {
              // if (fields.length <= 0) {
              //   console.log({a: "aquii", fields})
              //   add()
              // }

              const addButton = elem.addButton
              const removeButton = elem.removeButton

              if (addButton.elements[0].props) {
                addButton.elements[0].props.onClick = () => add()
              } else {
                addButton.elements[0].props = { onClick: () => add() }
              }

              return (
                <>
                  {fields.map(({ key, name, ...restField }) => {
                    if (removeButton.elements[0].props) {
                      removeButton.elements[0].props.onClick = () =>
                        remove(name)
                    } else {
                      removeButton.elements[0].props = {
                        onClick: () => remove(name),
                      }
                    }
                    return (
                      <Row
                        key={key}
                        justify="space-between"
                        className="campaign-format-style"
                        data-is-last={key === fields.length - 1}
                      >
                        <Col sm={21}>
                          <Row
                            justify="space-between"
                            gutter={[16, 0]}
                            align="bottom"
                          >
                            {elem.fields.map((elemCol, index) => {
                              const elemFormItem = elemCol.elements[0]
                              const elemCol_ = {
                                ...elemCol,
                                elements: [
                                  {
                                    ...elemFormItem,
                                    props: {
                                      ...elemFormItem.props,
                                      name: [name, elemFormItem.props.name],
                                    },
                                  },
                                ],
                              }

                              return returnElement(elemCol_, elem_, index)
                            })}
                          </Row>
                        </Col>

                        {key !== 0 && (
                          <Col sm={2}>
                            {returnElement(removeButton, elem_, 0)}
                          </Col>
                        )}
                      </Row>
                    )
                  })}
                  {returnElement(addButton, elem_, 0)}
                </>
              )
            }}
          </Form.List>
        )

      default:
        return <span>?</span>
    }
  }

  // useEffect(() => {
  //   if (id) dispatch(getProposalStoredData(id))
  // }, [])

  useEffect(() => {
    setState(data)
    form.setFieldsValue(data)
  }, [data])

  useEffect(() => {
    if (proposalState.status.saveProposal === StateStatus.succeeded) {
      updateModal({})
      if (proposalState.data.proposal.status === ProposalStatusEnum.draft) {
        history.replace("/dashboard/proposal/success")
      } else {
        message.success("Sua proposta foi salva!")
        history.replace("/dashboard/proposal/share")
      }
    }
  }, [proposalState.status.saveProposal])

  const goNextPage = () => {
    if (currentStep < json.steps.length) {
      history.push(
        id
          ? `${json.steps[parseInt(currentStep)].props.path[0]}/${id}`
          : `${json.steps[parseInt(currentStep)].props.path[0]}`
      )
    }
  }

  return (
    <Form
      form={form}
      layout="vertical"
      scrollToFirstError={true}
      // onValuesChange={(changedValues, values) => {
      //   if(Object.keys(changedValues).includes("campaign_format_social_media")) {
      //     updateConditionals([...conditionals]) // força renderização do componente
      //   }
      // }}
      onFinish={async (values) => {
        if (values?.contact?.email) {
          if (modal?.type === "alert-account-not-found") {
            updateModal({})
          } else {
            try {
              await handleContactForm(values.contact)
            } catch (err) {
              return updateModal({ type: "alert-account-not-found" })
            }
          }
        }

        setState({ ...state, ...values })
        // setFormData({ ...formData, ...values })

        goNextPage()
      }}
    >
      <Switch>
        {json.steps.map((item, index) => returnElement(item, null, index))}
        <Route
          path={"/dashboard/proposal/select-type"}
          component={() => (
            <SelectProposalType
              goNextPage={() => history.push("/dashboard/proposal/1")}
            />
          )}
        />
        <Route
          path={"/dashboard/proposal/share/send-email"}
          component={() => (
            <ShareProposalEmail rootPath={"/dashboard/proposal"} />
          )}
        />
        <Route
          path={"/dashboard/proposal/share"}
          component={() => (
            <ShareProposalOptions rootPath={"/dashboard/proposal"} />
          )}
        />
        <Route
          path={"/dashboard/proposal/success"}
          component={() => <FirstCreatedPropozall form={form} />}
        />
      </Switch>

      {/* <BaseModal
        type="alert"
        isVisible={modal.type === "schedule-meeting"}
        title={"Agendar reunião"}
        okButtonText="Conectar"
        cancelButtonText="Cancelar"
        onCancel={() => updateModal({})}
        onOk={() => {
          googlesign.signIn()
        }}
        isClosable={false}
      >
        <div>
          É necessário se conectar ao
          <span className="normal-14-500"> Google Calendar </span>
          para agendar uma reunião.
        </div>
      </BaseModal> */}
      <BaseModal
        type="alert"
        isVisible={modal.type === "alert-account-not-found"}
        title={"E-mail não cadastrado"}
        okButtonText="Continuar"
        cancelButtonText="Tentar novamente"
        onCancel={() => {
          updateModal({})
        }}
        onOk={() => {
          form.submit()
        }}
      >
        Este e-mail não está cadastrado no Propozall. Você poderá prosseguir,
        mas certifique-se de conferir se este é o <span> e-mail</span> correto.
        Recomendamos que o usuário esteja cadastrado na plataforma.
      </BaseModal>
      <BaseModal
        isVisible={modal.type === "general"}
        title={modal.title}
        okButtonText="Ok"
        onCancel={() => {
          if (modal.redirect) history.push(modal.redirect)
          updateModal({})
        }}
        onOk={() => {
          if (modal.redirect) history.push(modal.redirect)
          updateModal({})
        }}
      >
        {modal.message}
      </BaseModal>
    </Form>
  )
}

JsonForm.propTypes = {
  data: propotypes.object,
  json: propotypes.object,
  form: propotypes.object,
  proposalType: propotypes.string,
}

export default JsonForm

// 9946b0eb-a57a-4c97-9a8f-e1e43dd30fa3
