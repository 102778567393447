import React from "react"

export const DownloadDocumentsIcon = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke="#fff"
          className="line"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M12.402 2H5.867A1.867 1.867 0 004 3.867v14.936a1.867 1.867 0 001.867 1.867h11.202a1.867 1.867 0 001.867-1.867V8.534L12.402 2z"
        ></path>
        <path
          stroke="#fff"
          className="line"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M11 2v7h7"
        ></path>

        <rect
          width="9.7"
          height="9.7"
          x="-0.65"
          y="-0.65"
          className="rect"
          fill="#002999"
          rx="4.85"
          transform="matrix(0 -1 -1 0 21.604 21.73)"
        ></rect>

        <mask
          id="mask0_3361_65418"
          style={{ maskType: "alpha" }}
          width="12"
          height="12"
          x="11"
          y="12"
          maskUnits="userSpaceOnUse"
        >
          <path
            fill="#fff"
            d="M0 0H11V11H0z"
            transform="matrix(0 -1 -1 0 22.904 23.03)"
          ></path>
        </mask>
        <g mask="url(#mask0_3361_65418)">
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M17.863 19.44l1.645-1.64.646.646-2.75 2.75-2.75-2.75.646-.646 1.646 1.64v-5.577h.917v5.578z"
            clipRule="evenodd"
          ></path>
        </g>
        <rect
          width="9.7"
          height="9.7"
          x="-0.65"
          y="-0.65"
          stroke="#fff"
          strokeWidth="1.3"
          rx="4.85"
          transform="matrix(0 -1 -1 0 21.604 21.73)"
        ></rect>
      </svg>
    </span>
  )
}
