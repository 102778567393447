import React from "react"
import BaseModal from "../../../Modal"
import PropTypes from "prop-types"
import { Row } from "antd"

const DeleteOrCancelModal = ({
  isVisible,
  isLoading,
  isDelete,
  onOk,
  onCancel,
}) => {
  return (
    <BaseModal
      type="alert"
      isVisible={isVisible}
      title={isDelete ? "Excluir Proposta" : "Cancelar Proposta"}
      isLoading={isLoading}
      okButtonText={isDelete ? "Sim, excluir" : "Sim, cancelar"}
      // okButtonColor="red"
      onOk={onOk}
      cancelButtonText={"Não, manter"}
      onCancel={onCancel}
    >
      <Row justify="start">
        <p>
          Ao {isDelete ? "excluir" : "cancelar"} esta proposta você não poderá
          dar continuidade ao processo.
        </p>
        <p>Tem certeza que deseja cancelar a proposta?</p>
      </Row>
    </BaseModal>
  )
}

DeleteOrCancelModal.propTypes = {
  isVisible: PropTypes.bool,
  isDelete: PropTypes.bool,
  isLoading: PropTypes.bool,
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
}

export default DeleteOrCancelModal
