import React, { useEffect, useState } from "react"
import { Form, Row, Col, Divider, Select } from "antd"
import BaseModal from "../../common/components/Modal"
import FlattedInput, {
  FlattedMaskedInput,
  FlattedPhoneInputForm,
} from "../../common/components/FlattedInput"
import { useDispatch, useSelector } from "react-redux"
import { cnpj, cpf } from "cpf-cnpj-validator"
import PropTypes from "prop-types"
import "./styles.scss"
import StateStatus from "../../utils/stateStatus"
import {
  createContact,
  listContactParams,
  resetContactStatus,
  selectContactState,
  updateContact,
} from "./contactSlice"

const { useForm } = Form

const CreateContactModal = ({ data, onSaved, onCancel }) => {
  const contactState = useSelector(selectContactState)
  const [contact, updateContactData] = useState(data || {})
  const [showErrorModal, updateShowErrorModal] = useState(false)
  //
  const [usingCpf, updateUsingCpf] = useState(false)
  //
  const [form] = useForm()
  const dispatch = useDispatch()

  useEffect(() => {
    switch (contactState.status.saveContact) {
      case StateStatus.succeeded:
        updateContactData(contactState.data)
        onSaved(contact)
        dispatch(resetContactStatus())
        break
      case StateStatus.failed:
        updateShowErrorModal(true)
        dispatch(resetContactStatus())
        break
    }
  }, [contactState.status.saveContact])

  useEffect(() => {
    switch (contactState.status.listParams) {
      case StateStatus.idle:
        dispatch(listContactParams())
        break
      case StateStatus.failed:
        onCancel()
        break
    }
  }, [contactState.status.listParams])

  useEffect(() => {
    if (contact.sector) {
      form.setFieldsValue({
        sector: contact.sector.id,
      })
    }
  }, [contact])

  const saveContact = (data) => {
    const contactId = data.id
    delete data.id

    if (contactId) {
      dispatch(updateContact({ contactId, data }))
    } else {
      dispatch(createContact(data))
    }

    data.id = contact.id
    updateContactData(data)
  }

  return (
    <>
      <BaseModal
        isVisible={true}
        isLoading={contactState.status.saveContact === StateStatus.loading}
        okButtonText={"Salvar"}
        cancelButtonText={"Cancelar"}
        onOk={() => {
          form.submit()
        }}
        onCancel={() => {
          onCancel()
          form.resetFields()
        }}
        title={contact.id ? "Editar Contato" : "Adicionar Contato"}
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={(data) => {
            data.id = contact.id
            saveContact(data)
          }}
        >
          <div className="normal-14 modal-description">
            <div className="normal-12">
              Insira os dados do contato que deseja cadastrar
            </div>
          </div>
          <Row gutter={[16, 0]} justify="center">
            <Col span={24}>
              <Form.Item
                initialValue={contact.company_name || ""}
                name="company_name"
                label={usingCpf ? "Nome completo" : "Nome empresa"}
                rules={[
                  {
                    required: true,
                    message: <div className="normal-12">Campo obrigatório</div>,
                  },
                ]}
              >
                <FlattedInput
                  placeholder="Ex.: Airfluencers"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]} justify="center" align="bottom">
            <Col span={12}>
              <Form.Item
                initialValue={contact.cpf_cnpj || ""}
                name="cpf_cnpj"
                label="CPF/CNPJ"
                rules={[
                  {
                    required: false,
                    message: <div className="normal-12">Campo obrigatório</div>,
                  },
                  () => ({
                    validator(_, value) {
                      if (value && value.length > 1) {
                        const val = value.replace(/[^0-9]+/g, "")

                        updateUsingCpf(val.length <= 11)

                        if (val.length > 11) {
                          if (cnpj.isValid(val)) return Promise.resolve()
                          if (
                            val.length === 14 &&
                            value.replace(/[^0]/g, "").length === 14
                          )
                            return Promise.resolve()

                          return Promise.reject(new Error("CNPJ inválido"))
                        }

                        if (!cpf.isValid(val))
                          return Promise.reject(new Error("CPF inválido"))
                      }

                      return Promise.resolve()
                    },
                  }),
                ]}
              >
                <FlattedMaskedInput
                  // style={{ width: "100%" }}
                  maskOptions={{
                    dispatch: function (appended, dynamicMasked) {
                      const value = dynamicMasked.unmaskedValue
                      const isCPF = value.length <= 11 || cpf.isValid(value)
                      return dynamicMasked.compiledMasks[isCPF ? 0 : 1]
                    },
                  }}
                  mask={[
                    {
                      mask: "000.000.000-000?",
                      lazy: true,
                    },
                    {
                      mask: "00.000.000/0000-00",
                      lazy: true,
                    },
                  ]}
                  placeholder="CPF ou CNPJ"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Setor"
                name="sector"
                rules={[
                  {
                    required: true,
                    message: <div className="normal-12">Campo obrigatório</div>,
                  },
                ]}
              >
                <Select
                  placeholder="Selecione o setor"
                  notFoundContent={null}
                  filterOption={false}
                >
                  {contactState.sectors.map((item) => (
                    <Select.Option key={item.id}>{item.label}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <div className="modal-description">
            <div className="normal-12-500">Dados do contato</div>
            <div className="normal-12">
              {usingCpf
                ? "Adicione o número de contato"
                : "Adicione o número de contato de alguma pessoa da empresa"}
            </div>
          </div>
          <Row gutter={[16, 0]} justify="space-between">
            <Col span={24}>
              <Form.Item
                initialValue={contact.name || ""}
                name="name"
                label="Nome"
                rules={[
                  {
                    required: true,
                    message: <div className="normal-12">Campo obrigatório</div>,
                  },
                ]}
              >
                <FlattedInput placeholder="Nome" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]} justify="space-between" align="top">
            <Col span={12}>
              <Form.Item
                initialValue={contact.phone || ""}
                name="phone"
                label="Telefone"
              >
                <FlattedPhoneInputForm />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                initialValue={contact.email || ""}
                name="email"
                label="E-mail"
                rules={[
                  {
                    required: true,
                    message: <div className="normal-12">Campo obrigatório</div>,
                  },
                  {
                    type: "email",
                    message: <div className="normal-12">Email inválido</div>,
                  },
                ]}
              >
                <FlattedInput
                  placeholder="Inserir e-mail"
                  style={{ width: "100%" }}
                ></FlattedInput>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </BaseModal>
      <BaseModal
        isVisible={showErrorModal}
        title="Erro!"
        okButtonText="Ok"
        onOk={() => {
          updateShowErrorModal(false)
        }}
      >
        {contactState.errMessage}
      </BaseModal>
    </>
  )
}

CreateContactModal.propTypes = {
  data: PropTypes.object,
  onSaved: PropTypes.func,
  onCancel: PropTypes.func,
}

export { CreateContactModal }
