import React from "react"
import PropTypes from "prop-types"
import { OutlinedButton } from "../../../../../../common/components/Button"
import "./styles.scss"
import { Col, Row } from "antd"

const CardSettings = ({
  label,
  value,
  actionText,
  onClick,
  className,
  isDisabled,
}) => {
  return (
    <div
      className={`card-settings ${className ?? ""}`}
      style={{ cursor: "auto" }}
    >
      <Row justify="space-between" style={{ width: "100%" }}>
        <Col className="normal-16" span={value != null ? 5 : 8}>
          {label}
        </Col>
        <Col className="normal-16-500" span={value != null ? 13 : 10}>
          {value}
        </Col>

        <Col span={6}>
          {onClick && (
            <OutlinedButton
              disabled={isDisabled}
              onClick={onClick}
              style={{ width: "220px", height: "40px" }}
            >
              {actionText}
            </OutlinedButton>
          )}
        </Col>
      </Row>
    </div>
  )
}

CardSettings.defaultProps = {
  isDisabled: false,
}

CardSettings.propTypes = {
  label: PropTypes.string,
  value: PropTypes.object,
  actionText: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
}

export { CardSettings }
