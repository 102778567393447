import React, { useEffect, useState } from "react"
//
import { useDispatch, useSelector } from "react-redux"
import {
  getNotification,
  listNotifications,
  removeNotification,
  resetNotification,
  selectNotificationsState,
} from "./listNotificationsSlice"
import StateStatus from "../../utils/stateStatus"
//
import { Row } from "antd"
import Card from "./components/cards"
import Loading from "../../common/components/Loading"
import BaseModal from "../../common/components/Modal"
import { OutlinedButton, WhiteButton } from "../../common/components/Button"
import Icons from "../../common/components/Icons"
import { LoadMorePages } from "../../common/components/Pagination"
//
import emptyImg from "../../common/assets/images/notifications.png"
import "./styles.scss"
import { useHistory } from "react-router-dom"
import InfoDateTime from "../../common/components/InfoDateTime"

const ListNotifications = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const notificationsState = useSelector(selectNotificationsState)
  //
  const [showErrorModal, updateShowErrorModal] = useState(false)

  const [isConfigOpened, updateIsconfigOpened] = useState(false)

  const loading =
    notificationsState.status.listNotifications === StateStatus.loading
  const firstLoading = loading && notificationsState.page <= 1

  useEffect(() => {
    dispatch(listNotifications({ page: 1, filter: "" }))
  }, [])

  useEffect(() => {
    if (notificationsState.status.listNotifications === StateStatus.failed) {
      updateShowErrorModal(true)
    }
  }, [notificationsState.status.listNotifications])

  return (
    <>
      <div className="config-page">
        <Row justify="center">
          <div className="config-tab-content">
            <Row
              className="configButton"
              style={{ width: "93%" }}
              justify="space-between"
            >
              <WhiteButton
                id={
                  !isConfigOpened
                    ? "button-config-btn"
                    : "button-config-blue-btn"
                }
                onClick={() => {
                  history.replace("/dashboard/notifications/config")
                  updateIsconfigOpened(!isConfigOpened)
                }}
                style={{
                  width: "180px",
                  height: "35px",
                }}
                icon={<Icons icon="bell-config" />}
              >
                Preferências
              </WhiteButton>

              <OutlinedButton
                onClick={() =>
                  dispatch(removeNotification("all")).then(() => {
                    dispatch(listNotifications({ page: 1, filter: "" }))
                  })
                }
                style={{
                  width: "180px",
                  height: "35px",
                  backgroundColor: "transparent",
                }}
                disabled={notificationsState.notifications.length <= 0}
              >
                Limpar todas
              </OutlinedButton>
            </Row>
            <div>
              {firstLoading ? (
                <div style={{ height: "60vh" }}>
                  <Loading />
                </div>
              ) : notificationsState.notifications.length === 0 ? (
                <div className="empty" style={{ textAlign: "center" }}>
                  <img
                    src={emptyImg}
                    alt="imagem para simbolizar que a lista está vazia"
                  />
                  <span className="normal-14-400">
                    Você não possui notificações
                  </span>
                </div>
              ) : (
                <>
                  {notificationsState.notifications.map((item, index) => (
                    <Row key={index} style={{ width: "93%" }}>
                      <Card
                        notification={item}
                        onClick={() => {
                          dispatch(getNotification(item.id))
                        }}
                        onDelete={() => {
                          dispatch(removeNotification(item.id, index))
                        }}
                      />
                    </Row>
                  ))}

                  <LoadMorePages
                    style={{ textAlign: "center" }}
                    isVisible={
                      notificationsState.page < notificationsState.totalPages &&
                      notificationsState.page >= 1
                    }
                    title="Mais notificações"
                    isLoading={loading && notificationsState.page >= 1}
                    onClick={() => {
                      dispatch(
                        listNotifications({
                          page: notificationsState.page + 1,
                          filter: "",
                        })
                      )
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </Row>
      </div>
      <BaseModal
        isVisible={notificationsState.notification !== null}
        title={
          notificationsState.notification &&
          notificationsState.notification.title
        }
        okButtonText={
          notificationsState.notification?.data?.link_description ?? "Fechar"
        }
        onCancel={() => {
          dispatch(resetNotification())
        }}
        onOk={() => {
          dispatch(resetNotification())
          if (notificationsState.notification?.data?.link) {
            window.location.href = notificationsState.notification.data.link
          }
        }}
      >
        <p>
          {notificationsState.notification &&
            notificationsState.notification.content}
        </p>
        {notificationsState.notification?.created_at && (
          <InfoDateTime
            datetimeStr={notificationsState.notification.created_at}
          />
        )}
      </BaseModal>
      <BaseModal
        isVisible={showErrorModal}
        title="Erro!"
        okButtonText="Ok"
        onCancel={() => {
          updateShowErrorModal(false)
        }}
        onOk={() => {
          updateShowErrorModal(false)
        }}
      >
        {notificationsState.errMessage}
      </BaseModal>
    </>
  )
}

export default ListNotifications
