import axios from "axios"
import api from "../../service/api"
import apiRoutes, { baseUrl } from "../../service/apiRoutes"

export const proposalGet = (proposalId) =>
  api.get(`${apiRoutes.proposal}/${proposalId}`)

export const createQRCodePix = (data) =>
  api.post(apiRoutes.qrCodePixCreator, data)

export const contractCreate = (id, data) =>
  api.post(`${apiRoutes.proposal}/${id}/contract`, data)

export const getDefaultContract = (id, data) =>
  api.get(`${apiRoutes.proposal}/${id}/contract/default`, data)

export const uploadInvoice = (proposalId, data) =>
  axios.post(`${baseUrl}${apiRoutes.proposal}/${proposalId}/nf/file`, data)

export const getContractInfo = (id) =>
  api.get(`${apiRoutes.contractView}/${id}`)

export const contractClose = (id) =>
  api.put(`${apiRoutes.contractView}/${id}/close`)

export const contractReset = (id, data) =>
  api.put(`${apiRoutes.contractView}/${id}/reset`, data)

export const reviewProposal = (proposalId, data) =>
  api.put(
    `${apiRoutes.proposalCounterparty}/${proposalId}/review-by-anon`,
    data
  )

export const fulfillProposal = (proposalId) =>
  api.put(`${apiRoutes.proposalCounterparty}/${proposalId}/fulfill`)

export const proposalDelete = (id) => api.delete(`${apiRoutes.proposal}/${id}`)
