import React from "react"

export const BellDotIcon = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        width="18px"
        height="18px"
        viewBox="0 0 11 12"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <defs>
          <rect
            id="propozall-bell-dot-icon-path"
            x="0"
            y="0"
            width="18"
            height="18"
          ></rect>
        </defs>
        <g
          id="propozall-bell-dot-icon"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="propozall-bell-dot-icon-1">
            <g id="propozall-bell-dot-icon-2">
              <mask fill="white" id="propozall-bell-dot-icon-mask">
                <use
                  id="propozall-bell-dot-icon-svg-1"
                  xlinkHref="#propozall-bell-dot-icon-path"
                />
              </mask>
              <g id="propozall-bell-dot-icon-3" />
              <path
                id="propozall-bell-dot-icon-svg-1"
                mask="url(#propozall-bell-dot-icon-mask)"
                fill="#000000"
                fillOpacity="0.7"
                d="m5.89978,0c-0.37661,0.5014 -0.59978,1.12464 -0.59978,1.8c0,0.153 0.01145,0.30332 0.03355,0.45016l-0.03355,-0.00016c-1.8,0 -2.4,1.362 -2.4,2.85l0,3.9l4.8,0l0,-3.9c0,-0.12193 -0.00403,-0.243 -0.01242,-0.36256c0.19771,0.04101 0.40255,0.06256 0.61242,0.06256c0.20235,0 0.40003,-0.02003 0.59115,-0.05823c0.00594,0.11796 0.00885,0.23745 0.00885,0.35823l0,3.3l1.2,0.6l0,1.2l-9.6,0l0,-1.2l1.2,-0.6l0,-3.3c0,-1.848 0.684,-3.384 2.4,-3.792l0,-1.308l1.79978,0zm0.6639,1.32373c0.20881,-0.76299 0.90708,-1.32373 1.73632,-1.32373c0.99411,0 1.8,0.80589 1.8,1.8c0,0.85337 -0.59386,1.56804 -1.39085,1.7533c-0.13144,0.03055 -0.26841,0.0467 -0.40915,0.0467c-0.37684,0 -0.72664,-0.1158 -1.01574,-0.31377c-0.23692,-0.16223 -0.43308,-0.37964 -0.56996,-0.6337c-0.13672,-0.25376 -0.2143,-0.54408 -0.2143,-0.85253c0,-0.16487 0.02217,-0.32457 0.06368,-0.47627zm-0.06368,9.47627l-2.4,0c0,0.66 0.54,1.2 1.2,1.2c0.66,0 1.2,-0.54 1.2,-1.2z"
              />
            </g>
          </g>
          <path
            id="propozall-bell-dot-icon-svg-2"
            d="m7.68009,1.59242"
            opacity="NaN"
            stroke="#000"
            fill="#676767"
          />
          <path
            id="propozall-bell-dot-icon-svg-3"
            d="m8.30476,3.75811c-1.02522,0 -1.85565,-0.83043 -1.85565,-1.85565c0,-1.02522 0.83043,-1.85565 1.85565,-1.85565c1.02522,0 1.85565,0.83043 1.85565,1.85565c0,1.02522 -0.83043,1.85565 -1.85565,1.85565z"
            opacity="undefined"
            strokeOpacity="0"
            stroke="#0c0002"
            fill="#ff0f0f"
          />
        </g>
      </svg>
    </span>
  )
}
