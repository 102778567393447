export const simpleJson = {
  steps: [
    {
      type: "Route",
      step_title: "Como é a proposta?",
      props: {
        path: ["/dashboard/proposal/1", "/dashboard/proposal/1/:id"],
        exact: true,
      },
      elements: [
        {
          type: "Row",
          props: {
            justify: "start",
            gutter: [24, 24],
            align: "bottom",
          },
          elements: [
            {
              type: "Col",
              props: {
                xs: 24,
                sm: 24,
                md: 12,
              },
              elements: [
                {
                  type: "p",
                  default: "Do que se trata a proposta?",
                  props: {
                    className: "input-title",
                  },
                },
              ],
            },
            // {
            //   type: "Col",
            //   props: {
            //     xs: 24,
            //     sm: 24,
            //     md: 12,
            //   },
            //   elements: [
            //     {
            //       type: "Form.Item",
            //       props: {
            //         name: "job_description_allow",
            //         initialValue: false,
            //         valuePropName: "checked",
            //       },
            //       element: {
            //         type: "StyledSwitch",
            //         props: {
            //           storeValue: "state.job_description_allow",
            //           label: "Adicionar descrição dos serviços?",
            //           placement: "left",
            //         },
            //       },
            //     },
            //   ],
            // },
          ],
        },

        {
          type: "Space",
        },

        {
          type: "Row",
          props: {
            justify: "start",
            gutter: [24, 24],
            align: "top",
          },
          elements: [
            {
              type: "Col",
              props: {
                span: 12,
              },
              elements: [
                {
                  type: "Form.Item",
                  props: {
                    name: "title",
                    label: "Título da proposta",
                    initialValue: "",
                    rules: [
                      {
                        required: true,
                        message: "Campo obrigatório",
                      },
                    ],
                  },
                  element: {
                    type: "FlattedInput",
                    props: {
                      placeholder: "Título da proposta",
                    },
                  },
                },
              ],
            },
          ],
        },

        {
          type: "Space",
          // conditional: {
          //   name: "job_description_allow",
          //   equal: true,
          //   then: "hide",
          // },
        },

        {
          type: "Row",
          props: {
            justify: "space-between",
            align: "top",
          },
          elements: [
            {
              type: "Col",
              props: {
                span: 12,
              },
              elements: [
                {
                  type: "Form.Item",
                  // conditional: {
                  //   name: "job_description_allow",
                  //   equal: true,
                  //   then: "hide",
                  // },
                  props: {
                    name: "job_description",
                    label: "Do que se trata o serviço prestado?",
                    className: "add-space-bottom-label",
                    rules: [
                      {
                        required: true,
                        message: "Campo obrigatório",
                      },
                    ],
                  },
                  element: {
                    type: "TextArea",
                    props: {
                      placeholder: "Descrever o serviço que será prestado",
                    },
                  },
                },
              ],
            },
          ],
        },

        {
          type: "Space",
        },

        {
          type: "Row",
          props: {
            justify: "space-between",
            align: "top",
          },
          elements: [
            // name
            {
              type: "Col",
              props: {
                sm: 12,
              },
              elements: [
                {
                  type: "Form.Item",
                  props: {
                    label: "Para quem vai ser criado a proposta?",
                    name: ["contact", "company_name"],
                    rules: [
                      {
                        required: true,
                        message: "Campo obrigatório",
                      },
                    ],
                  },
                  element: {
                    type: "FlattedInput",
                    props: {
                      placeholder: "Nome",
                    },
                  },
                },
              ],
            },
          ],
        },

        {
          type: "Space",
          props: {},
        },

        {
          type: "Row",
          props: {
            justify: "start",
            gutter: [24, 24],
            align: "bottom",
          },
          elements: [
            {
              type: "Col",
              props: {
                sm: 12,
              },
              elements: [
                {
                  type: "Dropdown",
                  props: {
                    trigger: ["click"],
                    placement: "bottomRight",
                    overlay: {
                      type: "DatePicker",
                      props: {
                        className: "floating",
                        storeValue: "form.valid_until",
                        defaultValue: "form.valid_until",
                      },
                    },
                  },
                  element: {
                    type: "Form.Item",
                    props: {
                      name: "valid_until",
                      label: "Validade da proposta",
                      initialValue: "",
                      rules: [
                        {
                          required: true,
                          message: "Campo obrigatório",
                        },
                      ],
                    },
                    element: {
                      type: "FlattedInput",
                      props: {
                        readOnly: true,
                        placeholder: "Inserir a data",
                      },
                    },
                  },
                },
              ],
            },
          ],
        },

        {
          type: "Space",
          props: {},
        },

        {
          type: "Row",
          props: {
            justify: "start",
            gutter: [24, 24],
            align: "bottom",
          },
          elements: [
            {
              type: "Col",
              props: {
                sm: 12,
              },
              elements: [
                {
                  type: "Dropdown",
                  props: {
                    trigger: ["click"],
                    placement: "bottomRight",
                    overlay: {
                      type: "DatePicker",
                      props: {
                        className: "floating",
                        minDate: null,
                        storeValue: "form.date_to_finish",
                        defaultValue: "form.date_to_finish",
                      },
                    },
                  },
                  element: {
                    type: "Form.Item",
                    props: {
                      name: "date_to_finish",
                      label: "Qual a data de entrega do serviço?",
                      initialValue: "",
                      rules: [
                        {
                          required: true,
                          message: "Campo obrigatório",
                        },
                      ],
                    },
                    element: {
                      type: "FlattedInput",
                      props: {
                        readOnly: true,
                        placeholder: "Inserir a data",
                      },
                    },
                  },
                },
              ],
            },
          ],
        },
      ],
      actions: [
        {
          type: "ButtonPreviousStep",
          props: {
            span: 12,
          },
          elements: [
            {
              type: "text",
              default: "Voltar",
            },
          ],
        },

        {
          type: "ButtonNextStep",
          props: {
            span: 12,
          },
          elements: [
            {
              type: "text",
              default: "Continuar",
            },
          ],
        },
      ],
    },

    // SECOND
    {
      type: "Route",
      step_title: "Pagamento",
      props: {
        path: ["/dashboard/proposal/2", "/dashboard/proposal/2/:id"],
        exact: true,
      },
      elements: [
        {
          type: "Row",
          props: {
            justify: "start",
            gutter: [24, 24],
            align: "bottom",
          },
          elements: [
            {
              type: "Col",
              props: {
                span: 12,
              },
              elements: [
                {
                  type: "p",
                  default: "Como vai ser feito o pagamento?",
                  props: {
                    className: "input-title",
                  },
                },
              ],
            },
            // {
            //   type: "Col",
            //   props: {
            //     span: 12,
            //   },
            //   elements: [
            //     {
            //       type: "Form.Item",
            //       props: {
            //         name: "general_observations_allow",
            //         initialValue: false,
            //         valuePropName: "checked",
            //       },
            //       element: {
            //         type: "StyledSwitch",
            //         props: {
            //           storeValue: "state.general_observations_allow",
            //           label: "Possui alguma observação?",
            //           placement: "left",
            //         },
            //       },
            //     },
            //   ],
            // },
          ],
        },

        {
          type: "Space",
        },

        {
          type: "Row",
          props: {
            justify: "start",
            gutter: [24, 24],
            align: "bottom",
          },
          elements: [
            {
              type: "Col",
              props: {
                sm: 8,
              },
              elements: [
                {
                  type: "Form.Item",
                  props: {
                    label: "Qual o valor desta proposta?",
                    name: "total_value",
                    initialValue: "",
                    rules: [
                      {
                        required: true,
                        message: "Campo obrigatório",
                      },
                    ],
                  },
                  element: {
                    type: "FlattedInputCurrency",
                    props: {
                      placeholder: "R$ 0,00",
                    },
                  },
                },
              ],
            },
          ],
        },

        {
          type: "Space",
          props: {},
        },

        {
          type: "SelectPaymentMethod",
          props: {
            label: "Qual a forma de pagamento?",
            placeholder: "Selecione uma opção",
            rules: [
              {
                required: true,
                message: "Campo obrigatório",
              },
            ],
          },
        },

        {
          type: "Space",
          props: {},
        },

        {
          type: "Row",
          props: {
            justify: "start",
            gutter: [24, 24],
            align: "bottom",
          },
          elements: [
            {
              type: "Col",
              props: {
                sm: 8,
              },
              elements: [
                {
                  type: "Dropdown",
                  props: {
                    trigger: ["click"],
                    placement: "bottomRight",
                    overlay: {
                      type: "DatePicker",
                      props: {
                        className: "floating",
                        storeValue: "form.payment_date",
                        defaultValue: "form.payment_date",
                      },
                    },
                  },
                  element: {
                    type: "Form.Item",
                    props: {
                      name: "payment_date",
                      label: "Qual a data de pagamento?",
                      initialValue: "",
                      rules: [
                        {
                          required: true,
                          message: "Campo obrigatório",
                        },
                      ],
                    },
                    element: {
                      type: "FlattedInput",
                      props: {
                        readOnly: true,
                        placeholder: "Inserir a data",
                      },
                    },
                  },
                },
              ],
            },
          ],
        },

        {
          type: "Space",
          props: {},
        },

        {
          type: "SelectCpfCnpj",
          props: {
            name: "cpf_cnpj",
            label: "CNPJ de faturamento",
            span: 12,
            rules: [
              {
                required: true,
                message: "Campo obrigatório",
              },
            ],
          },
        },

        {
          type: "Space",
          // conditional: {
          //   name: "general_observations_allow",
          //   equal: true,
          //   then: "hide",
          // },
        },

        {
          type: "Row",
          props: {
            justify: "space-between",
            align: "top",
          },
          elements: [
            {
              type: "Col",
              props: {
                span: 12,
              },
              elements: [
                {
                  type: "Form.Item",
                  // conditional: {
                  //   name: "general_observations_allow",
                  //   equal: true,
                  //   then: "hide",
                  // },
                  props: {
                    name: "general_observations",
                    label: "Observações gerais",
                    className: "add-space-bottom-label",
                    rules: [
                      {
                        required: true,
                        message: "Campo obrigatório",
                      },
                    ],
                  },
                  element: {
                    type: "TextArea",
                    props: {
                      placeholder: "Descrever observações...",
                    },
                  },
                },
              ],
            },
          ],
        },
      ],
      actions: [
        {
          type: "ButtonPreviousStep",
          props: {
            span: 12,
          },
          elements: [
            {
              type: "text",
              default: "Voltar",
            },
          ],
        },

        {
          type: "ButtonNextStep",
          props: {
            span: 12,
          },
          elements: [
            {
              type: "text",
              default: "Continuar",
            },
          ],
        },
      ],
    },

    // FIFITH
    {
      type: "Route",
      step_title: "Envio",
      props: {
        path: ["/dashboard/proposal/3", "/dashboard/proposal/3/:id"],
        className: "step-no-style",
      },
      elements: [
        {
          type: "ProposalCreateResume",
          elements: [],
        },
      ],
    },
  ],
}
