import React from "react"
import PropTypes from "prop-types"
import Icons from "../Icons"
import { Col, Row } from "antd"
import { Tooltip } from "../Tooltip"
import "./styles.scss"

const ProposalStatus = ({ status, children }) => {
  return (
    <Row gutter={[8, 0]} className="proposal-status" wrap={false}>
      <Col>
        <Icons icon={`proposal-${status}`} className="proposal-icon" />
      </Col>
      <Col>
        <div className="normal-16-500 not-overflow">
          <Tooltip title={children}>{children}</Tooltip>
        </div>
      </Col>
    </Row>
  )
}

ProposalStatus.propTypes = {
  status: PropTypes.string.isRequired,
  children: PropTypes.element,
}

export default ProposalStatus
