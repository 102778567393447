import React, { useState, useEffect } from "react"
//
import { useSelector, useDispatch } from "react-redux"
import { getSubscription, selectRegister } from "../../RegisterSlice"
import StateStatus from "../../../../utils/stateStatus"
//
import FeedbackPayment from "../../components//FeedbackPayment"
//
import "./index.scss"

const FeedbackForm = () => {
  const dispatch = useDispatch()
  const register = useSelector(selectRegister)
  const [resultPayment, updResultPayment] = useState()

  const buildStatus = (status, hasError, textError) => {
    if (!status || status === "pending" || status === "draft") {
      if (hasError) {
        return {
          status: "failed",
          text: `Erro: ${textError}. Favor entrar em contato com a administradora do seu cartão.`,
        }
      }

      return { status: "pending", text: null }
    } else if (status === "paid" || status === "in_protest") {
      return { status: "success", text: null }
    } else {
      const result = { status: "failed", text: null }

      if (status === "refunded") {
        // refunded - a assinatura foi reembolsada
        result.text = `Erro: ${textError}. A sua assinatura foi reembolsada.`
      } else if (status === "canceled") {
        // canceled - a assinatura foi cancelada
        result.text = `Erro: ${textError}. A sua assinatura foi cancelada.`
      } else if (status === "expired") {
        // expired - a assinatura foi expirada
        result.text = `Erro: ${textError}. A sua assinatura foi expirada.`
      } else if (status === "chargeback") {
        // chargeback - a assinatura foi contestada
        result.text = `Erro: ${textError}. A sua assinatura foi contestada.`
      }

      return result
    }
  }

  const verifyPayment = () => {
    if (
      Object.keys(register.objPayment).length > 0 &&
      register.objPayment.invoice_data
    ) {
      if (
        register.objPayment.invoice_data.has_error ||
        register.objPayment.invoice_data.status === "payment_refused" ||
        register.objPayment.invoice_data.status === "approved" ||
        register.objPayment.invoice_data.status === "paid"
      ) {
        return false
      }
    }

    return true
  }

  useEffect(() => {
    if (
      Object.keys(register.objPayment).length > 0 &&
      register.objPayment.invoice_data
    ) {
      const result = buildStatus(
        register.objPayment.invoice_data.status,
        register.objPayment.invoice_data.has_error,
        register.objPayment.invoice_data.error_reason
      )
      updResultPayment(result)
    }
  }, [register.objPayment])

  useEffect(() => {
    document.body.style.overflow = "auto"
    const interval = setInterval(() => {
      if (
        (!resultPayment?.status || resultPayment.status === "pending") &&
        register.status.getSubscription !== StateStatus.loading &&
        verifyPayment()
      ) {
        dispatch(getSubscription())
      }
    }, 5000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <FeedbackPayment
      status={resultPayment?.status}
      text={resultPayment?.text}
      page="register"
    />
  )
}

export default FeedbackForm
