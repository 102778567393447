import React from "react"

export const PencilIcon = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.1453 0.646887C12.1917 0.600326 12.2469 0.563385 12.3076 0.53818C12.3684 0.512974 12.4335 0.5 12.4993 0.5C12.565 0.5 12.6301 0.512974 12.6909 0.53818C12.7516 0.563385 12.8068 0.600326 12.8532 0.646887L15.8531 3.64676C15.8997 3.6932 15.9366 3.74837 15.9618 3.80912C15.987 3.86986 16 3.93498 16 4.00074C16 4.06651 15.987 4.13162 15.9618 4.19237C15.9366 4.25311 15.8997 4.30828 15.8531 4.35473L5.85355 14.3543C5.80557 14.4019 5.74842 14.4394 5.68556 14.4643L0.685776 16.4642C0.594916 16.5006 0.49538 16.5095 0.399508 16.4898C0.303636 16.4701 0.215644 16.4228 0.14644 16.3536C0.0772369 16.2844 0.0298655 16.1964 0.0101989 16.1005C-0.00946768 16.0046 -0.000564569 15.9051 0.0358045 15.8142L2.03572 10.8144C2.06064 10.7516 2.09806 10.6944 2.14571 10.6465L12.1453 0.646887ZM11.2063 3.00078L13.4992 5.29368L14.7922 4.00074L12.4993 1.70784L11.2063 3.00078ZM12.7922 6.00065L10.4993 3.70775L3.99963 10.2075V10.5005H4.49961C4.63221 10.5005 4.75938 10.5531 4.85315 10.6469C4.94691 10.7407 4.99959 10.8678 4.99959 11.0004V11.5004H5.49957C5.63217 11.5004 5.75934 11.5531 5.8531 11.6469C5.94687 11.7406 5.99954 11.8678 5.99954 12.0004V12.5004H6.29253L12.7922 6.00065ZM3.03167 11.1754L2.92568 11.2814L1.39774 15.1023L5.21858 13.5743L5.32457 13.4683C5.2292 13.4327 5.14697 13.3688 5.0889 13.2852C5.03082 13.2015 4.99966 13.1022 4.99959 13.0003V12.5004H4.49961C4.36701 12.5004 4.23984 12.4477 4.14607 12.3539C4.05231 12.2602 3.99963 12.133 3.99963 12.0004V11.5004H3.49965C3.39784 11.5003 3.29847 11.4692 3.21484 11.4111C3.13121 11.353 3.06731 11.2708 3.03167 11.1754Z"
          fill="#002999"
        />
      </svg>
    </span>
  )
}
