import React from "react"

export const ProposalStatusWaitingAcceptIcon = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        width="21"
        height="27"
        viewBox="0 0 21 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.3475 11.5543C19.7675 10.9885 20 10.2954 20 9.57427C20 8.43012 19.3725 7.34712 18.3625 6.74319C18.1025 6.58773 17.8064 6.50592 17.505 6.5062H11.51L11.66 3.37442C11.695 2.6176 11.4325 1.899 10.9225 1.35113C10.6722 1.08109 10.3703 0.866233 10.0353 0.719843C9.70032 0.573452 9.33946 0.498637 8.975 0.500019C7.675 0.500019 6.525 1.3919 6.18 2.66856L4.0325 10.5936H0.8C0.3575 10.5936 0 10.958 0 11.409V20.6846C0 21.1356 0.3575 21.5 0.8 21.5H15.8325C16.0625 21.5 16.2875 21.4541 16.495 21.3624C17.685 20.8451 18.4525 19.6602 18.4525 18.3453C18.4525 18.0242 18.4075 17.7082 18.3175 17.4024C18.7375 16.8367 18.97 16.1436 18.97 15.4225C18.97 15.1014 18.925 14.7854 18.835 14.4796C19.255 13.9139 19.4875 13.2208 19.4875 12.4996C19.4825 12.1786 19.4375 11.86 19.3475 11.5543ZM1.8 19.6653V12.4283H3.825V19.6653H1.8ZM17.71 10.67L17.1625 11.1542L17.51 11.8014C17.6245 12.0146 17.6838 12.254 17.6825 12.4971C17.6825 12.9176 17.5025 13.3176 17.1925 13.5928L16.645 14.077L16.9925 14.7242C17.107 14.9375 17.1663 15.1769 17.165 15.4199C17.165 15.8404 16.985 16.2404 16.675 16.5157L16.1275 16.9998L16.475 17.6471C16.5895 17.8603 16.6488 18.0997 16.6475 18.3427C16.6475 18.9135 16.3175 19.4283 15.8075 19.6627H5.425V12.3467L7.9125 3.16037C7.97664 2.92492 8.11433 2.71719 8.30471 2.56866C8.49508 2.42014 8.72777 2.33891 8.9675 2.33729C9.1575 2.33729 9.345 2.39336 9.495 2.50803C9.7425 2.69659 9.875 2.982 9.86 3.28524L9.62 8.34093H17.48C17.925 8.61869 18.2 9.08756 18.2 9.57427C18.2 9.99473 18.02 10.3923 17.71 10.67Z"
          fill="#5B7CD7"
        />
        <path
          d="M14.5 25.5C17.5376 25.5 20 23.0376 20 20C20 16.9624 17.5376 14.5 14.5 14.5C11.4624 14.5 9 16.9624 9 20C9 23.0376 11.4624 25.5 14.5 25.5Z"
          fill="white"
          stroke="#002999"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.15 22.2L14.8223 20.8723C14.616 20.666 14.5001 20.3863 14.5 20.0946V16.7"
          fill="white"
        />
        <path
          d="M16.15 22.2L14.8223 20.8723C14.616 20.666 14.5001 20.3863 14.5 20.0946V16.7"
          stroke="#002999"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  )
}
