import React from "react"

export const CircleArrowUpIcon = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        width="18px"
        height="18px"
        viewBox="0 0 15 15"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <defs>
          <rect
            id="circle-arrow-up-path"
            x="0"
            y="0"
            width="23"
            height="23"
          ></rect>
        </defs>
        <g
          id="circle-Design-Tokens"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="circle-arrow-Base-Components-2"
            transform="translate(-452.000000, -1464.000000)"
          >
            <g
              id="circle-arrow-/-circle_up"
              transform="translate(448.000000, 1460.000000)"
            >
              <mask id="circle-mask-2" fill="white">
                <use xlinkHref="#circle-arrow-up-path"></use>
              </mask>
              <g id="circle-arrow-/-circle_up-(Background/Mask)"></g>
              <path
                d="M12.1708333,14.8541667 L10.8291667,14.8541667 L10.8291667,10.7151249 L9.09170823,12.4458749 L8.14583333,11.5 L11.5,8.14583333 L14.8541667,11.5 L13.9082918,12.4458749 L12.1708333,10.7151249 L12.1708333,14.8541667 Z M11.5,18.2083333 C7.79509002,18.2083333 4.79166667,15.20491 4.79166667,11.5 C4.79166667,7.7950897 7.79509002,4.79166667 11.5,4.79166667 C15.2049103,4.79166667 18.2083333,7.7950897 18.2083333,11.5 C18.2083333,15.20491 15.2049103,18.2083333 11.5,18.2083333 C9.03006001,18.2083333 9.03006001,18.2083333 11.5,18.2083333 Z M11.5,16.8666667 C8.53607202,16.8666665 6.1333332,14.463928 6.13333333,11.5 C6.13333346,8.5360717 8.53607202,6.1333332 11.5,6.13333333 C14.4639283,6.13333346 16.8666668,8.5360717 16.8666667,11.5 C16.8666665,14.463928 14.4639283,16.8666668 11.5,16.8666667 C9.52404801,16.8666666 9.52404801,16.8666666 11.5,16.8666667 Z"
                fill="#000000"
                mask="url(#mask-2)"
              ></path>
            </g>
          </g>
        </g>
      </svg>
    </span>
  )
}
