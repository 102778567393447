import React from "react"

export const HomeLampIcon = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="42"
        viewBox="0 0 32 42"
        fill="none"
      >
        <path
          d="M16.0009 31.856V22.3554M16.0009 22.3554C16.9454 22.3561 17.8862 22.2412 18.8009 22.0134M16.0009 22.3554C15.0565 22.3561 14.1157 22.2412 13.2009 22.0134M20.2009 35.5459C17.4257 36.0568 14.5761 36.0568 11.8009 35.5459M18.8009 39.8583C16.9394 40.0472 15.0625 40.0472 13.2009 39.8583M20.2009 31.856V31.5086C20.2009 29.7297 21.4292 28.2096 23.0159 27.3174C25.6815 25.821 27.7636 23.514 28.9396 20.7539C30.1156 17.9939 30.3198 14.9349 29.5206 12.051C28.7214 9.16703 26.9634 6.61909 24.519 4.80198C22.0746 2.98486 19.0803 2 16 2C12.9197 2 9.92537 2.98486 7.48098 4.80198C5.03659 6.61909 3.27861 9.16703 2.47942 12.051C1.68023 14.9349 1.88445 17.9939 3.06044 20.7539C4.23643 23.514 6.31853 25.821 8.98414 27.3174C10.5708 28.2096 11.8009 29.7297 11.8009 31.5086V31.856"
          stroke="#002999"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  )
}
