import React from "react"

export const OnboardingDocument = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="17"
        viewBox="0 0 15 17"
        fill="none"
      >
        <path
          d="M2.07812 16.4163C1.50087 16.4163 1.0102 16.2143 0.60612 15.8102C0.20204 15.4061 0 14.9155 0 14.3382V11.6663H2.375V0.583008H14.25V14.3382C14.25 14.9155 14.048 15.4061 13.6439 15.8102C13.2398 16.2143 12.7491 16.4163 12.1719 16.4163H2.07812ZM12.1701 15.2288C12.422 15.2288 12.6337 15.1435 12.8052 14.9728C12.9767 14.8021 13.0625 14.5906 13.0625 14.3382V1.77051H3.5625V11.6663H11.2812V14.3382C11.2812 14.5906 11.3664 14.8021 11.5368 14.9728C11.7072 15.1435 11.9183 15.2288 12.1701 15.2288ZM4.75 5.33301V4.14551H11.875V5.33301H4.75ZM4.75 7.70801V6.52051H11.875V7.70801H4.75ZM2.05833 15.2288H10.0938V12.8538H1.1875V14.3382C1.1875 14.5906 1.27326 14.8021 1.44479 14.9728C1.61632 15.1435 1.82083 15.2288 2.05833 15.2288ZM2.05833 15.2288H1.1875H10.0938H2.05833Z"
          fill="#002999"
        />
      </svg>
    </span>
  )
}
