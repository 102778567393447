import React from "react"
import PropTypes from "prop-types"
import avatar from "../../assets/avatar.svg"
import "./styles.scss"

const Avatar = ({ src, size }) => {
  return (
    <img
      data-testid="avatar"
      className="avatar-src"
      height={size}
      width={size}
      src={src || avatar}
      onError={(event) => {
        event.target.src = avatar
      }}
    />
  )
}

Avatar.defaultProps = {
  src: avatar,
  size: "45px",
}

Avatar.propTypes = {
  src: PropTypes.string,
  size: PropTypes.string,
}

export default Avatar
