import React from "react"

export const ContractStatusWaitingIcon = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        width="30"
        height="30"
        viewBox="0 0 49 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M31.5 10H22.1667C21.4594 10 20.7811 10.281 20.281 10.781C19.781 11.2811 19.5 11.9594 19.5 12.6667V34C19.5 34.7072 19.781 35.3855 20.281 35.8856C20.7811 36.3857 21.4594 36.6667 22.1667 36.6667H38.1667C38.8739 36.6667 39.5522 36.3857 40.0523 35.8856C40.5524 35.3855 40.8333 34.7072 40.8333 34V19.3333V13C40.8333 11.3431 39.4902 10 37.8333 10H31.5Z"
          stroke="#141414"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.5 18H30.7941H35.5"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M25.5 21H30.7941H35.5"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M25.5 15H27.5833H30.5"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M15.5 38C19.9183 38 23.5 34.4183 23.5 30C23.5 25.5817 19.9183 22 15.5 22C11.0817 22 7.5 25.5817 7.5 30C7.5 34.4183 11.0817 38 15.5 38Z"
          fill="#FAFAFA"
          stroke="black"
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M15.5 25.2V30L18.7 31.6" fill="#FAFAFA" />
        <path
          d="M15.5 25.2V30L18.7 31.6"
          stroke="black"
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.5 31.8127C26.6219 30.9031 27.1945 28.913 27.4662 27.5362C27.6612 26.5483 27.8594 25.468 27.8594 24.4558C27.8594 23.6931 27.8734 23.9824 27.9332 24.6279C28.0116 25.4748 28.0069 26.3184 28.0069 27.1676C28.0069 28.3098 27.9465 29.4691 28.1462 30.5674C28.1873 30.7936 28.1544 31.0498 28.1544 31.2802C28.1544 31.6276 28.3898 30.6229 28.4493 30.2807C28.6445 29.1586 28.9743 31.1963 29.7437 31.2229C30.279 31.2413 30.5206 30.7301 30.9235 30.4855C31.1821 30.3285 31.4599 30.6439 31.6935 30.7477C32.0569 30.9092 32.561 30.3885 32.8405 30.2234C33.3888 29.8994 33.5715 30.3267 34.053 30.5674C34.7393 30.9106 35.5717 30.3381 36.265 30.3381"
          stroke="black"
          strokeWidth="1.7"
          strokeLinecap="round"
        />
      </svg>
    </span>
  )
}
