import React from "react"
import PropTypes from "prop-types"
import Icons from "../Icons"
import Loading from "../Loading"
import "./styles.scss"

const LoadMorePages = ({ isVisible, isLoading, title, onClick, ...props }) => {
  return (
    <div className="load-more-paginator" {...props}>
      {isVisible &&
        (isLoading ? (
          <Loading className="dark small center" />
        ) : (
          <Icons
            className="icon-load-more"
            title={title}
            onClick={onClick}
            icon="circle-plus"
          />
        ))}
    </div>
  )
}

LoadMorePages.propTypes = {
  isVisible: PropTypes.bool,
  isLoading: PropTypes.bool,
  title: PropTypes.string,
  onClick: PropTypes.func,
}

export { LoadMorePages }
