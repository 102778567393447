import React from "react"
import RoudedBox from "../../../../common/components/RoudedBox"
import { Col, Row } from "antd"
import Icons from "../../../../common/components/Icons"
import "./styles.scss"

const NewLettersCard = () => {
  return (
    <div>
      <RoudedBox className="default-container">
        <Row>
          <Col className="col-title">
            <div className="icon-title">
              <Icons icon="proposal-type-influencer" />
            </div>
            <div className="title normal-14">Novidade!</div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="news normal-12">
              Agora, ao criar uma proposta, adicione o Pix como forma de
              pagamento e gere seu QR Code diretamente. O QR Code fica anexado a
              proposta. Essa opção não é válida para propostas com
              influenciadores.
            </div>
          </Col>
          {/* <Col>
            <a href="" className="link normal-14">
              Saiba mais
            </a>
          </Col> */}
        </Row>
        {/* <Row className="style-dots">
          <Col className="col-dots">
            <div className="dots"></div>
            <div className="dots"></div>
            <div className="dots"></div>
          </Col>
        </Row> */}
      </RoudedBox>
    </div>
  )
}
export default NewLettersCard
