import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  confirmEditEmailThunk,
  editEmailThunk,
  editProfile,
  getProfile,
  listAddress,
  resetActionsStatus,
  selectConfigState,
  selectProfileData,
  uploadAvatar,
} from "../../configurationsSlice"
import {
  beautifyCpfCnpj,
  formatPhoneNumber,
  parsePhone,
} from "../../../../utils/formaters"
import { Col, Form, Row } from "antd"
import ErrorMessage from "../../../../common/components/ErrorMessage"
import Avatar from "../../../../common/components/avatar"
import BaseModal from "../../../../common/components/Modal"
import { FavoriteIcon } from "../../../../common/components/FavoriteIcon"
import FlattedInput, {
  FlattedInputNumber,
  FlattedPhoneInputForm,
} from "../../../../common/components/FlattedInput"
import PropTypes from "prop-types"
import "./styles.scss"
import EditSocialMediasModal from "./components/editSocialMediasModal"
import { CardSettings } from "./components/cardSettings"
import StateStatus from "../../../../utils/stateStatus"
import { AddressModal } from "./components/addressModal"
import { PasswordModal } from "./components/passwordModal"
import FlattedInputPassword from "../../../../common/components/FlattedInputPassword"

const { useForm } = Form

const ProfileData = () => {
  const dispatch = useDispatch()
  const profileData = useSelector(selectProfileData)
  const configState = useSelector(selectConfigState)
  const [address, updateAddress] = useState({})
  const [modalData, updateModalData] = useState({})
  const [showModal, updateShowModal] = useState()
  const [phone, updatePhone] = useState()
  const [fileSelected, updateFileSelected] = useState()

  useEffect(() => {
    document.body.style.overflow = "auto"
    dispatch(getProfile())
    dispatch(listAddress())
  }, [])

  useEffect(() => {
    if (profileData && profileData.phone) {
      const phone = parsePhone(profileData.phone)
      updatePhone(phone)
    } else {
      updatePhone(null)
    }
  }, [profileData, profileData.phone])

  useEffect(() => {
    switch (configState.status.listAddress) {
      case StateStatus.succeeded:
        updateAddress(configState.data.address[0] ?? {})
        break
      case StateStatus.failed:
        updateShowModal({
          type: "error",
          title: "Erro!",
          message: configState.errMessage,
        })
        break
    }
  }, [configState.status.listAddress])

  useEffect(() => {
    switch (configState.status.editProfile) {
      case StateStatus.failed:
        updateShowModal({
          type: "error",
          title: "Erro!",
          message: configState.errMessage,
        })
        break

      case StateStatus.succeeded:
        updateModalData({})
        break
    }
  }, [configState.status.editProfile])

  const handleChooseFile = (event) => {
    try {
      const file = event.target.files[0]
      if (!file || !file.type.match(/(jpeg|jpeg|png)/)) {
        throw Error("Formato inválido")
      }

      if (file.size > 5000000) {
        throw Error("Arquivo deve ser menor que 5MB")
      }

      updateFileSelected({
        file,
        url: URL.createObjectURL(event.target.files[0]),
      })
    } catch (err) {
      updateFileSelected({ error: err.message })
    }
  }

  return (
    <>
      <div className="config-tab-content">
        <CardSettings
          label="Nome"
          value={profileData.name}
          actionText="Alterar"
          onClick={() => updateModalData({ field: "name" })}
        />
        <CardSettings
          label="E-mail"
          value={
            <Row
              gutter={[8, 8]}
              justify="space-between"
              style={{ paddingRight: "15px", width: "100%" }}
            >
              <Col>{profileData.email}</Col>
              <Col style={{ textAlign: "center" }}>
                {profileData.email_confirmed ? (
                  <div className="normal-16">
                    Verificado{" "}
                    {profileData.email_confirmed_at
                      ? `em ${profileData.email_confirmed_at}`
                      : ""}
                  </div>
                ) : (
                  <div className="normal-16">Aguardando verificação</div>
                )}
              </Col>
            </Row>
          }
          actionText="Alterar"
          onClick={() => {
            updateModalData({ field: "email" })
          }}
        />
        <CardSettings
          label="Senha"
          value="• • • • • • • •"
          actionText="Alterar"
          onClick={() => updateModalData({ field: "password" })}
        />
        <CardSettings
          label="Celular"
          value={profileData.phone ? formatPhoneNumber(profileData.phone) : "-"}
          actionText={profileData.phone ? "Alterar" : "Adicionar"}
          onClick={() => updateModalData({ field: "phone" })}
        />
        <CardSettings
          className="profile-avatar"
          label="Foto do Perfil"
          value={
            <div className="avatar">
              {<Avatar src={profileData.avatar_url} />}
            </div>
          }
          actionText={profileData.avatar_url ? "Alterar" : "Adicionar"}
          onClick={() => updateModalData({ field: "avatar" })}
        />
        <CardSettings
          label={"CPF ou CNPJ"}
          value={beautifyCpfCnpj(profileData.cpf_cnpj)}
          actionText=""
          onClick={null}
        />
        <CardSettings
          className="profile-social-medias"
          label="Redes Sociais"
          value={
            profileData.social_medias &&
            profileData.social_medias.length > 0 ? (
              <Row className="normal-16-500">
                {profileData.social_medias &&
                  profileData.social_medias.map((item, index) => (
                    <span key={index} className="social-media">
                      <span className="info">
                        {item.is_favorite && <FavoriteIcon isFavorite={true} />}
                        <span>
                          {item.plataform.initials}: {item.username}
                        </span>
                      </span>
                      <span className="sep">|</span>
                    </span>
                  ))}
              </Row>
            ) : (
              "-"
            )
          }
          actionText={
            profileData.social_medias && profileData.social_medias.length > 0
              ? "Alterar"
              : "Adicionar"
          }
          onClick={() => updateModalData({ field: "social-medias" })}
        />
        <CardSettings
          label="Endereço"
          value={
            address.id ? (
              <div className="normal-16-500">
                {address.street}, {address.number} - {address.neighborhood} -{" "}
                {address.city}, {address.state}
              </div>
            ) : (
              "-"
            )
          }
          actionText={address.id ? "Alterar" : "Adicionar"}
          onClick={() => updateModalData({ field: "address" })}
        />
      </div>
      <EditFieldModal
        title="Alterar Nome"
        text=""
        isVisible={modalData.field === "name"}
        isLoading={configState.status.editProfile === StateStatus.loading}
        onSave={(values) => {
          dispatch(editProfile(values))
          updateModalData(false)
        }}
        onCancel={() => {
          updateModalData({})
        }}
      >
        <Form.Item
          label="Nome e sobrenome"
          name="name"
          initialValue={profileData.name || ""}
          rules={[
            {
              required: true,
              message: <div className="normal-12">Campo obrigatório</div>,
            },
            () => ({
              validator(_, value) {
                if (value && value.trim().split(" ").length >= 2) {
                  return Promise.resolve()
                } else {
                  return Promise.reject(new Error())
                }
              },
              message: <div className="normal-12">Insira nome e sobrenome</div>,
            }),
          ]}
        >
          <FlattedInput></FlattedInput>
        </Form.Item>
      </EditFieldModal>
      <EditFieldModal
        title="Alteração de e-mail"
        text="Vamos enviar um código para o novo e-mail para verificação."
        isVisible={modalData.field === "email"}
        isLoading={configState.status.editEmail === StateStatus.loading}
        onSave={(values, form) => {
          if (
            values.new_email.trim().toLowerCase() ===
            profileData.email.trim().toLowerCase()
          ) {
            return form.setFields([
              {
                name: "new_email",
                errors: ["E-mail é igual ao seu atual e-mail cadastrado"],
              },
            ])
          }

          dispatch(editEmailThunk(values)).then((resp) => {
            if (resp?.error?.code === "NOT_ALLOWED") {
              form.setFields([{ name: "password", errors: ["Senha inválida"] }])
            } else if (resp?.error?.code === "EMAIL_ALREADY_EXISTS") {
              form.setFields([
                {
                  name: "new_email",
                  errors: ["E-mail já cadastrado por outro usuário"],
                },
              ])
            } else if (!resp.error) {
              form.resetFields()
              updateModalData({ field: "confirm-email" })
            }
          })
        }}
        onCancel={() => {
          updateModalData({})
        }}
        onClose={() => {
          updateModalData({})
        }}
      >
        <Form.Item
          label="Novo e-mail"
          name="new_email"
          initialValue={""}
          isLoading={configState.status.editProfile === StateStatus.loading}
          rules={[
            {
              required: true,
              message: "Este campo é obrigtório.",
            },
            {
              type: "email",
              message: "E-mail inválido",
            },
          ]}
        >
          <FlattedInput
            style={{ width: "100%" }}
            type="email"
            placeholder="Digite seu e-mail"
          />
        </Form.Item>
        <Form.Item
          name="password"
          initialValue={""}
          rules={[
            {
              required: true,
              message: (
                <div className="normal-12">Insira a senha de usuário</div>
              ),
            },
          ]}
        >
          <FlattedInputPassword
            style={{ width: "100%" }}
            placeholder="Digite sua senha"
          />
        </Form.Item>
      </EditFieldModal>
      <EditFieldModal
        title="Confirmação"
        text="Digite o código de verficação de 6 dígitos que enviamos no seu e-mail."
        isVisible={modalData.field === "confirm-email"}
        okButtonText={"Concluir"}
        isLoading={configState.status.confirmEditEmail === StateStatus.loading}
        onSave={(values, form) => {
          dispatch(
            confirmEditEmailThunk({ code: values.code.toString() })
          ).then((resp) => {
            if (resp?.error?.code === "VALIDATION_ERROR") {
              form.setFields([{ name: "code", errors: ["Código inválida"] }])
            } else if (!resp.error) {
              updateModalData({})
              updateShowModal({
                type: "success",
                title: "Alteração sucedida",
                message: "Seu e-mail foi alterado com sucesso!",
              })
            }
          })
        }}
        onClose={() => {
          updateModalData({})
        }}
      >
        <Form.Item
          label="Código de verficação"
          name="code"
          initialValue={""}
          isLoading={configState.status.editProfile === StateStatus.loading}
          rules={[
            {
              required: true,
              message: "Este campo é obrigtório.",
            },
          ]}
        >
          <FlattedInputNumber placeholder="Digite o código" />
        </Form.Item>
      </EditFieldModal>
      <EditFieldModal
        title={profileData.phone ? "Alterar Telefone" : "Adicionar Telefone"}
        text="Vamos enviar um código de verificação para poder confimar o número"
        isVisible={modalData.field === "phone"}
        isLoading={configState.status.editProfile === StateStatus.loading}
        onSave={(values) => {
          if (!phone) {
            updatePhone({ isValid: false })
          } else if (!phone.isValid) {
            updatePhone({ ...phone, isValid: false })
          } else {
            values.phone = phone.phone
            dispatch(editProfile(values))
            updateModalData({})
          }
        }}
        onCancel={() => {
          updateModalData({})
        }}
      >
        <Row>
          <Col span={16}>
            <Form.Item name="phone" label="Telefone">
              <FlattedPhoneInputForm
                value={phone ? phone.phone : ""}
                onChange={({ phoneNumber }) => {
                  updatePhone(phoneNumber)
                }}
              />
              {phone && (
                <>
                  <ErrorMessage
                    text="Campo obrigatório"
                    isVisible={!phone.number && !phone.isValid}
                  />
                  <ErrorMessage
                    text="Telefone inválido"
                    isVisible={!phone.isValid}
                  />
                </>
              )}
            </Form.Item>
          </Col>
        </Row>
      </EditFieldModal>
      <EditFieldModal
        title={"Alterar foto do perfil"}
        text=""
        isLoading={configState.status.uploadAvatar === StateStatus.loading}
        isVisible={modalData.field === "avatar"}
        onSave={(values) => {
          if (!fileSelected) {
            updateModalData({})
          } else {
            dispatch(uploadAvatar(fileSelected.file)).then(({ type }) => {
              if (type.endsWith("fulfilled")) {
                updateModalData({})
                updateFileSelected(null)
              }
            })
          }
        }}
        onCancel={() => {
          updateModalData({})
          updateFileSelected(null)
        }}
      >
        <div className="edit-avatar">
          <div className="avatar">
            <div>
              {fileSelected && !fileSelected.error ? (
                <Avatar src={fileSelected.url} />
              ) : (
                <Avatar src={profileData.avatar_url} />
              )}
            </div>
            <label htmlFor="avatar">Alterar foto</label>
            <input id="avatar" type="file" onChange={handleChooseFile} />
            {fileSelected && fileSelected.error && (
              <ErrorMessage text={fileSelected.error} isVisible={true} />
            )}
          </div>
        </div>
      </EditFieldModal>
      <EditSocialMediasModal
        isVisible={modalData.field === "social-medias"}
        onSave={() => updateModalData({})}
        onCancel={() => updateModalData({})}
        isLoading={configState.status.editProfile === StateStatus.loading}
      ></EditSocialMediasModal>
      <AddressModal
        isVisible={modalData.field === "address"}
        data={address}
        onError={() =>
          updateShowModal({
            type: "error",
            title: "Erro!",
            message: configState.errMessage,
          })
        }
        onSuccess={() => updateModalData({})}
        onCancel={() => updateModalData({})}
      />
      <PasswordModal
        isVisible={modalData.field === "password"}
        data={address}
        onError={() =>
          updateShowModal({
            type: "error",
            title: "Erro!",
            message: configState.errMessage,
          })
        }
        onSuccess={() => updateModalData({})}
        onCancel={() => updateModalData({})}
      />
      <BaseModal
        type={showModal?.type || "error"}
        isVisible={!!showModal}
        title={showModal?.title || "Erro!"}
        okButtonText="Ok"
        onOk={() => {
          updateShowModal(null)
          dispatch(resetActionsStatus())
        }}
        onCancel={() => {
          updateShowModal(null)
          dispatch(resetActionsStatus())
        }}
      >
        {showModal?.message || configState.errMessage}
      </BaseModal>
    </>
  )
}

const EditFieldModal = ({
  title,
  text,
  isVisible,
  onSave,
  onCancel,
  onClose,
  children,
  okButtonText,
  cancelButtonText,
  ...rest
}) => {
  const [form] = useForm()

  return (
    <BaseModal
      className="edit-field-modal"
      title={title}
      isVisible={isVisible}
      okButtonText={okButtonText || "Salvar"}
      cancelButtonText={cancelButtonText || "Cancelar"}
      onOk={() => {
        form.submit()
      }}
      onClose={() => {
        form.resetFields()
        if (onClose) {
          onClose()
        } else if (onCancel) {
          onCancel()
        }
      }}
      onCancel={
        onCancel &&
        (() => {
          form.resetFields()
          onCancel && onCancel()
        })
      }
      width={500}
      {...rest}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={(values) => {
          onSave && onSave(values, form)
        }}
      >
        <div style={{ marginBottom: "20px" }} className="normal-14">
          {text}
        </div>
        {children}
      </Form>
    </BaseModal>
  )
}

EditFieldModal.propTypes = {
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  isVisible: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.any,
  text: PropTypes.string,
  okButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
}

export default ProfileData
