import React, { useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
// import GoogleLogin from "react-google-login"
//
import { resetDashState } from "../dashboard/DashboardSlice"
import {
  login,
  loginProvider,
  loginSelector,
  resetLoginState,
} from "./LoginSlice"
//
import { Col, Form, Row } from "antd"
import { BlueButton } from "../../common/components/Button"
import RoundedInput from "../../common/components/RoundedInput"
import RoundedInputPassword from "../../common/components/RoundedInputPassword"
import BaseModal from "../../common/components/Modal"
import ErrorMessage from "../../common/components/ErrorMessage"
import SliderBeforeLogin from "../../common/components/SliderBeforeLogin"
//
import logoImg from "../../common/assets/logo/propozall.svg"
import appleLogo from "../../common/assets/logo-btn-apple.png"
import googleLogo from "../../common/assets/logo-btn-google.png"
//
import StateStatus from "../../utils/stateStatus"
import PropTypes from "prop-types"
import "./styles.scss"
import { requestBasicProfile, requestRefreshToken } from "./LoginService"
import { validateRecaptcha } from "../../service/recaptcha"
import { useGoogleLogin } from "../../service/googleAuth"
import mixpanel from "../../mixPanel"

const Login = ({ hasCheckSession }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const loginState = useSelector(loginSelector)
  const googleauth = useGoogleLogin({
    clientId: process.env.REACT_APP_GOOGLE_ID,
    scope:
      "https://www.googleapis.com/auth/userinfo.email" +
      " https://www.googleapis.com/auth/userinfo.profile",
    onSuccess: (data) => {
      dispatch(
        loginProvider({
          accessToken: data.accessToken,
          email: data.profileObj.email,
          provider: "google",
        })
      )
    },
    onFailure: () => {
      console.log("FAILED LOGIN GOOGLE")
    },
  })

  //
  const [showErrorModal, updateShowErrorModal] = useState(false)

  const onFinish = (values) => {
    validateRecaptcha().then((token) => {
      values.recaptcha_token = token
      dispatch(login(values))
    })
  }

  // const responseGoogle = (data) => {
  //   if (data.accessToken) {
  //     dispatch(
  //       loginProvider({
  //         accessToken: data.accessToken,
  //         email: data.profileObj.email,
  //         provider: "google",
  //       })
  //     )
  //   }
  // }

  const loginApple = (response) => {
    const providerObj = {
      accessToken: response.authorization.id_token,
      provider: "apple",
      code: response.authorization.code,
    }

    dispatch(loginProvider(providerObj))
  }

  const checkIfExistsSession = async () => {
    dispatch(resetDashState())
    if (localStorage.getItem("refresh_token") && hasCheckSession) {
      try {
        await requestRefreshToken()
        await requestBasicProfile()
        history.replace("/dashboard")
      } catch (err) {
        localStorage.removeItem("access_token")
        localStorage.removeItem("refresh_token")
      }
    }
  }

  useEffect(() => {
    checkIfExistsSession()
  }, [])

  useEffect(() => {
    switch (loginState.status.login) {
      case StateStatus.succeeded: {
        dispatch(resetDashState())
        dispatch(resetLoginState())
        mixpanel.track("Sign in via APP")
        history.replace("/dashboard?check_profile=true")
        break
      }
      case StateStatus.failed: {
        if (
          loginState.provider &&
          loginState.notRegistered &&
          loginState.provider.accessToken
        ) {
          dispatch(resetLoginState())
          history.push({
            pathname: "/register",
            search: `?email=${loginState.provider?.email ?? ""}`,
            state: { provider: loginState.provider },
          })
        }

        updateShowErrorModal(true)
        break
      }
    }
  }, [loginState.status.login])

  return (
    <Row justify="center" className="login-container">
      <Col className="left-side">
        <SliderBeforeLogin />
      </Col>
      <Col className="right-side">
        <div>
          <div className="logo-container">
            <img
              src={logoImg}
              alt="Logo Propozall"
              className="logo"
              width="300"
            />
          </div>

          <div className="subtitle">Bem-vindo de volta</div>
          <Form onFinish={onFinish} layout="vertical">
            {loginState.status.login === StateStatus.failed && (
              <ErrorMessage
                text={loginState.errMessage}
                isVisible={loginState.errMessage !== null}
              />
            )}
            <Form.Item
              name="email"
              label="Email"
              initialValue=""
              rules={[
                {
                  required: true,
                  message: <div className="normal-12">Insira um email</div>,
                },
                {
                  type: "email",
                  message: <div className="normal-12">Email inválido</div>,
                },
              ]}
            >
              <RoundedInput
                placeholder="Email"
                // type="email"
                style={{ width: "314px" }}
              ></RoundedInput>
            </Form.Item>
            <Form.Item
              name="password"
              label="Senha"
              initialValue=""
              rules={[
                {
                  required: true,
                  message: <div className="normal-12">Digite uma senha</div>,
                },
              ]}
            >
              <RoundedInputPassword
                style={{ width: "314px" }}
                placeholder="Senha"
              ></RoundedInputPassword>
            </Form.Item>

            <Link to="/password/forgot">
              <div className="forgot-password">Esqueceu a senha?</div>
            </Link>

            <BlueButton
              isLoading={loginState.status.login === StateStatus.loading}
              type="submit"
              style={{ width: "314px", margin: "27px 0" }}
            >
              Entrar
            </BlueButton>
            <div className="apple-login">
              <BlueButton
                type="button"
                style={{ width: "314px", marginBottom: "27px" }}
                onClick={() =>
                  window.singInApple().then((response) => {
                    loginApple(response)
                  })
                }
              >
                <img
                  src={appleLogo}
                  style={{ marginRight: "20px" }}
                  width="18"
                />
                Entrar com a Apple
              </BlueButton>
            </div>
            <div className="google-login">
              <BlueButton
                id="google-login"
                type="button"
                style={{ width: "314px", marginBottom: "27px" }}
                onClick={() => googleauth.signIn()}
                disabled={!googleauth.loaded}
              >
                <img
                  src={googleLogo}
                  style={{ marginRight: "20px" }}
                  width="22"
                  height="22"
                />
                <span
                  style={{ padding: "10px 10px 10px 0px", fontWeight: 500 }}
                >
                  Entrar com o Google
                </span>
              </BlueButton>
              {/* <GoogleLogin
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                clientId={process.env.REACT_APP_GOOGLE_ID}
                buttonText="Entrar com o Google"
                cookiePolicy={"single_host_origin"}
                scope={
                  "https://www.googleapis.com/auth/userinfo.email " +
                  "https://www.googleapis.com/auth/userinfo.profile"
                }
              /> */}
            </div>
          </Form>

          <hr></hr>

          <div className="text-create-account">
            <span className="font-14">Você não possui uma conta? </span>
            <a href="https://propozall.com/planos/">
              <span className="link-14">Criar uma conta</span>
            </a>
          </div>
        </div>
      </Col>
      <BaseModal
        isVisible={showErrorModal && loginState.provider}
        title="Erro!"
        okButtonText="Ok"
        onCancel={() => {
          updateShowErrorModal(false)
        }}
        onOk={() => {
          updateShowErrorModal(false)
        }}
      >
        {loginState.errMessage}
      </BaseModal>
    </Row>
  )
}

Login.defaultProps = {
  hasCheckSession: true,
}

Login.propTypes = {
  hasCheckSession: PropTypes.bool,
}

export { Login }
