import React from "react"
import PropTypes from "prop-types"
import "./styles.scss"
import { Tooltip } from "../Tooltip"

const TagStatus = ({ status, children }) => {
  return (
    <Tooltip title={children}>
      <div className="tag-status not-overflow" data-status={status}>
        {children}
      </div>
    </Tooltip>
  )
}

TagStatus.propTypes = {
  status: PropTypes.string,
  children: PropTypes.string,
}

export default TagStatus
