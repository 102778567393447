import axios from "axios"
import api from "../../service/api"
import apiRoutes from "../../service/apiRoutes"

export const createAccount = (data) => api.post(apiRoutes.create_register, data)
export const updateAccount = (data) => api.put(apiRoutes.create_register, data)
//
export const address = (data) => api.get(apiRoutes.create_address, data)
export const createAddress = (data) => api.post(apiRoutes.create_address, data)
export const updateAddress = (id, data) =>
  api.put(`${apiRoutes.create_address}/${id}`, data)
//
export const createPayment = (data) => api.post(apiRoutes.create_payment, data)
export const subscription = () => api.get(apiRoutes.subscription)

export const checkCoupon = (coupon) =>
  api.get(`${apiRoutes.subscription}${apiRoutes.check_coupon}`, {
    params: { coupon },
  })
export const planDetails = () => api.get(apiRoutes.plans)
export const getAddressByCep = (cep) =>
  axios.get(`https://viacep.com.br/ws/${cep}/json/`)
