import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { getBytesAndDownloadFile } from "../../utils/files"
import StateStatus from "../../utils/stateStatus"
import {
  proposalGet,
  uploadInvoice,
  contractCreate,
  contractClose,
  getDefaultContract,
  getContractInfo,
  reviewProposal,
  fulfillProposal,
  contractReset,
  proposalDelete,
} from "./service"

export const getProposal = createAsyncThunk(
  "proposal-view/get",
  async (proposalId) => {
    const response = await proposalGet(proposalId)
    return response.data
  }
)

export const invoiceUpload = createAsyncThunk(
  "proposal-view/accept",
  async ({ proposalId, file }) => {
    const data = new FormData()
    data.append("file", file)
    const response = await uploadInvoice(proposalId, data)
    return response.data
  }
)

export const createContract = createAsyncThunk(
  "proposal-view/create_contract",
  async ({ proposalId, file }) => {
    const data = new FormData()
    if (file) {
      data.append("file", file)
    }

    const response = await contractCreate(proposalId, data)
    return response.data
  }
)

export const resetContract = createAsyncThunk(
  "proposal-view/reset_contract",
  async (contractId) => {
    const response = await contractReset(contractId)
    return response.data
  }
)

export const deleteOrCancelProposal = createAsyncThunk(
  "proposals/delete",
  async (id) => {
    const response = await proposalDelete(id)
    return response.data
  }
)

export const closeContract = createAsyncThunk(
  "proposal-view/close_contract",
  async (contractId) => {
    const response = await contractClose(contractId)
    return response.data
  }
)

export const proposalReview = createAsyncThunk(
  "proposal-view/review",
  async ({ proposalId, data }) => {
    const response = await reviewProposal(proposalId, data)
    return response.data
  }
)

export const downloadDefaultContract = createAsyncThunk(
  "proposal-view/download_default_contract",
  async (proposalId) => {
    const response = await getDefaultContract(proposalId)
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", "contrato.doc")
    link.setAttribute("target", "__blank")
    document.body.appendChild(link)
    link.click()
    link.remove()

    return response.data
  }
)

export const downloadContract = createAsyncThunk(
  "proposal-view/download_contract",
  async ({ contractId, doc }) => {
    const response = await getContractInfo(contractId)

    let url
    let name
    if (doc === "original") {
      url = response.data.data.original_file_public_url
      name = "contrato-original.pdf"
    } else {
      url = response.data.data.signed_file_public_url
      name = "contrato-assinado.pdf"
    }

    const link = await getBytesAndDownloadFile(url, "application/pdf", name)
    link.click()
    return response.data
  }
)

export const proposalFulfill = createAsyncThunk(
  "proposal_accept/fulfill",
  async (proposalId) => {
    const response = await fulfillProposal(proposalId)
    return response.data
  }
)

const initialState = {
  isAccountConfirmed: true,
  status: {
    getProposal: StateStatus.idle,
    createContract: StateStatus.idle,
    closeContract: StateStatus.idle,
    resetContract: StateStatus.idle,
    downloadDefaultContract: StateStatus.idle,
    downloadContract: StateStatus.idle,
    proposalFulfill: StateStatus.idle,
    proposalReview: StateStatus.idle,
    deleteOrCancelProposal: StateStatus.idle,
  },
  data: {
    proposal: null,
  },
  errMessage: null,
}

export const proposalViewSlice = createSlice({
  name: "proposal-view",
  initialState,
  reducers: {
    resetStepsState: (state, action) => {
      state.status.closeContract = StateStatus.idle
      state.status.createContract = StateStatus.idle
      state.status.proposalReview = StateStatus.idle
      state.status.proposalFulfill = StateStatus.idle
      state.status.deleteOrCancelProposal = StateStatus.idle
    },
  },
  extraReducers: {
    [getProposal.pending]: (state, action) => {
      state.status.getProposal = StateStatus.loading
      state.isAccountConfirmed = true
    },
    [getProposal.fulfilled]: (state, action) => {
      state.data.proposal = action.payload.data
      state.status.getProposal = StateStatus.succeeded
    },
    [getProposal.rejected]: (state, action) => {
      state.data.proposal = null
      state.status.getProposal = StateStatus.failed
      state.errMessage = action.error.message
      if (action.error.code === "NOT_CONFIRMED_ACCOUNT") {
        state.isAccountConfirmed = false
      } else {
        state.isAccountConfirmed = true
      }
    },
    // proposalReview
    [proposalReview.pending]: (state, action) => {
      state.status.proposalReview = StateStatus.loading
    },
    [proposalReview.fulfilled]: (state, action) => {
      state.data.proposal = action.payload.data
      state.status.proposalReview = StateStatus.succeeded
    },
    [proposalReview.rejected]: (state, action) => {
      state.errMessage = action.error.message
      state.status.proposalReview = StateStatus.failed
    },
    // createContract
    [createContract.pending]: (state) => {
      state.status.createContract = StateStatus.loading
    },
    [createContract.fulfilled]: (state, action) => {
      state.status.createContract = StateStatus.succeeded
    },
    [createContract.rejected]: (state, action) => {
      state.status.createContract = StateStatus.failed
      state.errMessage = action.error.message
    },
    // closeContract
    [closeContract.pending]: (state) => {
      state.status.closeContract = StateStatus.loading
    },
    [closeContract.fulfilled]: (state, action) => {
      state.status.closeContract = StateStatus.succeeded
    },
    [closeContract.rejected]: (state, action) => {
      state.status.closeContract = StateStatus.failed
      state.errMessage = action.error.message
    },
    // resetContract
    [resetContract.pending]: (state) => {
      state.status.resetContract = StateStatus.loading
    },
    [resetContract.fulfilled]: (state, action) => {
      state.status.resetContract = StateStatus.succeeded
    },
    [resetContract.rejected]: (state, action) => {
      state.status.resetContract = StateStatus.failed
      state.errMessage = action.error.message
    },
    // downloadDefaultContract
    [downloadDefaultContract.pending]: (state) => {
      state.status.downloadDefaultContract = StateStatus.loading
    },
    [downloadDefaultContract.fulfilled]: (state, action) => {
      state.status.downloadDefaultContract = StateStatus.succeeded
    },
    [downloadDefaultContract.rejected]: (state, action) => {
      state.status.downloadDefaultContract = StateStatus.failed
      state.errMessage = action.error.message
    },
    // downloadContract
    [downloadContract.pending]: (state) => {
      state.status.downloadContract = StateStatus.loading
    },
    [downloadContract.fulfilled]: (state, action) => {
      state.status.downloadContract = StateStatus.succeeded
    },
    [downloadContract.rejected]: (state, action) => {
      state.status.downloadContract = StateStatus.failed
      state.errMessage = action.error.message
    },
    // proposalFulfill
    [proposalFulfill.pending]: (state, action) => {
      state.status.proposalFulfill = StateStatus.loading
    },
    [proposalFulfill.fulfilled]: (state, action) => {
      state.status.proposalFulfill = StateStatus.succeeded
      state.status.proposalGet = StateStatus.idle
    },
    [proposalFulfill.rejected]: (state, action) => {
      state.status.proposalFulfill = StateStatus.failed
      state.errMessage = "Não foi possível finalizar proposta."
    },
    // deleteOrCancelProposal
    [deleteOrCancelProposal.pending]: (state, action) => {
      state.status.deleteOrCancelProposal = StateStatus.loading
    },
    [deleteOrCancelProposal.fulfilled]: (state, action) => {
      state.status.deleteOrCancelProposal = StateStatus.succeeded
      state.status.proposalGet = StateStatus.idle
    },
    [deleteOrCancelProposal.rejected]: (state, action) => {
      state.status.deleteOrCancelProposal = StateStatus.failed
      state.errMessage = "Não foi possível cancelar ou excluir a proposta."
    },
  },
})

export const { resetStepsState } = proposalViewSlice.actions
export const selectProposalViewState = (state) => state.proposalView
