import api from "../../service/api"
import apiCounterparty from "../../service/apiCounterparty"
import apiRoutes from "../../service/apiRoutes"

export const getContract = (contractId) =>
  api.get(`${apiRoutes.contractView}/${contractId}`)

export const deleteSigner = (contractId, id) =>
  api.delete(`${apiRoutes.contractView}/${contractId}/signer/${id}`)

export const createSigner = (contractId, data) =>
  api.post(`${apiRoutes.contractView}/${contractId}/signer`, data)

export const signerEdit = (contractId, id, data) =>
  api.put(`${apiRoutes.contractView}/${contractId}/signer/${id}`, data)

export const getContractByCounterparty = (contractId) =>
  apiCounterparty.get(`${apiRoutes.contractView}/${contractId}`)

export const deleteSignerByCounterparty = (contractId, id) =>
  apiCounterparty.delete(`${apiRoutes.contractView}/${contractId}/signer/${id}`)

export const createSignerByCounterparty = (contractId, data) =>
  apiCounterparty.post(`${apiRoutes.contractView}/${contractId}/signer`, data)

export const signerEditByCounterparty = (contractId, id, data) =>
  apiCounterparty.put(
    `${apiRoutes.contractView}/${contractId}/signer/${id}`,
    data
  )
