import React, { useEffect, useState } from "react"
import { useParams, useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { getContract, selectContractViewState } from "./contractViewSlice"
//
import { Col, Row, Tooltip } from "antd"
import Icons from "../../common/components/Icons"
import Loading from "../../common/components/Loading"
import Avatar from "../../common/components/avatar"
import RoudedBox from "../../common/components/RoudedBox"
import DownloadContractModal from "../listProposals/components/dowloadContratoModal"
//
import "./styles.scss"
import StateStatus from "../../utils/stateStatus"
import { beautifyCpfCnpj } from "../../utils/formaters"
import ContractStatus from "../../common/components/ContractStatus"

const ContractView = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const contractViewState = useSelector(selectContractViewState)
  const { contract } = contractViewState.data
  const [showModal, updateShowModal] = useState(false)

  useEffect(() => {
    dispatch(getContract({ id }))
  }, [id])

  return contractViewState.status.getContract === StateStatus.loading ||
    !contract ? (
    <Loading />
  ) : (
    <div className="root-container contract-view-page">
      <div
        className="back-page-btn"
        onClick={() => history.replace("/dashboard/contract-history")}
      >
        <Icons icon="arrow-right" />
        <span>Voltar</span>
      </div>

      <Row align="center" justify="space-between">
        <h1 className="normal-24-500">Ver Assinaturas </h1>
      </Row>
      <Row>
        <p className="normal-14">
          Confira o histórico de assinaturas do contrato
        </p>
      </Row>
      <br />

      {contractViewState.status.getContract === StateStatus.failed && (
        <Row justify="center">
          <p>Não foi possível ver o contrato.</p>
        </Row>
      )}

      {contractViewState.status.getContract === StateStatus.succeeded && (
        <>
          {/* USER */}
          <Row
            className="contract-info-bar"
            justify="space-between"
            align="bottom"
          >
            <Col className="counterparty-info">
              {contract.proposal.role === "CONTRACTOR" ? (
                <Row align="middle">
                  <Avatar src={contract.proposal.contracted.avatar_url} />
                  <span>{contract.proposal.contracted.name}</span>
                </Row>
              ) : (
                <Row align="middle">
                  <Avatar src={contract.proposal.contractor.avatar_url} />
                  <span>{contract.proposal.contractor.name}</span>
                </Row>
              )}
            </Col>
            <Col>
              <ContractStatus status={contract.status}>
                {contract.status_name}
              </ContractStatus>
            </Col>
          </Row>

          {/* SLA */}
          <div className="contract-info">
            <Row gutter={[16, 16]}>
              <Col>
                <p className="normal-16-500">
                  Contrato.propozallsa.{contract.hash_version}.pdf
                </p>
              </Col>
              <Col>
                <Icons
                  icon="show"
                  title="Ver pdf"
                  onClick={() => {
                    updateShowModal("show-contract")
                  }}
                />
              </Col>
              <Col>
                <Icons
                  icon="download"
                  title="Baixar"
                  onClick={() => {
                    updateShowModal("download-contract")
                  }}
                />
              </Col>
            </Row>
            <Row>
              <p className="normal-14">#{contract.hash_version}</p>
            </Row>
          </div>

          {/* TABLE */}
          <div className="signers">
            <Row gutter={[24, 0]} style={{ padding: "10px 24px" }}>
              <Col span={4}>NOME</Col>
              <Col span={4}>EMPRESA</Col>
              <Col span={4}>QUEM ASSINA</Col>
              <Col span={5}>E-MAIL</Col>
              <Col span={4}>CPF</Col>
              <Col span={3}>ASSINATURA</Col>
            </Row>

            {contract.signers &&
              contract.signers.map((item, key) => {
                return (
                  <RoudedBox className="simple-table" key={key}>
                    <Row gutter={[24, 0]}>
                      <Col span={4}>
                        <div>{item.name || " - "}</div>
                      </Col>
                      <Col span={4} className="">
                        <div>
                          {item.proposal_role === "CONTRACTOR"
                            ? contract.proposal.contractor.company_name
                            : contract.proposal.contracted.company_name}
                        </div>
                      </Col>
                      <Col span={4} className="">
                        {item.signer_role_name === "Contratante" ? (
                          <div>
                            <Tooltip title="quem paga e recebe o serviço">
                              {item.signer_role_name}
                            </Tooltip>
                          </div>
                        ) : (
                          <div>
                            <Tooltip title="quem recebe e faz o serviço">
                              {item.signer_role_name}
                            </Tooltip>
                          </div>
                        )}
                      </Col>
                      <Col span={5} className="">
                        <div>{item.email}</div>
                      </Col>
                      <Col span={4} className="">
                        <div>
                          {item.cpf ? beautifyCpfCnpj(item.cpf) : " - "}
                        </div>
                      </Col>
                      <Col span={3} className="">
                        <div>{!item.signed_at ? "Pendente" : "Assinou"}</div>
                      </Col>
                    </Row>
                  </RoudedBox>
                )
              })}
          </div>

          {/* EVENTS */}
          <div className="contract-events">
            <h2 className="title-events">Atividades</h2>
            <div className="events">
              {contract.logs &&
                contract.logs.map((item, key) => {
                  return (
                    <Row align="center" justify="space-between" key={key}>
                      <Col span={12}>{item.inserted_at}</Col>

                      <Col span={12}>{item.description}</Col>
                    </Row>
                  )
                })}
            </div>
          </div>

          <DownloadContractModal
            contractId={contract.id}
            hasSignedContract={!!contract.signed_file_public_url}
            isShowContract={showModal === "show-contract"}
            isVisible={
              showModal === "download-contract" || showModal === "show-contract"
            }
            onClose={() => updateShowModal(null)}
          />
        </>
      )}
    </div>
  )
}

export default ContractView
