import React, { useEffect, useState } from "react" //    useState
import PropTypes from "prop-types"
import "../boxUserRoleModal/styles.scss"
import Avatar from "../../../../common/components/avatar"
import { ConfigurationButton } from "../../Topbar"
import { Divider, Progress, Row, Tooltip } from "antd"
import Icons from "../../../../common/components/Icons"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { selectProfileData } from "../../../configurations/configurationsSlice"
import { useDispatch, useSelector } from "react-redux"
import { logout } from "../../../login/LoginSlice"
import { updateMode } from "../../../dashboard/DashboardSlice"

// const body = document.body

const BoxUserRoleModal = ({ user, email, role, onClose }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const profileData = useSelector(selectProfileData)
  const [showRole, updateShowRole] = useState(false)
  const isPlanStarter = profileData.plan_identifier === "starter"
  const roleName = role === "contracted" ? "Contratado" : "Contratante"

  useEffect(() => {
    document.body.style.overflow = "hidden"
  })

  const colorStatusMap = (proposals, maxProposals) => {
    const pct = proposals / maxProposals
    if (isPlanStarter && pct < 0.5) {
      return { light: "#2656D8", dark: "#0700CF" }
    } else if (isPlanStarter && pct >= 0.5 && pct < 1) {
      return { light: "#F7B205", dark: "#B38104" }
    } else if (pct < 0.8) {
      return { light: "#2656D8", dark: "#0700CF" }
    } else if (pct >= 0.8 && pct < 1) {
      return { light: "#F7B205", dark: "#B38104" }
    } else {
      return { light: "#CF4544", dark: "#AD3A39" }
    }
  }

  const colorBackgroundMap = (proposals, maxProposals) => {
    const pct = proposals / maxProposals
    if (isPlanStarter && pct < 0.5) {
      return { light: "#D9E1F8", dark: "#0700CF" }
    } else if (isPlanStarter && pct >= 0.5 && pct < 1) {
      return { light: "#FFFAF0", dark: "#B38104" }
    } else if (pct < 0.8) {
      return { light: "#D9E1F8", dark: "#0700CF" }
    } else if (pct >= 0.8 && pct < 1) {
      return { light: "#FFFAF0", dark: "#B38104" }
    } else {
      return { light: "#F0E8E8", dark: "#AD3A39" }
    }
  }
  const color = colorStatusMap(
    profileData.count_active_proposals,
    profileData.proposals_limit ?? Infinity
  )

  const colorBackgroundBoxPlanUse = colorBackgroundMap(
    profileData.count_active_proposals,
    profileData.proposals_limit ?? Infinity
  )

  const onCloseModal = () => {
    document.body.style.overflow = "auto"
    onClose && onClose()
  }

  return (
    <div className="box-user-modal" data-show-role-active={showRole}>
      <div className="back-modal" onClick={onCloseModal}></div>
      <div className="box-user-content">
        <div className="box-style-profile" style={{ cursor: "auto" }}>
          <Row justify="flex-start">
            <span className="user-avatar">
              <Avatar />
            </span>
            <div className="box-style-text">
              <div
                className="normal-14-500"
                style={{
                  paddingLeft: "13px",
                }}
              >
                {user}
              </div>
              <div
                className="style-user-email"
                style={{
                  paddingLeft: "13px",
                }}
              >
                {email}
              </div>
            </div>
          </Row>
        </div>
        <div
          className="box-role-style"
          data-active={showRole}
          onClick={() => updateShowRole(!showRole)}
        >
          <Row style={{ width: "100%" }}>
            {role === "contracted" ? (
              <div className="role-icon">
                <Icons icon={"suitcase"} />
              </div>
            ) : (
              <div className="role-icon">
                <Icons icon={"interpriseIcon"} />
              </div>
            )}

            <div
              className="normal-14-500"
              style={{
                paddingLeft: "26px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {roleName === "Contratante" ? (
                <div>
                  <Tooltip
                    title="quem paga e recebe o serviço"
                    placement="bottomLeft"
                  >
                    Perfil {roleName}
                  </Tooltip>
                </div>
              ) : (
                <div>
                  <Tooltip
                    title="quem recebe e faz o serviço"
                    placement="bottomLeft"
                  >
                    Perfil {roleName}
                  </Tooltip>
                </div>
              )}
            </div>
            <div className="arrow-role-icon">
              {showRole ? (
                <Icons icon={"outline-arrow-top"} />
              ) : (
                <Icons icon={"outline-arrow-down"} />
              )}
            </div>
          </Row>
        </div>
        {showRole ? (
          <div
            className="box-role-options"
            onClick={() => {
              document.body.style.overflow = "auto"
              dispatch(
                updateMode({
                  mode: role === "contracted" ? "contractor" : "contracted",
                })
              )
            }}
          >
            <Row style={{ width: "100%" }}>
              {role !== "contracted" ? (
                <div className="role-icon">
                  <Icons icon={"suitcase"} />
                </div>
              ) : (
                <div className="role-icon">
                  <Icons icon={"interpriseIcon"} />
                </div>
              )}
              <div
                className="normal-14-500"
                style={{
                  paddingLeft: "26px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {role === "contracted" ? (
                  <div>
                    <Tooltip
                      title="quem paga e recebe o serviço"
                      placement="bottomLeft"
                    >
                      {"Perfil Contratante"}
                    </Tooltip>
                  </div>
                ) : (
                  <div>
                    <Tooltip
                      title="quem recebe e faz o serviço"
                      placement="bottomLeft"
                    >
                      {"Perfil Contratado"}
                    </Tooltip>
                  </div>
                )}
              </div>
            </Row>
          </div>
        ) : null}
        <div
          className="box-config-style"
          onClick={() => history.push("/dashboard/config")}
        >
          <Row>
            <ConfigurationButton />
            <span className="normal-14-500">Configurações</span>
          </Row>
        </div>
        <div
          className="plan-use-box"
          style={{ backgroundColor: colorBackgroundBoxPlanUse.light }}
        >
          <Row style={{ width: "230px" }} justify="start">
            <span className="normal-16-700">Plano {profileData.plan_name}</span>
          </Row>
          <div className="normal-12" style={{ padding: "6px 0" }}>
            Você usou:
          </div>
          <Progress
            percent={
              (profileData.count_active_proposals /
                profileData.proposals_limit ?? Infinity) * 100
            }
            showInfo={false}
            strokeColor={color.light}
            strokeWidth="16px"
          />
          <div className="plan-usage-box" style={{ paddingTop: "8px" }}>
            {profileData.proposals_limit ? (
              <>
                <span style={{ color: color.dark }} className="plan-use-usage">
                  {profileData.count_active_proposals} de{" "}
                  {profileData.proposals_limit}
                </span>
                <span className="plan-use-usage-description">
                  {profileData.count_active_proposals > 1
                    ? "propostas aceitas usadas"
                    : "proposta aceita usada"}
                </span>
              </>
            ) : (
              <span style={{ color: color.dark }} className="plan-use-usage">
                Ilimitado
              </span>
            )}
            <div
              className="box-plan-upgrade"
              style={{
                display: "flex",
                alignItems: "baseline",
                cursor: "pointer",
              }}
              onClick={() =>
                history.push("/dashboard/config?tab=4&update=plan")
              }
            >
              <div
                className="normal-14-500 plan-upgrade"
                style={{ paddingTop: "25px" }}
              >
                Aumentar meu limite
                <span
                  className="arrow-right-icon"
                  style={{
                    paddingLeft: "10px",
                    position: "relative",
                    top: "2px",
                  }}
                >
                  <Icons icon={"longArrowRight"} />
                </span>
              </div>
            </div>
          </div>
        </div>
        <Divider className="divider-style" />
        <div
          className="exit-box"
          onClick={() =>
            dispatch(logout()).then(() => {
              history.replace("/")
            })
          }
        >
          <Row
            style={{ width: "100%", display: "flex", alignItems: "flex-start" }}
          >
            <div className="exit-icon">
              <Icons icon={"roundedExitIcon"} />
            </div>
            <div className="normal-14-500" style={{ paddingLeft: "16px" }}>
              Sair
            </div>
          </Row>
        </div>
      </div>
    </div>
  )
}
BoxUserRoleModal.defaultProps = {
  isVisible: true,
}
BoxUserRoleModal.propTypes = {
  user: PropTypes.string,
  role: PropTypes.string,
  email: PropTypes.string,
  onClose: PropTypes.func,
}

export default BoxUserRoleModal
