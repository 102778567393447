import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import {
  proposals,
  deleteNfe,
  nfses,
  exportNfse,
  getExportNfseList,
} from "./nfseService"
//
import StateStatus from "../../utils/stateStatus"

export const listNfse = createAsyncThunk("nfse/list", async (data) => {
  const { role, page } = data

  delete data.page

  const response = await nfses(page, {
    role,
    ...data,
  })

  return {
    page: response.data.data.current_page,
    count: response.data.data.count,
    items: response.data.data.items,
    totalPages: response.data.data.total_pages,
  }
})

export const removeNfse = createAsyncThunk("nfse/list/remove", async (id) => {
  const response = await deleteNfe(id)

  return { id, ...response.data }
})

export const getProposals = createAsyncThunk(
  "nfse/proposals",
  async ({ role }) => {
    const response = await proposals({
      role,
    })

    return {
      items: response.data.data.items,
    }
  }
)

export const exportingNfse = createAsyncThunk(
  "nfse/export-zip",
  async (data) => {
    try {
      const response = await exportNfse(data)
      return response.data
    } catch (err) {
      let message = err.data.message ?? "Não foi possível solicitar exportação."

      if (err.code === "NFSE_NOT_FOUND") {
        message = "Nenhuma nota fiscal foi encontrada no período informado."
      }

      const customError = {
        message,
        name: "Api Error",
        data: err.data,
      }
      throw customError
    }
  }
)

export const getExportingNfse = createAsyncThunk(
  "nfse/list-zip-files",
  async (data) => {
    const response = await getExportNfseList(data)

    return {
      items: response.data.data.items,
    }
  }
)

const initialState = {
  status: {
    listNfse: StateStatus.idle,
    removeNfse: StateStatus.idle,
    getProposals: StateStatus.idle,
    exportingNfse: StateStatus.idle,
    getExportingNfse: StateStatus.idle,
  },
  // list
  page: 0,
  count: 0,
  list: [],
  totalPages: 0,
  // utils
  proposals: [],
  errMessage: "",
  getExportingNfse: [],
}

export const nfseSlice = createSlice({
  name: "nfse",
  initialState,
  reducers: {
    resetAll: (state) => {
      state.list = []
      state.page = 0
      state.count = 0
      state.totalPages = 0
      state.getExportingNfse = []
      //
      state.status.listNfse = StateStatus.idle
      state.status.getProposals = StateStatus.idle
      state.status.getExportingNfse = StateStatus.idle
      state.status.exportingNfse = StateStatus.idle
    },
    resetNFSEeToExportStatus: (state) => {
      state.status.exportingNfse = StateStatus.idle
    },
  },
  extraReducers: {
    [listNfse.pending]: (state, action) => {
      state.page = action.meta.arg.page
      state.status.listNfse = StateStatus.loading
    },
    [listNfse.fulfilled]: (state, action) => {
      const { items, totalPages, page, count } = action.payload

      if (page <= 1) {
        state.list = [...items]
      } else {
        state.list = [...state.list, ...items]
      }

      state.page = page
      state.count = count
      state.totalPages = totalPages
      state.status.listNfse = StateStatus.succeeded
    },
    [listNfse.rejected]: (state, action) => {
      state.errMessage =
        action.error.message ?? "Não foi possível listar as notas fiscais."
      state.status.listNfse = StateStatus.failed
    },
    // ---------
    [getProposals.pending]: (state) => {
      state.status.getProposals = StateStatus.loading
    },
    [getProposals.fulfilled]: (state, action) => {
      state.proposals = action.payload.items
      state.status.getProposals = StateStatus.succeeded
    },
    [getProposals.rejected]: (state, action) => {
      state.errMessage =
        action.error.message ?? "Não foi possível listar as notas fiscais."
      state.status.getProposals = StateStatus.failed
    },
    // ---------
    [removeNfse.pending]: (state) => {
      state.status.removeNfse = StateStatus.loading
    },
    [removeNfse.fulfilled]: (state, action) => {
      state.list = state.list.filter((item) => item.id !== action.payload.id)

      state.status.removeNfse = StateStatus.succeeded
    },
    [removeNfse.rejected]: (state, action) => {
      state.errMessage =
        action.error.message ?? "Não foi possível listar as notas fiscais."
      state.status.removeNfse = StateStatus.failed
    },
    // --------
    [exportingNfse.pending]: (state) => {
      state.status.exportingNfse = StateStatus.loading
    },
    [exportingNfse.fulfilled]: (state, action) => {
      state.status.exportingNfse = StateStatus.succeeded
    },
    [exportingNfse.rejected]: (state, action) => {
      state.errMessage = action.error.message
      state.status.exportingNfse = StateStatus.failed
    },
    // --------
    [getExportingNfse.pending]: (state) => {
      state.status.getExportingNfse = StateStatus.loading
    },
    [getExportingNfse.fulfilled]: (state, action) => {
      const list = action.payload.items.filter(
        (item) => localStorage.getItem(item.id) !== "showed"
      )
      state.getExportingNfse = list
      state.status.getExportingNfse = StateStatus.succeeded
    },
    [getExportingNfse.rejected]: (state, action) => {
      state.errMessage =
        action.error.message ?? "Não foi possível listar o download."
      state.status.getExportingNfse = StateStatus.failed
    },
    // --------
  },
})

export const { resetAll, resetNFSEeToExportStatus } = nfseSlice.actions

export const selectNfse = (state) => state.nfse
