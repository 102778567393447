import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import StateStatus from "../../utils/stateStatus"
import { editCompany, addCompany, companyGet } from "./companyService"

export const getCompany = createAsyncThunk("company/get", async (companyId) => {
  const response = await companyGet(companyId)
  return response.data
})

export const updateCompany = createAsyncThunk(
  "company/edit",
  async ({ companyId, data }) => {
    const response = await editCompany(companyId, data)
    return response.data
  }
)

export const createCompany = createAsyncThunk(
  "company/create",
  async (data) => {
    const response = await addCompany(data)
    return response.data
  }
)

const initialState = {
  status: {
    saveCompany: StateStatus.idle,
    getCompany: StateStatus.idle,
  },
  data: {},
  sectors: [],
  errMessage: null,
}

export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    resetCompanyStatus: (state, action) => {
      state.status.saveCompany = StateStatus.idle
    },
  },
  extraReducers: {
    [createCompany.pending]: (state) => {
      state.status.saveCompany = StateStatus.loading
    },
    [createCompany.fulfilled]: (state, action) => {
      state.status.saveCompany = StateStatus.succeeded
      state.data = action.payload.data
    },
    [createCompany.rejected]: (state, action) => {
      state.status.saveCompany = StateStatus.failed
      state.errMessage = action.error.message
    },
    [updateCompany.pending]: (state) => {
      state.status.saveCompany = StateStatus.loading
    },
    [updateCompany.fulfilled]: (state, action) => {
      state.status.saveCompany = StateStatus.succeeded
      state.data = action.payload.data
    },
    [updateCompany.rejected]: (state, action) => {
      state.status.saveCompany = StateStatus.failed
      state.errMessage = action.error.message
    },
    [getCompany.pending]: (state) => {
      state.status.getCompany = StateStatus.loading
    },
    [getCompany.fulfilled]: (state, action) => {
      state.status.getCompany = StateStatus.succeeded
      state.data = action.payload.data
    },
    [getCompany.rejected]: (state, action) => {
      state.status.getCompany = StateStatus.failed
      state.errMessage = action.error.message
    },
  },
})

export const { resetCompanyStatus } = companySlice.actions

export const selectCompanyState = (state) => state.company
