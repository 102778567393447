import React, { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { useQuery } from "../../utils/query"
import { LogoBar } from "../topbar/Topbar"
import { Row, Form } from "antd"
import { useDispatch, useSelector } from "react-redux"
import {
  proposalFulfill,
  proposalGet,
  selectProposalAcceptState,
  confirmAccessCode,
  sendCodeToConfirm,
  proposalReview,
  resetConfirmAccess,
  resetReviewState,
  downloadContract,
} from "./proposalAcceptSlice"
import StateStatus from "../../utils/stateStatus"
import BaseModal from "../../common/components/Modal"
import Loading from "../../common/components/Loading"
import ProposalDetails from "../../common/components/ProposalDetails"
import "./styles.scss"
import FlattedInput from "../../common/components/FlattedInput"
import SignersModal from "../SignersModal"

export const ProposalAccept = () => {
  const { id } = useParams()
  const query = useQuery()
  const [formConfirmCode] = Form.useForm()
  const [showModal, updateShowModal] = useState({})
  const [emailCounterparty, updateEmailCounterparty] = useState()
  const proposalState = useSelector(selectProposalAcceptState)
  const proposal = proposalState.proposal
  const dispatch = useDispatch()
  const history = useHistory()
  const code = query.get("code")
  const token = query.get("token")
  const refreshToken = query.get("refresh_token")

  if (token && refreshToken) {
    localStorage.setItem("access_token_counterparty", token)
    localStorage.setItem("refresh_token_counterparty", refreshToken)
  }

  useEffect(() => {
    dispatch(resetReviewState())
    dispatch(resetConfirmAccess())

    if (code) {
      dispatch(confirmAccessCode({ proposalId: id, data: { code } }))
    } else {
      dispatch(proposalGet(id))
    }
    document.body.style.overflow = "auto"
  }, [token, refreshToken, code])

  useEffect(() => {
    if (proposalState.status.proposalGet === StateStatus.failed) {
      updateShowModal({ modal: "send-code-modal" })
    }

    if (proposalState.status.proposalGet === StateStatus.succeeded) {
      const email =
        proposal.role === "CONTRACTOR"
          ? proposal.contractor.email
          : proposal.contracted.email

      updateEmailCounterparty(email)
    }
  }, [proposalState.status.proposalGet])

  useEffect(() => {
    if (proposalState.status.proposalReview === StateStatus.succeeded) {
      dispatch(proposalGet(id))
      dispatch(resetReviewState())

      if (proposal.status === "accepted") {
        updateShowModal({
          modal: "generic",
          title: "Proposta Aceita!",
          type: "success",
          message: emailCounterparty
            ? `Aguarde o contrato ser enviado, quando disponível 
          ele será enviado para o e-mail ${emailCounterparty}.`
            : "Aguarde os proxímos passos, o dono da proposta já foi " +
              "informado sobre o aceite da proposta.",
          redirect: false,
        })
      } else if (proposal.status === "refused") {
        updateShowModal({
          modal: "generic",
          title: "Proposta recusada!",
          message: "Você recusou a proposta com sucesso.",
        })
      }
    }

    if (proposalState.status.proposalReview === StateStatus.failed) {
      updateShowModal({
        modal: "generic",
        title: "Erro!",
        message: proposalState.errMessage,
        redirect: false,
      })
      dispatch(resetReviewState())
    }

    document.body.style.overflow = "auto"
  }, [proposalState.status.proposalReview])

  useEffect(() => {
    if (proposalState.status.sendCodeToConfirm === StateStatus.succeeded) {
      updateShowModal({ modal: "insert-code-modal" })
      dispatch(resetConfirmAccess())
    }

    if (proposalState.status.sendCodeToConfirm === StateStatus.failed) {
      updateShowModal({})
      updateShowModal({
        modal: "generic",
        title: "Erro!",
        message: "Erro ao enviar email.",
        redirect: false,
      })
      dispatch(resetConfirmAccess())
    }
  }, [proposalState.status.sendCodeToConfirm])

  useEffect(() => {
    if (proposalState.status.confirmAccessCode === StateStatus.succeeded) {
      updateShowModal({})
      setTimeout(() => {
        history.replace(proposalState.redirectTo)
      }, 1000) // delay para aguardar setar o access token no local storage
      dispatch(resetConfirmAccess())
    }

    if (proposalState.status.confirmAccessCode === StateStatus.failed) {
      formConfirmCode.setFields([{ name: "code", errors: ["Código inválido"] }])
      dispatch(resetConfirmAccess())
    }
  }, [proposalState.status.confirmAccessCode])

  return (
    <>
      <div className="body-container">
        <div className="top-bar" style={{ padding: 0 }}>
          <LogoBar />
        </div>
        <div className="content proposal-accept">
          {proposalState.status.proposalGet === StateStatus.loading ? (
            <div style={{ height: "60vh" }}>
              <Loading />
            </div>
          ) : proposalState.status.proposalGet === StateStatus.succeeded ? (
            <Row justify="center">
              <section>
                <ProposalDetails
                  proposal={proposal}
                  isLoading={
                    proposalState.status.proposalReview ===
                      StateStatus.loading ||
                    proposalState.status.proposalFulfill === StateStatus.loading
                  }
                  onAcceptProposal={(values) => {
                    let counterparty = {}
                    if (proposal.created_by === "CONTRACTOR") {
                      counterparty = proposal.contracted
                    } else {
                      counterparty = proposal.contractor
                    }

                    const data = {
                      counterparty: {
                        cpf_cnpj: values.cpf_cnpj,
                        company_name: counterparty.company_name,
                        name: counterparty.name,
                        email: values.email,
                        phone: values.phone,
                        sector: counterparty.sector,
                        address: counterparty.address,
                      },
                      action: "accept",
                    }

                    if (proposal.created_by === "CONTRACTOR") {
                      if (counterparty.bank_account) {
                        const bankAcc = counterparty.bank_account
                        data.counterparty.bank_account = {
                          bank_code: bankAcc.bank_code,
                          account_type: bankAcc.account_type,
                          agency: bankAcc.agency,
                          account_number: bankAcc.account_number,
                          pix_key: bankAcc.pix_key,
                          owner_name: bankAcc.owner_name,
                          cpf_cnpj: bankAcc.cpf_cnpj,
                        }
                      }
                      data.counterparty.social_medias =
                        counterparty.social_medias
                    }

                    dispatch(proposalReview({ proposalId: id, data }))
                  }}
                  onRefuseProposal={(values) => {
                    const data = {
                      review_message: values.review_message,
                      action: "refuse",
                    }

                    dispatch(proposalReview({ proposalId: id, data }))
                  }}
                  onFinalizeProposal={() => {
                    dispatch(proposalFulfill(id))
                  }}
                  onDownloadContract={(doc) => {
                    dispatch(
                      downloadContract({
                        contractId: proposal.contract_id,
                        doc,
                      })
                    )
                  }}
                  onAddSigners={() =>
                    updateShowModal({ modal: "add-signers-modal" })
                  }
                />
              </section>
            </Row>
          ) : null}
        </div>
      </div>
      {proposal?.contract_id && (
        <SignersModal
          isPublicPage={true}
          contractId={proposal.contract_id}
          isVisible={showModal?.modal === "add-signers-modal"}
          onClose={() => {
            dispatch(resetReviewState())
            dispatch(resetConfirmAccess())
            dispatch(proposalGet(id))
            updateShowModal({})
          }}
        />
      )}
      <BaseModal
        isVisible={showModal.modal === "generic"}
        title={showModal.title}
        type={showModal.type || "general"}
        okButtonText={showModal.okButtonText || "Fechar janela"}
        onClose={() => {
          if (showModal.redirect) history.push(showModal.redirect)
          updateShowModal({})
        }}
        onOk={() => {
          if (showModal.redirect) history.push(showModal.redirect)
          updateShowModal({})
        }}
      >
        {showModal.message}
      </BaseModal>
      <BaseModal
        isVisible={showModal?.modal === "send-code-modal"}
        title={"Código de confirmação"}
        isLoading={
          proposalState.status.sendCodeToConfirm === StateStatus.loading
        }
        okButtonText="Enviar Código"
        cancelButtonText="Cancelar"
        onCancel={() => {
          updateShowModal({})
          history.push("/")
        }}
        onOk={() => {
          dispatch(sendCodeToConfirm(id))
        }}
      >
        Um código de confirmação será enviado para o <span>e-mail</span> do{" "}
        <b>contratante</b>.
      </BaseModal>
      <BaseModal
        isVisible={showModal?.modal === "insert-code-modal"}
        title={"Código de confirmação"}
        okButtonText="Confirmar"
        cancelButtonText="Cancelar"
        isLoading={
          proposalState.status.confirmAccessCode === StateStatus.loading
        }
        onCancel={() => {
          updateShowModal({})
          history.push("/")
        }}
        onOk={() => {
          formConfirmCode.submit()
        }}
      >
        <Form
          form={formConfirmCode}
          onFinish={(values) => {
            dispatch(confirmAccessCode({ proposalId: id, data: values }))
          }}
        >
          <div style={{ width: "100%" }}>
            Digite o código enviado para {proposalState.codeSentToEmail}
          </div>
          <br />
          <Form.Item name="code" initialValue="">
            <FlattedInput
              style={{ width: "120px" }}
              onKeyUp={() => {
                formConfirmCode.setFields([{ name: "code", errors: [] }])
              }}
            />
          </Form.Item>
        </Form>
      </BaseModal>
    </>
  )
}
