import api from "../../service/api"
import apiRoutes from "../../service/apiRoutes"

export const schemaProposal = (proposalId) =>
  api.get(`${apiRoutes.proposal}/${proposalId}/${apiRoutes.proposal_schema}`)
export const proposalGet = (proposalId) =>
  api.get(`${apiRoutes.proposal}/${proposalId}`)
export const addProposal = (data) => api.post(apiRoutes.proposal, data)
export const editProposal = (proposalId, data) =>
  api.put(`${apiRoutes.proposal}/${proposalId}`, data)
export const shareProposal = (proposalId, data) =>
  api.post(`${apiRoutes.proposal}/${proposalId}/share`, data)
export const getSegments = () => api.get(`${apiRoutes.proposal}/segments`)
export const getMethods = () => api.get(`${apiRoutes.proposal}/methods`)
export const fileUpload = (data) => api.post(apiRoutes.file, data)
export const findAccount = (params) =>
  api.get(`${apiRoutes.profile}/find`, { params })
