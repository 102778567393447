import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import "./index.scss"
import axios from "axios"
import BaseModal from "../../../../common/components/Modal"

const ModalTermsOfUse = ({ onAccept, onClose, isVisible }) => {
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    axios.get(`${window.location.origin}/privacy.html`).then((resp) => {
      const iframe = document.getElementById("terms-privacy-iframe")
      iframe.contentDocument.open()
      iframe.contentDocument.write(resp.data)
      iframe.contentDocument.close()
      setIsLoaded(true)
    })
  }, [])

  return (
    <BaseModal
      className="software-contract-modal"
      isVisible={isVisible}
      style={{ display: isLoaded ? "none" : "block" }}
      okButtonText="Aceitar contrato"
      onOk={() => onAccept()}
      onClose={() => onClose()}
      isClosable={false}
    >
      <iframe
        id="terms-privacy-iframe"
        onLoad={function (event) {
          event.target.style.height =
            event.target.contentWindow.document.body.offsetHeight + "px"
          event.target.contentWindow.document.body.style.padding = "0"
          event.target.contentWindow.document.body.style.paddingRight = "15px"
        }}
        style={{
          display: "block",
          padding: "0 20px 25px 0",
          border: "none",
          height: "60vh",
          width: "100%",
        }}
      />
    </BaseModal>
  )
}

ModalTermsOfUse.defaultProps = {
  isVisible: true,
}

ModalTermsOfUse.propTypes = {
  onAccept: PropTypes.func,
  onClose: PropTypes.func,
  isVisible: PropTypes.bool,
}

export default ModalTermsOfUse
