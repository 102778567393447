import React, { useEffect, useState } from "react"
//
import { useDispatch, useSelector } from "react-redux"
import {
  getPlans,
  getSubscription,
  selectConfigState,
  postCancelPlan,
  getPaymentCreditCard,
  getProfile,
} from "../../configurationsSlice"
import StateStatus from "../../../../utils/stateStatus"
//
import { Form, Col, Row } from "antd"
import BaseModal from "../../../../common/components/Modal"
// import Loading from "../../../../common/components/Loading"
//
import MenuUpdatePlan from "./components/menuUpdatePlan"
import { CardSettings } from "../profile/components/cardSettings"
import { useHistory } from "react-router-dom"
//
import "./styles.scss"
import ChangePaymentMethod from "./components/changePaymentMethod"

const { useForm } = Form

const Payment = () => {
  const [form] = useForm()
  const dispatch = useDispatch()
  const history = useHistory()
  //
  const configState = useSelector(selectConfigState)
  const subscription = configState.subscription
  const profile = configState.data?.profileData
  //
  const [showMenu, updateShowMenu] = useState(null)
  const [showModal, updateShowModal] = useState(null)

  // const buildStatus = () => {
  //   switch (profile.status) {
  //     case "processing_payment":
  //     case "waiting_payment": {
  //       return "Pagamento pendente"
  //     }

  //     case "approved": {
  //       if (subscription.expires_at) {
  //         return ""
  //       }
  //       return "Plano atual"
  //     }
  //     case "payment_refused": {
  //       return "Plano expirado"
  //     }
  //   }
  // }

  useEffect(() => {
    if (configState.status.getPlans === StateStatus.succeeded) {
      const query = new URLSearchParams(window.location.hash.split("?")[1])
      const update = query.get("update")
      if (update) {
        updateShowMenu({ type: "show-plans" })
      }
    }
  }, [configState.status.getPlans])

  useEffect(() => {
    document.body.style.overflow = "auto"
    dispatch(getSubscription())
    dispatch(getPlans())
    dispatch(getPaymentCreditCard())
  }, [])

  useEffect(() => {
    if (configState.status.postCancelPlan === StateStatus.succeeded) {
      updateShowModal({ type: "plan-is-canceled" })
    }
  }, [configState.status.postCancelPlan])

  useEffect(() => {
    if (configState.status.updatePayment === StateStatus.succeeded) {
      updateShowModal({})
    }
  }, [configState.status.updatePayment])

  return (
    <>
      <div className="config-tab-content">
        {configState.status.getSubscription === StateStatus.failed && (
          <p>
            <br />
            Erro! Tente novamente mais tarde.
          </p>
        )}

        {configState.status.getSubscription === StateStatus.loading && (
          // <Loading />
          <></>
        )}

        {configState.status.getSubscription === StateStatus.succeeded && (
          <>
            <CardSettings
              label="Plano"
              value={
                <Row style={{ width: "100%" }}>
                  {/* {subscription.plan_name} */}
                  <Col span={8}>{profile.plan_name}</Col>

                  <Col span={16} style={{ textAlign: "center" }}>
                    {subscription.expires_at
                      ? `Vencimento em ${subscription.expires_at}.`
                      : null}
                    {subscription.downgrade_scheduled_name &&
                    subscription.renews_at
                      ? `Altera para ${subscription.downgrade_scheduled_name} em ${subscription.renews_at}.`
                      : null}
                  </Col>
                </Row>
              }
              actionText="Alterar"
              isDisabled={!profile.requires_payment_service}
              onClick={() => {
                updateShowMenu({ type: "show-plans" })
                updateShowModal({ type: "show-alert-add-credit-card" })
              }}
            />
            <CardSettings
              label={"Método de Pagamento"}
              value={
                configState.data.creditCard ? (
                  <Row
                    justify="space-between"
                    align="middle"
                    style={{ opacity: "0.8" }}
                  >
                    <Col span={24} className="normal-16">
                      {configState.data.creditCard.display_number
                        .replaceAll("X", "•")
                        .replaceAll("-", "  ")}
                    </Col>
                    {/* <Col span={24} className="normal-14">
                      {configState.data.creditCard.holder_name}
                    </Col> */}
                    {/* {configState.data.creditCard.cc_expires_at} */}
                  </Row>
                ) : null
              }
              actionText="Alterar"
              isDisabled={!profile.requires_payment_service}
              onClick={() => updateShowModal({ type: "change-payment-method" })}
            />
            <CardSettings
              label="Histórico de pagamentos"
              value={
                <>
                  <Col span={12}>{""}</Col>
                  {/* <Col span={12} style={{ justifyContent: "center" }}>
                    <div className="normal-16">Verificado em 15/02/2021</div>
                  </Col> */}
                </>
              }
              actionText={"Ver"}
              isDisabled={!profile.requires_payment_service}
              onClick={() => history.push("/dashboard/payment-history")}
            />
            <CardSettings
              label="Cancelar plano"
              actionText="Cancelar"
              onClick={() => updateShowModal({ type: "cancel-plan" })}
              isDisabled={
                subscription.expires_at !== null ||
                subscription.downgrade_scheduled === "starter" ||
                !profile.requires_payment_service ||
                profile.plan_identifier === "starter"
              }
            />
          </>
        )}

        <BaseModal
          type="alert"
          isVisible={showModal?.type === "cancel-plan"}
          title={"Encerrar serviços"}
          cancelButtonText="Fechar"
          okButtonText="Encerrar"
          okButtonColor={"red"}
          isLoading={configState.status.postCancelPlan === StateStatus.loading}
          onCancel={() => {
            updateShowModal({})
          }}
          onOk={() => {
            dispatch(postCancelPlan())
          }}
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={(values) => {
              dispatch(values)
              updateShowModal({})
            }}
          >
            <div style={{ marginBottom: "20px" }} className="normal-14">
              {"Você deseja encerrar os serviços realizando o "}
              <b>{"cancelamento"}</b>
              {" do plano atual?"}
              {
                " Você ainda poderá utlizar normalmente até a data de vencimento da fatura atual."
              }
            </div>
          </Form>
        </BaseModal>

        <BaseModal
          type="alert"
          isVisible={showModal?.type === "plan-is-canceled"}
          title={"Serviços encerrados"}
          okButtonText="Fechar"
          onClose={() => updateShowModal({})}
          onOk={() => {
            updateShowModal({})
          }}
        >
          <div style={{ marginBottom: "20px" }} className="normal-14">
            {
              "Seus serviços foram cancelados. Após a data de validade você não terá mais acesso aos benefícios dos planos pagos."
            }
          </div>
        </BaseModal>

        <BaseModal
          type="success"
          isVisible={showModal?.type === "plan-is-updated"}
          title={"Alteração de plano solicitada"}
          okButtonText="Fechar"
          onClose={() => updateShowModal({})}
          onOk={() => {
            updateShowModal({})
          }}
        >
          A sua solicitação para alteração do plano foi realizada com sucesso!
        </BaseModal>
      </div>

      {showMenu?.type === "show-plans" ? (
        <MenuUpdatePlan
          onClose={() => updateShowMenu({})}
          onUpdate={() => {
            dispatch(getSubscription())
            dispatch(getProfile())
            updateShowMenu({})
            updateShowModal({ type: "plan-is-updated" })
          }}
        />
      ) : null}

      <ChangePaymentMethod
        isVisible={showModal?.type === "change-payment-method"}
        onCancel={() => updateShowModal({})}
        onSave={() => {
          dispatch(getPaymentCreditCard())
          updateShowModal({})
        }}
      />
    </>
  )
}

export default Payment
