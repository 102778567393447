import api from "../../service/api"
import apiRoutes from "../../service/apiRoutes"

export const proposals = (page, data) =>
  api.get(`${apiRoutes.proposals}/${page}`, { params: data })

export const downloadProposalFile = (fileKey) =>
  api.get(`${apiRoutes.file}/${fileKey}`, { responseType: "arraybuffer" })

export const statistics = (data) =>
  api.get(`${apiRoutes.statistics}`, { params: data })

export const proposalduplicate = (id, data) =>
  api.post(`${apiRoutes.proposal}/${id}/duplicate`, data)

export const proposalDelete = (id) => api.delete(`${apiRoutes.proposal}/${id}`)

export const sendContract = (id, data) =>
  api.post(`${apiRoutes.proposal}/${id}/contract`, data)

export const getDefaultContract = (id, data) =>
  api.get(`${apiRoutes.proposal}/${id}/contract/default`, data)

export const contractResend = (id) =>
  api.put(`${apiRoutes.contractView}/${id}/resend`)

export const getContractInfo = (id) =>
  api.get(`${apiRoutes.contractView}/${id}`)

// TAGS
export const putProposalTags = (id, data) =>
  api.put(`${apiRoutes.proposal}/${id}/tags`, data)

export const getProposalTags = (id) =>
  api.get(`${apiRoutes.proposal}/${id}/tags`)

export const postTag = (data) => api.post("/tags", data) // data = { name: "" }

export const getTags = () => api.get("/tags")

export const deleteTag = (id) => api.delete(`/tags/${id}`)
