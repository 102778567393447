import React from "react"

export const CircleHelpIcon = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <defs>
          <rect
            id="circle-help-svg-path-1"
            x="0"
            y="0"
            width="24"
            height="24"
          ></rect>
        </defs>
        <g
          id="circle-help-svg-icon"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="circle-help-svg-icon-2"
            transform="translate(-388.000000, -455.000000)"
          >
            <g
              id="circle-help-svg-icon-3"
              transform="translate(130.000000, 229.000000)"
            >
              <g
                id="circle-help-svg-icon-group"
                transform="translate(30.000000, 226.000000)"
              >
                <g
                  id="circle-help-svg-icon-4"
                  transform="translate(228.000000, 0.000000)"
                >
                  <mask id="circle-help-svg-mask-2" fill="white">
                    <use xlinkHref="#circle-help-svg-path-1"></use>
                  </mask>
                  <g id="circle-help-svg-icon-5"></g>
                  <path
                    d="M2,12 C2,6.48000002 6.48000002,2 12,2 C17.5200005,2 22,6.48000002 22,12 C22,17.5200005 17.5200005,22 12,22 C6.48000002,22 2,17.5200005 2,12 C2,8.32000001 2,8.32000001 2,12 Z M13,16 L13,18 L11,18 L11,16 L13,16 L13,16 Z M12,20 C7.59000015,20 4,16.4099998 4,12 C4,7.59000015 7.59000015,4 12,4 C16.4099998,4 20,7.59000015 20,12 C20,16.4099998 16.4099998,20 12,20 C9.0600001,20 9.0600001,20 12,20 Z M8,10 C8,7.78999996 9.78999996,6 12,6 C14.21,6 16,7.78999996 16,10 C16,11.282917 15.2099791,11.9733191 14.4407616,12.6455393 C13.7110186,13.2832642 13,13.9046259 13,15 L11,15 C11,13.1787252 11.9421186,12.4565887 12.770443,11.8216753 C13.4202347,11.3236074 14,10.8792152 14,10 C14,8.9000001 13.1000004,8 12,8 C10.8999996,8 10,8.9000001 10,10 L8,10 C8,8.52666664 8,8.52666664 8,10 Z"
                    fill="#676767"
                    mask="url(#circle-help-svg-mask-2)"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </span>
  )
}
