import React, { useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"
//
import { useDispatch, useSelector } from "react-redux"
import { selectDashSlice, updateMode } from "../dashboard/DashboardSlice"
import {
  checkNotifications,
  selectNotificationsState,
} from "../listNotifications/listNotificationsSlice"
import PropTypes from "prop-types"
//
import { Tooltip } from "antd"
import Icons from "../../common/components/Icons"
import StyledSwitch from "../../common/components/Switch"
import SearchAnimation from "../../common/components/SearchAnimation"
//
import logoImg from "../../common/assets/logo/propozall.svg"
import PlanUse from "./components/planUse"

import "./styles.scss"
import BoxUserRole from "./components/boxUserRole/boxUserRole"

const NotificationsButton = () => {
  const [notificationsJob, setNotificationsJob] = useState()
  const history = useHistory()
  const dispatch = useDispatch()
  const notificationsState = useSelector(selectNotificationsState)

  useEffect(() => {
    if (!notificationsJob) {
      const job = setInterval(() => {
        dispatch(checkNotifications()).then((data) => {
          if (!data?.payload) clearInterval(job)
        })
      }, 1000 * 500)

      setNotificationsJob(job)
    }
    return () => notificationsJob && clearInterval(notificationsJob)
  }, [])

  return (
    <Icons
      icon={notificationsState.unviewed === 0 ? "bell" : "bell-alert"}
      title="Notificações"
      placement="bottom"
      onClick={() => history.push("/dashboard/notifications")}
    />
  )
}

const SwitchMode = () => {
  const dispatch = useDispatch()
  const state = useSelector(selectDashSlice)

  return (
    <div className="junction-switch-mode">
      <StyledSwitch
        checked={state.mode === "contractor"}
        onClick={(value) =>
          dispatch(updateMode({ mode: value ? "contractor" : "contracted" }))
        }
      />
      <span>
        {state.mode === "contractor"
          ? "Perfil Contratante"
          : "Perfil Contratado"}
      </span>

      <Tooltip title="Você pode alterar de Contratante para Contratado em apenas um click.">
        <Icons className="circle-help-icon" icon="circle-help" />
      </Tooltip>
    </div>
  )
}
const ConfigurationButton = () => {
  const history = useHistory()

  return (
    <Icons
      icon="settings"
      className="settings"
      title="Configurações"
      placement="bottom"
      onClick={() => {
        document.body.style.overflow = "auto"
        history.push("/dashboard/config")
      }}
    />
  )
}

const WelcomeBar = ({ title }) => {
  const dashState = useSelector(selectDashSlice)
  const username = dashState.data.basicProfile.name

  return (
    <div className="topbar-root">
      <div className="topbar-container">
        <div className="topbar-container-left">
          <span className="normal-24-500">
            {title || (username && `Olá, ${username || " - "}!`)}
          </span>
        </div>

        <div className="topbar-container-right">
          <PlanUse />
          <SwitchMode state={dashState} />
          <ConfigurationButton />
          <NotificationsButton />
        </div>
      </div>
    </div>
  )
}

const LogoBar = () => {
  return (
    <div className="topbar-root">
      <div className="topbar-container justify-center">
        <Link to={"/"}>
          <img src={logoImg} alt="Logo Propozall" width="250" />
        </Link>
      </div>
    </div>
  )
}

const TitleBar = ({ title }) => {
  const dashState = useSelector(selectDashSlice)

  return (
    <div className="topbar-root">
      <div className="topbar-container">
        <div className="topbar-container-left">
          <span className="normal-24-500">{title}</span>
        </div>

        <div className="topbar-container-right">
          <PlanUse />
          <SwitchMode state={dashState} />
          <ConfigurationButton />
          <NotificationsButton />
        </div>
      </div>
    </div>
  )
}

const TitleWithSearchBar = ({ title, onSearch, icon, inputValue }) => {
  const dashState = useSelector(selectDashSlice)
  const user = dashState.data.basicProfile.name
  const email = dashState.data.basicProfile.email
  const role = dashState.mode

  return (
    <div className="topbar-root">
      <div className="topbar-container">
        <div className="topbar-container-left">
          <span className="icon-navbar" style={{ paddingRight: "20px" }}>
            <Icons icon={icon} />
          </span>
          <span className="normal-24-500">{title}</span>
        </div>
        <div className="topbar-container-right">
          {/* <SwitchMode state={dashState} /> */}
          {onSearch ? (
            <SearchAnimation
              onSearch={onSearch}
              value={inputValue ?? ""}
              placeholder="Pesquisar"
            />
          ) : null}

          <NotificationsButton />
          <BoxUserRole user={user} role={role} email={email} />
        </div>
      </div>
    </div>
  )
}

TitleWithSearchBar.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  onSearch: PropTypes.func,
  inputValue: PropTypes.string,
}

TitleBar.propTypes = {
  title: PropTypes.string,
}

WelcomeBar.propTypes = {
  title: PropTypes.string,
}

export {
  WelcomeBar,
  LogoBar,
  TitleBar,
  TitleWithSearchBar,
  ConfigurationButton,
}
