import React, { useState } from "react"
import PropTypes from "prop-types"
import "./styles.scss"
import Icons from "../Icons"

const Search = ({ onSearch, delay, ...props }) => {
  const [job, updateJob] = useState()

  const onChange = (event) => {
    if (job) clearTimeout(job)

    const value = event.target.value
    if (value.length === 0 || value.length >= 1) {
      const newJob = setTimeout(() => {
        if (onSearch) onSearch(value)
      }, 400)

      updateJob(newJob)
    }
  }

  const onKeyUp = (event) => {
    if (!onSearch) return

    const key = event.which || event.keyCode

    if (key === 13) {
      // Enter
      if (job) clearTimeout(job)
      onSearch(event.target.value)
    }
  }

  return (
    <div className="search-bar">
      <Icons icon="search" />
      <input
        className="search-input"
        type="text"
        onChange={onChange}
        onKeyUp={onKeyUp}
        {...props}
      />
    </div>
  )
}

Search.propTypes = {
  onSearch: PropTypes.func,
  delay: PropTypes.number,
}

export default Search
