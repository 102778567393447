import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getContracts } from "./contractHistoryService"
//
import StateStatus from "../../utils/stateStatus"

export const listContracts = createAsyncThunk(
  "contract/list",
  async ({ role }, thunkAPI) => {
    const { contracts } = thunkAPI.getState()

    const response = await getContracts(contracts.page, {
      role,
      filter: contracts.search,
      starts_at: contracts.startDate,
      ends_at: contracts.endDate,
    })

    return {
      count: response.data.data.count,
      items: response.data.data.items,
      totalItems: response.data.data.count,
      totalPages: response.data.data.total_pages,
    }
  }
)

const initialState = {
  status: {
    listContracts: StateStatus.idle,
  },
  search: null,
  endDate: null,
  startDate: null,
  //
  page: 1,
  list: [],
  totalPages: 0,
  totalItems: 0,
  //
  errMessage: "",
}

export const contractHistorySlice = createSlice({
  name: "contracts",
  initialState,
  reducers: {
    updateSearch: (state, action) => {
      state.search = action.payload
    },
    updateStartDate: (state, action) => {
      state.startDate = action.payload
    },
    updateEndDate: (state, action) => {
      state.endDate = action.payload
    },
    updatePage: (state, action) => {
      state.page = action.payload
    },
  },
  extraReducers: {
    [listContracts.pending]: (state) => {
      state.status.listContracts = StateStatus.loading
    },
    [listContracts.fulfilled]: (state, action) => {
      const { items, totalPages, totalItems } = action.payload

      state.list = items

      state.totalItems = totalItems

      state.totalPages = totalPages

      state.status.listContracts = StateStatus.succeeded
    },
    [listContracts.rejected]: (state, action) => {
      state.errMessage =
        action.error.message ?? "Não foi possível listar os contratos."
      state.status.listContracts = StateStatus.failed
    },
  },
})

export const { updateSearch, updateStartDate, updateEndDate, updatePage } =
  contractHistorySlice.actions

export const selectContractsState = (state) => state.contracts
