import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import PropTypes from "prop-types"
//
import Icons from "../Icons"
//
import "./styles.scss"
import { useDispatch } from "react-redux"
import { cleanSelectedFilters } from "../../../features/listProposals/listProposalsSlice"

const SideMenu = ({ options }) => {
  const location = useLocation()
  const dispatch = useDispatch()
  //
  // const [height] = useState(60)
  // const [offsetTop, updateOffsetTop] = useState(0)
  const [selectedIndex, updateSelectedIndex] = useState(0)
  const [filterOptions, updateFilterOptions] = useState([])
  //

  useEffect(() => {
    updateFilterOptions(options.filter((item) => item !== null))
  }, [options])

  useEffect(() => {
    let isMenuPath = false
    for (let i = 0; i < filterOptions.length; i++) {
      if (location.pathname.match(filterOptions[i].path)) {
        // updateOffsetTop(i * height)
        updateSelectedIndex(i)
        isMenuPath = true

        break
      }
    }

    if (!isMenuPath) {
      // updateOffsetTop(null)
      updateSelectedIndex(null)
    }
  }, [location, filterOptions])

  useEffect(() => {
    if (
      !location.pathname.includes("/dashboard/proposals") ||
      !location.pathname.includes("/dashboard/proposal")
    ) {
      dispatch(cleanSelectedFilters())
    }
  }, [location.pathname])

  return (
    <div className="side-menu">
      <ul>
        {filterOptions.length > 0 &&
          filterOptions.map((item, index) => {
            return (
              item && (
                <li
                  key={index}
                  data-testid={`sidemenu-btn${index}`}
                  className={`menu-item-${item.icon} ${
                    index === selectedIndex ? "menu-item selected" : "menu-item"
                  }`}
                  onClick={() => {
                    if (filterOptions[index].onClick)
                      filterOptions[index].onClick()
                  }}
                >
                  <Icons alt={item.title} icon={item.icon} />
                  <div className="icon-title">{item.title}</div>
                </li>
              )
            )
          })}
      </ul>
      {/* <i
        data-testid="sidemenu-indicator"
        style={{
          transform: offsetTop != null ? `translateY(${offsetTop}px)` : "none",
          display: offsetTop != null ? "block" : "none",
        }}
        className={`active-marker position-${selectedIndex}`}
      /> */}
    </div>
  )
}

SideMenu.propTypes = {
  options: PropTypes.array.isRequired,
}

export default SideMenu
