import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { createCreditCardToken } from "../../utils/iugu"
import StateStatus from "../../utils/stateStatus"
import { checkCoupon } from "../register/RegisterService"
import {
  addAddress,
  address,
  editAddress,
  editNotificationSettings,
  editPassword,
  getNotificationSettings,
  profile,
  profileEdit,
  contacts,
  companies,
  bankAccounts,
  editBankAccount,
  addBankAccount,
  banks,
  deleteContact,
  deleteCompany,
  deleteBankAccount,
  avatarUpload,
  favCompany,
  plans,
  subscription,
  updSubscription,
  cancelPlan,
  editPlan,
  updPayment,
  getPaymentCC,
  confirmEditEmail,
  editEmail,
} from "./configureService"

export const getProfile = createAsyncThunk("config/profile", async () => {
  const response = await profile()

  return response.data
})

export const editProfile = createAsyncThunk("config/edit", async (data) => {
  const response = await profileEdit(data)

  return response.data
})

export const verifyCouponThunk = createAsyncThunk(
  "config/verify-coupon",
  async ({ coupon }) => {
    const response = await checkCoupon(coupon)

    return response.data
  }
)

export const editEmailThunk = createAsyncThunk(
  "config/edit-email",
  async (data) => {
    const response = await editEmail(data)

    return response.data
  }
)

export const confirmEditEmailThunk = createAsyncThunk(
  "config/confirm-edit-email",
  async (data) => {
    const response = await confirmEditEmail(data)

    return response.data
  }
)

export const uploadAvatar = createAsyncThunk(
  "config/upload_avatar",
  async (file) => {
    const data = new FormData()
    data.append("file", file)

    const response = await avatarUpload(data)
    return response.data
  }
)

export const listAddress = createAsyncThunk("config/list_address", async () => {
  const response = await address()
  return response.data
})

export const createAddress = createAsyncThunk(
  "config/create_address",
  async (data) => {
    await addAddress(data)
    return data
  }
)

export const updateAddress = createAsyncThunk(
  "config/update_address",
  async (data) => {
    await editAddress(data.addressId, data.data)
    return { id: data.addressId, ...data.data }
  }
)

export const listBankAccounts = createAsyncThunk(
  "config/list_bank_account",
  async ({ filter }) => {
    const response = await bankAccounts(filter)
    return response.data
  }
)

export const listContacts = createAsyncThunk(
  "config/list_contacts",
  async ({ page, filter }) => {
    const response = await contacts(page, { filter })
    return {
      totalPages: response.data.data.total_pages,
      count: response.data.data.count,
      items: response.data.data.items,
      page,
      filter,
    }
  }
)

export const removeContact = createAsyncThunk(
  "config/remove_contact",
  async (contactId) => {
    try {
      const response = await deleteContact(contactId)
      return response.data
    } catch (err) {
      if (err.code === "INTEGRITY_ERROR") {
        err.message =
          "O contratante já está sendo utilizado e não pôde ser excluído"
      }
      return Promise.reject(err)
    }
  }
)

export const listCompanies = createAsyncThunk(
  "config/list_companies",
  async ({ filter }) => {
    const response = await companies(filter)
    return response.data
  }
)

export const favoriteCompany = createAsyncThunk(
  "config/favorite_company",
  async (companyId) => {
    const response = await favCompany(companyId)
    return response.data
  }
)

export const removeCompany = createAsyncThunk(
  "config/remove_company",
  async (companyId) => {
    try {
      const response = await deleteCompany(companyId)
      return response.data
    } catch (err) {
      if (err.code === "INTEGRITY_ERROR") {
        err.message = "O CNPJ já está sendo utilizado e não pôde ser excluído"
      }
      return Promise.reject(err)
    }
  }
)

export const updateBankAccount = createAsyncThunk(
  "config/edit_bank_account",
  async ({ bankAccountId, data }) => {
    const response = await editBankAccount(bankAccountId, data)
    return response.data
  }
)

export const createBankAccount = createAsyncThunk(
  "config/create_bank_account",
  async (data) => {
    const response = await addBankAccount(data)
    return response.data
  }
)

export const removeBankAccount = createAsyncThunk(
  "config/remove_bank_account",
  async (bankAccountId) => {
    try {
      const response = await deleteBankAccount(bankAccountId)
      return response.data
    } catch (err) {
      if (err.code === "INTEGRITY_ERROR") {
        err.message = "A conta já está sendo utilizada e não pôde ser excluída"
      }
      return Promise.reject(err)
    }
  }
)

export const listBanks = createAsyncThunk("config/list_banks", async () => {
  const response = await banks()
  return response.data
})

export const updatePassword = createAsyncThunk(
  "config/update_password",
  async (data) => {
    await editPassword(data)
  }
)

export const listNotificationsSettings = createAsyncThunk(
  "config/get_notifications_settings",
  async () => {
    const response = await getNotificationSettings()
    return response.data.data.items
  }
)

export const updateNotificationSettings = createAsyncThunk(
  "config/update_notifications_settings",
  async (data) => {
    const bodyData = {
      type: data.type,
    }
    bodyData.allow_destinies = []
    Object.entries(data).forEach((item) => {
      if (item[1] && ["sms", "email", "app"].includes(item[0])) {
        bodyData.allow_destinies.push(item[0])
      }
    })
    await editNotificationSettings(bodyData)
    return data
  }
)

// INVOICE
export const getPlans = createAsyncThunk("config/get-plans", async () => {
  const response = await plans()

  return response.data.data.items
})

export const editCurrentPlan = createAsyncThunk(
  "config/edit-current-plan",
  async ({ planId, coupon }) => {
    const response = await editPlan({ plan_identifier: planId, coupon })

    return response.data.data
  }
)

export const getSubscription = createAsyncThunk(
  "config/get-subscription",
  async () => {
    const response = await subscription()

    return response.data
  }
)

export const updatePayment = createAsyncThunk(
  "/config/update-payment",
  async (data) => {
    const token = await createCreditCardToken({
      cardNumber: data.card_number,
      cardName: data.card_name,
      validity: data.validity,
      cvv: data.cvv,
    })

    const response = await updPayment({ credit_card_token: token })
    return response.data
  }
)

export const getPaymentCreditCard = createAsyncThunk(
  "/config/get-payment-cc",
  async () => {
    const response = await getPaymentCC()
    return response.data
  }
)

export const updateSubscription = createAsyncThunk(
  "config/update-subscription",
  async () => {
    const response = await updSubscription()

    return response.data
  }
)
export const postCancelPlan = createAsyncThunk(
  "config/cancelPlan",
  async () => {
    const response = await cancelPlan()

    return response.data
  }
)

const initialState = {
  status: {
    getProfile: StateStatus.idle,
    editProfile: StateStatus.idle,
    editEmail: StateStatus.idle,
    confirmEditEmail: StateStatus.idle,
    uploadAvatar: StateStatus.idle,
    listAddress: StateStatus.idle,
    createAddress: StateStatus.idle,
    updateAddress: StateStatus.idle,
    listContacts: StateStatus.idle,
    removeContact: StateStatus.idle,
    listCompanies: StateStatus.idle,
    removeCompany: StateStatus.idle,
    favoriteCompany: StateStatus.idle,
    listBankAccounts: StateStatus.idle,
    listBanks: StateStatus.idle,
    createBankAccount: StateStatus.idle,
    updateBankAccount: StateStatus.idle,
    updatePassword: StateStatus.idle,
    removeBankAccount: StateStatus.idle,
    listNotificationsSettings: StateStatus.idle,
    updateNotificationSettings: StateStatus.idle,
    // INVOICES
    getPlans: StateStatus.idle,
    editCurrentPlan: StateStatus.idle,
    getSubscription: StateStatus.idle,
    updatePayment: StateStatus.idle,
    getPaymentCreditCard: StateStatus.idle,
    updateSubscription: StateStatus.idle,
    postCancelPlan: StateStatus.idle,
    verifyCoupon: StateStatus.idle,
  },
  data: {
    profileData: {},
    address: [{}],
    bankAccounts: { filter: "", items: null },
    contacts: { page: 0, totalPages: 0, count: 0, filter: "", items: null },
    companies: { filter: "", items: null },
    banks: [{}],
    bankAccount: {
      bank_name: "",
      bank_code: "",
      account_type: "",
      account_number: "",
      agency: "",
      pix_key: "",
    },
    notificationSettings: [],
    // INVOICES
    plan: {},
    subscription: {},
    plans: [],
    creditCard: null,
  },
  errMessage: null,
}

export const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    addBankAccountData: (state, action) => {
      state.data.bankAccount = action.payload
    },
    resetUpdatePayment: (state, action) => {
      state.status.updatePayment = StateStatus.idle
    },
    searchBankAccount: (state, action) => {
      state.data.bankAccounts.items = []
      state.data.bankAccounts.filter = action.payload
      state.status.listBankAccounts = StateStatus.idle
    },
    searchContacts: (state, action) => {
      state.data.contacts.items = []
      state.data.contacts.page = 0
      state.data.contacts.filter = action.payload
      state.status.listContacts = StateStatus.idle
    },
    searchCompanies: (state, action) => {
      state.data.companies.items = []
      state.data.companies.filter = action.payload
      state.status.listCompanies = StateStatus.idle
    },
    resetActionsStatus: (state, action) => {
      state.status.createBankAccount = StateStatus.idle
      state.status.updateBankAccount = StateStatus.idle
      state.status.removeBankAccount = StateStatus.idle
      state.status.editCurrentPlan = StateStatus.idle
      state.status.postCancelPlan = StateStatus.idle

      state.status.createAddress = StateStatus.idle
      state.status.updateAddress = StateStatus.idle

      state.status.editProfile = StateStatus.idle
    },
  },
  extraReducers: {
    [getProfile.pending]: (state) => {
      state.status.getProfile = StateStatus.loading
    },
    [getProfile.fulfilled]: (state, action) => {
      state.data.profileData = action.payload.data
      state.status.getProfile = StateStatus.succeeded
    },
    [getProfile.rejected]: (state, action) => {
      state.status.getProfile = StateStatus.failed
      state.errMessage = action.error.message
    },
    [editEmailThunk.pending]: (state) => {
      state.status.editEmail = StateStatus.loading
    },
    [editEmailThunk.fulfilled]: (state, action) => {
      state.status.editEmail = StateStatus.succeeded
    },
    [editEmailThunk.rejected]: (state, action) => {
      state.status.editEmail = StateStatus.failed
      state.errMessage = "Não foi possível alterar o e-mail no momento."
    },
    [confirmEditEmailThunk.pending]: (state) => {
      state.status.confirmEditEmail = StateStatus.loading
    },
    [confirmEditEmailThunk.fulfilled]: (state, action) => {
      state.status.confirmEditEmail = StateStatus.succeeded
      state.status.getProfile = StateStatus.idle
    },
    [confirmEditEmailThunk.rejected]: (state, action) => {
      state.status.confirmEditEmail = StateStatus.failed
      state.errMessage = action.error.message
    },
    [editProfile.pending]: (state) => {
      state.status.editProfile = StateStatus.loading
    },
    [editProfile.fulfilled]: (state, action) => {
      state.status.editProfile = StateStatus.succeeded
      state.data.profileData = action.payload.data
    },
    [editProfile.rejected]: (state, action) => {
      state.status.editProfile = StateStatus.failed
      state.errMessage = action.error.message
    },
    [uploadAvatar.pending]: (state) => {
      state.status.uploadAvatar = StateStatus.loading
    },
    [uploadAvatar.fulfilled]: (state, action) => {
      state.status.uploadAvatar = StateStatus.succeeded
      state.data.profileData = action.payload.data
    },
    [uploadAvatar.rejected]: (state, action) => {
      state.status.uploadAvatar = StateStatus.failed
      state.errMessage = action.error.message
    },
    [listAddress.pending]: (state) => {
      state.status.listAddress = StateStatus.loading
    },
    [listAddress.fulfilled]: (state, action) => {
      state.status.listAddress = StateStatus.succeeded
      state.data.address = action.payload.data.items
    },
    [listAddress.rejected]: (state, action) => {
      state.status.listAddress = StateStatus.failed
      state.errMessage = action.error.message
    },
    [createAddress.pending]: (state) => {
      state.status.createAddress = StateStatus.loading
    },
    [createAddress.fulfilled]: (state, action) => {
      state.status.createAddress = StateStatus.succeeded
      state.data.address = [action.payload]
    },
    [createAddress.rejected]: (state, action) => {
      state.status.createAddress = StateStatus.failed
      state.errMessage = action.error.message
    },
    [updateAddress.pending]: (state) => {
      state.status.updateAddress = StateStatus.loading
    },
    [updateAddress.fulfilled]: (state, action) => {
      state.status.updateAddress = StateStatus.succeeded
      state.data.address[0] = action.payload
    },
    [updateAddress.rejected]: (state, action) => {
      state.status.updateAddress = StateStatus.failed
      state.errMessage = action.error.message
    },
    [listBankAccounts.pending]: (state) => {
      state.status.listBankAccounts = StateStatus.loading
    },
    [listBankAccounts.fulfilled]: (state, action) => {
      state.data.bankAccounts.items = action.payload.data.items
      state.status.listBankAccounts = StateStatus.succeeded
    },
    [listBankAccounts.rejected]: (state, action) => {
      state.status.listBankAccounts = StateStatus.failed
      state.errMessage = action.error.message
    },
    [listContacts.pending]: (state, action) => {
      state.data.contacts.page = action.meta.arg.page
      state.status.listContacts = StateStatus.loading
    },
    [listContacts.fulfilled]: (state, action) => {
      const { items, page, filter, totalPages } = action.payload
      const contacts = state.data.contacts.items

      if (page <= 1) {
        state.data.contacts.items = items
      } else {
        state.data.contacts.items = [...contacts, ...items]
      }

      state.data.contacts.page = page
      state.data.contacts.filter = filter
      state.data.contacts.totalPages = totalPages
      state.status.listContacts = StateStatus.succeeded
    },
    [listContacts.rejected]: (state, action) => {
      state.data.contacts.page = action.meta.arg.page - 1
      state.status.listContacts = StateStatus.failed
      state.errMessage = action.error.message
    },
    [removeContact.pending]: (state) => {
      state.status.removeContact = StateStatus.loading
    },
    [removeContact.fulfilled]: (state, action) => {
      state.status.removeContact = StateStatus.succeeded
    },
    [removeContact.rejected]: (state, action) => {
      state.status.removeContact = StateStatus.failed
      state.errMessage = action.error.message
    },
    [listCompanies.pending]: (state) => {
      state.status.listCompanies = StateStatus.loading
    },
    [listCompanies.fulfilled]: (state, action) => {
      state.data.companies.items = action.payload.data.items
      state.status.listCompanies = StateStatus.succeeded
    },
    [listCompanies.rejected]: (state, action) => {
      state.status.listCompanies = StateStatus.failed
      state.errMessage = action.error.message
    },
    [removeCompany.pending]: (state) => {
      state.status.removeCompany = StateStatus.loading
    },
    [removeCompany.fulfilled]: (state, action) => {
      state.status.removeCompany = StateStatus.succeeded
    },
    [removeCompany.rejected]: (state, action) => {
      state.status.removeCompany = StateStatus.failed
      state.errMessage = action.error.message
    },
    [favoriteCompany.pending]: (state) => {
      state.status.favoriteCompany = StateStatus.loading
    },
    [favoriteCompany.fulfilled]: (state, action) => {
      state.status.favoriteCompany = StateStatus.succeeded
      state.status.listCompanies = StateStatus.idle
    },
    [favoriteCompany.rejected]: (state, action) => {
      state.status.favoriteCompany = StateStatus.failed
      state.errMessage = action.error.message
    },
    [listBanks.pending]: (state) => {
      state.status.listBanks = StateStatus.loading
    },
    [listBanks.fulfilled]: (state, action) => {
      state.status.listBanks = StateStatus.succeeded
      state.data.banks = action.payload.data.items
    },
    [listBanks.rejected]: (state, action) => {
      state.status.listBanks = StateStatus.failed
      state.errMessage = action.error.message
    },
    [createBankAccount.pending]: (state) => {
      state.status.createBankAccount = StateStatus.loading
    },
    [createBankAccount.fulfilled]: (state, action) => {
      state.status.createBankAccount = StateStatus.succeeded
      state.status.listBankAccounts = StateStatus.idle
      state.data.bankAccount = action.payload.data
    },
    [createBankAccount.rejected]: (state, action) => {
      state.status.createBankAccount = StateStatus.failed
      state.errMessage = action.error.message
    },
    [updateBankAccount.pending]: (state) => {
      state.status.updateBankAccount = StateStatus.loading
    },
    [updateBankAccount.fulfilled]: (state, action) => {
      state.status.updateBankAccount = StateStatus.succeeded
      state.status.listBankAccounts = StateStatus.idle
      state.data.bankAccount = action.payload.data
    },
    [updateBankAccount.rejected]: (state, action) => {
      state.status.updateBankAccount = StateStatus.failed
      state.errMessage = action.error.message
    },
    [removeBankAccount.pending]: (state) => {
      state.status.removeBankAccount = StateStatus.loading
    },
    [removeBankAccount.fulfilled]: (state, action) => {
      state.status.removeBankAccount = StateStatus.succeeded
    },
    [removeBankAccount.rejected]: (state, action) => {
      state.status.removeBankAccount = StateStatus.failed
      state.errMessage = action.error.message
    },
    [updatePassword.pending]: (state) => {
      state.status.updatePassword = StateStatus.loading
    },
    [updatePassword.fulfilled]: (state) => {
      state.status.updatePassword = StateStatus.succeeded
    },
    [updatePassword.rejected]: (state, action) => {
      state.status.updatePassword = StateStatus.failed
      state.errMessage = action.error.message
    },
    [listNotificationsSettings.pending]: (state) => {
      state.status.listNotificationsSettings = StateStatus.loading
    },
    [listNotificationsSettings.fulfilled]: (state, action) => {
      state.status.listNotificationsSettings = StateStatus.succeeded
      state.data.notificationSettings = action.payload.map((item) => {
        const email = item.allow_destinies.includes("email")
        const app = item.allow_destinies.includes("app")
        const sms = item.allow_destinies.includes("sms")

        return {
          type: item.type,
          email,
          app,
          sms,
          all: email && app && sms,
        }
      })
    },
    [listNotificationsSettings.rejected]: (state, action) => {
      state.status.listNotificationsSettings = StateStatus.failed
      state.errMessage = action.error.message
    },
    [updateNotificationSettings.pending]: (state) => {
      state.status.updateNotificationSettings = StateStatus.loading
    },
    [updateNotificationSettings.fulfilled]: (state, action) => {
      state.status.updateNotificationSettings = StateStatus.succeeded
      state.data.notificationSettings = state.data.notificationSettings.map(
        (item) => {
          if (item.type === action.payload.type) {
            return action.payload
          } else {
            return item
          }
        }
      )
    },
    [updateNotificationSettings.rejected]: (state, action) => {
      state.status.updateNotificationSettings = StateStatus.failed
      state.errMessage = action.error.message
    },
    //
    // INVOICES
    [getPlans.pending]: (state) => {
      state.status.getPlans = StateStatus.loading
    },
    [getPlans.fulfilled]: (state, action) => {
      state.plans = action.payload
      state.status.getPlans = StateStatus.succeeded
    },
    [getPlans.rejected]: (state, action) => {
      state.status.getPlans = StateStatus.failed
      state.errMessage = action.error.message
    },
    //
    [editCurrentPlan.pending]: (state) => {
      state.status.editCurrentPlan = StateStatus.loading
    },
    [editCurrentPlan.fulfilled]: (state, action) => {
      state.status.editCurrentPlan = StateStatus.succeeded
    },
    [editCurrentPlan.rejected]: (state, action) => {
      state.status.editCurrentPlan = StateStatus.failed
      state.errMessage = action.error.message
    },

    // COUPON
    [verifyCouponThunk.pending]: (state) => {
      state.status.verifyCoupon = StateStatus.loading
    },
    [verifyCouponThunk.fulfilled]: (state, action) => {
      state.data.coupon = action.payload.data
      state.status.verifyCoupon = StateStatus.succeeded
    },
    [verifyCouponThunk.rejected]: (state, action) => {
      // state.msg = action.error.message
      state.msg = null
      state.status.verifyCoupon = StateStatus.failed
    },
    [getSubscription.pending]: (state) => {
      state.status.getSubscription = StateStatus.loading
    },
    [getSubscription.fulfilled]: (state, action) => {
      state.subscription = action.payload.data
      state.status.getSubscription = StateStatus.succeeded
    },
    [getSubscription.rejected]: (state, action) => {
      state.errMessage = action.error.message
      state.status.getSubscription = StateStatus.failed
    },
    // updatePayment
    [updatePayment.pending]: (state) => {
      state.status.updatePayment = StateStatus.loading
    },
    [updatePayment.fulfilled]: (state, action) => {
      state.status.updatePayment = StateStatus.succeeded
    },
    [updatePayment.rejected]: (state, action) => {
      state.errMessage = action.error.message
      state.status.updatePayment = StateStatus.failed
    },

    [getPaymentCreditCard.pending]: (state) => {
      state.status.getPaymentCreditCard = StateStatus.loading
    },
    [getPaymentCreditCard.fulfilled]: (state, action) => {
      if (action.payload.data?.holder_name) {
        state.data.creditCard = action.payload.data
      } else {
        state.data.creditCard = null
      }
      state.status.getPaymentCreditCard = StateStatus.succeeded
    },
    [getPaymentCreditCard.rejected]: (state, action) => {
      state.errMessage = action.error.message
      state.status.getPaymentCreditCard = StateStatus.failed
    },

    [updateSubscription.pending]: (state) => {
      state.status.updateSubscription = StateStatus.loading
    },
    [updateSubscription.fulfilled]: (state, action) => {
      state.status.updateSubscription = StateStatus.succeeded
    },
    [updateSubscription.rejected]: (state, action) => {
      state.errMessage = action.error.message
      state.status.updateSubscription = StateStatus.failed
    },
    // Cancel Plan
    [postCancelPlan.pending]: (state) => {
      state.status.postCancelPlan = StateStatus.loading
    },
    [postCancelPlan.fulfilled]: (state, action) => {
      state.status.postCancelPlan = StateStatus.succeeded
    },
    [postCancelPlan.rejected]: (state, action) => {
      state.errMessage = action.error.message
      state.status.postCancelPlan = StateStatus.failed
    },
  },
})

export const selectConfigState = (state) => state.config
export const selectProfileData = (state) => state.config.data.profileData
export const selectBankAccount = (state) => state.config.data.bankAccount
export const selectBankAccountsData = (state) => state.config.data.bankAccounts
export const selectContactsData = (state) => state.config.data.contacts
export const selectAddresses = (state) => state.config.data.address
export const selectCompaniesData = (state) => state.config.data.companies
export const selectBanks = (state) => state.config.data.banks
export const selectNotificationsSettings = (state) =>
  state.config.data.notificationSettings

export const {
  resetActionsStatus,
  addBankAccountData,
  putBank,
  putAccountType,
  searchBankAccount,
  searchContacts,
  searchCompanies,
  resetUpdatePayment,
} = configSlice.actions
