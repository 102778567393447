import React, { useEffect, useState } from "react"
import BaseModal from "../../common/components/Modal"
import { Col, Divider, Form, Menu, Row, Select } from "antd"
import FlattedInput, {
  FlattedMaskedInput,
} from "../../common/components/FlattedInput"
import PropTypes from "prop-types"
import "./styles.scss"
import Icons from "../../common/components/Icons"
import { BlueButton, WhiteButton } from "../../common/components/Button"
import { useDispatch, useSelector } from "react-redux"
import {
  addSigner,
  editSigner,
  getContractInfo,
  removeSigner,
  resetErrorsState,
  selectSignersState,
} from "./slice"
import StateStatus from "../../utils/stateStatus"
import Loading from "../../common/components/Loading"
import { cpf } from "cpf-cnpj-validator"
import { beautifyCpfCnpj } from "../../utils/formaters"
import ErrorMessage from "../../common/components/ErrorMessage"
import { signerRolesList } from "../../utils/constants"

const MAX_SIGNERS_BY_PROPOSAL_ROLE = 10

const SignerRow = ({
  form,
  isEditing,
  onEdit,
  onCancel,
  onDelete,
  signerData,
}) => {
  const [signer, setSigner] = useState()
  const dispatch = useDispatch()
  const signersState = useSelector(selectSignersState)

  useEffect(() => {
    setSigner(signerData)
    if (form && isEditing) {
      form.setFieldsValue({
        id: signerData?.id,
        cpf: signerData?.cpf,
        name: signerData?.name,
        signer_role: signerData?.signer_role,
        proposal_role: signerData?.proposal_role,
        contract_id: signerData?.contract_id,
        email: signerData?.email,
      })
    }
  }, [signerData, isEditing, form])

  return (
    <>
      <Row
        justify="space-between"
        className={`signer-row ${!isEditing ? "opened" : ""}`}
        gutter={[16, 0]}
      >
        <Col span={6} className="not-overflow">
          {signer?.name}
        </Col>
        <Col span={4} className="not-overflow">
          {signer?.signer_role_name}
        </Col>
        <Col span={7} className="not-overflow">
          {signer?.email}
        </Col>
        <Col span={4} className="not-overflow">
          {signer?.cpf ? beautifyCpfCnpj(signer?.cpf) : ""}
        </Col>
        <Col span={3}>
          <Row justify="center">
            <Col>
              <Icons icon="edit" onClick={onEdit} />
            </Col>
            <Col>
              {signersState.status.removeSigner === StateStatus.loading &&
              signersState.data.deletingId === signer?.id ? (
                <Loading className="dark small center remove-signer-loading" />
              ) : (
                <Icons icon="delete" onClick={onDelete} />
              )}
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className={`signer-form ${isEditing ? "opened" : ""}`}>
        <Divider />
        <Form
          layout="vertical"
          form={form}
          onFinish={(values) => {
            if (values.cpf) {
              values.cpf = values.cpf.replace(/\D/g, "")
            } else {
              values.cpf = null
            }

            const contractId = values.contract_id
            delete values.contract_id

            if (values.id) {
              const id = values.id
              delete values.id
              dispatch(editSigner({ contractId, id, data: values }))
            } else {
              dispatch(addSigner({ contractId, data: values }))
            }
          }}
          onValuesChange={() => {
            dispatch(resetErrorsState())
          }}
        >
          <Form.Item name="id" noStyle>
            <input type="hidden" />
          </Form.Item>
          <Form.Item name="proposal_role" noStyle>
            <input type="hidden" />
          </Form.Item>
          <Form.Item name="contract_id" noStyle>
            <input type="hidden" />
          </Form.Item>
          <Form.Item
            name="name"
            label="Nome completo"
            initialValue=""
            rules={[
              {
                required: true,
                message: "Campo obrigatório",
              },
              () => ({
                validator(_, value_) {
                  const value = (value_ ?? "").trim()
                  if (value === "" || value.split(" ").length >= 2) {
                    return Promise.resolve()
                  } else {
                    return Promise.reject(new Error())
                  }
                },
                message: (
                  <div className="normal-12">Insira nome e sobrenome</div>
                ),
              }),
            ]}
          >
            <FlattedInput
              placeholder="Nome completo"
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            label="Quem assina"
            initialValue={undefined}
            style={{ width: "100%" }}
            name="signer_role"
            rules={[
              {
                required: true,
                message: "Campo obrigatório",
              },
            ]}
          >
            <Select placeholder="Quem assina" filterOption={false}>
              {signer &&
                signerRolesList.map((item) => {
                  if (
                    signer.proposal_role === "CONTRACTOR" &&
                    item.value === "CONTRACTED"
                  ) {
                    return null
                  }

                  if (
                    signer.proposal_role === "CONTRACTED" &&
                    item.value === "CONTRACTOR"
                  ) {
                    return null
                  }

                  return (
                    <Select.Option key={item.value} value={item.value}>
                      {item.name}
                    </Select.Option>
                  )
                })}
            </Select>
          </Form.Item>
          <Form.Item
            label="E-mail"
            initialValue=""
            style={{ width: "100%" }}
            name="email"
            rules={[
              {
                required: true,
                message: "Campo obrigatório",
              },
              {
                type: "email",
                message: "E-mail inválido",
              },
            ]}
          >
            <FlattedInput
              type="email"
              placeholder="E-mail"
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            name="cpf"
            label="CPF"
            rules={[
              () => ({
                validator(_, value) {
                  const cpfValue = (value ?? "").replace(/\D/g, "")
                  if (cpfValue === "" || cpf.isValid(cpfValue)) {
                    return Promise.resolve()
                  } else {
                    return Promise.reject(new Error())
                  }
                },
                message: <div className="normal-12">CPF inválido</div>,
              }),
            ]}
          >
            <FlattedMaskedInput
              mask="000.000.000-00"
              placeholder="CPF"
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Row>
            <ErrorMessage
              text={signersState.errMessage}
              isVisible={!!signersState.errMessage}
            />
          </Row>
          <Row justify="end" gutter={[16, 0]}>
            <Col>
              <WhiteButton
                type="button"
                onClick={() => {
                  dispatch(resetErrorsState())
                  onCancel()
                }}
              >
                Cancelar
              </WhiteButton>
            </Col>
            <Col>
              <BlueButton
                isLoading={
                  signersState.status.saveSigner === StateStatus.loading
                }
                type="button"
                onClick={() => form.submit()}
              >
                Salvar
              </BlueButton>
            </Col>
          </Row>
        </Form>
      </Row>
    </>
  )
}

const SignerMenuItem = ({
  form,
  name,
  contractId,
  idxEditing,
  onChangeIdxEditing,
  proposalRole,
  signersData,
}) => {
  const [isOpened, setIsOpened] = useState(false)
  const [signers, setSigners] = useState([])
  const [showModal, setShowModal] = useState({})
  const [count, setCount] = useState(0)
  const signersState = useSelector(selectSignersState)
  const dispatch = useDispatch()

  useEffect(() => {
    setSigners(signersData ?? [])
  }, [signersData])

  useEffect(() => {
    let count = 0
    signers.forEach((x) => {
      if (x.proposal_role === proposalRole) count += 1
    })
    setCount(count)
  }, [signers])

  const showFormEditSigner = (signer) => {
    const newSigners = signers.filter((x) => !!x.id) // remove signers sem id
    const newIdxEditing = signers.findIndex((x) => x.id === signer.id)
    onChangeIdxEditing(newSigners, newIdxEditing)
    dispatch(resetErrorsState())
  }

  const hideFormEditSigner = () => {
    const newSigners = signers.filter((x) => !!x.id) // remove signers sem id
    onChangeIdxEditing(newSigners, null)
    dispatch(resetErrorsState())
  }

  const showFormAddSigner = () => {
    const newSigner = {
      proposal_role: proposalRole,
      contract_id: contractId,
    }
    const newSigners = [...signers, newSigner]
    const newIdxEditing = newSigners.length - 1
    form.resetFields()
    onChangeIdxEditing(newSigners, newIdxEditing)
    dispatch(resetErrorsState())
  }

  const deleteSigner = (signer) => {
    const contractId = signer.contract_id
    const id = signer.id
    dispatch(removeSigner({ contractId, id }))
  }

  const { status } = signersState.data.contract
  const canEditSigners = ["draft", "created"].includes(status)

  return (
    <>
      <Menu.Item>
        <Row
          className="drop-arrow-menu-name"
          onClick={() => setIsOpened(!isOpened)}
          justify="space-between"
        >
          <Col className="normal-14-500">
            {name}
            <span className="count-signers">
              <span>{count}</span>
            </span>
          </Col>
          <Col className="drop-arrow-icon">
            {isOpened ? (
              <Icons icon="flat-arrow-down" />
            ) : (
              <Icons icon="flat-arrow-right" />
            )}
          </Col>
        </Row>
      </Menu.Item>
      <Row
        className={`drop-arrow-options ${isOpened ? "opened" : ""}`}
        justify="center"
      >
        {signers.map((item, index) =>
          item.proposal_role === proposalRole ? (
            <SignerRow
              key={index}
              form={form}
              signerData={item}
              contractId={contractId}
              isEditing={idxEditing === index}
              onDelete={() => {
                if (canEditSigners) {
                  deleteSigner(item)
                } else {
                  setShowModal({ type: "delete-signer-warning", signer: item })
                }
              }}
              onEdit={() => {
                if (canEditSigners) {
                  showFormEditSigner(item)
                } else {
                  setShowModal({ type: "edit-signer-warning", signer: item })
                }
              }}
              onCancel={() => hideFormEditSigner()}
            />
          ) : null
        )}
        <Row justify="end">
          {signers.filter((x) => x.proposal_role === proposalRole).length <
            MAX_SIGNERS_BY_PROPOSAL_ROLE && idxEditing === null ? (
            <WhiteButton
              type="button"
              onClick={() => {
                if (canEditSigners) {
                  showFormAddSigner()
                } else {
                  setShowModal({ type: "edit-signer-warning", signer: null })
                }
              }}
            >
              <Icons
                icon="plus"
                style={{ position: "relative", left: "-15px" }}
              />
              Adicionar assinante
            </WhiteButton>
          ) : null}
        </Row>
      </Row>
      <BaseModal
        isVisible={showModal.type === "edit-signer-warning"}
        title="Edição de assinantes"
        width={600}
        cancelButtonText="Cancelar"
        onCancel={() => setShowModal({})}
        okButtonText="Continuar"
        onOk={() => {
          if (showModal.signer) {
            showFormEditSigner(showModal.signer)
          } else {
            showFormAddSigner()
          }
          setShowModal({})
        }}
      >
        <div style={{ marginBottom: "10px" }}>
          {showModal.signer
            ? "Ao editar assinantes deste contrato todos precisarão assinar novamente."
            : "Ao adicionar assinantes neste contrato todos precisarão assinar novamente."}
        </div>
        <div>Deseja continuar mesmo assim?</div>
      </BaseModal>
      <BaseModal
        isVisible={showModal.type === "delete-signer-warning"}
        title="Remoção de assinantes"
        width={600}
        cancelButtonText="Cancelar"
        onCancel={() => setShowModal({})}
        okButtonText="Continuar"
        onOk={() => {
          deleteSigner(showModal.signer)
          setShowModal({})
        }}
      >
        <div style={{ marginBottom: "10px" }}>
          Ao remover assinantes deste contrato todos precisarão assinar
          novamente.
        </div>
        <div>Deseja continuar mesmo assim?</div>
      </BaseModal>
    </>
  )
}

const SignersModal = ({ contractId, isVisible, isPublicPage, ...props }) => {
  const signersState = useSelector(selectSignersState)
  const dispatch = useDispatch()
  const [signers, setSigners] = useState()
  const [idxEditing, setIdxEditing] = useState()
  const [form] = Form.useForm()

  useEffect(() => {
    // localStorage.setItem("edit-signers-from-public-page", false)
    if (isVisible) {
      // if (isPublicPage) { // usado pro contraparte editar sem login, só com token
      //   localStorage.setItem("edit-signers-from-public-page", true)
      // }
      dispatch(getContractInfo(contractId))
    } else {
      setSigners([])
      setIdxEditing(null)
    }
  }, [contractId, isVisible])

  useEffect(() => {
    if (signersState.status.getContractInfo === StateStatus.succeeded) {
      setSigners(signersState.data.contract.signers)
      setIdxEditing(null)
      form.resetFields()
    }
  }, [signersState.status.getContractInfo])

  useEffect(() => {
    if (signersState.status.removeSigner === StateStatus.succeeded) {
      dispatch(getContractInfo(contractId))
    }
  }, [signersState.status.removeSigner])

  useEffect(() => {
    if (signersState.status.saveSigner === StateStatus.succeeded) {
      dispatch(getContractInfo(contractId))
    }
  }, [signersState.status.saveSigner])

  return signersState.data.contract?.id === contractId ? (
    <BaseModal
      className="signers-modal"
      title="Assinantes do contrato"
      isVisible={isVisible}
      width={"40%"}
      {...props}
    >
      <div className="normal-16" style={{ marginBottom: "8px" }}>
        Gerencie quem deverá assinar este contrato.
      </div>
      <div className="normal-14">
        As pessoas inseridas receberão este contrato pelo email que você
        preencher.
      </div>
      <br />
      {signersState.status.getContractInfo === StateStatus.loading &&
      !signersState.data?.contract?.signers ? (
        <div className="main-loading">
          <Loading />
        </div>
      ) : (
        <>
          {/* <Row className="normal-18">Assinantes principais</Row>
          <Row>
            <Menu style={{ width: "100%" }} className="signers-drop-menu">
              <SignerMenuItem
                contractId={contractId}
                proposalRole={null}
                signersData={mainSigners}
                name={
                  <>
                    <span className="normal-14-500">Contratante</span> e{" "}
                    <span className="normal-14-500">Contratado</span>
                  </>
                }
              />
            </Menu>
          </Row>
          <br /> */}
          {/* <Row className="normal-18">Assinantes</Row> */}
          <Row>
            <Menu style={{ width: "100%" }} className="signers-drop-menu">
              <SignerMenuItem
                form={form}
                idxEditing={idxEditing}
                onChangeIdxEditing={(signers, idxEditing) => {
                  setSigners(signers)
                  setIdxEditing(idxEditing)
                }}
                contractId={contractId}
                proposalRole="CONTRACTED"
                signersData={signers}
                name={
                  <span className="normal-14">
                    Assinantes do{" "}
                    <span className="normal-14-500">Contratado</span>
                  </span>
                }
              />
            </Menu>
            <Menu style={{ width: "100%" }} className="signers-drop-menu">
              <SignerMenuItem
                form={form}
                idxEditing={idxEditing}
                onChangeIdxEditing={(signers, idxEditing) => {
                  setSigners(signers)
                  setIdxEditing(idxEditing)
                }}
                contractId={contractId}
                proposalRole="CONTRACTOR"
                signersData={signers}
                name={
                  <span className="normal-14">
                    Assinantes do{" "}
                    <span className="normal-14-500">Contratante</span>
                  </span>
                }
              />
            </Menu>
            {["waiting_sign", "signing"].includes(
              signersState.data.contract.status
            ) ? (
              <div className="warning-edit-signers">
                <span>Atenção: </span>
                <span>
                  ao adicionar ou editar assinantes deste contrato todos
                  precisarão assinar novamente.
                </span>
              </div>
            ) : null}
          </Row>
        </>
      )}
    </BaseModal>
  ) : null
}

SignerRow.propTypes = {
  form: PropTypes.object,
  isEditing: PropTypes.bool,
  signerData: PropTypes.object,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onCancel: PropTypes.func,
}

SignerMenuItem.propTypes = {
  form: PropTypes.object,
  idxEditing: PropTypes.number,
  onChangeIdxEditing: PropTypes.func,
  name: PropTypes.element.isRequired,
  contractId: PropTypes.string.isRequired,
  proposalRole: PropTypes.string,
  signersData: PropTypes.array.isRequired,
}

SignersModal.propTypes = {
  contractId: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  isPublicPage: PropTypes.bool,
}

export default SignersModal
