import React, { useEffect, useState } from "react"
import { PlainButton } from "../../../../common/components/Button"
import BaseModal from "../../../../common/components/Modal"
import { useDispatch, useSelector } from "react-redux"
import { Row, Col } from "antd"
import Search from "../../../../common/components/Search"
import { BankAccountModal } from "./components/modal"
import {
  listBankAccounts,
  selectBankAccount,
  selectBankAccountsData,
  selectConfigState,
  searchBankAccount,
  addBankAccountData,
  updateBankAccount,
  createBankAccount,
  removeBankAccount,
  resetActionsStatus,
} from "../../configurationsSlice"
import StateStatus from "../../../../utils/stateStatus"
import emptyImg from "../../../../common/assets/images/dados-bancarios.png"
import "./styles.scss"
import Icons from "../../../../common/components/Icons"
import Loading from "../../../../common/components/Loading"

const BankAccountList = () => {
  const [bankAcc, updateBankAcc] = useState()
  const [showBankAccountModal, updateShowBankAccountModal] = useState(false)
  const [showDeleteModal, updateShowDeleteModal] = useState(false)
  const [showErrorModal, updateShowErrorModal] = useState(false)
  const configState = useSelector(selectConfigState)
  const bankAccount = useSelector(selectBankAccount)
  const bankAccounts = useSelector(selectBankAccountsData)
  const dispatch = useDispatch()

  useEffect(() => {
    document.body.style.overflow = "auto"
    dispatch(listBankAccounts({ filter: bankAccounts.filter }))
  }, [])

  useEffect(() => {
    switch (configState.status.listBankAccounts) {
      case StateStatus.idle:
        dispatch(listBankAccounts({ filter: bankAccounts.filter }))
        break
      case StateStatus.failed:
        updateShowErrorModal(true)
        break
    }
  }, [configState.status.listBankAccounts])

  useEffect(
    () => handleActionsStatus(configState.status.removeBankAccount),
    [configState.status.removeBankAccount]
  )

  useEffect(
    () => handleActionsStatus(configState.status.createBankAccount),
    [configState.status.createBankAccount]
  )

  useEffect(
    () => handleActionsStatus(configState.status.updateBankAccount),
    [configState.status.updateBankAccount]
  )

  const handleActionsStatus = (status) => {
    switch (status) {
      case StateStatus.succeeded:
        dispatch(searchBankAccount(bankAccounts.filter))
        break
      case StateStatus.failed:
        updateShowErrorModal(true)
        dispatch(resetActionsStatus())
        break
    }
  }

  const firstLoading =
    (!bankAccounts.items || bankAccounts.items.length === 0) &&
    (configState.status.listBankAccounts === StateStatus.idle ||
      configState.status.listBankAccounts === StateStatus.loading)
  const loading = configState.status.listBankAccounts === StateStatus.loading
  const nodata =
    bankAccounts.items &&
    bankAccounts.items.length === 0 &&
    bankAccounts.filter === "" &&
    configState.status.listBankAccounts === StateStatus.succeeded

  return (
    <div className="config-tab-content">
      <div className={`root-content ${firstLoading ? "" : "visible"}`}>
        <div>
          <div className="normal-16-500">Lista de contas cadastradas</div>
          <div className="normal-12">
            Consulte, edite ou adicione uma conta para deixar castrada em sua
            lista de dados bancários.
          </div>
        </div>
        <Row justify="space-between">
          <Search
            placeholder="Pesquisar"
            onSearch={(value) => dispatch(searchBankAccount(value))}
          />
          <PlainButton
            icon={<Icons icon="plus" />}
            onClick={() => {
              addBankAccountData({})
              updateShowBankAccountModal(true)
            }}
          >
            Adicionar conta
          </PlainButton>
        </Row>

        {!nodata && (
          <Row
            justify="space-between"
            gutter={[16, 16]}
            className="normal-14-500 header-row"
          >
            <Col span={4}>Nome Favorecido</Col>
            <Col span={6}>Nº do Banco/Instituição</Col>
            <Col span={6}>Dados da Conta</Col>
            <Col span={6}>Chave PIX</Col>
            <Col span={2} style={{ textAlign: "center" }}>
              Ações
            </Col>
          </Row>
        )}

        {loading ? (
          <Loading />
        ) : (
          bankAccounts.items &&
          bankAccounts.items.map((item, index) => (
            <Row
              key={index}
              justify="space-between"
              gutter={[16, 16]}
              className="normal-14 body-row"
              align="middle"
            >
              <Col span={4} className="not-overflow">
                {item.owner_name ? item.owner_name : "-"}
              </Col>
              <Col span={6} className="not-overflow">
                {item.bank_name}
              </Col>
              <Col span={6}>
                Ag: {item.agency} | CC: {item.account_number}{" "}
              </Col>
              <Col span={6}>{item.pix_key} </Col>
              <Col span={2}>
                <Row justify="space-around">
                  <div
                    className="edit-button"
                    onClick={() => {
                      dispatch(addBankAccountData(item))
                      updateShowBankAccountModal(true)
                    }}
                  >
                    <Icons icon="edit" title="Editar" />
                  </div>
                  <div
                    className="delete-button"
                    onClick={() => {
                      updateBankAcc(item)
                      updateShowDeleteModal(true)
                    }}
                  >
                    <Icons icon="delete" title="Excluir" />
                  </div>
                </Row>
              </Col>
            </Row>
          ))
        )}

        {firstLoading ? (
          <Loading />
        ) : (
          nodata && (
            <div className="empty-card">
              <img
                src={emptyImg}
                alt="imagem para simbolizar que a lista está vazia"
              />
              <span className="normal-14-400">
                Você não possui dados bancários cadastrados
              </span>
            </div>
          )
        )}
      </div>
      {showBankAccountModal && (
        <BankAccountModal
          data={bankAccount}
          isLoading={
            configState.status.createBankAccount === StateStatus.loading ||
            configState.status.updateBankAccount === StateStatus.loading
          }
          onSubmit={(data) => {
            delete data.bank_name
            const bankAccountId = bankAccount.id

            if (bankAccountId) {
              dispatch(updateBankAccount({ bankAccountId, data }))
            } else {
              dispatch(createBankAccount(data))
            }

            searchBankAccount("")
            updateShowBankAccountModal(false)
            updateBankAccount(null)
          }}
          onCancel={() => {
            dispatch(addBankAccountData({}))
            updateShowBankAccountModal(false)
          }}
        />
      )}
      <BaseModal
        isVisible={showErrorModal}
        title="Erro!"
        okButtonText="Ok"
        onOk={() => {
          updateShowErrorModal(false)
        }}
      >
        {configState.errMessage}
      </BaseModal>
      <BaseModal
        isVisible={showDeleteModal}
        title={"Excluir conta"}
        isLoading={configState.status.removeBankAccount === StateStatus.loading}
        okButtonText={"Sim"}
        okButtonColor="red"
        onOk={() => {
          dispatch(removeBankAccount(bankAcc.id)).then(() => {
            updateShowDeleteModal(false)
          })
        }}
        cancelButtonText={"Não"}
        onCancel={() => {
          updateShowDeleteModal(false)
        }}
      >
        <Row justify="center" gutter={[8, 8]}>
          <Col span={24} style={{ textAlign: "center" }}>
            Tem certeza que deseja excluir esta conta?
          </Col>
          {bankAcc && (
            <Col span={24} style={{ textAlign: "center" }}>
              {bankAcc.bank_name} <br />
              Ag: {bankAcc.agency} | CC: {bankAcc.account_number}
            </Col>
          )}
        </Row>
      </BaseModal>
    </div>
  )
}

export default BankAccountList
