import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import Icons from "../../../../common/components/Icons"
import BaseModal from "../../../../common/components/Modal"
import StateStatus from "../../../../utils/stateStatus"
import {
  getProfile,
  selectConfigState,
} from "../../../configurations/configurationsSlice"
import {
  resendConfirmAccountEmail,
  selectConfirmAccountState,
} from "../../../confirmAccount/confirmAccountSlice"
import "./styles.scss"

const RestricionsModal = () => {
  const config = useSelector(selectConfigState)
  const confirmAccount = useSelector(selectConfirmAccountState)
  const history = useHistory()
  const dispatch = useDispatch()
  const profileData = config.data.profileData
  const [showModal, updateShowModal] = useState()

  const checkProfile = () => {
    dispatch(getProfile()).then(({ payload }) => {
      const data = payload.data
      const isIncomplete =
        data.incomplete &&
        (data.incomplete_data.phone || data.incomplete_data.address)

      if (isIncomplete && !data.email_confirmed) {
        updateShowModal(1.1)
        localStorage.setItem("restrict-steps-modal-was-opened", true)
      } else if (isIncomplete) {
        updateShowModal(2)
      } else if (!data.email_confirmed) {
        if (localStorage.getItem("restrict-steps-modal-was-opened")) {
          updateShowModal(1.2)
        } else {
          updateShowModal(3)
        }
      } else if (localStorage.getItem("restrict-steps-modal-was-opened")) {
        updateShowModal(1.3)
      }
    })
  }

  useEffect(() => {
    checkProfile()
  }, [])

  return (
    <>
      <BaseModal
        className="restrict-proposal-modal"
        isVisible={showModal === 1.1}
        title=""
        okButtonText="Completar Cadastro"
        isClosable={false}
        onCancel={() => {
          history.replace("/dashboard/proposals")
        }}
        onOk={() => {
          history.replace("/dashboard/config")
        }}
      >
        <div className="steps">
          <span>1ª etapa de 3</span>
        </div>
        <p className="normal-16-500">
          Olá! <br />
          Seu cadastro continua incompleto.
        </p>
        Você precisa completar o seu cadastro para poder criar propostas e ter
        um melhor aproveitamento do Propozall.
        <br />
        Dados requeridos:
        <br />
        <br />
        {profileData && profileData.incomplete && (
          <ul>
            {profileData.incomplete_data.phone && <li>Telefone</li>}
            {profileData.incomplete_data.address && <li>Endereço</li>}
          </ul>
        )}
      </BaseModal>
      <BaseModal
        className="restrict-proposal-modal"
        isVisible={showModal === 1.2}
        title=""
        okButtonText={"Reenviar"}
        isClosable={false}
        isLoading={
          confirmAccount.status.resendConfirmAccountEmail ===
          StateStatus.loading
        }
        onCancel={() => {
          history.replace("/dashboard/proposals")
        }}
        onOk={() => {
          dispatch(resendConfirmAccountEmail())
        }}
      >
        <div className="steps">
          <span>2ª etapa de 3</span>
        </div>
        <p className="normal-16-500">
          Poxa, <br />
          Seu e-mail ainda não foi verificado.
        </p>
        <p>
          E você precisa verificar o seu endereço de e-mail para ativar sua
          conta.
          <br />
          Se não encontrar na sua caixa de entrada do e-mail
          <span className="normal-14-bold"> {profileData.email} </span>
          procure também na sua caixa de Spam.
          <br />
          Caso não tenha recebido clique no botão abaixo.
        </p>
        {confirmAccount.status.resendConfirmAccountEmail ===
          StateStatus.succeeded && (
          <p className="normal-14-500 message-email-sent">
            <Icons icon="circle-check" /> E-mail enviado!
          </p>
        )}
      </BaseModal>
      <BaseModal
        className="restrict-proposal-modal"
        isVisible={showModal === 1.3}
        title=""
        okButtonText={"Criar proposta"}
        isClosable={false}
        isLoading={
          confirmAccount.status.resendConfirmAccountEmail ===
          StateStatus.loading
        }
        onCancel={() => {
          updateShowModal(null)
          localStorage.removeItem("restrict-steps-modal-was-opened")
        }}
        onOk={() => {
          updateShowModal(null)
          localStorage.removeItem("restrict-steps-modal-was-opened")
        }}
      >
        <div className="steps">
          <span>3ª etapa de 3</span>
        </div>
        <p className="normal-16-500">Cadastro concluído</p>
        <p>
          Agora que você terminou de preencher seus dados cadastrais, já pode
          criar sua proposta.
        </p>
      </BaseModal>
      <BaseModal
        className="restrict-proposal-modal"
        isVisible={showModal === 2}
        title=""
        okButtonText="Vamos lá"
        isClosable={false}
        onCancel={() => {
          history.replace("/dashboard/proposals")
        }}
        onOk={() => {
          history.replace("/dashboard/config")
        }}
      >
        <p className="normal-16-500 title">
          Olá! <br />
          Seu cadastro continua incompleto.
        </p>
        Você precisa completar o seu cadastro para poder criar propostas e ter
        um melhor aproveitamento do Propozall.
        <br />
        Dados incompletos:
        <br />
        <br />
        {profileData && profileData.incomplete && (
          <ul>
            {profileData.incomplete_data.phone && <li>Telefone</li>}
            {profileData.incomplete_data.address && <li>Endereço</li>}
          </ul>
        )}
      </BaseModal>
      <BaseModal
        className="restrict-proposal-modal"
        isVisible={showModal === 3}
        title=""
        okButtonText={"Reenviar"}
        isClosable={false}
        isLoading={
          confirmAccount.status.resendConfirmAccountEmail ===
          StateStatus.loading
        }
        onCancel={() => {
          history.replace("/dashboard/proposals")
        }}
        onOk={() => {
          dispatch(resendConfirmAccountEmail())
        }}
      >
        <p className="normal-16-500">
          Poxa, <br />
          Seu e-mail ainda não foi verificado.
        </p>
        <p>
          E você precisa verificar o seu endereço de e-mail para ativar sua
          conta.
          <br />
          Se não encontrar na sua caixa de entrada do e-mail
          <span className="normal-14-bold"> {profileData.email} </span>
          procure também na sua caixa de Spam.
          <br />
          Caso não tenha recebido clique no botão abaixo.
        </p>
        {confirmAccount.status.resendConfirmAccountEmail ===
          StateStatus.succeeded && (
          <p className="normal-14-500 message-email-sent">
            <Icons icon="circle-check" /> E-mail enviado!
          </p>
        )}
      </BaseModal>
    </>
  )
}

export default RestricionsModal
