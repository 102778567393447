import React from "react"

export const EditIcon = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <defs>
          <rect
            id="propozall-edit-icon-path"
            x="0"
            y="0"
            width="24"
            height="24"
          ></rect>
        </defs>
        <g
          id="propozall-edit-icon"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="propozall-edit-icon-1"
            transform="translate(-1125.000000, -410.000000)"
          >
            <g
              id="propozall-edit-icon-2"
              transform="translate(129.000000, 394.000000)"
            >
              <g transform="translate(24.000000, 16.000000)" id="Group">
                <g
                  transform="translate(972.000000, 0.000000)"
                  id="propozall-edit-icon-mask-3"
                >
                  <mask id="propozall-edit-icon-mask" fill="white">
                    <use xlinkHref="#propozall-edit-icon-path"></use>
                  </mask>
                  <g id="propozall-edit-icon-4"></g>
                  <path
                    d="M4.63922005,17.0363056 L4.42414663,19.4021126 C4.37449524,19.9482782 4.83205077,20.4058348 5.37821637,20.3561833 L7.74402417,20.1411098 L17.6793829,10.2057511 L14.5745787,7.10094695 L4.63922005,17.0363056 Z M20.1632404,7.72189356 C20.5061873,7.37894669 20.5061876,6.82291909 20.1632404,6.47997191 L18.3003579,4.61708944 C17.9574107,4.27414226 17.4013831,4.27414256 17.0584363,4.61708944 L15.1955396,6.47998613 L18.3003437,9.58479025 L20.1632404,7.72189356 Z"
                    fill="#000000"
                    mask="url(#propozall-edit-icon-mask)"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </span>
  )
}
