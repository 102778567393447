import React from "react"

export const SuitcaseIcon = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 26 26"
        fill="none"
      >
        <title>suitcase</title>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.0008 22.0035H5.99497C4.88994 22.0035 3.99414 21.1077 3.99414 20.0026V10.9989C3.99414 9.89385 4.88994 8.99805 5.99497 8.99805H20.0008C21.1058 8.99805 22.0016 9.89385 22.0016 10.9989V20.0026C22.0016 21.1077 21.1058 22.0035 20.0008 22.0035Z"
          stroke="#001B66"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
        <path
          d="M17.0014 22.0039V6.99766C17.0014 5.89263 16.1056 4.99683 15.0005 4.99683H10.9989C9.89385 4.99683 8.99805 5.89263 8.99805 6.99766V22.0039"
          stroke="#001B66"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
      </svg>
    </span>
  )
}
