import React from "react"
import PropTypes from "prop-types"
import "./styles.scss"

const Stepper = ({ steps, active, maxStep, onChange }) => (
  <div data-testid="step" className="stepper-container">
    <div className="stepper-line" />

    {steps.map((item, index) => {
      let className = ""

      if (index + 1 < active) className = "step-circle-active"
      else if (index + 1 > active) className = "step-circle-non-active"
      else className = "step-circle-active step-circle-atual"

      return (
        <div
          role="button"
          tabIndex={0}
          aria-label="change step"
          onClick={() => {
            if (maxStep >= index + 1 && index + 1 !== active && maxStep !== 4)
              onChange(index + 1)
          }}
          onKeyDown={() => {
            if (maxStep >= index + 1 && index + 1 !== active && maxStep !== 4)
              onChange(index + 1)
          }}
          className={`container-step ${
            maxStep >= index + 1 && index + 1 !== active && maxStep !== 4
              ? "pointer"
              : "default"
          }`}
          key={index}
        >
          <div
            data-testid={`step-circle-${index}`}
            className="step-circle-unit"
          >
            <span className={`step-circle ${className}`}>{index + 1}</span>

            <span
              className={`step-label font-16 ${
                index + 1 <= active ? "w-700" : "w-600 color-gray-5"
              }`}
            >
              {item}
            </span>
          </div>
        </div>
      )
    })}
  </div>
)

Stepper.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  active: PropTypes.number.isRequired,
  maxStep: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default Stepper
