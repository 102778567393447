import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import StateStatus from "../../utils/stateStatus"
import {
  statistics,
  graphStatistics,
  invoicingProgress,
  params,
  spreadsheet,
  // download,
} from "./countersPageService"

export const getInvoicingProgress = createAsyncThunk(
  "counters/invoicing-progress",
  async () => {
    const response = await invoicingProgress()
    return response.data
  }
)

export const getStatistics = createAsyncThunk(
  "counters/statistics",
  async ({ status, role }) => {
    const response = await statistics({ status, role })
    return response.data
  }
)

export const getGraphStatistics = createAsyncThunk(
  "counters/graph_statistics",
  async ({ period, role }, thunkAPI) => {
    const { counters } = thunkAPI.getState()

    const periods = counters.data.selectedPeriods
      .map((item) => `${item.value}`)
      .join()
    const tags = counters.data.selectedTags
      .map((item) => `${item.value}`)
      .join()
    const itemTypes = counters.data.selectedItemTypes
      .map((item) => `${item.value}`)
      .join()

    const response = await graphStatistics({
      period,
      role,
      series: periods,
      item_types: itemTypes,
      filter: counters.data.search,
      tags,
    })

    return response.data
  }
)

// export const downloadGraphStatistics = createAsyncThunk(
//   "counters/download/",
//   async ({ type }) => {
//     alert(`em progresso: ${type}`)
//     const response = await download({ type })

//     console.log(response)

//     return true
//   }
// )

export const exportSpreadsheet = createAsyncThunk(
  "counters/export-spreadsheet",
  async ({ start, end, role }) => {
    const response = await spreadsheet({
      starts_at: start,
      ends_at: end,
      role,
    })

    return response.data
  }
)
export const getGraphParams = createAsyncThunk(
  "counters/graph_statistics/show",
  async ({ role }) => {
    const response = await params({ role })

    return response.data
  }
)

const initialData = {
  total_value: 0,
  average: 0,
  count: 0,
  diff: 0,
}

const initialState = {
  status: {
    getStatistics: StateStatus.idle,
    getGraphParams: StateStatus.idle,
    getGraphStatistics: StateStatus.idle,
    exportSpreadsheet: StateStatus.idle,
    getInvoicingProgress: StateStatus.idle,
  },
  data: {
    invoicingProgress: {},
    tags: [],
    series: [],
    periods: [],
    //
    total: { ...initialData },
    waiting: { ...initialData },
    accepted: { ...initialData },
    expired: { ...initialData },
    //
    search: "",
    selectedTags: [],
    selectedPeriods: [],
    selectedItemTypes: [],
  },
  errMessage: null,
}

export const counterSlice = createSlice({
  name: "counters",
  initialState,
  reducers: {
    updatePeriod: (state, action) => {
      state.data.selectedPeriods = action.payload
    },
    updateTags: (state, action) => {
      state.data.selectedTags = action.payload
    },
    updateItemTypes: (state, action) => {
      state.data.selectedItemTypes = action.payload
    },
    updateSearch: (state, action) => {
      state.data.search = action.payload
    },
    //
    clearExportStatus: (state) => {
      state.status.exportSpreadsheet = StateStatus.idle
    },
  },
  extraReducers: {
    [getStatistics.pending]: (state) => {
      state.status.getStatistics = StateStatus.loading
    },
    [getStatistics.fulfilled]: (state, action) => {
      state.data.total = action.payload.data.total || state.data.total
      state.data.waiting = action.payload.data.waiting || state.data.waiting
      state.data.accepted = action.payload.data.accepted || state.data.accepted
      state.data.expired = action.payload.data.expired || state.data.expired

      state.status.getStatistics = StateStatus.succeeded
    },
    [getStatistics.rejected]: (state, action) => {
      state.errMessage = action.error.message
      state.status.getStatistics = StateStatus.failed
    },
    // ------------------
    [getGraphStatistics.pending]: (state) => {
      state.status.getGraphStatistics = StateStatus.loading
    },
    [getGraphStatistics.fulfilled]: (state, action) => {
      state.data.series = action.payload.data.series

      state.status.getGraphStatistics = StateStatus.succeeded
    },
    [getGraphStatistics.rejected]: (state, action) => {
      state.errMessage = action.error.message
      state.status.getGraphStatistics = StateStatus.failed
    },
    // ------------------
    [getInvoicingProgress.pending]: (state) => {
      state.status.getInvoicingProgress = StateStatus.loading
    },
    [getInvoicingProgress.fulfilled]: (state, action) => {
      state.data.invoicingProgress = action.payload.data
      state.status.getInvoicingProgress = StateStatus.succeeded
    },
    [getInvoicingProgress.rejected]: (state, action) => {
      state.errMessage = action.error.message
      state.status.getInvoicingProgress = StateStatus.failed
    },
    // ------------------
    [exportSpreadsheet.pending]: (state) => {
      state.status.exportSpreadsheet = StateStatus.loading
    },
    [exportSpreadsheet.fulfilled]: (state, action) => {
      state.status.exportSpreadsheet = StateStatus.succeeded
    },
    [exportSpreadsheet.rejected]: (state, action) => {
      state.errMessage = action.error.message
      state.status.exportSpreadsheet = StateStatus.failed
    },
    // ------------------
    [getGraphParams.pending]: (state) => {
      state.status.getGraphParams = StateStatus.loading
    },
    [getGraphParams.fulfilled]: (state, action) => {
      state.data.itemTypes = action.payload.data.item_types
      state.data.tags = action.payload.data.tags
      state.data.periods = action.payload.data.series

      state.status.getGraphParams = StateStatus.succeeded
    },
    [getGraphParams.rejected]: (state, action) => {
      state.errMessage = action.error.message
      state.status.getGraphParams = StateStatus.failed
    },
  },
})

export const {
  updateTags,
  updateItemTypes,
  updatePeriod,
  updateSearch,
  clearExportStatus,
} = counterSlice.actions

export const statisticsSelector = (state) => state.counters
