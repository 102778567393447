import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
//
import { useDispatch, useSelector } from "react-redux"
import {
  listCompanies,
  selectConfigState,
  selectProfileData,
} from "../../../configurations/configurationsSlice"
//
import { FavoriteIcon } from "../../../../common/components/FavoriteIcon"
import { CreateCompanyModal } from "../../../company/company"
import Select from "antd/es/select"
//
import StateStatus from "../../../../utils/stateStatus"
import { beautifyCpfCnpj } from "../../../../utils/formaters"
import { PlainButton } from "../../../../common/components/Button"
import Icons from "../../../../common/components/Icons"
import { cpf } from "cpf-cnpj-validator"
//
import "./styles.scss"
import { Form, Row, Col } from "antd"
import FlattedMaskedInput from "../../../../common/components/FlattedInput"

const SelectCNPJ = ({
  form,
  name,
  value,
  role,
  onChange,
  onSelected,
  ...props
}) => {
  const dispatch = useDispatch()
  //
  const config = useSelector(selectConfigState)
  const profileData = useSelector(selectProfileData)
  const [companies, updateCompanies] = useState([])
  const [isCpf, setIsCpf] = useState(false)
  const [inputCpf, setInputCpf] = useState()
  // const proposal = proposalState.data.proposal || {}
  const [showModal, updateShowModal] = useState(false)

  const userCPF = profileData.cpf ?? null

  useEffect(() => {
    if (value?.length === 11 || value === "MEU_CPF") {
      setIsCpf(true)
      setInputCpf(userCPF ?? value)
    } else if (value?.length === 14 || !value) {
      setIsCpf(false)
      setInputCpf(userCPF ?? null)
    }

    if (config.status.listCompanies === StateStatus.idle) {
      dispatch(listCompanies({ filter: "" }))
    }
  }, [])

  useEffect(() => {
    if (config.status.listCompanies === StateStatus.succeeded) {
      const companies = config.data.companies.items || []
      updateCompanies(companies)
    }
  }, [config.status.listCompanies])

  const setFieldValue = (name, value) => {
    const fields = {}
    fields[name] = value
    form.setFieldsValue(fields)
  }

  const updateSelected = (data) => {
    if (data.cpfCnpj?.length === 11) {
      setFieldValue(name, data.cpfCnpj)
      setFieldValue("user_cpf", data.cpfCnpj)
    } else {
      setFieldValue(name, data.cpfCnpj)
      setFieldValue("user_cpf", undefined)
    }

    data = {
      cpf_cnpj: data.cpfCnpj,
      company_name: data.name,
    }

    onSelected && onSelected(data)
  }

  return (
    <>
      <Row
        gutter={[16, 16]}
        align="bottom"
        className="row-select-cnpj-proposal"
      >
        <Col span={props.span ?? 14}>
          <Form.Item
            name={name}
            initialValue={value}
            onChange={onChange} // Requerido pelo Form.Item do antd
            {...props}
          >
            <Select
              className="select-cnpj-proposal"
              filterOption={false}
              placeholder="Selecione o CNPJ"
              loading={config.status.listCompanies === StateStatus.loading}
              notFoundContent={
                <center className="normal-14">CNPJ não cadastrado</center>
              }
              onChange={(value, option) => {
                if (option.key === "MEU_CPF") {
                  setIsCpf(true)
                  updateSelected({
                    cpfCnpj: value,
                    name: profileData.name,
                  })
                } else {
                  setIsCpf(false)
                  setInputCpf(null)
                  const selected = companies.filter((c) => c.cnpj === value)[0]
                  if (selected) {
                    updateSelected({ cpfCnpj: value, name: selected.name })
                  }
                }
              }}
            >
              {role === "CONTRACTOR" ? (
                <Select.Option
                  key={"MEU_CPF"}
                  value={inputCpf ?? "MEU_CPF"}
                  className="select-cnpj-option"
                >
                  <div>Meu CPF</div>
                </Select.Option>
              ) : null}

              {companies &&
                companies.map((item, index) => (
                  <Select.Option
                    key={index}
                    value={item.cnpj}
                    className="select-cnpj-option"
                  >
                    <div>
                      <div>{beautifyCpfCnpj(item.cnpj)}</div>
                      <div>
                        {item.name} {item.is_favorite ? <FavoriteIcon /> : null}
                      </div>
                    </div>
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={10}>
          <PlainButton
            icon={<Icons icon="plus" />}
            onClick={() => updateShowModal(true)}
            className="button-add-option-on-select"
          >
            Adicionar CNPJ
          </PlainButton>
        </Col>
      </Row>
      {isCpf && (
        <Row>
          <Col span={14}>
            <Form.Item
              name={"user_cpf"}
              initialValue={userCPF ? beautifyCpfCnpj(userCPF) : inputCpf}
              label="Meu CPF"
              rules={[
                {
                  required: true,
                  message: "Campo obrigatório",
                },
                () => ({
                  validator(_, value) {
                    if (!value) return Promise.resolve()
                    const val = value.replace(/[^0-9]+/g, "")

                    setInputCpf(val)

                    if (cpf.isValid(val)) {
                      return Promise.resolve()
                    } else {
                      return Promise.reject(new Error())
                    }
                  },
                  message: <div className="normal-12">CPF inválido</div>,
                }),
              ]}
            >
              <FlattedMaskedInput
                readOnly={!!userCPF}
                data-editable={!userCPF}
                mask="000.000.000-00"
                placeholder="Insira seu CPF"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
      )}

      {showModal && (
        <CreateCompanyModal
          data={null}
          onSaved={(company) => {
            dispatch(listCompanies({ filter: "" })).then(() => {
              updateSelected({ cpfCnpj: company.cnpj, name: company.name })
            })
            updateShowModal(false)
          }}
          onCancel={() => {
            updateShowModal(false)
          }}
        />
      )}
    </>
  )
}

SelectCNPJ.propTypes = {
  form: PropTypes.any,
  role: PropTypes.string,
  name: PropTypes.any,
  span: PropTypes.number,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onSelected: PropTypes.func.isRequired,
}

export default SelectCNPJ
