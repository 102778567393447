import React from "react"

export const DeleteIcon = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <defs>
          <rect
            id="propozall-delete-icon-path"
            x="0"
            y="0"
            width="24"
            height="24"
          ></rect>
        </defs>
        <g
          id="propozall-delete-icon"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="propozall-delete-icon-1"
            transform="translate(-1165.000000, -410.000000)"
          >
            <g
              id="propozall-delete-icon-2"
              transform="translate(129.000000, 394.000000)"
            >
              <g
                transform="translate(24.000000, 16.000000)"
                id="propozall-delete-icon-3"
              >
                <g transform="translate(972.000000, 0.000000)">
                  <g
                    transform="translate(40.000000, 0.000000)"
                    id="propozall-delete-icon-4"
                  >
                    <mask id="propozall-delete-icon-mask" fill="white">
                      <use xlinkHref="#propozall-delete-icon-path"></use>
                    </mask>
                    <g id="propozall-delete-icon-4"></g>
                    <path
                      d="M9.8,4 C8.91759996,4 8.2,4.71759996 8.2,5.6 L8.2,6.4 L5,6.4 L5,8 L6.6,8 L6.6,18.4 C6.6,19.2824 7.31759996,20 8.2,20 L16.2,20 C17.0824,20 17.8,19.2824 17.8,18.4 L17.8,8 L19.4,8 L19.4,6.4 L16.2,6.4 L16.2,5.6 C16.2,4.71759996 15.4824,4 14.6,4 L9.8,4 Z M14.6,5.6 L14.6,6.4 L9.8,6.4 L9.8,5.6 L14.6,5.6 Z M8.2,18.4 L8.2,8 L16.2,8 L16.2,18.4 L8.2,18.4 Z M11.4,9.6 L11.4,16.8 L9.8,16.8 L9.8,9.6 L11.4,9.6 Z M14.6,9.6 L14.6,16.8 L13,16.8 L13,9.6 L14.6,9.6 Z"
                      fill="#000000"
                      mask="url(#propozall-delete-icon-mask)"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </span>
  )
}
