import { sectorsMock } from "../sectors"

export const serviceProviderContractorJson = {
  steps: [
    {
      type: "Route",
      step_title: "Descrição",
      next_step: "/dashboard/proposal/1",
      props: {
        path: ["/dashboard/proposal/1", "/dashboard/proposal/1/:id"],
        exact: true,
      },
      elements: [
        {
          type: "p",
          default: "Título da proposta",
          props: {
            className: "input-title",
          },
        },
        {
          type: "Row",
          props: {
            className: "row-input-tittle",
            justify: "start",
            gutter: [16, 16],
            align: "bottom",
          },
          elements: [
            {
              type: "Col",
              props: {
                span: 16,
              },
              elements: [
                {
                  type: "Form.Item",
                  props: {
                    name: "title",
                    label: "",
                    initialValue: "",
                    rules: [
                      {
                        required: true,
                        message: "Campo obrigatório",
                      },
                    ],
                  },
                  element: {
                    type: "FlattedInput",
                    props: {
                      placeholder: "Digite o título",
                    },
                  },
                },
              ],
            },
          ],
        },

        {
          type: "Space",
        },

        {
          type: "Row",
          props: {
            justify: "start",
            gutter: [16, 16],
            align: "bottom",
          },
          elements: [
            {
              type: "Col",
              props: {
                span: 8,
              },
              elements: [
                {
                  type: "div",
                  props: {
                    className: "input-label-help",
                  },
                  elements: [
                    {
                      type: "text",
                      default: "Tipo de serviço",
                    },
                    {
                      type: "Icons",
                      props: {
                        icon: "circle-help",
                        title:
                          "Descreva o tipo de serviço que será prestado. Ex.: projeto arquitetônico, serviço de eletrecista, etc.",
                      },
                    },
                  ],
                },

                {
                  type: "Form.Item",
                  props: {
                    name: "service_name",
                    label: null,
                    initialValue: "",
                    rules: [
                      {
                        required: true,
                        message: "Campo obrigatório",
                      },
                    ],
                  },
                  element: {
                    type: "FlattedInput",
                    props: {
                      placeholder: "Nome",
                    },
                  },
                },
              ],
            },

            {
              type: "Col",
              props: {
                sm: 8,
              },
              elements: [
                {
                  type: "Dropdown",
                  props: {
                    trigger: ["click"],
                    placement: "bottomRight",
                    overlay: {
                      type: "DatePicker",
                      props: {
                        className: "floating",
                        storeValue: "form.valid_until",
                        defaultValue: "form.valid_until",
                      },
                    },
                  },
                  element: {
                    type: "Form.Item",
                    props: {
                      name: "valid_until",
                      label: "Validade da proposta",
                      initialValue: "",
                      rules: [
                        {
                          required: true,
                          message: "Campo obrigatório",
                        },
                      ],
                    },
                    element: {
                      type: "FlattedInput",
                      props: {
                        readOnly: true,
                        placeholder: "Inserir data",
                      },
                    },
                  },
                },
              ],
            },

            {
              type: "Col",
              props: {
                sm: 8,
              },
              elements: [
                {
                  type: "div",
                  props: {
                    className: "input-label-help",
                  },
                  elements: [
                    {
                      type: "text",
                      default: "Data limite de entrega dos serviços",
                    },
                    {
                      type: "Icons",
                      props: {
                        icon: "circle-help",
                        title:
                          "Se quiser estipule um prazo de entrega dos serviços a serem prestados.",
                      },
                    },
                  ],
                },
                {
                  type: "Dropdown",
                  props: {
                    trigger: ["click"],
                    placement: "bottomRight",
                    overlay: {
                      type: "DatePicker",
                      props: {
                        className: "floating",
                        minDate: null,
                        storeValue: "form.date_to_finish",
                        defaultValue: "form.date_to_finish",
                      },
                    },
                  },
                  element: {
                    type: "Form.Item",
                    props: {
                      name: "date_to_finish",
                      label: null,
                      initialValue: "",
                      rules: [
                        {
                          required: true,
                          message: "Campo obrigatório",
                        },
                      ],
                    },
                    element: {
                      type: "FlattedInput",
                      props: {
                        readOnly: true,
                        placeholder: "Data",
                      },
                    },
                  },
                },
              ],
            },
          ],
        },

        {
          type: "Space",
        },

        {
          type: "Row",
          props: {
            justify: "start",
            gutter: [16, 16],
            align: "bottom",
          },
          elements: [
            {
              type: "Col",
              props: {
                sm: 24,
              },
              elements: [
                {
                  type: "div",
                  props: {
                    className: "input-label-help input-title",
                  },
                  elements: [
                    {
                      type: "text",
                      default: "Descrição dos serviços",
                    },
                    {
                      type: "Icons",
                      props: {
                        icon: "circle-help",
                        title:
                          "Utilize esta etapa para descrever todos os serviços que serão prestados de forma detalhada.",
                      },
                    },
                  ],
                },
                {
                  type: "p",
                  default:
                    "Descreva todas as atividades e serviços do seu projeto",
                  props: {
                    className: "input-subtitle",
                  },
                },
              ],
            },
          ],
        },
        {
          type: "Form.Item",
          props: {
            name: "job_description",
          },
          rules: [
            {
              required: true,
              message: "Campo obrigatório",
            },
          ],
          element: {
            type: "TextArea",
            props: {
              placeholder: "Escreva aqui...",
            },
          },
        },

        // divider
        {
          type: "Space",
          props: {},
        },
        {
          type: "Divider",
          props: {},
        },

        // title | text
        {
          type: "p",
          default: "Adicione um arquivo",
          props: {
            className: "input-title",
          },
        },

        // subtitle | text
        {
          type: "p",
          default:
            "Carregue arquivos de áudio, imagem ou texto de tamanho máximo de 5MB",
          props: {
            className: "input-subtitle",
          },
        },

        // select file | select
        {
          type: "SelectFile",
          props: {
            storeValue: "state.files",
          },
        },
      ],
      actions: [
        {
          type: "ButtonPreviousStep",
          props: {
            span: 12,
          },
          elements: [
            {
              type: "text",
              default: "Voltar",
            },
          ],
        },

        {
          type: "ButtonNextStep",
          props: {
            span: 12,
          },
          elements: [
            {
              type: "text",
              default: "Continuar",
            },
          ],
        },
      ],
    },

    // SECOND
    {
      type: "Route",
      step_title: "Dados Contratado",
      props: {
        path: ["/dashboard/proposal/2", "/dashboard/proposal/2/:id"],
      },
      elements: [
        {
          type: "p",
          default: "Dados da contratada",
          props: {
            className: "input-title",
          },
        },
        {
          type: "p",
          default: "Insira as informações cadastrais do contratado",
          props: {
            className: "input-subtitle",
          },
        },

        {
          type: "Row",
          props: {
            justify: "space-between",
            align: "top",
          },
          elements: [
            // contact name
            {
              type: "Col",
              props: {
                sm: 7,
              },
              elements: [
                {
                  type: "Form.Item",
                  props: {
                    name: ["contact", "company_name"],
                    label: "Nome da contratada",
                    rules: [
                      {
                        required: true,
                        message: "Campo obrigatório",
                      },
                    ],
                  },
                  element: {
                    type: "SelectContact",
                  },
                },
              ],
            },

            // cnpj
            {
              type: "Col",
              props: {
                sm: 7,
              },
              elements: [
                {
                  type: "FlattedInputCNPJ",
                  props: {
                    name: ["contact", "cpf_cnpj"],
                    label: "CNPJ da contratada",
                    placeholder: "00.000.000/0000-00",
                    rules: [
                      {
                        required: false,
                        message: "Campo obrigatório",
                      },
                    ],
                  },
                },
              ],
            },

            // sector segment
            {
              type: "Col",
              props: {
                sm: 7,
              },
              elements: [
                {
                  type: "Form.Item",
                  props: {
                    label: "Setor",
                    name: ["contact", "sector"],
                    rules: [
                      {
                        required: true,
                        message: "Campo obrigatório",
                      },
                    ],
                  },
                  element: {
                    type: "Select",
                    props: {
                      name: "sector",
                      placeholder: "Selecione o setor",
                    },
                    options: sectorsMock,
                  },
                },
              ],
            },
          ],
        },

        // divider
        {
          type: "Divider",
          props: {
            className: "divider-margin-top",
          },
        },

        // ********************
        // *** CONTACT DATA ***
        // ********************

        // title | text
        {
          type: "p",
          default: "Dados do contato",
          props: {
            className: "input-title",
          },
        },

        // subtitle | text
        {
          type: "p",
          default: "Adicione o número de contato",
          props: {
            className: "input-subtitle",
          },
        },

        // name - phone - email | inputs
        {
          type: "Row",
          props: {
            justify: "space-between",
            align: "top",
          },
          elements: [
            // name
            {
              type: "Col",
              props: {
                sm: 7,
              },
              elements: [
                {
                  type: "Form.Item",
                  props: {
                    label: "Nome e sobrenome",
                    name: ["contact", "name"],
                    rules: [
                      {
                        required: true,
                        message: "Campo obrigatório",
                      },
                    ],
                  },
                  element: {
                    type: "FlattedInput",
                    props: {
                      placeholder: "Inserir nome de contato",
                    },
                  },
                },
              ],
            },

            // phone
            {
              type: "Col",
              props: {
                sm: 7,
              },
              elements: [
                {
                  type: "Form.Item",
                  props: {
                    name: ["contact", "phone"],
                    label: "Telefone",
                  },
                  element: {
                    type: "FlattedPhoneInputForm",
                    props: {},
                  },
                },
              ],
            },

            // email
            {
              type: "Col",
              props: {
                sm: 7,
              },
              elements: [
                {
                  type: "InputAccountEmail",
                  props: {
                    label: "E-mail",
                    name: ["contact", "email"],
                    placeholder: "Inserir e-mail",
                  },
                },
              ],
            },
          ],
        },
      ],
      actions: [
        {
          type: "ButtonPreviousStep",
          props: {
            span: 12,
          },
          elements: [
            {
              type: "text",
              default: "Voltar",
            },
          ],
        },

        {
          type: "ButtonNextStep",
          props: {
            span: 12,
          },
          elements: [
            {
              type: "text",
              default: "Continuar",
            },
          ],
        },
      ],
    },

    // THIRD
    {
      type: "Route",
      step_title: "Financeiro",
      props: {
        path: ["/dashboard/proposal/3", "/dashboard/proposal/3/:id"],
        exact: true,
      },
      elements: [
        {
          type: "Row",
          props: {
            justify: "start",
            gutter: [16, 16],
            align: "bottom",
          },
          elements: [
            {
              type: "Col",
              props: {
                span: 16,
              },
              elements: [
                {
                  type: "div",
                  props: {
                    className: "input-title",
                  },
                  elements: [
                    {
                      type: "text",
                      default: "Valor da proposta",
                    },
                  ],
                },
                {
                  type: "text",
                  default:
                    "Insira as informações referente ao valor e data de faturamento",
                },
              ],
            },
          ],
        },

        {
          type: "Space",
          props: {},
        },

        {
          type: "Row",
          props: {
            justify: "start",
            gutter: [16, 0],
            align: "bottom",
          },
          elements: [
            {
              type: "Col",
              props: {
                sm: 8,
              },
              elements: [
                {
                  type: "Form.Item",
                  props: {
                    label: "Valor total",
                    name: "total_value",
                    initialValue: "",
                    rules: [
                      {
                        required: true,
                        message: "Campo obrigatório",
                      },
                    ],
                  },
                  element: {
                    type: "FlattedInputCurrency",
                    props: {
                      placeholder: "R$ 0,00",
                    },
                  },
                },
              ],
            },

            {
              type: "Col",
              props: {
                sm: 8,
              },
              elements: [
                {
                  type: "Dropdown",
                  props: {
                    trigger: ["click"],
                    placement: "bottomRight",
                    overlay: {
                      type: "DatePicker",
                      props: {
                        className: "floating",
                        storeValue: "form.payment_date",
                        defaultValue: "form.payment_date",
                      },
                    },
                  },
                  element: {
                    type: "Form.Item",
                    props: {
                      name: "payment_date",
                      label: "Previsão de pagamento",
                      initialValue: "",
                      rules: [
                        {
                          required: true,
                          message: "Campo obrigatório",
                        },
                      ],
                    },
                    element: {
                      type: "FlattedInput",
                      props: {
                        readOnly: true,
                        placeholder: "Inserir data",
                      },
                    },
                  },
                },
              ],
            },

            {
              type: "Col",
              props: {
                sm: 8,
              },
              elements: [
                {
                  type: "Dropdown",
                  props: {
                    trigger: ["click"],
                    placement: "bottomRight",
                    overlay: {
                      type: "DatePicker",
                      props: {
                        className: "floating",
                        storeValue: "form.payment_day",
                        defaultValue: "form.payment_day",
                      },
                    },
                  },
                  element: {
                    type: "Form.Item",
                    props: {
                      name: "payment_day",
                      label: "Previsão de faturamento",
                      initialValue: "",
                      rules: [
                        {
                          required: true,
                          message: "Campo obrigatório",
                        },
                      ],
                    },
                    element: {
                      type: "FlattedInput",
                      props: {
                        readOnly: true,
                        placeholder: "Inserir data",
                      },
                    },
                  },
                },
              ],
            },
          ],
        },

        {
          type: "Space",
        },

        {
          type: "SelectPaymentMethod",
        },

        {
          type: "Space",
        },

        // divider
        {
          type: "Divider",
          props: {},
        },

        // company | select
        {
          type: "SelectCpfCnpj",
          props: {
            name: "cpf_cnpj",
            label: "CPF/CNPJ",
            rules: [
              {
                required: true,
                message: "Campo obrigatório",
              },
            ],
          },
        },

        {
          type: "Space",
          props: {},
        },

        // title | text
        {
          type: "p",
          default: "Observações gerais",
          props: {
            className: "title-textarea",
          },
        },

        {
          type: "Form.Item",
          props: {
            name: "general_observations",
          },
          rules: [
            {
              required: true,
              message: "Campo obrigatório",
            },
          ],
          element: {
            type: "TextArea",
            props: {
              placeholder: "Observações",
            },
          },
        },
      ],
      actions: [
        {
          type: "ButtonPreviousStep",
          props: {
            span: 12,
          },
          elements: [
            {
              type: "text",
              default: "Voltar",
            },
          ],
        },

        {
          type: "ButtonNextStep",
          props: {
            span: 12,
          },
          elements: [
            {
              type: "text",
              default: "Continuar",
            },
          ],
        },
      ],
    },

    // FIFITH
    {
      type: "Route",
      step_title: "Envio",
      props: {
        path: ["/dashboard/proposal/4", "/dashboard/proposal/4/:id"],
        className: "step-no-style",
      },
      elements: [
        {
          type: "ProposalCreateResume",
          elements: [],
        },
      ],
    },
  ],
}
