import React, { useEffect, useState } from "react"
import { useRouteMatch, useHistory, useLocation } from "react-router-dom"
//
import { useDispatch, useSelector } from "react-redux"
import {
  // getProposal,
  getProposalStoredData,
  resetErrorMessage,
  resetProposalState,
  resetSaveProposal,
  selectProposalState,
  updateAux,
  updateProposalStep,
} from "./proposalSlice"
//
import BaseModal from "../../common/components/Modal"
import JsonForm from "./jsonForm"
import Loading from "../../common/components/Loading"
import { Form, Row } from "antd"
import StepsStatusBar from "../../common/components/StepsStatusBar"
//
import StateStatus from "../../utils/stateStatus"
import RestricionsModal from "./components/RestrictionsModal"
import dynamicForms from "../../common/mocks/createProposalJson"
import { selectDashSlice } from "../dashboard/DashboardSlice"
import {
  getProfile,
  listBankAccounts,
  listCompanies,
  selectProfileData,
} from "../configurations/configurationsSlice"

// mixPanel
import mixpanel from "../../mixPanel"
import { ProposalTypesEnum } from "../../utils/constants"
import { VotesSatisfactionModal } from "../VoteSatisfaction"

const CreateProposal = () => {
  const { params } = useRouteMatch()
  //
  const dispatch = useDispatch()
  const location = useLocation()
  const dash = useSelector(selectDashSlice)
  const proposalState = useSelector(selectProposalState)
  const profileData = useSelector(selectProfileData)
  //
  const [type, updateType] = useState()
  const [form] = Form.useForm()
  const [dynamicFormJson, updateDynamicFormJson] = useState(null)
  const [showModal, updateShowModal] = useState()
  const [showVoteSatisfaction, setShowVoteSatisfaction] = useState(false)
  //
  const role = dash.mode?.toUpperCase()
  const history = useHistory()

  const isFirstProposal = proposalState.data.isFirstProposal

  const isProposalDownloaded =
    params.id !== undefined &&
    proposalState.status.getProposalStoredData === StateStatus.succeeded

  const isCreateNewProposal = params.id === undefined

  useEffect(() => {
    if (proposalState.status.saveProposal === StateStatus.succeeded) {
      dispatch(resetSaveProposal())
      if (isFirstProposal) setShowVoteSatisfaction(true)
    }
  }, [proposalState.status.saveProposal])

  useEffect(() => {
    if (type) {
      updateDynamicFormJson(dynamicForms[role][type])
      form.resetFields()
      mixpanel.track("Usuário clicou em criar proposta via web", {
        "Proposal type": type,
      })
    }
  }, [type])

  useEffect(() => {
    if (
      profileData.proposals_limit &&
      profileData.count_active_proposals >= profileData.proposals_limit
    ) {
      updateShowModal("modal-proposals-limit")
    }
  }, [profileData])

  useEffect(() => {
    if (!params.id) {
      history.replace("/dashboard/proposal/select-type")
    }

    dispatch(resetProposalState())
    dispatch(getProfile())
    form.resetFields()

    if (params.id) {
      dispatch(getProposalStoredData(params.id))
    } else {
      dispatch(updateAux({ type: ProposalTypesEnum.INFLUENCER }))
      updateType(ProposalTypesEnum.INFLUENCER)
    }

    dispatch(updateProposalStep(1))
    dispatch(listBankAccounts({ filter: "" }))
    dispatch(listCompanies({ filter: "" }))
  }, [])

  useEffect(() => {
    if (proposalState.data.aux.type) {
      updateType(proposalState.data.aux.type)
    }
  }, [proposalState.data.aux.type])

  useEffect(() => {
    let page = proposalState.step ?? 1
    if (params.page && params.page.length > 0) {
      page = parseInt(params.page)
    }

    if (!dynamicFormJson || page <= dynamicFormJson.steps.length) {
      dispatch(updateProposalStep(page))
      setTimeout(() => {
        document.body.style.overflow = "auto"
      }, 500)
    }
  }, [params.page])

  useEffect(() => {
    if (
      proposalState.status.getProposalStoredData === StateStatus.succeeded &&
      proposalState.data.proposal &&
      proposalState.data.proposal.owner &&
      proposalState.data.proposal.contact
    ) {
      const proposal = proposalState.data.proposal
      // updateType(proposal.type)
      form.setFieldsValue(proposal)
      form.setFieldsValue({ company_id: "-1" })
      const contact = proposal.contact
      const owner = proposal.owner

      form.setFieldsValue({ contact })

      dispatch(
        updateAux({
          type: proposal.type,
          cpf_cnpj: owner.cpf_cnpj,
          company_name: owner.company_name,
          bank_account: owner.back_account, // nome vem da API errado
          meeting: proposal.meeting,
          files: proposal.files.map((item) => {
            return {
              uid: item.id,
              name: item.filename,
              url: item.link,
              status: "done",
              response: null,
              mimeType: item.mime_type,
              payload: item,
              file: null,
            }
          }),
        })
      )
    }
  }, [proposalState.status.getProposalStoredData])

  return (
    <>
      <Row justify="center" style={{ width: "100%" }}>
        <div style={{ width: "1090px" }}>
          <Row justify="center">
            {dynamicFormJson && (
              <StepsStatusBar
                steps={[
                  {
                    step: 0,
                    active: false,
                    label: "Tipo de proposta",
                  },
                  ...dynamicFormJson.steps.map((item, index) => {
                    return {
                      step: index + 1,
                      active: false,
                      label: item.step_title,
                    }
                  }),
                ]}
                active={
                  location.pathname.includes("/proposal/select-type")
                    ? 0
                    : proposalState.step
                }
                maxSteps={dynamicFormJson ? dynamicFormJson.steps.length : 0}
                isEdit={params.id !== undefined}
              />
            )}
          </Row>
          <div className="create-proposal">
            {params.id !== undefined &&
            proposalState.status.getProposalStoredData ===
              StateStatus.loading ? (
              <div style={{ height: "50vh" }}>
                <Loading />
              </div>
            ) : (
              <>
                {dynamicFormJson &&
                type &&
                (isCreateNewProposal || isProposalDownloaded) ? (
                  <JsonForm
                    form={form}
                    json={dynamicFormJson}
                    proposalType={type}
                    data={proposalState.data.proposal}
                  />
                ) : null}
              </>
            )}
          </div>
        </div>
      </Row>
      <BaseModal
        type="error"
        isVisible={proposalState.errMessage != null}
        title="Erro!"
        okButtonText="Ok"
        onOk={() => {
          dispatch(resetErrorMessage())
        }}
      >
        {proposalState.errMessage}
        <br />
        {proposalState.validations &&
          proposalState.validations.map((value, index) => {
            return (
              <div key={index} style={{ padding: "2px 0" }}>
                <i>{value}</i>
              </div>
            )
          })}
      </BaseModal>
      <BaseModal
        type="alert"
        isVisible={showModal === "modal-proposals-limit"}
        title="Limite de propostas atingido"
        subtitle={
          "Você chegou ao seu limite de propostas aceitas no período do seu plano. Sua proposta não poderá ser enviada, apenas armazenada como rascunho."
        }
        okButtonText="Aumentar Plano"
        cancelButtonText="Continuar"
        onOk={() => history.push("/dashboard/config?tab=4&update=plan")}
        onCancel={() => {
          updateShowModal(null)
        }}
      ></BaseModal>

      {showVoteSatisfaction ? (
        <VotesSatisfactionModal
          question={"Como você se sentiu criando a proposta?"}
          onCancel={() => {
            setShowVoteSatisfaction(false)
          }}
          onClose={() => {
            setShowVoteSatisfaction(false)
          }}
        />
      ) : null}

      <RestricionsModal />
    </>
  )
}

export default CreateProposal
