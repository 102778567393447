import React from "react"

export const LinkIcon = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.0909 7.96403C12.6865 8.24843 13.2053 8.67163 13.6035 9.19808C14.0017 9.72453 14.2677 10.3388 14.3793 10.9894C14.4908 11.64 14.4446 12.3079 14.2445 12.9369C14.0444 13.5659 13.6963 14.1378 13.2294 14.6044L9.10436 18.7294C8.33077 19.5029 7.28155 19.9375 6.18753 19.9375C5.0935 19.9375 4.04428 19.5029 3.27069 18.7294C2.4971 17.9558 2.0625 16.9066 2.0625 15.8125C2.0625 14.7185 2.4971 13.6693 3.27069 12.8957L4.88128 11.2851M17.1188 10.7149L18.7294 9.10436C19.5029 8.33077 19.9375 7.28155 19.9375 6.18753C19.9375 5.0935 19.5029 4.04428 18.7294 3.27069C17.9558 2.4971 16.9066 2.0625 15.8125 2.0625C14.7185 2.0625 13.6693 2.4971 12.8957 3.27069L8.77069 7.39569C8.30378 7.86228 7.95565 8.4341 7.75556 9.06313C7.55547 9.69216 7.50925 10.36 7.6208 11.0106C7.73234 11.6612 7.99839 12.2755 8.39659 12.802C8.79479 13.3284 9.31352 13.7516 9.90919 14.036"
          stroke="#0038D1"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  )
}
