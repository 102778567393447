import React, { useState } from "react"
import PropTypes from "prop-types"
import "./styles.scss"
import MaskedInput from "antd-mask-input/build/main/lib/MaskedInput"
import Icons from "../Icons"

const Input = ({
  type,
  placeholder,
  className,
  value,
  maxLength,
  minLength,
  onChange,
  onKeyDown,
  onFocus,
  onInput,
  onBlur,
  isBlockPaste,
  isPassword,
  isDisabled,
  isReadOnly,
  preffix,
  suffix,
  ...props
}) => {
  const [hidden, setHidden] = useState(true)

  return (
    <div
      className={`input-simple-container ${
        isPassword && "input-container-password"
      }`}
    >
      {preffix && <div className="preffix">{preffix}</div>}

      <input
        data-testid="input"
        className={`simple-input ${preffix ? "with-preffix" : ""} ${
          preffix ? "with-suffix" : ""
        } ${className}`}
        //
        type={isPassword ? (hidden ? "password" : "text") : type}
        value={value}
        placeholder={placeholder}
        //
        onBlur={onBlur}
        onFocus={onFocus}
        onInput={onInput}
        onChange={onChange}
        onKeyDown={onKeyDown}
        readOnly={isReadOnly}
        onPaste={(e) => {
          if (isBlockPaste) e.preventDefault()
          return false
        }}
        onCopy={(e) => {
          if (isBlockPaste) e.preventDefault()
          return false
        }}
        //
        autoComplete={isBlockPaste ? "new-email" : null}
        //
        maxLength={maxLength}
        minLength={minLength}
        disabled={isDisabled}
        {...props}
      />

      {suffix && <div className="suffix">{suffix}</div>}

      {isPassword && (
        <div className="suffix">
          {!hidden && isPassword ? (
            <Icons
              icon="eye-opened"
              data-testid="fip-hide-icon"
              onClick={() => setHidden(!hidden)}
              className="icon-button"
            />
          ) : (
            <Icons
              icon="eye-closed"
              data-testid="fip-show-icon"
              onClick={() => setHidden(!hidden)}
              className="icon-button"
            />
          )}
        </div>
      )}

      {/* {isPassword && (
        <div className="suffix">
          <i
            role="button"
            tabIndex={0}
            aria-label="visivel"
            onClick={() => setHidden(!hidden)}
            className={`icon icon-eye${hidden ? "" : "-blocked"}`}
          />
        </div>
      )} */}
    </div>
  )
}

const SimpleMaskedInput = (props) => {
  return (
    <div className="input-simple-container">
      <MaskedInput className="simple-input simple-masked-input" {...props} />
    </div>
  )
}

Input.defaultProps = {
  type: null,
  value: "",
  className: "",
  placeholder: null,
  //
  maxLength: null,
  minLength: null,
  //
  isPassword: false,
  isReadOnly: false,
  //
  onBlur: null,
  onInput: null,
  onChange: null,
  onKeyDown: null,
  disabled: false,
  //
  blockPaste: false,
  preffix: null,
  suffix: null,
}

Input.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  //
  isPassword: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  //
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  //
  onBlur: PropTypes.func,
  onInput: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  //
  isBlockPaste: PropTypes.bool,
  preffix: PropTypes.element,
  suffix: PropTypes.element,
}

export { SimpleMaskedInput }
export default Input
