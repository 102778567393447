import React from "react"

export const ExtractIcon = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="10"
          y="7"
          width="13"
          height="18"
          rx="1"
          stroke="#676767"
          strokeWidth="1.5"
        />
        <line
          x1="13.0996"
          y1="21.7998"
          x2="13.8329"
          y2="21.7998"
          stroke="#676767"
          strokeLinecap="round"
        />
        <line
          x1="13.0996"
          y1="19.0996"
          x2="13.8329"
          y2="19.0996"
          stroke="#676767"
          strokeLinecap="round"
        />
        <path
          d="M13.29 14.5391V13.9502C13.0341 13.9248 12.8056 13.8574 12.6044 13.748C12.4033 13.6387 12.2451 13.4844 12.1298 13.2852C12.0165 13.084 11.9618 12.835 11.9658 12.5381L11.9716 12.5234H12.9765C12.9765 12.6816 12.9999 12.8105 13.0468 12.9102C13.0937 13.0078 13.1581 13.0801 13.2402 13.127C13.3222 13.1719 13.4169 13.1943 13.5243 13.1943C13.6122 13.1943 13.6855 13.1768 13.7441 13.1416C13.8027 13.1045 13.8466 13.0537 13.8759 12.9893C13.9052 12.9248 13.9199 12.8506 13.9199 12.7666C13.9199 12.667 13.9042 12.583 13.873 12.5146C13.8437 12.4463 13.7939 12.3857 13.7236 12.333C13.6552 12.2783 13.5605 12.2246 13.4394 12.1719C13.1347 12.0508 12.8837 11.9297 12.6865 11.8086C12.4911 11.6875 12.3466 11.5459 12.2529 11.3838C12.1591 11.2217 12.1122 11.0186 12.1122 10.7744C12.1122 10.4424 12.2245 10.1699 12.4492 9.95703C12.6738 9.74219 12.9736 9.61719 13.3486 9.58203V8.96387H13.8173V9.59668C14.1689 9.64941 14.4472 9.79395 14.6523 10.0303C14.8593 10.2666 14.9599 10.5684 14.954 10.9355L14.9628 10.959H13.9462C13.9462 10.75 13.9062 10.5957 13.8261 10.4961C13.748 10.3945 13.6454 10.3438 13.5185 10.3438C13.3876 10.3438 13.291 10.3848 13.2285 10.4668C13.166 10.5488 13.1347 10.6553 13.1347 10.7861C13.1347 10.8721 13.1484 10.9473 13.1757 11.0117C13.205 11.0742 13.2548 11.1318 13.3251 11.1846C13.3954 11.2373 13.4921 11.291 13.6152 11.3457C13.916 11.4766 14.165 11.6045 14.3622 11.7295C14.5595 11.8545 14.706 11.998 14.8017 12.1602C14.8993 12.3203 14.9482 12.5205 14.9482 12.7607C14.9482 13.0986 14.8408 13.3711 14.6259 13.5781C14.4111 13.7852 14.123 13.9072 13.7617 13.9443V14.5391H13.29Z"
          fill="#676767"
        />
        <line
          x1="16.5664"
          y1="21.7998"
          x2="20.7664"
          y2="21.7998"
          stroke="#676767"
          strokeLinecap="round"
        />
        <line
          x1="16.5664"
          y1="19.0996"
          x2="20.7664"
          y2="19.0996"
          stroke="#676767"
          strokeLinecap="round"
        />
      </svg>
    </span>
  )
}
