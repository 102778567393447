import React from "react"
import PropTypes from "prop-types"

import "./styles.scss"
import { Col, Row } from "antd"
import Icons from "../../../../../common/components/Icons"
import StyledSwitch from "../../../../../common/components/Switch"

const CardNotifiSettings = ({
  label,
  value,
  //   actionText,
  onClick,
  className,
  isSwitch,
}) => {
  return (
    <div
      className={`card-settings-notification card-settings ${className ?? ""}`}
      style={{ cursor: "auto" }}
      onClick={onClick}
      role={"button"}
    >
      <Row justify="space-between" style={{ width: "100%" }}>
        <Col className="normal-16" span={value != null ? 5 : 8}>
          {label}
        </Col>
        {/* <Col className="normal-16-500" span={value != null ? 13 : 10}>
          {value}
        </Col> */}

        <Col span={6}>
          {!isSwitch ? (
            <Icons icon={"outline-arrow-right"} />
          ) : (
            <StyledSwitch onClick={""} />
          )}
        </Col>
      </Row>
    </div>
  )
}

CardNotifiSettings.defaultProps = {
  onSwitch: false,
}

CardNotifiSettings.propTypes = {
  label: PropTypes.string,
  value: PropTypes.object,
  actionText: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  isSwitch: PropTypes.bool,
}

export { CardNotifiSettings }
