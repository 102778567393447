import React from "react"

export const ShareIcon = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <defs>
          <filter
            colorInterpolationFilters="auto"
            id="icon-share-proposal-filter-1"
          >
            <feColorMatrix
              in="SourceGraphic"
              type="matrix"
              values="0 0 0 0 0.403922 0 0 0 0 0.403922 0 0 0 0 0.403922 0 0 0 1.000000 0"
            ></feColorMatrix>
          </filter>
          <rect
            id="icon-share-proposal-path-2"
            x="0"
            y="0"
            width="24"
            height="24"
          ></rect>
        </defs>
        <g
          id="icon-share-proposal"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            filter="url(#icon-share-proposal-filter-1)"
            id="icon-share-proposal-copy"
          >
            <g>
              <mask id="icon-share-proposal-mask-3" fill="white">
                <use xlinkHref="#icon-share-proposal-path-2"></use>
              </mask>
              <g id="icon-share-proposal-backgroud-mask"></g>
            </g>
          </g>
          <path
            d="M12,7.75 L12,9.25 L4.75,9.25 L4.75,19.25 L19.25,19.25 L19.25,12 L20.75,12 L20.75,20.75 L3.25,20.75 L3.25,7.75 L12,7.75 Z M19.65,6.35 L19.65,11 L18.35,11 L18.35,7.65 L15,7.65 L15,6.35 L19.65,6.35 Z"
            id="icon-share-proposal-path-3"
            fill="#676767"
            fillRule="nonzero"
          ></path>
          <path
            d="M18.6232532,6.8514926 L19.3767468,8.1485074 C17.2636498,9.37609945 15.3358806,10.7742617 13.5926326,12.3431849 C11.9940616,13.7818989 10.7487458,15.1859425 9.85515873,16.5518354 L9.63908876,16.8925119 L8.36091124,16.1074881 C9.34679417,14.5022712 10.7568659,12.8773313 12.5891855,11.2282437 C14.2279568,9.75334952 16.02125,8.42408585 17.9684771,7.24059162 L18.6232532,6.8514926 Z"
            id="icon-share-proposal-path-6"
            fill="#676767"
            fillRule="nonzero"
          ></path>
        </g>
      </svg>
    </span>
  )
}
