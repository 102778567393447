import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Col, Divider, Progress, Row } from "antd"
import Icons from "../Icons"
import "./styles.scss"
import { getBytesAndDownloadFile } from "../../../utils/files"
const ModalProgressDownload = ({ files, onClose, filesLenght }) => {
  return (
    <div className="modal-progress-download">
      <Row justify="space-between" className="download-label">
        {files.every((item) => item.data.progress === 100) ? (
          <div className="download-text-label">
            {files.length <= 1 ? "Download concluído" : "Downloads concluídos"}
            <span style={{ paddingLeft: 8 }}>
              <Icons icon="circle-check-filled" />
            </span>
          </div>
        ) : (
          <div className="download-text-label">
            {files.length <= 1
              ? "Fazendo download"
              : `Fazendo ${
                  files.length - files.every((item) => item.status === "ready")
                } downloads`}
          </div>
        )}
        <div
          className="close-icon"
          style={{ cursor: "pointer" }}
          onClick={onClose}
        >
          <Icons icon="close" />
        </div>
      </Row>

      {files.map((item, index) => (
        <Col key={index}>
          <ProgressRow
            fileName={item.data.filename}
            timeDownload={item.data.secs_to_finish}
            percentProgress={item.data.progress}
            onClickCancel={item.cancelDownload}
            onClickDown={async () => {
              const name = item.data.filename
              const url = item.file.link
              const mimeType = item.data.mime_type
              const link = await getBytesAndDownloadFile(url, mimeType, name)

              return link.click()
            }}
            status={item.status}
            id={item.id}
          />

          {/* <CompletedExport
              completedFileName={item.data.filename}
              filesForCompleted={files}
              progressForCompleted={item.data.progress}
              onClickDownCompleted={() => }
            /> */}

          {index < files.length - 1 && filesLenght > 1 ? (
            <div className="divider">
              <Divider className="divider-item" type="horizontal" />
            </div>
          ) : (
            ""
          )}
        </Col>
      ))}
    </div>
  )
}

const ProgressRow = ({
  fileName,
  timeDownload,
  percentProgress,
  onClickCancel,
  onClickDown,
  status,
  id,
}) => {
  useEffect(() => {
    const downloadId = localStorage.getItem(id)
    if (status === "ready" && downloadId !== "downloaded") {
      onClickDown()
      localStorage.setItem(id, "downloaded")
    }
  }, [status])

  return (
    <div>
      <div className="row-fileName">
        <span className="fileName">{fileName}</span>
      </div>
      <div className="progress-row">
        {percentProgress === 100 ? (
          <div className="completed-label">Concluído</div>
        ) : (
          <>
            <span className="time-download" style={{ paddingRight: "5px" }}>
              restam {timeDownload === undefined ? "0" : timeDownload}
              {timeDownload > 60 ? " minutos" : " segundos"}
            </span>
            <span
              className="bar-progress"
              style={{ paddingRight: "5px", width: "72px" }}
            >
              <Progress
                strokeLinecap="butt"
                percent={percentProgress}
                showInfo={false}
                size={[70, 10]}
                strokeColor={"#3258C1"}
              />
            </span>
          </>
        )}
        <Col style={{ display: "grid" }}>
          {/* <span className="cancel-download" onClick={onClickCancel}>
            cancelar
          </span> */}
          <span
            className="cancel-download"
            style={{ paddingBottom: 5, cursor: "pointer" }}
            onClick={onClickDown}
          >
            {status === "ready" ? "Baixar novamente" : ""}
          </span>
        </Col>
      </div>
    </div>
  )
}
// const CompletedExport = ({
//   completedFileName,
//   filesForCompleted,
//   progressForCompleted,
//   onClickDownCompleted,
// }) => {
//   return (
//     <div className="completed-exported-row">
//       <div className="completed-filename">{completedFileName}</div>
//       {progressForCompleted === 100 ? (
//         <Row>
//           <div className="completed-label">Concluído</div>
//           <span
//             className="cancel-download"
//             style={{ paddingBottom: 5 }}
//             onClick={onClickDownCompleted}
//           >
//             download
//           </span>
//         </Row>
//       ) : null}
//     </div>
//   )
// }

// CompletedExport.propTypes = {
//   completedFileName: PropTypes.string,
//   filesForCompleted: PropTypes.array,
//   progressForCompleted: PropTypes.number,
//   onClickDownCompleted: PropTypes.func,
// }

ModalProgressDownload.propTypes = {
  files: PropTypes.array,
  onClose: PropTypes.func,
  filesLenght: PropTypes.number,
}

ProgressRow.propTypes = {
  fileName: PropTypes.string,
  timeDownload: PropTypes.number,
  percentProgress: PropTypes.number,
  onClickCancel: PropTypes.func,
  onClickDown: PropTypes.func,
  status: PropTypes.string,
  id: PropTypes.string,
}

export default ModalProgressDownload
