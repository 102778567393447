import api from "../../service/api"
import apiRoutes from "../../service/apiRoutes"

export const listMessagesProposal = (proposalId) =>
  api.get(`${apiRoutes.proposal}/${proposalId}/messages`)
export const sendMessageProposal = (proposalId, data) =>
  api.post(`${apiRoutes.proposal}/${proposalId}/messages`, data)
export const deleteMessageProposal = (messageId) =>
  api.delete(`${apiRoutes.proposal}/messages/${messageId}`)
export const fulfillProposal = (proposalId) =>
  api.put(`${apiRoutes.proposalCounterparty}/${proposalId}/fulfill`)
export const proposalCancel = (id) => api.delete(`${apiRoutes.proposal}/${id}`)
