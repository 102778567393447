import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import StateStatus from "../../utils/stateStatus"
import {
  listMessagesProposal,
  sendMessageProposal,
  deleteMessageProposal,
  fulfillProposal,
  proposalCancel,
} from "./service"

export const listMessagesProposalThunk = createAsyncThunk(
  "chat-proposal/list",
  async (proposalId) => {
    const response = await listMessagesProposal(proposalId)
    return response.data
  }
)

export const sendMessageProposalThunk = createAsyncThunk(
  "chat-proposal/send",
  async ({ proposalId }, { getState }) => {
    const state = getState()
    const texts = []

    const noSentMessages = Array.from(
      state.chatProposal.noSentMessages.map((x) => {
        if (x.sent === false) texts.push(x.text)
        return { ...x, sent: true }
      })
    )

    if (texts.length > 0) {
      const response = await sendMessageProposal(proposalId, { texts })
      return { data: response.data.data, noSentMessages }
    } else {
      const response = await listMessagesProposal(proposalId)
      return { data: response.data.data, noSentMessages }
    }
  }
)

export const sendAlertProposalThunk = createAsyncThunk(
  "chat-proposal/send-alert",
  async ({ proposalId, alert }) => {
    const response = await sendMessageProposal(proposalId, { alert })
    return response.data
  }
)

export const deleteMessageProposalThunk = createAsyncThunk(
  "chat-proposal/delete",
  async (messageId) => {
    const response = await deleteMessageProposal(messageId)
    return response.data
  }
)

export const proposalFulfillThunk = createAsyncThunk(
  "chat-proposal/fulfill-proposal",
  async (proposalId) => {
    const response = await fulfillProposal(proposalId)
    return response.data
  }
)

export const cancelProposalThunk = createAsyncThunk(
  "chat-proposal/cancel-proposal",
  async (proposalId) => {
    const response = await proposalCancel(proposalId)
    return response.data
  }
)

const initialState = {
  status: {
    listMessagesProposal: StateStatus.idle,
    sendMessageProposal: StateStatus.idle,
    deleteMessageProposal: StateStatus.idle,
    proposalFulfill: StateStatus.idle,
    cancelProposal: StateStatus.idle,
    sendAlertProposal: StateStatus.idle,
  },
  data: {
    id: null,
    contractor: null,
    contracted: null,
    role: null,
    created_by: null,
    title: null,
    count_messages: 0,
    total_messages: 0,
    total_pages: 0,
    page: 1,
    messages: null,
  },
  noSentMessages: [],
  proposalId: null,
  sectors: [],
  errMessage: null,
}

export const chatProposalSlice = createSlice({
  name: "chat-proposal",
  initialState,
  reducers: {
    setProposalIdOnChat: (state, action) => {
      state.proposalId = action.payload
    },
    resetChatProposal: (state, action) => {
      state.data = { ...initialState.data }
      state.noSentMessages = []
      state.status.listMessagesProposal = StateStatus.idle
    },
    addMessageOnQueue: (state, action) => {
      state.noSentMessages = [...state.noSentMessages, action.payload]
    },
    resetStatusMessages: (state, action) => {
      state.status.listMessagesProposal = StateStatus.idle
      state.status.sendMessageProposal = StateStatus.idle
      state.status.deleteMessageProposal = StateStatus.idle
      state.status.sendAlertProposal = StateStatus.idle
    },
    resetProposalStatus: (state, action) => {
      state.status.cancelProposal = StateStatus.idle
      state.status.proposalFulfill = StateStatus.idle
    },
    updateChatData: (state, action) => {
      state.data = action.payload
    },
  },
  extraReducers: {
    [listMessagesProposalThunk.pending]: (state) => {
      state.status.listMessagesProposal = StateStatus.loading
    },
    [listMessagesProposalThunk.fulfilled]: (state, action) => {
      if (action.meta.arg === state.proposalId) {
        state.status.listMessagesProposal = StateStatus.succeeded
        state.data = action.payload.data
      }
    },
    [listMessagesProposalThunk.rejected]: (state, action) => {
      state.status.listMessagesProposal = StateStatus.failed
      state.errMessage = action.error.message
    },
    [sendMessageProposalThunk.pending]: (state) => {
      state.status.sendMessageProposal = StateStatus.loading
    },
    [sendMessageProposalThunk.fulfilled]: (state, action) => {
      if (action.meta.arg.proposalId === state.proposalId) {
        state.noSentMessages = action.payload.noSentMessages
        state.data = action.payload.data
        state.status.sendMessageProposal = StateStatus.succeeded
      }
    },
    [sendMessageProposalThunk.rejected]: (state, action) => {
      state.status.sendMessageProposal = StateStatus.failed
      state.errMessage = action.error.message
    },
    [sendAlertProposalThunk.pending]: (state) => {
      state.status.sendAlertProposal = StateStatus.loading
    },
    [sendAlertProposalThunk.fulfilled]: (state, action) => {
      if (action.meta.arg.proposalId === state.proposalId) {
        state.data = action.payload.data
        state.status.sendAlertProposal = StateStatus.succeeded
      }
    },
    [sendAlertProposalThunk.rejected]: (state, action) => {
      state.status.sendAlertProposal = StateStatus.failed
      state.errMessage = action.error.message
    },
    [deleteMessageProposalThunk.pending]: (state) => {
      state.status.deleteMessageProposal = StateStatus.loading
    },
    [deleteMessageProposalThunk.fulfilled]: (state, action) => {
      state.status.deleteMessageProposal = StateStatus.succeeded
      state.data = action.payload.data
    },
    [deleteMessageProposalThunk.rejected]: (state, action) => {
      state.status.deleteMessageProposal = StateStatus.failed
      state.errMessage = action.error.message
    },
    // finalize proposal
    [proposalFulfillThunk.pending]: (state, action) => {
      state.status.proposalFulfill = StateStatus.loading
    },
    [proposalFulfillThunk.fulfilled]: (state, action) => {
      state.status.proposalFulfill = StateStatus.succeeded
    },
    [proposalFulfillThunk.rejected]: (state, action) => {
      state.status.proposalFulfill = StateStatus.failed
      state.errMessage = "Não foi possível finalizar proposta."
    },

    // finalize proposal
    [cancelProposalThunk.pending]: (state, action) => {
      state.status.cancelProposal = StateStatus.loading
    },
    [cancelProposalThunk.fulfilled]: (state, action) => {
      state.status.cancelProposal = StateStatus.succeeded
    },
    [cancelProposalThunk.rejected]: (state, action) => {
      state.status.cancelProposal = StateStatus.failed
      state.errMessage = "Não foi possível cancelar proposta."
    },
  },
})

export const {
  resetChatProposal,
  addMessageOnQueue,
  resetStatusMessages,
  setProposalIdOnChat,
  resetProposalStatus,
  updateChatData,
} = chatProposalSlice.actions

export const selectChatProposalState = (state) => state.chatProposal
