import React from "react"
import PropTypes from "prop-types"
import "./styles.scss"
import Icons from "../../../../common/components/Icons"
import InfoDateTime from "../../../../common/components/InfoDateTime"

const Card = ({ notification, onClick, onDelete }) => {
  return (
    <>
      <div className="card-notification">
        <div className="join card-body" onClick={onClick}>
          <div>
            <div
              className={`farol  ${notification.viewed ? "" : "farol-blue"}`}
            />
            {/*  */}
            <div className="normal-16-500">{notification.title}</div>
            <div className="normal-16 content-notif">
              {notification.content}
            </div>
            {notification?.created_at && (
              <InfoDateTime datetimeStr={notification.created_at} />
            )}
          </div>
        </div>
        <div className="join card-actions">
          <div className="delete-button" onClick={onDelete}>
            <Icons icon="delete" />
          </div>
        </div>
      </div>
    </>
  )
}

const CardEmptyData = () => {
  return (
    <div className="card-notification">
      <div className="normal-16">Nenhuma notificação encontrada</div>
    </div>
  )
}

Card.propTypes = {
  notification: PropTypes.object,
  onDelete: PropTypes.func,
  onClick: PropTypes.func,
}

export { CardEmptyData }
export default Card
