import React, { useState } from "react"
//
import { useSelector } from "react-redux"
//
import { Col, Divider, message, Row } from "antd"
import PropTypes from "prop-types"
//
import openEnglishImg from "../../common/assets/benefits/open-english.svg"
import vitamineseImg from "../../common/assets/benefits/vitamine-se.png"
import payoneerImg from "../../common/assets/benefits/payoneer.png"
import { selectConfigState } from "../configurations/configurationsSlice"
import "./styles.scss"
import { BlueButton } from "../../common/components/Button"
import BaseModal from "../../common/components/Modal"
import { useHistory } from "react-router-dom"

const BenefitCard = ({
  isBlocked,
  imgSrc,
  description,
  benefits,
  link,
  coupom,
  onActivate,
  ...props
}) => {
  const copyTextToClipboard = async (text) => {
    if (text) {
      await navigator.clipboard.writeText(text)
      message.success(
        <>
          Cupom copiado! <br />
          Você será redirecionado para o site parceiro.
        </>
      )
    }
  }

  return (
    <Row justify={"center"} className="benefit-card" align="middle" {...props}>
      <div className="explain-content">
        <Col span={24}>
          <img src={imgSrc} />
          <Divider />
        </Col>
        <Col span={24}>
          <p>{description}</p>
          <div className="benefits-list">
            <strong>Benefícios</strong>
            {benefits.map((text, idx) => (
              <div key={idx}>{text}</div>
            ))}
          </div>
        </Col>
      </div>
      {/* {isBlocked ? (
        <div className="action-content-block">
          <Col className="block-description">
            <Icons icon="lock" />
            <span>Bloqueado</span>
          </Col>
          <Col>
            <Link to="/dashboard/config?tab=4&update=plan">Alterar plano</Link>
          </Col>
        </div>
      ) : ( */}
      <div className="action-content">
        <Col span={24}>
          {/* <div className="link-description">{linkDescription}</div> */}
          <BlueButton
            onClick={() => {
              onActivate()

              if (!isBlocked) {
                if (coupom) {
                  copyTextToClipboard(coupom)
                  setTimeout(() => {
                    window.open(link, "_blank")
                  }, 2000)
                } else {
                  window.open(link, "_blank")
                }
              }
            }}
          >
            {coupom ? "Copiar cupom" : "Ativar benefício"}
          </BlueButton>
        </Col>
      </div>
      {/* )} */}
    </Row>
  )
}

BenefitCard.propTypes = {
  isBlocked: PropTypes.bool,
  onActivate: PropTypes.func,
  imgSrc: PropTypes.string,
  description: PropTypes.string,
  benefits: PropTypes.array,
  link: PropTypes.string,
  coupom: PropTypes.string,
}

const BenefitsPage = () => {
  const configState = useSelector(selectConfigState)
  const profile = configState.data?.profileData
  const [modal, setModal] = useState()
  const history = useHistory()

  const showBenefits = !["starter"].includes(profile?.plan_identifier)

  return (
    <div className="root-container benefits-page">
      <div className="page-title">Clube de benefícios Propozall</div>
      <div className="page-description">
        Desfrute de vantagens exclusivas e vivencie um mundo de benefícios com o
        Clube Propozall, enriquecendo ainda mais a sua experiência conosco.
      </div>

      <Row justify="start" gutter={[16, 16]} align="middle">
        <Col xs={24} sm={12} md={8} lg={8}>
          <BenefitCard
            id={"openenglish"}
            isBlocked={!showBenefits}
            imgSrc={openEnglishImg}
            onActivate={() => {
              if (!showBenefits) {
                setModal({ name: "upgrade-plan" })
              }
            }}
            description={
              <>
                A Open English é uma plataforma online de ensino de inglês que
                já ajudou mais de 2 milhões de pessoas a aprenderem o idioma.
              </>
            }
            benefits={[
              <>
                Ganhe <strong>70% de desconto</strong> do valor base do curso
                para pagamento à vista e <strong>60% de desconto</strong> para
                pagamento parcelado.
              </>,
            ]}
            link={
              "https://www.openenglish.com.br/para-empresas/leadform-empleado/?utm_source=salesforce&utm_medium=agent&utm_campaign=agent&utm_content=0053r00000B8WvU-1583522056686-b2b-0013r00002os0y6&utm_medium=Outbound&utm_campaign=Alianza_Brazil_Danilo"
            }
          />
        </Col>
        <Col xs={24} sm={12} md={8} lg={8}>
          <BenefitCard
            id={"vitaminese"}
            isBlocked={!showBenefits}
            imgSrc={vitamineseImg}
            onActivate={() => {
              if (!showBenefits) {
                setModal({ name: "upgrade-plan" })
              }
            }}
            description={
              <>
                Descubra as melhores vitaminas e suplementos personalizados de
                acordo com o seu objetivo. Simples e seguro, sua dose diária de
                vitamine-se.
              </>
            }
            benefits={[
              <>
                Ganhe <strong>15% de desconto</strong> + frete grátis em toda a
                linha de produtos.
              </>,
            ]}
            link={"https://www.vitaminese.com.br/"}
            coupom={"Propozall15"}
          />
        </Col>
        <Col xs={24} sm={12} md={8} lg={8}>
          <BenefitCard
            id={"payoneer"}
            isBlocked={!showBenefits}
            imgSrc={payoneerImg}
            onActivate={() => {
              if (!showBenefits) {
                setModal({ name: "upgrade-plan" })
              }
            }}
            description={
              <>
                A Payoneer é uma plataforma de pagamentos internacionais para
                freelancers e criadores de conteúdo, oferecendo serviços de
                recebimentos e transferências internacionais em mais de 10
                moedas.
              </>
            }
            link={"http://tracking.payoneer.com/SH4jE"}
            benefits={[
              <>
                Abra a sua conta e ganhe <strong>33% de desconto</strong> em
                tarifas e <strong>cashback de US$50</strong> ao receberem os
                primeiros US$1.000
              </>,
            ]}
          />
        </Col>
      </Row>
      <BaseModal
        isVisible={modal?.name === "upgrade-plan"}
        title="Clube de benefícios Propozall"
        okButtonText="Quero fazer upgrade do plano"
        onOk={() => {
          history.push("/dashboard/config?tab=4&update=plan")
          setModal(null)
        }}
        onClose={() => {
          setModal(null)
        }}
      >
        Para desfrutar dos incríveis benefícios oferecidos pelo Clube de
        Benefícios Propozall, convidamos você a realizar um upgrade do seu
        plano, uma vez que o acesso exclusivo do clube não está disponível no
        plano grátis.
      </BaseModal>
    </div>
  )
}

export default BenefitsPage
