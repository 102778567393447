import React, { memo, useEffect, useState } from "react"
import Icons from "../../../../common/components/Icons"
import Loading from "../../../../common/components/Loading"
import PropTypes from "prop-types"
import { Col, message, Row } from "antd"
import { createQRCodePix } from "../../../proposalView/service"
import "./styles.scss"

const BoxQRCodePix = ({ name, keyPix, totalValue, city, transactionId }) => {
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState(false)
  const [linkCopied, setLinkCopied] = useState(false)

  useEffect(() => {
    getQrCodePix()
  }, [keyPix])

  const getQrCodePix = async () => {
    setLoading(true)
    const response = await createQRCodePix({
      name,
      key_pix: keyPix,
      total_value: totalValue,
      city,
      transaction_id: transactionId,
    })

    console.log(response.data.data)
    console.log(linkCopied)
    setResult(response.data.data)
    setLoading(false)
  }

  const copyLinkToClipboard = async () => {
    if (result?.codigo_pix) {
      await navigator.clipboard.writeText(result?.codigo_pix)
      localStorage.setItem("qrcodepix-copied", result?.codigo_pix)
      message.success("Código PIX copiado!")
      setLinkCopied(true)
    }
  }

  return (
    <Row jusyify="center" align="middle" className="box-qrcode-pix">
      {!loading ? (
        <>
          {result.qrcode_base64 && (
            <Col span={24} className="box-qrcode-pix">
              <img src={result.qrcode_base64} width={160} height={160} />
            </Col>
          )}
          {result?.codigo_pix && (
            <Col span={24} className="box-codigo-pix">
              <div>{result.codigo_pix}</div>
              <div onClick={copyLinkToClipboard}>
                <Icons title="Copiar link" icon="copy" className="icon-copy" />
                Copiar código copia e cola
              </div>
            </Col>
          )}
        </>
      ) : (
        <Col span={24} className="box-qrcode-pix">
          <Loading className="small dark center" />
        </Col>
      )}
    </Row>
  )
}

BoxQRCodePix.propTypes = {
  name: PropTypes.string,
  keyPix: PropTypes.string,
  totalValue: PropTypes.string,
  city: PropTypes.string,
  transactionId: PropTypes.string,
}

export default memo(BoxQRCodePix)
