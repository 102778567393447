import React, { useCallback, useEffect, useState } from "react"
import { Route, Switch } from "react-router"
import { useHistory } from "react-router-dom"
//
import { useDispatch, useSelector } from "react-redux"
// import { logout } from "../login/LoginSlice"
import { resetProposalState } from "../proposal/proposalSlice"
import { getBasicProfile, selectDashSlice, updateMode } from "./DashboardSlice"
// import { checkNotifications } from "../listNotifications/listNotificationsSlice"
import {
  saveSelectedFilters,
  selectListProposalsState,
} from "../listProposals/listProposalsSlice"
import StateStatus from "../../utils/stateStatus"
// pages
import contractHistory from "../contractHistory/contractHistory"
import Configurations from "../configurations/configurations"
import CreateProposal from "../proposal/proposal"
import ContractView from "../contractView/contractView"
import ProposalView from "../proposalView"
import ListProposals from "../listProposals/listProposals"
import ListNotifications from "../listNotifications/listNotifications"
import DownloadReports from "../reports/Reports"
import PaymentHistory from "../paymentHistory/paymentHistory"
import Nfse from "../nfse/nfse"
import CreateNfse from "../createNfse/createNfse"
import NfseResume from "../createNfse/pages/nfseResume/nfseResume"
import ConfigNotifications from "../listNotifications/components/configNotifications/index"
// components
import { TitleWithSearchBar } from "../topbar/Topbar"
import BaseModal from "../../common/components/Modal"
import SideMenu from "../../common/components/sideMenu"
import blueLogo from "../../common/assets/logo/blue_logo.svg"
import whiteLogo from "../../common/assets/logo/white_logo.svg"

// import { useGoogleLogout } from "react-google-login"
import "./styles.scss"
import { useQuery } from "../../utils/query"
import { getProfile } from "../configurations/configurationsSlice"
import { isMobileDevice } from "../../utils/mobile"
import { isIOS } from "react-device-detect"
// import Counters from "../countersPage/countersPage"
// import Onboarding from "../onboarding/onboarding"
import HomePage from "../home/homePage"
import Icons from "../../common/components/Icons"
import BenefitsPage from "../benefitsPage"

export const Dashboard = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const dashState = useSelector(selectDashSlice)
  const listProposalState = useSelector(selectListProposalsState)
  // const role = dashState.mode?.toUpperCase()
  const [isMobileDeviceState, setMobileDeviceState] = useState(false)
  const [isExpanded, setExpanded] = useState(false)
  const query = useQuery()

  // const location = useLocation()
  // const checkProfile = query.get("check_profile")

  useEffect(() => {
    setTimeout(() => {
      const role = query.get("role")
      if (role && role.length > 0) {
        dispatch(updateMode({ mode: role }))
      }
    }, 3000)

    dispatch(getProfile())
    if (isMobileDevice()) {
      setMobileDeviceState(true)
    }
  }, [])

  // const googlelogout = useGoogleLogout({
  //   clientId: process.env.REACT_APP_GOOGLE_ID,
  //   cookiePolicy: "single_host_origin",
  //   onFailure: () => {},
  //   onLogoutSuccess: () => {},
  //   scope:
  //     "https://www.googleapis.com/auth/calendar " +
  //     "https://www.googleapis.com/auth/userinfo.email " +
  //     "https://www.googleapis.com/auth/userinfo.profile ",
  // })

  useEffect(() => {
    if (StateStatus.idle === dashState.status.basicProfile) {
      dispatch(getBasicProfile())
    }

    if (StateStatus.succeeded === dashState.status.basicProfile) {
      // if (checkProfile) {
      //   if (dashState.data.basicProfile.incomplete) {
      //     updateShowProfileWarning(true)
      //     updateIncompleteData(dashState.data.basicProfile.incomplete_data)
      //   }
      //   history.replace(location.pathname)
      // }

      const email = dashState.data.basicProfile.email
      if (email.includes("@airstrip") || email.includes("@airfluencers")) {
        window.clarity("set", "user_type", "internal")
      }
    }
  }, [dashState.status])

  useEffect(() => {
    document.body.className = ""
    document.body.classList.add(`body-${dashState.mode}`)
  }, [dashState.mode])

  return (
    <div className={`body-container body-container-${dashState.mode}`}>
      <div className={`left-bar left-bar-${isExpanded}`}>
        <div
          className="expanded-arrow"
          onClick={() => setExpanded(!isExpanded)}
        >
          <Icons
            icon={isExpanded ? "outline-arrow-left" : "outline-arrow-right"}
          />
        </div>
        <div className="logo">
          <img
            src={dashState.mode === "contractor" ? whiteLogo : blueLogo}
            alt="Logo Propozall"
            width="50"
          />
        </div>

        <SideMenu
          options={[
            {
              path: /.*\/dashboard$/,
              onClick: () => history.push("/dashboard"),
              icon: "home",
              title: "Início",
            },
            {
              path: /.*\/dashboard\/benefits.*$/,
              onClick: () => history.push("/dashboard/benefits"),
              icon: "benefits",
              title: "Clube de benefícios",
            },
            {
              path: /.*\/dashboard\/proposals.*$/,
              onClick: () => history.push("/dashboard/proposals"),
              icon: "proposal",
              title: "Propostas",
            },
            {
              path: /.*\/dashboard\/contract-history.*$/,
              onClick: () => history.push("/dashboard/contract-history"),
              icon: "contract",
              title: "Histórico de contratos",
            },
            // --------------------------------------------------------------------------
            {
              path: /.*\/dashboard\/proposal(\/)?.*$/,
              onClick: () => {
                dispatch(resetProposalState())
                history.push("/dashboard/proposal/1")
              },
              icon: "plus",
              title: "Criar proposta",
            },
            // --------------------------------------------------------------------------

            {
              path: /.*\/dashboard\/reports.*$/,
              onClick: () => history.push("/dashboard/reports"),
              icon: "reports",
              title: "Relatórios de arquivos baixados",
            },
            {
              path: /.*\/dashboard\/nfse.*$/,
              onClick: () => history.push("/dashboard/nfse"),
              icon: "nfe",
              title: "Notas fiscais",
            },
            // dashState.mode === "contractor"
            //   ? {
            //       path: /.*\/dashboard\/invoices.*$/,
            //       onClick: () => history.push("/dashboard/invoices"),
            //       icon: "credit-card",
            //       title: "Faturamento",
            //     }
            //   : null,
            // {
            //   path: null,
            //   onClick: () => {
            //     // googlelogout.signOut()

            //     // if (window.chrome) {
            //     //   // disconnect manually, google signOut above is not cleaning session.
            //     //   // Whitout this line, when the google popup is opened after sign out,
            //     //   // the last user signed is sign in automattically.
            //     //   // const authinstance = window.gapi.auth2.getAuthInstance()
            //     //   // authinstance.disconnect()
            //     // }

            //     dispatch(logout()).then(() => {
            //       history.replace("/")
            //     })
            //   },
            //   icon: "exit",
            //   title: "Sair",
            // },
          ]}
        />
      </div>

      <div className="top-bar">
        <Switch>
          <Route
            exact
            path="/dashboard"
            component={useCallback(() => (
              <TitleWithSearchBar
                title="Início"
                icon={"home"}
                // onSearch={(value) => {
                //   dispatch()
                // }}
              />
            ))}
          />

          <Route
            path="/dashboard/contract-history"
            component={useCallback(() => (
              <TitleWithSearchBar
                title="Histórico de Contratos"
                icon={"contract"}
                // onSearch={(value) => {
                //   dispatch()
                // }}
              />
            ))}
          />

          <Route
            path="/dashboard/contract-history/:id"
            component={useCallback(() => (
              <TitleWithSearchBar
                title="Histórico de Contratos"
                icon={"contract"}
                // onSearch={(value) => {
                //   dispatch()
                // }}
              />
            ))}
          />

          <Route
            path="/dashboard/payment-history"
            component={useCallback(() => (
              <TitleWithSearchBar
                title="Relatórios de arquivos baixados"
                icon={"reports"}
                // onSearch={(value) => {
                //   dispatch()
                // }}
              />
            ))}
          />

          {/* <Route
              path="/dashboard/invoices"
              component={() => <TitleBar title="Configurações" />}
            /> */}

          <Route
            path="/dashboard/reports"
            component={useCallback(() => (
              <TitleWithSearchBar
                title="Relatórios de arquivos baixados"
                icon={"reports"}
              />
            ))}
          />

          <Route
            path="/dashboard/config"
            component={useCallback(() => (
              <TitleWithSearchBar title="Configurações" icon={"settings"} />
            ))}
          />
          <Route
            path="/dashboard/proposal/:page/:id([^/]{20,})?"
            component={useCallback(() => (
              <TitleWithSearchBar title="Criar Proposta" />
            ))}
          />
          <Route
            path="/dashboard/notifications/config"
            component={useCallback(() => (
              <TitleWithSearchBar title="Notificações" icon={"bell"} />
            ))}
          />
          <Route
            path="/dashboard/notifications"
            component={useCallback(() => (
              <TitleWithSearchBar title="Notificações" icon={"bell"} />
            ))}
          />
          <Route
            path="/dashboard/proposals/:id"
            component={useCallback(() => (
              <TitleWithSearchBar title="Proposta" />
            ))}
          />
          <Route
            path="/dashboard/benefits"
            component={useCallback(() => (
              <TitleWithSearchBar
                title="Clube de benefícios"
                icon={"benefits"}
              />
            ))}
          />

          <Route
            path="/dashboard/nfse/create"
            component={useCallback(() => (
              <TitleWithSearchBar title="Criar Nota Fiscal" />
            ))}
          />
          <Route
            path="/dashboard/nfse"
            component={useCallback(() => (
              <TitleWithSearchBar
                title="Notas Ficais"
                icon={"nfe"}
                // onSearch={(value) => {
                //   dispatch()
                // }}
              />
            ))}
          />

          <Route
            path="/dashboard/proposals"
            component={useCallback(
              () => (
                <TitleWithSearchBar
                  title="Propostas"
                  inputValue={listProposalState[listProposalState.tab].filter}
                  icon={"proposal"}
                  onSearch={(value) => {
                    dispatch(saveSelectedFilters({ filter: value }))
                  }}
                />
              ),
              [
                listProposalState.tab,
                listProposalState[listProposalState.tab].filter,
              ]
            )}
          />
        </Switch>
      </div>

      <div className="content">
        <Switch>
          <Route exact path="/dashboard" component={HomePage} />

          <Route path="/dashboard/proposals/:id" component={ProposalView} />
          <Route path="/dashboard/proposals" component={ListProposals} />
          <Route path="/dashboard/benefits" component={BenefitsPage} />

          <Route
            path="/dashboard/proposal/:page/:id([^/]{20,})?"
            component={CreateProposal}
          />

          <Route path="/dashboard/config" component={Configurations} />

          <Route
            path="/dashboard/notifications/config"
            component={ConfigNotifications}
          />

          <Route
            path="/dashboard/notifications"
            component={ListNotifications}
          />

          <Route
            path="/dashboard/nfse/create/:proposalId"
            component={CreateNfse}
          />

          <Route path="/dashboard/nfse/:nfseId/resume" component={NfseResume} />

          <Route path="/dashboard/nfse" component={Nfse} />

          <Route
            path="/dashboard/contract-history/:id"
            component={ContractView}
          />

          <Route
            path="/dashboard/contract-history"
            component={contractHistory}
          />

          <Route path="/dashboard/payment-history" component={PaymentHistory} />

          <Route path="/dashboard/reports" component={DownloadReports} />
        </Switch>
      </div>

      {/* <BaseModal
        isVisible={showProfileWarning}
        title=""
        okButtonText="Completar cadastro"
        onOk={() => {
          history.push("/dashboard/config")
          updateShowProfileWarning(false)
        }}
        onCancel={() => {
          updateShowProfileWarning(false)
        }}
      >
        <p className="normal-16-500 title">
          Olá! <br />
          Seu cadastro está incompleto.
        </p>
        Você precisa terminar algumas etapas para você finalizar o seu cadastro
        e ter um melhor aproveitamento do Propozall.
        <br />
        Dados incompletos:
        <br />
        <br />
        <ul>
          {incompleteData.phone && <li>Telefone</li>}
          {incompleteData.social_medias && <li>Redes Sociais</li>}
          {incompleteData.address && <li>Endereço</li>}
          {incompleteData.companies && <li>CNPJ</li>}
        </ul>
      </BaseModal> */}

      <BaseModal
        isVisible={isMobileDeviceState}
        onClose={() => setMobileDeviceState(false)}
        type="alert"
        title="Aplicativo Propozall"
        subtitle="Recomendamos o uso do aplicativo Propozall para gerenciar suas propostas via dispositivos mobile."
      >
        <p className="normal-16-500 title">
          {isIOS ? (
            <a
              href="https://apps.apple.com/br/app/propozall/id1609648372"
              target="_blank"
              rel="noreferrer"
            >
              Instalar Aplicativo
            </a>
          ) : (
            <a
              href="https://play.google.com/store/apps/details?id=com.propozall"
              target="_blank"
              rel="noreferrer"
            >
              Instalar Aplicativo
            </a>
          )}
        </p>
      </BaseModal>
    </div>
  )
}
