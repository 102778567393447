import React from "react"

export const PenIcon = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        width="17"
        height="18"
        viewBox="0 0 17 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="Vector"
          d="M11.959 3.36544L13.2242 2.09944C13.488 1.83568 13.8457 1.6875 14.2188 1.6875C14.5918 1.6875 14.9495 1.83568 15.2133 2.09944C15.477 2.36319 15.6252 2.72093 15.6252 3.09394C15.6252 3.46695 15.477 3.82468 15.2133 4.08844L4.4365 14.8652C4.03999 15.2615 3.55102 15.5527 3.01375 15.7127L1 16.3127L1.6 14.2989C1.75996 13.7617 2.05123 13.2727 2.4475 12.8762L11.9598 3.36544H11.959ZM11.959 3.36544L13.9375 5.34394"
          stroke="#0038D1"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  )
}
