import React from "react"
import PropTypes from "prop-types"

const NfeIcon = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="33"
        height="32"
        viewBox="0 0 33 32"
      >
        <title>{props?.alt ?? ""}</title>
        <path d="M9.777 4.364c-1.231 0.265-2.323 1.368-2.583 2.608-0.123 0.584-0.123 18.472 0 19.056 0.264 1.253 1.357 2.347 2.611 2.611 0.331 0.069 1.444 0.081 7.367 0.083 6.768 0.001 6.989-0.003 7.405-0.109 0.656-0.167 1.052-0.403 1.589-0.943 0.341-0.344 0.523-0.579 0.653-0.851 0.368-0.76 0.351-0.308 0.333-8.72l-0.015-7.627-0.129-0.345c-0.255-0.679-0.5-0.969-2.612-3.075-2.203-2.197-2.216-2.208-3.123-2.495-0.872-0.276-0.953-0.28-6.212-0.275-4 0.003-4.992 0.019-5.285 0.081zM18.335 8.208c0 1.093 0.024 2.143 0.051 2.333 0.141 0.987 0.91 1.76 1.884 1.904l0.012 0.001c0.197 0.028 1.389 0.052 2.651 0.052l2.292 0.001-0.029 13.195-0.123 0.229c-0.177 0.329-0.591 0.673-0.932 0.772-0.249 0.073-1.18 0.081-7.116 0.069l-6.829-0.015-0.251-0.131c-0.316-0.157-0.567-0.406-0.722-0.711l-0.004-0.009-0.133-0.261v-18.277l0.129-0.251c0.219-0.42 0.555-0.708 0.961-0.825 0.112-0.032 1.788-0.055 4.172-0.059l3.987-0.004 0.001 1.985zM20.919 6.508c0.245 0.089 0.515 0.339 2.195 2.033l1.915 1.931-2.272 0.015c-1.631 0.011-2.292-0.003-2.347-0.048-0.061-0.052-0.076-0.444-0.076-2.085v-2.023l0.153 0.037c0.084 0.020 0.279 0.083 0.432 0.14zM11.833 18.5v3h1.556v-1.837c0-1.091 0.020-1.831 0.051-1.82 0.028 0.011 0.636 0.831 1.349 1.824l1.3 1.805 1.577 0.031v-6.003h-1.555l-0.015 1.872-0.013 1.871-2.667-3.737-1.584-0.005v3zM18.833 18.5v3h1.556v-2.444h2v-1.112h-2v-1.277h2.667v-1.167h-4.223v3z"></path>
      </svg>
    </span>
  )
}

NfeIcon.propTypes = {
  alt: PropTypes.string,
}

export { NfeIcon }
