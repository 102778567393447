import React from "react"
import PropTypes from "prop-types"

const ReportsIcon = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 210 297"
      >
        <path
          d="M174.345 82.726l-29.458-32.328c-3.918-4.278-14.42-6.692-19.947-6.692H50.212a20.839 22.856 0 00-20.839 22.856v159.992a20.839 22.856 0 0020.84 22.856h109.403a20.839 22.856 0 0020.839-22.856V98.881a20.839 22.856 0 00-6.11-16.164z"
          clipRule="evenodd"
          fill="none"
          stroke="gray"
          strokeWidth="17.459"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M123.016 34.854a8.73 8.73 0 00-8.598 8.853v45.711c0 10.615 8.218 20.158 19.15 20.158h41.676a8.73 8.73 0 100-17.459h-41.676c-.57 0-1.691-.697-1.691-2.699V43.707a8.73 8.73 0 00-8.861-8.853zm-15.287 93.636a6.548 6.548 0 00-6.45 6.64v51.661l-9.761-10.707a6.548 6.548 0 10-9.676 8.822l20.414 22.39a6.548 6.548 0 002.52 2.288 6.548 6.548 0 00.492.228 6.548 6.548 0 00.273.108 6.548 6.548 0 00.225.078 6.548 6.548 0 00.543.152 6.548 6.548 0 003.185-.037 6.548 6.548 0 00.133-.035 6.548 6.548 0 00.367-.115 6.548 6.548 0 00.412-.156 6.548 6.548 0 00.104-.043 6.548 6.548 0 002.885-2.454l19.175-21.023a6.548 6.548 0 10-9.675-8.824l-8.52 9.34V135.13a6.548 6.548 0 00-6.646-6.64z"
          color="#000"
          fontWeight="400"
          fontFamily="sans-serif"
          overflow="visible"
          fill="gray"
        />
        <title>{props?.alt ?? ""}</title>
      </svg>
    </span>
  )
}

ReportsIcon.propTypes = {
  alt: PropTypes.string,
}

export { ReportsIcon }
