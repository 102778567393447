import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import "./styles.scss"
import { useHistory, useLocation } from "react-router-dom"
import { useQuery } from "../../../utils/query"

const TabBarMenu = ({ selectedTab, onChange, menuItems }) => {
  const query = useQuery()
  const location = useLocation()
  const history = useHistory()
  const [offsetTop, updateOffsetTop] = useState(0)
  const [width, updateWidth] = useState(0)
  const [selectedIndex, updateSelectedIndex] = useState(0)

  useEffect(() => {
    const elem = document.querySelector(".tab-bar-menu .menu-item:first-child")
    const width = elem.offsetWidth
    updateWidth(width)
  }, [])

  useEffect(() => {
    const index = parseInt(query.get("tab"))
    const elem = document.querySelector(".tab-bar-menu .menu-item:first-child")
    const width = elem.offsetWidth
    updateWidth(width)

    if (
      query.get("tab") !== null &&
      typeof index === "number" &&
      index >= 0 &&
      index <= menuItems.length
    ) {
      updateOffsetTop(index * width)
      updateSelectedIndex(index)
      if (onChange) {
        onChange(index)
      }
    } else if (
      selectedTab &&
      selectedTab >= 0 &&
      selectedTab <= menuItems.length
    ) {
      updateOffsetTop(selectedTab * width)
      updateSelectedIndex(selectedTab)
    }
  }, [query, selectedTab])

  const onClickItem = (index, width) => {
    updateWidth(width)
    history.push(`${location.pathname}?tab=${index}`)
  }

  return (
    <div className="tab-bar-menu">
      <div className="menu">
        {menuItems.map((item, index) => {
          return (
            <span
              key={index}
              className={
                index === selectedIndex ? "menu-item selected" : "menu-item"
              }
              onClick={(event) => {
                onClickItem(index, event.target.offsetWidth)
              }}
            >
              <span className="menu-item-text">{item.name}</span>
              {item.count !== undefined && (
                <span className="style-count">
                  {item.count
                    ? item.count.toLocaleString("pt-BR", {
                        style: "decimal",
                      })
                    : 0}
                </span>
              )}
            </span>
          )
        })}
      </div>
      <div className="menu-marker">
        <div
          className="active-marker"
          style={{
            // transform: `translateX(${offsetTop}px)`,
            // WebkitTransform: `translateX(${offsetTop}px)`,
            // MsTransform: `translateX(${offsetTop}px)`,
            // MozTransform: `translateX(${offsetTop}px)`,
            // OTransform: `translateX(${offsetTop}px)`,
            marginLeft: `${offsetTop}px`,
            width: `${width}px`,
          }}
        ></div>
      </div>
    </div>
  )
}

TabBarMenu.propTypes = {
  onChange: PropTypes.func,
  menuItems: PropTypes.array,
  selectedTab: PropTypes.number,
}

export default TabBarMenu
