import React from "react"
import Loading from "../Loading"
import { Button, Spin } from "antd"
import PropTypes from "prop-types"
import { LoadingOutlined } from "@ant-design/icons"
import "./styles.scss"

const IconButton = ({ isLoading, className, children, ...props }) => {
  return (
    <button
      data-testid="icon-button"
      className={`outline-button icon-button ${className ?? ""}`}
      {...props}
    >
      {isLoading ? (
        <span className="button-loading">
          <Loading className="light small" />
          <span>{children}</span>
        </span>
      ) : (
        children
      )}
    </button>
  )
}

const BlueButton = ({ icon, isLoading, className, children, ...props }) => {
  return (
    <button
      data-testid="blue-button"
      className={`blue-button ${className ?? ""}`}
      {...props}
    >
      {isLoading ? (
        <div style={{ marginRight: "15px" }}>
          <Spin
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 24,
                  color: "white",
                  strokeWidth: "30",
                }}
                spin
              />
            }
          />{" "}
        </div>
      ) : (
        icon
      )}
      <span>{children}</span>
    </button>
  )
}

const WhiteButton = ({ icon, isLoading, className, children, ...props }) => {
  return (
    <button
      data-testid="white-button"
      className={`white-button ${className ?? ""}`}
      {...props}
    >
      <span className="button-icon">
        {isLoading ? <Loading className="dark small" /> : icon}
      </span>
      <span>{children}</span>
    </button>
  )
}

const OutlinedButton = ({ icon, className, isLoading, children, ...props }) => {
  return (
    <button
      data-testid="outlined-button"
      className={`outlined-button ${className ?? ""}`}
      {...props}
    >
      <span className="button-icon">
        {isLoading ? <Loading className="dark small" /> : icon}
      </span>
      <span>{children}</span>
    </button>
  )
}

// Comentado pois já existe o componente WhiteButton que é igual.
//
// const OutlinedBlueButton = (props) => {
//   return (
//     <button
//       data-testid="outlined-button"
//       className="outlined-blue-button"
//       {...props}
//     ></button>
//   )
// }

const RedButton = ({ icon, isLoading, className, children, ...props }) => {
  return (
    <button
      data-testid="red-button"
      className={`red-button ${className ?? ""}`}
      {...props}
    >
      <span className="button-icon">
        {isLoading ? <Loading className="light small" /> : icon}
      </span>
      <span>{children}</span>
    </button>
  )
}

const PlainButton = ({ icon, className, children, ...props }) => {
  return (
    <Button
      icon={icon}
      className={`plain-button ${className ?? ""}`}
      {...props}
    >
      {children}
    </Button>
  )
}

IconButton.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.any,
  className: PropTypes.string,
}

BlueButton.propTypes = {
  icon: PropTypes.any,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.any,
}

WhiteButton.propTypes = {
  icon: PropTypes.any,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.any,
}

RedButton.propTypes = {
  icon: PropTypes.any,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.any,
}

PlainButton.propTypes = {
  icon: PropTypes.element,
  children: PropTypes.any,
  className: PropTypes.string,
}

OutlinedButton.propTypes = {
  icon: PropTypes.any,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.any,
}

export {
  IconButton,
  BlueButton,
  WhiteButton,
  OutlinedButton,
  RedButton,
  PlainButton,
  // OutlinedBlueButton,
}
