import React from "react"

export const DocPlusIcon = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
      >
        <path
          d="M39 28.5V23.25C39 21.4598 38.2888 19.7429 37.023 18.477C35.7571 17.2112 34.0402 16.5 32.25 16.5H29.25C28.6533 16.5 28.081 16.2629 27.659 15.841C27.2371 15.419 27 14.8467 27 14.25V11.25C27 9.45979 26.2888 7.7429 25.023 6.47703C23.7571 5.21116 22.0402 4.5 20.25 4.5H16.5M24 22.5V34.5M30 28.5H18M21 4.5H11.25C10.008 4.5 9 5.508 9 6.75V41.25C9 42.492 10.008 43.5 11.25 43.5H36.75C37.992 43.5 39 42.492 39 41.25V22.5C39 17.7261 37.1036 13.1477 33.7279 9.77208C30.3523 6.39642 25.7739 4.5 21 4.5Z"
          stroke="#002999"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  )
}
