import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
//
import { getAddressByCep } from "../../RegisterService"
import { useDispatch, useSelector } from "react-redux"
import {
  resetData,
  resetStatus,
  selectRegister,
  sendAddress,
  sendPayment,
  updAddress,
  setShowAddress,
} from "../../RegisterSlice"
import PropTypes from "prop-types"
//
import { Form, Select } from "antd"
import Input, { SimpleMaskedInput } from "../../../../common/components/Input"
//
import { states } from "../../../../utils/lists"
import StateStatus from "../../../../utils/stateStatus"
import "./styles.scss"

import Loading from "../../../../common/components/Loading"

const AddressForm = ({ rel: form }) => {
  const history = useHistory()
  //
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const register = useSelector(selectRegister)
  //
  const address = register.data.address ?? {}

  const onFinish = (values) => {
    values.address.cep = values.address.cep.replace(/[^0-9]+/g, "")
    if (register.data?.id || register.data?.address?.id) {
      dispatch(
        updAddress({
          id: register.data?.id || register.data?.address?.id,
          data: values.address,
        })
      )
    } else {
      dispatch(sendAddress({ data: values.address }))
    }
  }

  const updateStateByCep = (cep) => {
    setLoading(true)
    setTimeout(() => {
      getAddressByCep(cep)
        .then((response) => {
          form.setFieldsValue({
            address: {
              city: response.data.localidade,
              street: response.data.logradouro,
              state: response.data.uf,
              neighborhood: response.data.bairro,
            },
          })
          if (response != null) {
            dispatch(setShowAddress(true))
          }
        })
        .finally(() => {
          setLoading(false)
        })
    }, 3000)
  }

  useEffect(() => {
    if (register.status.saveAddress === StateStatus.succeeded) {
      dispatch(resetStatus())
      // plan starter (dont need credit card)
      if (register.planIdentifier === "starter") {
        dispatch(sendPayment({ token: null }))
      } else {
        history.push("/register/3")
      }
    }
  }, [register.status.saveAddress])

  useEffect(() => {
    if (register.status.getAddress === StateStatus.succeeded) {
      if (register.data.address?.cep) {
        dispatch(setShowAddress(true))
      }
    }
  }, [register.status.getAddress])

  useEffect(() => {
    if (
      register.status.sendPayment === StateStatus.succeeded &&
      register.planIdentifier === "starter"
    ) {
      dispatch(resetStatus())
      dispatch(resetData())

      history.replace("/register/5")
    }
  }, [register.status.sendPayment])

  useEffect(() => {
    document.body.style.overflow = "auto"
  }, [])

  return (
    <>
      <div className="step-about">
        <h1 className="form-title">Endereço</h1>
        <h2 className="form-subtitle">
          Preencha os campos a seguir com os dados do seu endereço.
        </h2>
        <span className="form-observation">
          Itens com (*) são obrigatórios.{" "}
        </span>
      </div>

      <Form onFinish={onFinish} form={form} layout="vertical">
        <Form.Item
          label="CEP *"
          name={["address", "cep"]}
          initialValue={address.cep}
          rules={[
            () => ({
              validator(_, value) {
                if (value !== undefined && value !== null && value.length > 0) {
                  const val = value.replace(/[^0-9]+/g, "")

                  if (val.length < 8) {
                    return Promise.reject(new Error("Informe um CEP válido"))
                  }

                  return Promise.resolve()
                } else {
                  return Promise.reject(new Error("Informe um CEP"))
                }
              },
            }),
          ]}
        >
          <SimpleMaskedInput
            onInput={(event) => {
              const cep = event.target.value.replaceAll(/[-._]/g, "")
              if (cep.length === 8) {
                updateStateByCep(cep)
              } else if (cep.length === 0) {
                form.resetFields()
                dispatch(setShowAddress(false))
              }
            }}
            mask={[
              {
                mask: "00000-000",
                lazy: true,
              },
            ]}
            placeholder="Digite seu CEP"
          />
        </Form.Item>
        {loading && (
          <Form.Item>
            <Loading />
            <p className="loading-message">
              {"Estamos buscando as informações deste cep..."}
            </p>
          </Form.Item>
        )}
        {!loading && register.isAddress && (
          <>
            <div className="junction-inputs">
              <Form.Item
                label="Estado/Região *"
                initialValue={address.state}
                className="ant-form-item-control-select"
                name={["address", "state"]}
                rules={[
                  {
                    required: true,
                    message: (
                      <div className="normal-12">Selecione um estado</div>
                    ),
                  },
                  {
                    max: 50,
                    message: "Máximo de 50 caracteres",
                  },
                ]}
              >
                <Select
                  className="rounded-select"
                  placeholder="Selecione seu Estado"
                  disabled
                >
                  {states.map((state) => {
                    return (
                      <Select.Option key={state.key} value={state.key}>
                        {state.label}
                      </Select.Option>
                    )
                  })}
                </Select>
              </Form.Item>

              <Form.Item
                label="Cidade *"
                name={["address", "city"]}
                initialValue={address.city}
                rules={[
                  {
                    required: true,
                    message: (
                      <div className="normal-12">Informe uma cidade</div>
                    ),
                  },
                  {
                    max: 50,
                    message: "Máximo de 50 caracteres",
                  },
                ]}
              >
                <Input isDisabled placeholder="Preencha com sua cidade" />
              </Form.Item>
            </div>

            <Form.Item
              label="Rua *"
              name={["address", "street"]}
              initialValue={address.street}
              rules={[
                {
                  required: true,
                  message: (
                    <div className="normal-12">Informe o nome da rua</div>
                  ),
                },
                {
                  max: 50,
                  message: "Máximo de 50 caracteres",
                },
              ]}
            >
              <Input placeholder="Digite sua rua" />
            </Form.Item>

            <div className="junction-inputs-nnc">
              <Form.Item
                label="Bairro *"
                name={["address", "neighborhood"]}
                initialValue={address.neighborhood}
                rules={[
                  {
                    required: true,
                    message: (
                      <div className="normal-12">Informe um bairro válido</div>
                    ),
                  },
                  {
                    max: 50,
                    message: "Máximo de 50 caracteres",
                  },
                ]}
              >
                <Input placeholder="Preencha com seu bairro" />
              </Form.Item>

              <Form.Item
                label="Número *"
                name={["address", "number"]}
                initialValue={address.number}
                rules={[
                  {
                    required: true,
                    message: <div className="normal-12">Informe o número</div>,
                  },
                  {
                    max: 10,
                    message: "Máximo de 10 caracteres",
                  },
                ]}
              >
                <Input placeholder="00" />
              </Form.Item>

              <Form.Item
                label="Complemento"
                name={["address", "complement"]}
                initialValue={address.complement ?? ""}
                rules={[
                  {
                    max: 50,
                    message: "Máximo de 50 caracteres",
                  },
                ]}
              >
                <Input placeholder="00" />
              </Form.Item>
            </div>

            <Form.Item
              label="Ponto de referência"
              name={["address", "reference_point"]}
              initialValue={address.reference_point ?? ""}
              rules={[
                {
                  max: 50,
                  message: "Máximo de 50 caracteres",
                },
              ]}
            >
              <Input placeholder="Preencha com um ponto de refência" />
            </Form.Item>
          </>
        )}
      </Form>
    </>
  )
}

AddressForm.propTypes = {
  rel: PropTypes.object.isRequired,
}

export default AddressForm
