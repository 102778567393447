import React from "react"

export const ProposalStatusWaitingInvoiceIcon = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        width="21"
        height="22"
        viewBox="0 0 21 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.5 2.40909C5.75537 2.40909 1.90909 6.25537 1.90909 11C1.90909 15.7446 5.75537 19.5909 10.5 19.5909C15.2446 19.5909 19.0909 15.7446 19.0909 11C19.0909 6.25537 15.2446 2.40909 10.5 2.40909ZM0 11C0 5.20101 4.70101 0.5 10.5 0.5C16.299 0.5 21 5.20101 21 11C21 16.799 16.299 21.5 10.5 21.5C4.70101 21.5 0 16.799 0 11Z"
          fill="#002999"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.5 4.31818C11.0272 4.31818 11.4545 4.74555 11.4545 5.27273V11.164C11.4547 11.417 11.5553 11.6599 11.7342 11.8388L14.0386 14.1432C14.4114 14.516 14.4114 15.1204 14.0386 15.4931C13.6658 15.8659 13.0614 15.8659 12.6887 15.4931L10.3845 13.189C9.84749 12.6521 9.54562 11.9238 9.54545 11.1644V5.27273C9.54545 4.74555 9.97282 4.31818 10.5 4.31818Z"
          fill="#002999"
        />
      </svg>
    </span>
  )
}
