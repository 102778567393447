import React, { useEffect } from "react"
//
import { useDispatch, useSelector } from "react-redux"
import { selectDashSlice } from "../dashboard/DashboardSlice"
//
import { Col, Row } from "antd"
import Icons from "../../common/components/Icons"
import Loading from "../../common/components/Loading"
//
import StateStatus from "../../utils/stateStatus"
import { listReports, selectReportsState } from "./ReportsSlice"
//
import emptyImg from "../../common/assets/images/contratos.png"
import "./styles.scss"
import { getBytesAndDownloadFile } from "../../utils/files"

const Reports = () => {
  const dispatch = useDispatch()
  const reports = useSelector(selectReportsState)
  const dash = useSelector(selectDashSlice)
  //
  const role = dash.mode?.toUpperCase()

  const columns = [
    {
      title: "Número do arquivo",
      span: 8,
      getContent: (item) => (item.id ? item.id : "..."),
    },
    {
      title: "Data de solicitação",
      span: 4,
      getContent: (item) =>
        item.created_at ? item.created_at.split(" ")[0] : "...",
    },
    {
      title: "Período do relatório",
      span: 5,
      getContent: (item) => `${item.starts_at} a ${item.ends_at}`,
    },
    {
      title: "Tipo de arquivo",
      span: 4,
      getContent: (item) => (item.file_type ? item.file_type : "..."),
    },
    {
      title: "Arquivos baixados",
      span: 3,
      getContent: (item) => {
        if (item.status !== "ready" || !item.file?.link) {
          return <div className="download-button disabled">processando...</div>
        }

        return (
          <div
            className="download-button"
            onClick={async () => {
              if (item.file) {
                const link = await getBytesAndDownloadFile(
                  item.file.link,
                  item.file.mime_type,
                  item.file.filename
                )
                link.click()
              }
            }}
          >
            <Icons icon="download" title="Download" />
            Baixar
          </div>
        )
      },
    },
  ]

  useEffect(() => {
    dispatch(listReports({ role }))
  }, [])

  useEffect(() => {
    document.body.style.overflow = "auto"

    const interval = setInterval(() => {
      dispatch(listReports({ role, refresh: true }))
    }, 30 * 1000) // 30s

    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <div className="root-container reports-page">
      {/* <LoadingCarousel /> */}
      <div>
        <p className="normal-18">
          Encontre seu histórico de relatórios e baixe em Excel.
          <Icons
            icon={"excel"}
            style={{ top: "2px", position: "relative", paddingLeft: "5px" }}
          />
        </p>
      </div>

      <div style={{ width: "100%", marginTop: "85px" }}>
        {(reports.status.listReports === StateStatus.succeeded ||
          reports.list.length > 0) &&
          (reports.list.length === 0 ? (
            <div className="empty-card">
              <img
                src={emptyImg}
                alt="imagem para simbolizar que a lista está vazia"
              />
              <span className="normal-14-400">Você não possui relatórios</span>
            </div>
          ) : (
            <div className="list-reports">
              <Row justify="space-between" className="list-header">
                {columns.map((item, index) => (
                  <Col key={index} span={item.span}>
                    {item.title}
                  </Col>
                ))}
              </Row>

              <Col justify="space-around" className="list-body">
                {reports.list.map((report, index) => (
                  <Row
                    key={index}
                    justify="space-between"
                    className="list-body-card"
                  >
                    {columns.map((item, idx) => (
                      <Col
                        key={`${index}-${idx}`}
                        span={item.span}
                        className={
                          item.getClassName != null && item.getClassName(report)
                        }
                      >
                        {item.getContent(report)}
                      </Col>
                    ))}
                  </Row>
                ))}
              </Col>
            </div>
          ))}

        {reports.status.listReports === StateStatus.failed && (
          <p className="">{reports.errMessage}</p>
        )}

        {reports.status.listReports === StateStatus.loading &&
          reports.list < 1 && <Loading />}
      </div>
    </div>
  )
}

export default Reports
