import api from "../../service/api"
import apiRoutes, { baseUrl } from "../../service/apiRoutes"
import axios from "axios"

export const makeLogin = (data) => {
  return api.post(apiRoutes.login, data)
}

export const requestBasicProfile = () => {
  const accessToken = localStorage.getItem("access_token")
  return axios.get(`${baseUrl}${apiRoutes.profile}`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  })
}

export const requestRefreshToken = () => {
  const refreshToken = localStorage.getItem("refresh_token")
  return axios.get(`${baseUrl}${apiRoutes.refreshToken}`, {
    headers: { Authorization: `Bearer ${refreshToken}` },
  })
}

export const makeProviderLogin = (accessToken, provider, code = null) =>
  api.post(apiRoutes.loginAuth, {
    provider,
    access_token: accessToken,
    code,
  })
