import { influencerJson } from "./INFLUENCER.js"
import { serviceProviderJson } from "./SERVICE_PROVIDER.js"
import { simpleJson } from "./SIMPLE.js"
import { freelaJson } from "./FREELA.js"
import { freelaContractorJson } from "./FREELA_CONTRACTOR.js"
import { influencerContractorJson } from "./INFLUENCER_CONTRACTOR.js"
import { serviceProviderContractorJson } from "./SERVICE_PROVIDER_CONTRACTOR.js"
import { simpleContractorJson } from "./SIMPLE_CONTRACTOR.js"

const dynamicResume = {
  CONTRACTOR: {
    INFLUENCER: influencerContractorJson,
    SERVICE_PROVIDER: serviceProviderContractorJson,
    SIMPLE: simpleContractorJson,
    FREELA: freelaContractorJson,
  },
  CONTRACTED: {
    INFLUENCER: influencerJson,
    SERVICE_PROVIDER: serviceProviderJson,
    SIMPLE: simpleJson,
    FREELA: freelaJson,
  },
}

export default dynamicResume
