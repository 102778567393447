import React from "react"
import Calendar from "react-calendar"
import PropTypes from "prop-types"
import "./styles.scss"

const DatePicker = ({ value, onChange, className, ...props }) => {
  return (
    <div className={`date-picker-container ${className}`}>
      <div className="date-picker-calendar">
        <Calendar onChange={onChange} value={value} {...props} />
      </div>
    </div>
  )
}

DatePicker.defaultProps = {
  date: new Date(),
  className: "",
}

DatePicker.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  className: PropTypes.string,
}

export { DatePicker }
