import React from "react"
import PropTypes from "prop-types"
//
import { numberToReal } from "../../../../../../utils/formaters"
import "./index.scss"
import RadioButton from "../../../../../../common/components/RadioButton"

const CardPlan = ({ plan, isOption, isDisabled, isSelected, onClick }) => {
  return (
    <div
      className={
        "plan-card " +
        `${isOption ? "card-option " : ""}` +
        `${isSelected ? "selected " : ""}` +
        `${isDisabled ? "disabled " : ""}`
      }
      onClick={isDisabled ? null : () => onClick(plan)}
    >
      <div className="name">
        {isOption && (
          <span>
            <RadioButton checked={isSelected} disabled={isDisabled} />
          </span>
        )}
        {plan.name || plan.plan_name}
      </div>

      <span className="limit">
        {plan.proposals_limit === null ? (
          <>
            <b>Ilimitado</b>
          </>
        ) : (
          <>
            Até <b>{plan.proposals_limit} propostas</b> aceitas
          </>
        )}
      </span>
      <span className="price">
        {plan.price === 0 ? (
          <b>Grátis</b>
        ) : plan.price ? (
          <b>{numberToReal(plan.price)} / mês</b>
        ) : null}
      </span>

      {!isOption &&
        (plan.renews_at ? (
          <span className="renewalDate">
            Renova: {plan.renews_at}{" "}
            {plan.downgrade_scheduled_name
              ? `(${plan.downgrade_scheduled_name})`
              : ""}
          </span>
        ) : plan.expires_at ? (
          <span className="renewalDate">Validade: {plan.expires_at}</span>
        ) : null)}
    </div>
  )
}

CardPlan.defaultProps = {
  plan: {},
  isOption: false,
  isSelected: false,
  isDisabled: false,
  onClick: () => {},
}

CardPlan.propTypes = {
  isOption: PropTypes.bool,
  isSelected: PropTypes.bool,
  isDisabled: PropTypes.bool,
  plan: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default CardPlan
