import React from "react"

export const VideoconferenceIcon = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <defs>
          <rect
            id="videoconference-icon-svg-path-1"
            x="0"
            y="0"
            width="24"
            height="24"
          ></rect>
        </defs>
        <g
          id="videoconference-icon-svg-g"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="videoconference-icon-svg-g-2"
            transform="translate(-915.000000, -671.000000)"
          >
            <g
              id="videoconference-icon-svg-3"
              transform="translate(900.000000, 254.000000)"
            >
              <g
                id="videoconference-icon-svg-g-4"
                transform="translate(15.000000, 417.000000)"
              >
                <mask id="videoconference-icon-svg-mask-2" fill="white">
                  <use xlinkHref="#videoconference-icon-svg-path-1"></use>
                </mask>
                <g id="videoconference-icon-svg-g-5"></g>
                <path
                  d="M17.930428,5 C18.4520303,5 18.8748725,5.41573559 18.8748725,5.92857143 L18.874,9.839 L20.1599443,7.84930715 C20.3211139,7.59973759 20.5834533,7.43664139 20.873977,7.39976687 L21,7.39180724 C21.5522847,7.39180724 22,7.83952249 22,8.39180724 L22,8.39180724 L22,14.6081928 C22,14.9477207 21.8277224,15.2640546 21.5425001,15.4482485 C21.0785501,15.747863 20.4595588,15.6146428 20.1599443,15.1506929 L20.1599443,15.1506929 L18.874,13.16 L18.8748725,17.0714286 C18.8748725,17.5842644 18.4520303,18 17.930428,18 L2.81931691,18 C2.29771465,18 1.87487247,17.5842644 1.87487247,17.0714286 L1.87487247,5.92857143 C1.87487247,5.41573559 2.29771465,5 2.81931691,5 L17.930428,5 Z M16.9859836,6.85714286 L3.76376136,6.85714286 L3.76376136,16.1428571 L16.9859836,16.1428571 L16.9859836,6.85714286 Z"
                  fill="#000000"
                  mask="url(#videoconference-icon-svg-mask-2)"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </span>
  )
}
