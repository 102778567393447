import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { DatePicker } from "../../../../common/components/DatePicker"
import BaseModal from "../../../../common/components/Modal"
import Icons from "../../../../common/components/Icons"
import { Form, Row } from "antd"
import { formatDate, parseDate } from "../../../../utils/formaters"
import FlattedInput from "../../../../common/components/FlattedInput"
import { TimeSelect } from "../../../../common/components/TimePicker"
import ErrorMessage from "../../../../common/components/ErrorMessage"
// import { Transitions } from "../../../../common/components/Transitions"
import "./styles.scss"

const { useForm } = Form

const MeetingModal = ({ data, onSave, onCancel }) => {
  const [form] = useForm()
  const [ends, updateEnds] = useState({
    date: null,
    timeselected: false,
    errors: null,
  })
  const [starts, updateStarts] = useState({
    date: null,
    timeselected: false,
    errors: null,
  })
  const [selectedDate, updateSelectedDate] = useState()
  const [emails, updateEmails] = useState([])
  const [editField, updateEditField] = useState({})

  useEffect(() => {
    if (data) {
      if (typeof data.start_date === "string") {
        updateStarts({
          date: parseDate(data.start_date, "dd/MM/yyyy HH:mm"),
          timeselected: true,
          errors: null,
        })
        updateEnds({
          date: parseDate(data.end_date, "dd/MM/yyyy HH:mm"),
          timeselected: true,
          errors: null,
        })
      }

      updateEmails(data.attendees)

      form.setFieldsValue({
        summary: data.summary,
        video_conference: data.video_conference,
        location: data.location,
        description: data.description,
      })
    }
  }, [])

  useEffect(() => {
    if (starts.date && starts.timeselected) {
      if (!ends.date || starts.date.getTime() > ends.date.getTime()) {
        updateEnds(starts)
      }
    }
  }, [starts, ends, selectedDate])

  const addEmail = (index, value) => {
    const list = [...emails]
    if (index !== undefined) list.splice(index, 1)
    if (!list.includes(value)) list.push(value)
    updateEmails(list)
  }

  const updateFields = async (field) => {
    await form.validateFields([field]).then(() => {
      if (field === "email") {
        addEmail(editField.index, form.getFieldsValue().email)
        form.setFieldsValue({ email: "" })
      }
      updateEditField({})
    })
  }

  return (
    <>
      <BaseModal
        className="meeting-modal"
        isVisible={true}
        isClosable={false}
        onClose={onCancel}
        hasButtons={true}
        width={470}
        onOk={() => form.submit()}
        okButtonText="Salvar"
      >
        <Form
          className="form-metting"
          layout="vertical"
          form={form}
          placeholder="Selecione a data da reunião"
          onFinish={(values) => {
            if (!starts.date) {
              updateStarts({ ...starts, errors: "Selecione a data da reunião" })
            } else if (!starts.timeselected || !ends.timeselected) {
              if (!starts.timeselected) {
                updateStarts({
                  ...starts,
                  errors: "Selecione a hora de início da reunião",
                })
              }
              if (!ends.timeselected) {
                updateEnds({
                  ...ends,
                  errors: "Selecione a hora de término da reunião",
                })
              }
            } else {
              delete values.email
              values.attendees = [...emails]
              values.start_date = formatDate(starts.date, "dd/MM/yyyy HH:mm")
              values.end_date = formatDate(ends.date, "dd/MM/yyyy HH:mm")
              onSave(values)
            }
          }}
        >
          {/* <Row justify="center" align="bottom">
             <Icons
              icon="down"
              className="header-icons icon-down"
              onClick={onCancel} 
            />
          </Row>  */}
          <Row justify="end">
            <Icons icon="close" className="header-icons" onClick={onCancel} />
          </Row>
          <Form.Item
            initialValue=""
            name="summary"
            className="input-title"
            rules={[
              {
                required: true,
                message: <div className="normal-12">Campo obrigatório</div>,
              },
              {
                pattern: /^.{0,80}$/,
                message: (
                  <div className="normal-12">Máximo de 80 caracteres</div>
                ),
              },
            ]}
          >
            <FlattedInput placeholder="Adicionar título" />
          </Form.Item>

          <Row justify="center">
            <ErrorMessage
              text={starts.errors}
              isVisible={starts.errors !== null}
            />
          </Row>

          {/* <Row justify="space-between" className="selected-date-time-box">
            <Icons icon="calendar" className="calendar-icon-submit" />

            <span
              data-active={selectedDate === "date"}
              data-selected={starts.date !== null}
              onClick={() => {
                updateSelectedDate("date")
                onSelectCalendar(!selectCalendar)
              }}
            >
              {starts.date
                ? formatDate(starts.date, "iiii, dd 'de' MMM.")
                : "Selecione uma data"}
            </span>
            <span
              data-active={selectedDate === "starts_time"}
              data-selected={starts.timeselected}
              onClick={() => updateSelectedDate("starts_time")}
            >
              {starts.timeselected ? formatDate(starts.date, "HH:mm") : "-"}
            </span>
          </Row> */}

          {/* <div>
            {selectedDate === "starts_time" && (
              <Row justify="center" className="meeting-timer">
                <div>
                  <TimeSelect
                    value={starts.date}
                    placeholder="00:00"
                    format="HH:mm"
                    onChange={(value) => {
                      if (!value) return

                      updateStarts({
                        ...starts,
                        date: value,
                        timeselected: true,
                        errors: null,
                      })
                      if (ends.date === null) {
                        updateEnds({
                          ...ends,
                          date: value,
                          timeselected: true,
                          errors: null,
                        })
                      }
                      updateSelectedDate("ends_time")
                    }}
                  />
                </div>
              </Row>
            )}
          </div>
          <div>
            {selectedDate === "ends_time" && (
              <Row justify="center" className="meeting-timer">
                <div>
                  <TimeSelect
                    value={ends.date}
                    placeholder="00:00"
                    format="HH:mm"
                    onChange={(value) => {
                      if (!value) return
                      updateEnds({
                        ...ends,
                        date: value,
                        timeselected: true,
                        errors: null,
                      })
                      updateSelectedDate("date")
                    }}
                  />
                </div>
              </Row>
            )}
          </div> */}

          {/* <Row justify="space-between" className="selected-date-time-box">
            <span
              data-active={selectedDate === "ends_date"}
              data-selected={ends.date !== null}
              onClick={() => updateSelectedDate("ends_date")}
            >
              {ends.date ? formatDate(ends.date, "iiii, dd 'de' MMM.") : "-"}
            </span>
            <span
              data-active={selectedDate === "ends_time"}
              data-selected={ends.timeselected}
              onClick={() => updateSelectedDate("ends_time")}
            >
              {ends.timeselected ? formatDate(ends.date, "HH:mm") : "-"}
            </span>
          </Row>
          <Row justify="center">
            <ErrorMessage text={ends.errors} isVisible={ends.errors !== null} />
          </Row> */}
          <Row className="meeting-menu add-field-button" align="middle">
            <Icons icon="calendar" />
            <span className="text-date-picker">
              <span
                onClick={() => {
                  if (selectedDate !== "date") {
                    updateSelectedDate("date")
                  } else {
                    updateSelectedDate(null)
                  }
                  // onSelectCalendar(!selectCalendar)
                }}
              >
                {starts.date
                  ? formatDate(starts.date, "iiii, dd 'de' MMM.")
                  : "Selecione uma data"}
              </span>
              {/* SELEÇÃO DE HORARIO */}
              <span className="style-timer">
                <TimeSelect
                  value={starts.date}
                  placeholder="00:00"
                  format="HH:mm"
                  onChange={(value) => {
                    if (!value) return

                    updateStarts({
                      ...starts,
                      date: value,
                      timeselected: true,
                      errors: null,
                    })
                    if (ends.date === null) {
                      updateEnds({
                        ...ends,
                        date: value,
                        timeselected: true,
                        errors: null,
                      })
                    }
                    updateSelectedDate(null)
                  }}
                />
                <span>-</span>
                <TimeSelect
                  value={ends.date}
                  placeholder="00:00"
                  format="HH:mm"
                  onChange={(value) => {
                    if (!value) return
                    updateEnds({
                      ...ends,
                      date: value,
                      timeselected: true,
                      errors: null,
                    })
                    updateSelectedDate(null)
                  }}
                  selectedStartTime={starts.date}
                  disabled={!starts.date}
                />
              </span>
            </span>
          </Row>
          {selectedDate === "date" ? (
            <DatePicker
              className="floating"
              date={selectedDate.includes("starts") ? starts.date : ends.date}
              minDate={new Date()}
              onChange={(date) => {
                updateStarts({
                  ...starts,
                  date,
                  timeselected: false,
                  errors: null,
                })

                updateEnds({
                  ...ends,
                  date,
                  timeselected: false,
                  errors: null,
                })
                updateSelectedDate(null)
              }}
            />
          ) : null}
          {/* {selectedDate === "starts_time" && (
            <Row justify="center" className="meeting-timer">
              <div>
                
              </div>
            </Row>
          )} */}
          {/* {selectedDate === "ends_time" && (
            <Row justify="center" className="meeting-timer">
              <div>
                
              </div>
            </Row>
          )} */}

          <Row
            className="meeting-menu add-field-button"
            align="middle"
            onClick={() => updateEditField({ field: "email" })}
          >
            <Icons icon="add-user" />
            <span>Adicionar pessoas</span>
          </Row>
          <Row>
            <div className="metting-people-box">
              {emails.map((item, index) => (
                <div key={index}>
                  <span
                    className="not-overflow"
                    onClick={() => {
                      form.setFieldsValue({ email: item })
                      updateEditField({ field: "email", index })
                    }}
                  >
                    {item}
                  </span>
                  <Icons
                    icon="close"
                    onClick={() => {
                      const list = [...emails]
                      list.splice(index, 1)
                      updateEmails(list)
                    }}
                  />
                </div>
              ))}
            </div>
          </Row>

          <Row
            className="meeting-menu add-field-button"
            align="middle"
            onClick={() => updateEditField({ field: "video_conference" })}
          >
            <Icons icon="videoconference" />
            <span>Adicionar videoconferência</span>
          </Row>

          <Row
            className="meeting-menu add-field-button"
            align="middle"
            onClick={() => updateEditField({ field: "location" })}
          >
            <Icons icon="location" />
            <span>Adicionar local</span>
          </Row>

          <Row
            className="meeting-menu add-field-button"
            align="middle"
            onClick={() => updateEditField({ field: "description" })}
          >
            <Icons icon="text-description" />
            <span>Adicionar descrição</span>
          </Row>
          <EditFieldModal
            isVisible={editField.field === "email"}
            onCancel={() => {
              form.setFieldsValue({ email: "" })
              updateEditField({})
            }}
            onSave={() => updateFields("email")}
          >
            <Row justify="center">
              <div className="input-title">Adicionar pessoa</div>
              <div className="input-description">
                Insira o email do participante da reunião
              </div>
              <Form.Item
                name="email"
                initialValue=""
                rules={[
                  {
                    type: "email",
                    message: <div className="normal-12">Email inválido</div>,
                  },
                ]}
              >
                <FlattedInput type="email" />
              </Form.Item>
            </Row>
          </EditFieldModal>
          <EditFieldModal
            isVisible={editField.field === "video_conference"}
            onSave={() => updateFields("video_conference")}
            onCancel={() => {
              if (data) {
                form.setFieldsValue({
                  video_conference: data.video_conference,
                })
              }
              updateEditField({})
            }}
          >
            <Row justify="center">
              <div className="input-title">Adicionar videoconferência</div>
              <div className="input-description">Insira o link da reunião</div>
              <Form.Item
                name="video_conference"
                initialValue=""
                rules={[
                  {
                    type: "url",
                    message: "Link inválido",
                  },
                ]}
              >
                <FlattedInput></FlattedInput>
              </Form.Item>
            </Row>
          </EditFieldModal>
          <EditFieldModal
            isVisible={editField.field === "location"}
            onSave={() => updateFields("location")}
            onCancel={() => {
              form.setFieldsValue({ location: "" })
              updateEditField({})
            }}
          >
            <Row justify="center">
              <div className="input-title">Adicionar local</div>
              <div className="input-description">
                Insira o endereço da reunião
              </div>
              <Form.Item name="location" initialValue="">
                <FlattedInput></FlattedInput>
              </Form.Item>
            </Row>
          </EditFieldModal>
          <EditFieldModal
            isVisible={editField.field === "description"}
            onSave={() => updateFields("description")}
            onCancel={() => {
              form.setFieldsValue({ description: "" })
              updateEditField({})
            }}
          >
            <Row justify="center">
              <div className="input-title">Adicionar descrição</div>
              <div className="input-description">
                Insira a descrição da reunião
              </div>
              <Form.Item name="description" initialValue="">
                <FlattedInput></FlattedInput>
              </Form.Item>
            </Row>
          </EditFieldModal>
        </Form>
      </BaseModal>
    </>
  )
}

const EditFieldModal = ({ isVisible, onSave, onCancel, ...rest }) => {
  return (
    <BaseModal
      className="edit-field-modal"
      isVisible={isVisible}
      okButtonText="Salvar"
      cancelButtonText="Cancelar"
      onOk={() => {
        onSave && onSave()
      }}
      onCancel={() => {
        onCancel && onCancel()
      }}
      isClosable={false}
      width={500}
      {...rest}
    ></BaseModal>
  )
}

EditFieldModal.propTypes = {
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  isVisible: PropTypes.bool,
}

MeetingModal.propTypes = {
  data: PropTypes.object,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
}

export { MeetingModal }
