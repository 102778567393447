import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import {
  passwordChange,
  passwordForgot,
  linkConfirmation,
} from "./passwordService"
import StateStatus from "../../utils/stateStatus"

export const forgotPassword = createAsyncThunk(
  "password/forgot",
  async (data) => {
    const response = await passwordForgot(data)
    return response.data
  }
)

export const confirmLink = createAsyncThunk(
  "password/confirm-link",
  async (data) => {
    const response = await linkConfirmation(data)
    return response.data
  }
)

export const changePassword = createAsyncThunk(
  "password/change",
  async (data) => {
    try {
      const response = await passwordChange(data)
      return response.data
    } catch (err) {
      let message = err.data.message

      if (err.code === "NOT_ALLOWED") message = "Senha inválida"

      const customError = {
        message,
        name: "Api Error",
        data: err.data,
      }
      throw customError
    }
  }
)

const initialState = {
  status: {
    forgotPassword: StateStatus.idle,
    changePassword: StateStatus.idle,
    confirmLink: StateStatus.idle,
  },
  token: null,
  errMessage: null,
}

export const passwordSlice = createSlice({
  name: "password",
  initialState,
  reducers: {
    resetPasswState: (state, action) => {
      state.errMessage = null
      state.status.forgotPassword = StateStatus.idle
      state.status.changePassword = StateStatus.idle
      state.status.confirmLink = StateStatus.idle
      state.token = null
    },
  },
  extraReducers: {
    [forgotPassword.pending]: (state, action) => {
      state.status.forgotPassword = StateStatus.loading
    },
    [forgotPassword.fulfilled]: (state, action) => {
      state.status.forgotPassword = StateStatus.succeeded
    },
    [forgotPassword.rejected]: (state, action) => {
      state.status.forgotPassword = StateStatus.failed
      state.errMessage = action.error.message
    },
    [confirmLink.pending]: (state, action) => {
      state.status.confirmLink = StateStatus.loading
    },
    [confirmLink.fulfilled]: (state, action) => {
      state.status.confirmLink = StateStatus.succeeded
      state.token = action.payload.data.token
    },
    [confirmLink.rejected]: (state, action) => {
      state.status.confirmLink = StateStatus.failed
      state.errMessage = action.error.message
    },
    [changePassword.pending]: (state, action) => {
      state.status.changePassword = StateStatus.loading
    },
    [changePassword.fulfilled]: (state, action) => {
      state.proposal = action.payload.data
      state.status.changePassword = StateStatus.succeeded
    },
    [changePassword.rejected]: (state, action) => {
      state.status.changePassword = StateStatus.failed
      state.errMessage = action.error.message
    },
  },
})

export const { resetPasswState } = passwordSlice.actions
export const selectPasswordState = (state) => state.password
