import api from "../../service/apiCounterparty"
import axios from "axios"
import apiRoutes, { baseUrl } from "../../service/apiRoutes"

export const sendCodeToEmail = (proposalId) =>
  axios.get(
    `${baseUrl}${apiRoutes.proposalCounterparty}/${proposalId}/confirmation`
  )

export const confirmCode = (proposalId, data) =>
  axios.post(
    `${baseUrl}${apiRoutes.proposalCounterparty}/${proposalId}/confirmation`,
    data
  )

export const reviewProposal = (proposalId, data) =>
  api.put(
    `${apiRoutes.proposalCounterparty}/${proposalId}/review-by-anon`,
    data
  )

// export const acceptProposal = (proposalId) =>
//   api.put(`${apiRoutes.proposalCounterparty}/${proposalId}/accept`)

export const fulfillProposal = (proposalId) =>
  api.put(`${apiRoutes.proposalCounterparty}/${proposalId}/fulfill`)

export const getProposal = (proposalId) =>
  api.get(`${apiRoutes.proposalCounterparty}/${proposalId}`)

export const invoiceDownload = (proposalId) =>
  axios.get(
    `${baseUrl}${apiRoutes.proposalCounterparty}/${proposalId}/nf/file`,
    {
      responseType: "arraybuffer",
    }
  )

export const getContractInfo = (id) =>
  api.get(`${apiRoutes.contractView}/${id}`)
