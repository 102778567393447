import React from "react"
import PropTypes from "prop-types"
import "./styles.scss"

const RoudedBox = ({ children, style, className }) => {
  return (
    <div style={style} className={`${className} rouded-box`}>
      {children}
    </div>
  )
}

RoudedBox.propTypes = {
  children: PropTypes.any,
  style: PropTypes.object,
  className: PropTypes.string,
}

export default RoudedBox
