import emailIcon from "../../assets/envelop-icon.svg"
import doneIcon from "../../assets/done-icon.svg"
import React from "react"

const MailSentIcon = () => {
  return (
    <div>
      <div
        style={{
          width: "150px",
          height: "150px",
          backgroundColor: "#D9E4FA",
          borderRadius: "50%",
          textAlign: "center",
          lineHeight: "140px",
        }}
      >
        <img src={emailIcon} width="80px" />
        <div
          style={{
            width: "23px",
            height: "23px",
            backgroundColor: "white",
            borderRadius: "50%",
            textAlign: "center",
            lineHeight: "21px",
            position: "relative",
            top: "-45px",
            left: "100px",
          }}
        >
          <img src={doneIcon} width="22px" />
        </div>
      </div>
    </div>
  )
}

export { MailSentIcon }
