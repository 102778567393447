import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import StateStatus from "../../utils/stateStatus"
import { contract, contractSign } from "./contractService"

export const getContract = createAsyncThunk(
  "contract/get",
  async ({ documentId, signerId }) => {
    try {
      const response = await contract({
        document_id: documentId,
        signer: signerId,
      })

      return response.data
    } catch (err) {
      throw Error(err.response.data.errors.msg)
    }
  }
)

export const signContract = createAsyncThunk("contract/sign", async (data) => {
  try {
    const response = await contractSign(data)
    return response.data
  } catch {
    throw Error(
      "Não foi possível assinar o documento. Tente novamente mais tarde!"
    )
  }
})

const initialState = {
  status: {
    signContract: StateStatus.idle,
    getContract: StateStatus.idle,
  },
  contract: {},
  errMessage: null,
}

export const contractSlice = createSlice({
  name: "contract",
  initialState,
  reducers: {
    resetErrorMessage: (state, action) => {
      state.errMessage = null
    },
  },
  extraReducers: {
    [getContract.pending]: (state, action) => {
      state.status.getContract = StateStatus.loading
    },
    [getContract.fulfilled]: (state, action) => {
      state.status.getContract = StateStatus.succeeded
      state.contract = action.payload
    },
    [getContract.rejected]: (state, action) => {
      state.status.getContract = StateStatus.failed
      state.errMessage = action.error.message
    },
    [signContract.pending]: (state, action) => {
      state.status.signContract = StateStatus.loading
    },
    [signContract.fulfilled]: (state, action) => {
      state.status.signContract = StateStatus.succeeded
    },
    [signContract.rejected]: (state, action) => {
      state.status.signContract = StateStatus.failed
      state.errMessage = action.error.message
    },
  },
})

export const { resetErrorMessage } = contractSlice.actions
export const selectContractState = (state) => state.contract
