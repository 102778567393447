import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  selectConfigState,
  updatePassword,
} from "../../../../configurationsSlice"
import { Form, Row, Col } from "antd"
import BaseModal from "../../../../../../common/components/Modal"
import StateStatus from "../../../../../../utils/stateStatus"
import FlattedInputPassword from "../../../../../../common/components/FlattedInputPassword"
import PropTypes from "prop-types"
import "./styles.scss"

const PasswordModal = ({ isVisible, onSuccess, onCancel }) => {
  const dispatch = useDispatch()
  const configState = useSelector(selectConfigState)
  const [passValidation, updatePassValidation] = useState({})
  const [form] = Form.useForm()

  useEffect(() => {
    switch (configState.status.updatePassword) {
      case StateStatus.succeeded:
        form.resetFields()
        onSuccess()
        break
      case StateStatus.failed:
        form.setFields([{ name: "password", errors: ["Senha incorreta"] }])
        break
    }
  }, [configState.status.updatePassword])

  return (
    <BaseModal
      className="change-password-modal"
      isVisible={isVisible}
      okButtonText="Alterar"
      cancelButtonText="Cancelar"
      isLoading={configState.status.updatePassword === StateStatus.loading}
      onOk={() => {
        form.submit()
      }}
      onCancel={() => {
        form.resetFields()
        onCancel()
      }}
      title="Alterar Senha"
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={(values) => {
          dispatch(updatePassword(values))
        }}
      >
        <Form.Item name="password" label="Senha atual">
          <FlattedInputPassword style={{ width: "314px" }} />
        </Form.Item>
        <div style={{ marginBottom: "20px" }} className="normal-14-500">
          Nova senha
        </div>
        <div style={{ marginBottom: "20px" }} className="normal-14">
          Crie uma senha forte: use a combinação de 8 caracteres, utilize letras
          maiúsculas, minúsculas e números.
        </div>
        <Form.Item
          name="new_password"
          rules={[
            {
              required: true,
              message: <div className="normal-12">Insira uma senha</div>,
            },
            () => ({
              validator(_, __) {
                const falses = Object.entries(passValidation).filter(
                  (item) => item[1] === false
                )
                if (falses.length > 0) {
                  return Promise.reject(new Error("Senha inválida"))
                }
                return Promise.resolve()
              },
            }),
          ]}
        >
          <FlattedInputPassword
            style={{ width: "314px" }}
            onChange={(event, result) => {
              updatePassValidation(result)
            }}
          />
        </Form.Item>
        <div className="change-password-validation" style={{ width: "314px" }}>
          <Row>
            <Col
              span={12}
              className={passValidation.hasEigthLength ? "checked" : ""}
            >
              8 caracteres
            </Col>
            <Col
              span={12}
              className={passValidation.hasOneCapitalLetter ? "checked" : ""}
            >
              1 letra maiúscula
            </Col>
            <Col
              span={12}
              className={passValidation.hasOneNumber ? "checked" : ""}
            >
              1 número
            </Col>
            <Col
              span={12}
              className={passValidation.hasOneLowerLetter ? "checked" : ""}
            >
              1 letra minúscula
            </Col>
          </Row>
        </div>
      </Form>
    </BaseModal>
  )
}

PasswordModal.propTypes = {
  isVisible: PropTypes.bool,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  onCancel: PropTypes.func,
}

export { PasswordModal }
