import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  listContacts,
  selectContactsData,
  selectConfigState,
  searchContacts,
  removeContact,
  resetActionsStatus,
} from "../../configurationsSlice"
import StateStatus from "../../../../utils/stateStatus"
//
import { Row, Col } from "antd"
import ContactModal from "./components/modal"
import Icons from "../../../../common/components/Icons"
import Search from "../../../../common/components/Search"
import BaseModal from "../../../../common/components/Modal"
import Loading from "../../../../common/components/Loading"
import { CreateContactModal } from "../../../contact/contact"
import { PlainButton } from "../../../../common/components/Button"
//
import { beautifyCpfCnpj, formatPhoneNumber } from "../../../../utils/formaters"
import { LoadMorePages } from "../../../../common/components/Pagination"
//
import emptyImg from "../../../../common/assets/images/contatos.png"
import "./styles.scss"

const ContactsList = () => {
  const [showCreateModal, updateShowCreateModal] = useState(false)
  const [showContactModal, updateShowContactModal] = useState(false)
  const [showDeleteModal, updateShowDeleteModal] = useState(false)
  const [showErrorModal, updateShowErrorModal] = useState(false)
  const [contact, updateContact] = useState({})

  const configState = useSelector(selectConfigState)
  const contacts = useSelector(selectContactsData)
  const dispatch = useDispatch()

  useEffect(() => {
    document.body.style.overflow = "auto"
    dispatch(listContacts({ page: 1, filter: contacts.filter }))
  }, [])

  useEffect(() => {
    switch (configState.status.listContacts) {
      case StateStatus.idle:
        dispatch(
          listContacts({
            page: 1,
            filter: contacts.filter,
          })
        )
        break
      case StateStatus.failed:
        updateShowErrorModal(true)
    }
  }, [configState.status.listContacts])

  useEffect(() => {
    switch (configState.status.removeContact) {
      case StateStatus.succeeded:
        dispatch(searchContacts(contacts.filter))
        break
      case StateStatus.failed:
        updateShowErrorModal(true)
        dispatch(resetActionsStatus())
        break
    }
  }, [configState.status.removeContact])

  const firstLoading =
    (!contacts.items || contacts.items.length === 0) &&
    (configState.status.listContacts === StateStatus.idle ||
      configState.status.listContacts === StateStatus.loading)
  const loading = configState.status.listContacts === StateStatus.loading
  const nodata =
    contacts.items &&
    contacts.items.length === 0 &&
    contacts.filter === "" &&
    configState.status.listContacts === StateStatus.succeeded

  return (
    <>
      <div className="config-tab-content">
        <div className={`root-content ${firstLoading ? "" : "visible"}`}>
          <div>
            <div className="normal-16-500">Lista de contatos cadastrados</div>
            <div className="normal-12">
              Consulte, edite ou adicione uma conta para deixar cadastrado em
              sua listagem de dados bancários
            </div>
          </div>
          <Row justify="space-between">
            <Search
              placeholder="Pesquisar contato"
              onSearch={(value) => dispatch(searchContacts(value))}
              delay={1}
            />
            <PlainButton
              icon={<Icons icon="plus" />}
              onClick={() => {
                updateContact({})
                updateShowCreateModal(true)
              }}
            >
              Adicionar Contato
            </PlainButton>
          </Row>
          {!nodata && (
            <Row
              justify="space-between"
              gutter={[16, 16]}
              className="normal-14-500 header-row"
            >
              <Col span={4}>CPF/CNPJ</Col>
              <Col span={3}>Nome</Col>
              <Col span={3}>Contato</Col>
              <Col span={5}>E-Mail</Col>
              <Col span={4}>Telefone</Col>
              <Col span={3} style={{ textAlign: "center" }}>
                Ações
              </Col>
            </Row>
          )}
          {loading && contacts.page === 1 ? (
            <Loading />
          ) : (
            <>
              {contacts.items &&
                contacts.items.map((item, index) => (
                  <Row
                    key={index}
                    justify="space-between"
                    gutter={[16, 16]}
                    className="normal-14 body-row"
                    align="middle"
                  >
                    <Col span={4}>
                      {item.cpf_cnpj ? beautifyCpfCnpj(item.cpf_cnpj) : " - "}
                    </Col>
                    <Col span={3} className="not-overflow">
                      {item.company_name}
                    </Col>
                    <Col span={3} className="not-overflow">
                      {item.name}
                    </Col>
                    <Col span={5} className="not-overflow">
                      {item.email}
                    </Col>
                    <Col span={4}>
                      {item.phone ? formatPhoneNumber(item.phone) : " - "}{" "}
                    </Col>
                    <Col span={3}>
                      <Row justify="space-around">
                        <div
                          className="show-button"
                          onClick={() => {
                            updateContact(item)
                            updateShowContactModal(true)
                          }}
                        >
                          <Icons icon="show" title="Ver" />
                        </div>
                        <div
                          className="edit-button"
                          onClick={() => {
                            updateContact(item)
                            updateShowCreateModal(true)
                          }}
                        >
                          <Icons icon="edit" title="Editar" />
                        </div>
                        <div
                          className="delete-button"
                          onClick={() => {
                            updateContact(item)
                            updateShowDeleteModal(true)
                          }}
                        >
                          <Icons icon="delete" title="Excluir" />
                        </div>
                      </Row>
                    </Col>
                  </Row>
                ))}

              {!nodata && (
                <LoadMorePages
                  isVisible={
                    contacts.page < contacts.totalPages && contacts.page >= 1
                  }
                  title="Mais fornecedores"
                  isLoading={loading && contacts.page >= 1}
                  onClick={() => {
                    dispatch(
                      listContacts({
                        page: contacts.page + 1,
                        filter: contacts.filter,
                      })
                    )
                  }}
                />
              )}
            </>
          )}
        </div>

        {firstLoading ? (
          <Loading />
        ) : (
          nodata && (
            <div className="empty-card">
              <img
                src={emptyImg}
                alt="imagem para simbolizar que a lista está vazia"
              />
              <span className="normal-14-400">
                Você não possui contatos cadastrados
              </span>
            </div>
          )
        )}
      </div>
      {showCreateModal && (
        <CreateContactModal
          data={contact}
          onSaved={(data) => {
            dispatch(searchContacts(contacts.filter))
            updateShowCreateModal(false)
          }}
          onCancel={() => {
            updateContact({})
            updateShowCreateModal(false)
          }}
        />
      )}
      {showContactModal && (
        <ContactModal
          data={contact}
          onClose={() => {
            updateShowContactModal(false)
          }}
        />
      )}
      <BaseModal
        isVisible={showErrorModal}
        title="Erro!"
        okButtonText="Ok"
        onOk={() => {
          updateShowErrorModal(false)
        }}
      >
        {configState.errMessage}
      </BaseModal>
      <BaseModal
        isVisible={showDeleteModal}
        title={"Excluir contato"}
        isLoading={configState.status.removeContact === StateStatus.loading}
        okButtonText={"Sim"}
        okButtonColor="red"
        onOk={() => {
          dispatch(removeContact(contact.id)).then(() => {
            updateShowDeleteModal(false)
          })
        }}
        cancelButtonText={"Não"}
        onCancel={() => {
          updateShowDeleteModal(false)
        }}
      >
        <Row justify="center" gutter={[8, 8]}>
          <Col span={24} style={{ textAlign: "center" }}>
            Tem certeza que deseja excluir este contato?
          </Col>
          {contact && (
            <Col span={24} style={{ textAlign: "center" }}>
              Contato: {contact.name}
              <br />
              Empresa: {contact.company_name}
            </Col>
          )}
        </Row>
      </BaseModal>
    </>
  )
}

export default ContactsList
