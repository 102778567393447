import React, { useEffect } from "react"
import { useHistory, useParams, Switch, Route } from "react-router-dom"
//
import { useDispatch, useSelector } from "react-redux"
import {
  getProposal,
  resetAll,
  resetStatus,
  selectCreateNfseSlice,
} from "./createNfseSlice"
import StateStatus from "../../utils/stateStatus"
//
import Loading from "../../common/components/Loading"
import BaseModal from "../../common/components/Modal"
import Icons from "../../common/components/Icons"
//
// import EmissionNfse from "./pages/emissionNfse/emissionNfse"
import InvoiceUpload from "./pages/invoiceUpload/invoiceUpload"
import NfseResume from "./pages/nfseResume/nfseResume"
//
import "./styless.scss"

const CreateNfse = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const slice = useSelector(selectCreateNfseSlice)
  //
  const status = slice.status
  //
  const { proposalId } = useParams()

  useEffect(() => {
    if (slice.proposal?.id !== proposalId) {
      dispatch(resetAll())
    }

    if (proposalId) {
      dispatch(getProposal({ id: proposalId }))
    }
  }, [proposalId])

  return (
    <div className="root-container create-nfse-page">
      <Switch>
        <Route
          path={[
            // "/dashboard/nfse/create/:proposalId/emitter",
            // "/dashboard/nfse/create/:proposalId/edit-emitter",
            "/dashboard/nfse/create/:proposalId/upload",
            "/dashboard/nfse/create/:proposalId/emit",
          ]}
        >
          <div className="back-button" onClick={() => history.goBack()}>
            <Icons icon="arrow-left" />
            Voltar
          </div>
        </Route>
      </Switch>

      {slice.status.getProposal === StateStatus.loading &&
        slice.proposal.id !== proposalId && <Loading />}

      {slice.status.getProposal === StateStatus.succeeded && (
        <Switch>
          {/* <Route
            path={[
              "/dashboard/nfse/create/:proposalId/emitter",
              "/dashboard/nfse/create/:proposalId/edit-emitter",
            ]}
            component={MillionNote}
          /> */}

          <Route
            path={["/dashboard/nfse/create/:proposalId/upload"]}
            component={InvoiceUpload}
          />

          {/* <Route
            path={["/dashboard/nfse/create/:proposalId/emit"]}
            component={EmissionNfse}
          /> */}

          <Route
            path={[
              "/dashboard/nfse/create/:proposalId/resume-emit/:nfseId?",
              "/dashboard/nfse/create/:proposalId/resume-upload/:nfseId?",
            ]}
            component={NfseResume}
          />

          {/* <Route
            path={["/dashboard/nfse/create/:proposalId"]}
            component={EmissionModel}
          /> */}
        </Switch>
      )}

      <BaseModal
        type="error"
        isVisible={
          status.loginMillionNote === StateStatus.failed ||
          status.sendNfse === StateStatus.failed
        }
        title={
          slice.errMessage?.includes("\n")
            ? slice.errMessage.split("\n", 2)[0]
            : "Erro!"
        }
        okButtonText="Tentar novamente"
        cancelButtonText="Voltar a tela inicial"
        onOk={() => dispatch(resetStatus())}
        onCancel={() => history.replace("/dashboard/nfse")}
      >
        {slice.errMessage?.includes("\n")
          ? slice.errMessage.split("\n", 2)[1]
          : slice.errMessage}
      </BaseModal>
    </div>
  )
}

export default CreateNfse
