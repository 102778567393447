import { MaskedInput } from "antd-mask-input"
import React from "react"
import "./styles.scss"

const RoundedInput = (props) => {
  return (
    <input data-testid="input" className="rounded-input" {...props}></input>
  )
}

const RoundedMaskedInput = (props) => {
  return (
    <MaskedInput
      data-testid="input"
      className="rounded-input"
      {...props}
    ></MaskedInput>
  )
}

export { RoundedMaskedInput }

export default RoundedInput
