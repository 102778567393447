import React, { useCallback, useEffect, useState } from "react"
import Icons from "../Icons"
import PropTypes from "prop-types"
import "./styles.scss"
import MaskedInput from "antd-mask-input/build/main/lib/MaskedInput"
import CountryPhoneInput from "antd-country-phone-input"
import validators from "../../../utils/validators"
import { InputNumber } from "antd"
import { parsePhone } from "../../../utils/formaters"
// import { parsePhone } from "../../../utils/formaters"

const FlattedInput = (props) => {
  return (
    <input data-testid="flatted-input" className="flatted-input" {...props} />
  )
}

const FlattedTextArea = (props) => {
  return (
    <textarea
      data-testid="flatted-textarea"
      className="flatted-textarea"
      {...props}
    />
  )
}

const FlattedMaskedInput = (props) => {
  return (
    <MaskedInput
      data-testid="flatted-masked-input"
      className="flatted-input flatted-masked-input"
      {...props}
    />
  )
}

const FlattedDropdownInput = ({ onClear, showClear, ...props }) => {
  return (
    <div
      className={`flatted-dropdown-input-container ${
        showClear ? "with-cleaner" : ""
      }`}
    >
      <div className={"flatted-dropdown-input"}>
        <input className="flatted-input" {...props}></input>
        <Icons icon="down" className="dropdown-icon" />
      </div>
      {showClear ? (
        <span
          className="dropdown-cleaner"
          style={{ display: showClear ? "inline" : "none", cursor: "pointer" }}
          onClick={onClear}
        >
          <Icons icon="circle-close-filled" />
        </span>
      ) : null}
    </div>
  )
}

const FlattedLabelInput = ({ label, ...props }) => {
  return (
    <div className="flatted-label-input">
      <label>{label}</label>
      <input className="flatted-input" {...props} />
    </div>
  )
}

const FlattedInputNumber = ({ formatter, parser, ...props }) => {
  return (
    <InputNumber
      className="flatted-input-number"
      formatter={formatter}
      parser={parser}
      {...props}
    />
  )
}

const FlattedInputCurrency = ({ ...props }) => {
  const formatCurrency = useCallback((value) => {
    value = value.toString()
    if (value.length === 0) return ""
    if (value.length <= 2) return `R$ 0,${value.padStart(2, 0)}`

    const prefixValue = value.slice(0, -2).replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    return `R$ ${prefixValue},${value.slice(-2)}`
  })

  return (
    <FlattedInputNumber
      formatter={formatCurrency}
      parser={(value) => value.replace(/\D/g, "")}
      placeholder="R$ 0,00"
      {...props}
    />
  )
}

const FlattedPhoneInputForm = ({ value, placeholder, onChange, id }) => {
  const defaultNumber = {
    code: 55,
    number: "",
    phone: "",
  }

  const phoneParse = (rawPhone) => {
    // const [countryCode, phone] = rawPhone.split("-")

    const parsed = parsePhone(rawPhone)
    // return {
    //   code: parseInt(countryCode.replace("+", "")),
    //   number: phone ? parseInt(phone) : "",
    //   phone: rawPhone,
    //   isValid: true,
    // }

    return {
      code: parsed.code,
      number: parsed.number,
      phone: parsed.phone,
      isValid: parsed.isValid,
    }
  }

  const [number, updateNumber] = useState(
    value ? phoneParse(value) : defaultNumber
  )
  const [phoneNumber, updatePhoneNumber] = useState(number.number)
  const [firstRender, updateFirstRender] = useState(true)

  const isBrazilizan = number?.code === 55 || number?.country === "BR"

  useEffect(() => {
    const data = value ? phoneParse(value) : defaultNumber
    updateNumber(data)
    updatePhoneNumber(data.number ? data.number.toString() : "")
  }, [value])

  const dispatchOnChange = () => {
    const input = document.getElementById(id)
    onChange({
      _reactName: "onChange",
      _targetInst: null,
      bubbles: true,
      cancelable: false,
      currentTarget: null,
      defaultPrevented: false,
      eventPhase: 3,
      isDefaultPrevented: () => false,
      isPropagationStopped: () => false,
      isTrusted: true,
      nativeEvent: {
        bubbles: true,
        cancelBubble: false,
        cancelable: false,
        composed: true,
        currentTarget: null,
        data: "",
        dataTransfer: null,
        defaultPrevented: false,
        detail: 0,
        eventPhase: 0,
        explicitOriginalTarget: input,
        inputType: "insertText",
        isComposing: false,
        isTrusted: true,
        layerX: 0,
        layerY: 0,
        originalTarget: input,
        rangeOffset: 0,
        rangeParent: null,
        returnValue: true,
        srcElement: input,
        target: input,
        timeStamp: 0,
        type: "input",
        view: window,
        which: 0,
      },
      target: input,
      timeStamp: 0,
      type: "change",
      phoneNumber: number,
    })
  }

  useEffect(() => {
    if (firstRender) {
      updateFirstRender(false)
    } else {
      dispatchOnChange()
    }
  }, [number.phone, number.code])

  const onChangePhone = (event) => {
    const value = event.target.value
    const rawValue = value.replace(/\D/g, "")
    if (!isBrazilizan || rawValue.length <= 11) {
      updatePhoneNumber(value)

      // update parsed number
      const code = number.code ?? defaultNumber.code
      let phone = `+${code}-`
      if (rawValue.length > 0) {
        phone = `+${code}-${rawValue}`
      }
      updateNumber({ ...phoneParse(phone) })
    }
  }

  return (
    <>
      <input
        style={{ display: "none" }}
        id={id}
        value={`${number.number ?? ""}`.length > 0 ? number.phone : null}
        readOnly={true}
      />

      <div className="flatted-phone-input">
        <CountryPhoneInput
          style={{ position: "relative", top: "-3px" }}
          value={{
            code: number.code,
            phone: number.number,
          }}
          onChange={(value) => {
            const phone = number.number || ""
            const completePhone = `+${value.code}-${phone}`
            updateNumber({ ...phoneParse(completePhone) })
          }}
        />

        <FlattedMaskedInput
          style={{ position: "relative", top: "1px" }}
          placeholder={placeholder ?? "Telefone"}
          value={phoneNumber}
          onChange={onChangePhone}
          maskOptions={{
            dispatch: function (appended, dynamicMasked) {
              if (!isBrazilizan) return dynamicMasked.compiledMasks[2]

              const value = dynamicMasked.unmaskedValue
              const isCellPhone = value[2] === "9"
              return dynamicMasked.compiledMasks[isCellPhone ? 1 : 0]
            },
          }}
          mask={[
            {
              mask: "00 0000-0000",
              lazy: true,
            },
            {
              mask: "00 00000-0000",
              lazy: true,
            },
            {
              mask: "00000000000000000",
              lazy: true,
            },
          ]}
        />

        {/* <FlattedInput
          style={{ position: "relative", top: "1px" }}
          placeholder={placeholder ?? "Telefone"}
          value={phoneNumber}
          onChange={(event) => {
            updatePhoneNumber(event.target.value)

            // update parsed number
            const rawValue = event.target.value.replace(/\D/g, "")
            const code = number.code ?? defaultNumber.code
            let phone = `+${code}-`
            if (rawValue.length > 0) {
              phone = `+${code}-${rawValue}`
            }
            updateNumber({ ...phoneParse(phone) })
          }}
          onBlur={(event) => {
            // const rawValue = event.target.value.replace(/\D/g, "")
            // let phone = `+${number.code}-`
            // if (rawValue.length > 0) {
            //   phone = `+${number.code}-${rawValue}`
            // }
            // updateNumber({ ...phoneParse(phone) })
          }}
        /> */}
      </div>
    </>
  )
}

const FlattedPhoneInput = ({ phone, onChange, placeholder }) => {
  const [current, setCurrent] = useState({ country: "BR", code: 55 })

  useEffect(() => {
    if (phone) {
      if (!phone.code) {
        phone = {
          ...phone,
          number: phone.number ? phone.number.toString() : "",
          country: "BR",
          code: 55,
        }
      }
      setCurrent(phone)
    }
  }, [phone])

  const onChangePhone = useCallback(
    (event) => {
      const value = event.target.value.replace(/\D/g, "")
      const phone = `+${current.code}-${value}`

      const values = {
        country: current.country,
        code: current.code,
        number: value,
        phone,
        isValid: validators.phone(phone),
      }
      setCurrent(values)
      onChange(values)
    },
    [current]
  )

  return (
    <div className="flatted-phone-input">
      {/* o input do CountryPhoneInput foi desabilitado com css 
      para permitir uso de máscara com o FlattedMaskedInput */}

      <CountryPhoneInput
        placeholder={placeholder}
        // style={{ position: "relative", top: "-3px" }}
        value={{
          short: current.country,
          code: current.code,
          phone: current.number,
        }}
        onChange={(value) => {
          const number = current.number || ""
          const phone = `+${value.code}-${number}`

          const values = {
            country: value.short,
            code: value.code,
            number,
            phone,
            isValid: validators.phone(phone),
          }

          setCurrent(values)
          onChange(values)
        }}
      />

      {current.code === 55 || current.country === "BR" ? (
        <FlattedMaskedInput
          style={{ height: "40px" }}
          mask="(00) 00000-0000"
          placeholder="(00) 00000-0000"
          value={current.number ? current.number.toString() : ""}
          onChange={onChangePhone}
        />
      ) : (
        <FlattedInput
          style={{ height: "40px" }}
          placeholder="Telefone"
          value={current.number ? current.number.toString() : ""}
          onChange={onChangePhone}
        />
      )}
    </div>
  )
}

FlattedPhoneInputForm.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  id: PropTypes.any,
}

FlattedPhoneInput.propTypes = {
  phone: PropTypes.object,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
}

// -----------------------------------------------------------

FlattedLabelInput.propTypes = {
  label: PropTypes.string,
}

FlattedInputNumber.defaultProps = {
  formatter: (value) => value.replace(/\D/g, ""),
  parser: (value) => value.replace(/\D/g, ""),
}

FlattedInputNumber.propTypes = {
  formatter: PropTypes.func,
  parser: PropTypes.func,
}

FlattedDropdownInput.propTypes = {
  showClear: PropTypes.any,
  onClear: PropTypes.func,
}

export {
  FlattedInput,
  FlattedMaskedInput,
  FlattedLabelInput,
  FlattedDropdownInput,
  FlattedPhoneInput,
  FlattedInputNumber,
  FlattedInputCurrency,
  FlattedPhoneInputForm,
  FlattedTextArea,
}
export default FlattedInput
