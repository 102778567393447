import React from "react"
import PropTypes from "prop-types"
import Icons from "../Icons"
import { Col, Row } from "antd"
import { Tooltip } from "../Tooltip"

const ContractStatus = ({ status, children }) => {
  return (
    <Row gutter={[4, 0]} className="contract-status" wrap={false}>
      <Col>
        <Icons icon={`contract-${status}`} className="contract-icon" />
      </Col>
      <Col>
        <div className="normal-16-500 not-overflow">
          <Tooltip title={children}>{children}</Tooltip>
        </div>
      </Col>
    </Row>
  )
}

ContractStatus.propTypes = {
  status: PropTypes.string.isRequired,
  children: PropTypes.any,
}

export default ContractStatus
