import { Col, Form, message, Row } from "antd"
import React from "react"
import { BlueButton } from "../../../../common/components/Button"
import FlattedInput from "../../../../common/components/FlattedInput"
import PropTypes from "prop-types"
import "./styles.scss"
import {
  resetShareProposal,
  selectProposalState,
  shareProposalThunk,
} from "../../proposalSlice"
import { useDispatch, useSelector } from "react-redux"
import StateStatus from "../../../../utils/stateStatus"
import { useHistory } from "react-router-dom"

const ShareProposalEmail = ({ rootPath }) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const history = useHistory()
  const proposalState = useSelector(selectProposalState)

  const redirectAfterFinish = () => {
    dispatch(resetShareProposal())
    history.goBack()
  }

  return (
    <>
      <Row className="share-proposal-email" justify="center" align="middle">
        <Col span={24}>
          <Form
            form={form}
            onFinish={(values) => {
              dispatch(
                shareProposalThunk({
                  proposalId: proposalState.data.proposal.id,
                  email: values.email,
                })
              ).then(({ payload }) => {
                if (payload) {
                  redirectAfterFinish()
                  message.success("Proposta enviada com sucesso!")
                } else {
                  redirectAfterFinish()
                  message.error("Não foi possível enviar a proposta.")
                }
              })
            }}
          >
            <Row justify="center" className="share-title">
              Insira o e-mail para enviar a proposta
            </Row>
            <Row className="share-options" justify="center" gutter={[16, 16]}>
              <Col span={24}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: (
                        <div className="normal-12">Campo obrigatório</div>
                      ),
                    },
                    {
                      type: "email",
                      message: <div className="normal-12">Email inválido</div>,
                    },
                  ]}
                >
                  <FlattedInput type="email" placeholder="E-mail" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col span={24}>
          <Row justify="center" align="center">
            <Col>
              <BlueButton
                isLoading={
                  proposalState.status.shareProposal === StateStatus.loading
                }
                htmlType="button"
                onClick={() => form.submit()}
              >
                Enviar proposta
              </BlueButton>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

ShareProposalEmail.propTypes = {
  rootPath: PropTypes.string,
}

export default ShareProposalEmail
