import React from "react"
import PropTypes from "prop-types"
import "./styles.scss"

const Loading = ({ className }) => {
  return (
    <div className={`loading-container ${className}`}>
      <div data-testid="loading" className="loading"></div>
    </div>
  )
}

Loading.defaultProps = {
  className: "dark medium center",
}

Loading.propTypes = {
  className: PropTypes.string,
}

export default Loading
