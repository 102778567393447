import React from "react"

export const ProposalStatusDraftIcon = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.1816 0.183609C15.2396 0.125408 15.3086 0.0792312 15.3845 0.0477245C15.4605 0.0162178 15.5419 0 15.6241 0C15.7063 0 15.7877 0.0162178 15.8636 0.0477245C15.9395 0.0792312 16.0085 0.125408 16.0666 0.183609L19.8164 3.93344C19.8746 3.9915 19.9208 4.06047 19.9523 4.13639C19.9838 4.21232 20 4.29372 20 4.37593C20 4.45813 19.9838 4.53953 19.9523 4.61546C19.9208 4.69139 19.8746 4.76035 19.8164 4.81841L7.31694 17.3179C7.25696 17.3774 7.18552 17.4242 7.10695 17.4554L0.85722 19.9552C0.743645 20.0007 0.619225 20.0118 0.499385 19.9873C0.379545 19.9627 0.269555 19.9035 0.18305 19.8169C0.0965462 19.7304 0.0373319 19.6205 0.0127486 19.5006C-0.0118346 19.3808 -0.000705711 19.2564 0.0447556 19.1428L2.54465 12.8931C2.57581 12.8145 2.62258 12.743 2.68214 12.6831L15.1816 0.183609ZM14.0079 3.12598L16.874 5.99211L18.4902 4.37593L15.6241 1.5098L14.0079 3.12598ZM15.9903 6.87582L13.1242 4.00969L4.99954 12.1343V12.5006H5.62451C5.79026 12.5006 5.94923 12.5664 6.06643 12.6836C6.18364 12.8008 6.24948 12.9598 6.24948 13.1255V13.7505H6.87446C7.04021 13.7505 7.19917 13.8164 7.31638 13.9336C7.43358 14.0508 7.49943 14.2097 7.49943 14.3755V15.0005H7.86566L15.9903 6.87582ZM3.78959 13.3443L3.6571 13.4768L1.74718 18.2528L6.52322 16.3429L6.65572 16.2104C6.5365 16.1659 6.43372 16.086 6.36112 15.9814C6.28853 15.8769 6.24958 15.7527 6.24948 15.6254V15.0005H5.62451C5.45876 15.0005 5.29979 14.9346 5.18259 14.8174C5.06538 14.7002 4.99954 14.5412 4.99954 14.3755V13.7505H4.37457C4.2473 13.7504 4.12309 13.7115 4.01855 13.6389C3.91402 13.5663 3.83414 13.4635 3.78959 13.3443Z"
          fill="#002999"
        />
      </svg>
    </span>
  )
}
