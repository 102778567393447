import React from "react"
import PropTypes from "prop-types"
import "./styles.scss"
import Icons from "../Icons"
import { formatDate, parseDate } from "../../../utils/formaters"

const InfoDateTime = ({ datetimeStr, datetime, fmtIn }) => {
  const fmtOut = "HH:mm - dd/MMM"

  return (
    <div className="info-date-time">
      <span>
        <Icons icon="watch" />
      </span>
      <span>
        {datetime
          ? formatDate(datetime, fmtOut).toUpperCase()
          : datetimeStr
          ? formatDate(parseDate(datetimeStr, fmtIn), fmtOut).toUpperCase()
          : "00:00 - 00/00"}
      </span>
    </div>
  )
}

InfoDateTime.defaultProps = {
  fmtIn: "dd/MM/yyyy HH:mm",
}

InfoDateTime.propTypes = {
  datetimeStr: PropTypes.string,
  datetime: PropTypes.object,
  fmtIn: PropTypes.string,
}

export default InfoDateTime
