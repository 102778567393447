import React from "react"

export const LampIcon = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#141414"
        strokeWidth="1.5"
      >
        <g strokeLinecap="round" strokeLinejoin="round">
          <path d="M13 16.001V11.41" />
          <path d="M10.883 11.41h4.237" />
        </g>
        <path
          d="M16.001 16.264v2.738a3 3 0 0 1-3.001 3.001h0a3 3 0 0 1-3.001-3.001v-2.738C7.34 14.881 5.971 11.861 6.683 8.95s3.32-4.958 6.317-4.958 5.605 2.047 6.317 4.958-.657 5.931-3.315 7.314h0z"
          strokeLinejoin="round"
        />
        <path
          d="M16.457 16.002H9.543"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  )
}
