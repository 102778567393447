import React, { useEffect } from "react"
import {
  useParams,
  Switch,
  Route,
  useRouteMatch,
  useHistory,
} from "react-router-dom"
//
import { useDispatch, useSelector } from "react-redux"
import {
  getNfse,
  resetAll,
  selectCreateNfseSlice,
  sendNfse,
} from "../../createNfseSlice"
import StateStatus from "../../../../utils/stateStatus"
//
import { Col, Row } from "antd"
import { BlueButton, WhiteButton } from "../../../../common/components/Button"
import RoudedBox from "../../../../common/components/RoudedBox"
import Icons from "../../../../common/components/Icons"
import Loading from "../../../../common/components/Loading"
//
import { cnpjMask, numberToReal } from "../../../../utils/formaters"
import "./styless.scss"
import { urlToNewTabLink } from "../../../../utils/files"
import BaseModal from "../../../../common/components/Modal"

const NfseResume = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const slice = useSelector(selectCreateNfseSlice)
  //
  const nfse = slice.nfse
  const status = slice.status
  const { path } = useRouteMatch()
  //
  const { nfseId } = useParams()

  useEffect(() => {
    if (nfseId) {
      dispatch(getNfse(nfseId))
    }
  }, [nfseId])

  const isCreated = !!nfseId

  return (
    <div className="nfse-resume-page">
      <div className="back-button" onClick={() => history.goBack()}>
        <Icons icon="arrow-left" />
        Voltar
      </div>

      {status.getNfse === StateStatus.loading && <Loading />}

      {nfse.proposal && (
        <Switch>
          <Route
            path={[
              "/dashboard/nfse/create/:proposalId/resume-emit/:nfseId?",
              "/dashboard/nfse/create/:proposalId/resume-upload/:nfseId?",
              "/dashboard/nfse/:nfseId/resume",
            ]}
          >
            <Row justify="space-between" align="middle">
              <Col>
                <h1 className="normal-24-500">Resumo da Nota Fiscal</h1>
              </Col>
              {(nfse.id && !nfse.is_file_upload) ||
              path.includes("resume-emit") ? (
                <Col>
                  <WhiteButton
                    onClick={() => {
                      history.push(
                        `/dashboard/nfse/create/${nfse.proposal.id}/emitter`
                      )
                    }}
                  >
                    Editar emissor
                  </WhiteButton>
                </Col>
              ) : null}
            </Row>

            <p className="title-box">Dados da proposta</p>
            <RoudedBox>
              <div className="junction">
                <p className="label-value">Proposta vinculada</p>
                <span className="value">{nfse.proposal.title}</span>
              </div>

              <div className="junction">
                <p className="label-value">Valor da NF-e</p>
                <span className="value">{numberToReal(nfse.value / 100)}</span>
              </div>

              <div className="junction">
                <p className="label-value">Número da NF-e</p>
                <span className="value">{nfse.nf_number ?? " - "}</span>
              </div>

              <div className="junction">
                <p className="label-value">Data da emissão</p>
                <span className="value">{nfse.emitted_at ?? " - "}</span>
              </div>

              {nfse.id && nfse.status_name ? (
                <div className="junction">
                  <p className="label-value">Status emissão</p>
                  <span className="value">{nfse.status_name}</span>
                </div>
              ) : null}
            </RoudedBox>

            <p className="title-box">Dados do prestador</p>
            <RoudedBox>
              <div className="junction">
                <p className="label-value">Nome</p>
                <span className="value">{nfse.contracted.name}</span>
              </div>

              <div className="junction">
                <p className="label-value">CNPJ</p>
                <span className="value">
                  {nfse.contracted.cpf_cnpj &&
                    cnpjMask(nfse.contracted.cpf_cnpj)}
                </span>
              </div>

              <div className="junction">
                <p className="label-value">E-mail</p>
                <span className="value">{nfse.contracted.email}</span>
              </div>
            </RoudedBox>

            <p className="title-box">Dados do tomador</p>
            <RoudedBox>
              <div className="junction">
                <p className="label-value">Nome</p>
                <span className="value">{nfse.contractor.name}</span>
              </div>

              <div className="junction">
                <p className="label-value">CPF/CNPJ</p>
                <span className="value">
                  {nfse.contractor.cpf_cnpj &&
                    cnpjMask(nfse.contractor.cpf_cnpj)}
                </span>
              </div>

              <div className="junction">
                <p className="label-value">E-mail</p>
                <span className="value">{nfse.contractor.email}</span>
              </div>
            </RoudedBox>

            {nfse.service_code && nfse.description && (
              <>
                <p className="title-box">Dados do serviço</p>
                <RoudedBox>
                  <div className="junction">
                    <p className="label-value">Cód. do serviço</p>

                    <span className="value">
                      {nfse.service_code
                        ? `${nfse.service_code.code} - ${nfse.service_code.description}`
                        : " - "}
                    </span>
                  </div>

                  <div className="junction">
                    <p className="label-value">Descrição</p>
                    <span className="value">{nfse.description ?? " - "}</span>
                  </div>
                </RoudedBox>
              </>
            )}

            {nfse.public_url && (
              <>
                <p className="title-box">Arquivo</p>
                <RoudedBox>
                  <div
                    className="junction selected-file"
                    onClick={() => {
                      const link = urlToNewTabLink(
                        nfse.public_url,
                        nfse.file?.name ?? "nota-fiscal.pdf"
                      )
                      link.click()
                    }}
                  >
                    <Icons icon="file" />
                    {nfse.file?.name ?? "nota-fiscal.pdf"}
                  </div>
                </RoudedBox>
              </>
            )}
          </Route>
        </Switch>
      )}

      {!isCreated && (
        <Row justify="end" className="buttons">
          <BlueButton
            onClick={() =>
              dispatch(
                sendNfse({
                  type: path.includes("upload") ? "upload" : "manually",
                })
              )
            }
            isLoading={slice.status.sendNfse === StateStatus.loading}
            disabled={slice.status.sendNfse === StateStatus.loading}
          >
            {path.includes("upload") ? "Enviar" : "Emitir Nota Fiscal"}
          </BlueButton>
        </Row>
      )}

      <BaseModal
        type="success"
        isVisible={status.sendNfse === StateStatus.succeeded}
        title={path.includes("upload") ? "NF-e enviada" : "NF-e criada"}
        okButtonText="Finalizar"
        onClose={() => {
          dispatch(resetAll())
          history.replace("/dashboard/nfse")
        }}
        onOk={() => {
          dispatch(resetAll())
          history.replace("/dashboard/nfse")
        }}
      >
        {path.includes("upload")
          ? "Confira suas notas fiscais na sessão \"Notas Fiscais\"."
          : "Sua nota fiscal será emitida automaticamente pelo Portal da Prefeitura de São Paulo. Assim que estiver disponível você poderá acessa-lá na sessão \"Notas Fiscais\""}
      </BaseModal>
    </div>
  )
}

export default NfseResume
