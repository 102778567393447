export const freelaContractorJson = [
  {
    type: "div",
    props: {
      className: "proposal-resume",
    },
    elements: [
      // PROFILE CONTRACTED
      {
        type: "Row",
        props: {
          align: "top",
          gutter: [16, 16],
          justify: "space-between",
          className: "row-content proposal-profile",
        },
        elements: [
          // AVATAR
          {
            type: "Col",
            props: {
              xs: 24,
              sm: 24,
              md: 4,
              lg: 3,
            },
            elements: [
              {
                type: "div",
                props: {
                  className: "avatar",
                },
                elements: [
                  {
                    type: "Avatar",
                    name: "contractor.avatar_url",
                  },
                ],
              },
            ],
          },
          // NAME
          {
            type: "Col",
            props: {
              xs: 24,
              sm: 24,
              md: 8,
              lg: 9,
            },
            elements: [
              {
                type: "div",
                props: {
                  className: "info normal-16",
                },
                elements: [
                  {
                    type: "div",
                    props: {
                      className: "normal-20",
                    },
                    elements: [
                      {
                        type: "text",
                        name: "contractor.name",
                      },
                    ],
                  },
                  {
                    type: "div",
                    props: {
                      className: "normal-16",
                    },
                    elements: [
                      {
                        type: "text",
                        name: "contractor.email",
                      },
                    ],
                  },
                  {
                    type: "SocialMedias",
                    name: "contractor.social_medias",
                    default: "",
                    props: {
                      className: "normal-20",
                    },
                  },
                  // TODO: adicionar items (social_networks)
                ],
              },
            ],
          },
          // CNPJ
          {
            type: "Col",
            props: {
              xs: 24,
              sm: 24,
              md: 12,
              lg: 12,
              className: "normal-20",
            },
            elements: [
              {
                type: "div",
                props: {
                  className: "normal-16",
                },
                elements: [
                  {
                    type: "div",
                    props: {},
                    elements: [
                      {
                        type: "text",
                        default: "CNPJ: ",
                      },
                      {
                        type: "CpfCnpj",
                        default: "-",
                        name: "contractor.cpf_cnpj",
                      },
                    ],
                  },
                  {
                    type: "div",
                    props: {},
                    elements: [
                      {
                        type: "text",
                        default: "Nome/Razão Social: ",
                      },
                      {
                        type: "text",
                        name: "contractor.company_name",
                      },
                    ],
                  },
                ],
              },
              {
                type: "div",
                props: {
                  className: "address normal-16",
                },
                elements: [
                  {
                    type: "Address",
                    name: "address",
                    default: "",
                  },
                ],
              },
            ],
          },
        ],
      },

      // CONTRACTOR
      {
        type: "CollapseRowHeader",
        props: {
          title: "Dados da contratada",
          icon: "suitcase",
          resume: "Nome e CPF/CNPJ",
          collapseFor: "row-content-counterparty",
          path: "/dashboard/proposal/3",
        },
      },

      {
        type: "CollapseRowContent",
        props: {
          id: "row-content-counterparty",
        },
        elements: [
          {
            type: "Col",
            props: {
              xs: 24,
              sm: 24,
              md: 12,
              lg: 12,
            },
            elements: [
              {
                type: "LabelValue",
                conditional: {
                  name: "contracted.cpf_cnpj",
                },
                elements: [
                  {
                    type: "label",
                    elements: [
                      {
                        type: "text",
                        default: "CNPJ:",
                      },
                    ],
                  },
                  {
                    type: "div",
                    props: {},
                    elements: [
                      {
                        type: "CpfCnpj",
                        name: "contracted.cpf_cnpj",
                      },
                    ],
                  },
                ],
              },
              {
                type: "LabelValue",
                conditional: {
                  name: "contracted.company_name",
                },
                elements: [
                  {
                    type: "label",
                    elements: [
                      {
                        type: "text",
                        default: "Nome:",
                      },
                    ],
                  },
                  {
                    type: "div",
                    props: {},
                    elements: [
                      {
                        type: "text",
                        name: "contracted.company_name",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            type: "Col",
            props: {
              xs: 24,
              sm: 24,
              md: 12,
              lg: 12,
            },
            elements: [
              {
                type: "LabelValue",
                conditional: {
                  name: "contracted.name",
                },
                elements: [
                  {
                    type: "label",
                    elements: [
                      {
                        type: "text",
                        default: "Contato:",
                      },
                    ],
                  },
                  {
                    type: "div",
                    props: {},
                    elements: [
                      {
                        type: "text",
                        name: "contracted.name",
                      },
                    ],
                  },
                ],
              },
              {
                type: "LabelValue",
                conditional: {
                  name: "contracted.email",
                },
                elements: [
                  {
                    type: "label",
                    elements: [
                      {
                        type: "text",
                        default: "E-mail:",
                      },
                    ],
                  },
                  {
                    type: "div",
                    props: {},
                    elements: [
                      {
                        type: "text",
                        name: "contracted.email",
                      },
                    ],
                  },
                ],
              },
              {
                type: "LabelValue",
                conditional: {
                  name: "contracted.phone",
                },
                elements: [
                  {
                    type: "label",
                    elements: [
                      {
                        type: "text",
                        default: "Telefone:",
                      },
                    ],
                  },
                  {
                    type: "div",
                    props: {},
                    elements: [
                      {
                        type: "text",
                        name: "contracted.phone",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },

      // SCOPE
      {
        type: "CollapseRowHeader",
        props: {
          title: "Descrições do projeto",
          icon: "description-service",
          resume:
            "Validade da proposta, prazo de entrega dos serviços e descrição.",
          collapseFor: "row-content-services",
          path: "/dashboard/proposal/1",
        },
      },
      {
        type: "CollapseRowContent",
        props: {
          id: "row-content-services",
        },
        elements: [
          {
            type: "Col",
            props: {
              xs: 24,
              sm: 24,
              md: 12,
              lg: 12,
            },
            elements: [
              {
                type: "LabelValue",
                elements: [
                  {
                    type: "label",
                    elements: [
                      {
                        type: "text",
                        default: "Descrição:",
                      },
                    ],
                  },
                  {
                    type: "div",
                    props: {},
                    elements: [
                      {
                        type: "text",
                        name: "job_description",
                      },
                    ],
                  },
                ],
              },

              {
                type: "LabelValue",
                elements: [
                  {
                    type: "label",
                    elements: [
                      {
                        type: "text",
                        default: "Validade da proposta:",
                      },
                    ],
                  },
                  {
                    type: "div",
                    props: {},
                    elements: [
                      {
                        type: "text",
                        name: "valid_until",
                      },
                    ],
                  },
                ],
              },
            ],
          },

          {
            type: "Col",
            props: {
              xs: 24,
              sm: 24,
              md: 12,
              lg: 12,
            },
            elements: [
              {
                type: "LabelValue",
                elements: [
                  {
                    type: "label",
                    elements: [
                      {
                        type: "text",
                        default: "Prazo de entrega dos serviços:",
                      },
                    ],
                  },
                  {
                    type: "div",
                    props: {},
                    elements: [
                      {
                        type: "text",
                        name: "date_to_finish",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },

      // ATTACHMENTS
      {
        type: "CollapseRowHeader",
        props: {
          title: "Anexos",
          icon: "clips",
          resume: "Arquivos anexados.",
          collapseFor: "row-content-anexos",
          path: "/dashboard/proposal/1",
        },
      },

      {
        type: "CollapseRowContent",
        props: {
          id: "row-content-anexos",
          className: "file-container",
          gutter: [16, 16],
        },
        elements: [
          {
            type: "FileItem",
            name: "files",
          },
        ],
      },

      // VALUE
      {
        type: "CollapseRowHeader",
        props: {
          title: "Valor da proposta",
          icon: "currency",
          resume:
            "Valor total, prazo de pagamento, faturamento e observações gerais.",
          collapseFor: "row-content-values",
          path: "/dashboard/proposal/2",
        },
      },

      {
        type: "CollapseRowContent",
        props: {
          id: "row-content-values",
        },
        elements: [
          {
            type: "Col",
            props: {
              xs: 24,
              sm: 24,
              md: 12,
              lg: 12,
            },
            elements: [
              {
                type: "LabelValue",
                elements: [
                  {
                    type: "label",
                    elements: [
                      {
                        type: "text",
                        default: "Valor total:",
                      },
                    ],
                  },
                  {
                    type: "div",
                    props: {},
                    elements: [
                      {
                        type: "text",
                        name: "total_value",
                        formatter: "currency",
                      },
                    ],
                  },
                ],
              },

              {
                type: "LabelValue",
                elements: [
                  {
                    type: "label",
                    elements: [
                      {
                        type: "text",
                        default: "Previsão de pagamento:",
                      },
                    ],
                  },
                  {
                    type: "div",
                    props: {},
                    elements: [
                      {
                        type: "text",
                        name: "payment_date",
                      },
                    ],
                  },
                ],
              },

              {
                type: "div",
                conditional: {
                  name: "payment_pix_key",
                },
                props: {
                  className: "label-value",
                },
                elements: [
                  {
                    type: "label",
                    elements: [
                      {
                        type: "text",
                        default: "Chave PIX para pagamento:",
                      },
                    ],
                  },
                  {
                    type: "BoxQRCodePix",
                    name: "payment_pix_key",
                    props: {},
                  },
                ],
              },
            ],
          },
          {
            type: "Col",
            props: {
              xs: 24,
              sm: 24,
              md: 12,
              lg: 12,
            },
            elements: [
              {
                type: "LabelValue",
                elements: [
                  {
                    type: "label",
                    elements: [
                      {
                        type: "text",
                        default: "Forma de pagamento:",
                      },
                    ],
                  },
                  {
                    type: "div",
                    props: {},
                    elements: [
                      {
                        type: "choice",
                        name: "payment_method",
                        default: "-",
                        choices: [
                          [0, "À vista"],
                          [1, "Ato / Entrega"],
                          [2, "Parcelado"],
                          [3, "Recorrente"],
                        ],
                      },
                    ],
                  },
                ],
              },

              // op == 0
              {
                type: "LabelValue",
                conditional: {
                  name: "payment_method",
                  equal: 0,
                },
                elements: [
                  {
                    type: "label",
                    elements: [
                      {
                        type: "text",
                        default: "Tipo de pagamento:",
                      },
                    ],
                  },
                  {
                    type: "div",
                    props: {},
                    elements: [
                      {
                        type: "text",
                        name: "payment_type",
                      },
                    ],
                  },
                ],
              },

              // op == 1
              {
                type: "LabelValue",
                conditional: {
                  name: "payment_method",
                  equal: 1,
                },
                elements: [
                  {
                    type: "label",
                    elements: [
                      {
                        type: "text",
                        default: "Detalhar porcentagem de pagamento:",
                      },
                    ],
                  },
                  {
                    type: "div",
                    props: {},
                    elements: [
                      {
                        type: "text",
                        name: "payment_percent",
                      },
                    ],
                  },
                ],
              },

              // op == 2
              {
                type: "LabelValue",
                conditional: {
                  name: "payment_method",
                  equal: 2,
                },
                elements: [
                  {
                    type: "label",
                    elements: [
                      {
                        type: "text",
                        default: "Quantidade de parcelas:",
                      },
                    ],
                  },
                  {
                    type: "div",
                    props: {},
                    elements: [
                      {
                        type: "text",
                        name: "payment_parcels",
                      },
                    ],
                  },
                ],
              },

              // op == 3
              {
                type: "LabelValue",
                conditional: {
                  name: "payment_method",
                  equal: 3,
                },
                elements: [
                  {
                    type: "label",
                    elements: [
                      {
                        type: "text",
                        default: "Data de início:",
                      },
                    ],
                  },
                  {
                    type: "div",
                    props: {},
                    elements: [
                      {
                        type: "text",
                        name: "starts_at",
                      },
                    ],
                  },
                ],
              },

              {
                type: "LabelValue",
                conditional: {
                  name: "payment_method",
                  equal: 3,
                },
                elements: [
                  {
                    type: "label",
                    elements: [
                      {
                        type: "text",
                        default: "Data de termino:",
                      },
                    ],
                  },
                  {
                    type: "div",
                    props: {},
                    elements: [
                      {
                        type: "text",
                        name: "ends_at",
                      },
                    ],
                  },
                ],
              },

              {
                type: "div",
                props: {
                  className: "label-value",
                },
                elements: [
                  {
                    type: "label",
                    elements: [
                      {
                        type: "text",
                        default: "Observações gerais:",
                      },
                    ],
                  },
                  {
                    type: "div",
                    props: {},
                    elements: [
                      {
                        type: "TextArea",
                        name: "general_observations",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
]
