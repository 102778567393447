export const validateRecaptcha = () => {
  return new Promise((resolve, reject) => {
    resolve("")
    // NOTE: Temporariamente desativado recaptcha devido a
    // problemas em navegadores com dispositivos IOS
    // window.grecaptcha.ready(async () => {
    //   try {
    //     const token = await window.grecaptcha.execute(
    //       process.env.REACT_APP_RECAPTCHA_SITE_KEY,
    //       { action: "submit" }
    //     )
    //     resolve(token)
    //   } catch (err) {
    //     reject(err)
    //   }
    // })
  })
}
