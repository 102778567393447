import React from "react"

export const AlertIcon = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
      >
        <title>alert</title>
        <path d="M12.549 3.329c1.103-1.911 3.543-2.563 5.449-1.457 0.603 0.35 1.104 0.852 1.453 1.457v0l12.012 20.803c1.103 1.911 0.453 4.357-1.453 5.463-0.607 0.352-1.296 0.538-1.998 0.538v0h-24.024c-2.202 0-3.987-1.79-3.987-3.998 0-0.703 0.185-1.394 0.537-2.003v0zM17.198 3.256c-1.144-0.664-2.607-0.272-3.269 0.874v0l-12.012 20.803c-0.211 0.365-0.322 0.78-0.322 1.202 0 1.325 1.071 2.399 2.393 2.399v0h24.024c0.421 0 0.834-0.111 1.199-0.323 1.144-0.664 1.534-2.131 0.872-3.278v0l-12.012-20.803c-0.209-0.363-0.51-0.664-0.872-0.874zM16 22.037c0.881 0 1.595 0.716 1.595 1.599s-0.714 1.599-1.595 1.599c-0.881 0-1.595-0.716-1.595-1.599s0.714-1.599 1.595-1.599zM16.002 8.444c0.393 0 0.719 0.248 0.787 0.574l0.013 0.126v9.795c0 0.386-0.358 0.7-0.8 0.7-0.393 0-0.719-0.247-0.787-0.574l-0.013-0.126v-9.795c0-0.386 0.358-0.7 0.8-0.7z"></path>
      </svg>
    </span>
  )
}
