import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { makeProviderLogin, makeLogin } from "./LoginService"
//
import StateStatus from "../../utils/stateStatus"

export const login = createAsyncThunk("login/login", async (data) => {
  const response = await makeLogin({
    email: data.email,
    password: data.password,
    recaptcha_token: data.recaptcha_token,
  })
  localStorage.setItem("access_token", response.data.data.access_token)
  localStorage.setItem("refresh_token", response.data.data.refresh_token)

  return response.data.data
})

export const loginProvider = createAsyncThunk(
  "login/loginProvider",
  async ({ accessToken, provider, code, email }) => {
    const response = await makeProviderLogin(accessToken, provider, code)
    localStorage.setItem("access_token", response.data.data.access_token)
    localStorage.setItem("refresh_token", response.data.data.refresh_token)
  }
)

export const logout = createAsyncThunk("login/logout", async () => {
  localStorage.removeItem("access_token")
  localStorage.removeItem("refresh_token")
  return true
})

export const isAuthenticated = () => {
  const accessToken = localStorage.getItem("access_token")
  return accessToken !== null
}

const initialState = {
  errMessage: null,
  notRegistered: null,
  status: {
    login: StateStatus.idle,
    logout: StateStatus.idle,
  },
  provider: null,
}

export const loginReducer = createSlice({
  name: "login",
  initialState,
  reducers: {
    resetLoginState: (state, action) => {
      state.status.login = StateStatus.idle
    },
  },
  extraReducers: {
    [login.pending]: (state) => {
      state.provider = null
      state.status.login = StateStatus.loading
    },
    [login.fulfilled]: (state, action) => {
      const profile = action.payload.profile

      if (
        profile &&
        !["approved", "processing_payment"].includes(profile.status)
      ) {
        state.status.login = StateStatus.idle
        window.location = "/#/register"
      } else {
        state.status.login = StateStatus.succeeded
      }
    },
    [login.rejected]: (state, action) => {
      state.status.login = StateStatus.failed
      state.errMessage = action.error.message

      if (action.error.code === "INVALID_CREDENTIALS") {
        state.errMessage = "Email ou senha inválidos."
      }
    },
    [loginProvider.pending]: (state, action) => {
      state.provider = {
        accessToken: action.meta.arg.accessToken,
        provider: action.meta.arg.provider,
        email: action.meta.arg.email,
      }
      state.status.login = StateStatus.loading
    },
    [loginProvider.fulfilled]: (state) => {
      state.status.login = StateStatus.succeeded
    },
    [loginProvider.rejected]: (state, action) => {
      state.errMessage = action.error.message
      state.notRegistered = action.error.code === "USER_NOT_FOUND"
      state.status.login = StateStatus.failed
    },
    [logout.fulfilled]: (state, action) => {
      state.status.logout = StateStatus.succeeded
      state.status.login = StateStatus.idle
    },
  },
})

export const { addGoogleAccessToken, resetLoginState } = loginReducer.actions

export const loginSelector = (state) => state.login
