import React from "react"
import { Routes } from "./routes"
import { ConfigProvider } from "antd-country-phone-input"
import en from "world_countries_lists/data/countries/en/world.json"

function App() {
  return (
    <ConfigProvider locale={en}>
      <div className="App">
        <Routes></Routes>
      </div>
    </ConfigProvider>
  )
}

export default App
