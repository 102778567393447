import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { editProfile, selectProfileData } from "../../../../configurationsSlice"
import { Col, Form, Row } from "antd"
import BaseModal from "../../../../../../common/components/Modal"
import { FavoriteIcon } from "../../../../../../common/components/FavoriteIcon"
import RadioButton from "../../../../../../common/components/RadioButton"
import PropTypes from "prop-types"
import Icons from "../../../../../../common/components/Icons"
import "./styles.scss"
import FlattedInput from "../../../../../../common/components/FlattedInput"

const EditSocialMediasModal = ({ isVisible, onSave, onCancel }) => {
  const dispatch = useDispatch()
  const profileData = useSelector(selectProfileData)
  const [form] = Form.useForm()
  const [socialMedias, updateSocialMedias] = useState([])
  const [urlSocialMedia, updateUrlSocialMedia] = useState("")
  const [favoriteSM, updateFavoriteSM] = useState(null)

  useEffect(() => {
    if (profileData && profileData.social_medias) {
      updateSocialMedias(profileData.social_medias)
      profileData.social_medias.forEach((item, index) => {
        if (item.is_favorite) updateFavoriteSM(index)
      })
    }
  }, [profileData.social_medias])

  useEffect(() => {
    if (favoriteSM != null && favoriteSM >= socialMedias.length) {
      updateFavoriteSM(null)
    }
  }, [socialMedias])

  const validSocialMedia = (value) => {
    value = value.replace(/^https?:\/\//, "")

    let plataform
    let username

    if (value.match(/instagram.com\/.+/)) {
      plataform = { id: "instagram", name: "Instagram" }
      username = value.split("/")[1]
    } else if (value.match(/twitter\.com\/.+/)) {
      plataform = { id: "twitter", name: "Twitter" }
      username = value.split("/")[1]
    } else if (value.match(/facebook\.com\/.+/)) {
      plataform = { id: "facebook", name: "Facebook" }
      username = value.split("/")[1]
    } else if (value.match(/pinterest.com\/.+/)) {
      plataform = { id: "pinterest", name: "Pinterest" }
      username = value.split("/")[1]
    } else if (value.match(/youtube\.com\/c\/.+/)) {
      plataform = { id: "youtube", name: "YouTube" }
      username = value.split("/c/")[1]
    } else if (value.match(/tiktok\.com\/@.+/)) {
      plataform = { id: "tiktok", name: "TikTok" }
      username = value.split("/@")[1]
    } else if (value.match(/linkedin\.com\/in\/.+/)) {
      plataform = { id: "linkedin", name: "Linkedin" }
      username = value.split("/in/")[1]
    } else if (value.match(/twitch\.tv\/.+/)) {
      plataform = { id: "twitch", name: "Twitch" }
      username = value.split("/")[1]
    } else return null

    username = username.split(/[/?]/)[0].trim()

    return { username: `@${username}`, plataform }
  }

  const saveSocialMedia = () => {
    let smedias = [...socialMedias]
    if (urlSocialMedia.length > 0) {
      const smedia = validSocialMedia(urlSocialMedia)
      if (smedia === null) return

      const exists = smedias.filter(
        (x) =>
          x.username === smedia.username &&
          x.plataform.id === smedia.plataform.id
      )[0]

      if (!exists) {
        smedias = [...smedias, smedia]
        updateSocialMedias(smedias)
      }

      updateUrlSocialMedia("")
      form.resetFields()
    }
    return smedias
  }

  return (
    <BaseModal
      className="edit-social-medias"
      title={
        (profileData.social_medias && profileData.social_medias.length > 0
          ? "Adicionar"
          : "Alterar") + " redes sociais"
      }
      isVisible={isVisible}
      okButtonText="Salvar"
      cancelButtonText="Cancelar"
      onOk={() => {
        saveSocialMedia()
        form.submit()
      }}
      onCancel={() => {
        updateUrlSocialMedia("")
        form.resetFields()
        onCancel()
      }}
      width={500}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={(values) => {
          let smedias = saveSocialMedia()

          smedias = smedias.map((item, index) => ({
            username: item.username,
            plataform_id: item.plataform.id,
            is_favorite: favoriteSM === index,
          }))

          dispatch(editProfile({ social_medias: smedias }))
          onSave()
        }}
      >
        {socialMedias.map((item, index) => (
          <Row key={index}>
            <Col span={22} className="social-media">
              <Form.Item label={item.plataform.name}>
                <FlattedInput
                  value={item.username}
                  style={{ width: "100%" }}
                  readOnly
                  onChange={(event) => {
                    updateUrlSocialMedia(event.target.value)
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Icons
                className="delete-icon"
                icon="delete"
                onClick={() => {
                  const list = [...socialMedias]
                  list.splice(index, 1)
                  updateSocialMedias(list)
                }}
              />
            </Col>
            <Col span={24}>
              <div className="favorite-item">
                <RadioButton
                  checked={favoriteSM === index}
                  value={index}
                  onClick={() => {
                    const isFavorite = favoriteSM === index
                    updateFavoriteSM(isFavorite ? null : index)
                  }}
                >
                  Favoritar
                </RadioButton>
                <FavoriteIcon />
              </div>
            </Col>
          </Row>
        ))}
        <Row>
          <Col span={22}>
            <Form.Item
              initialValue=""
              name="social-media"
              rules={[
                () => ({
                  validator(_, value) {
                    if (value.length === 0) {
                      return Promise.resolve()
                    } else if (validSocialMedia(value)) {
                      return Promise.resolve()
                    } else {
                      return Promise.reject(new Error())
                    }
                  },
                  message: <div className="normal-12">Link inválido</div>,
                }),
              ]}
            >
              <FlattedInput
                placeholder="Ex.: https://instagram.com/airfluencers"
                style={{ width: "100%" }}
                onChange={(event) => {
                  updateUrlSocialMedia(event.target.value)
                }}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Icons
              className="plus-icon"
              icon="plus"
              style={{ fontSize: "1px" }} // fix css bug by Radio.Group
              onClick={() => saveSocialMedia()}
            />
          </Col>
          <Col span={24}>
            {urlSocialMedia.length > 0 && (
              <div className="favorite-item">
                <RadioButton
                  checked={favoriteSM === socialMedias.length}
                  value={socialMedias.length}
                  onClick={() => {
                    const isFavorite = favoriteSM === socialMedias.length
                    updateFavoriteSM(isFavorite ? null : socialMedias.length)
                  }}
                >
                  Favoritar
                </RadioButton>
                <FavoriteIcon />
              </div>
            )}
          </Col>
        </Row>
      </Form>
    </BaseModal>
  )
}

EditSocialMediasModal.propTypes = {
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  isVisible: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.any,
  text: PropTypes.string,
}

export default EditSocialMediasModal
