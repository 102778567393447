import { Row, Col, Form, message } from "antd"
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import BaseModal from "../../common/components/Modal"
import StateStatus from "../../utils/stateStatus"
import vote0 from "../../common/assets/votes_satisfaction/vote-0.svg"
import vote1 from "../../common/assets/votes_satisfaction/vote-1.svg"
import vote2 from "../../common/assets/votes_satisfaction/vote-2.svg"
import vote3 from "../../common/assets/votes_satisfaction/vote-3.svg"
import vote4 from "../../common/assets/votes_satisfaction/vote-4.svg"
import { FlattedTextArea } from "../../common/components/FlattedInput"
import { useDispatch, useSelector } from "react-redux"
import {
  existsVoteSatisfactionThunk,
  resetShowQuestion,
  resetStatus,
  selectVoteSatisfactionState,
  sendVoteSatisfactionThunk,
} from "./slice"

import "./styles.scss"
import ErrorMessage from "../../common/components/ErrorMessage"

const VotesSatisfactionCard = ({ question, form, onFinish }) => {
  const [vote, setVote] = useState()
  const [voteError, setVoteError] = useState(false)

  const votes = [vote0, vote1, vote2, vote3, vote4]

  const selectVote = (index) => {
    setVoteError(false)
    setVote(index)
  }

  return (
    <Row justify="center" className="vote-satisfaction-card">
      <Form
        form={form}
        onFinish={(values) => {
          if (!vote && vote !== 0) {
            setVoteError(true)
          } else {
            onFinish({ vote, comments: values.comments })
          }
        }}
        layout="vertical"
      >
        <Row justify="space-between" align="middle" className="box-votes">
          {votes.map((img, index) => {
            return (
              <Col key={index}>
                <div
                  className="image-vote-box"
                  data-is-active={index === vote}
                  onClick={() => selectVote(index)}
                >
                  <img src={img} onClick={() => selectVote(index)} />
                </div>
              </Col>
            )
          })}
        </Row>
        <ErrorMessage
          isVisible={voteError}
          text="Selecione seu voto de satisfação acima"
        />
        <Row>
          <Col span={24}>
            <Form.Item name="comments" label="Quer deixar algum comentário?">
              <FlattedTextArea placeholder="Deixe um comentário..." />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Row>
  )
}

const VotesSatisfactionModal = ({ question, onCancel, onClose }) => {
  const [form] = Form.useForm()
  const dispacth = useDispatch()
  const state = useSelector(selectVoteSatisfactionState)

  useEffect(() => {
    dispacth(existsVoteSatisfactionThunk({ question }))
  }, [])

  useEffect(() => {
    if (state.status.existsVoteSatisfaction === StateStatus.succeeded) {
      if (!state.show_question) {
        onClose && onClose()
      }
      dispacth(resetStatus())
    }
  }, [state.status.existsVoteSatisfaction])

  useEffect(() => {
    if (state.status.sendVoteSatisfaction === StateStatus.succeeded) {
      onClose && onClose()
      dispacth(resetStatus())
      dispacth(resetShowQuestion())
    }
  }, [state.status.sendVoteSatisfaction])

  const onFinish = (values) => {
    dispacth(
      sendVoteSatisfactionThunk({
        question,
        vote: values.vote,
        comments: values.comments,
      })
    ).then(({ payload }) => {
      if (!payload) return

      onClose && onClose()
      dispacth(resetStatus())
      dispacth(resetShowQuestion())
      message.success("Obrigado por responder nossa pesquisa!")
    })
  }

  return (
    <BaseModal
      isVisible={Boolean(state.show_question)}
      type="general"
      width={397}
      className="votes-satisfaction-modal"
      title={question}
      okButtonText="Enviar"
      cancelButtonText="Agora não"
      isClosable={false}
      onOk={() => form.submit()}
      onCancel={() => {
        dispacth(resetStatus())
        dispacth(resetShowQuestion())
        onCancel && onCancel()
      }}
      isLoading={state.status.sendVoteSatisfaction === StateStatus.loading}
    >
      <VotesSatisfactionCard
        question={question}
        form={form}
        onFinish={onFinish}
      />
    </BaseModal>
  )
}

VotesSatisfactionModal.propTypes = {
  question: PropTypes.string,
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
}

VotesSatisfactionCard.propTypes = {
  question: PropTypes.string,
  onFinish: PropTypes.func,
  form: PropTypes.object,
}

export { VotesSatisfactionModal }
