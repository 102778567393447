import React from "react"

export const ShowIcon = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <defs>
          <rect
            id="propozall-show-icon-path"
            x="0"
            y="0"
            width="24"
            height="24"
          ></rect>
        </defs>
        <g
          id="propozall-show-icon"
          stroke="none"
          strokeWidth="1"
          fillRule="evenodd"
        >
          <g
            id="propozall-show-icon-1"
            transform="translate(-1124.000000, -331.000000)"
          >
            <g
              id="propozall-show-icon-2"
              transform="translate(128.000000, 315.000000)"
            >
              <g
                transform="translate(24.000000, 13.000000)"
                id="propozall-show-icon-5"
              >
                <g transform="translate(972.000000, 0.000000)">
                  <g
                    id="propozall-show-icon-3"
                    transform="translate(0.000000, 3.000000)"
                  >
                    <g>
                      <g id="propozall-show-icon-4"></g>
                      <path
                        fill="#000000"
                        d="M9,12 C9,13.642 10.358,15 12,15 C13.641,15 15,13.642 15,12 C15,10.359 13.641,9 12,9 C10.358,9 9,10.359 9,12 Z M2.10543073,11.684 L2,12 L2.10443617,12.316 C2.12631801,12.383 4.40799665,19 12,19 C19.5920014,19 21.8736803,12.383 21.8945675,12.316 L22,12 L21.8955631,11.684 C21.8736813,11.617 19.5920014,5 12,5 C4.40799665,5 2.12631794,11.617 2.10543073,11.684 Z M4.11657,12 C4.617863,10.842 6.68072367,7 12,7 C17.322258,7 19.3841249,10.846 19.8834286,12 C19.3821356,13.158 17.3192744,17 12,17 C6.6777401,17 4.61587369,13.154 4.11657,12 Z"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </span>
  )
}
