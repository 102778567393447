import React from "react"
import { Row } from "antd"
import PropTypes from "prop-types"

const CollapseRowContent = ({
  id,
  isActive,
  className,
  children,
  ...props
}) => {
  return (
    <Row
      id={id}
      data-is-active={isActive}
      data-allow-animation={
        localStorage.getItem("data-allow-animation-content") === id
      }
      gutter={[16, 16]}
      className={`row-content ${className ?? ""}`}
      {...props}
    >
      {children}
    </Row>
  )
}

CollapseRowContent.propTypes = {
  id: PropTypes.string,
  isActive: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.any,
}

export default CollapseRowContent
