import axios from "axios"
import jwtDecode from "jwt-decode"
import apiRoutes, { authorized } from "./apiRoutes"
const apiUrl = `${process.env.REACT_APP_BASEURL}`

const api = axios.create({
  baseURL: apiUrl,
  headers: {
    "x-app": "web",
    "Content-Type": "application/json",
    Accept: "application/json",
  },
})

const requestRefreshToken = async () => {
  const refreshToken = localStorage.getItem("refresh_token_counterparty")
  const response = await axios.get(`${apiUrl}${apiRoutes.refreshToken}`, {
    headers: {
      "x-app": "web",
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${refreshToken}`,
    },
  })
  localStorage.setItem(
    "access_token_counterparty",
    response.data.data.access_token
  )
  localStorage.setItem(
    "refresh_token_counterparty",
    response.data.data.refresh_token
  )

  return response
}

const configureNewAccessToken = async (config) => {
  const refreshToken = localStorage.getItem("refresh_token_counterparty")
  if (refreshToken) {
    try {
      const response = await requestRefreshToken()
      config.headers.Authorization = `Bearer ${response.data.data.access_token}`
    } catch (err) {
      // window.location = "/"
    }
  } else {
    // window.location = "/"
  }
}

const requireAuthToken = (config) => {
  const endpoint = authorized.filter((item) => item.url === config.url)[0]
  if (!endpoint) return true

  return !endpoint.methods.includes(config.method)
}

api.interceptors.request.use(
  async (config) => {
    if (!requireAuthToken(config)) return config

    const accessToken = localStorage.getItem("access_token_counterparty")
    if (accessToken) {
      const tokenData = jwtDecode(accessToken)
      const currentDate = new Date()
      if (tokenData.exp * 1000 <= currentDate.getTime()) {
        await configureNewAccessToken(config)
      } else {
        config.headers.Authorization = `Bearer ${accessToken}`
      }
    } else {
      await configureNewAccessToken(config)
    }
    return config
  },
  (error) => {
    return Promise.error(error)
  }
)

api.interceptors.response.use(undefined, (err) => {
  let message = ""

  if (!err.response) {
    message = "Não foi possível conectar ao servidor, tente mais tarde!"
  } else if (err.response.status >= 500) {
    message = "Erro no sistema, tente mais tarde!"
  } else {
    message = err.response.data.message || "Erro no sistema, tente mais tarde!"
  }

  const customError = {
    name: "Api Error",
    message,
    code: err.response ? err.response.data.error : null,
    validations: err.response ? err.response.data.validations : [],
    data: err.response ? err.response.data : null,
  }

  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
  return Promise.reject(customError)
})

export default api
