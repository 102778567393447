import React from "react"

export const ContractStatusUnsignedIcon = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        width="30"
        height="30"
        viewBox="0 0 49 49"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25.5 10.7H16.1667C15.4594 10.7 14.7811 10.9809 14.281 11.481C13.781 11.9811 13.5 12.6594 13.5 13.3666V34.6999C13.5 35.4072 13.781 36.0855 14.281 36.5856C14.7811 37.0857 15.4594 37.3666 16.1667 37.3666H32.1667C32.8739 37.3666 33.5522 37.0857 34.0523 36.5856C34.5524 36.0855 34.8333 35.4072 34.8333 34.6999V20.0333V13.7C34.8333 12.0431 33.4902 10.7 31.8333 10.7H25.5Z"
          stroke="#141414"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.5 22.0332L27 23.5332L29.5 21.0332"
          stroke="black"
          strokeWidth="1.7"
          strokeLinecap="round"
        />
        <path
          d="M17 23.0332H23"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M17.5 30.0332H23.5729"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.7006 28.2785C26.3666 27.9486 25.8285 27.9519 25.4985 28.2858C25.1686 28.6197 25.1718 29.1579 25.5058 29.4879L26.412 30.3832L25.5058 31.2785C25.1718 31.6085 25.1686 32.1467 25.4985 32.4806C25.8285 32.8145 26.3666 32.8178 26.7006 32.4879L27.6214 31.5781L28.5422 32.4879C28.8762 32.8178 29.4143 32.8145 29.7443 32.4806C30.0742 32.1467 30.071 31.6085 29.737 31.2785L28.8308 30.3832L29.737 29.4879C30.071 29.1579 30.0742 28.6197 29.7443 28.2858C29.4143 27.9519 28.8762 27.9486 28.5422 28.2785L27.6214 29.1883L26.7006 28.2785Z"
          fill="black"
        />
      </svg>
    </span>
  )
}
