import React from "react"

export const ProposalServiceProviderIcon = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Frame">
          <path
            id="Vector"
            d="M4.5 12C4.5 13.9891 5.29018 15.8967 6.6967 17.3033C8.10322 18.7098 10.0109 19.5 12 19.5C13.9891 19.5 15.8968 18.7098 17.3033 17.3033C18.7098 15.8967 19.5 13.9891 19.5 12M4.5 12C4.5 10.0108 5.29018 8.10318 6.6967 6.69666C8.10322 5.29014 10.0109 4.49996 12 4.49996C13.9891 4.49996 15.8968 5.29014 17.3033 6.69666C18.7098 8.10318 19.5 10.0108 19.5 12M4.5 12H3M19.5 12H21M19.5 12H12L7.5 4.20496M3.543 15.077L4.953 14.564M19.048 9.43396L20.458 8.92096M5.106 17.785L6.256 16.821M17.746 7.17896L18.895 6.21496M7.501 19.795L8.251 18.495L12.002 12M15.751 5.50496L16.501 4.20496M10.438 20.863L10.698 19.386M13.303 4.61396L13.563 3.13696M13.563 20.863L13.303 19.386M10.698 4.61396L10.438 3.13696M16.5 19.794L15.75 18.495M18.894 17.785L17.745 16.821M6.256 7.17796L5.106 6.21396M20.458 15.078L19.048 14.565M4.954 9.43496L3.544 8.92096"
            stroke="#0038D1"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </span>
  )
}
