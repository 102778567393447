import { Form } from "antd"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, Link } from "react-router-dom"
import { BlueButton } from "../../../common/components/Button"
import BaseModal from "../../../common/components/Modal"
import RoundedInput from "../../../common/components/RoundedInput"
import StateStatus from "../../../utils/stateStatus"
import {
  forgotPassword,
  resetPasswState,
  selectPasswordState,
} from "../passwordSlice"
import logoImg from "../../../common/assets/logo/propozall.svg"
import { MailSentIcon } from "../../../common/components/MailIcon"
import { validateRecaptcha } from "../../../service/recaptcha"

export const ForgotPassword = () => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const history = useHistory()
  const password = useSelector(selectPasswordState)

  useEffect(() => {
    if (password.status.forgotPassword === StateStatus.failed) {
      form.setFields([
        {
          name: "email",
          errors: ["Revise o email fornecido"],
        },
      ])
    }
  }, [password.status])

  return (
    <div
      style={{
        height: "90%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Link to={"/"}>
          <img
            src={logoImg}
            alt="Logo Propozall"
            className="logo"
            width="300"
          />
        </Link>
        <div style={{ margin: "25px 0" }}>
          <div
            style={{ margin: "10px 0" }}
            className="normal-18-bold centralize-text"
          >
            Esqueceu sua senha?
          </div>
          <div className="normal-14 centralize-text">
            Insira o e-mail cadastrado abaixo para
            <br />
            poder apagar a sua senha e receber as
            <br />
            instruções para uma nova
          </div>
        </div>
      </div>
      <Form
        form={form}
        layout="vertical"
        onFinish={(values) => {
          validateRecaptcha().then((token) => {
            values.recaptcha_token = token
            dispatch(forgotPassword(values))
          })
        }}
      >
        <Form.Item
          name="email"
          label="Email"
          initialValue=""
          rules={[
            {
              required: true,
              message: <div className="normal-12">Insira um email</div>,
            },
            {
              type: "email",
              message: <div className="normal-12">Email inválido</div>,
            },
          ]}
        >
          <RoundedInput
            placeholder="Email"
            type="email"
            style={{ width: "314px" }}
          ></RoundedInput>
        </Form.Item>
        <BlueButton
          // className="g-recaptcha"
          // data-sitekey="6LeshMQgAAAAAFP6iwNrvDQRt9L4TnbGhVXCH2bE"
          // data-callback='onSubmit'
          // data-action='submit'
          isLoading={password.status.forgotPassword === StateStatus.loading}
          style={{ width: "314px", margin: "35px 0" }}
          type="submit"
        >
          Solicitar
        </BlueButton>
      </Form>
      <BaseModal
        isVisible={password.status.forgotPassword === StateStatus.succeeded}
        onOk={() => {
          dispatch(resetPasswState())
          history.push("/")
        }}
        onCancel={() => {
          dispatch(resetPasswState())
          history.push("/")
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div style={{ marginBottom: "20px" }}>
            <MailSentIcon />
          </div>
          <div className="normal-16-500">E-mail enviado!</div>
          <br />
          <div className="normal-14 centralize-text">
            Por favor, verifique sua caixa de e-mail e clique no link enviado
            para apagar a senha e criar uma nova.
          </div>
        </div>
      </BaseModal>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <hr></hr>
        <div>
          <span className="font-14">Você não possui uma conta? </span>
          <a href="https://propozall.com/planos/">
            <span className="link-14">Criar uma conta</span>
          </a>
        </div>
      </div>
    </div>
  )
}
