import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
//
import { useDispatch, useSelector } from "react-redux"
import { selectDashSlice } from "../../../dashboard/DashboardSlice"
import { selectProposalState } from "../../../proposal/proposalSlice"
import {
  listAddress,
  selectAddresses,
  selectProfileData,
} from "../../../configurations/configurationsSlice"
import StateStatus from "../../../../utils/stateStatus"
//
import { Col, Row } from "antd"
import { BlueButton, WhiteButton } from "../../../../common/components/Button"
import TagStatus from "../../../../common/components/TagStatus"
import ProposalResume from "../ProposalResume"
//
import "./styles.scss"
import Icons from "../../../../common/components/Icons"
import { useHistory } from "react-router-dom"

const ProposalCreateResume = ({
  elements,
  values,
  onClickSend,
  onClickDraft,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  //
  const addressesData = useSelector(selectAddresses)
  const dashState = useSelector(selectDashSlice)
  const profileData = useSelector(selectProfileData)
  const proposalState = useSelector(selectProposalState)
  const [activeKeys, setActiveKeys] = useState([])

  //
  const role = dashState.mode?.toUpperCase()
  //
  const [address, updateAddress] = useState({})
  const [data, updateData] = useState(values)

  const isEditingSentProposal =
    proposalState.data.proposal.id &&
    proposalState.data.proposal.status !== "draft"

  const hasPlanLimitReached =
    profileData.proposals_limit &&
    profileData.count_active_proposals >= profileData.proposals_limit

  const hasEmailCounterparty = !!values.contact?.email

  const canSaveDraft =
    !proposalState.data.proposal.id ||
    proposalState.data.proposal.status === "draft"

  useEffect(() => {
    if (!addressesData[0]) dispatch(listAddress())
  }, [])

  useEffect(() => {
    if (addressesData[0]) {
      updateAddress(addressesData[0])
    }
  }, [addressesData])

  useEffect(() => {
    if (address.city || values)
      if (role === "CONTRACTOR") {
        updateData({
          ...values,
          files: proposalState.data.aux.files,
          contracted: values.contact ? values.contact : null,
          contractor: {
            cpf_cnpj: proposalState.data.aux.cpf_cnpj,
            company_name: proposalState.data.aux.company_name,
            // bank_account: proposalState.data.aux.bank_account,
            name: profileData.name,
            email: profileData.email,
            avatar_url: profileData.avatar_url,
            social_medias: profileData.social_medias,
            address,
          },
        })
      } else {
        updateData({
          ...values,
          files: proposalState.data.aux.files,
          contractor: values.contact ? values.contact : null,
          contracted: {
            cpf_cnpj: proposalState.data.aux.cpf_cnpj,
            company_name: proposalState.data.aux.company_name,
            bank_account: proposalState.data.aux.bank_account,
            name: profileData.name,
            email: profileData.email,
            avatar_url: profileData.avatar_url,
            social_medias: profileData.social_medias,
            address,
          },
        })
      }

    setTimeout(openAllCollapseRow, 500)
  }, [address, values, proposalState.data])

  const openColappse = (keys) => {
    setActiveKeys([...keys, ...activeKeys])
  }

  const closeColappse = (keys) => {
    setActiveKeys(activeKeys.filter((x) => !keys.includes(x)))
  }

  const openAllCollapseRow = () => {
    if (activeKeys?.length === 0) {
      const elemsTitle = Array.from(
        document.querySelectorAll(
          ".proposal-resume > .row-title:not(.row-proposal-title)"
        )
      )
      const elemContent = Array.from(
        document.querySelectorAll(
          ".proposal-resume > .row-content:not(.proposal-profile)"
        )
      )

      const keys = elemContent?.map((elem) => elem.id)

      const lastTitle = elemsTitle.slice(-1)[0]
      const lastContent = elemContent.slice(-1)[0]

      if (lastTitle) {
        lastTitle.setAttribute("data-is-last", "true")
      }

      if (lastContent) {
        lastContent.setAttribute("data-is-last", "true")
      }

      openColappse(keys)
    }
  }

  return (
    <>
      <div className="proposal-create-resume">
        <div className="main-content-top"></div>
        <div className="main-content">
          <Row
            justify="space-between"
            gutter={[16, 0]}
            className="row-title row-proposal-title"
          >
            <Col xs={24} sm={24} md={10} lg={10} className="normal-24">
              <span className="proposal-title not-overflow">
                {values.title}
              </span>
            </Col>

            {values.status_name && values.status ? (
              <Col
                xs={24}
                sm={24}
                md={6}
                lg={6}
                className="normal-16 proposal-status"
              >
                <Icons
                  icon={`proposal-${values.status}`}
                  className="status-icon"
                />
                <TagStatus status={values.status}>
                  {values.status_name}
                </TagStatus>
              </Col>
            ) : null}

            {/* <Col xs={24} sm={24} md={8} lg={8} className="normal-16 created-at">
            <span>
            Data proposta:{" "}
            </span>
            <span>
            {proposal.created_at ? proposal.created_at.split(" ")[0] : "-"}
            {onShare && (
              <Icons
              icon="share"
              title="Compartilhar Proposta"
              onClick={onShare}
              />
              )}
              </span>
            </Col> */}
          </Row>

          <ProposalResume
            values={data}
            id={proposalState.data?.proposal?.id}
            elements={elements}
            activeKeys={activeKeys}
            openColappse={openColappse}
            closeColappse={closeColappse}
          />
        </div>

        <Row
          justify="space-between"
          gutter={[16, 16]}
          className="row-content-end"
          data-can-draft={canSaveDraft}
        >
          <Col>
            <WhiteButton onClick={() => history.goBack()}>Voltar</WhiteButton>
          </Col>
          <Col>
            <Row justify="end" gutter={[16, 16]}>
              {canSaveDraft ? (
                <Col>
                  <WhiteButton
                    onClick={onClickDraft}
                    isLoading={
                      proposalState.data.aux.is_draft !== undefined &&
                      proposalState.data.aux.is_draft &&
                      proposalState.status.saveProposal === StateStatus.loading
                    }
                  >
                    Salvar rascunho
                  </WhiteButton>
                </Col>
              ) : null}
              <Col>
                <BlueButton
                  onClick={onClickSend}
                  disabled={!isEditingSentProposal && hasPlanLimitReached}
                  isLoading={
                    proposalState.data.aux.is_draft !== undefined &&
                    !proposalState.data.aux.is_draft &&
                    proposalState.status.saveProposal === StateStatus.loading
                  }
                >
                  {hasEmailCounterparty
                    ? "Enviar proposta"
                    : "Salvar para enviar"}
                </BlueButton>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  )
}

ProposalCreateResume.propTypes = {
  values: PropTypes.object.isRequired,
  elements: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClickSend: PropTypes.func,
  onClickDraft: PropTypes.func,
}

export default ProposalCreateResume
