import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import StateStatus from "../../utils/stateStatus"
import {
  editProposal,
  addProposal,
  shareProposal,
  // proposalGet,
  getSegments,
  getMethods,
  fileUpload,
  schemaProposal,
} from "./proposalService"

export const getProposalStoredData = createAsyncThunk(
  "proposal/schema",
  async (proposalId) => {
    const response = await schemaProposal(proposalId)

    return response.data
  }
)

export const updateProposal = createAsyncThunk(
  "proposal/edit",
  async ({ proposalId, data }) => {
    try {
      const response = await editProposal(proposalId, data)
      return response.data
    } catch (err) {
      const customError = {
        name: err.name,
        message: err.validations.join("## "),
        code: err.code,
        // validations: err.validations,
      }
      throw customError
    }
  }
)

export const shareProposalThunk = createAsyncThunk(
  "proposal/share",
  async ({ proposalId, email }) => {
    const response = await shareProposal(proposalId, { email })
    return response.data
  }
)

export const createProposal = createAsyncThunk(
  "proposal/create",
  async (data) => {
    try {
      const response = await addProposal(data)
      return response.data
    } catch (err) {
      const customError = {
        name: err.name,
        message:
          err.code === "VALIDATION_ERROR"
            ? err.validations.join("## ")
            : err.message,
        code: err.code,
        // validations: err.validations,
      }
      throw customError
    }
  }
)

// export const getProposal = createAsyncThunk(
//   "proposal/get",
//   async (proposalId) => {
//     const response = await proposalGet(proposalId)
//     return response.data
//   }
// )

export const listSegments = createAsyncThunk(
  "proposal/list_segments",
  async () => {
    const response = await getSegments()
    return response.data
  }
)

export const listMethods = createAsyncThunk(
  "proposal/list_methods",
  async () => {
    const response = await getMethods()
    return response.data
  }
)

export const uploadFile = createAsyncThunk(
  "proposal/upload_file",
  async (file) => {
    const data = new FormData()
    data.append("file", file)
    const response = await fileUpload(data)
    return response.data
  }
)

const initialState = {
  status: {
    getProposal: StateStatus.idle,
    saveProposal: StateStatus.idle,
    listSegments: StateStatus.idle,
    listMethods: StateStatus.idle,
    shareProposal: StateStatus.idle,
  },
  step: 1,
  data: {
    proposal: {},
    segments: [],
    methods: [],
    aux: {
      cnpj: null,
      company_name: null,
      bank_account: null,
      files: [],
      meeting: null,
      is_draft: false,
    },
    isFirstProposal: null,
  },
  errMessage: null,
  validations: [],
}

export const proposalSlice = createSlice({
  name: "proposal",
  initialState,
  reducers: {
    updateProposalStep: (state, action) => {
      state.step = action.payload
    },
    resetErrorMessage: (state) => {
      state.errMessage = null
    },
    resetShareProposal: (state) => {
      state.status.shareProposal = StateStatus.idle
    },
    resetSaveProposal: (state) => {
      state.status.saveProposal = StateStatus.idle
    },
    updateAux: (state, action) => {
      // Usado para guardar dados da company da proposta a ser exibido
      // na pagina de resumo, pois quando se edita uma proposta nao
      // se tem o id e não há como fazer getCompany("-1")
      if (action.payload.cpf_cnpj !== undefined) {
        state.data.aux.cpf_cnpj = action.payload.cpf_cnpj
      }

      if (action.payload.owner !== undefined) {
        state.data.aux.owner = action.payload.owner
      }

      if (action.payload.company_name !== undefined) {
        state.data.aux.company_name = action.payload.company_name
      }

      if (action.payload.bank_account !== undefined) {
        state.data.aux.bank_account = action.payload.bank_account
      }

      if (action.payload.is_draft !== undefined) {
        state.data.aux.is_draft = action.payload.is_draft
      }

      if (action.payload.file !== undefined) {
        state.data.aux.files = [...state.data.aux.files, action.payload.file]
      }

      if (action.payload.files !== undefined) {
        state.data.aux.files = action.payload.files
      }

      if (action.payload.type !== undefined) {
        state.data.aux.type = action.payload.type
      }

      if (action.payload.meeting !== undefined) {
        if (action.payload.meeting === null) {
          state.data.aux.meeting = null
        } else {
          state.data.aux.meeting = {
            ...(state.data.aux.meeting || {}),
            ...action.payload.meeting,
          }
        }
      }
    },
    resetProposalState: (state) => {
      state.errMessage = null
      state.validations = []
      state.data.proposal = {}
      state.data.aux = initialState.data.aux
      state.status.saveProposal = StateStatus.idle
      state.status.getProposalStoredData = StateStatus.idle
    },
  },
  extraReducers: {
    [createProposal.pending]: (state, action) => {
      state.status.saveProposal = StateStatus.loading
    },
    [createProposal.fulfilled]: (state, action) => {
      state.status.saveProposal = StateStatus.succeeded
      state.data.proposal = action.payload.data
      state.data.isFirstProposal = action.payload.data.is_first_proposal
    },
    [createProposal.rejected]: (state, action) => {
      state.status.saveProposal = StateStatus.failed

      if (action.error.code === "VALIDATION_ERROR") {
        state.errMessage = "Erro ao validar alguns campos"
        state.validations = action.error.message.split("##")
      } else {
        state.errMessage = action.error.message
        state.validations = []
      }
    },
    [updateProposal.pending]: (state) => {
      state.status.saveProposal = StateStatus.loading
    },
    [updateProposal.fulfilled]: (state, action) => {
      state.status.saveProposal = StateStatus.succeeded
      state.data.proposal = action.payload.data
      state.data.isFirstProposal = action.payload.data.is_first_proposal
    },
    [updateProposal.rejected]: (state, action) => {
      state.status.saveProposal = StateStatus.failed
      if (action.error.code === "VALIDATION_ERROR") {
        state.errMessage = "Erro ao validar alguns campos"
        state.validations = action.error.message.split("##")
      } else {
        state.errMessage = action.error.message
        state.validations = []
      }
    },
    [shareProposalThunk.pending]: (state, action) => {
      state.status.shareProposal = StateStatus.loading
    },
    [shareProposalThunk.fulfilled]: (state, action) => {
      state.status.shareProposal = StateStatus.succeeded
    },
    [shareProposalThunk.rejected]: (state, action) => {
      state.status.shareProposal = StateStatus.failed
      state.errMessage = action.error.message
    },
    [listSegments.pending]: (state, action) => {
      state.status.listSegments = StateStatus.loading
    },
    [listSegments.fulfilled]: (state, action) => {
      state.data.segments = action.payload.data.items
      state.status.listSegments = StateStatus.succeeded
    },
    [listSegments.rejected]: (state, action) => {
      state.status.listSegments = StateStatus.failed
      state.errMessage = action.error.message
      state.validations = []
    },
    [listMethods.pending]: (state, action) => {
      state.status.listMethods = StateStatus.loading
    },
    [listMethods.fulfilled]: (state, action) => {
      state.data.methods = action.payload.data.items
      state.status.listMethods = StateStatus.succeeded
    },
    [listMethods.rejected]: (state, action) => {
      state.status.listMethods = StateStatus.failed
      state.errMessage = action.error.message
      state.validations = []
    },
    //
    [getProposalStoredData.pending]: (state) => {
      state.status.getProposalStoredData = StateStatus.loading
    },
    [getProposalStoredData.fulfilled]: (state, action) => {
      state.data.proposal = action.payload.data

      state.status.getProposalStoredData = StateStatus.succeeded
    },
    [getProposalStoredData.rejected]: (state, action) => {
      state.errMessage = action.error.message

      state.status.getProposalStoredData = StateStatus.failed
    },
  },
})

export const {
  updateProposalStep,
  resetErrorMessage,
  resetProposalState,
  resetSaveProposal,
  updateAux,
  resetShareProposal,
} = proposalSlice.actions

export const selectProposalState = (state) => state.proposal
