import React from "react"

export const PlusCopyIcon = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        width="24px"
        height="24px"
        viewBox="0 0 28 28"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <defs>
          <rect
            id="propozall-plus-copy-icon-path"
            x="0"
            y="0"
            width="28"
            height="28"
          ></rect>
        </defs>
        <g
          id="propozall-plus-copy-icon"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="propozall-plus-copy-icon-1"
            transform="translate(-570.000000, -328.000000)"
          >
            <g
              id="propozall-plus-copy-icon-2"
              transform="translate(556.000000, 312.000000)"
            >
              <g
                id="propozall-plus-copy-icon-3"
                transform="translate(4.000000, 6.000000)"
              >
                <g
                  id="propozall-plus-copy-icon-4"
                  transform="translate(10.000000, 10.000000)"
                >
                  <mask id="propozall-plus-copy-icon-mask" fill="white">
                    <use xlinkHref="#propozall-plus-copy-icon-path"></use>
                  </mask>
                  <g id="propozall-plus-copy-icon-5"></g>
                  <path
                    d="M16.8,8.4 L16.8,11.2 L19.6,11.2 L19.6,13.0666667 L16.8,13.0666667 L16.8,15.8666667 L14.9333333,15.8666667 L14.9333333,13.0666667 L12.1333333,13.0666667 L12.1333333,11.2 L14.9333333,11.2 L14.9333333,8.4 L16.8,8.4 Z M10.2666667,4.66666667 L21.4666667,4.66666667 C22.4961339,4.66666667 23.3333333,5.50386662 23.3333333,6.53333333 L23.3333333,17.7333333 C23.3333333,18.7627997 22.4961339,19.6 21.4666667,19.6 L10.2666667,19.6 C9.23719985,19.6 8.4,18.7627997 8.4,17.7333333 L8.4,6.53333333 C8.4,5.50386662 9.23719985,4.66666667 10.2666667,4.66666667 L10.2666667,4.66666667 Z M10.2666667,17.7333333 L21.4685341,17.7333333 L21.4666667,6.53333333 L10.2666667,6.53333333 L10.2666667,17.7333333 Z M6.53333333,10.2666667 L6.53333333,21.4666667 L17.7333333,21.4666667 L17.7333333,23.3333333 L6.53333333,23.3333333 C5.50386662,23.3333333 4.66666667,22.4961339 4.66666667,21.4666667 L4.66666667,10.2666667 L6.53333333,10.2666667 Z"
                    fill="#000000"
                    mask="url(#propozall-plus-copy-icon-mask)"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </span>
  )
}
