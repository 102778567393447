export const influencerJson = [
  {
    type: "div",
    props: {
      className: "proposal-resume",
    },
    elements: [
      // PROFILE CONTRACTED
      {
        type: "Row",
        props: {
          align: "top",
          gutter: [16, 16],
          justify: "space-between",
          className: "row-content proposal-profile",
        },
        elements: [
          // AVATAR
          {
            type: "Col",
            props: {
              xs: 24,
              sm: 24,
              md: 4,
              lg: 3,
            },
            elements: [
              {
                type: "div",
                props: {
                  className: "avatar",
                },
                elements: [
                  {
                    type: "Avatar",
                    name: "contracted.avatar_url",
                  },
                ],
              },
            ],
          },
          // NAME
          {
            type: "Col",
            props: {
              xs: 24,
              sm: 24,
              md: 8,
              lg: 9,
            },
            elements: [
              {
                type: "div",
                props: {
                  className: "info normal-16",
                },
                elements: [
                  {
                    type: "div",
                    props: {
                      className: "normal-20",
                    },
                    elements: [
                      {
                        type: "text",
                        name: "contracted.name",
                      },
                    ],
                  },
                  {
                    type: "div",
                    props: {
                      className: "normal-16",
                    },
                    elements: [
                      {
                        type: "text",
                        name: "contracted.email",
                      },
                    ],
                  },
                  {
                    type: "SocialMedias",
                    name: "contracted.social_medias",
                    default: "",
                    props: {
                      className: "normal-20",
                    },
                  },
                  // TODO: adicionar items (social_networks)
                ],
              },
            ],
          },
          // CNPJ
          {
            type: "Col",
            props: {
              xs: 24,
              sm: 24,
              md: 12,
              lg: 12,
              className: "normal-20",
            },
            elements: [
              {
                type: "div",
                props: {
                  className: "normal-16",
                },
                elements: [
                  {
                    type: "div",
                    props: {},
                    elements: [
                      {
                        type: "text",
                        default: "CNPJ: ",
                      },
                      {
                        type: "CpfCnpj",
                        name: "contracted.cpf_cnpj",
                      },
                    ],
                  },
                  {
                    type: "div",
                    props: {},
                    elements: [
                      {
                        type: "text",
                        default: "Nome/Razão Social: ",
                      },
                      {
                        type: "text",
                        name: "contracted.company_name",
                      },
                    ],
                  },
                ],
              },
              {
                type: "div",
                props: {
                  className: "address normal-16",
                },
                elements: [
                  {
                    type: "Address",
                    name: "address",
                    default: "",
                  },
                ],
              },
            ],
          },
        ],
      },

      // CONTRACTOR
      {
        type: "CollapseRowHeader",
        props: {
          title: "Dados do contratante",
          icon: "contractor",
          resume: "CPF/CNPJ, nome, contato, telefone e e-mail.",
          collapseFor: "row-content-counterparty",
          path: "/dashboard/proposal/2",
        },
      },

      {
        type: "CollapseRowContent",
        props: {
          id: "row-content-counterparty",
        },
        elements: [
          {
            type: "Col",
            props: {
              xs: 24,
              sm: 24,
              md: 12,
              lg: 12,
            },
            elements: [
              {
                type: "div",
                props: {
                  className: "label-value",
                },
                elements: [
                  {
                    type: "label",
                    elements: [
                      {
                        type: "text",
                        default: "CPF/CNPJ:",
                      },
                    ],
                  },
                  {
                    type: "div",
                    props: {},
                    elements: [
                      {
                        type: "CpfCnpj",
                        default: "-",
                        name: "contractor.cpf_cnpj",
                      },
                    ],
                  },
                ],
              },
              {
                type: "div",
                props: {
                  className: "label-value",
                },
                elements: [
                  {
                    type: "label",
                    elements: [
                      {
                        type: "text",
                        default: "Nome:",
                      },
                    ],
                  },
                  {
                    type: "div",
                    props: {},
                    elements: [
                      {
                        type: "text",
                        name: "contractor.company_name",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            type: "Col",
            props: {
              xs: 24,
              sm: 24,
              md: 12,
              lg: 12,
            },
            elements: [
              {
                type: "div",
                props: {
                  className: "label-value",
                },
                elements: [
                  {
                    type: "label",
                    elements: [
                      {
                        type: "text",
                        default: "Contato:",
                      },
                    ],
                  },
                  {
                    type: "div",
                    props: {},
                    elements: [
                      {
                        type: "text",
                        name: "contractor.name",
                      },
                    ],
                  },
                ],
              },
              {
                type: "div",
                props: {
                  className: "label-value",
                },
                elements: [
                  {
                    type: "label",
                    elements: [
                      {
                        type: "text",
                        default: "E-mail:",
                      },
                    ],
                  },
                  {
                    type: "div",
                    props: {},
                    elements: [
                      {
                        type: "text",
                        name: "contractor.email",
                      },
                    ],
                  },
                ],
              },
              {
                type: "div",
                props: {
                  className: "label-value",
                },
                elements: [
                  {
                    type: "label",
                    elements: [
                      {
                        type: "text",
                        default: "Telefone:",
                      },
                    ],
                  },
                  {
                    type: "div",
                    props: {},
                    elements: [
                      {
                        type: "text",
                        name: "contractor.phone",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },

      // SCOPE
      {
        type: "CollapseRowHeader",
        props: {
          title: "Dados da campanha",
          icon: "description-service",
          resume:
            "Nome da campanha, periodo da campanha, descrição, tipo da campanha, canais, impulsionar publicações, repostar publicações, destacar nos favoritos, validade da proposta.",
          collapseFor: "row-content-campaign",
          path: "/dashboard/proposal/1",
        },
      },
      {
        type: "CollapseRowContent",
        props: {
          id: "row-content-campaign",
        },
        elements: [
          {
            type: "Col",
            props: {
              xs: 24,
              sm: 24,
              md: 12,
              lg: 12,
            },
            elements: [
              {
                type: "div",
                props: {
                  className: "label-value",
                },
                elements: [
                  {
                    type: "label",
                    elements: [
                      {
                        type: "text",
                        default: "Nome da campanha:",
                      },
                    ],
                  },
                  {
                    type: "div",
                    props: {},
                    elements: [
                      {
                        type: "text",
                        name: "campaign_name",
                      },
                    ],
                  },
                ],
              },
              {
                type: "div",
                props: {
                  className: "label-value",
                },
                elements: [
                  {
                    type: "label",
                    elements: [
                      {
                        type: "text",
                        default: "Periodo da campanha:",
                      },
                    ],
                  },
                  {
                    type: "div",
                    props: {},
                    elements: [
                      {
                        type: "text",
                        name: "campaign_starts_at",
                      },
                      {
                        type: "text",
                        default: " a ",
                      },
                      {
                        type: "text",
                        name: "campaign_ends_at",
                      },
                    ],
                  },
                ],
              },
              {
                type: "div",
                props: {
                  className: "label-value",
                },
                elements: [
                  {
                    type: "label",
                    elements: [
                      {
                        type: "text",
                        default: "Descrição:",
                      },
                    ],
                  },
                  {
                    type: "div",
                    props: {},
                    elements: [
                      {
                        type: "text",
                        name: "campaign_description",
                      },
                    ],
                  },
                ],
              },

              {
                type: "div",
                props: {
                  className: "label-value",
                },
                elements: [
                  {
                    type: "label",
                    elements: [
                      {
                        type: "text",
                        default: "Tipo da campanha:",
                      },
                    ],
                  },
                  {
                    type: "div",
                    props: {},
                    elements: [
                      {
                        type: "text",
                        name: "segment_id",
                      },
                    ],
                  },
                ],
              },

              {
                type: "div",
                props: {
                  className: "label-value",
                },
                elements: [
                  {
                    type: "label",
                    elements: [
                      {
                        type: "text",
                        default: "Canais:",
                      },
                    ],
                  },
                  {
                    type: "div",
                    props: {},
                    elements: [
                      {
                        type: "ItemsLabels",
                        name: "items",
                      },
                    ],
                  },
                ],
              },

              {
                type: "div",
                props: {
                  className: "label-value",
                },
                elements: [
                  {
                    type: "label",
                    elements: [
                      {
                        type: "text",
                        default: "Outros formatos:",
                      },
                    ],
                  },
                  {
                    type: "div",
                    props: {},
                    elements: [
                      {
                        type: "choice",
                        name: "campaign_format_social_media",
                        choices: [[true, "Redes Sociais; "]],
                      },
                      {
                        type: "choice",
                        name: "campaign_format_podcast",
                        choices: [[true, "Podcast; "]],
                      },
                      {
                        type: "choice",
                        name: "campaign_format_shooting",
                        choices: [[true, "Shooting; "]],
                      },
                      {
                        type: "choice",
                        name: "campaign_format_presential",
                        choices: [[true, "Presencial; "]],
                      },
                      {
                        type: "choice",
                        name: "campaign_format_live",
                        choices: [[true, "Live; "]],
                      },
                    ],
                  },
                ],
              },

              {
                type: "div",
                props: {
                  className: "label-value",
                },
                elements: [
                  {
                    type: "label",
                    elements: [
                      {
                        type: "text",
                        default: "Observações:",
                      },
                    ],
                  },
                  {
                    type: "div",
                    props: {},
                    elements: [
                      {
                        type: "TextArea",
                        name: "campaign_format_observations",
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            type: "Col",
            props: {
              xs: 24,
              sm: 24,
              md: 12,
              lg: 12,
            },
            elements: [
              // {
              //   type: "div",
              //   props: {
              //     className: "label-value",
              //   },
              //   elements: [
              //     {
              //       type: "label",
              //       elements: [
              //         {
              //           type: "text",
              //           default: "Tempo mínimo para apagar publicação:",
              //         },
              //       ],
              //     },
              //     {
              //       type: "div",
              //       props: {},
              //       elements: [
              //         {
              //           type: "text",
              //           name: "time_erase_post",
              //           suffix: " mês",
              //         },
              //       ],
              //     },
              //   ],
              // },

              {
                type: "div",
                props: {
                  className: "label-value",
                },
                elements: [
                  {
                    type: "label",
                    elements: [
                      {
                        type: "text",
                        default: "Impulsionar publicações:",
                      },
                    ],
                  },
                  {
                    type: "div",
                    props: {},
                    elements: [
                      {
                        type: "choice",
                        name: "has_boost",
                        default: " - ",
                        choices: [
                          [true, "Sim"],
                          [false, "Não"],
                        ],
                      },
                    ],
                  },
                ],
              },

              {
                type: "div",
                props: {
                  className: "label-value",
                },
                elements: [
                  {
                    type: "label",
                    elements: [
                      {
                        type: "text",
                        default: "Observações:",
                      },
                    ],
                  },
                  {
                    type: "div",
                    props: {},
                    elements: [
                      {
                        type: "TextArea",
                        name: "boost_observations",
                      },
                    ],
                  },
                ],
              },

              {
                type: "div",
                props: {
                  className: "label-value",
                },
                elements: [
                  {
                    type: "label",
                    elements: [
                      {
                        type: "text",
                        default: "Repostar publicações:",
                      },
                    ],
                  },
                  {
                    type: "div",
                    props: {},
                    elements: [
                      {
                        type: "choice",
                        name: "has_repost",
                        default: " - ",
                        choices: [
                          [true, "Rede do cliente"],
                          [false, "Não"],
                        ],
                      },
                    ],
                  },
                ],
              },

              {
                type: "div",
                props: {
                  className: "label-value",
                },
                elements: [
                  {
                    type: "label",
                    elements: [
                      {
                        type: "text",
                        default: "Observações:",
                      },
                    ],
                  },
                  {
                    type: "div",
                    props: {},
                    elements: [
                      {
                        type: "TextArea",
                        name: "repost_observations",
                      },
                    ],
                  },
                ],
              },

              {
                type: "div",
                props: {
                  className: "label-value",
                },
                elements: [
                  {
                    type: "label",
                    elements: [
                      {
                        type: "text",
                        default: "Destacar nos favoritos:",
                      },
                    ],
                  },
                  {
                    type: "div",
                    props: {},
                    elements: [
                      {
                        type: "choice",
                        name: "highlight_post",
                        default: " - ",
                        choices: [
                          [true, "Sim"],
                          [false, "Não"],
                        ],
                      },
                    ],
                  },
                ],
              },

              {
                type: "div",
                props: {
                  className: "label-value",
                },
                elements: [
                  {
                    type: "label",
                    elements: [
                      {
                        type: "text",
                        default: "Validade da proposta:",
                      },
                    ],
                  },
                  {
                    type: "div",
                    props: {},
                    elements: [
                      {
                        type: "text",
                        name: "valid_until",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },

      // VALUE
      {
        type: "CollapseRowHeader",
        props: {
          title: "Valor da proposta",
          icon: "currency",
          resume:
            "Valor total, prazo de pagamento, faturamento e observações gerais.",
          collapseFor: "row-content-values",
          path: "/dashboard/proposal/2",
        },
      },

      {
        type: "CollapseRowContent",
        props: {
          id: "row-content-values",
        },
        elements: [
          {
            type: "Col",
            props: {
              xs: 24,
              sm: 24,
              md: 12,
              lg: 12,
            },
            elements: [
              {
                type: "div",
                props: {
                  className: "label-value",
                },
                elements: [
                  {
                    type: "label",
                    elements: [
                      {
                        type: "text",
                        default: "Valor total:",
                      },
                    ],
                  },
                  {
                    type: "div",
                    props: {},
                    elements: [
                      {
                        type: "text",
                        name: "total_value",
                        formatter: "currency",
                      },
                    ],
                  },
                ],
              },
              {
                type: "div",
                props: {
                  className: "label-value",
                },
                elements: [
                  {
                    type: "label",
                    elements: [
                      {
                        type: "text",
                        default: "Prazo de pagamento:",
                      },
                    ],
                  },
                  {
                    type: "div",
                    props: {},
                    elements: [
                      {
                        type: "text",
                        name: "days_to_payment",
                        suffix: " ",
                      },
                      {
                        type: "choice",
                        name: "days_to_payment_type",
                        default: "dias",
                        choices: [
                          ["util", "dias úteis"],
                          ["corrido", "dias corridos"],
                        ],
                      },
                    ],
                  },
                ],
              },

              {
                type: "div",
                props: {
                  className: "label-value",
                },
                elements: [
                  {
                    type: "label",
                    elements: [
                      {
                        type: "text",
                        default: "Faturamento:",
                      },
                    ],
                  },
                  {
                    type: "div",
                    props: {},
                    elements: [
                      {
                        type: "text",
                        name: "billing_date",
                      },
                    ],
                  },
                ],
              },

              {
                type: "div",
                props: {
                  className: "label-value",
                },
                elements: [
                  {
                    type: "label",
                    elements: [
                      {
                        type: "text",
                        default: "Observações gerais:",
                      },
                    ],
                  },
                  {
                    type: "div",
                    props: {},
                    elements: [
                      {
                        type: "TextArea",
                        name: "general_observations",
                      },
                    ],
                  },
                ],
              },

              {
                type: "div",
                conditional: {
                  name: "payment_pix_key",
                },
                props: {
                  className: "label-value",
                },
                elements: [
                  {
                    type: "label",
                    elements: [
                      {
                        type: "text",
                        default: "Chave PIX para pagamento:",
                      },
                    ],
                  },
                  {
                    type: "BoxQRCodePix",
                    name: "payment_pix_key",
                    props: {},
                  },
                ],
              },
            ],
          },
          {
            type: "Col",
            props: {
              xs: 24,
              sm: 24,
              md: 12,
              lg: 12,
            },
            elements: [
              {
                type: "div",
                props: {
                  className: "label-value",
                },
                conditional: {
                  name: "contracted.bank_account",
                },
                elements: [
                  {
                    type: "label",
                    elements: [
                      {
                        type: "text",
                        default: "Banco:",
                      },
                    ],
                  },
                  {
                    type: "div",
                    props: {},
                    elements: [
                      {
                        type: "text",
                        name: "contracted.bank_account.bank_name",
                      },
                    ],
                  },
                ],
              },

              {
                type: "div",
                props: {
                  className: "label-value",
                },
                conditional: {
                  name: "contracted.bank_account",
                },
                elements: [
                  {
                    type: "label",
                    elements: [
                      {
                        type: "text",
                        default: "Agência:",
                      },
                    ],
                  },
                  {
                    type: "div",
                    props: {},
                    elements: [
                      {
                        type: "text",
                        name: "contracted.bank_account.agency",
                      },
                    ],
                  },
                ],
              },

              {
                type: "div",
                props: {
                  className: "label-value",
                },
                conditional: {
                  name: "contracted.bank_account",
                },
                elements: [
                  {
                    type: "label",
                    elements: [
                      {
                        type: "text",
                        default: "Conta:",
                      },
                    ],
                  },
                  {
                    type: "div",
                    props: {},
                    elements: [
                      {
                        type: "text",
                        name: "contracted.bank_account.account_number",
                      },
                    ],
                  },
                ],
              },

              {
                type: "div",
                props: {
                  className: "label-value",
                },
                conditional: {
                  name: "contracted.bank_account",
                },
                elements: [
                  {
                    type: "label",
                    elements: [
                      {
                        type: "text",
                        default: "Tipo de conta:",
                      },
                    ],
                  },
                  {
                    type: "div",
                    props: {},
                    elements: [
                      {
                        type: "choice",
                        name: "contracted.bank_account.account_type",
                        default: "-",
                        choices: [
                          ["poupança", "Poupança"],
                          ["corrente", "Corrente"],
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },

      // ATTACHMENTS
      {
        type: "CollapseRowHeader",
        props: {
          title: "Anexos",
          icon: "clips",
          resume: "Arquivos anexados.",
          collapseFor: "row-content-anexos",
        },
      },

      {
        type: "CollapseRowContent",
        props: {
          id: "row-content-anexos",
          className: "file-container",
          gutter: [16, 16],
        },
        elements: [
          {
            type: "FileItem",
            name: "files",
          },
        ],
      },
    ],
  },
]
