import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Col, Row } from "antd"
import { beautifyCpfCnpj } from "../../../../../utils/formaters"
import "./styles.scss"

const SignersTable = ({ signers }) => {
  const [signersContractor, setSignersContractor] = useState([])
  const [signersContracted, setSignersContracted] = useState([])

  useEffect(() => {
    if (signers && signers.length > 0) {
      setSignersContractor(
        signers.filter((x) => x.proposal_role === "CONTRACTOR")
      )
      setSignersContracted(
        signers.filter((x) => x.proposal_role === "CONTRACTED")
      )
    }
  }, [signers])

  const tables = [
    {
      signers: signersContracted,
      title: <>Assinantes do contratado</>,
    },
    {
      signers: signersContractor,
      title: <>Assinantes do contratante</>,
    },
  ]

  return tables.map((item, index) => {
    return (
      <Row className="signers-table" key={index}>
        <Col span={24} className="signers-title">
          {item.title}
        </Col>
        <Col span={24} className="signers-hearder">
          <Row gutter={[16, 0]}>
            <Col span={4}>Nome</Col>
            {/* <Col span={4}>Empresa</Col> */}
            <Col span={4}>Quem assina</Col>
            <Col span={8}>E-mail</Col>
            <Col span={4}>CPF</Col>
            <Col span={4}>Status da assinatura</Col>
          </Row>
        </Col>
        {item.signers.length === 0 ? (
          <div className="no-signers">Nenhum assinante!</div>
        ) : (
          <>
            {item.signers.map((signer, index) => (
              <Col span={24} key={index} className="signers-body">
                <Row gutter={[16, 0]}>
                  <Col span={4} className="not-overflow">
                    {signer.name || " - "}
                  </Col>
                  {/* <Col span={4} className="not-overflow">
                      {signer.proposal_role === "CONTRACTOR"
                        ? proposal.contractor.company_name
                        : proposal.contracted.company_name}
                    </Col> */}
                  <Col span={4} className="not-overflow">
                    {signer.signer_role_name}
                  </Col>
                  <Col span={8} className="not-overflow">
                    {signer.email}
                  </Col>
                  <Col span={4} className="not-overflow">
                    {signer.cpf ? beautifyCpfCnpj(signer.cpf) : " - "}
                  </Col>
                  <Col span={4} className="not-overflow">
                    {!signer.signed_at ? "Pendente" : "Assinou"}
                  </Col>
                </Row>
              </Col>
            ))}
          </>
        )}
      </Row>
    )
  })
}

SignersTable.propTypes = {
  signers: PropTypes.array,
}

export default SignersTable
