import React from "react"
import { Tooltip as AntdTooltip } from "antd"
import PropTypes from "prop-types"
import "./styles.scss"

const Tooltip = ({ title, placement, children }) => {
  return (
    <AntdTooltip title={title} placement={placement}>
      {children}
    </AntdTooltip>
  )
}

Tooltip.propTypes = {
  title: PropTypes.string,
  placement: PropTypes.string,
  children: PropTypes.any,
}

export { Tooltip }
