import { Form } from "antd"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, Link } from "react-router-dom"
import { BlueButton } from "../../../common/components/Button"
import BaseModal from "../../../common/components/Modal"
import RoundedInputPassword from "../../../common/components/RoundedInputPassword"
import { useQuery } from "../../../utils/query"
import StateStatus from "../../../utils/stateStatus"
import logoImg from "../../../common/assets/logo/propozall.svg"
import {
  changePassword,
  confirmLink,
  resetPasswState,
  selectPasswordState,
} from "../passwordSlice"

export const ChangePassword = () => {
  const [form] = Form.useForm()
  const [passValidation, updatePassValidation] = useState({})
  const query = useQuery()
  const password = useSelector(selectPasswordState)
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    const code = query.get("code")
    const email = query.get("email")

    if (password.status.confirmLink === StateStatus.idle) {
      dispatch(confirmLink({ email, code }))
    }
  }, [])

  const passwordValidation = (value) => {
    const result = {
      hasEigthLength: value.length >= 8,
      hasOneNumber: value.search(/\d/g) !== -1,
      hasOneCapitalLetter: value.search(/[A-Z]/g) !== -1,
      hasOneLowerLetter: value.search(/[a-z]/g) !== -1,
    }

    updatePassValidation(result)
    return result
  }

  return (
    <div
      style={{
        height: "90%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Link to={"/"}>
          <img
            src={logoImg}
            alt="Logo Propozall"
            className="logo"
            width="300"
          />
        </Link>
        <div className="subtitle">Cadastrar nova senha</div>
      </div>
      {password.status.changePassword !== StateStatus.succeeded && (
        <Form
          form={form}
          layout="vertical"
          onFinish={(values) => {
            values.token = password.token
            delete values.confirm
            dispatch(changePassword(values))
          }}
        >
          <Form.Item
            name="new_password"
            label="Nova senha"
            initialValue={""}
            rules={[
              {
                required: true,
                message: <div className="normal-12">Insira uma senha</div>,
              },
              () => ({
                validator(_, __) {
                  const falses = Object.entries(passValidation).filter(
                    (item) => item[1] === false
                  )
                  if (falses.length > 0) {
                    return Promise.reject(new Error("Senha inválida"))
                  }
                  return Promise.resolve()
                },
              }),
            ]}
          >
            <RoundedInputPassword
              style={{ width: "100%" }}
              placeholder=""
              onChange={(event) => {
                passwordValidation(event.target.value)
              }}
            />
          </Form.Item>
          <div className="password-validation-container">
            <div className="password-validation">
              <div className={passValidation.hasEigthLength ? "checked" : ""}>
                8 caracteres
              </div>
              <div
                className={passValidation.hasOneCapitalLetter ? "checked" : ""}
              >
                1 letra maiúscula
              </div>
              <div className={passValidation.hasOneNumber ? "checked" : ""}>
                1 número
              </div>
              <div
                className={passValidation.hasOneLowerLetter ? "checked" : ""}
              >
                1 letra minúscula
              </div>
            </div>
          </div>
          <Form.Item
            label="Confirmar senha"
            name="confirm"
            rules={[
              {
                required: true,
                message: <div className="normal-12">Insira uma senha</div>,
              },
              () => ({
                validator(_, value) {
                  const newPass = form.getFieldsValue().new_password
                  if (value !== newPass) {
                    return Promise.reject(new Error("Senha não confere"))
                  }
                  return Promise.resolve()
                },
              }),
            ]}
          >
            <RoundedInputPassword style={{ width: "100%" }} placeholder="" />
          </Form.Item>
          <BlueButton
            isLoading={password.status.changePassword === StateStatus.loading}
            style={{ width: "314px", margin: "35px 0" }}
            type="submit"
          >
            Alterar
          </BlueButton>
        </Form>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <hr></hr>
        <div>
          <span className="font-14">Você não possui uma conta? </span>
          <a href="https://propozall.com/planos/">
            <span className="link-14">Criar uma conta</span>
          </a>
        </div>
      </div>
      <BaseModal
        isVisible={password.status.confirmLink === StateStatus.failed}
        title="Link inválido"
        okButtonText="Ok"
        onOk={() => {
          dispatch(resetPasswState())
          history.push("/")
        }}
        onCancel={() => {
          dispatch(resetPasswState())
          history.push("/")
        }}
      >
        Link inválido, solicite a troca de senha novamente!
      </BaseModal>
      <BaseModal
        isVisible={password.status.changePassword === StateStatus.succeeded}
        title="Senha alterada"
        okButtonText="Ok"
        onOk={() => {
          dispatch(resetPasswState())
          history.push("/")
        }}
        onCancel={() => {
          dispatch(resetPasswState())
          history.push("/")
        }}
      >
        Senha alterada com sucesso!
      </BaseModal>
    </div>
  )
}
