import React, { useEffect, useMemo, useRef, useState } from "react"
import PropTypes from "prop-types"
//
import Icons from "../../common/components/Icons"
//
import "./index.scss"
import StateStatus from "../../utils/stateStatus"
import { useDispatch, useSelector } from "react-redux"
import {
  // addMessageOnQueue,
  cancelProposalThunk,
  listMessagesProposalThunk,
  proposalFulfillThunk,
  resetChatProposal,
  resetProposalStatus,
  resetStatusMessages,
  selectChatProposalState,
  // sendAlertProposalThunk,
  // sendMessageProposalThunk,
  setProposalIdOnChat,
  updateChatData,
} from "./slice"
import { Link, useParams } from "react-router-dom"
import Avatar from "../../common/components/avatar"
import { Col, Menu, Row } from "antd"
import TextArea from "antd/lib/input/TextArea"
import { formatStatusToStep } from "../../utils/formaters"
import { WhiteButton } from "../../common/components/Button"
import DeleteOrCancelModal from "../../common/components/ProposalDetails/components/deleteOrCancelModal"
import BaseModal from "../../common/components/Modal"
import SearchAnimation from "../../common/components/SearchAnimation"
import { useMessagesSocketIO } from "./socket"

const ChatProposalMessage = ({
  proposalId,
  isVisible,
  onClose,
  onUpdateProposal,
}) => {
  const state = useSelector(selectChatProposalState)
  const dispatch = useDispatch()
  const [messages, setMessages] = useState([])
  const refChat = useRef()
  const [input, setInput] = useState("")
  const [chatingWith, setChatingWith] = useState({})
  const [myself, setMyself] = useState({})
  const [owner, setOwner] = useState({})
  const [countMenuItems, setCountMenuItems] = useState(0)
  // const [jobSendMessage, updateJobSendMessage] = useState()
  const [showDropdownMenu, updateShowDropdownMenu] = useState(false)
  const [showModal, setShowModal] = useState({})
  const [inputSearch, setInputSearch] = useState()
  const [intervalGetMessages, setIntervalGetMessages] = useState()
  const socket = useMessagesSocketIO({
    proposalId,
    onReceiveMessage: (data) => {
      setMessages([...data.messages])
      setTimeout(resizeChat, 400)
      dispatch(updateChatData(data))
    },
    onJoinChat: (data) => {
      console.log({ data })
    },
    onLeftChat: (data) => {
      console.log({ data })
    },
  })

  // const createJobSendMessage = () => {
  //   if (jobSendMessage) clearTimeout(jobSendMessage)

  //   const newJob = setTimeout(() => {
  //     dispatch(sendMessageProposalThunk({ proposalId }))
  //   }, 3 * 1000)

  //   updateJobSendMessage(newJob)
  // }

  // const createJobGetMessages = (proposalId) => {
  //   const interval = setTimeout(() => {
  //     if (
  //       state.status.listMessagesProposal === StateStatus.loading ||
  //       state.status.sendMessageProposal === StateStatus.loading ||
  //       state.status.deleteMessageProposal === StateStatus.loading ||
  //       state.status.sendAlertProposal === StateStatus.loading ||
  //       messages?.filter((x) => x.sent === false).length > 0
  //     ) {
  //       return setTimeout(() => createJobGetMessages(proposalId), 400)
  //     } else {
  //       dispatch(listMessagesProposalThunk(proposalId))
  //     }
  //   }, [8 * 1000])

  //   setIntervalGetMessages(interval)
  // }

  useEffect(() => {
    // if (jobSendMessage) clearTimeout(jobSendMessage)
    dispatch(resetChatProposal())

    if (isVisible) {
      dispatch(setProposalIdOnChat(proposalId))
      dispatch(listMessagesProposalThunk(proposalId))
    } else {
      dispatch(setProposalIdOnChat(null))
    }
  }, [proposalId, isVisible])

  const resizeChat = () => {
    const body = document.querySelector("body")
    const topBarChat = document.querySelector(".top-bar-chat")
    const bottomBarChat = document.querySelector(".bottom-bar-chat")

    if (!body || !topBarChat) {
      return resizeChat()
    }

    const heigth = {
      content:
        body.offsetHeight -
        topBarChat.offsetHeight -
        (bottomBarChat?.offsetHeight ?? 0),
      topBar: topBarChat.offsetHeight,
    }

    refChat.current.style.marginTop = `${heigth.topBar}px`
    refChat.current.style.height = `${heigth.content}px`
    refChat.current.scrollTop = refChat.current.scrollHeight

    getMenuItems()
  }

  const getMenuItems = () => {
    const items = document.querySelectorAll(
      ".chat-proposal-menu .ant-menu-item"
    )
    setCountMenuItems(items.length ?? 0)
  }

  useEffect(() => {
    document.body.style.overflow = isVisible ? "hidden" : "auto"
    setTimeout(resizeChat, 300)
    setTimeout(resizeChat, 600)

    return () => clearTimeout(intervalGetMessages)
  }, [])

  useEffect(() => {
    if (
      (state.status.listMessagesProposal === StateStatus.succeeded ||
        state.status.sendMessageProposal === StateStatus.succeeded ||
        state.status.deleteMessageProposal === StateStatus.succeeded ||
        state.status.sendAlertProposal === StateStatus.succeeded) &&
      isVisible
    ) {
      dispatch(resetStatusMessages())

      let myself
      let chatingWith

      if (state.data?.role === "CONTRACTOR") {
        myself = state.data.contractor
        chatingWith = state.data.contracted
      } else {
        myself = state.data.contracted
        chatingWith = state.data.contractor
      }

      setMyself(myself)
      setChatingWith(chatingWith)
      socket.joinChat(myself.name)

      if (state.data?.created_by === "CONTRACTOR") {
        setOwner(state.data.contractor)
      } else {
        setOwner(state.data.contracted)
      }

      if (
        state.data.messages?.length >= messages?.length ||
        state.status.deleteMessageProposal === StateStatus.succeeded
      ) {
        setMessages(state.data.messages)
      }

      setTimeout(resizeChat, 400)
    }
  }, [
    isVisible,
    state.status.listMessagesProposal,
    state.status.sendMessageProposal,
    state.status.sendAlertProposal,
    state.status.deleteMessageProposal,
  ])

  useEffect(() => {
    if (state.status.listMessagesProposal === StateStatus.succeeded) {
      setIntervalGetMessages(null)
      // createJobGetMessages(proposalId)
    }
  }, [state.status.listMessagesProposal])

  const onSearchMessage = (value) => {
    setInputSearch(value)
    if (value.trim().length === 0) return setMessages(state.data.messages)
    const msgs = state.data.messages.filter(
      (x) =>
        x.type === "date" ||
        x.text?.toLowerCase()?.includes(value.toLowerCase())
    )
    const msgsFilter = []

    msgs.reduce((current, next, idx) => {
      if ((next && next.type !== "date") || current.type !== "date") {
        msgsFilter.push(current)
      }

      if (idx === msgs.length - 1 && next.type !== "date") {
        msgsFilter.push(next)
      }

      return next
    })

    setMessages(msgsFilter)
  }

  useEffect(() => {
    if (state.status.proposalFulfill === StateStatus.succeeded) {
      setShowModal({})
      dispatch(listMessagesProposalThunk(proposalId))
      resizeChat()
      setTimeout(resizeChat, 600)
      onUpdateProposal && onUpdateProposal()
      dispatch(resetProposalStatus())
    }
  }, [state.status.proposalFulfill])

  useEffect(() => {
    if (state.status.cancelProposal === StateStatus.succeeded) {
      setShowModal({})
      dispatch(listMessagesProposalThunk(proposalId))
      resizeChat()
      setTimeout(resizeChat, 600)
      onUpdateProposal && onUpdateProposal()
      dispatch(resetProposalStatus())
    }
  }, [state.status.cancelProposal])

  // useEffect(() => {
  //   if (
  //     state.status.sendMessageProposal === StateStatus.succeeded &&
  //     isVisible
  //   ) {
  //     //
  //   }

  //   if (state.status.sendMessageProposal === StateStatus.failed && isVisible) {
  //     //
  //   }
  // }, [state.status.sendMessageProposal])

  useEffect(() => {
    resizeChat()
  }, [messages])

  const addMessage = (text) => {
    const newMessage = {
      text,
      sent: false,
      type: "message",
      from_role: myself.role,
    }
    // dispatch(addMessageOnQueue(newMessage))
    setMessages([...messages, newMessage])
    setTimeout(() => {
      refChat.current.scrollTop = refChat.current.scrollHeight
    }, 300)

    // if (!jobSendMessage) {
    //   createJobSendMessage()
    // }

    socket.sendMessage(myself.role, text)
    resizeChat()
  }

  const onCloseChat = () => {
    socket.leaveChat(myself.name)
    document.body.style.overflow = "auto"
    dispatch(setProposalIdOnChat(null))
    // dispatch(sendMessageProposalThunk({ proposalId }))
    if (onClose) onClose()
  }

  const menu = useMemo(
    () => (
      <Menu className="chat-proposal-menu">
        {/* {state.data.status === "waiting_nfs" && state.data.role === "CONTRACTED" ? (
        <>
          <Menu.Item
            onClick={() => {
              history.push(`/dashboard/nfse/create/${proposalId}`)
            }}
          >
            <span className="icon-menu">
              <Icons icon="upload-circle" />
            </span>
            Enviar nota fiscal
          </Menu.Item>
        </>
      ) : null} */}

        {state.data.role === state.data.created_by && (
          <>
            {!["canceled", "finalized", "expired", "refused"].includes(
              state.data.status
            ) ? (
              <>
                <Menu.Divider />
                <Menu.Item
                  onClick={() => setShowModal({ type: "cancel-proposal" })}
                >
                  <span className="icon-menu">
                    <Icons icon="cancel" />
                  </span>
                  Cancelar proposta
                </Menu.Item>
              </>
            ) : null}

            {!["canceled", "finalized", "expired", "refused"].includes(
              state.data.status
            ) ? (
              <>
                <Menu.Divider />
                <Menu.Item
                  onClick={() => setShowModal({ type: "finalize-proposal" })}
                >
                  <span className="icon-menu">
                    <Icons icon="navigation-flag" />
                  </span>
                  Finalizar proposta
                </Menu.Item>
              </>
            ) : null}
          </>
        )}
      </Menu>
    ),
    [state.data]
  )

  const class_ = isVisible && !showModal.type ? "open" : "close"

  return (
    <>
      <div className={`aside-plans ${class_}`} onClick={onCloseChat}>
        <div
          className="content-chat-proposal"
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
        >
          <div className="top-bar-chat">
            <Row className="box-top-bar-search" justify={"space-between"}>
              <Col>
                {/** Será exibido somente em tela mobile, pelo css */}
                <Icons
                  icon="arrow-right"
                  onClick={() => {
                    if (location.pathname.includes("/webview")) {
                      history.replace("/webview/chat-proposal/back")
                    } else {
                      onCloseChat()
                    }
                  }}
                />
                {/** Será ocultado em tela mobile, pelo css */}
                <Icons
                  icon="close"
                  onClick={() => {
                    if (location.pathname.includes("/webview")) {
                      history.replace("/webview/chat-proposal/back")
                    } else {
                      onCloseChat()
                    }
                  }}
                />
              </Col>
              <Col>
                <SearchAnimation
                  onSearch={onSearchMessage}
                  value={inputSearch}
                  placeholder="Pesquisar"
                />
              </Col>
            </Row>
            <Row
              className="box-person-chatting"
              justify={"space-between"}
              align="middle"
            >
              <Col className="person-info">
                <Row justify={"start"} gutter={[16, 8]}>
                  <Col>
                    <Avatar src={chatingWith?.avatar_url} size={48} />
                  </Col>
                  <Col>
                    <div>{chatingWith?.name}</div>
                    <div>
                      {chatingWith?.role === "CONTRACTOR"
                        ? "Contratante"
                        : "Contratado"}
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col className="proposal-info">
                <div>
                  <Link
                    to={`/dashboard/proposals/${proposalId}`}
                    onClick={() => onCloseChat()}
                  >
                    {state.data.title ?? "..."}
                  </Link>
                </div>
                <div>
                  Proprietário:{" "}
                  {state.data.created_by === state.data.role
                    ? "você"
                    : owner?.name}
                </div>
              </Col>
            </Row>
            <Row
              className="box-proposal-target"
              justify={"space-between"}
              align="middle"
            >
              <Col span={12}>
                {state.data.status && (
                  <span
                    className="label-status-proposal"
                    data-status={state.data.status}
                  >
                    {state.data.status_name}
                    {!["draft", "expired", "canceled", "refused"].includes(
                      state.data.status
                    ) && (
                      <span> ({formatStatusToStep(state.data.status)})</span>
                    )}
                  </span>
                )}
              </Col>
              <Col span={12} style={{ textAlign: "right" }}>
                <span>
                  Você:{" "}
                  {myself?.role === "CONTRACTOR" ? "contratante" : "contratado"}
                </span>
              </Col>
            </Row>
          </div>
          <div className="center-bar-chat" ref={refChat}>
            {messages?.map((item, idx) => (
              <div
                key={idx}
                className="chat-message"
                data-fromuser={item.from_role === myself?.role}
                data-type={item.type}
              >
                <span>
                  {item.from_role !== myself?.role &&
                  (!messages[idx - 1]?.type ||
                    messages[idx - 1]?.type !== "message" ||
                    messages[idx - 1]?.from_role === myself?.role) ? (
                    <span className="triangle">
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                    </span>
                  ) : null}

                  {item.type === "alert" ? <Icons icon="lamp" /> : null}
                  <span className="text">{item.text}</span>

                  {item.id ? (
                    <Icons icon="check" className="status-msg-icon" />
                  ) : (
                    <Icons icon="clock" className="status-msg-icon" />
                  )}

                  {item.from_role === myself?.role &&
                  (!messages[idx + 1]?.type ||
                    messages[idx + 1]?.type !== "message" ||
                    messages[idx + 1]?.from_role !== myself?.role) ? (
                    <span className="triangle">
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                    </span>
                  ) : null}
                </span>
              </div>
            ))}
          </div>
          <div className="bottom-bar-chat">
            <Row className="box-chat-actions" justify={"end"} gutter={[8, 8]}>
              <Col
                span={24}
                className="col-chat-menu"
                style={{ display: showDropdownMenu ? "flex" : "none" }}
              >
                <div
                  className="background-menu"
                  onClick={() => updateShowDropdownMenu(!showDropdownMenu)}
                ></div>
                {menu}
              </Col>

              {state.data?.status === "accepted" &&
              state.data?.role !== state.data?.created_by &&
              !state.data?.last_alerts?.includes("send_contract") ? (
                <Col>
                  <WhiteButton
                    onClick={() => {
                      socket.sendAlert(myself.role, "send_contract")
                      // dispatch(
                      //   sendAlertProposalThunk({
                      //     proposalId,
                      //     alert: "send_contract",
                      //   })
                      // )
                    }}
                  >
                    Cobrar contrato
                  </WhiteButton>
                </Col>
              ) : null}

              {state.data?.status === "waiting_sign_contract" &&
              myself?.pending_signs === 0 &&
              chatingWith?.pending_signs > 0 &&
              !state.data?.last_alerts?.includes("sign_contract") ? (
                <Col>
                  <WhiteButton
                    onClick={() => {
                      socket.sendAlert(myself.role, "sign_contract")
                      // dispatch(
                      //   sendAlertProposalThunk({
                      //     proposalId,
                      //     alert: "sign_contract",
                      //   })
                      // )
                    }}
                  >
                    Cobrar assinaturas
                  </WhiteButton>
                </Col>
              ) : null}

              {state.data?.status === "waiting_nfs" &&
              state.data?.role === "CONTRACTOR" &&
              !state.data?.last_alerts?.includes("send_nfs") ? (
                <Col>
                  <WhiteButton
                    onClick={() => {
                      socket.sendAlert(myself.role, "send_nfs")
                      // dispatch(
                      //   sendAlertProposalThunk({
                      //     proposalId,
                      //     alert: "send_nfs",
                      //   })
                      // )
                    }}
                  >
                    Cobrar nota fiscal
                  </WhiteButton>
                </Col>
              ) : null}

              <Col>
                {countMenuItems > 0 && (
                  <div
                    className="more-button"
                    onClick={() => {
                      updateShowDropdownMenu(!showDropdownMenu)
                    }}
                  >
                    <Icons icon="more" />
                  </div>
                )}
              </Col>
            </Row>
            <Row
              className="box-chat-inputs"
              justify={"space-between"}
              style={{
                display: [
                  "accepted",
                  "waiting_sign_contract",
                  "waiting_nfs",
                ].includes(state.data?.status)
                  ? "flex"
                  : "none",
              }}
            >
              <Col span={24}>Mensagem:</Col>
              <Col span={24}>
                <Row align={"middle"} gutter={[8, 8]}>
                  <Col span={22}>
                    <TextArea
                      value={input}
                      onChange={(e) => setInput(e.target.value)}
                      onInput={(e) => {
                        e.target.style.height = `${e.target.scrollHeight}px`
                        resizeChat()
                      }}
                      // onKeyDown={(event) => {
                      //   const value = event.target.value.trim()

                      //   if (jobSendMessage) {
                      //     createJobSendMessage()
                      //   }

                      //   if (event.key === "Enter" || event.keyCode === 13) {
                      //     if (value.length > 0) {
                      //       event.preventDefault()
                      //       addMessage(event.target.value)
                      //     }
                      //     setInput("")
                      //   }
                      // }}
                    />
                  </Col>
                  <Col span={2}>
                    <div
                      className="btn-send-message"
                      onClick={() => {
                        if (input.length > 0) {
                          addMessage(input)
                          setInput("")
                        }
                      }}
                    >
                      <Icons icon="send-message" />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <DeleteOrCancelModal
        isVisible={
          showModal.type === "cancel-proposal" ||
          showModal.type === "delete-proposal"
        }
        isDelete={false}
        isLoading={state.status.cancelProposal === StateStatus.loading}
        onOk={() => {
          dispatch(cancelProposalThunk(proposalId))
        }}
        onCancel={() => {
          setShowModal({})
        }}
      />
      <BaseModal
        type="alert"
        isVisible={showModal.type === "finalize-proposal"}
        title={"Finalizar proposta"}
        isLoading={state.status.finalizeProposal === StateStatus.loading}
        okButtonText={"Continuar"}
        // okButtonColor="red"
        onOk={() => {
          dispatch(proposalFulfillThunk(proposalId))
        }}
        cancelButtonText={"Cancelar"}
        onCancel={() => {
          setShowModal({})
        }}
      >
        <Row justify="center">
          {["accepted", "waiting_sign_contract"].includes(
            state.data?.status
          ) ? (
            <>
              A proposta será finalizada sem um contrato assinado pelas partes.
              Deseja continuar mesmo assim?
            </>
          ) : state.data?.status === "waiting_nfs" &&
            state.data?.count_nfs === 0 ? (
            <>
              Você não adicionou notas fiscais para esta proposta. Deseja
              continuar mesmo assim?
            </>
          ) : (
            <>
              Ao finalizar esta proposta você não poderá dar continuidade ao
              processo.
            </>
          )}
        </Row>
      </BaseModal>
    </>
  )
}

const ChatProposalMessagePage = () => {
  const { id } = useParams()
  const [isVisible, setIsVisible] = useState(false)

  const setCredentialsFromPath = () => {
    /* Obtem access_token e refresh_token via URL, usado para abrir o chat via Webview do APP */
    const paths = location.href.split(`${id}/`)
    if (paths[1]) {
      const access = paths[1].trim().split("/")
      if (access[0] && access[1]) {
        localStorage.setItem("access_token", access[0])
        localStorage.setItem("refresh_token", access[1])
        return true
      }
    }

    if (localStorage.getItem("access_token")) {
      return true
    }

    return false
  }

  useEffect(() => {
    const success = setCredentialsFromPath()
    if (success) {
      setIsVisible(true)
    }
  }, [])

  return <ChatProposalMessage proposalId={id} isVisible={isVisible} />
}

ChatProposalMessage.defaultProps = {
  isVisible: false,
  onClose: () => {},
}

ChatProposalMessage.propTypes = {
  proposalId: PropTypes.string.isRequired,
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
  onUpdateProposal: PropTypes.func,
}

export default ChatProposalMessage
export { ChatProposalMessagePage }
