import React, { useEffect, useState, useRef, useCallback } from "react"
import PropTypes from "prop-types"

window.pdfjsLib.GlobalWorkerOptions.workerSrc = window.pdfjsWorker

const PDFViewer = ({ url, page, onLoad }) => {
  const canvasRef = useRef()
  const [pdfRef, setPdfRef] = useState()
  const pdfjsLib = window.pdfjsLib

  const renderPage = useCallback(
    async (pageNum, pdf) => {
      try {
        const page = await pdf.getPage(pageNum)
        const viewport = page.getViewport({ scale: 1.5 })
        const canvas = canvasRef.current
        canvas.height = viewport.height
        canvas.width = viewport.width
        page.render({
          canvasContext: canvas.getContext("2d"),
          viewport,
        })
      } catch (err) {}
    },
    [pdfRef]
  )

  useEffect(() => {
    renderPage(page, pdfRef)
  }, [pdfRef, page, renderPage])

  useEffect(() => {
    if (url) {
      const loadingTask = pdfjsLib.getDocument(url)
      loadingTask.promise.then(
        (loadedPdf) => {
          setPdfRef(loadedPdf)
          onLoad &&
            onLoad({
              numPages: loadedPdf.numPages,
            })
        },
        function (reason) {
          console.error(reason)
        }
      )
    }
  }, [url])

  return <canvas ref={canvasRef}></canvas>
}

PDFViewer.propTypes = {
  url: PropTypes.string,
  page: PropTypes.number,
  onLoad: PropTypes.func,
}

export default PDFViewer
