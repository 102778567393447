import api from "../../service/api"
import apiRoutes from "../../service/apiRoutes"

export const passwordChange = (data) =>
  api.post(`${apiRoutes.changePassword}`, data)

export const passwordForgot = (data) =>
  api.post(`${apiRoutes.forgetPassword}`, data)

export const linkConfirmation = (data) =>
  api.put(`${apiRoutes.forgetPassword}`, data)
