import React from "react"

export const RoundedExitIcon = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15 12H3"
          stroke="#323232"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.94727 16C5.42027 18.961 8.46827 21 12.0003 21C16.9713 21 21.0003 16.971 21.0003 12C21.0003 7.029 16.9713 3 12.0003 3C8.46827 3 5.42027 5.039 3.94727 8"
          stroke="#323232"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 9L15 12L12 15"
          stroke="#323232"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  )
}
