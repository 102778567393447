import React from "react"
import Icons from "../Icons"
import PropTypes from "prop-types"
import "./styles.scss"

const FavoriteIcon = ({ isFavorite, onChange, ...rest }) => {
  const title = isFavorite ? "Desfavoritar" : "Favoritar"
  const class_ = isFavorite ? "favorited" : ""

  return (
    <Icons
      className={`favorite-icon ${class_}`}
      icon="heart"
      title={onChange ? title : null}
      onClick={() => {
        if (onChange) onChange(!isFavorite)
      }}
      {...rest}
    />
  )
}

FavoriteIcon.propTypes = {
  isFavorite: PropTypes.bool,
  onChange: PropTypes.func,
}

export { FavoriteIcon }
