import React from "react"

export const ProposalStatusFinalizedIcon = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        width="21"
        height="22"
        viewBox="0 0 21 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.5003 0.666748C4.79633 0.666748 0.166992 5.29608 0.166992 11.0001C0.166992 16.7041 4.79633 21.3334 10.5003 21.3334C16.2043 21.3334 20.8337 16.7041 20.8337 11.0001C20.8337 5.29608 16.2043 0.666748 10.5003 0.666748C10.5003 0.666748 4.79633 0.666748 10.5003 0.666748ZM10.5003 19.2667C5.94333 19.2667 2.23366 15.5571 2.23366 11.0001C2.23366 6.44308 5.94333 2.73341 10.5003 2.73341C15.0573 2.73341 18.767 6.44308 18.767 11.0001C18.767 15.5571 15.0573 19.2667 10.5003 19.2667C10.5003 19.2667 5.94333 19.2667 10.5003 19.2667ZM8.43366 13.2424L15.2433 6.43277L16.7003 7.9001L8.43366 16.1668L4.30033 12.0334L5.75733 10.5764L8.43366 13.2424Z"
          fill="#002999"
        />
      </svg>
    </span>
  )
}
