import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import StateStatus from "../../utils/stateStatus"
import {
  createSigner,
  createSignerByCounterparty,
  deleteSigner,
  deleteSignerByCounterparty,
  getContract,
  getContractByCounterparty,
  signerEdit,
  signerEditByCounterparty,
} from "./service"

export const getContractInfo = createAsyncThunk(
  "signers/get-contract",
  async (contractId) => {
    let response
    if (localStorage.getItem("edit-signers-from-public-page") === "true") {
      response = await getContractByCounterparty(contractId)
    } else {
      response = await getContract(contractId)
    }
    return response.data
  }
)

export const removeSigner = createAsyncThunk(
  "signers/remove-signer",
  async ({ contractId, id }) => {
    let response
    if (localStorage.getItem("edit-signers-from-public-page") === "true") {
      response = await deleteSignerByCounterparty(contractId, id)
    } else {
      response = await deleteSigner(contractId, id)
    }
    return response.data
  }
)

export const addSigner = createAsyncThunk(
  "signers/add-signer",
  async ({ contractId, data }) => {
    let response
    if (localStorage.getItem("edit-signers-from-public-page") === "true") {
      response = await createSignerByCounterparty(contractId, data)
    } else {
      response = await createSigner(contractId, data)
    }
    return response.data
  }
)

export const editSigner = createAsyncThunk(
  "signers/edit-signer",
  async ({ contractId, id, data }) => {
    let response
    if (localStorage.getItem("edit-signers-from-public-page") === "true") {
      response = await signerEditByCounterparty(contractId, id, data)
    } else {
      response = await signerEdit(contractId, id, data)
    }
    return response.data
  }
)

// export const saveSigners = createAsyncThunk(
//   "signers/save-signers",
//   async ({ contractId, signers }) => {
//     const promisses = signers.map((signer) => {
//       return signer.id
//         ? editSigner({ contractId, id: signer.id, data: signer })
//         : addSigner({ contractId, data: signer })
//     })

//     await Promise.all(promisses)

//     return true
//   }
// )

const initialState = {
  status: {
    getContractInfo: StateStatus.idle,
    saveSigner: StateStatus.idle,
    removeSigner: StateStatus.idle,
  },
  data: {
    contract: null,
    deletingId: null,
  },
  errMessage: null,
  validations: [],
}

export const signersSlice = createSlice({
  name: "signers",
  initialState,
  reducers: {
    resetErrorsState: (state, payload) => {
      state.errMessage = null
      // state.status.removeSigner = StateStatus.idle
      // state.status.saveSigner = StateStatus.idle
    },
  },
  extraReducers: {
    // getContractInfo
    [getContractInfo.pending]: (state, action) => {
      state.status.getContractInfo = StateStatus.loading
    },
    [getContractInfo.fulfilled]: (state, action) => {
      state.data.contract = action.payload.data
      state.status.getContractInfo = StateStatus.succeeded
    },
    [getContractInfo.rejected]: (state, action) => {
      state.status.getContractInfo = StateStatus.failed
      state.errMessage = action.error.message
      state.validations = []
    },

    // removeSigner
    [removeSigner.pending]: (state, action) => {
      const { id } = action.meta.arg
      state.data.deletingId = id
      state.status.removeSigner = StateStatus.loading
    },
    [removeSigner.fulfilled]: (state, action) => {
      state.status.removeSigner = StateStatus.succeeded
    },
    [removeSigner.rejected]: (state, action) => {
      state.status.removeSigner = StateStatus.failed
      state.errMessage = action.error.message
      state.validations = []
    },

    // createSigner
    [addSigner.pending]: (state, action) => {
      state.status.saveSigner = StateStatus.loading
    },
    [addSigner.fulfilled]: (state, action) => {
      state.status.saveSigner = StateStatus.succeeded
    },
    [addSigner.rejected]: (state, action) => {
      state.status.saveSigner = StateStatus.failed
      state.errMessage = action.error.message
      state.validations = []
    },

    // editSigner
    [editSigner.pending]: (state, action) => {
      state.status.saveSigner = StateStatus.loading
    },
    [editSigner.fulfilled]: (state, action) => {
      state.status.saveSigner = StateStatus.succeeded
    },
    [editSigner.rejected]: (state, action) => {
      state.status.saveSigner = StateStatus.failed
      state.errMessage = action.error.message
      state.validations = []
    },
  },
})

export const { resetErrorsState } = signersSlice.actions

export const selectSignersState = (state) => state.signers
