import React from "react"

export const MessagesIcon = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          height="100%"
          width="100%"
          d="m4 0.25c-2.0622 0-3.75 1.6878-3.75 3.75v8c0 2.0622 1.6878 3.75 3.75 3.75h0.75v2.25a0.75008 0.75008 0 0 0 1.166 0.625l4.3125-2.875h5.7715c2.0622 0 3.75-1.6878 3.75-3.75v-8c0-2.0622-1.6878-3.75-3.75-3.75zm0 1.5h12c1.2515 0 2.25 0.99848 2.25 2.25v8c0 1.2515-0.99848 2.25-2.25 2.25h-6a0.75008 0.75008 0 0 0-0.41602 0.125l-3.334 2.2227v-1.5977a0.75008 0.75008 0 0 0-0.75-0.75h-1.5c-1.2515 0-2.25-0.99848-2.25-2.25v-8c0-1.2515 0.99848-2.25 2.25-2.25z"
          color="#000000"
          fill="#0700cf"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  )
}
