import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
//
import { proposalGet } from "../proposal/proposalService"
import {
  emitter,
  get,
  login,
  manuallyCreate,
  proposals,
  uploadCreate,
} from "./createNfseService"
//
import StateStatus from "../../utils/stateStatus"

export const getProposal = createAsyncThunk(
  "nfse/create/get-company",
  async ({ id }) => {
    const response = await proposalGet(id)

    return response.data.data
  }
)

export const loginMillionNote = createAsyncThunk(
  "nfse/create/million-note",
  async (data, thunkAPI) => {
    const { createNfse } = thunkAPI.getState()

    const response = await login({
      ...data,
      proposal_id: createNfse.proposal.id,
    })

    return {
      ...data,
      ...response.data.data,
    }
  }
)

export const getProposals = createAsyncThunk(
  "nfse/create/get-proposals",
  async ({ role }) => {
    const response = await proposals({
      role,
    })

    return {
      items: response.data.data.items,
    }
  }
)

export const sendNfse = createAsyncThunk(
  "nfse/create",
  async ({ type }, thunkAPI) => {
    const { createNfse } = thunkAPI.getState()

    let data = {}
    let response = {}

    if (type === "upload") {
      const data = new FormData()

      data.append("file", createNfse.nfse.file?.binary)
      data.append("emitted_at", createNfse.nfse.emitted_at)
      data.append("nf_number", createNfse.nfse.nf_number)
      data.append("value", createNfse.nfse.value)
      data.append("proposal_id", createNfse.nfse.proposal_id)

      response = await uploadCreate(data)
    } else if (type === "manually") {
      data = {
        value: createNfse.nfse.value,
        contractor_cpf_cnpj: createNfse.nfse.contractor.cpf_cnpj,
        proposal_id: createNfse.nfse.proposal_id,
        description: createNfse.nfse.description,
        iss_code: createNfse.nfse.service_code?.code,
      }

      response = await manuallyCreate(data)
    }

    return response.data.data
  }
)

export const getEmitter = createAsyncThunk(
  "nfse/get-emitter",
  async (_, thunkAPI) => {
    const { createNfse } = thunkAPI.getState()

    try {
      const response = await emitter({
        proposal_id: createNfse.proposal.id,
      })

      return response.data.data
    } catch (error) {
      return thunkAPI.rejectWithValue(error)
    }
  }
)

export const getNfse = createAsyncThunk("nfse/get", async (id) => {
  const response = await get(id)

  return response.data.data
})

const initialState = {
  status: {
    getNfse: StateStatus.idle,
    sendNfse: StateStatus.idle,
    getEmitter: StateStatus.idle,
    getProposal: StateStatus.idle,
    loginMillionNote: StateStatus.idle,
  },
  //
  proposals: [],
  proposal: {},
  data: {},
  user: {},
  emitter: {},
  nfse: {
    nf_number: null,
    value: null,
    description: null,
    emitted_at: null,
    public_url: null,
    service_code: {
      code: null,
      description: null,
    },
    proposal: null,
  },
  errMessage: "",
}

export const createNfseSlice = createSlice({
  name: "createNfse",
  initialState,
  reducers: {
    resetAll: (state) => {
      state.status = {
        getNfse: StateStatus.idle,
        sendNfse: StateStatus.idle,
        getEmitter: StateStatus.idle,
        getProposal: StateStatus.idle,
        loginMillionNote: StateStatus.idle,
      }
      //
      state.proposals = []
      state.proposal = {}
      state.data = {}
      state.user = {}
      state.emitter = {}
      //
      state.errMessage = ""
    },
    resetStatus: (state) => {
      state.status.sendNfse = StateStatus.idle
      state.status.loginMillionNote = StateStatus.idle
      state.status.getEmitter = StateStatus.idle
      state.emitter = {}
    },
    updateIndex: (state, action) => {
      state.index = action.payload
    },
    updateData: (state, action) => {
      state.nfse = action.payload?.nfse
    },
  },
  extraReducers: {
    [getProposal.pending]: (state) => {
      state.status.getProposal = StateStatus.loading
    },
    [getProposal.fulfilled]: (state, action) => {
      state.proposal = action.payload
      state.proposal.total_value = state.proposal.total_value * 100

      if (state.proposal.contracted?.cpf_cnpj) {
        state.data.username = state.proposal.contracted.cpf_cnpj
      }

      state.status.getProposal = StateStatus.succeeded
    },
    [getProposal.rejected]: (state, action) => {
      state.errMessage = action.error.message ?? "Teste"
      state.status.getProposal = StateStatus.failed
    },
    // ---------
    [getNfse.pending]: (state) => {
      state.status.getNfse = StateStatus.loading
    },
    [getNfse.fulfilled]: (state, action) => {
      state.nfse = action.payload
      state.nfse.contracted = state.nfse.proposal.contracted
      state.nfse.contractor = state.nfse.proposal.contractor
      state.status.getNfse = StateStatus.succeeded
    },
    [getNfse.rejected]: (state, action) => {
      state.errMessage = action.error.message ?? "Erro ao buscar nota!"
      state.status.getNfse = StateStatus.failed
    },
    // ---------
    [getEmitter.pending]: (state) => {
      state.status.getEmitter = StateStatus.loading
    },
    [getEmitter.fulfilled]: (state, action) => {
      state.emitter = action.payload
      state.status.getEmitter = StateStatus.succeeded
    },
    [getEmitter.rejected]: (state, action) => {
      state.errMessage = action.error.message ?? "Erro!"
      state.status.getEmitter = StateStatus.failed
    },
    // ---------
    [loginMillionNote.pending]: (state) => {
      state.status.loginMillionNote = StateStatus.loading
    },
    [loginMillionNote.fulfilled]: (state, action) => {
      state.user = action.payload
      state.data.password = action.payload.password
      state.status.loginMillionNote = StateStatus.succeeded
    },
    [loginMillionNote.rejected]: (state, action) => {
      state.errMessage = action.error.message ?? "Usuário inválido!"
      state.status.loginMillionNote = StateStatus.failed
    },
    // ---------
    [getProposals.pending]: (state) => {
      state.status.getProposals = StateStatus.loading
    },
    [getProposals.fulfilled]: (state, action) => {
      state.proposals = action.payload.items
      state.status.getProposals = StateStatus.succeeded
    },
    [getProposals.rejected]: (state, action) => {
      state.errMessage =
        action.error.message ?? "Não foi possível listar as notas fiscais."
      state.status.getProposals = StateStatus.failed
    },
    // ---------
    [sendNfse.pending]: (state) => {
      state.status.sendNfse = StateStatus.loading
    },
    [sendNfse.fulfilled]: (state) => {
      state.status.sendNfse = StateStatus.succeeded
    },
    [sendNfse.rejected]: (state, action) => {
      state.errMessage = action.error.message ?? "Erro ao criar a nota!"
      state.status.sendNfse = StateStatus.failed
    },
  },
})

export const { resetStatus, updateIndex, resetAll, updateData } =
  createNfseSlice.actions

export const selectCreateNfseSlice = (state) => state.createNfse

// ->>> /create/nfse
// ->>> /upload/nfse
