import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import StateStatus from "../../utils/stateStatus"
import {
  notifications,
  readNotification,
  deleteNotification,
  deleteAllNotifications,
  unviewedNotifications,
} from "./listNotificationsService"

export const listNotifications = createAsyncThunk(
  "notifications/list",
  async ({ page, filter }) => {
    const response = await notifications(page, { filter })
    return {
      data: response.data.data,
      page,
      filter,
    }
  }
)

export const removeNotification = createAsyncThunk(
  "notifications/delete",
  async (notificationId) => {
    if (notificationId === "all") {
      const response = await deleteAllNotifications()
      return response.data
    } else {
      const response = await deleteNotification(notificationId)
      return response.data
    }
  }
)

export const getNotification = createAsyncThunk(
  "notifications/get",
  async (notificationId) => {
    const response = await readNotification(notificationId)
    return response.data
  }
)

export const checkNotifications = createAsyncThunk(
  "notifications/check",
  async () => {
    const response = await unviewedNotifications()
    return response.data
  }
)

const initialState = {
  status: {
    removeNotification: StateStatus.idle,
    listNotifications: StateStatus.idle,
    getNotification: StateStatus.idle,
    checkNotifications: StateStatus.idle,
  },
  page: 1,
  totalPages: 0,
  notifications: [],
  notification: null,
  unviewed: 0,
  errMessage: "",
}

export const listNotificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    resetNotification: (state) => {
      state.notification = null
    },
  },
  extraReducers: {
    [listNotifications.pending]: (state, action) => {
      state.page = action.meta.arg.page
      state.status.listNotifications = StateStatus.loading
    },
    [listNotifications.fulfilled]: (state, action) => {
      const notifications = action.payload.data.items
      const totalPages = action.payload.data.total_pages
      const page = action.payload.page

      if (page === 1) {
        state.notifications = notifications
      } else {
        state.notifications = [...state.notifications, ...notifications]
      }

      state.page = page
      state.totalPages = totalPages
      state.status.listNotifications = StateStatus.succeeded
    },
    [listNotifications.rejected]: (state, action) => {
      state.page = action.meta.arg.page - 1
      state.status.listNotifications = StateStatus.failed
      state.errMessage = action.errMessage
    },
    [removeNotification.pending]: (state, action) => {
      state.status.removeNotification = StateStatus.loading
    },
    [removeNotification.fulfilled]: (state, action) => {
      const id = action.meta.arg
      state.notifications = state.notifications.filter((item) => item.id !== id)

      state.status.removeNotification = StateStatus.succeeded
    },
    [removeNotification.rejected]: (state, action) => {
      state.errMessage = action.errMessage
      state.status.removeNotification = StateStatus.failed
    },
    [checkNotifications.pending]: (state, action) => {
      state.status.checkNotifications = StateStatus.loading
    },
    [checkNotifications.fulfilled]: (state, action) => {
      state.status.checkNotifications = StateStatus.succeeded
      state.unviewed = action.payload.data.count ?? 0
    },
    [checkNotifications.rejected]: (state, action) => {
      state.status.checkNotifications = StateStatus.failed
      state.errMessage = action.errMessage
    },
    [getNotification.pending]: (state, action) => {
      state.status.getNotification = StateStatus.loading
    },
    [getNotification.fulfilled]: (state, action) => {
      state.status.getNotification = StateStatus.succeeded
      state.notification = action.payload.data
      state.notifications = state.notifications.map((item) => {
        return {
          ...item,
          viewed: state.notification.id === item.id ? true : item.viewed,
        }
      })
    },
    [getNotification.rejected]: (state, action) => {
      state.status.getNotification = StateStatus.failed
      state.errMessage = action.errMessage
    },
  },
})

export const { resetNotification } = listNotificationsSlice.actions
export const selectNotificationsState = (state) => state.notifications
