import React from "react"
import PropTypes from "prop-types"
//
import "./styles.scss"
import { Progress } from "antd"

const StepsStatusBar = ({ steps, active, maxSteps, isEdit }) => {
  const step = Array.from(steps).filter((x) => x.step === active)[0]

  return (
    <div className={`steps-status ${isEdit ? "steps-status-edit" : ""}`}>
      <div className="normal-14-500">{step?.label}</div>
      <div className="normal-12" style={{ opacity: active === 0 ? 0 : 1 }}>
        {active === 1
          ? `${active} etapa de ${maxSteps}`
          : `${active} etapas de ${maxSteps}`}
      </div>
      <Progress
        percent={(active / maxSteps) * 100}
        size="small"
        showInfo={false}
      />
    </div>
  )
}

StepsStatusBar.defaultProps = {
  isEdit: false,
}

StepsStatusBar.propTypes = {
  steps: PropTypes.array,
  isEdit: PropTypes.bool,
  maxSteps: PropTypes.number,
  active: PropTypes.number,
}

export default StepsStatusBar
