import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams, useHistory } from "react-router-dom"
import ProposalDetails from "../../common/components/ProposalDetails"
import StateStatus from "../../utils/stateStatus"
import ShareProposalModal from "../../common/components/ShareProposalModal"
import Loading from "../../common/components/Loading"
// img
import approvedProposalImg from "../../common/assets/images/propostas-aprovadas.png"
//
import {
  selectProposalViewState,
  createContract,
  downloadDefaultContract,
  downloadContract,
  invoiceUpload,
  getProposal,
  proposalReview,
  proposalFulfill,
  resetStepsState,
  closeContract,
  resetContract,
  deleteOrCancelProposal,
} from "./slice"
import "./styles.scss"
import Icons from "../../common/components/Icons"
import BaseModal from "../../common/components/Modal"
import { updateMode } from "../dashboard/DashboardSlice"
import SignersModal from "../SignersModal"
import { selectSignersState } from "../SignersModal/slice"
import { BlueButton } from "../../common/components/Button"
import { useQuery } from "../../utils/query"
import { logout } from "../login/LoginSlice"
import {
  resendConfirmAccountEmail,
  resetResendConfirmAccountEmail,
  selectConfirmAccountState,
} from "../confirmAccount/confirmAccountSlice"
import {
  getProfile,
  selectProfileData,
} from "../configurations/configurationsSlice"

const ProposalView = () => {
  const { id } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const query = useQuery()
  // const configState = useSelector(selectConfigState)
  const proposalViewState = useSelector(selectProposalViewState)
  const profileData = useSelector(selectProfileData)
  const signersState = useSelector(selectSignersState)
  const confirmAccount = useSelector(selectConfirmAccountState)
  const { proposal } = proposalViewState.data
  const [modal, updateModal] = useState({})

  useEffect(() => {
    updateModal({})
    dispatch(resetStepsState())
    dispatch(getProposal(id))
    dispatch(getProfile())
    document.body.style.overflow = "auto"
  }, [id])

  useEffect(() => {
    if (
      proposalViewState.status.getProposal === StateStatus.succeeded &&
      id === proposal?.id
    ) {
      if (query.get("role") && query.get("role") !== proposal.role) {
        // Evita o erro de loop quando usuario clica sobre link no email
        // e esta com a sessão de usuario de outro papel na proposta.
        dispatch(logout()).then(() => {
          history.replace({
            pathname: "/",
            search: `?redirect_url=/dashboard/proposal/${id}`,
          })
        })
      } else {
        dispatch(
          updateMode({ mode: proposal.role.toLowerCase(), redirect: false })
        )
      }
    }
  }, [proposalViewState.status.getProposal])

  useEffect(() => {
    if (proposalViewState.status.proposalReview === StateStatus.succeeded) {
      dispatch(getProposal(id))
      dispatch(resetStepsState())

      if (proposal.status === "accepted") {
        const email =
          proposal.role === "CONTRACTOR"
            ? proposal.contractor.email
            : proposal.contracted.email

        updateModal({
          name: "general",
          type: "success",
          title: "Proposta aceita!",
          message: `Aguarde o contrato ser enviado, quando disponível 
          ele será enviado para o e-mail ${email}.`,
        })
      } else if (proposal.status === "refused") {
        updateModal({
          name: "general",
          title: "Proposta recusada!",
          message: "Você recusou a proposta com sucesso.",
        })
      }
    }

    if (proposalViewState.status.proposalReview === StateStatus.failed) {
      updateModal({
        name: "general",
        title: "Erro!",
        message: proposalViewState.errMessage,
      })
      dispatch(resetStepsState())
    }
  }, [proposalViewState.status.proposalReview])

  useEffect(() => {
    if (proposalViewState.status.createContract === StateStatus.succeeded) {
      dispatch(resetStepsState())
      dispatch(getProposal(id))
    }

    if (proposalViewState.status.createContract === StateStatus.failed) {
      updateModal({ name: "invalid-contract" })
      dispatch(resetStepsState())
    }
  }, [proposalViewState.status.createContract])

  useEffect(() => {
    if (proposalViewState.status.proposalFulfill === StateStatus.succeeded) {
      dispatch(resetStepsState())
      dispatch(getProposal(id))
    }

    if (proposalViewState.status.proposalFulfill === StateStatus.failed) {
      updateModal({
        name: "general",
        type: "error",
        title: "Erro ao finalizar contrato",
        message:
          "Não foi possível finalizar a proposta no momento, tente novamente mais tarde.",
      })
      dispatch(resetStepsState())
    }
  }, [proposalViewState.status.proposalFulfill])

  useEffect(() => {
    if (proposalViewState.status.closeContract === StateStatus.succeeded) {
      dispatch(resetStepsState())
      dispatch(getProposal(id))
    }

    if (proposalViewState.status.closeContract === StateStatus.failed) {
      updateModal({
        name: "general",
        type: "error",
        title: "Erro ao enviar contrato",
        message:
          "Não foi possível enviar a contrato no momento, tente novamente mais tarde.",
      })
      dispatch(resetStepsState())
    }
  }, [proposalViewState.status.closeContract])

  useEffect(() => {
    if (proposalViewState.status.resetContract === StateStatus.succeeded) {
      dispatch(resetStepsState())
      dispatch(getProposal(id))
    }

    if (proposalViewState.status.resetContract === StateStatus.failed) {
      updateModal({
        name: "general",
        type: "error",
        title: "Erro ao anular contrato",
        message:
          "Não foi possível usar outro contrato no momento, tente novamente mais tarde.",
      })
      dispatch(resetStepsState())
    }
  }, [proposalViewState.status.resetContract])

  useEffect(() => {
    if (
      proposalViewState.status.deleteOrCancelProposal === StateStatus.succeeded
    ) {
      dispatch(resetStepsState())
      if (proposal.status === "draft") {
        history.replace("/dashboard/proposals")
      } else {
        dispatch(getProposal(id))
      }
    }
    if (
      proposalViewState.status.deleteOrCancelProposal === StateStatus.failed
    ) {
      updateModal({
        name: "general",
        type: "error",
        title: `Erro ao ${
          proposal.status === "draft" ? "excluir" : "cancelar"
        } a proposta!`,
        message: `Não foi possível ${
          proposal.status === "draft" ? "excluir" : "cancelar"
        } 
        a proposta no momento, tente novamente mais tarde.`,
      })
      dispatch(resetStepsState())
    }
  }, [proposalViewState.status.deleteOrCancelProposal])

  return (
    <div className="proposal-view-page">
      {proposalViewState.status.getProposal === StateStatus.loading &&
      proposal?.id !== id ? ( // Exibir loading só no primeiro GET
        <div style={{ height: "60vh" }}>
          <Loading className="medium dark center" />
        </div>
      ) : proposal?.id ? (
        <div className="proposal-view-content">
          <div
            className="back-page-btn"
            onClick={() => history.replace("/dashboard/proposals")}
          >
            <Icons icon="arrow-right" />
            <span>Voltar</span>
          </div>
          <ProposalDetails
            proposal={proposal}
            profileData={profileData}
            isLoading={
              proposalViewState.status.createContract === StateStatus.loading ||
              proposalViewState.status.proposalReview === StateStatus.loading ||
              proposalViewState.status.closeContract === StateStatus.loading
            }
            isGetContractLoading={
              signersState.status.getContractInfo === StateStatus.loading
            }
            onShare={() => updateModal({ name: "share" })}
            onAcceptProposal={(values) => {
              let counterparty = {}
              if (proposal.created_by === "CONTRACTOR") {
                counterparty = proposal.contracted
              } else {
                counterparty = proposal.contractor
              }

              const data = {
                counterparty: {
                  cpf_cnpj: values.cpf_cnpj,
                  company_name: counterparty.company_name,
                  name: counterparty.name,
                  email: values.email,
                  phone: values.phone,
                  sector: counterparty.sector,
                  address: counterparty.address,
                },
                action: "accept",
              }

              if (proposal.created_by === "CONTRACTOR") {
                if (counterparty.bank_account) {
                  const bankAcc = counterparty.bank_account
                  data.counterparty.bank_account = {
                    bank_code: bankAcc.bank_code,
                    account_type: bankAcc.account_type,
                    agency: bankAcc.agency,
                    account_number: bankAcc.account_number,
                    pix_key: bankAcc.pix_key,
                    owner_name: bankAcc.owner_name,
                    cpf_cnpj: bankAcc.cpf_cnpj,
                  }
                }
                data.counterparty.social_medias = counterparty.social_medias
              }

              dispatch(proposalReview({ proposalId: id, data }))
            }}
            onRefuseProposal={(values) => {
              const data = {
                review_message: values.review_message,
                action: "refuse",
              }

              dispatch(proposalReview({ proposalId: id, data }))
            }}
            onDownloadDefaultContract={() => {
              dispatch(downloadDefaultContract(proposal.id))
            }}
            onDownloadContract={(doc) => {
              dispatch(
                downloadContract({ contractId: proposal.contract_id, doc })
              )
            }}
            onSendContract={(file) => {
              dispatch(createContract({ proposalId: proposal.id, file }))
            }}
            isResetContratLoading={
              proposalViewState.status.resetContract === StateStatus.loading
            }
            onResetContract={() => {
              dispatch(resetContract(proposal.contract_id))
            }}
            onCloseContract={() => {
              dispatch(closeContract(proposal.contract_id))
            }}
            isFinalizeLoading={
              proposalViewState.status.proposalFulfill === StateStatus.loading
            }
            onFinalizeProposal={() => {
              dispatch(proposalFulfill(id))
            }}
            isCancelLoading={
              proposalViewState.status.deleteOrCancelProposal ===
              StateStatus.loading
            }
            onCancelProposal={() => {
              dispatch(deleteOrCancelProposal(id))
            }}
            onUploadNF={(file) => {
              dispatch(
                invoiceUpload({
                  proposalId: proposal.id,
                  file,
                })
              ).then(() => {
                dispatch(getProposal(proposal.id))
              })
            }}
            onAddSigners={() => updateModal({ name: "add-signers" })}
          />
        </div>
      ) : !proposalViewState.isAccountConfirmed ? (
        <div className="proposals-empty-card">
          <img
            src={approvedProposalImg}
            alt="imagem para simbolizar que a lista está vazia"
          />
          <>
            <p className="message">
              Você precisa confirmar seu e-mail para ver suas propostas.
            </p>

            <BlueButton
              onClick={() => dispatch(resendConfirmAccountEmail())}
              // style={{width: "100%"}}
              isLoading={
                confirmAccount.status.resendConfirmAccountEmail ===
                StateStatus.loading
              }
              style={{ width: "450px" }}
            >
              Receber e-mail de confirmação
            </BlueButton>
          </>
        </div>
      ) : null}
      {proposal?.contract_id && (
        <SignersModal
          contractId={proposal.contract_id}
          isVisible={modal.name === "add-signers"}
          onClose={() => {
            updateModal({})
            dispatch(resetStepsState())
            dispatch(getProposal(id))
          }}
        />
      )}
      <ShareProposalModal
        isVisible={modal.name === "share"}
        proposal={proposal}
        onClose={() => updateModal({})}
      />
      <BaseModal
        isVisible={modal.name === "general"}
        type={modal.type || "general"}
        title={modal.title}
        okButtonText={modal.okButtonText || "Fechar janela"}
        onClose={() => {
          if (modal.redirect) history.push(modal.redirect)
          updateModal({})
        }}
        onOk={() => {
          if (modal.redirect) history.push(modal.redirect)
          updateModal({})
        }}
      >
        {modal.message}
      </BaseModal>
      <BaseModal
        isVisible={modal.name === "invalid-contract"}
        title={"Não conseguimos ler o seu contrato"}
        okButtonText="Fechar"
        onClose={() => {
          if (modal.redirect) history.push(modal.redirect)
          updateModal({})
        }}
        onOk={() => {
          if (modal.redirect) history.push(modal.redirect)
          updateModal({})
        }}
        isClosable={false}
      >
        <div className="normal-14-500">O que pode ter acontecido:</div>
        <br />
        <div>
          Verifique se o arquivo está protegido por senha e caso deseje
          continuar retire-a e tente novamente.
        </div>
        {modal.message}
      </BaseModal>
      <BaseModal
        isVisible={
          confirmAccount.status.resendConfirmAccountEmail ===
            StateStatus.succeeded ||
          confirmAccount.status.resendConfirmAccountEmail === StateStatus.failed
        }
        title={
          confirmAccount.status.resendConfirmAccountEmail ===
          StateStatus.succeeded
            ? "E-mail enviado"
            : "Erro ao enviar e-mail"
        }
        okButtonText="Fechar janela"
        onOk={() => {
          dispatch(resetResendConfirmAccountEmail())
          history.push("/dashboard")
        }}
        onClose={() => {
          dispatch(resetResendConfirmAccountEmail())
          history.push("/dashboard")
        }}
      >
        {confirmAccount.status.resendConfirmAccountEmail ===
        StateStatus.succeeded
          ? "Lembre-se de verificar a caixa de spam ou lixo eletrônico. Se você ainda não recebeu o e-mail aguarde alguns minutos antes de tentar novamente."
          : "Tente novamente mais tade."}
      </BaseModal>
    </div>
  )
}

export default ProposalView
