import axios from "axios"
import apiRoutes, { baseUrl } from "../../service/apiRoutes"

export const uploadInvoice = (proposalId, data) =>
  axios.post(`${baseUrl}${apiRoutes.proposal}/${proposalId}/nf/file`, data)

export const getInvoiceInfo = (proposalId) =>
  axios.get(`${baseUrl}${apiRoutes.proposal}/${proposalId}/nf`)

// export const downloadInvoice = (proposalId) =>
//   axios.get(
//     `${baseUrl}${apiRoutes.proposal}/${proposalId}/nf/file`, {
//     responseType: "arraybuffer",
//   })
