import api from "../../service/api"
import apiRoutes from "../../service/apiRoutes"

export const login = (data) =>
  api.post(`${apiRoutes.nfse}/emitter/credential`, data) // million note

export const manuallyCreate = (data) => api.post(apiRoutes.nfse, data)

export const uploadCreate = (data) => api.post(`${apiRoutes.nfse}/file`, data)

export const get = (id) => api.get(`${apiRoutes.nfse}/${id}`)

export const proposals = (data) =>
  api.get(`${apiRoutes.nfse}${apiRoutes.proposals}`, { params: data })

export const emitter = (data) =>
  api.get(`${apiRoutes.nfse}/emitter`, { params: data }) // million note
