import React, { useState, useEffect, useCallback } from "react"
import { Select, TimePicker as TimePickerAntd } from "antd"
import PropTypes from "prop-types"
import moment from "moment"
import "./styles.scss"
import { formatDate } from "../../../utils/formaters"

const TimePicker = ({ time, format, onChange, ...props }) => {
  return (
    <TimePickerAntd
      className="time-picker-proposal"
      defaultValue={time}
      format={format}
      onChange={onChange}
      {...props}
    />
  )
}

const TimeSelect = ({
  value,
  format,
  onChange,
  selectedStartTime,
  ...props
}) => {
  const [dates, setDates] = useState([])
  const [options, setOptions] = useState([])

  useEffect(() => {
    const diffMin = 15
    let date = new Date()
    const day = date.getDay()
    date.setHours(0, 0, 0)

    const dts = []
    while (date.getDay() === day) {
      const nextDate = new Date(date.getTime() + diffMin * 60000)

      if (!selectedStartTime || date.getTime() > selectedStartTime.getTime()) {
        dts.push(date)
      }
      date = nextDate
    }

    setDates(dts)
    setOptions(dts)
  }, [selectedStartTime])

  const onSearch = useCallback(
    (search) => {
      const dts = []
      dates.forEach((date) => {
        const formatted = formatDate(date, format).replace(/\D/g, "")
        if (formatted.startsWith(search.replace(/\D/g, ""))) {
          dts.push(date)
        }
      })

      if (dts.length === 0) {
        setOptions(dates)
      } else {
        setOptions(dts)
      }
    },
    [dates]
  )

  return (
    <Select
      value={value !== null ? formatDate(value, format) : "00:00"}
      style={{ minWidth: "100px" }}
      notFoundContent={null}
      filterOption={false}
      allowClear={false}
      defaultValue={value !== null ? formatDate(value, format) : "00:00"}
      showSearch
      onSearch={onSearch}
      onChange={useCallback((timestamp) => {
        let selected = new Date(timestamp)

        if (value) {
          value = new Date(value.getTime())
          value.setHours(
            selected.getHours(),
            selected.getMinutes(),
            selected.getSeconds()
          )
          selected = value
        }
        onChange(selected)
      }, [])}
      {...props}
    >
      {options.map((item, index) => (
        <Select.Option value={item.getTime()} key={index}>
          {formatDate(item, format)}
        </Select.Option>
      ))}
    </Select>
  )
}

TimePicker.defaultProps = {
  time: moment(),
  format: "HH:mm",
}

TimePicker.propTypes = {
  time: PropTypes.object,
  onChange: PropTypes.func,
  format: PropTypes.string,
}

TimeSelect.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  format: PropTypes.string,
  selectedStartTime: PropTypes.object,
}

export { TimePicker, TimeSelect }
