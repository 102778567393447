import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import {
  bytesToDownloadLink,
  getBytesAndDownloadFile,
  urlToNewTabLink,
} from "../../utils/files"
import StateStatus from "../../utils/stateStatus"
import {
  proposals,
  downloadProposalFile,
  statistics,
  proposalduplicate,
  proposalDelete,
  contractResend,
  getDefaultContract,
  getContractInfo,
  getProposalTags,
  putProposalTags,
  getTags,
  postTag,
  deleteTag,
} from "./listProposalsService"

// GET

export const listProposals = createAsyncThunk(
  "proposals/list",
  async ({ page, filter, status, role, filters = {} }) => {
    const response = await proposals(page, {
      filter,
      group_status: status,
      role,
      ...(filters || {}),
    })
    return {
      data: response.data.data,
      page,
      filter,
    }
  }
)

// ACTIONS

export const duplicateProposal = createAsyncThunk(
  "proposals/duplicate",
  async ({ id, data }) => {
    const response = await proposalduplicate(id, data)
    return response.data
  }
)

export const deleteProposal = createAsyncThunk(
  "proposals/delete",
  async (id) => {
    const response = await proposalDelete(id)
    return response.data
  }
)

export const getStatistics = createAsyncThunk(
  "counters/statistics",
  async ({ role }) => {
    const response = await statistics({ role })
    return response.data
  }
)

export const showAttachment = createAsyncThunk(
  "proposals/show_attachment",
  async (file) => {
    const { key, filename, mime_type: mime } = file
    const response = await downloadProposalFile(key)
    const link = bytesToDownloadLink(response.data, mime, filename)
    document.body.appendChild(link)
    link.click()
    return true
  }
)

export const resendContract = createAsyncThunk(
  "proposals/resend_contract",
  async (contractId) => {
    const response = await contractResend(contractId)
    return response.data
  }
)

export const downloadDefaultContract = createAsyncThunk(
  "proposals/download_default_contract",
  async (proposalId) => {
    const response = await getDefaultContract(proposalId)
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", "contrato.doc")
    link.setAttribute("target", "__blank")
    document.body.appendChild(link)
    link.click()
    link.remove()

    return response.data
  }
)

export const downloadContract = createAsyncThunk(
  "proposals/download_contract",
  async ({ contractId, doc, isShowContract }) => {
    const response = await getContractInfo(contractId)

    let url
    let name

    if (doc === "original") {
      url = response.data.data.original_file_public_url
      name = "contrato-original.pdf"
    } else {
      url = response.data.data.signed_file_public_url
      name = "contrato-assinado.pdf"
    }

    let link
    if (!isShowContract) {
      link = await getBytesAndDownloadFile(url, "application/pdf", name)
    } else {
      link = urlToNewTabLink(url, name)
    }
    link.click()

    return response.data
  }
)

// TAGS
export const allTags = createAsyncThunk("all-tags", async () => {
  const response = await getTags()

  return response.data
})
export const addTag = createAsyncThunk("add-tag", async (data) => {
  const response = await postTag(data)

  return response.data
})

export const removeTag = createAsyncThunk("remove-tag", async (tagId) => {
  const response = await deleteTag(tagId)

  return response.data
})

// PROPOSAL TAGS
export const listProposalTags = createAsyncThunk(
  "proposals/get-proposal-tags",
  async (proposalId) => {
    const response = await getProposalTags(proposalId)

    return response.data
  }
)

export const updateProposalTags = createAsyncThunk(
  "proposals/update-proposal-tags",
  async ({ id, tags }) => {
    const response = await putProposalTags(id, { tags })

    return response.data
  }
)

const initData = {
  status: {
    listProposals: StateStatus.idle,
  },
  page: 1,
  totalPages: 0,
  count: 0,
  filter: "",
  filters: {},
  proposals: null,
  statistics: { count: 0, total_value: 0 },
}

const initialState = {
  waiting: { ...initData, name: "waiting" },
  accepted: { ...initData, name: "accepted" },
  expired: { ...initData, name: "expired" },
  status: {
    showAttachment: StateStatus.idle,
    duplicateProposal: StateStatus.idle,
    deleteProposal: StateStatus.idle,
    resendContract: StateStatus.idle,
    downloadDefaultContract: StateStatus.idle,
    downloadContract: StateStatus.idle,
    allTags: StateStatus.idle,
    addTag: StateStatus.idle,
    removeTag: StateStatus.idle,
    // updateProposalTags: StateStatus.idle,
    // listProposalTags: StateStatus.idle,
    getStatistics: StateStatus.idle,
  },
  selectedFilters: {
    tags: [],
    owner: null,
    value: null,
    status: null,
    createIn: null,
  },
  emailsContract: [],
  tab: "accepted",
  isAccountConfirmed: true,
  tags: [],
  errMessage: null,
}

export const listProposalsSlice = createSlice({
  name: "proposals",
  initialState,
  reducers: {
    resetListProposalsStatus: (state) => {
      state[state.tab].status.listProposals = StateStatus.idle
    },
    resetDeleteTagStatus: (state) => {
      state.status.removeTag = StateStatus.idle
    },
    resetError: (state) => {
      state.errMessage = null
    },
    resetDelete: (state) => {
      state[state.tab].status.deleteProposal = StateStatus.idle
    },
    cleanSelectedFilters: (state) => {
      state.waiting = { ...initData, name: "waiting" }
      state.accepted = { ...initData, name: "accepted" }
      state.expired = { ...initData, name: "expired" }
      state.selectedFilters = initialState.selectedFilters
      state.filters = initialState.filters
      state.tab = "accepted"
    },
    saveSelectedFilters: (state, action) => {
      const selectedFilters = { ...state.selectedFilters }
      let textFilter = state[state.tab].filter || ""

      if (action.payload?.filter !== undefined) {
        textFilter = action.payload?.filter || ""
      }

      if (action.payload?.tags !== undefined) {
        selectedFilters.tags = action.payload?.tags || []
      }

      if (action.payload?.owner !== undefined) {
        selectedFilters.owner = action.payload?.owner
      }

      if (action.payload?.value !== undefined) {
        selectedFilters.value = action.payload?.value
      }

      if (action.payload?.status !== undefined) {
        selectedFilters.status = action.payload?.status
      }

      if (action.payload?.createdIn !== undefined) {
        selectedFilters.createdIn = action.payload?.createdIn
      }

      const sortBy = []
      const tagIds = selectedFilters.tags.map((item) => item.id)

      if (selectedFilters.value) sortBy.push(selectedFilters.value)
      if (selectedFilters.createdIn) sortBy.push(selectedFilters.createdIn)

      const filters = {
        status: selectedFilters.status,
        created_by: selectedFilters.owner,
        tags: tagIds.length > 0 ? tagIds.toString() : null,
        sort_by:
          selectedFilters.value || selectedFilters.createdIn
            ? sortBy.toString()
            : null,
      }

      state.selectedFilters = selectedFilters
      const tabInitData = { ...initData, filters, filter: textFilter }

      state.waiting = {
        ...tabInitData,
        name: "waiting",
        statistics: state.waiting.statistics,
      }
      state.expired = {
        ...tabInitData,
        name: "expired",
        statistics: state.expired.statistics,
      }
      state.accepted = {
        ...tabInitData,
        name: "accepted",
        statistics: state.accepted.statistics,
      }
    },
    resetResendContract: (state) => {
      state.status.resendContract = StateStatus.idle
      state.emailsContract = []
    },
    setMessagesAsViewed: (state, action) => {
      state[state.tab].proposals = state[state.tab].proposals.map((p) => {
        if (p.id === action.payload.proposalId) {
          return { ...p, count_unviewed_messages: 0 }
        } else return p
      })
    },
    changeSelectedTab: (state, action) => {
      if (typeof action.payload === "string") {
        if (["accepted", "waiting", "expired"].includes(action.payload)) {
          state.tab = state[action.payload].name
        }
      } else {
        switch (action.payload) {
          case 0:
            state.tab = state.accepted.name
            break
          case 1:
            state.tab = state.waiting.name
            break
          case 2:
            state.tab = state.expired.name
            break
        }
      }
    },
    searchProposals: (state, action) => {
      state[state.tab].proposals = []
      state[state.tab].page = 0
      state[state.tab].filter = action.payload
      state[state.tab].status.listProposals = StateStatus.idle
    },
  },
  extraReducers: {
    [listProposals.pending]: (state, action) => {
      const tab = action.meta.arg.status
      const page = action.meta.arg.page
      const filters = action.meta.arg.filters
      const filter = action.meta.arg.filter

      if ([0, 1].includes(page)) {
        state[tab].proposals = []
      }

      state[tab].page = page
      state[tab].filters = filters
      state[tab].filter = filter
      state.isAccountConfirmed = true
      state[tab].status.listProposals = StateStatus.loading
    },
    [listProposals.fulfilled]: (state, action) => {
      const tab = action.meta.arg.status
      const proposals = action.payload.data.items
      const totalPages = action.payload.data.total_pages
      const filter = action.payload.filter
      const page = action.payload.page
      const count = action.payload.data.count

      if (page === 1) {
        state[tab].proposals = proposals
      } else {
        state[tab].proposals = [...state[tab].proposals, ...proposals]
      }

      state[tab].filter = filter
      state[tab].totalPages = totalPages
      state[tab].count = count
      state[tab].page = page
      state[tab].status.listProposals = StateStatus.succeeded
    },
    [listProposals.rejected]: (state, action) => {
      const tab = action.meta.arg.status
      state[tab].page = action.meta.arg.page - 1
      state[tab].status.listProposals = StateStatus.failed

      if (action.error.code === "NOT_CONFIRMED_ACCOUNT") {
        state.isAccountConfirmed = false
      } else {
        state.isAccountConfirmed = true
        state.errMessage = "Não foi possível listar propostas."
      }
    },

    // getStatistics
    [getStatistics.pending]: (state) => {
      state.status.getStatistics = StateStatus.loading
    },
    [getStatistics.fulfilled]: (state, action) => {
      state.status.getStatistics = StateStatus.succeeded
      state.waiting.statistics = action.payload.data.waiting
      state.accepted.statistics = action.payload.data.accepted
      state.expired.statistics = action.payload.data.expired
    },
    [getStatistics.rejected]: (state, action) => {
      state.status.getStatistics = StateStatus.failed
      state.errMessage = action.error.message
    },

    // showAttachment
    [showAttachment.pending]: (state) => {
      state.status.showAttachment = StateStatus.loading
    },
    [showAttachment.fulfilled]: (state, action) => {
      state.proposal = action.payload.data
      state.status.showAttachment = StateStatus.succeeded
    },
    [showAttachment.rejected]: (state, action) => {
      state.status.showAttachment = StateStatus.failed
      state.errMessage = "Não foi possível exibir anexo da proposta."
    },
    // duplicateProposal
    [duplicateProposal.pending]: (state) => {
      state.status.duplicateProposal = StateStatus.loading
    },
    [duplicateProposal.fulfilled]: (state, action) => {
      state.status.duplicateProposal = StateStatus.succeeded
      state[state.tab].status.listProposals = StateStatus.idle
    },
    [duplicateProposal.rejected]: (state, action) => {
      state.status.duplicateProposal = StateStatus.failed
      state.errMessage = "Não foi possível duplicar proposta."
    },
    // deleteProposal
    [deleteProposal.pending]: (state) => {
      state.status.deleteProposal = StateStatus.loading
    },
    [deleteProposal.fulfilled]: (state, action) => {
      state.status.deleteProposal = StateStatus.succeeded
      state[state.tab].status.listProposals = StateStatus.idle
    },
    [deleteProposal.rejected]: (state, action) => {
      state.status.deleteProposal = StateStatus.failed
      state.errMessage = action.error.message
    },
    // resendContract
    [resendContract.pending]: (state) => {
      state.status.resendContract = StateStatus.loading
    },
    [resendContract.fulfilled]: (state, action) => {
      state.status.resendContract = StateStatus.succeeded
      state.emailsContract = action.payload.data.emails
    },
    [resendContract.rejected]: (state, action) => {
      state.status.resendContract = StateStatus.failed
      state.errMessage = action.error.message
    },
    // downloadDefaultContract
    [downloadDefaultContract.pending]: (state) => {
      state.status.downloadDefaultContract = StateStatus.loading
    },
    [downloadDefaultContract.fulfilled]: (state, action) => {
      state.status.downloadDefaultContract = StateStatus.succeeded
    },
    [downloadDefaultContract.rejected]: (state, action) => {
      state.status.downloadDefaultContract = StateStatus.failed
      state.errMessage = action.error.message
    },
    // downloadContract
    [downloadContract.pending]: (state) => {
      state.status.downloadContract = StateStatus.loading
    },
    [downloadContract.fulfilled]: (state, action) => {
      state.status.downloadContract = StateStatus.succeeded
    },
    [downloadContract.rejected]: (state, action) => {
      state.status.downloadContract = StateStatus.failed
      // state.errMessage = action.error.message
      state.errMessage = "Não foi possível fazer o download do contrato."
    },
    // get all tags
    [allTags.pending]: (state) => {
      state.status.allTags = StateStatus.loading
    },
    [allTags.fulfilled]: (state, action) => {
      state.tags = action.payload.data.items
      state.status.allTags = StateStatus.succeeded
    },
    [allTags.rejected]: (state, action) => {
      state.errMessage = action.error.message
      state.status.allTags = StateStatus.failed
    },
    // add tag
    [addTag.pending]: (state) => {
      state.status.addTag = StateStatus.loading
    },
    [addTag.fulfilled]: (state, action) => {
      state.status.addTag = StateStatus.succeeded
    },
    [addTag.rejected]: (state, action) => {
      state.errMessage = action.error.message
      state.status.addTag = StateStatus.failed
    },
    // remove tag
    [removeTag.pending]: (state) => {
      state.status.removeTag = StateStatus.loading
    },
    [removeTag.fulfilled]: (state, action) => {
      state.tags = action.payload.data.items

      state.status.removeTag = StateStatus.succeeded
    },
    [removeTag.rejected]: (state, action) => {
      state.errMessage = action.error.message

      state.status.removeTag = StateStatus.failed
    },
  },
})

export const {
  resetListProposalsStatus,
  changeSelectedTab,
  searchProposals,
  resetError,
  resetDelete,
  resetResendContract,
  resetDeleteTagStatus,
  saveSelectedFilters,
  cleanSelectedFilters,
  setMessagesAsViewed,
} = listProposalsSlice.actions

export const selectListProposalsState = (state) => state.proposals
