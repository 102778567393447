import React from "react"

export const HistoryIcon = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.925 21C9.44167 21 7.33333 20.1208 5.6 18.3625C3.86667 16.6042 3 14.475 3 11.975H4.5C4.5 14.0583 5.21667 15.8333 6.65 17.3C8.08333 18.7667 9.84167 19.5 11.925 19.5C14.0417 19.5 15.8333 18.7583 17.3 17.275C18.7667 15.7917 19.5 13.9917 19.5 11.875C19.5 9.80833 18.7583 8.0625 17.275 6.6375C15.7917 5.2125 14.0083 4.5 11.925 4.5C10.7917 4.5 9.72917 4.75833 8.7375 5.275C7.74583 5.79167 6.88333 6.475 6.15 7.325H8.775V8.825H3.55V3.625H5.05V6.275C5.91667 5.25833 6.94583 4.45833 8.1375 3.875C9.32917 3.29167 10.5917 3 11.925 3C13.175 3 14.35 3.23333 15.45 3.7C16.55 4.16667 17.5125 4.80417 18.3375 5.6125C19.1625 6.42083 19.8125 7.36667 20.2875 8.45C20.7625 9.53333 21 10.7 21 11.95C21 13.2 20.7625 14.375 20.2875 15.475C19.8125 16.575 19.1625 17.5333 18.3375 18.35C17.5125 19.1667 16.55 19.8125 15.45 20.2875C14.35 20.7625 13.175 21 11.925 21ZM15.125 16.075L11.275 12.275V6.925H12.775V11.65L16.2 15L15.125 16.075Z"
          fill="#002999"
        />
      </svg>
    </span>
  )
}
