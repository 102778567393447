import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { LogoBar } from "../topbar/Topbar"
import RoudedBox from "../../common/components/RoudedBox"
import "./styles.scss"
import { Row, Col } from "antd"
import { useDispatch, useSelector } from "react-redux"
import {
  invoiceUpload,
  proposalGet,
  resetErrorMessage,
  resetUploadState,
  selectInvoiceUploadState,
} from "./invoiceUploadSlice"
import StateStatus from "../../utils/stateStatus"
import BaseModal from "../../common/components/Modal"
import ErrorMessage from "../../common/components/ErrorMessage"
import { beautifyCpfCnpj, numberToReal } from "../../utils/formaters"
import Loading from "../../common/components/Loading"
import Icons from "../../common/components/Icons"
import Avatar from "../../common/components/avatar"
import ProposalStatus from "../../common/components/ProposalStatus"

export const InvoiceUpload = () => {
  const { id } = useParams()
  const [showModal, updateShowModal] = useState(false)
  const [modal, updateModal] = useState({ title: "", message: "" })
  const [fileSelected, updateFileSelected] = useState({})
  const invoiceState = useSelector(selectInvoiceUploadState)
  const proposal = invoiceState.proposal
  const [owner, updateOwner] = useState()
  const [counterparty, updateCounterparty] = useState()
  const dispatch = useDispatch()

  const handleChooseFile = (event) => {
    try {
      const file = event.target.files[0]
      if (!file || !file.type.match(/(pdf)/)) {
        throw Error("Formato inválido, envie a Nota Fiscal em formato PDF.")
      }

      if (file.size > 5000000) {
        throw Error("Arquivo deve ser menor que 5MB")
      }

      updateFileSelected({ file })
      dispatch(invoiceUpload({ proposalId: id, file }))
    } catch (err) {
      updateFileSelected({ error: err.message })
    }
  }

  useEffect(() => {
    dispatch(proposalGet(id))
    dispatch(resetErrorMessage())
    dispatch(resetUploadState())
  }, [id])

  useEffect(() => {
    if (invoiceState.status.invoiceUpload === StateStatus.succeeded) {
      dispatch(proposalGet(id))
      dispatch(resetUploadState())
    }

    if (invoiceState.status.invoiceUpload === StateStatus.failed) {
      updateModal({
        title: "Erro!",
        message: invoiceState.errMessage,
        redirect: "/",
      })
      updateShowModal(true)
      dispatch(resetErrorMessage())
      dispatch(resetUploadState())
    }
  }, [invoiceState.status.invoiceUpload])

  useEffect(() => {
    if (invoiceState.status.proposalGet === StateStatus.succeeded) {
      if (proposal.created_by === "CONTRACTED") {
        updateCounterparty(proposal.contractor)
        updateOwner(proposal.contracted)
      } else if (proposal.created_by === "CONTRACTOR") {
        updateCounterparty(proposal.contracted)
        updateOwner(proposal.contractor)
      }
    }
  }, [invoiceState.status.proposalGet])

  return (
    <>
      <div className="body-container">
        <div className="top-bar" style={{ padding: 0 }}>
          <LogoBar />
        </div>
        <div className="row-content">
          <Row justify="center">
            {invoiceState.status.proposalGet === StateStatus.loading ? (
              <div style={{ height: "60vh" }}>
                <Loading />
              </div>
            ) : (
              <div className="proposal-invoice">
                <div className="main-content-top"></div>
                <RoudedBox className="main-content">
                  <Row
                    justify="space-between"
                    gutter={[16, 0]}
                    className="row-title row-proposal-title"
                    align="middle"
                  >
                    <Col xs={24} sm={24} md={10} lg={10} className="normal-24">
                      <span className="proposal-title not-overflow">
                        {proposal.title}
                      </span>
                    </Col>

                    <Col
                      xs={24}
                      sm={24}
                      md={6}
                      lg={6}
                      className="normal-16 proposal-status-col"
                    >
                      <ProposalStatus status={proposal.status}>
                        {proposal.status_name}
                      </ProposalStatus>
                    </Col>

                    <Col
                      xs={24}
                      sm={24}
                      md={8}
                      lg={8}
                      className="normal-16 created-at"
                    >
                      <span>
                        Data proposta:{" "}
                        {proposal.created_at
                          ? proposal.created_at.split(" ")[0]
                          : "-"}
                      </span>
                    </Col>
                  </Row>

                  {owner && (
                    <Row className="row-content proposal-profile">
                      <Col span={12}>
                        <div className="avatar">
                          <Avatar src={owner.avatar_url} />
                        </div>
                        <div className="info normal-16">
                          <div className="normal-20">{owner.name}</div>
                          <div>{owner.email}</div>
                          {owner.social_medias &&
                            owner.social_medias.map((item, index) => (
                              <div key={index}>
                                {item.plataform.initials}: {item.username}
                              </div>
                            ))}
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className="address normal-16">
                          <div style={{ marginBottom: "8px" }}>
                            <div>CNPJ: {beautifyCpfCnpj(owner.cnpj)}</div>
                            <div>Razão Social: {owner.company_name}</div>
                          </div>
                          {/* {owner.address && (
                            <>
                              <div>
                                {owner.address.street}, {owner.address.number}
                              </div>
                              <div>
                                {owner.address.city} - {owner.address.state} |
                                CEP: {owner.address.cep}
                              </div>
                            </>
                          )} */}
                        </div>
                      </Col>
                    </Row>
                  )}
                  <Row className="row-title">Valor da proposta</Row>
                  <Row className="row-content">
                    <Col span={12}>
                      <div className="label-value">
                        <label>Valor total:</label>
                        <div>
                          {proposal.total_value
                            ? numberToReal(proposal.total_value / 100)
                            : numberToReal(0)}
                        </div>
                      </div>
                      {/* <div className="label-value">
                        <label>Descrição da NF:</label>
                        <div>
                          {proposal.invoice_details
                            ? proposal.invoice_details
                            : " - "}
                        </div>
                      </div> */}
                    </Col>
                    <Col span={12}>
                      {/* <div className="label-value">
                        <label>Prazo de pagamento:</label>
                        <div>
                          {proposal.days_to_payment
                            ? `${proposal.days_to_payment} dias`
                            : " - "}
                        </div>
                      </div> */}
                    </Col>
                  </Row>
                  <Row className="row-title">
                    {proposal.created_by === "CONTRACTOR"
                      ? "Dados Contratado"
                      : "Dados Contratante"}
                  </Row>
                  {counterparty && (
                    <Row gutter={[16, 16]} className="row-content">
                      <Col span={12}>
                        <div className="label-value">
                          <label>CNPJ:</label>
                          <div>
                            {counterparty.cnpj
                              ? beautifyCpfCnpj(counterparty.cnpj)
                              : "-"}
                          </div>
                        </div>
                        <div className="label-value">
                          <label>Nome:</label>
                          <div>{counterparty.company_name}</div>
                        </div>
                      </Col>

                      <Col span={12}>
                        <div className="label-value">
                          <label>Contato:</label>
                          <div>{counterparty.name}</div>
                        </div>
                        <div className="label-value">
                          <label>E-mail:</label>
                          <div>{counterparty.email}</div>
                        </div>
                        {/* <div className="label-value">
                          <label>Telefone:</label>
                          <div>
                            {counterparty.phone
                              ? counterparty.phone
                              : "-"}
                          </div>
                        </div> */}
                      </Col>
                    </Row>
                  )}
                  <Row className="row-title">Nota Fiscal</Row>
                  <Row gutter={[8, 0]} className="row-content invoice-file">
                    {proposal.nfs && proposal.nfs.length > 0 ? (
                      proposal.nfs.map((invoice, index) => (
                        <Col key={index} className="upload-file">
                          {(index > 0 ? " / " : "") + invoice.filename}
                          <Icons
                            icon="download"
                            className="upload-icon"
                            title="Baixar"
                            onClick={() => {
                              const link = document.createElement("a")
                              link.href = invoice.link
                              link.setAttribute("download", invoice.filename)
                              link.setAttribute("target", "__blank")
                              document.body.appendChild(link)
                              link.click()
                            }}
                          />
                        </Col>
                      ))
                    ) : (
                      <div>Sem nota fiscal cadastrada</div>
                    )}
                  </Row>
                  <Row className="row-content">
                    <div className="upload-file">
                      <input
                        id="invoice"
                        type="file"
                        onChange={handleChooseFile}
                      />
                      <label htmlFor="invoice">
                        Enviar nota fiscal
                        <Icons icon="upload-circle" className="upload-icon" />
                      </label>
                      <ErrorMessage
                        text={fileSelected.error}
                        isVisible={!!fileSelected.error}
                      />
                    </div>
                  </Row>
                </RoudedBox>
              </div>
            )}
          </Row>
        </div>
      </div>
      <BaseModal
        isVisible={showModal}
        title={modal.title}
        okButtonText="Ok"
        onCancel={() => {
          updateShowModal(false)
          updateModal({})
          // history.replace("/")
        }}
        onOk={() => {
          updateShowModal(false)
          updateModal({})
          // history.replace("/")
        }}
      >
        {modal.message}
      </BaseModal>
    </>
  )
}
