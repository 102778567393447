import { Divider, Row } from "antd"
import Col from "antd/es/grid/col"
import React, { useEffect, useState } from "react"
import BaseModal from "../../../../common/components/Modal"
import RadioButton from "../../../../common/components/RadioButton"
import Icons from "../../../../common/components/Icons"
import PropTypes from "prop-types"
import "./styles.scss"
import {
  downloadContract,
  selectListProposalsState,
} from "../../listProposalsSlice"
import StateStatus from "../../../../utils/stateStatus"
import { useDispatch, useSelector } from "react-redux"

const DownloadContractModal = ({
  contractId,
  hasSignedContract,
  isShowContract,
  isVisible,
  onClose,
}) => {
  const listProposalsState = useSelector(selectListProposalsState)
  const [option, updateOption] = useState("original")
  const dispatch = useDispatch()

  useEffect(() => {
    if (listProposalsState.status.downloadContract === StateStatus.succeeded) {
      onClose()
    }
  }, [listProposalsState.status.downloadContract])

  useEffect(() => {
    updateOption("original")

    if (hasSignedContract) {
      updateOption("signed")
    }
  }, [contractId, hasSignedContract])

  return (
    <BaseModal
      className="download-contract-modal"
      isVisible={isVisible}
      title={isShowContract ? "Ver o contrato" : "Download do contrato"}
      subtitle={
        isShowContract
          ? "Para ver o contrato assinale a opção desejada e no botão ver"
          : "Para efetuar o download do contrato assinale a opção desejada e no botão download"
      }
      okButtonText={isShowContract ? "Ver" : "Download"}
      isLoading={
        listProposalsState.status.downloadContract === StateStatus.loading
      }
      onOk={() => {
        dispatch(downloadContract({ contractId, doc: option, isShowContract }))
      }}
      onCancel={onClose}
    >
      <Divider />
      <Row justify="space-between">
        <Col span={12}>
          <div className="subtitle">Documento</div>

          <div className="contract-icon-text">
            <Icons icon="contract" /> Contrato
          </div>
        </Col>
        <Col span={12}>
          <div className="subtitle">Opções</div>
          <RadioButton
            checked={option === "original"}
            value={"original"}
            onClick={() => {
              updateOption("original")
            }}
          >
            Contrato original
          </RadioButton>

          <RadioButton
            disabled={!hasSignedContract}
            checked={option === "signed"}
            value={"signed"}
            onClick={() => {
              updateOption("signed")
            }}
          >
            Contrato assinado
          </RadioButton>
        </Col>
      </Row>
    </BaseModal>
  )
}

DownloadContractModal.propTypes = {
  contractId: PropTypes.object,
  hasSignedContract: PropTypes.bool,
  isShowContract: PropTypes.bool,
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
}

export default DownloadContractModal
