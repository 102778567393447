import React from "react"

export const FileExportIcon = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.66602 9.33301V6.66634C6.66602 6.31272 6.80649 5.97358 7.05654 5.72353C7.30659 5.47348 7.64573 5.33301 7.99935 5.33301H12.666L17.3327 9.99967V17.333C17.3327 17.6866 17.1922 18.0258 16.9422 18.2758C16.6921 18.5259 16.353 18.6663 15.9993 18.6663H7.99935C7.64573 18.6663 7.30659 18.5259 7.05654 18.2758C6.80649 18.0258 6.66602 17.6866 6.66602 17.333V15.333"
          stroke="#002999"
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.666 5.33301V9.99967H17.3327"
          stroke="#002999"
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.7378 12.7075L12.0913 12.354L11.7378 12.0004L9.28321 9.54586C9.08794 9.3506 8.77136 9.3506 8.5761 9.54586C8.38084 9.74112 8.38084 10.0577 8.5761 10.253L10.1774 11.8542H5.23242C4.95628 11.8542 4.73242 12.0781 4.73242 12.3542C4.73242 12.6304 4.95628 12.8542 5.23242 12.8542H10.1768L8.5761 14.455C8.38084 14.6502 8.38084 14.9668 8.5761 15.1621C8.77136 15.3573 9.08794 15.3573 9.28321 15.1621L11.7378 12.7075Z"
          fill="#002999"
        />
      </svg>
    </span>
  )
}
