import React from "react"
import BaseModal from "../../../../../../common/components/Modal"
import { Row, Divider } from "antd"
import PropTypes from "prop-types"
import "./styles.scss"

const ContactModal = ({ data, onClose }) => {
  return (
    <BaseModal isVisible={true} title={"Dados contato"} onCancel={onClose}>
      <Row className="row-info">
        <div className="normal-16">Nome empresa: {data.company_name}</div>
        {data.cpf_cnpj && (
          <div className="normal-16">CPF/CNPJ: {data.cpf_cnpj}</div>
        )}
        {data.sector && (
          <div className="normal-16">Setor: {data.sector.label}</div>
        )}
      </Row>
      <Divider />
      <Row className="row-info">
        <div className="normal-12">Contato</div>
        <div className="normal-16">Nome: {data.name}</div>
        <div className="normal-16">E-mail: {data.email}</div>
        <div className="normal-16">Telefone: {data.phone}</div>
      </Row>
    </BaseModal>
  )
}

ContactModal.propTypes = {
  data: PropTypes.object,
  onClose: PropTypes.func,
}

export default ContactModal
