import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
//
import { AutoComplete } from "antd"
//
import { fetchContact } from "../../../contact/contactService"
//
import "./styles.scss"

const SelectContact = ({ form, role, onChange, onSelected }) => {
  const [value, updateValue] = useState()
  const [timeout, updateTimeout] = useState(0)
  const [companies, updateCompanies] = useState([])
  const [options, updateOptions] = useState([])

  const handleSearch = (query) => {
    updateValue(query)
    clearTimeout(timeout)

    if (query.length >= 3) {
      updateTimeout(
        setTimeout(() => {
          fetchContact(query).then((response) => {
            let items = []

            if (role === "CONTRACTED") {
              items = response.data.data.items
            } else {
              items = response.data.data.items.filter(
                (x) => !x.cpf_cnpj || x.cpf_cnpj.length === 14
              )
            }

            updateCompanies([
              ...items.map((item) => ({ value: item.company_name })),
            ])
            updateOptions(items)
          })
        }, 500)
      )
    }
  }

  const handleSelect = (name) => {
    const selected = options.filter((item) => item.company_name === name)[0]

    const contact = {
      cpf_cnpj: selected.cpf_cnpj,
      name: selected.name,
      email: selected.email,
      phone: selected.phone,
      company_name: selected.company_name,
      sector: selected?.sector?.label ?? "",
    }

    onSelected && onSelected(contact)

    updateValue(selected.company_name)
  }

  useEffect(() => {
    if (form.getFieldsValue().contact)
      updateValue(form.getFieldsValue().contact.company_name)
  }, [])

  return (
    <AutoComplete
      value={value || ""}
      options={companies}
      placeholder="Inserir nome"
      onSearch={handleSearch}
      onSelect={(value) => handleSelect(value)}
      onChange={onChange} // Requerido pelo Form.Item do antd
    />
  )
}

SelectContact.defaultProps = {
  onChange: () => {},
}

SelectContact.propTypes = {
  form: PropTypes.any,
  role: PropTypes.string,
  onChange: PropTypes.func,
  onSelected: PropTypes.func,
}

export default SelectContact
