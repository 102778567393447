import React from "react"
import { Col, Row } from "antd"
import PropTypes from "prop-types"
import Icons from "../../../../common/components/Icons"
import "./styles.scss"
import { useHistory } from "react-router-dom"

const CollapseRowHeader = ({
  title,
  icon,
  resume,
  isActive,
  collapseFor,
  className,
  onOpen,
  onClose,
  pathToEdit,
}) => {
  const history = useHistory()

  const toggleCollapsable = (event) => {
    // Obtem o elemento CollapseRowContent atrelado a este header e
    // identificado pelo id 'collapseFor'.
    localStorage.setItem("data-allow-animation-content", collapseFor)
    const elem = document.getElementById(collapseFor)

    elem.setAttribute("data-allow-animation", "true")
    event.target.setAttribute("data-allow-animation", "true")

    // desabilita animação do CollapseRowContent para funcionar apenas
    // após o click no header deste evento.
    setTimeout(() => {
      elem.setAttribute("data-allow-animation", "false")
      event.target.setAttribute("data-allow-animation", "false")
    }, 3000)

    isActive ? onClose([collapseFor]) : onOpen([collapseFor])
  }

  return (
    <Row
      wrap={false}
      htmlFor={collapseFor}
      className={`row-title ${className ?? ""}`}
      data-is-active={isActive}
      data-allow-animation={
        localStorage.getItem("data-allow-animation-content") === collapseFor
      }
      justify="space-between"
      align="middle"
      gutter={[16, 16]}
    >
      <Col>
        <div>
          <span onClick={toggleCollapsable}>
            <Icons icon={icon} className="title-icon" />
            {title ?? ""}
          </span>
          {pathToEdit ? (
            <span
              className="edit-btn"
              onClick={() => history.replace(pathToEdit)}
            >
              <Icons icon="pen" /> Editar
            </span>
          ) : null}
        </div>
        <div className="title-resume" onClick={toggleCollapsable}>
          {resume ?? ""}
        </div>
      </Col>
      <Col onClick={toggleCollapsable}>
        {}
        <Icons
          icon={isActive ? "outline-arrow-down" : "outline-arrow-top"}
          className="collapse-toggle-icon"
        />
      </Col>
    </Row>
  )
}

CollapseRowHeader.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  resume: PropTypes.string,
  isActive: PropTypes.bool,
  collapseFor: PropTypes.string,
  className: PropTypes.string,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  pathToEdit: PropTypes.string,
}

export default CollapseRowHeader
