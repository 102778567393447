import React from "react"

export const ContractStatusSignedIcon = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        width="30"
        height="30"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M31.3 9.30005H21.9667C21.2595 9.30005 20.5812 9.581 20.0811 10.0811C19.581 10.5812 19.3 11.2595 19.3 11.9667V33.3C19.3 34.0073 19.581 34.6856 20.0811 35.1857C20.5812 35.6858 21.2595 35.9667 21.9667 35.9667H37.9667C38.674 35.9667 39.3522 35.6858 39.8523 35.1857C40.3524 34.6856 40.6334 34.0073 40.6334 33.3V18.6334V12.3C40.6334 10.6432 39.2902 9.30005 37.6334 9.30005H31.3Z"
          stroke="#141414"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.3 18.6333H30.5942H35.3"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M25.3 21.6333H30.5942H35.3"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M25.3 15.6333H27.3834H30.3"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M15.3 38.6333C19.7183 38.6333 23.3 35.0516 23.3 30.6333C23.3 26.215 19.7183 22.6333 15.3 22.6333C10.8818 22.6333 7.30005 26.215 7.30005 30.6333C7.30005 35.0516 10.8818 38.6333 15.3 38.6333Z"
          fill="#FAFAFA"
          stroke="black"
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.1003 30.633L14.5003 33.033L18.5003 29.033"
          stroke="#141414"
          strokeWidth="1.8"
          strokeLinecap="round"
        />
        <path
          d="M25.3 32.446C26.4219 31.5364 26.9945 29.5463 27.2663 28.1695C27.4612 27.1816 27.6595 26.1013 27.6595 25.0891C27.6595 24.3264 27.6735 24.6157 27.7332 25.2612C27.8116 26.1081 27.807 26.9517 27.807 27.8009C27.807 28.9431 27.7465 30.1024 27.9462 31.2008C27.9874 31.4269 27.9544 31.6831 27.9544 31.9135C27.9544 32.2609 28.1898 31.2562 28.2494 30.914C28.4445 29.7919 28.7744 31.8296 29.5438 31.8562C30.079 31.8746 30.3207 31.3634 30.7235 31.1188C30.9821 30.9618 31.26 31.2772 31.4936 31.381C31.857 31.5425 32.361 31.0218 32.6406 30.8567C33.1888 30.5327 33.3715 30.96 33.853 31.2008C34.5393 31.5439 35.3718 30.9714 36.065 30.9714"
          stroke="black"
          strokeWidth="1.7"
          strokeLinecap="round"
        />
      </svg>
    </span>
  )
}
