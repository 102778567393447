import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
//
import { useDispatch, useSelector } from "react-redux"
import {
  createBankAccount,
  listBankAccounts,
  selectConfigState,
} from "../../../configurations/configurationsSlice"
import { selectProposalState, updateAux } from "../../../proposal/proposalSlice"
//
import { BankAccountModal } from "../../../configurations/components/bankAccount/components/modal"
import { PlainButton } from "../../../../common/components/Button"
import Icons from "../../../../common/components/Icons"
import Select from "antd/es/select"
//
import StateStatus from "../../../../utils/stateStatus"
import "./styles.scss"
import { Form, Row, Col } from "antd"

const SelectBank = ({ form, name, placeholder, ...props }) => {
  const dispatch = useDispatch()
  //
  const config = useSelector(selectConfigState)
  const proposalState = useSelector(selectProposalState)
  const proposal = proposalState.data.aux || {}
  //
  const [banks, updateBanks] = useState()
  const [showModal, updateShowModal] = useState(false)

  useEffect(() => {
    if (proposal.bank_account) {
      if (!form.getFieldsValue()[name]) {
        const fields = {}
        fields[name] = "-1"
        form.setFieldsValue(fields)
      }
    }

    if (config.data.bankAccounts?.items?.length === 0) {
      dispatch(listBankAccounts({ filter: "" }))
    }
  }, [])

  useEffect(() => {
    if (config.data.bankAccounts?.items?.length > 0) {
      let banks = config.data.bankAccounts?.items
      if (proposal.bank_account?.agency) {
        let selected = config.data.bankAccounts.items.filter((b) => {
          return (
            b.agency === proposal.bank_account.agency &&
            b.bank_code === proposal.bank_account.bank_code &&
            b.account_number === proposal.bank_account.account_number
          )
        })[0]

        if (!selected) {
          selected = { id: "-1", ...proposal.bank_account }
          banks = [...banks, selected]
        }

        dispatch(updateAux({ bank_account: selected }))
        const fields = {}
        fields[name] = selected.id
        form.setFieldsValue(fields)
      }

      updateBanks(banks)
    }
  }, [config.data.bankAccounts?.items, proposal.bank_account?.agency])

  return (
    <Row gutter={[16, 16]} align="bottom" className="row-select-bank-proposal">
      <Col span={14}>
        <Form.Item name={name} {...props}>
          <Select
            className="select-bank-proposal"
            placeholder={placeholder}
            filterOption={false}
            loading={config.status.listBankAccounts === StateStatus.loading}
            notFoundContent={
              <center className="normal-14">
                Conta bancária não cadastrada.
              </center>
            }
            onChange={(id) => {
              setTimeout(() => {
                const selected = banks.filter((c) => c.id === id)[0]
                dispatch(updateAux({ bank_account: selected }))
              }, 500)
            }}
          >
            {banks &&
              banks.map((item, index) => (
                <Select.Option
                  key={index}
                  value={item.id}
                  className="select-bank-option"
                >
                  <div>
                    <div>
                      Ag: {item.agency} | CC: {item.account_number}
                    </div>
                    <div>{item.bank_name}</div>
                  </div>
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={10}>
        <PlainButton
          icon={<Icons icon="plus" />}
          onClick={() => updateShowModal(true)}
          className="button-add-option-on-select"
        >
          Adicionar conta bancária
        </PlainButton>
      </Col>

      {showModal && (
        <BankAccountModal
          data={null}
          isLoading={config.status.createBankAccount === StateStatus.loading}
          onSubmit={(data) => {
            delete data.bank_name

            dispatch(createBankAccount(data)).then(({ payload }) => {
              dispatch(listBankAccounts({ filter: "" })).then(() => {
                if (payload && payload.data) {
                  const fields = {}
                  fields[name] = payload.data.id
                  form.setFieldsValue(fields)
                  dispatch(updateAux({ bank_account: payload.data }))
                }
              })
              updateShowModal(false)
            })
          }}
          onCancel={() => {
            updateShowModal(false)
          }}
        />
      )}
    </Row>
  )
}

SelectBank.propTypes = {
  form: PropTypes.any,
  name: PropTypes.any,
  placeholder: PropTypes.string,
}

export default SelectBank
