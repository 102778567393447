import React, { useEffect, useMemo, useState } from "react"
import { useHistory } from "react-router-dom"
//
import StateStatus from "../../utils/stateStatus"
import { useDispatch, useSelector } from "react-redux"
import {
  changeSelectedTab,
  cleanSelectedFilters,
  deleteProposal,
  duplicateProposal,
  getStatistics,
  listProposals,
  resendContract,
  resetDelete,
  resetError,
  resetResendContract,
  saveSelectedFilters,
  selectListProposalsState,
  setMessagesAsViewed,
} from "./listProposalsSlice"

import { selectDashSlice } from "../dashboard/DashboardSlice"
import {
  getProfile,
  selectConfigState,
} from "../configurations/configurationsSlice"
import {
  resendConfirmAccountEmail,
  resetResendConfirmAccountEmail,
  selectConfirmAccountState,
} from "../confirmAccount/confirmAccountSlice"
//
import Card from "./components/cards"
import { Row, Col, Form, Dropdown, Badge } from "antd"
import Icons from "../../common/components/Icons"
import BaseModal from "../../common/components/Modal"
import Loading from "../../common/components/Loading"
import { Tooltip } from "../../common/components/Tooltip"
import RoudedBox from "../../common/components/RoudedBox"
import { BlueButton } from "../../common/components/Button"
import TabBarMenu from "../../common/components/TabBarMenu"
import FlattedInput from "../../common/components/FlattedInput"
import { CircleCheckBox } from "../../common/components/CheckBox"
import DownloadContractModal from "./components/dowloadContratoModal"
import ShareProposalModal from "../../common/components/ShareProposalModal"
//
import { useQuery } from "../../utils/query"
import { numberToReal } from "../../utils/formaters"
//
import expiredProposalImg from "../../common/assets/images/propostas-vencidas.png"
import pendingProposalImg from "../../common/assets/images/propostas-pendentes.png"
import approvedProposalImg from "../../common/assets/images/propostas-aprovadas.png"
//
import "./styles.scss"
import DeleteOrCancelModal from "../../common/components/ProposalDetails/components/deleteOrCancelModal"
import { useObserverElement } from "../../utils/observer"
import FilterProposalsMenu from "./components/filterProposalsMenu"
import ChatProposalMessage from "../chatProposal"

const ListProposals = () => {
  const query = useQuery()
  const history = useHistory()
  const dispatch = useDispatch()
  //
  const config = useSelector(selectConfigState)
  const dashState = useSelector(selectDashSlice)
  const listProposalsState = useSelector(selectListProposalsState)
  const tabData = listProposalsState[listProposalsState.tab]
  const confirmAccount = useSelector(selectConfirmAccountState)
  //
  const role = dashState.mode?.toUpperCase()
  const profileData = config.data.profileData
  const loading =
    tabData.status.listProposals === StateStatus.loading ||
    tabData.proposals == null ||
    tabData.status.listProposals === StateStatus.idle
  //
  const [formDuplicate] = Form.useForm()
  const [proposal, updateProposal] = useState({})
  const [showModal, updateShowModal] = useState(null)
  const [showErrorModal, updateShowErrorModal] = useState(false)
  // infinityScroll
  const [scroollTriggerRef, isScroollTriggerVisible] = useObserverElement({
    root: null,
    rootMargin: "0px",
    threshold: 1.0,
  })

  const emptyImages = {
    expired: expiredProposalImg,
    waiting: pendingProposalImg,
    accepted: approvedProposalImg,
  }

  const menuItems = useMemo(() => {
    return [
      {
        name: "Aceitas",
        key: "accepted",
        count: listProposalsState.accepted.statistics.count,
      },
      {
        name: "Pendentes",
        key: "waiting",
        count: listProposalsState.waiting.statistics.count,
      },
      {
        name: (
          <Tooltip title="As propostas canceladas, que passaram do prazo de validade ou foram finalizadas, são consideradas inativas.">
            Inativas
          </Tooltip>
        ),
        key: "expired",
        count: listProposalsState.expired.statistics.count,
      },
    ]
  }, [listProposalsState.status.getStatistics])

  const getFirstPageList = () => {
    dispatch(
      listProposals({
        page: 1,
        filter: tabData.filter || "",
        status: tabData.name,
        role,
        filters: tabData.filters,
      })
    )
  }

  useEffect(() => {
    dispatch(getProfile())
    dispatch(getStatistics({ role }))

    const index = parseInt(query.get("tab"))

    if (
      query.get("tab") !== null &&
      typeof index === "number" &&
      index >= 0 &&
      index < menuItems.length
    ) {
      dispatch(changeSelectedTab(index))
    } else {
      dispatch(changeSelectedTab(listProposalsState.tab || 0))
    }
  }, [])

  useEffect(() => {
    getFirstPageList()
    // Limpa filtro de status ao mudar a tab, cada tab possui suas etapas (status).
    dispatch(saveSelectedFilters({ status: null }))
    document.body.style.overflow = "auto"
  }, [listProposalsState.tab])

  useEffect(() => {
    getFirstPageList()
  }, [tabData.filters, tabData.filter])

  useEffect(() => {
    switch (listProposalsState.status.deleteProposal) {
      case StateStatus.succeeded:
        dispatch(resetDelete())
        break
    }
  }, [listProposalsState.status.deleteProposal])

  useEffect(() => {
    if (tabData.status.listProposals === StateStatus.succeeded) {
      //
    }
  }, [tabData.status.listProposals])

  useEffect(() => {
    if (listProposalsState.errMessage) {
      updateShowErrorModal(true)
    } else {
      updateShowErrorModal(false)
    }
  }, [listProposalsState.errMessage])

  useEffect(() => {
    if (isScroollTriggerVisible) {
      if (
        tabData.page < tabData.totalPages &&
        tabData.page >= 1 &&
        tabData.status.listProposals !== StateStatus.loading
      ) {
        dispatch(
          listProposals({
            page: tabData.page + 1,
            filter: tabData.filter,
            status: tabData.name,
            role,
            filters: tabData.filters,
          })
        )
      }
    }
  }, [isScroollTriggerVisible])

  const hasFilterSelected =
    tabData.filters?.status ||
    tabData.filters?.created_by ||
    tabData.filters?.tags ||
    tabData.filters?.sort_by

  return (
    <div className="list-proposal-page">
      <Col style={{ width: "100%" }} align="center">
        {profileData.email_confirmed && (
          <Row
            gutter={[24, 27]}
            style={{ width: "1090px" }}
            justify="space-between"
          >
            <Col xs={24} sm={24} md={7} lg={7}>
              <RoudedBox className="white-box">
                <Row justify="space-between">
                  <span className="box-label">Nº propostas</span>
                  <span className="box-value">
                    {tabData.statistics?.count
                      ? tabData.statistics.count.toLocaleString("pt-BR", {
                          style: "decimal",
                        })
                      : 0}
                  </span>
                </Row>
              </RoudedBox>
            </Col>
            <Col xs={24} sm={24} md={7} lg={7}>
              <RoudedBox className="white-box">
                <Row justify="space-between">
                  <span className="box-label">Nº total de contratos</span>
                  <span className="box-value">
                    {tabData.statistics?.contracts
                      ? tabData.statistics.contracts.toLocaleString("pt-BR", {
                          style: "decimal",
                        })
                      : 0}
                  </span>
                </Row>
              </RoudedBox>
            </Col>
            <Col xs={24} sm={24} md={10} lg={10}>
              <RoudedBox className="blue-box">
                <Row justify="space-between">
                  <span className="box-label">Valor total</span>
                  <span className="box-value">
                    {tabData.statistics ? (
                      <Tooltip
                        title={numberToReal(
                          tabData.statistics.total_value / 100
                        )}
                      >
                        {numberToReal(
                          tabData.statistics.total_value / 100,
                          false
                        )}
                      </Tooltip>
                    ) : (
                      0
                    )}
                  </span>
                </Row>
              </RoudedBox>
            </Col>
          </Row>
        )}

        <Row
          gutter={[24, 24]}
          style={{ width: "1090px", marginTop: "27px" }}
          justify="center"
        >
          <Col span={24}>
            <Row justify="space-around">
              <TabBarMenu
                selectedTab={
                  listProposalsState.tab === "waiting"
                    ? 1
                    : listProposalsState.tab === "accepted"
                    ? 0
                    : 2
                }
                menuItems={menuItems}
                onChange={(index) => {
                  dispatch(changeSelectedTab(index))
                }}
              />
            </Row>

            {!loading || tabData.proposals?.length > 0 ? (
              <Row justify="space-around" className="proposals-header">
                <Col span={5}>Negociando com</Col>
                <Col span={3}>Marcadores</Col>
                <Col span={3}>Proprietário</Col>
                <Col span={3}>Criada em</Col>
                <Col span={5}>Etapa</Col>
                <Col span={2}>
                  {listProposalsState.tab === "waiting" ? "" : "Chat"}
                </Col>
                <Col span={2}>Valor</Col>
                <Col span={1}>
                  <Dropdown
                    overlay={<FilterProposalsMenu />}
                    trigger={["click"]}
                    placement="bottomRight"
                  >
                    <Badge
                      dot={hasFilterSelected}
                      style={{ backgroundColor: "#0018e8" }}
                    >
                      <div className="more-button">
                        <Icons icon="config-filters" />
                      </div>
                    </Badge>
                  </Dropdown>
                </Col>
              </Row>
            ) : null}

            {!loading && tabData.proposals?.length === 0 ? (
              listProposalsState.isAccountConfirmed ? (
                <div className="proposals-empty-card">
                  <img
                    src={emptyImages[listProposalsState.tab]}
                    alt="imagem para simbolizar que a lista está vazia"
                  />
                  Nenhuma proposta
                </div>
              ) : (
                <div className="proposals-empty-card">
                  <img
                    src={emptyImages[listProposalsState.tab]}
                    alt="imagem para simbolizar que a lista está vazia"
                  />
                  <>
                    <p className="message">
                      Você precisa confirmar seu e-mail para ver suas propostas.
                    </p>

                    <BlueButton
                      onClick={() => dispatch(resendConfirmAccountEmail())}
                      // style={{width: "100%"}}
                      isLoading={
                        confirmAccount.status.resendConfirmAccountEmail ===
                        StateStatus.loading
                      }
                      style={{ width: "450px" }}
                    >
                      Receber e-mail de confirmação
                    </BlueButton>
                  </>
                </div>
              )
            ) : null}

            {loading && tabData.proposals?.length === 0 ? <Loading /> : null}

            {tabData.proposals?.map((item, index) => (
              <>
                <Card
                  key={index}
                  proposal={item}
                  onClick={(event) => {
                    history.push(`/dashboard/proposals/${item.id}`)
                  }}
                  onDuplicate={() => {
                    updateProposal(item)
                    formDuplicate.setFieldsValue({
                      name: item.campaign_name,
                    })
                    updateShowModal("duplicate")
                  }}
                  // onOpenContract={() => {
                  //   window.location.href = item.contract_link
                  // }}
                  onResendContract={() => {
                    updateProposal(item)
                    dispatch(resendContract(item.contract_id))
                  }}
                  onEdit={() => {
                    history.push({
                      pathname: `/dashboard/proposal/1/${item.id}`,
                    })
                  }}
                  onDownloadContract={() => {
                    updateProposal(item)
                    updateShowModal("download-contract")
                  }}
                  onShare={() => {
                    updateProposal(item)
                    updateShowModal("share")
                  }}
                  onDelete={() => {
                    updateProposal(item)
                    updateShowModal("delete")
                  }}
                  onCancel={() => {
                    updateProposal(item)
                    updateShowModal("cancel")
                  }}
                  onClickMessages={() => {
                    updateProposal(item)
                    updateShowModal("chat-proposal")
                    dispatch(setMessagesAsViewed({ proposalId: item.id }))
                  }}
                />
                {tabData.proposals?.length - 1 === index ? (
                  <div
                    style={{ height: "30px", width: "100%" }}
                    ref={scroollTriggerRef}
                  ></div>
                ) : null}
              </>
            ))}

            {loading && tabData.proposals?.length > 0 && tabData.page > 1 ? (
              <Loading />
            ) : null}
          </Col>
        </Row>
      </Col>

      {tabData.page >= 1 && (
        <>
          <Tooltip
            placement="bottom"
            title={
              <>
                <b>
                  {tabData.proposals?.length ?? 0} / {tabData.count} Propostas
                </b>
              </>
            }
          >
            <div style={{ width: "100%", height: "80px" }}></div>
          </Tooltip>
        </>
      )}

      <BaseModal
        isVisible={showErrorModal}
        title="Erro!"
        okButtonText="Ok"
        onOk={() => {
          dispatch(resetError())
        }}
        onCancel={() => {
          dispatch(resetError())
        }}
      >
        {listProposalsState.errMessage}
      </BaseModal>

      <BaseModal
        isVisible={showModal === "duplicate"}
        title="Duplicar Proposta"
        isLoading={
          listProposalsState.status.duplicateProposal === StateStatus.loading
        }
        okButtonText="Salvar"
        onOk={() => {
          formDuplicate.submit()
        }}
        cancelButtonText="Cancelar"
        onCancel={() => {
          formDuplicate.resetFields()
          updateShowModal(null)
        }}
      >
        <Form
          form={formDuplicate}
          onFinish={(data) => {
            dispatch(
              duplicateProposal({
                id: proposal.id,
                data,
              })
            ).then(() => {
              dispatch(getProfile())
              dispatch(getStatistics({ role }))

              if (listProposalsState.tab !== "waiting") {
                dispatch(cleanSelectedFilters())
                history.push("/dashboard/proposals?tab=1")
              } else {
                dispatch(cleanSelectedFilters())
                getFirstPageList()
              }

              updateShowModal(null)
              formDuplicate.resetFields()
            })
          }}
        >
          <Row justify="center">
            Insira abaixo um nome para a nova proposta
            <Form.Item name="name">
              <FlattedInput />
            </Form.Item>
          </Row>
        </Form>
      </BaseModal>
      <DeleteOrCancelModal
        isVisible={showModal === "delete" || showModal === "cancel"}
        isDelete={showModal === "delete"}
        isLoading={
          listProposalsState.status.deleteProposal === StateStatus.loading
        }
        onOk={() => {
          dispatch(deleteProposal(proposal.id)).then(() => {
            updateShowModal(null)
            dispatch(getProfile())
            dispatch(getStatistics({ role }))
            getFirstPageList()
          })
        }}
        onCancel={() => {
          updateShowModal(null)
        }}
      />

      <BaseModal
        className="resend-contract-modal"
        isVisible={
          listProposalsState.status.resendContract === StateStatus.loading ||
          listProposalsState.status.resendContract === StateStatus.succeeded
        }
        title="Reenviar Contrato"
        okButtonText={
          listProposalsState.status.resendContract === StateStatus.loading
            ? null
            : "Ok"
        }
        onOk={() => {
          dispatch(resetResendContract())
        }}
        onCancel={() => {
          dispatch(resetResendContract())
        }}
      >
        <Row style={{ minHeight: "100px" }} align="middle" justify="center">
          {listProposalsState.status.resendContract === StateStatus.loading && (
            <div>
              <Row justify="center">Reenviando contrato para assinantes...</Row>
              <br />
              <Row justify="center">
                <Loading />
              </Row>
            </div>
          )}
          {listProposalsState.status.resendContract ===
            StateStatus.succeeded && (
            <div>
              <Row justify="center">Contrato enviado com sucesso!</Row>
              <br />
              <Row justify="center">
                {listProposalsState.emailsContract.map((email, index) => {
                  return (
                    <Col key={index} span={24}>
                      <CircleCheckBox checked={true}>{email}</CircleCheckBox>
                    </Col>
                  )
                })}
              </Row>
            </div>
          )}
        </Row>
      </BaseModal>

      <ShareProposalModal
        isVisible={showModal === "share"}
        proposal={proposal}
        onClose={() => updateShowModal(null)}
      />

      {proposal && proposal.contract_id ? (
        <DownloadContractModal
          contractId={proposal.contract_id}
          hasSignedContract={["waiting_nfs", "finalized"].includes(
            proposal.status
          )}
          isShowContract={false}
          isVisible={showModal === "download-contract"}
          onClose={() => updateShowModal(null)}
        />
      ) : null}

      {/* confirm email alert */}
      <BaseModal
        isVisible={
          confirmAccount.status.resendConfirmAccountEmail ===
            StateStatus.succeeded ||
          confirmAccount.status.resendConfirmAccountEmail === StateStatus.failed
        }
        title={
          confirmAccount.status.resendConfirmAccountEmail ===
          StateStatus.succeeded
            ? "E-mail enviado"
            : "Erro ao enviar e-mail"
        }
        okButtonText="Fechar janela"
        onOk={() => {
          dispatch(resetResendConfirmAccountEmail())
          history.push("/dashboard")
        }}
        onCancel={() => {
          dispatch(resetResendConfirmAccountEmail())
          history.push("/dashboard")
        }}
      >
        {confirmAccount.status.resendConfirmAccountEmail ===
        StateStatus.succeeded
          ? "Lembre-se de verificar a caixa de spam ou lixo eletrônico. Se você ainda não recebeu o e-mail aguarde alguns minutos antes de tentar novamente."
          : "Tente novamente mais tade."}
      </BaseModal>
      {showModal === "chat-proposal" && (
        <ChatProposalMessage
          proposalId={proposal?.id}
          isVisible={showModal === "chat-proposal" && proposal?.id}
          onUpdateProposal={() => {
            dispatch(getProfile())
            dispatch(getStatistics({ role }))
            getFirstPageList()
          }}
          onClose={() => {
            updateShowModal(null)
            updateProposal(null)
          }}
        />
      )}
    </div>
  )
}

export default ListProposals
