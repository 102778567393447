import React from "react"

export const WatchIcon = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18Z"
          stroke="#002999"
          strokeWidth="2.55411"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.4 13.2L10.4688 11.2688C10.1687 10.9688 10.0001 10.5619 10 10.1376V5.20001"
          stroke="#002999"
          strokeWidth="2.55411"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  )
}
