export const sectorsMock = [
  {
    value: "Tecnologia da Informação (TI)",
    label: "Tecnologia da Informação (TI)",
  },
  {
    value: "Saúde e Medicina",
    label: "Saúde e Medicina",
  },
  {
    value: "Educação e Ensino",
    label: "Educação e Ensino",
  },
  {
    value: "Varejo e Comércio",
    label: "Varejo e Comércio",
  },
  {
    value: "Finanças e Serviços Financeiros",
    label: "Finanças e Serviços Financeiros",
  },
  {
    value: "Indústria Automotiva",
    label: "Indústria Automotiva",
  },
  {
    value: "Energia e Sustentabilidade",
    label: "Energia e Sustentabilidade",
  },
  {
    value: "Alimentos e Bebidas",
    label: "Alimentos e Bebidas",
  },
  {
    value: "Turismo e Hospitalidade",
    label: "Turismo e Hospitalidade",
  },
  {
    value: "Mídia e Entretenimento",
    label: "Mídia e Entretenimento",
  },
  {
    value: "Agricultura e Agropecuária",
    label: "Agricultura e Agropecuária",
  },
  {
    value: "Construção e Engenharia Civil",
    label: "Construção e Engenharia Civil",
  },
  {
    value: "Transporte e Logística",
    label: "Transporte e Logística",
  },
  {
    value: "Moda e Vestuário",
    label: "Moda e Vestuário",
  },
  {
    value: "Serviços Jurídicos",
    label: "Serviços Jurídicos",
  },
  {
    value: "Consultoria e Assessoria",
    label: "Consultoria e Assessoria",
  },
  {
    value: "Setor Público e Administração",
    label: "Setor Público e Administração",
  },
  {
    value: "Telecomunicações",
    label: "Telecomunicações",
  },
  {
    value: "Marketing e Publicidade",
    label: "Marketing e Publicidade",
  },
  {
    value: "Artes e Cultura",
    label: "Artes e Cultura",
  },
  {
    value: "Esportes e Fitness",
    label: "Esportes e Fitness",
  },
  {
    value: "Ciência e Pesquisa",
    label: "Ciência e Pesquisa",
  },
  {
    value: "Biotecnologia e Farmacêutica",
    label: "Biotecnologia e Farmacêutica",
  },
  {
    value: "Design e Arquitetura",
    label: "Design e Arquitetura",
  },
  {
    value: "Recursos Humanos e Gestão de Pessoas",
    label: "Recursos Humanos e Gestão de Pessoas",
  },
  {
    value: "Serviços de Limpeza e Manutenção",
    label: "Serviços de Limpeza e Manutenção",
  },
  {
    value: "Segurança e Vigilância",
    label: "Segurança e Vigilância",
  },
  {
    value: "Organizações Sem Fins Lucrativos",
    label: "Organizações Sem Fins Lucrativos",
  },
  {
    value: "Moda e Beleza",
    label: "Moda e Beleza",
  },
  {
    value: "Automatização e Robótica",
    label: "Automatização e Robótica",
  },
]
