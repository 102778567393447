import React, { useState } from "react"
import FlattedInput from "../FlattedInput"
import Icons from "../Icons"
import PropTypes from "prop-types"
import "./styles.scss"

const FlattedInputPassword = ({ onChange, ...props }) => {
  const [isPassword, updateIsPassword] = useState(true)

  const passwordValidation = (value) => {
    const result = {
      hasEigthLength: value.length >= 8,
      hasOneNumber: value.search(/\d/g) !== -1,
      hasOneCapitalLetter: value.search(/[A-Z]/g) !== -1,
      hasOneLowerLetter: value.search(/[a-z]/g) !== -1,
    }

    return result
  }

  return (
    <div>
      <FlattedInput
        type={isPassword ? "password" : "text"}
        className="flatted-input"
        onChange={(event) => {
          const result = passwordValidation(event.target.value)
          onChange(event, result)
        }}
        {...props}
      ></FlattedInput>
      {isPassword ? (
        <Icons
          icon="eye-closed"
          data-testid="fip-show-icon"
          onClick={() => {
            updateIsPassword(!isPassword)
          }}
          className="icon-button"
        />
      ) : (
        <Icons
          icon="eye-opened"
          data-testid="fip-hide-icon"
          onClick={() => {
            updateIsPassword(!isPassword)
          }}
          className="icon-button"
        />
      )}
    </div>
  )
}

FlattedInputPassword.propTypes = {
  onChange: PropTypes.func,
}

export default FlattedInputPassword
