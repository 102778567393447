import React from "react"

export const ContractStatusFinishIcon = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        width="30"
        height="30"
        viewBox="0 0 49 49"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M31.6375 8.5H21.575C20.8125 8.5 20.0812 8.795 19.542 9.3201C19.0029 9.8452 18.7 10.5574 18.7 11.3V33.7C18.7 34.4426 19.0029 35.1548 19.542 35.6799C20.0812 36.205 20.8125 36.5 21.575 36.5H38.825C39.5874 36.5 40.3187 36.205 40.8579 35.6799C41.397 35.1548 41.7 34.4426 41.7 33.7V18.3L31.6375 8.5Z"
          stroke="#141414"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.5 39.6333C18.9183 39.6333 22.5 36.0516 22.5 31.6333C22.5 27.215 18.9183 23.6333 14.5 23.6333C10.0817 23.6333 6.5 27.215 6.5 31.6333C6.5 36.0516 10.0817 39.6333 14.5 39.6333Z"
          fill="#FAFAFA"
          stroke="black"
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.3003 31.633L13.7003 34.033L17.7003 30.033"
          stroke="#141414"
          strokeWidth="1.8"
          strokeLinecap="round"
        />
      </svg>
    </span>
  )
}
