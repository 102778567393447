import React from "react"

export const CalendarIcon = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        width="23"
        height="23"
        viewBox="0 0 23 23"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M18.2083 21.0832H4.79167C3.73312 21.0832 2.875 20.225 2.875 19.1665V5.74984C2.875 4.69129 3.73312 3.83317 4.79167 3.83317H6.70833V1.9165H8.625V3.83317H14.375V1.9165H16.2917V3.83317H18.2083C19.2669 3.83317 20.125 4.69129 20.125 5.74984V19.1665C20.125 20.225 19.2669 21.0832 18.2083 21.0832ZM4.79167 9.58317V19.1665H18.2083V9.58317H4.79167ZM4.79167 5.74984V7.6665H18.2083V5.74984H4.79167ZM16.2917 17.2498H14.375V15.3332H16.2917V17.2498ZM12.4583 17.2498H10.5417V15.3332H12.4583V17.2498ZM8.625 17.2498H6.70833V15.3332H8.625V17.2498ZM16.2917 13.4165H14.375V11.4998H16.2917V13.4165ZM12.4583 13.4165H10.5417V11.4998H12.4583V13.4165ZM8.625 13.4165H6.70833V11.4998H8.625V13.4165Z"></path>
      </svg>
    </span>
  )
}
