import React, { useEffect } from "react"
import "./styles.scss"
import RoundedBox from "../../common/components/RoudedBox"
import homeImage from "../../common/assets/dashboardOnboarding/home-page-img.svg"
import Icons from "../../common/components/Icons"
import { useHistory } from "react-router-dom"
import InvoiceCardProgress from "../../common/components/InvoicingCard"
import NewLettersCard from "./components/cardNewLetters/NewLettersCard"
import { useDispatch, useSelector } from "react-redux"
import {
  getInvoicingProgress,
  statisticsSelector,
} from "../countersPage/countersPageSlice"
import { Col, Row } from "antd"

const HomePage = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const counters = useSelector(statisticsSelector)

  const hasMeiCompany = !!counters.data.invoicingProgress?.cnpj

  useEffect(() => {
    dispatch(getInvoicingProgress())
  }, [])

  return (
    <div className="home-content">
      <div className="home-title">
        <p>O que vamos fazer hoje?</p>
      </div>
      <Row className="content-box" gutter={[0, 16]}>
        <Col span={24}>
          <Row className="top-row" justify="space-between" gutter={[8, 8]}>
            <Col className="col-home-img" span={12}>
              <img
                className="home-img"
                data-testid="home-img"
                src={homeImage}
                alt="Imagem Ilustrativa"
              />
            </Col>
            <Col className="create-propozall-box" span={12}>
              <Row justify="space-between" gutter={[8, 8]}>
                <Col onClick={() => history.push("/dashboard/proposal/1")}>
                  <RoundedBox className="home-container clickable">
                    <div>
                      <Icons
                        icon={"onboardingDocPlus"}
                        className="container-icons-stroke"
                      />
                      <div
                        style={{ paddingBottom: "32px", paddingTop: "18px" }}
                        className="normal-18"
                      >
                        Criar proposta
                      </div>
                    </div>
                    <div
                      style={{ textAlign: "center" }}
                      className="bottom-info normal-12"
                    >
                      Escolha o melhor modelo de proposta que se encaixa no seu
                      negócio
                    </div>
                  </RoundedBox>
                </Col>
                <Col
                  onClick={() => history.push("/dashboard/contract-history")}
                >
                  <RoundedBox className="home-container clickable">
                    <div>
                      <Icons
                        icon={"onboardingAssign"}
                        className="container-icons-fill"
                      />
                      <div
                        style={{ paddingBottom: "32px", paddingTop: "24px" }}
                        className="normal-18"
                      >
                        Assinar contrato
                      </div>
                    </div>
                    <div
                      style={{ textAlign: "center" }}
                      className="bottom-info normal-12"
                    >
                      Assine contratos com validade jurídica.
                    </div>
                  </RoundedBox>
                </Col>
                <Col onClick={() => history.push("/dashboard/nfse")}>
                  <RoundedBox className="home-container clickable">
                    <div>
                      <Icons
                        icon={"onboardingDocNfse"}
                        className="container-icons-fill"
                      />
                      <div
                        style={{ paddingBottom: "32px", paddingTop: "24px" }}
                        className="normal-18"
                      >
                        Enviar nota fiscal
                      </div>
                    </div>
                    <div
                      style={{ textAlign: "center" }}
                      className="bottom-info normal-12"
                    >
                      Envie notas fiscais e tenha tudo sempre documentado.
                    </div>
                  </RoundedBox>
                </Col>
                {/* <Col>
                  <RoundedBox className="home-container last-home-container ">
                    <div>
                      <Icons
                        icon={"onboardingLamp"}
                        className="container-icons-stroke"
                      />
                      <div
                        style={{ paddingBottom: "32px", paddingTop: "24px" }}
                        className="normal-18"
                      >
                        Próximo passo
                      </div>
                    </div>
                    <div
                      style={{ textAlign: "center" }}
                      className="bottom-info normal-12"
                    >
                      Você tem contrato aguardando o envio de nota fiscal. Não
                      perca o prazo!
                    </div>
                  </RoundedBox>
                </Col> */}
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row
            justify={hasMeiCompany ? "space-between" : "end"}
            gutter={[16, 16]}
            className="bottom-row"
          >
            {hasMeiCompany && (
              <Col span={12} className="col-invoice-progress">
                <InvoiceCardProgress />
              </Col>
            )}
            <Col
              span={hasMeiCompany ? 12 : 12}
              className="col-newsletter-progress"
            >
              <NewLettersCard />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default HomePage
