export const signerRolesList = [
  { value: "CONTRACTOR", name: "Contratante" },
  { value: "CONTRACTED", name: "Contratado" },
  { value: "WITNESS", name: "Testemunha" },
  { value: "FINANCIAL_RESPONSIBLE", name: "Responsável Financeiro" },
  { value: "PARTY", name: "Parte" },
  { value: "INTERVENING", name: "Interveniente" },
  { value: "ENDORSER", name: "Endossante" },
  { value: "ENDORSEE", name: "Endossatário" },
  { value: "ADMINISTRATOR", name: "Administrador" },
  { value: "GUARANTOR", name: "Avalista" },
  { value: "TRANSFEROR", name: "Cedente" },
  { value: "TRANSFEREE", name: "Cessionário" },
  { value: "JOINT_DEBTOR", name: "Devedor Solidário" },
  { value: "ISSUER", name: "Emitente" },
  { value: "MANAGER", name: "Gestor" },
  { value: "BUYER", name: "Parte Compradora" },
  { value: "SELLER", name: "Parte Vendedora" },
  { value: "ATTORNEY", name: "Procurador" },
  { value: "LEGAL_REPRESENTATIVE", name: "Representante legal" },
  { value: "CO_RESPONSIBLE", name: "Responsável Solidário" },
  { value: "VALIDATOR", name: "Validador" },
  { value: "LESSOR", name: "Locador" },
  { value: "LESSEE", name: "Locatário" },
  { value: "SURETY", name: "Fiador" },
]

export const ProposalTypesEnum = Object.freeze({
  INFLUENCER: "INFLUENCER",
  SERVICE_PROVIDER: "SERVICE_PROVIDER",
  SIMPLE: "SIMPLE",
  FREELA: "FREELA",
})

export const ProposalRolesEnum = Object.freeze({
  CONTRACTOR: "CONTRACTOR",
  CONTRACTED: "CONTRACTED",
})

export const ProposalStatusEnum = Object.freeze({
  draft: "draft",
  waiting_accept: "waiting_accept",
  accepted: "accepted",
  refused: "refused",
  canceled: "canceled",
  expired: "expired",
  waiting_sign_contract: "waiting_sign_contract",
  waiting_nfs: "waiting_nfs",
  finalized: "finalized",
})
