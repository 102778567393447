import React from "react"

export const ProposalStatusExpiredIcon = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        width="49"
        height="49"
        viewBox="0 0 49 49"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25.5001 36.6667C32.8639 36.6667 38.8334 30.6971 38.8334 23.3333C38.8334 15.9695 32.8639 10 25.5001 10C18.1363 10 12.1667 15.9695 12.1667 23.3333C12.1667 30.6971 18.1363 36.6667 25.5001 36.6667Z"
          stroke="#141414"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.5 15.3333V23.3333L30.8333 25.9999"
          stroke="#141414"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.7734 24.1346L9.7231 35.9046C9.57773 36.1563 9.50082 36.4417 9.50001 36.7324C9.49919 37.0231 9.57451 37.3089 9.71846 37.5615C9.86241 37.814 10.07 38.0245 10.3205 38.1719C10.571 38.3193 10.8558 38.3986 11.1465 38.4017H25.2471C25.5378 38.3986 25.8226 38.3193 26.0731 38.1719C26.3236 38.0245 26.5312 37.814 26.6751 37.5615C26.8191 37.3089 26.8944 37.0231 26.8936 36.7324C26.8928 36.4417 26.8159 36.1563 26.6705 35.9046L19.6202 24.1346C19.4718 23.89 19.2629 23.6877 19.0135 23.5474C18.7642 23.407 18.4829 23.3333 18.1968 23.3333C17.9107 23.3333 17.6294 23.407 17.3801 23.5474C17.1307 23.6877 16.9218 23.89 16.7734 24.1346Z"
          fill="#FAFAFA"
          stroke="#141414"
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.1968 28.4133V31.7429"
          stroke="#141414"
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.1968 35.0723H18.2051"
          stroke="#141414"
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  )
}
