export const isMobileDevice = () => {
  return window.innerWidth <= 810
}

export const isWebviewApp = (path) => {
  if (localStorage.getItem("from_webview_app") === "true") {
    return true
  }

  const isWebview = path.includes("/webview")
  localStorage.setItem("from_webview_app", isWebview)
  return isWebview
}

export const redirectToMobileDashboard = () => {
  const refreshToken = localStorage.getItem("refresh_token")
  window.location.replace(
    `https://${window.location.hostname}/#/webview_access/${refreshToken}`
  )

  // Limpa local storage do webview do APP, quando o usuário
  // criou por ele, o mesmo guarda a access_token do usuário e não
  // deixa o usuário abrir a tela de cadastro novamente.
  localStorage.removeItem("access_token")
  localStorage.removeItem("refresh_token")
}
