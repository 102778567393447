import React from "react"

export const SignatureIcon = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        width="33"
        height="32"
        viewBox="0 0 33 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_1273_52514"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="32"
          height="32"
        >
          <rect width="32" height="32" fill="white" />
        </mask>
        <g mask="url(#mask0_1273_52514)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.9117 12.8134C23.8668 12.7145 23.8108 12.6202 23.732 12.54L18.232 6.93997C18.1532 6.8597 18.0606 6.80277 17.9634 6.75704C17.9359 6.74397 17.9066 6.7365 17.8773 6.72624C17.8003 6.7001 17.7214 6.6833 17.6398 6.67864C17.6197 6.67677 17.6023 6.6665 17.583 6.6665H11.1663C10.1553 6.6665 9.33301 7.5037 9.33301 8.53317V23.4665C9.33301 24.496 10.1553 25.3332 11.1663 25.3332H22.1663C23.1774 25.3332 23.9997 24.496 23.9997 23.4665V13.1998C23.9997 13.1802 23.9896 13.1625 23.9878 13.142C23.9832 13.0598 23.9676 12.9796 23.9419 12.9012C23.9318 12.8713 23.9236 12.8424 23.9117 12.8134ZM18.4997 12.2665V9.8529L20.8702 12.2665H18.4997ZM11.1663 8.53317H16.6663V13.1998C16.6663 13.716 17.0761 14.1332 17.583 14.1332H22.1663L22.1682 23.4665H11.1663V8.53317Z"
            fill="#676767"
          />
        </g>
        <line
          x1="12.5"
          y1="18.5"
          x2="18.5"
          y2="18.5"
          stroke="#676767"
          strokeLinecap="round"
        />
        <line
          x1="12.5"
          y1="16.5"
          x2="20.5"
          y2="16.5"
          stroke="#676767"
          strokeLinecap="round"
        />
        <line
          x1="12.5"
          y1="14.5"
          x2="15.5"
          y2="14.5"
          stroke="#676767"
          strokeLinecap="round"
        />
        <line
          x1="12.5"
          y1="12.5"
          x2="15.5"
          y2="12.5"
          stroke="#676767"
          strokeLinecap="round"
        />
        <path
          d="M20 21.5C20 21.5 15.0968 22.625 16 21.5C16.9032 20.375 12 21.5 12 21.5"
          stroke="#676767"
        />
        <mask
          id="mask1_1273_52514"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="17"
          y="7"
          width="16"
          height="16"
        >
          <rect x="17" y="7" width="16" height="16" fill="white" />
        </mask>
        <g mask="url(#mask1_1273_52514)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.0931 18.3577L19.9497 19.9349C19.9166 20.299 20.2217 20.604 20.5858 20.5709L22.163 20.4276L28.7866 13.804L26.7167 11.7341L20.0931 18.3577ZM30.4425 12.1481C30.6711 11.9195 30.6711 11.5488 30.4425 11.3201L29.2005 10.0782C28.9719 9.84958 28.6012 9.84958 28.3726 10.0782L27.1307 11.3201L29.2005 13.39L30.4425 12.1481Z"
            fill="#676767"
          />
        </g>
      </svg>
    </span>
  )
}
