import React from "react"

export const CreditCardIcon = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_1655_56761"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="32"
          height="32"
        >
          <rect width="32" height="32" fill="white" />
        </mask>

        <g mask="url(#mask0_1655_56761)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.66667 9.33325C8.7475 9.33325 8 10.0808 8 10.9999V20.9999C8 21.9191 8.7475 22.6666 9.66667 22.6666H23C23.9192 22.6666 24.6667 21.9191 24.6667 20.9999V10.9999C24.6667 10.0808 23.9192 9.33325 23 9.33325H9.66667ZM23 10.9999V12.6666H9.66667V10.9999H23ZM9.66667 15.9999H23.0008L23.0017 20.9999H9.66667V15.9999ZM17.1667 17.6666V19.3333H11.3333V17.6666H17.1667Z"
            fill="#676767"
          />
        </g>
      </svg>
    </span>
  )
}
