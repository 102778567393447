import { Col, Dropdown, Form, Row, Select } from "antd"
import React, { useEffect, useState } from "react"
import FlattedInput from "../../../../common/components/FlattedInput"
import PropTypes from "prop-types"
import { DatePicker } from "../../../../common/components/DatePicker"
import { formatDate } from "../../../../utils/formaters"

const SelectPaymentMethod = ({ form, label, placeholder, rules }) => {
  const [value, updateValue] = useState()
  const [startsAt, updateStartsAt] = useState()
  const [endsAt, updateEndsAt] = useState()

  useEffect(() => {
    const value = form.getFieldValue("payment_method")
    updateValue(value)

    form.setFieldsValue({
      payment_type:
        value === 0 ? form.getFieldValue("payment_type") : undefined,
      payment_percent:
        value === 1 ? form.getFieldValue("payment_percent") : undefined,
      payment_parcels:
        value === 2 ? form.getFieldValue("payment_parcels") : undefined,
      starts_at: value === 3 ? form.getFieldValue("starts_at") : undefined,
      ends_at: value === 3 ? form.getFieldValue("ends_at") : undefined,
    })
  }, [value])

  return (
    <Row gutter={[16, 16]}>
      <Col sm={8}>
        <Form.Item
          name="payment_method"
          label={label ?? "Forma de pagamento"}
          rules={rules ?? []}
        >
          <Select
            onChange={(value) => updateValue(value)}
            placeholder={placeholder ?? "Selecione uma opção"}
          >
            <Select.Option value={0}>À vista</Select.Option>
            <Select.Option value={4}>Pix</Select.Option>
            <Select.Option value={1}>Ato / Entrega</Select.Option>
            <Select.Option value={2}>Parcelado</Select.Option>
            <Select.Option value={3}>Recorrente</Select.Option>
          </Select>
        </Form.Item>
      </Col>
      {value === 0 && (
        <Col sm={8}>
          <Form.Item
            name="payment_type"
            label="Tipo de pagamento"
            initialValue=""
          >
            <FlattedInput placeholder="Ex.: Pix, Dinheiro, Cartão, Transferência Bancára, etc." />
          </Form.Item>
        </Col>
      )}

      {value === 1 && (
        <Col sm={8}>
          <Form.Item
            name="payment_percent"
            label="Detalhar porcentagem de pagamento"
            initialValue=""
          >
            <FlattedInput placeholder="Inserir descrição" />
          </Form.Item>
        </Col>
      )}

      {value === 2 && (
        <Col sm={8}>
          <Form.Item
            name="payment_parcels"
            label="Quantidade de parcelas"
            initialValue=""
          >
            <Select placeholder="6x">
              {[2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((num, index) => (
                <Select.Option value={`${num}x`} key={index}>
                  {num}x
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      )}

      {value === 3 && (
        <>
          <Col sm={8}>
            <Dropdown
              overlay={
                <DatePicker
                  className="floating"
                  date={startsAt}
                  minDate={new Date()}
                  onChange={(date) => {
                    form.setFieldsValue({ starts_at: formatDate(date) })
                    updateStartsAt(date)
                  }}
                />
              }
              trigger={["click"]}
              placement="bottomRight"
            >
              <Form.Item
                initialValue=""
                name="starts_at"
                label="Data início"
                rules={[
                  {
                    required: true,
                    message: <div className="normal-12">Campo obrigatório</div>,
                  },
                ]}
              >
                <FlattedInput readOnly placeholder="Inserir data" />
              </Form.Item>
            </Dropdown>
          </Col>

          <Col sm={8}>
            <Dropdown
              overlay={
                <DatePicker
                  className="floating"
                  date={endsAt}
                  minDate={new Date()}
                  onChange={(date) => {
                    form.setFieldsValue({ ends_at: formatDate(date) })
                    updateEndsAt(date)
                  }}
                />
              }
              trigger={["click"]}
              placement="bottomRight"
            >
              <Form.Item initialValue="" name="ends_at" label="Data término">
                <FlattedInput readOnly placeholder="Inserir data" />
              </Form.Item>
            </Dropdown>
          </Col>
        </>
      )}

      {value === 4 && (
        <Col sm={8}>
          <Form.Item
            name="payment_pix_key"
            label="Chave Pix"
            initialValue=""
            rules={[
              {
                required: false,
                message: <div className="normal-12">Campo obrigatório</div>,
              },
            ]}
          >
            <FlattedInput placeholder="Insira a chave pix" />
          </Form.Item>
        </Col>
      )}
    </Row>
  )
}

SelectPaymentMethod.propTypes = {
  form: PropTypes.any.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  rules: PropTypes.array,
}

export default SelectPaymentMethod
