import React from "react"
import PropTypes from "prop-types"
import "./styles.scss"

const tagColors = [
  { color: "#4F4312", backgroundColor: "#FAE681" },
  { color: "#6E2A13", backgroundColor: "#FEC1A8" },
  { color: "#35187F", backgroundColor: "#DFD2FD" },
  { color: "#651A2F", backgroundColor: "#FACDDB" },
  { color: "#0F2E64", backgroundColor: "#ADC8F1" },
]

const ProposalTag = ({ name }) => {
  const sumCharValues = (value) => {
    /** Soma o valor numerico de cada caracter */
    let sumCharsCode = 0
    value.split("").forEach((char, index) => {
      sumCharsCode += char.charCodeAt(0)
      // console.log({ tag, sumCharsCode })
    })

    sumCharsCode += value.length
    return sumCharsCode
  }

  const buildTagStyle = () => {
    const sumCharsCode = sumCharValues(name)
    const index = parseInt(sumCharsCode % tagColors.length)

    return {
      color: tagColors[index].color,
      backgroundColor: tagColors[index].backgroundColor,
    }
  }

  return (
    <span className="container-proposal-tag" style={buildTagStyle()}>
      {name}
    </span>
  )
}

ProposalTag.propTypes = {
  name: PropTypes.string,
}

export default ProposalTag
