import React from "react"
import Icons from "../../components/Icons"
// import { useDropzone } from "react-dropzone"
import { Row, Upload } from "antd"
import PropTypes from "prop-types"
import "./styles.scss"

const { Dragger } = Upload

// const FileUploadBox = ({ onDrop, maxSize, onDropRejected }) => {
//   const { getRootProps, getInputProps, isDragActive } = useDropzone({
//     onDrop,
//     maxSize,
//     onDropRejected,
//   })

//   return (
//     <div className="file-upload-box" {...getRootProps()}>
//       <input {...getInputProps()} />
//       <div className="item-file-box icon">
//         <Icons icon="upload-cloud"></Icons>
//       </div>
//       <div className="item-file-box label">
//         {isDragActive ? "Solte os arquivos aqui ..." : "Selecionar arquivo"}
//       </div>
//     </div>
//   )
// }

const FileUploadBox = ({ icon, ...props }) => {
  return (
    <Dragger
      className="file-upload-box"
      showUploadList={{
        showDownloadIcon: false,
        showPreviewIcon: false,
        showRemoveIcon: true,
        removeIcon: <Icons icon="delete" />,
      }}
      {...props}
    >
      <Row justify="center" className="icon">
        {<Icons icon={icon} /> ?? <Icons icon="upload-cloud" />}
      </Row>
      <Row justify="center" className="label">
        Clique ou solte os arquivos aqui
      </Row>
    </Dragger>
  )
}

FileUploadBox.propTypes = {
  icon: PropTypes.string,
  // onDrop: PropTypes.func,
  // maxSize: PropTypes.number,
  // onDropRejected: PropTypes.func,
}

export { FileUploadBox }
