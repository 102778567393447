import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import StateStatus from "../../utils/stateStatus"
import { editContact, addContact, getSectors } from "./contactService"

export const updateContact = createAsyncThunk(
  "contact/edit",
  async ({ contactId, data }) => {
    const response = await editContact(contactId, data)
    return response.data
  }
)

export const createContact = createAsyncThunk(
  "contact/create",
  async (data) => {
    const response = await addContact(data)
    return response.data
  }
)

export const listContactParams = createAsyncThunk(
  "contact/list_params",
  async () => {
    const response = await getSectors()
    return {
      sectors: response.data.data.items,
    }
  }
)

const initialState = {
  status: {
    listParams: StateStatus.idle,
    saveContact: StateStatus.idle,
  },
  data: {},
  sectors: [],
  errMessage: null,
}

export const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {
    resetContactStatus: (state, action) => {
      state.status.saveContact = StateStatus.idle
    },
  },
  extraReducers: {
    [createContact.pending]: (state) => {
      state.status.saveContact = StateStatus.loading
    },
    [createContact.fulfilled]: (state, action) => {
      state.status.saveContact = StateStatus.succeeded
      state.data = action.payload.data
    },
    [createContact.rejected]: (state, action) => {
      state.status.saveContact = StateStatus.failed
      state.errMessage = action.error.message
    },
    [updateContact.pending]: (state) => {
      state.status.saveContact = StateStatus.loading
    },
    [updateContact.fulfilled]: (state, action) => {
      state.status.saveContact = StateStatus.succeeded
      state.data = action.payload.data
    },
    [updateContact.rejected]: (state, action) => {
      state.status.saveContact = StateStatus.failed
      state.errMessage = action.error.message
    },
    [listContactParams.pending]: (state) => {
      state.status.listParams = StateStatus.loading
    },
    [listContactParams.fulfilled]: (state, action) => {
      state.sectors = action.payload.sectors
      state.status.listParams = StateStatus.succeeded
    },
    [listContactParams.rejected]: (state, action) => {
      state.status.listParams = StateStatus.failed
      state.errMessage = action.error.message
    },
  },
})

export const { resetContactStatus } = contactSlice.actions

export const selectContactState = (state) => state.contact
