import { sectorsMock } from "../sectors"

export const influencerJson = {
  steps: [
    {
      type: "Route",
      step_title: "Dados da campanha",
      props: {
        path: ["/dashboard/proposal/1", "/dashboard/proposal/1/:id"],
        exact: true,
      },
      elements: [
        {
          type: "p",
          default: "Título da proposta",
          props: {
            className: "input-title",
          },
        },
        {
          type: "Row",
          props: {
            className: "row-input-tittle",
            justify: "start",
            gutter: [16, 16],
            align: "bottom",
          },
          elements: [
            {
              type: "Col",
              props: {
                span: 16,
              },
              elements: [
                {
                  type: "Form.Item",
                  props: {
                    name: "title",
                    label: "",
                    initialValue: "",
                    rules: [
                      {
                        required: true,
                        message: "Campo obrigatório",
                      },
                    ],
                  },
                  element: {
                    type: "FlattedInput",
                    props: {
                      placeholder: "Digite o título",
                    },
                  },
                },
              ],
            },
          ],
        },

        {
          type: "Space",
        },

        {
          type: "p",
          default: "Tipos de campanha",
          props: {
            className: "input-title",
          },
        },

        {
          type: "Row",
          props: {
            justify: "space-between",
            align: "top",
            gutter: [16, 0],
          },
          elements: [
            // segment
            {
              type: "Col",
              props: {
                sm: 8,
              },
              elements: [
                {
                  type: "Form.Item",
                  props: {
                    name: "segment_id",
                    label: "Selecione qual o tipo da campanha",
                    rules: [
                      {
                        required: true,
                        message: "Campo obrigatório",
                      },
                    ],
                  },
                  element: {
                    type: "Select",
                    props: {
                      name: "segment_id",
                      placeholder: "Selecione o segmento",
                    },
                    options: [
                      { value: "Tecnologia", label: "Tecnologia" },
                      {
                        value: "Saúde e bem-estar",
                        label: "Saúde e bem-estar",
                      },
                      { value: "Moda e estilo", label: "Moda e estilo" },
                      {
                        value: "Alimentação e gastronomia",
                        label: "Alimentação e gastronomia",
                      },
                      {
                        value: "Viagens e turismo",
                        label: "Viagens e turismo",
                      },
                      {
                        value: "Beleza e cuidados pessoais",
                        label: "Beleza e cuidados pessoais",
                      },
                      {
                        value: "Fitness e esportes",
                        label: "Fitness e esportes",
                      },
                      {
                        value: "Educação e aprendizado",
                        label: "Educação e aprendizado",
                      },
                      { value: "Arte e cultura", label: "Arte e cultura" },
                      { value: "Automobilismo", label: "Automobilismo" },
                      {
                        value: "Negócios e empreendedorismo",
                        label: "Negócios e empreendedorismo",
                      },
                      {
                        value: "Música e entretenimento",
                        label: "Música e entretenimento",
                      },
                      {
                        value: "Maternidade e paternidade",
                        label: "Maternidade e paternidade",
                      },
                      {
                        value: "Sustentabilidade e meio ambiente",
                        label: "Sustentabilidade e meio ambiente",
                      },
                      {
                        value: "Fotografia e videografia",
                        label: "Fotografia e videografia",
                      },
                      {
                        value: "Finanças e investimentos",
                        label: "Finanças e investimentos",
                      },
                      { value: "Imobiliário", label: "Imobiliário" },
                      {
                        value: "Games e entretenimento digital",
                        label: "Games e entretenimento digital",
                      },
                      {
                        value: "Agricultura e agronegócio",
                        label: "Agricultura e agronegócio",
                      },
                      { value: "Pet care", label: "Pet care" },
                      { value: "Moda sustentável", label: "Moda sustentável" },
                      {
                        value: "Decoração e design de interiores",
                        label: "Decoração e design de interiores",
                      },
                      {
                        value: "Marketing e publicidade",
                        label: "Marketing e publicidade",
                      },
                      {
                        value: "Esportes radicais",
                        label: "Esportes radicais",
                      },
                      {
                        value: "Livros e literatura",
                        label: "Livros e literatura",
                      },
                      { value: "Artesanato", label: "Artesanato" },
                      {
                        value: "Culinária e receitas",
                        label: "Culinária e receitas",
                      },
                      {
                        value: "Design e arquitetura",
                        label: "Design e arquitetura",
                      },
                      {
                        value: "Cinema, filmes e séries",
                        label: "Cinema, filmes e séries",
                      },
                    ],
                  },
                },
              ],
            },

            // campaign name
            {
              type: "Col",
              props: {
                sm: 8,
              },
              elements: [
                {
                  type: "Form.Item",
                  props: {
                    label: "Nome da Campanha",
                    name: "campaign_name",
                    rules: [
                      {
                        required: true,
                        message: "Campo obrigatório",
                      },
                    ],
                  },
                  element: {
                    type: "FlattedInput",
                    props: {
                      placeholder: "Nome",
                    },
                  },
                },
              ],
            },

            // valid until
            {
              type: "Col",
              props: {
                sm: 8,
              },
              elements: [
                {
                  type: "Dropdown",
                  props: {
                    trigger: ["click"],
                    placement: "bottomRight",
                    overlay: {
                      type: "DatePicker",
                      props: {
                        className: "floating",
                        storeValue: "form.valid_until",
                        defaultValue: "form.valid_until",
                      },
                    },
                  },
                  element: {
                    type: "Form.Item",
                    props: {
                      name: "valid_until",
                      label: "Validade da proposta",
                      rules: [
                        {
                          required: true,
                          message: "Campo obrigatório",
                        },
                      ],
                    },
                    element: {
                      type: "FlattedInput",
                      props: {
                        readOnly: true,
                        placeholder: "Inserir data",
                      },
                    },
                  },
                },
              ],
            },
          ],
        },

        {
          type: "Space",
          props: {},
        },

        // title | text
        {
          type: "p",
          default: "Descrição da campanha",
          props: {
            className: "title-textarea",
          },
        },

        {
          type: "Form.Item",
          props: {
            name: "campaign_description",
          },
          element: {
            type: "TextArea",
            props: {
              placeholder: "Descrição",
            },
          },
        },

        // space - divider
        {
          type: "Divider",
          props: {
            className: "divider-margin-top",
          },
        },

        // *********************
        // ** CAMPAIGN FORMAT **
        // *********************

        // title | text
        {
          type: "p",
          default: "Formato da campanha",
          props: {
            className: "input-title",
          },
        },

        // subtitle | text
        {
          type: "p",
          default:
            "Selecione um ou mais tipos de redes sociais e formatos de publicação para a campanha",
          props: {
            className: "input-subtitle",
          },
        },

        // rede sociais | checkbox
        {
          type: "Form.Item",
          props: {
            name: "campaign_format_social_media",
            initialValue: false,
            valuePropName: "checked",
          },
          element: {
            type: "CheckBox",
            props: {
              storeValue: "state.campaign_format_social_media",
            },
            text: "Redes sociais",
          },
        },

        // shooting | checkbox
        {
          type: "Form.Item",
          props: {
            name: "campaign_format_shooting",
            initialValue: false,
            valuePropName: "checked",
          },
          element: {
            type: "CheckBox",
            text: "Shooting",
          },
        },

        // presential | checkbox
        {
          type: "Form.Item",
          props: {
            name: "campaign_format_presential",
            initialValue: false,
            valuePropName: "checked",
          },
          element: {
            type: "CheckBox",
            text: "Presencial",
          },
        },
        // podcast | checkbox
        {
          type: "Form.Item",
          props: {
            name: "campaign_format_podcast",
            initialValue: false,
            valuePropName: "checked",
          },
          element: {
            type: "CheckBox",
            text: "Podcast",
          },
        },
        // live | checkbox
        {
          type: "Form.Item",
          props: {
            name: "campaign_format_live",
            initialValue: false,
            valuePropName: "checked",
          },
          element: {
            type: "CheckBox",
            text: "Live (ao vivo)",
          },
        },

        {
          type: "Space",
          conditional: {
            name: "campaign_format_social_media",
            equal: true,
          },
          props: {},
        },

        // type - quantity - unit -  | list inputs
        {
          type: "Form.List",
          props: {
            name: "items",
            initialValue: [{ type: null, quantity: null, unit: null }],
          },
          conditional: {
            name: "campaign_format_social_media",
            equal: true,
          },
          addButton: {
            type: "Row",
            props: {
              justify: "start",
              align: "bottom",
              style: { height: 60 },
            },
            elements: [
              {
                type: "WhiteButton",
                props: {
                  type: "button",
                  style: { height: 40, width: "250px" },
                },
                elements: [
                  {
                    type: "text",
                    default: "+ Adicionar rede social",
                  },
                ],
              },
            ],
          },
          removeButton: {
            type: "Row",
            props: {
              justify: "center",
              align: "middle",
              style: { height: 70 },
            },
            elements: [
              {
                type: "WhiteButton",
                props: {
                  type: "button",
                  style: {},
                },
                elements: [
                  {
                    type: "text",
                    default: "Remover",
                  },
                ],
              },
            ],
          },
          fields: [
            // type / social network
            {
              type: "Col",
              props: {
                sm: 8,
              },
              elements: [
                {
                  type: "Form.Item",
                  props: {
                    name: "type",
                    label: "Rede social",
                    rules: [
                      {
                        required: true,
                        message: "Campo obrigatório",
                      },
                    ],
                  },
                  element: {
                    type: "Select",
                    props: {
                      name: "type",
                      placeholder: "Selecione a rede",
                    },
                    options: [
                      {
                        label: "Facebook",
                        value: "Facebook",
                      },
                      {
                        label: "Linkedin",
                        value: "Linkedin",
                      },
                      {
                        label: "Pinterest",
                        value: "Pinterest",
                      },
                      {
                        label: "Instagram",
                        value: "Instagram",
                      },
                      {
                        label: "TikTok",
                        value: "TikTok",
                      },
                      {
                        label: "Twitch",
                        value: "Twitch",
                      },
                      {
                        label: "Twitter",
                        value: "Twitter",
                      },
                      {
                        label: "Youtube",
                        value: "Youtube",
                      },
                    ],
                  },
                },
              ],
            },

            {
              type: "Col",
              props: {
                sm: 8,
              },
              elements: [
                {
                  type: "Form.Item",
                  props: {
                    name: "quantity",
                    label: "Quantidade",
                    rules: [
                      {
                        required: true,
                        message: "Campo obrigatório",
                      },
                    ],
                  },
                  element: {
                    type: "FlattedInputNumber",
                    props: {
                      placeholder: "Quantidade",
                    },
                  },
                },
              ],
            },
            {
              type: "Col",
              props: {
                sm: 8,
              },
              elements: [
                {
                  type: "Form.Item",
                  props: {
                    name: "unit",
                    label: "Tipo de Postagem",
                    rules: [
                      {
                        required: true,
                        message: "Campo obrigatório",
                      },
                    ],
                  },
                  element: {
                    type: "Select",
                    props: {
                      name: "unit",
                      placeholder: "Selecione a postagem",
                    },
                    options: [
                      {
                        label: "Post Feed",
                        value: "Post Feed",
                      },
                      {
                        label: "Live",
                        value: "Live",
                      },
                      {
                        label: "Pin (feed)",
                        value: "Pin",
                      },
                      {
                        label: "Idea Pin (video 60s)",
                        value: "Idea Pin",
                      },
                      {
                        label: "Carrossel de fotos",
                        value: "Carrossel de fotos",
                      },
                      {
                        label: "Reels",
                        value: "Reels",
                      },
                      {
                        label: "Vídeo",
                        value: "Vídeo",
                      },
                      {
                        label: "Shorts (video 60s)",
                        value: "Shorts",
                      },
                      {
                        label: "Stories",
                        value: "Stories",
                      },
                      {
                        label: "Sequência de 1 Stories",
                        value: "Sequência de 1 Stories",
                      },
                      {
                        label: "Sequência de 2 Stories",
                        value: "Sequência de 2 Stories",
                      },
                      {
                        label: "Sequência de 3 Stories",
                        value: "Sequência de 3 Stories",
                      },
                      {
                        label: "Sequência de 4 Stories",
                        value: "Sequência de 4 Stories",
                      },
                      {
                        label: "Sequência de 5 Stories",
                        value: "Sequência de 5 Stories",
                      },
                      {
                        label: "Sequência de 6 Stories",
                        value: "Sequência de 6 Stories",
                      },
                      {
                        label: "Cobertura",
                        value: "Cobertura",
                      },
                      {
                        label: "Thumb",
                        value: "Thumb",
                      },
                    ],
                  },
                },
              ],
            },
          ],
        },

        {
          type: "Space",
          conditional: {
            name: "campaign_format_social_media",
            equal: true,
          },
          props: {},
        },

        // observations | input
        {
          type: "Form.Item",
          props: {
            name: "campaign_format_observations",
          },
          rules: [
            {
              required: true,
              message: "Campo obrigatório",
            },
          ],
          element: {
            type: "TextArea",
            props: {
              placeholder: "Observações",
            },
          },
        },

        // space - divider
        // {
        //   type: "DoubleSpace",
        //   props: {},
        // },
        {
          type: "Divider",
          props: {
            className: "divider-margin-top",
          },
        },

        // *********************
        // ******* BOOST *******
        // *********************
        // title | text
        {
          type: "p",
          default: "Impulsionar",
          props: {
            className: "input-title",
          },
        },

        // subtitle | text
        {
          type: "p",
          default:
            "Selecione a opção para que as publicações sejam impulsionadas",
          props: {
            className: "input-subtitle",
          },
        },

        // boost | checkbox
        {
          type: "Form.Item",
          props: {
            name: "has_boost",
            initialValue: false,
            valuePropName: "checked",
          },
          element: {
            type: "CircleCheckBox",
            text: "Impulsionar publicação",
          },
        },

        {
          type: "Form.Item",
          props: {
            name: "boost_observations",
          },
          rules: [
            {
              required: true,
              message: "Campo obrigatório",
            },
          ],
          element: {
            type: "TextArea",
            props: {
              placeholder: "Observações",
            },
          },
        },

        // divider
        {
          type: "Divider",
          props: {
            className: "divider-margin-top",
          },
        },

        // ********************
        // ****** REPOST ******
        // ********************

        // title | text
        {
          type: "p",
          default: "Repostar publicações",
          props: {
            className: "input-title",
          },
        },

        // subtitle | text
        {
          type: "p",
          default:
            "Selecione a opção para que as publicações sejam respostadas em outras redes sociais",
          props: {
            className: "input-subtitle",
          },
        },

        // repost | checkbox
        {
          type: "Form.Item",
          props: {
            name: "has_repost",
            initialValue: false,
            valuePropName: "checked",
          },
          element: {
            type: "CircleCheckBox",
            text: "Rede do cliente",
          },
        },

        {
          type: "Form.Item",
          props: {
            name: "repost_observations",
          },
          rules: [
            {
              required: true,
              message: "Campo obrigatório",
            },
          ],
          element: {
            type: "TextArea",
            props: {
              placeholder: "Observações",
            },
          },
        },

        // divider
        {
          type: "Divider",
          props: {
            className: "divider-margin-top",
            style: {
              marginTop: 32,
            },
          },
        },

        // **********************
        // *** CAMPAIGN DATES ***
        // **********************

        // title | text
        {
          type: "p",
          default: "Data das publicações",
          props: {
            className: "input-title",
          },
        },

        // subtitle | text
        {
          type: "p",
          default: "Configure as datas de postagem dos anúncios",
          props: {
            className: "input-subtitle",
          },
        },

        // campaign_starts_at campaign_ends_at | inputs
        {
          type: "Row",
          props: {
            gutter: [16, 16],
            align: "top",
          },
          elements: [
            // campaign_starts_at | dropdown
            {
              type: "Col",
              props: {
                sm: 8,
              },
              elements: [
                {
                  type: "Dropdown",
                  props: {
                    trigger: ["click"],
                    placement: "bottomRight",
                    overlay: {
                      type: "DatePicker",
                      onupdate: [
                        {
                          type: "clean",
                          storeValue: "form.campaign_ends_at",
                        },
                      ],
                      props: {
                        className: "floating",
                        storeValue: "form.campaign_starts_at",
                        defaultValue: "form.campaign_starts_at",
                        minDate: "01/01/1990",
                      },
                    },
                  },
                  element: {
                    type: "Form.Item",
                    props: {
                      name: "campaign_starts_at",
                      rules: [
                        {
                          required: true,
                          message: "Campo obrigatório",
                        },
                      ],
                    },

                    element: {
                      type: "FlattedLabelInput",
                      props: {
                        readOnly: true,
                        placeholder: "Data",
                        label: "Começa",
                      },
                    },
                  },
                },
              ],
            },

            {
              type: "Col",
              props: {
                sm: 8,
              },
              elements: [
                // campaign_ends_at | dropdown
                {
                  type: "Dropdown",
                  conditional: {
                    name: "campaign_starts_at",
                    diff: undefined,
                    then: "disabled",
                  },
                  props: {
                    trigger: ["click"],
                    placement: "bottomRight",
                    overlay: {
                      type: "DatePicker",
                      props: {
                        className: "floating",
                        storeValue: "form.campaign_ends_at",
                        defaultValue: "form.campaign_ends_at",
                        minDate: "form.campaign_starts_at",
                      },
                    },
                  },
                  element: {
                    type: "Form.Item",
                    props: {
                      name: "campaign_ends_at",
                      rules: [
                        {
                          required: true,
                          message: "Campo obrigatório",
                        },
                      ],
                    },
                    element: {
                      type: "FlattedLabelInput",
                      conditional: {
                        name: "campaign_starts_at",
                        diff: undefined,
                        then: "disabled",
                      },
                      props: {
                        readOnly: true,
                        placeholder: "Data",
                        label: "Termina",
                      },
                    },
                  },
                },
              ],
            },
          ],
        },

        // divider
        {
          type: "Divider",
          props: {
            className: "divider-margin-top",
          },
        },

        // ***********************
        // *** HIGHLIGHT POSTS ***
        // ***********************

        // title | text
        {
          type: "p",
          default: "Destacar publicacões",
          props: {
            className: "input-title",
          },
        },

        // subtitle | text
        {
          type: "p",
          default:
            "Selecione a opção se as publicacoes serao destacadas nas redes sociais",
          props: {
            className: "input-subtitle",
          },
        },

        // highlight posts | checkbox
        {
          type: "Form.Item",
          props: {
            name: "highlight_post",
            initialValue: false,
            valuePropName: "checked",
          },
          element: {
            type: "Radio",
            element: {
              type: "text",
              default: "Destacar nos favoritos",
            },
          },
        },

        // divider
        {
          type: "Divider",
          props: {},
        },

        // ************************
        // *** SCHEDULE MEETING ***
        // ************************

        // title | text
        // {
        //   type: "p",
        //   default: "Agendar reunião",
        //   props: {
        //     className: "input-title",
        //   },
        // },

        // // subtitle | text
        // {
        //   type: "p",
        //   default:
        //     "Selecione a opção abaixo se deseja realizar um bate papo com o contratante para poder acertar todos os detalhes da proposta",
        //   props: {
        //     className: "input-subtitle",
        //   },
        // },

        // schedule meeting | checkbox
        // {
        //   type: "Form.Item",
        //   props: {
        //     name: "schedule_meeting",
        //     initialValue: false,
        //     valuePropName: "checked",
        //   },
        //   element: {
        //     type: "RadioScheduleMeeting",
        //     element: {
        //       type: "text",
        //       default: "Agendar Reunião",
        //     },
        //   },
        // },

        // // divider
        // {
        //   type: "Divider",
        //   props: {},
        // },

        // ************************
        // *** SCHEDULE MEETING ***
        // ************************

        // title | text
        {
          type: "p",
          default: "Adicione um arquivo",
          props: {
            className: "input-title",
          },
        },

        // subtitle | text
        {
          type: "p",
          default:
            "Carregue arquivos de áudio, imagem ou texto de tamanho máximo de 5MB",
          props: {
            className: "input-subtitle",
          },
        },

        // select file | select
        {
          type: "SelectFile",
          props: {
            storeValue: "state.files",
          },
        },

        // space - divider
        {
          type: "Space",
          props: {},
        },
        {
          type: "Divider",
          props: {},
        },

        // **********************
        // *** PROPOSAL VALUE ***
        // **********************

        // title | text
        {
          type: "p",
          default: "Valor da proposta",
          props: {
            className: "input-title",
          },
        },

        // subtitle | text
        {
          type: "p",
          default: "Adicione as informações de faturamento da proposta",
          props: {
            className: "input-subtitle",
          },
        },

        {
          type: "Row",
          props: {
            justify: "start",
            align: "bottom",
            gutter: [16, 16],
          },
          elements: [
            {
              type: "Col",
              props: {
                sm: 7,
              },
              elements: [
                {
                  type: "Form.Item",
                  props: {
                    name: "days_to_payment",
                    label: "Prazo de pagamento",
                    rules: [
                      {
                        required: true,
                        message: "Campo obrigatório",
                      },
                    ],
                  },
                  element: {
                    type: "FlattedInputNumber",
                    props: {
                      placeholder: "Nº de dias",
                    },
                  },
                },
              ],
            },

            {
              type: "Col",
              props: {
                sm: 7,
              },
              elements: [
                {
                  type: "Form.Item",
                  props: {
                    name: "days_to_payment_type",
                    label: "Tipo de dias",
                    rules: [
                      {
                        required: true,
                        message: "Campo obrigatório",
                      },
                    ],
                  },
                  element: {
                    type: "RadioGroup",
                    props: {},
                    elements: [
                      {
                        type: "RadioButton",
                        props: {
                          value: "util",
                        },
                        element: {
                          type: "text",
                          default: "Úteis",
                        },
                      },
                      {
                        type: "RadioButton",
                        props: {
                          value: "corrido",
                        },
                        element: {
                          type: "text",
                          default: "Corridos",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          ],
        },

        {
          type: "Space",
          props: {},
        },

        {
          type: "Row",
          props: {
            justify: "start",
            align: "bottom",
            gutter: [16, 16],
          },
          elements: [
            {
              type: "Col",
              props: {
                sm: 7,
              },
              elements: [
                {
                  type: "Form.Item",
                  props: {
                    label: "Valor total",
                    name: "total_value",
                    rules: [
                      {
                        required: true,
                        message: "Campo obrigatório",
                      },
                    ],
                  },
                  element: {
                    type: "FlattedInputCurrency",
                    props: {
                      placeholder: "R$ 0,00",
                    },
                  },
                },
              ],
            },
            {
              type: "Col",
              props: {
                sm: 7,
              },
              elements: [
                {
                  type: "Dropdown",
                  props: {
                    trigger: ["click"],
                    placement: "bottomRight",
                    overlay: {
                      type: "DatePicker",
                      props: {
                        className: "floating",
                        storeValue: "form.billing_date",
                        defaultValue: "form.billing_date",
                      },
                    },
                  },
                  element: {
                    type: "Form.Item",
                    props: {
                      name: "billing_date",
                      label: "Faturamento",
                      rules: [
                        {
                          required: true,
                          message: "Campo obrigatório",
                        },
                      ],
                    },
                    element: {
                      type: "FlattedInput",
                      props: {
                        readOnly: true,
                        placeholder: "Selecione a data",
                      },
                    },
                  },
                },
              ],
            },
          ],
        },

        // divider
        {
          type: "Divider",
          props: {
            className: "divider-margin-top",
          },
        },

        // company | select

        {
          type: "SelectCpfCnpj",
          props: {
            name: "cpf_cnpj",
            label: "CNPJ",
            rules: [
              {
                required: true,
                message: "Campo obrigatório",
              },
            ],
          },
        },

        {
          type: "SelectBank",
          props: {
            name: "bank_account_id",
            label: "Conta bancária",
            placeholder: "Selecione a Conta",
          },
          options: [],
        },

        {
          type: "Space",
          props: {},
        },

        // title | text
        {
          type: "p",
          default: "Observações gerais",
          props: {
            className: "title-textarea",
          },
        },

        {
          type: "Form.Item",
          props: {
            name: "general_observations",
          },
          rules: [
            {
              required: true,
              message: "Campo obrigatório",
            },
          ],
          element: {
            type: "TextArea",
            props: {
              placeholder: "Observações",
            },
          },
        },
      ],
      actions: [
        {
          type: "ButtonPreviousStep",
          props: {
            span: 12,
          },
          elements: [
            {
              type: "text",
              default: "Voltar",
            },
          ],
        },

        {
          type: "ButtonNextStep",
          props: {
            span: 12,
          },
          elements: [
            {
              type: "text",
              default: "Continuar",
            },
          ],
        },
      ],
    },

    // ---------------------------------------
    // ---------------------------------------
    // ------------- SECOND STEP -------------
    // ---------------------------------------
    // ---------------------------------------
    {
      type: "Route",
      step_title: "Dados da contratante",
      props: {
        path: ["/dashboard/proposal/2", "/dashboard/proposal/2/:id"],
      },
      elements: [
        // *********************
        // ** CONTRACTOR DATA **
        // *********************

        // title | text
        {
          type: "p",
          default: "Dados da contratante",
          props: {
            className: "input-title",
          },
        },

        // subtitle | text
        {
          type: "p",
          default: "Insira as informações cadastrais do contratante",
          props: {
            className: "input-subtitle",
          },
        },

        // space
        {
          type: "Space",
          props: {},
        },

        // cnpj - company name - segment sector | inputs
        {
          type: "Row",
          props: {
            justify: "space-between",
            align: "top",
          },
          elements: [
            // contact name
            {
              type: "Col",
              props: {
                sm: 7,
              },
              elements: [
                {
                  type: "Form.Item",
                  props: {
                    name: ["contact", "company_name"],
                    label: "Nome da contratante",
                    rules: [
                      {
                        required: true,
                        message: "Campo obrigatório",
                      },
                    ],
                  },
                  element: {
                    type: "SelectContact",
                  },
                },
              ],
            },

            // cnpj
            {
              type: "Col",
              props: {
                sm: 7,
              },
              elements: [
                {
                  type: "FlattedInputCpfCnpj",
                  props: {
                    name: ["contact", "cpf_cnpj"],
                    label: "CPF/CNPJ da contratante",
                    placeholder: "CPF ou CNPJ",
                    rules: [
                      {
                        required: false,
                        message: "Campo obrigatório",
                      },
                    ],
                  },
                },
              ],
            },

            // sector segment
            {
              type: "Col",
              props: {
                sm: 7,
              },
              elements: [
                {
                  type: "Form.Item",
                  props: {
                    label: "Setor",
                    name: ["contact", "sector"],
                    rules: [
                      {
                        required: true,
                        message: "Campo obrigatório",
                      },
                    ],
                  },
                  element: {
                    type: "Select",
                    props: {
                      name: "sector",
                      placeholder: "Selecione o setor",
                    },
                    options: sectorsMock,
                  },
                },
              ],
            },
          ],
        },

        // divider
        {
          type: "Divider",
          props: {
            className: "divider-margin-top",
          },
        },

        // ********************
        // *** CONTACT DATA ***
        // ********************

        // title | text
        {
          type: "p",
          default: "Dados do contato",
          props: {
            className: "input-title",
          },
        },

        // subtitle | text
        {
          type: "p",
          default: "Adicione o número de contato",
          props: {
            className: "input-subtitle",
          },
        },

        // name - phone - email | inputs
        {
          type: "Row",
          props: {
            justify: "space-between",
            align: "top",
          },
          elements: [
            // name
            {
              type: "Col",
              props: {
                sm: 7,
              },
              elements: [
                {
                  type: "Form.Item",
                  props: {
                    label: "Nome e sobrenome",
                    name: ["contact", "name"],
                    rules: [
                      {
                        required: true,
                        message: "Campo obrigatório",
                      },
                    ],
                  },
                  element: {
                    type: "FlattedInput",
                    props: {
                      placeholder: "Inserir nome de contato",
                    },
                  },
                },
              ],
            },

            // phone
            {
              type: "Col",
              props: {
                sm: 7,
              },
              elements: [
                {
                  type: "Form.Item",
                  props: {
                    name: ["contact", "phone"],
                    label: "Telefone",
                  },
                  element: {
                    type: "FlattedPhoneInputForm",
                    props: {},
                  },
                },
              ],
            },

            // email
            {
              type: "Col",
              props: {
                sm: 7,
              },
              elements: [
                {
                  type: "InputAccountEmail",
                  props: {
                    label: "E-mail",
                    name: ["contact", "email"],
                    placeholder: "Inserir e-mail",
                  },
                },
              ],
            },
          ],
        },
      ],
      actions: [
        {
          type: "ButtonPreviousStep",
          props: {
            span: 12,
          },
          elements: [
            {
              type: "text",
              default: "Voltar",
            },
          ],
        },

        {
          type: "ButtonNextStep",
          props: {
            span: 12,
          },
          elements: [
            {
              type: "text",
              default: "Continuar",
            },
          ],
        },
      ],
    },

    {
      type: "Route",
      step_title: "Envio",
      props: {
        path: ["/dashboard/proposal/3", "/dashboard/proposal/3/:id"],
        className: "step-no-style",
      },
      elements: [
        {
          type: "ProposalCreateResume",
          elements: [],
        },
      ],
    },
  ],
}
