import React from "react"

export const FileIcon = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_1655_56775"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="32"
          height="32"
        >
          <rect width="32" height="32" fill="white" />
        </mask>
        <g mask="url(#mask0_1655_56775)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.9127 12.8137C23.8677 12.7147 23.8118 12.6205 23.733 12.5402L18.233 6.94021C18.1542 6.85995 18.0616 6.80301 17.9644 6.75728C17.9369 6.74421 17.9076 6.73675 17.8782 6.72648C17.8012 6.70035 17.7224 6.68355 17.6408 6.67888C17.6207 6.67701 17.6032 6.66675 17.584 6.66675H11.1673C10.1562 6.66675 9.33398 7.50395 9.33398 8.53341V23.4667C9.33398 24.4962 10.1562 25.3334 11.1673 25.3334H22.1673C23.1784 25.3334 24.0007 24.4962 24.0007 23.4667V13.2001C24.0007 13.1805 23.9906 13.1627 23.9887 13.1422C23.9842 13.0601 23.9686 12.9798 23.9429 12.9014C23.9328 12.8715 23.9246 12.8426 23.9127 12.8137ZM18.5007 12.2667V9.85315L20.8712 12.2667H18.5007ZM11.1673 8.53341H16.6673V13.2001C16.6673 13.7162 17.0771 14.1334 17.584 14.1334H22.1673L22.1692 23.4667H11.1673V8.53341Z"
            fill="#676767"
          />
        </g>
      </svg>
    </span>
  )
}
