import React from "react"
import PropTypes from "prop-types"
import Loading from "../Loading"

const GrayButton = ({ isLoading, icon, children, onClick }) => {
  return (
    <span className="gray-button">
      {isLoading ? <Loading className="dark small" /> : icon}
      <label onClick={onClick}>{children}</label>
    </span>
  )
}

GrayButton.propTypes = {
  icon: PropTypes.element,
  isLoading: PropTypes.element,
  children: PropTypes.element,
  onClick: PropTypes.func,
}

export default GrayButton
