import { format, parse } from "date-fns"
import { PhoneNumberFormat, PhoneNumberUtil } from "google-libphonenumber"
import { ptBR } from "date-fns/esm/locale"

const phoneUtil = PhoneNumberUtil.getInstance()

export const numberToReal = (number, truncade = false) => {
  if (!number) {
    number = 0
  }

  if (truncade && number >= 1000) {
    return `R$ ${formatMilhar(number)}`
  } else {
    return number.toLocaleString("pt-RF", {
      style: "currency",
      currency: "BRL",
    })
  }
}

/**
 * Abrevia unidades do número real.
 * Ex.: 14558333,00 | 123699000555,50  | 4058,65 Mil | 3599.00 Mi
 * Resultado: 14 Mi | 123 Bi | 4 Mil | 3.5 Mil
 */
export const formatMilhar = (number) => {
  const milhares = ["Mil", "Mi", "Bi"]

  let label = null
  let num = null
  number = parseInt(number).toString()

  milhares.forEach((item, index) => {
    if (number.length >= 4 + index * 3) {
      label = item
      // Obtem os 4 à 6 primeiros digitos e add o separador ".":
      // Ex.: 1999888 -> 1.999 | 125999555888 -> 125.999
      num = index === 0 ? number : number.slice(0, index * 3 * -1)
      num = num.replace(/\B(?=(\d{3})+(?!\d))/g, ".")

      if (num.length === 5 && num[2] !== "0")
        // Ex.: 4.558 Mi | 3.699 Bi
        num = num.slice(0, 3)
      // Resultado: 4.5 Mi | 3.6 Bi
      else if (num.length >= 5)
        // Ex.: 14.558 Mi | 123.699 Bi | 4.058 Mil | 3.099 Mi
        num = num.split(".")[0] // Resultado: 14 Mi | 123 Bi | 4 Mil | 3 Mi
    }
  })

  return `${num} ${label}`
}

export const formatPhoneNumber = (phone) => {
  try {
    const parsed = parsePhone(phone)
    return parsed.formatted
  } catch {
    return phone
  }
}

export const parseNumber = (numberStr) => parseInt(numberStr.replace(/\D/g, ""))

export const cpfMask = (value) => {
  return value
    .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})(\d)/, "$1.$2") // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1") // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

export const cnpjMask = (value) => {
  return value
    .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{2})(\d)/, "$1.$2") // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1/$2")
    .replace(/(\d{4})(\d{1,2})/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1") // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

export const cepMask = (value) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1-$2")
    .replace(/(-\d{3})\d+?$/, "$1")
}

export const beautifyCpfCnpj = (value) => {
  if (!value) return ""

  const final = value.replace(/[.\-/]/g, "")

  if (final.length < 12) {
    return cpfMask(value)
  } else {
    return cnpjMask(value)
  }
}

export const parseDate = (dateStr, fmt = "dd/MM/yyyy") => {
  // date-fns formats: https://date-fns.org/v2.25.0/docs/format
  return parse(dateStr, fmt, new Date())
}

export const formatDate = (date, fmt = "dd/MM/yyyy") => {
  // date-fns formats: https://date-fns.org/v2.25.0/docs/format
  return format(date, fmt, { locale: ptBR })
}

export const parsePhone = (rawPhone) => {
  try {
    const result = phoneUtil.parse(rawPhone)
    const formatted = phoneUtil.format(result, PhoneNumberFormat.INTERNATIONAL)
    const valid = phoneUtil.isValidNumber(result)
    const code = result.getCountryCode()
    const number = result.getNationalNumber()
    const country = phoneUtil.getRegionCodeForNumber(result)

    const phone = {
      formatted,
      country,
      code,
      number,
      phone: `+${code}-${number}`,
      isValid: valid,
    }

    return phone
  } catch (err) {
    if (rawPhone) {
      const [countryCode, phone] = rawPhone.split("-")
      return {
        country: null,
        code: parseInt(countryCode.replace("+", "")),
        number: phone ? parseInt(phone) : "",
        phone: rawPhone,
        isValid: false,
      }
    } else {
      return {
        country: null,
        code: null,
        number: null,
        phone: "",
        isValid: false,
      }
    }
  }
}

/**
 * Transforma lista de string em uma única string.
 * Ex.: ["maça", "abacaxi", "uva"] -> "maçã, abacaxi e uva"
 */
export const joinStr = (labels) => {
  if (!labels || labels.length === 0 || !labels.join) return ""
  if (labels.length === 1) return labels[0]

  const label = labels.join(", ")
  const index = label.lastIndexOf(", ")

  const starts = label.substring(0, index)
  const ends = label.substring(index)

  return starts + ends.replace(", ", " e ")
}

export const formatStatusToStep = (status) => {
  switch (status) {
    case "waiting_review":
    case "waiting_accept":
      return "Etapa 1 de 5"
    case "accepted":
      return "Etapa 2 de 5"
    case "waiting_sign_contract":
      return "Etapa 3 de 5"
    case "waiting_nfs":
      return "Etapa 4 de 5"
    case "finalized":
      return "Etapa 5 de 5"
    case "draft":
    case "canceled":
    case "expired":
    default:
      return "-"
  }
}
