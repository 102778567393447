import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import StateStatus from "../../utils/stateStatus"
import { basicProfile } from "./DashboardService"

const initialState = {
  status: {
    basicProfile: StateStatus.idle,
  },
  mode: window.localStorage.getItem("mode") || "contracted",
  data: {
    basicProfile: {},
  },
}

export const getBasicProfile = createAsyncThunk(
  "DashSlice/get_basic_profile",
  async () => {
    const response = await basicProfile()
    return response.data.data
  }
)

export const dashboardSlice = createSlice({
  name: "DashSlice",
  initialState,
  reducers: {
    resetDashState: (state) => {
      state.status.basicProfile = StateStatus.idle
    },
    updateMode: (state, action) => {
      const { mode, redirect } = action.payload

      if (state.mode === mode.toLowerCase()) return

      window.localStorage.setItem("mode", mode.toLowerCase())
      state.mode = mode.toLowerCase()

      if (redirect || redirect === undefined) {
        setTimeout(() => {
          if (window.location.href.split("/#/")[1] === "dashboard") {
            window.location.reload()
          } else {
            window.location.href = "/#/dashboard"
          }
        }, 300)
      } else {
        window.location.reload()
      }
    },
  },
  extraReducers: {
    [getBasicProfile.pending]: (state) => {
      state.status.basicProfile = StateStatus.loading
    },
    [getBasicProfile.fulfilled]: (state, action) => {
      state.status.basicProfile = StateStatus.succeeded
      state.data.basicProfile = {
        cpf: action.payload.cpf,
        email: action.payload.email,
        name: action.payload.name,
        incomplete: action.payload.incomplete,
        incomplete_data: action.payload.incomplete_data,
      }
    },
    [getBasicProfile.rejected]: (state, action) => {
      state.status.basicProfile = StateStatus.failed
      state.errMessage = action.error.message
    },
  },
})

export const { resetDashState, updateMode } = dashboardSlice.actions
export const selectDashSlice = (state) => state.dash
