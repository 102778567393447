import axios from "axios"
import api from "../../service/api"
import apiRoutes, { baseUrl } from "../../service/apiRoutes"

export const notifications = (page, data) =>
  api.post(`${baseUrl}${apiRoutes.notifications}/${page}`, data)

export const unviewedNotificationsWithInterceptores = () =>
  api.get(`${baseUrl}${apiRoutes.notifications}/unviewed`)

export const unviewedNotifications = () =>
  axios.get(`${baseUrl}${apiRoutes.notifications}/unviewed`, {
    headers: {
      "x-app": "web",
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  })
export const deleteNotification = (notificationId) =>
  api.delete(`${baseUrl}${apiRoutes.notification}/${notificationId}`)
export const deleteAllNotifications = () =>
  api.delete(`${baseUrl}${apiRoutes.notifications}`)
export const readNotification = (notificationId) =>
  api.get(`${baseUrl}${apiRoutes.notification}/${notificationId}`)
