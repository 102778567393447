import React from "react"
import { Col, Divider, Row } from "antd"
import Icons from "../../common/components/Icons"
import { Link } from "react-router-dom"
import logoImg from "../../common/assets/logo/propozall.svg"
import googleImg from "../../common/assets/images/google_play.png"
import appleImg from "../../common/assets/images/apple_store.png"
import "./styles.scss"
import { BlueButton } from "../../common/components/Button"

const DeleteAccountTutorialPage = () => {
  // const history = useHistory()

  return (
    <div className="root-container delete-acc-tutorial-page">
      <div className="header-container">
        <header className="header">
          <Link to={"/"}>
            <img
              src={logoImg}
              alt="Logo Propozall"
              width="300"
              className="logo"
            />
          </Link>
        </header>
      </div>

      <div className="page-content">
        <Row>
          <Col span={24}>
            <div className="page-title">Como excluir minha conta</div>
          </Col>
          <Divider />
          <Col span={24}>
            <div className="subtitle">
              Siga o passo a passo para excluir sua conta Propozall:
            </div>
            <div className="list-steps">
              <div>
                <strong>1 - </strong>Faça login com a sua conta no Propozall;
              </div>

              <div>
                <strong>2 - </strong>
                Acesse o menu configurações <Icons icon="settings" /> e em
                seguida na opção &quot;Excluir conta&quot;;
              </div>

              <div>
                <strong>3 - </strong>Siga as instruções e depois clique em
                confirmar;
              </div>

              <div>
                <strong>4 - </strong>
                Após a confirmação você não terá mais acesso a sua conta
                Propozall.{" "}
                <strong>
                  Seus dados serão excluídos 90 dias após a confirmação.
                </strong>
              </div>

              <div>
                <strong>5 - </strong>
                Por motivos judiciais somos obrigados a manter salvo em nossos
                sistemas alguns dados da sua conta.{" "}
                <Link
                  onClick={() => {
                    document.getElementById("dados-mantidos").scrollIntoView()
                  }}
                >
                  {" "}
                  Saiba quais dados serão mantidos.
                </Link>
              </div>
            </div>
          </Col>
          <Col span={24}>
            <div className="subtitle">Quais dados serão excluídos?</div>
            <div>
              Ao excluir sua conta, os seguintes dados serão deletados dos
              nossos sistemas:
            </div>
            <div className="list-deleting">
              <div>- Propostas que você criou;</div>
              <div>- Seu endereço;</div>
              <div>- Seus telefones;</div>
              <div>- Suas redes Sociais;</div>
              <div>- Seus CNPJ’s;</div>
              <div>- Seu CPF’s;</div>
              <div>- Seu e-mail cadastrado.</div>
            </div>

            <div className="info-alert">
              <strong>
                A exclusão é concluída 90 dias após sua solicitação.
              </strong>
            </div>
          </Col>
          <span id="dados-mantidos"></span>
          <Col span={24}>
            <div className="subtitle">Quais dados serão mantidos?</div>
            <div>
              Por motivos judiciais somos obrigados a manter salvo em nossos
              sistemas alguns dados da sua conta, são eles:
            </div>
            <div className="list-keep">
              <div>- Contratos;</div>
              <div>- Faturas;</div>
              <div>- Notas fiscais criadas no Propozall.</div>
            </div>
            <div>
              Os dados citados no item acima firmam o pacto de trabalho ou
              comprovam o pagamento dos serviços utilizados durante o período em
              que você utilizava o Propozall.
            </div>
          </Col>
        </Row>
        <Row justify={"center"} className="row-actions">
          <Col span={24}>
            <BlueButton
              onClick={() => {
                window.open("https://propozall.page.link/proposal")
              }}
            >
              Entrar na minha conta
            </BlueButton>
          </Col>
        </Row>
      </div>

      <div className="footer-propozall">
        <Row className="row-logo">
          <Link to={"/"}>
            <img
              src={logoImg}
              alt="Logo Propozall"
              width="136"
              className="logo"
            />
          </Link>
        </Row>
        <Row justify={"space-between"} className="row-menu" gutter={[16, 16]}>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Row justify={"space-between"} gutter={[16, 16]}>
              <Col xs={24} sm={24} md={12} lg={12}>
                <div className="footer-list-title">Serviços</div>
                <div className="footer-menu">
                  <div>
                    <a
                      href="https://propozall.com/autonomos"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Para autônomos
                    </a>
                  </div>
                  <div>
                    <a
                      href="https://propozall.com/empresas"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Para empresas
                    </a>
                  </div>
                  <div>
                    <a
                      href="https://propozall.com/planos"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Planos
                    </a>
                  </div>
                  <div>
                    <a
                      href="https://propozall.com/clubedebeneficios"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Clube de benefícios
                    </a>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <div className="footer-list-title">Suporte</div>
                <div className="footer-menu">
                  <div>
                    <a
                      href="https://propozall.com/central-de-ajuda"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Central de ajuda
                    </a>
                  </div>
                  <div>
                    <a
                      href="https://propozall.com/contato"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Contato
                    </a>
                  </div>
                  <div>
                    <a
                      href="https://propozall.com/faq"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Perguntas frequentes
                    </a>
                  </div>
                  <div>
                    <a
                      href="https://propozall.com/termos-uso"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Termos de uso
                    </a>
                  </div>
                  <div>
                    <a
                      href="https://propozall.com/politica-privacidade"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Política de privacidade
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12}>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={12} lg={12}>
                <div className="footer-list-title">Siga-nos</div>
                <div className="footer-menu">
                  <div>
                    <a
                      href="https://br.linkedin.com/company/propozall"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Linkedin
                    </a>
                  </div>
                  <div>
                    <a
                      href="https://www.instagram.com/propozall.app"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Instagram
                    </a>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <div className="footer-list-title">Baixe o app</div>
                <div className="footer-menu">
                  <div>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.propozall"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={googleImg}
                        alt="Ir para Play Store"
                        width="120"
                        className="logo"
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href="https://apps.apple.com/br/app/propozall-contratos-nfs-e/id1609648372"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={appleImg}
                        alt="Ir para App Store"
                        width="120"
                        className="logo"
                      />
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="row-copyrigth" justify={"center"} align="middle">
          <Col>
            Propozall © {new Date().getFullYear()} - Todos os direitos
            reservados
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default DeleteAccountTutorialPage
