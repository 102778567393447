import React, { useEffect } from "react"
import BaseModal from "../../../../../../common/components/Modal"
import propTypes from "prop-types"
import { Row, Col, Form } from "antd"
import {
  FlattedMaskedInput,
  FlattedInput,
  FlattedInputNumber,
} from "../../../../../../common/components/FlattedInput"
import { useDispatch, useSelector } from "react-redux"
import {
  resetUpdatePayment,
  selectConfigState,
  updatePayment,
} from "../../../../configurationsSlice"
import StateStatus from "../../../../../../utils/stateStatus"
import "./styles.scss"
import ErrorMessage from "../../../../../../common/components/ErrorMessage"

const ChangePaymentMethod = ({
  isVisible,
  onSave = () => {},
  onCancel = () => {},
}) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const configState = useSelector(selectConfigState)

  useEffect(() => {
    window.Iugu.setup()
  }, [])

  useEffect(() => {
    form.resetFields()
  }, [isVisible])

  useEffect(() => {
    if (configState.status.updatePayment === StateStatus.succeeded) {
      onSave()
    }
  }, [configState.status.updatePayment])

  const onFinish = (values) => {
    if (
      values.validity &&
      values.card_name &&
      values.card_number &&
      values.cvv
    ) {
      dispatch(updatePayment(values))
    }
  }

  return (
    <BaseModal
      className="change-payment-method-modal"
      isVisible={isVisible}
      title="Alterar forma de pagamento"
      okButtonText="Salvar"
      width="35%"
      onOk={() => {
        form.submit()
      }}
      cancelButtonText="Cancelar"
      onCancel={onCancel}
      isLoading={configState.status.updatePayment === StateStatus.loading}
    >
      <div className="normal-14">
        Preencha seus dados a seguir para adicionar seu novo cartão.
      </div>
      {/* <hr
        style={{
          width: "100%",
          height: "1px",
          border: "none",
          backgroundColor: "#C2C2C2",
        }}
      /> */}
      <br />
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        onValuesChange={() => {
          if (configState.status.updatePayment === StateStatus.failed) {
            dispatch(resetUpdatePayment())
          }
        }}
      >
        <Row justify="space-between" gutter={[16, 16]}>
          <Col sm={12}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Cartão de crédito inválido",
                },
              ]}
              name="card_number"
              label="Número do cartão de crédito"
            >
              <FlattedMaskedInput
                style={{ width: "100%" }}
                mask="0000 0000 0000 0000"
                placeholder={"0000 0000 0000 0000"}
              />
            </Form.Item>
          </Col>
          <Col sm={12}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Preencha o nome do titular.",
                },
              ]}
              name="card_name"
              label="Nome do titular"
            >
              <FlattedInput
                style={{ width: "100%" }}
                placeholder={"Digite o nome do titular"}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="space-between" gutter={[16, 16]}>
          <Col sm={12}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Campo obrigatório",
                },
                () => ({
                  validator(_, val) {
                    const value = (`${val}` ?? "").replace(/\D/g, "")
                    if (!value || value.length < 3 || value.length > 4) {
                      return Promise.reject(new Error("CVV inválido"))
                    }
                    return Promise.resolve()
                  },
                }),
              ]}
              name="cvv"
              label="Código de segurança (CVV)"
            >
              <FlattedInputNumber
                maxLength={4}
                placeholder={"Digite o código de segurança"}
                formatter={(value) => value.replace(/\D/g, "")}
              />
            </Form.Item>
          </Col>
          <Col sm={12}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Preencha a data de validade",
                },
                {
                  validator(_, value) {
                    if (
                      !value ||
                      value.replace(/[^0-9]+/g, "").length < 6 ||
                      !window.Iugu.utils.validateExpirationString(value)
                    ) {
                      return Promise.reject(
                        new Error("Informe uma data válida")
                      )
                    }

                    return Promise.resolve()
                  },
                },
              ]}
              name="validity"
              label="Validade do cartão"
            >
              <FlattedMaskedInput mask="00/0000" placeholder={"00/0000"} />
            </Form.Item>
          </Col>
        </Row>
        <ErrorMessage
          text={
            "Não foi possível validar o cartão, verifique os dados preenchidos e tente novamente."
          }
          isVisible={configState.status.updatePayment === StateStatus.failed}
        />
      </Form>
    </BaseModal>
  )
}

ChangePaymentMethod.propTypes = {
  isVisible: propTypes.bool,
  onSave: propTypes.func,
  onCancel: propTypes.func,
}

export default ChangePaymentMethod
