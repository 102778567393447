import React, { useState } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { selectProposalState, updateAux } from "../../../proposal/proposalSlice"
//
import { CircleCheckBox } from "../../../../common/components/CheckBox"
import { MeetingModal } from "../../../proposal/components/MeetingModal"
//
// import Icons from "../../../../common/components/Icons"
import "./styles.scss"
import { Row } from "antd"
import { PlainButton } from "../../../../common/components/Button"

const RadioScheduleMeeting = ({ dataMeeting }) => {
  const proposalState = useSelector(selectProposalState)
  const proposal = proposalState.data.proposal || {}
  //
  const [showMeetingModal, updateShowMeetingModal] = useState(false)
  const dispatch = useDispatch()

  return (
    <>
      <Row>
        <CircleCheckBox
          id="meeting"
          checked={!!proposalState.data.aux.meeting}
          disabled={!(!proposal.id || proposal.status === "draft")}
          onChange={(e) => {
            if (!showMeetingModal) {
              updateShowMeetingModal(true)
            }
          }}
        >
          Agendar Reunião
        </CircleCheckBox>

        {proposalState.data.aux.meeting &&
          (!proposal.id || proposal.status === "draft") && (
            <PlainButton onClick={() => updateShowMeetingModal(true)}>
              Editar
            </PlainButton>
          )}
      </Row>

      {showMeetingModal && (
        <MeetingModal
          data={proposalState.data.aux.meeting}
          onSave={(data) => {
            dispatch(updateAux({ meeting: data }))
            updateShowMeetingModal(false)
            dataMeeting(data)
          }}
          onCancel={() => {
            updateShowMeetingModal(false)
          }}
        />
      )}
    </>
  )
}

RadioScheduleMeeting.propTypes = {
  dataMeeting: PropTypes.func,
}

export default RadioScheduleMeeting
