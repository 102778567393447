import React, { useEffect, createRef, useState } from "react"
//
import { useDispatch, useSelector } from "react-redux"
//
import { Dropdown, Col, Row, Table, Pagination } from "antd"
import Icons from "../../common/components/Icons"
import { DatePicker } from "../../common/components/DatePicker"
import RoudedBox from "../../common/components/RoudedBox"
//
import { numberToReal, formatDate } from "../../utils/formaters"
import StateStatus from "../../utils/stateStatus"
import {
  listPayments,
  selectPaymentsState,
  showPayment,
  updateEndDate,
  updatePage,
  updateStartDate,
} from "./paymentHistorySlice"
//
import emptyImg from "../../common/assets/images/contratos.png"
import "./styles.scss"

const PaymentHistory = () => {
  //
  const inputStartDate = createRef(null)
  const inputEndDate = createRef(null)
  const [start, updateStart] = useState()
  const [end, updateEnd] = useState()

  //
  const dispatch = useDispatch()
  const payments = useSelector(selectPaymentsState)
  const scrollTrigger = document.querySelector(".scroll-trigger")

  const columns = [
    {
      title: <div className="normal-14-500">DATA</div>,
      dataIndex: "created_at",
      key: "created_at",
      render: function Cell(text) {
        return <div className="normal-14">{text}</div>
      },
    },
    {
      title: <div className="normal-14-500">Nº DA FATURA</div>,
      // dataIndex: "invoice_number",
      // key: "invoice_number",
      render: function Cell(item) {
        return (
          <div
            className="link-14"
            onClick={() => {
              dispatch(showPayment(item.url))
            }}
          >
            {item.invoice_number}
          </div>
        )
      },
    },
    {
      title: <div className="normal-14-500">PLANO</div>,
      dataIndex: "plan_identifier",
      key: "plan_identifier",
      render: function Cell(text) {
        return (
          <div style={{ textTransform: "capitalize" }} className="normal-14">
            {text ?? " - "}
          </div>
        )
      },
    },
    {
      title: <div className="normal-14-500">VALOR</div>,
      dataIndex: "value",
      key: "value",
      render: function Cell(text) {
        return <div className="normal-14-500">{numberToReal(text)}</div>
      },
    },
    {
      title: (
        <div className="normal-14-500" style={{ textAlign: "center" }}>
          AÇÕES
        </div>
      ),
      // dataIndex: null,
      // key: null,
      render: function Cell(item) {
        return (
          <Row justify="center" className="actions">
            <div
              className="show-button"
              onClick={() => {
                dispatch(showPayment(item.url))
              }}
            >
              <Icons icon="show" title="Ver PDF" />
            </div>
            {/* <div className="mail-button" onClick={() => { }}>
              <Icons icon="mail" title="Enviar email" />
            </div>
            <div className="mail-button" onClick={() => { }}>
              <Icons icon="download" title="Download" />
            </div> */}
          </Row>
        )
      },
    },
  ]

  useEffect(() => {
    dispatch(listPayments({ page: payments.page }))
  }, [payments.startDate, payments.endDate])

  useEffect(() => {
    if (
      payments.list &&
      payments.list.length > 0 &&
      payments.totalPages !== 0 &&
      scrollTrigger
    ) {
      const intersectionObserver = new IntersectionObserver((entries) => {
        if (entries.some((entry) => entry.isIntersecting)) {
          if (payments.page < payments.totalPages) {
            dispatch(listPayments({ page: payments.page + 1 }))
          }
        }
      })

      intersectionObserver.observe(scrollTrigger)
      return () => intersectionObserver.disconnect()
    }
  }, [payments.page, payments.totalPages, scrollTrigger])

  const clickStartDate = () => inputStartDate.current.click()

  const clickEndDate = () => inputEndDate.current.click()

  return (
    <div className="root-container payment-history-page">
      <Row justify="space-between" align="middle">
        <Col>
          <h1 className="normal-24-500">Pagamentos e Cobranças</h1>
          <p className="normal-12">
            Confira o histórico de pagamentos do seu plano e veja detalhes das
            cobranças
          </p>
        </Col>
      </Row>

      <Row gutter={[24, 24]} justify="space-between">
        <Col span={24}>
          <RoudedBox className="filter-box">
            <span className="normal-16-500">Filtros</span>

            <div className="filter-date-box">
              <span className="normal-16-500">Período: </span>

              <Dropdown
                overlay={
                  <DatePicker
                    width="350px"
                    className="floating"
                    date={payments.startDate}
                    maxDate={end}
                    onChange={(date) => {
                      updateStart(date)
                      dispatch(updateStartDate(formatDate(date)))

                      // if(!end) clickEndDate()
                    }}
                  />
                }
                trigger={["click"]}
                placement="bottomRight"
              >
                <button ref={inputStartDate} />
              </Dropdown>
              <span className="text-date" onClick={() => clickStartDate()}>
                {payments.startDate || "--/--/--"}
              </span>

              <span className="text-date text-a">à</span>

              <Dropdown
                overlay={
                  <DatePicker
                    className="floating"
                    date={payments.endDate}
                    minDate={start}
                    onChange={(date) => {
                      updateEnd(date)
                      dispatch(updateEndDate(formatDate(date)))

                      // if(!startDateFormated) clickStartDate()
                    }}
                  />
                }
                trigger={["click"]}
                placement="bottomRight"
              >
                <button ref={inputEndDate} />
              </Dropdown>
              <span className="text-date" onClick={() => clickEndDate()}>
                {payments.endDate || "--/--/--"}
              </span>

              <Icons
                icon="calendar"
                onClick={() => {
                  if (!start || (start && end)) {
                    clickStartDate()
                  } else {
                    clickEndDate()
                  }
                }}
              />
            </div>
          </RoudedBox>
        </Col>
      </Row>

      <div style={{ width: "100%" }}>
        {payments.totalItems > 0 && (
          <Table
            pagination={false}
            dataSource={payments.list ?? []}
            columns={columns}
            loading={payments.status.listPayments === StateStatus.loading}
            locale={{
              emptyText: "   ",
            }}
          />
        )}

        {payments.totalItems > 6 && (
          <Pagination
            current={payments.page}
            total={payments.totalItems}
            pageSize={6}
            onChange={(page) => dispatch(updatePage(page))}
          />
        )}

        {payments.list.length === 0 &&
        payments.status.listPayments === StateStatus.succeeded ? (
          <div className="empty-card">
            <img
              src={emptyImg}
              alt="imagem para simbolizar que a lista está vazia"
            />
            <span className="normal-14-400">Você não possui pagamentos</span>
          </div>
        ) : null}

        {payments.status.listPayments === StateStatus.failed && (
          <p className="">{payments.errMessage}</p>
        )}
      </div>
    </div>
  )
}

export default PaymentHistory
