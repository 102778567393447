import React from "react"
import PropTypes from "prop-types"

const HomeIcon = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>{props?.alt ?? ""}</title>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.92433 14.1438L6.83936 14.2276C6.43371 14.6924 6.78033 15.4234 7.46098 15.4234L8.67615 15.4229L8.67664 24.5966C8.67664 25.0035 9.03184 25.3334 9.47001 25.3334H14.1458C14.584 25.3334 14.9392 25.0035 14.9392 24.5966V20.338C14.9392 19.8831 15.3362 19.5143 15.826 19.5143C16.3157 19.5143 16.7128 19.8831 16.7128 20.338V24.5966C16.7128 25.0035 17.068 25.3334 17.5061 25.3334H22.5287L22.6574 25.3238C23.0343 25.2667 23.3221 24.9628 23.3221 24.5966L23.3217 15.4229L24.5377 15.4234C25.2609 15.4234 25.607 14.5982 25.0744 14.1438L16.536 6.86089C16.2325 6.60203 15.7662 6.60203 15.4627 6.86089L6.92433 14.1438ZM15.9989 8.40324L22.5019 13.9517L22.4 13.9593C22.023 14.0164 21.7353 14.3203 21.7353 14.6865L21.735 23.8597H18.2984L18.2995 20.338C18.2995 19.0692 17.1921 18.0407 15.826 18.0407L15.6327 18.0476C14.3569 18.1392 13.3524 19.1296 13.3524 20.338L13.3517 23.8597H10.2629L10.2634 14.6865L10.253 14.567C10.1983 14.2558 9.93378 14.0101 9.5987 13.9593L9.49464 13.9517L15.9989 8.40324Z"
          fill="#676767"
        />
      </svg>
    </span>
  )
}

HomeIcon.propTypes = {
  alt: PropTypes.string,
}

export { HomeIcon }
