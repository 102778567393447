import React, { useCallback, useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
//
import { cnpj, cpf } from "cpf-cnpj-validator"
//
import { Row, Col, Form } from "antd"
import FlattedInput, {
  FlattedMaskedInput,
  FlattedPhoneInputForm,
  FlattedTextArea,
} from "../FlattedInput"
import { BlueButton, WhiteButton, RedButton } from "../Button"
import Icons from "../Icons"
import BaseModal from "../Modal"
import RadioButton from "../RadioButton"
import ErrorMessage from "../ErrorMessage"
import ProposalResume from "../../../features/proposal/components/ProposalResume"
//
import dynamicResume from "../../mocks/resumeProposalJson"
//
import "moment/locale/pt-br"
import "./styles.scss"
// import ContractStatus from "../ContractStatus"
import ProposalStatus from "../ProposalStatus"
import { BoxLink } from "../BoxLink"
import GrayButton from "../GrayButton"
import SignersTable from "./components/SignersTable"
import DeleteOrCancelModal from "./components/deleteOrCancelModal"
// import FileDownload from "./components/FileDownload"
// import FileUpload from "./components/FileUpload"
import { useHistory } from "react-router-dom"
import BreadMessage from "../BreadMessage"
import ContractStatus from "../ContractStatus"
import CollapseRowContent from "../../../features/proposal/components/CollapseRowContent"
import CollapseRowHeader from "../../../features/proposal/components/CollapseRowHeader"
import { ProposalStatusEnum, ProposalTypesEnum } from "../../../utils/constants"
import { allDDDs, invalidPhoneNumbers } from "../../../utils/lists"
// import { FileUploadBox } from "../FileUploadBox"

const ProposalDetails = ({
  proposal,
  onFinalizeProposal,
  onAcceptProposal,
  onRefuseProposal,
  onDownloadDefaultContract,
  onDownloadContract,
  onSendContract,
  onCloseContract,
  onResetContract,
  onCancelProposal,
  isCancelLoading,
  isFinalizeLoading,
  isGetContractLoading,
  isResetContratLoading,
  onAddSigners,
  onUploadNF,
  onShare,
  isLoading,
  profileData, // requer apenas em tela logado
}) => {
  /**
   * ATENÇÃO: Este componente é usado em telas que requer usuário logado e tela em que
   * não requer usuário. Por isso, não realizar chamadas a API que requer
   * access_token de usuário dentro desse componente, realizar chamada nas
   * telas que utilizam este componente.
   */
  //
  const [isLoaded, setLoaded] = useState(false)
  const [showModal, setShowModal] = useState({})
  const [owner, setOwner] = useState({})
  const [counterparty, setCounterparty] = useState({})
  const [showAcceptModal, setShowAcceptModal] = useState(false)
  const [showRefuseModal, setShowRefuseModal] = useState(false)
  const [showUploadContractModal, setShowUploadContractModal] = useState(false)
  const [showContractWarning, setShowContractWarning] = useState(false)
  const [showAlertNoSigners, setShowAlertNoSigners] = useState(false)
  const [showBreadAlert, setShowBreadAlert] = useState({})
  const [activeKeys, setActiveKeys] = useState([])
  // const [error, setError] = useState()

  // const [fileSelected, updateFileSelected] = useState()

  //
  const [formReview] = Form.useForm()
  const isOwner = Boolean(proposal.created_by === proposal.role)
  const canUseDefaultContract = Boolean(
    [
      ProposalTypesEnum.INFLUENCER,
      ProposalTypesEnum.SERVICE_PROVIDER,
      ProposalTypesEnum.SIMPLE,
      ProposalTypesEnum.FREELA,
    ].includes(proposal.type)
  )

  const [contractSelected, updateContractSelected] = useState({
    default: canUseDefaultContract,
  })
  const history = useHistory()

  const setDefaultValuesAcceptForm = () => {
    formReview.resetFields()
    if (proposal.created_by === "CONTRACTOR") {
      setOwner(proposal.contractor)
      setCounterparty(proposal.contracted)

      formReview.setFieldsValue({
        cpf_cnpj: proposal.contracted.cpf_cnpj,
        phone: proposal.contracted.phone,
        email: proposal.contracted.email,
      })
    } else if (proposal.created_by === "CONTRACTED") {
      setOwner(proposal.contracted)
      setCounterparty(proposal.contractor)

      formReview.setFieldsValue({
        cpf_cnpj: proposal.contractor.cpf_cnpj,
        phone: proposal.contractor.phone,
        email: proposal.contractor.email,
      })
    }
  }

  const updateBreadMessage = () => {
    if (["waiting_sign", "signing"].includes(proposal.contract?.status)) {
      if (!proposal.contract?.requires_counterparty_account) {
        setShowBreadAlert({
          type: "alert",
          text: "O contrato foi enviado por e-mail a todos os assinantes.",
        })
      } else if (proposal.created_by === "CONTRACTOR") {
        setShowBreadAlert({
          type: "error",
          text: (
            <>
              Aguardando a parte contratada criar uma conta com o e-mail{" "}
              <b>{proposal.contracted.email}</b> para poder assinar o contrato.
            </>
          ),
        })
      } else {
        setShowBreadAlert({
          type: "error",
          text: (
            <>
              Aguardando a parte contratante criar uma conta com o e-mail{" "}
              <b>{proposal.contractor.email}</b> para poder assinar o contrato.
            </>
          ),
        })
      }
    } else {
      setShowBreadAlert({})
    }
  }

  useEffect(() => {
    setDefaultValuesAcceptForm()

    if (proposal.status === "accepted") setShowAcceptModal(false)
    if (proposal.status === "refused") setShowRefuseModal(false)
    updateBreadMessage()
    openCollapseOnIitial()
    setLoaded(true)
  }, [proposal])

  const openCollapseOnIitial = () => {
    // Lista os callapsable .row-title
    const elemsTitle = Array.from(
      document.querySelectorAll(
        ".main-content > .row-title, .proposal-resume > .row-title"
      )
    )

    // Lista os callapsable .row-content
    const elemContent = Array.from(
      document.querySelectorAll(
        ".main-content > .row-content:not(.row-content-actions), .proposal-resume > .row-content"
      )
    )

    // if (elemsTitle.length === 0) {
    //   elemsTitle = Array.from(
    //     document.querySelectorAll("")
    //   )
    //   elemContent = Array.from(
    //     document.querySelectorAll("")
    //   )
    // }
    const createProposalPath = "/dashboard/proposal/"
    if (
      location.pathname.includes(createProposalPath) ||
      (proposal.created_by === proposal.role &&
        [ProposalStatusEnum.draft].includes(proposal.status)) ||
      (proposal.created_by !== proposal.role &&
        [ProposalStatusEnum.waiting_accept].includes(proposal.status))
    ) {
      // Abre todos collapsable
      const keys = []

      const lastTitle = elemsTitle.slice(-1)[0]
      const lastContent = elemContent.slice(-1)[0]
      lastTitle?.setAttribute("data-is-last", "true")
      lastContent?.setAttribute("data-is-last", "true")

      elemContent.forEach((item) => {
        keys.push(item.id)
      })

      setTimeout(() => openColappse(keys), 1000)
    } else {
      // Obtém o último collapsable row-title e row-content
      const lastTitle = elemsTitle.slice(-1)[0]
      const lastContent = elemContent.slice(-1)[0]

      if (!activeKeys?.includes(lastContent.id)) {
        lastTitle?.setAttribute("data-is-last", "true")
        lastContent?.setAttribute("data-is-last", "true")
        setTimeout(() => openColappse([lastContent.id]), 1000)
      }
    }
  }

  const hasSigners = () => {
    return (
      proposal.contract.signers.filter((x) => x.proposal_role === "CONTRACTOR")
        .length >= 1 &&
      proposal.contract.signers.filter((x) => x.proposal_role === "CONTRACTED")
        .length >= 1
    )
  }

  // const handleChooseNF = (event) => {
  //   try {
  //     const file = event.target.files[0]
  //     if (!file || !file.type.match(/(pdf)/)) {
  //       throw Error("Formato inválido, envie a Nota Fiscal em formato PDF.")
  //     }

  //     if (file.size > 5000000) {
  //       throw Error("Arquivo deve ser menor que 5MB")
  //     }

  //     updateNFSelected({ file })
  //     onUploadNF(file)
  //   } catch (err) {
  //     updateNFSelected({ error: err.message })
  //   }
  // }

  const handleChooseContract = (event) => {
    try {
      const file = event.target.files[0]
      if (!file || !file.type.match(/(pdf)/)) {
        throw Error("Formato inválido, selecione arquivo PDF.")
      }

      if (file.size > 10000000) {
        throw Error("Arquivo deve ser menor que 10MB")
      }

      setShowContractWarning(false)
      updateContractSelected({ file, default: false })
      setShowUploadContractModal(false)
    } catch (err) {
      updateContractSelected({ error: err.message, default: false })
    }
  }

  const reachProposalLimit = () => {
    if (
      profileData?.proposals_limit &&
      profileData?.count_active_contracts &&
      profileData?.count_active_contracts >= profileData.proposals_limit
    ) {
      setShowModal({ type: "show-limit-proposals-modal" })
      return true
    }

    return false
  }

  const openColappse = useCallback(
    (keys) => {
      setActiveKeys([...keys, ...activeKeys])
    },
    [activeKeys]
  )

  const closeColappse = useCallback(
    (keys) => {
      setActiveKeys(activeKeys.filter((x) => !keys.includes(x)))
    },
    [activeKeys]
  )

  const dynamicElements = useMemo(() => {
    return dynamicResume[proposal.created_by][proposal.type]
  }, [proposal.created_by, proposal.type])

  // const beforeUpload = (file) => {
  //   setError(null)
  //   const isLessThen5M = file.size / 1000000 < 5

  //   if (!isLessThen5M) {
  //     setError("O Arquivo precisa ser menor que 5Mb!")
  //     return false
  //   }
  //   return true
  // }

  return (
    <div className="proposal-details" style={{ opacity: isLoaded ? "1" : "0" }}>
      <div className="main-content-top" />
      <div className="main-content">
        <Row className="proposal-header-actions" justify="end">
          {proposal.role === "CONTRACTED" &&
          proposal.status === "waiting_nfs" ? (
            <Col>
              <WhiteButton
                // icon={<Icons icon="upload-circle" />}
                onClick={() => {
                  history.push(`/dashboard/nfse/create/${proposal.id}`)
                }}
              >
                Enviar nota fiscal
              </WhiteButton>
            </Col>
          ) : null}
        </Row>
        <Row
          justify="space-between"
          gutter={[16, 0]}
          className="row-title row-proposal-title"
          align="middle"
        >
          <Col xs={24} sm={24} md={10} lg={10} className="normal-24">
            <span className="proposal-title not-overflow">
              {proposal.title}
            </span>
          </Col>

          <Col
            xs={24}
            sm={24}
            md={6}
            lg={6}
            className="normal-16 proposal-status-col"
          >
            <ProposalStatus status={proposal.status}>
              {proposal.status_name}
            </ProposalStatus>
          </Col>

          <Col
            xs={24}
            sm={24}
            md={8}
            lg={8}
            className="normal-16 proposal-created-at"
          >
            <span>
              Data proposta:{" "}
              {proposal.created_at ? proposal.created_at.split(" ")[0] : "-"}
            </span>
            <span>
              {onShare && (
                <Icons
                  icon="share"
                  title="Compartilhar Proposta"
                  onClick={onShare}
                />
              )}
            </span>
          </Col>
        </Row>

        <ProposalResume
          values={proposal}
          elements={dynamicElements}
          activeKeys={activeKeys}
          openColappse={openColappse}
          closeColappse={closeColappse}
        />

        <CollapseRowHeader
          isActive={activeKeys.includes("row-content-history")}
          collapseFor="row-content-history"
          title="Histórico"
          icon="history"
          resume="Histórico de alterações."
          onOpen={openColappse}
          onClose={closeColappse}
        />

        <CollapseRowContent
          id="row-content-history"
          isActive={activeKeys.includes("row-content-history")}
        >
          <div style={{ width: "100%", maxHeight: "250px", overflowY: "auto" }}>
            {proposal.history &&
              proposal.history.map((item, index) => {
                return (
                  <Row className="item-history" key={index}>
                    <Col span={12}>{item.formatted_date}</Col>
                    <Col span={12}>{item.description}</Col>
                  </Row>
                )
              })}
          </div>
        </CollapseRowContent>
        {proposal.status === "refused" && (
          <>
            <CollapseRowHeader
              isActive={activeKeys.includes("row-content-refused")}
              collapseFor="row-content-refused"
              title="Justificativa recusa"
              icon="contract-refused"
              resume=""
              onOpen={openColappse}
              onClose={closeColappse}
            />

            <CollapseRowContent
              id="row-content-refused"
              isActive={activeKeys.includes("row-content-refused")}
            >
              {proposal.review_message &&
              proposal.review_message.trim().length > 0
                ? proposal.review_message
                : "A proposta foi recusada sem justificativa"}
            </CollapseRowContent>
          </>
        )}

        {isOwner &&
        proposal.status === "accepted" &&
        [undefined, "draft"].includes(proposal.contract?.status) ? (
          <>
            <CollapseRowHeader
              isActive={activeKeys.includes("row-content-contract")}
              collapseFor="row-content-contract"
              title="Contrato"
              icon="onboardingDocument"
              resume="Upload do contrato."
              onOpen={openColappse}
              onClose={closeColappse}
            />

            <CollapseRowContent
              id="row-content-contract"
              isActive={activeKeys.includes("row-content-contract")}
              className="contract-file"
            >
              <Col span={12} className="normal-14-500">
                {canUseDefaultContract
                  ? "Nesta etapa você pode utilizar o nosso modelo de contrato ou " +
                    "efetuar o upload do modelo de contrato de sua preferência:"
                  : "Nesta etapa você deve efetuar o upload do modelo de contrato " +
                    "de sua preferência:"}
              </Col>
              {canUseDefaultContract ? (
                <Col span={24}>
                  <Row style={{ marginBottom: "15px" }}>
                    <RadioButton
                      checked={contractSelected.default}
                      onChange={(a) => {
                        updateContractSelected({ default: a.target.checked })
                      }}
                    >
                      Modelo de contrato padrão do Propozall
                    </RadioButton>
                  </Row>
                  <Row
                    style={{
                      display: contractSelected.default ? "flex" : "none",
                    }}
                  >
                    <Col>
                      <BoxLink
                        to="#"
                        onClick={() => onDownloadDefaultContract()}
                      >
                        contrato-padrao.doc
                      </BoxLink>
                    </Col>
                    <Col>
                      <GrayButton
                        icon={<Icons icon="download" />}
                        onClick={() => onDownloadDefaultContract()}
                      >
                        Baixar contrato
                      </GrayButton>
                    </Col>
                  </Row>
                </Col>
              ) : null}
              <Col span={24}>
                <Row style={{ marginBottom: "15px" }}>
                  <RadioButton
                    checked={!contractSelected.default}
                    onChange={(a) => {
                      updateContractSelected({ default: !a.target.checked })
                    }}
                  >
                    Seu modelo de contrato
                  </RadioButton>
                </Row>
                <Row
                  style={{
                    display: contractSelected.default ? "none" : "flex",
                  }}
                >
                  {contractSelected.file ? (
                    <>
                      <Col>
                        <BoxLink
                          to="#"
                          onClick={() => {
                            const link = document.createElement("a")
                            const file = contractSelected.file
                            link.setAttribute("download", file.name)
                            link.href = URL.createObjectURL(file)
                            link.click()
                            link.remove()
                          }}
                          onClose={() => {
                            updateContractSelected({
                              default: false,
                              file: null,
                            })
                          }}
                        >
                          {contractSelected.file.name}
                        </BoxLink>
                      </Col>
                      <Col>
                        <GrayButton
                          icon={<Icons icon="download" />}
                          onClick={() => {
                            const link = document.createElement("a")
                            const file = contractSelected.file
                            link.setAttribute("download", file.name)
                            link.href = URL.createObjectURL(file)
                            link.click()
                            link.remove()
                          }}
                        >
                          Baixar contrato
                        </GrayButton>
                      </Col>
                    </>
                  ) : (
                    <Col>
                      <GrayButton
                        icon={<Icons icon="clips" />}
                        onClick={() => {
                          if (!reachProposalLimit()) {
                            setShowUploadContractModal(true)
                          }
                        }}
                      >
                        Anexar contrato
                      </GrayButton>
                      <ErrorMessage
                        text="Selecione o seu modelo de contrato."
                        isVisible={showContractWarning}
                      />
                    </Col>
                  )}
                </Row>
              </Col>
            </CollapseRowContent>
          </>
        ) : null}

        {proposal.contract && proposal.contract?.status !== "draft" ? (
          <>
            <CollapseRowHeader
              isActive={activeKeys.includes("row-content-contract")}
              collapseFor="row-content-contract"
              title="Contrato"
              icon="onboardingDocument"
              resume="Contrato anexado."
              onOpen={openColappse}
              onClose={closeColappse}
            />

            <CollapseRowContent
              id="row-content-contract"
              isActive={activeKeys.includes("row-content-contract")}
              className="contract-file"
            >
              <Col>
                <BoxLink to="#" onClick={() => onDownloadContract("original")}>
                  contrato-original.pdf
                </BoxLink>
              </Col>
              {proposal.contract?.status === "finalized" ? (
                <Col>
                  <BoxLink to="#" onClick={() => onDownloadContract("signed")}>
                    contrato-assinado.pdf
                  </BoxLink>
                </Col>
              ) : null}
              {proposal.contract?.status &&
              !["draft", "created", "waiting_sign", "signing"].includes(
                proposal.contract?.status
              ) ? (
                <Col>
                  {/* Exibe o status do contrato apenas quando 
                    está cancelado ou expirado */}
                  <ContractStatus status={proposal.contract?.status}>
                    {proposal.contract?.status_name}
                  </ContractStatus>
                </Col>
              ) : null}
              {isOwner &&
              ["created", "waiting_sign", "signing"].includes(
                proposal.contract?.status
              ) &&
              onResetContract ? (
                <Col span={24} className="signers-actions">
                  <GrayButton
                    onClick={() =>
                      setShowModal({ type: "edit-contract-warning" })
                    }
                    isLoading={isResetContratLoading}
                  >
                    Usar outro contrato
                  </GrayButton>
                </Col>
              ) : null}
            </CollapseRowContent>

            <CollapseRowHeader
              isActive={activeKeys.includes("row-content-signers")}
              collapseFor="row-content-signers"
              title="Assinantes"
              icon="onboardingAssign"
              resume="Nome, Quem assina, CPF, e-mail, status da assinatura."
              onOpen={openColappse}
              onClose={closeColappse}
            />

            <CollapseRowContent
              id="row-content-signers"
              isActive={activeKeys.includes("row-content-signers")}
            >
              {proposal.contract.status === "created" ? (
                <Col
                  span={12}
                  className="normal-14-500"
                  style={{ marginBottom: "10px" }}
                >
                  {isOwner
                    ? "Se você quiser adicione outras pessoas para assinar este contrato."
                    : ""}
                </Col>
              ) : null}
              <SignersTable signers={proposal.contract.signers} />
              {isOwner &&
              ["created", "waiting_sign", "signing"].includes(
                proposal.contract?.status
              ) ? (
                <>
                  <Col span={12}>
                    <Row justify="start">
                      <ErrorMessage
                        text={
                          "Você deve adicionar pelo menos 1 assinante para cada uma das partes."
                        }
                        isVisible={showAlertNoSigners}
                      />
                    </Row>
                  </Col>

                  <Col span={24} className="signers-actions">
                    <GrayButton
                      onClick={() => {
                        setShowAlertNoSigners(false)
                        onAddSigners()
                      }}
                      isLoading={isGetContractLoading}
                    >
                      Editar ou adicionar assinantes
                    </GrayButton>
                  </Col>
                </>
              ) : null}
              {!isOwner &&
              ["draft", "created"].includes(proposal.contract?.status) ? (
                <Col
                  span={24}
                  className="normal-14"
                  style={{ marginTop: "15px", color: "#676767" }}
                >
                  Aguarde o dono da proposta fechar o contrato. Os assinantes
                  irão receber um e-mail quando o contrato estiver disponível
                  para assinatura.
                </Col>
              ) : null}
            </CollapseRowContent>
          </>
        ) : null}
        {/* {isOwner &&
        ["created", "waiting_sign", "signing"].includes(
          proposal.contract?.status
        ) ? (
          <>
            <CollapseRowHeader
              isActive={activeKeys.includes("row-content-logotype")}
              collapseFor="row-content-logotype"
              title="Adicionar logotipo"
              icon="image"
              resume="Personalize seu contrato adicionando seu logotipo."
              onOpen={openColappse}
              onClose={closeColappse}
            />

            <CollapseRowContent
              id="row-content-logotype"
              isActive={activeKeys.includes("row-content-logotype")}
            >
              <div className="normal-14-500">
                Deixe seu contrato personalizado adicionando seu logotipo.
              </div>
              <FileUploadBox
                icon="upload-logotype"
                beforeUpload={beforeUpload}
              />

              <Row justify="center">
                <ErrorMessage text={error} isVisible={!!error} />
              </Row>
            </CollapseRowContent>
          </>
        ) : null} */}

        {["waiting_nfs", "finalized", "expired", "canceled"].includes(
          proposal.status
        ) ? (
          <>
            <CollapseRowHeader
              isActive={activeKeys.includes("row-content-nfs")}
              collapseFor="row-content-nfs"
              title="Nota Fiscal"
              icon="onboardingDocNfse"
              resume="Notas fiscais geradas referentes a essa proposta."
              onOpen={openColappse}
              onClose={closeColappse}
            />

            <CollapseRowContent
              id="row-content-nfs"
              isActive={activeKeys.includes("row-content-nfs")}
            >
              <Col
                span={13}
                className="normal-14-500"
                style={{ padding: "0 100px 20px 0" }}
              >
                {proposal.count_nfs > 0 ? (
                  <>
                    Visualize as notas fiscais referentes a essa proposta
                    clicando no botão abaixo.
                  </>
                ) : (
                  <>Nenhuma nota fiscal foi enviada para essa proposta.</>
                )}
              </Col>
              <Col span={13}>
                {proposal.count_nfs > 0 ? (
                  <GrayButton
                    // icon={<Icons icon="show" />}
                    onClick={() => {
                      history.push(`/dashboard/nfse?proposal_id=${proposal.id}`)
                    }}
                  >
                    Ver notas fiscais
                  </GrayButton>
                ) : (
                  proposal.status === "finalized" && (
                    <div style={{ padding: "0 15px" }}>
                      Nota fiscal não enviada.
                    </div>
                  )
                )}
              </Col>
            </CollapseRowContent>
          </>
        ) : null}

        <Row
          justify="end"
          className="row-content row-content-actions"
          align="bottom"
        >
          {isOwner &&
          proposal.status === "accepted" &&
          [undefined, "draft"].includes(proposal.contract?.status) &&
          onSendContract ? (
            <BlueButton
              disabled={!contractSelected.default && !contractSelected.file}
              onClick={() => {
                if (
                  !reachProposalLimit() &&
                  !contractSelected.default &&
                  !contractSelected.file
                ) {
                  setShowContractWarning(true)
                } else {
                  onSendContract(contractSelected.file)
                }
              }}
              isLoading={isLoading}
            >
              Criar contrato
            </BlueButton>
          ) : null}

          {isOwner &&
          proposal.contract?.status === "created" &&
          onCloseContract ? (
            <BlueButton
              onClick={() => {
                if (!hasSigners()) {
                  setShowAlertNoSigners(true)
                } else if (!reachProposalLimit()) {
                  onCloseContract()
                }
              }}
              isLoading={isLoading}
            >
              Enviar contrato aos assinantes
            </BlueButton>
          ) : null}
        </Row>
      </div>
      <Row>
        {showBreadAlert?.type && showBreadAlert?.text ? (
          <BreadMessage
            type={showBreadAlert?.type}
            text={showBreadAlert?.text}
            isVisible={true}
            style={{ marginTop: "15px" }}
          />
        ) : null}
      </Row>
      <Row justify="space-between" style={{ paddingTop: "20px" }}>
        <Col>
          {isOwner && onCancelProposal ? (
            <RedButton
              style={{ minWidth: "205px" }}
              disabled={[
                "finalized",
                "expired",
                "canceled",
                "refused",
              ].includes(proposal.status)}
              onClick={() => {
                if (proposal.status === "draft") {
                  setShowModal({ type: "delete-proposal" })
                } else {
                  setShowModal({ type: "cancel-proposal" })
                }
              }}
              isLoading={isCancelLoading}
            >
              {proposal.status === "draft"
                ? "Excluir proposta"
                : "Cancelar proposta"}
            </RedButton>
          ) : null}
          {["waiting_review", "waiting_accept"].includes(proposal.status) &&
          proposal.created_by !== proposal.role &&
          onAcceptProposal ? (
            <RedButton
              isLoading={isLoading && showRefuseModal}
              style={{ minWidth: "205px" }}
              onClick={() => setShowRefuseModal(true)}
            >
              Recusar Proposta
            </RedButton>
          ) : null}
        </Col>
        <Col>
          {["waiting_review", "waiting_accept"].includes(proposal.status) &&
          proposal.created_by !== proposal.role &&
          onAcceptProposal ? (
            <BlueButton
              isLoading={isLoading && showAcceptModal}
              onClick={() => setShowAcceptModal(true)}
            >
              Aceitar Proposta
            </BlueButton>
          ) : null}

          {isOwner ? (
            ["draft", "waiting_accept"].includes(proposal.status) ? (
              <BlueButton
                style={{ minWidth: "205px" }}
                onClick={() => {
                  history.push({
                    pathname: `/dashboard/proposal/1/${proposal.id}`,
                  })
                }}
              >
                Editar proposta
              </BlueButton>
            ) : (
              <BlueButton
                style={{ minWidth: "205px" }}
                disabled={
                  ["finalized", "expired", "canceled", "refused"].includes(
                    proposal.status
                  ) || !onFinalizeProposal
                }
                onClick={() => {
                  setShowModal({ type: "finalize-proposal" })
                }}
                isLoading={isFinalizeLoading}
              >
                Finalizar proposta
              </BlueButton>
            )
          ) : null}
        </Col>
      </Row>

      <BaseModal
        className="upload-contract-modal"
        isVisible={showUploadContractModal}
        onCancel={() => setShowUploadContractModal(false)}
        title="Subir modelo de contrato"
      >
        <div className="normal-12">
          É importante inserir as informações abaixo em seu contrato antes de
          fazer o upload do arquivo.
        </div>
        <div className="box-required-text normal-12">
          {proposal.contract_statement_required}
        </div>
        <Row className="actions" justify="center">
          <Col>
            <WhiteButton
              onClick={() => {
                document.getElementById("choose-contract-file").click()
              }}
            >
              Selecionar Arquivo
            </WhiteButton>
          </Col>
        </Row>
        <input
          id="choose-contract-file"
          type="file"
          onChange={handleChooseContract}
        />
        <div className="file-info normal-12">
          Tamanho máximo de upload de arquivo: 10 MB.
          <ErrorMessage
            text={contractSelected.error}
            isVisible={!!contractSelected.error}
          />
        </div>
      </BaseModal>
      <BaseModal
        isVisible={showAcceptModal}
        title={
          proposal.role === "CONTRACTOR"
            ? "Dados contratante"
            : "Dados contratado"
        }
        okButtonText="Aceitar Proposta"
        cancelButtonText="Cancelar"
        isLoading={isLoading}
        onCancel={() => {
          setShowAcceptModal(false)
          setDefaultValuesAcceptForm()
        }}
        onOk={() => {
          formReview.submit()
        }}
      >
        <Form
          layout="vertical"
          form={formReview}
          style={{ width: "100%" }}
          onFinish={(values) => {
            onAcceptProposal(values)
          }}
        >
          <Row>
            <div className="normal-14">
              Insira ou confirme seus dados como contraparte desta proposta para
              aceita-la.
            </div>
          </Row>
          <br />
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                initialValue=""
                name="cpf_cnpj"
                label={proposal.role === "CONTRACTOR" ? "CPF/CNPJ" : "CNPJ"}
                rules={[
                  {
                    required: true,
                    message: (
                      <div className="normal-12"> Campo obrigatório </div>
                    ),
                  },
                  () => ({
                    validator(_, value) {
                      const val = (value ?? "").replace(/[^0-9]+/g, "")

                      if (
                        val?.trim()?.toLowerCase() ===
                        owner?.cpf_cnpj?.trim()?.toLowerCase()
                      ) {
                        const label = val.length === 11 ? "CPF" : "CNPJ"
                        const role =
                          owner?.role === "CONTRACTOR"
                            ? "contratante"
                            : "contratado"

                        return Promise.reject(
                          new Error(`${label} é igual ao ${label} do ${role}`)
                        )
                      }

                      if (val.length > 11) {
                        if (cnpj.isValid(val)) return Promise.resolve()
                        if (
                          val.length === 14 &&
                          value.replace(/[^0]/g, "").length === 14
                        )
                          return Promise.resolve()

                        return Promise.reject(new Error("CNPJ inválido"))
                      }

                      if (proposal.role === "CONTRACTOR") {
                        if (!cpf.isValid(val))
                          return Promise.reject(new Error("CPF inválido"))
                      } else {
                        return Promise.reject(new Error("CNPJ inválido"))
                      }

                      return Promise.resolve()
                    },
                  }),
                ]}
              >
                {proposal.role === "CONTRACTOR" ? (
                  <FlattedMaskedInput
                    maskOptions={{
                      dispatch: function (appended, dynamicMasked) {
                        const value = dynamicMasked.unmaskedValue
                        const isCPF = value.length <= 11 || cpf.isValid(value)
                        return dynamicMasked.compiledMasks[isCPF ? 0 : 1]
                      },
                    }}
                    mask={[
                      {
                        mask: "000.000.000-000?",
                        lazy: true,
                      },
                      {
                        mask: "00.000.000/0000-00",
                        lazy: true,
                      },
                    ]}
                    placeholder="Insira seu CPF ou CNPJ"
                    style={{ width: "270px" }}
                  />
                ) : (
                  <FlattedMaskedInput
                    mask="00.000.000/0000-00"
                    placeholder="Insira seu CNPJ"
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={14}>
              <Form.Item
                name="phone"
                label="Telefone ou Celular"
                rules={[
                  {
                    required: true,
                    message: "Este campo é obrigtório.",
                  },
                  () => ({
                    validator(_, value) {
                      if (
                        value !== undefined &&
                        value !== null &&
                        value.length > 0
                      ) {
                        const val = value.replace(/[^0-9]+/g, "")
                        if (!allDDDs.includes(val.slice(0, 2))) {
                          return Promise.reject(new Error("DDD válido"))
                        }

                        if (invalidPhoneNumbers.includes(val)) {
                          return Promise.reject(
                            new Error("Este não é um número válido")
                          )
                        }

                        if (val.length < 10) {
                          return Promise.reject(
                            new Error("Informe um número válido")
                          )
                        }

                        return Promise.resolve()
                      } else {
                        return Promise.reject(new Error("Informe um número"))
                      }
                    },
                  }),
                ]}
              >
                <FlattedPhoneInputForm placeholder="Digite seu número" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Novo e-mail"
                name="email"
                rules={[
                  {
                    required: false,
                    message: "Este campo é obrigtório.",
                  },
                  {
                    type: "email",
                    message: "E-mail inválido",
                  },
                  () => ({
                    validator(_, value) {
                      if (
                        value?.trim()?.toLowerCase() ===
                        owner?.email?.trim()?.toLowerCase()
                      ) {
                        const role =
                          owner?.role === "CONTRACTOR"
                            ? "contratante"
                            : "contratado"

                        return Promise.reject(
                          new Error(`E-mail é igual ao e-mail do ${role}`)
                        )
                      } else {
                        return Promise.resolve()
                      }
                    },
                  }),
                ]}
              >
                <FlattedInput
                  disabled={!!counterparty?.email}
                  type="email"
                  placeholder="Digite seu e-mail"
                  style={{ width: "270px" }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </BaseModal>
      <BaseModal
        isVisible={showRefuseModal}
        title={"Recusar proposta"}
        okButtonText="Enviar"
        cancelButtonText="Cancelar"
        isLoading={isLoading}
        onCancel={() => {
          setShowRefuseModal(false)
          setDefaultValuesAcceptForm()
        }}
        onOk={() => {
          formReview.submit()
        }}
      >
        <Form
          layout="vertical"
          form={formReview}
          style={{ width: "100%" }}
          onFinish={(values) => {
            onRefuseProposal(values)
          }}
        >
          <Row>
            <div className="normal-14">
              Digite abaixo os motivos que estão te levando a recusar esta
              proposta para a outra parte poder ajustar.
            </div>
          </Row>
          <br />

          <Form.Item
            initialValue=""
            name="review_message"
            rules={[
              {
                required: true,
                message: <div className="normal-12"> Campo obrigatório </div>,
              },
            ]}
          >
            <FlattedTextArea placeholder="Digite aqui sua justificativa." />
          </Form.Item>
        </Form>
      </BaseModal>
      <DeleteOrCancelModal
        isVisible={
          showModal.type === "cancel-proposal" ||
          showModal.type === "delete-proposal"
        }
        isDelete={showModal.type === "delete-proposal"}
        isLoading={isCancelLoading}
        onOk={() => {
          onCancelProposal()
          setShowModal({})
        }}
        onCancel={() => {
          setShowModal({})
        }}
      />
      <BaseModal
        type="alert"
        isVisible={showModal.type === "finalize-proposal"}
        title={"Finalizar proposta"}
        isLoading={isFinalizeLoading}
        okButtonText={"Continuar"}
        // okButtonColor="red"
        onOk={() => {
          onFinalizeProposal()
          setShowModal({})
        }}
        cancelButtonText={"Cancelar"}
        onCancel={() => {
          setShowModal({})
        }}
      >
        <Row justify="center">
          {["accepted", "waiting_sign_contract"].includes(proposal.status) ? (
            <>
              A proposta será finalizada sem um contrato assinado pelas partes.
              Deseja continuar mesmo assim?
            </>
          ) : proposal.status === "waiting_nfs" && proposal.nfs.length === 0 ? (
            <>
              Você não adicionou notas fiscais para esta proposta. Deseja
              continuar mesmo assim?
            </>
          ) : (
            <>
              Ao finalizar esta proposta você não poderá dar continuidade ao
              processo.
            </>
          )}
        </Row>
      </BaseModal>
      <BaseModal
        type="alert"
        isVisible={showModal.type === "edit-contract-warning"}
        title="Usar outro contrato"
        width={600}
        cancelButtonText="Cancelar"
        onCancel={() => setShowModal({})}
        okButtonText="Continuar"
        onOk={() => {
          onResetContract()
          setShowModal({})
        }}
      >
        <div style={{ marginBottom: "10px" }}>
          Ao mudar de contrato será peciso enviá-lo para que as pessoas assinem
          novamente. Deseja continuar mesmo assim?
        </div>
      </BaseModal>
      <BaseModal
        type="alert"
        isVisible={showModal.type === "show-limit-proposals-modal"}
        onClose={false}
        title="Não é possivel enviar contratos"
        subtitle={`Você atingiu o limite total de ${profileData?.proposals_limit} propostas no Plano ${profileData?.plan_name} e não pode enviar mais contratos no momento. Aumente o seu plano para poder enviar mais contratos.`}
        okButtonText="Aumentar Plano"
        cancelButtonText="Continuar"
        onOk={
          () =>
            history.push({
              pathname: "/dashboard/config",
              search: "?tab=4&update=plan",
            })

          // history.push("/dashboard/config?tab=4&update=plan")
        }
        onCancel={() => setShowModal({})}
      ></BaseModal>
    </div>
  )
}

ProposalDetails.propTypes = {
  proposal: PropTypes.object,
  isLoading: PropTypes.bool,
  isGetContractLoading: PropTypes.bool,
  isCancelLoading: PropTypes.bool,
  isFinalizeLoading: PropTypes.bool,
  onAcceptProposal: PropTypes.func,
  onRefuseProposal: PropTypes.func,
  onFinalizeProposal: PropTypes.func,
  onDownloadDefaultContract: PropTypes.func,
  onDownloadContract: PropTypes.func,
  onSendContract: PropTypes.func,
  onCloseContract: PropTypes.func,
  onCancelProposal: PropTypes.func,
  onResetContract: PropTypes.func,
  onAddSigners: PropTypes.func,
  onUploadNF: PropTypes.func,
  onShare: PropTypes.func,
  isResetContratLoading: PropTypes.bool,
  actions: PropTypes.element,
  profileData: PropTypes.object,
}

export default ProposalDetails
