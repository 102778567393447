import React, { useEffect, useState } from "react"
import { Form, Row, Col, Select } from "antd"
import BaseModal from "../../../../../../common/components/Modal"
import FlattedInput, {
  FlattedMaskedInput,
} from "../../../../../../common/components/FlattedInput"
import Search from "../../../../../../common/components/Search"
import { useDispatch, useSelector } from "react-redux"
import PropTypes from "prop-types"
import {
  listBanks,
  selectBanks,
  selectConfigState,
} from "../../../../configurationsSlice"
import "./styles.scss"
import StateStatus from "../../../../../../utils/stateStatus"
import { cnpj, cpf } from "cpf-cnpj-validator"

const { useForm } = Form

const accountTypes = {
  corrente: "Corrente",
  poupança: "Poupança",
}

const BankAccountModal = ({ data, isLoading, onSubmit, onCancel }) => {
  const [hiddenActions, updateHiddenActions] = useState(false)
  const [form] = useForm()

  return (
    <BaseModal
      isVisible={true}
      isLoading={isLoading}
      okButtonText={hiddenActions ? null : "Salvar"}
      cancelButtonText={"Cancelar"}
      onOk={
        hiddenActions
          ? null
          : () => {
              form.submit()
            }
      }
      onCancel={() => {
        onCancel()
        form.resetFields()
      }}
      title={data && data.id ? "Editar Conta" : "Adicionar Conta"}
    >
      <BankAccountForm
        data={data}
        description={
          data && data.id ? "" : "Insira os dados da conta que deseja cadastrar"
        }
        onHiddenAction={updateHiddenActions}
        form={form}
        onSubmit={onSubmit}
      />
    </BaseModal>
  )
}

const BankAccountForm = ({
  data,
  description,
  onHiddenAction,
  form,
  onSubmit,
}) => {
  const [bankAccount, updateBankAccount] = useState(data || {})
  const configState = useSelector(selectConfigState)
  const banks = useSelector(selectBanks)
  const [filteredBanks, updateFilteredBanks] = useState([...banks])
  const [showBanks, updateShowBanks] = useState(!bankAccount.bank_code)
  const dispatch = useDispatch()

  useEffect(() => {
    if (showBanks) {
      onHiddenAction(true)
    } else {
      onHiddenAction(false)
    }
  }, [showBanks])

  useEffect(() => {
    switch (configState.status.listBanks) {
      case StateStatus.idle:
        dispatch(listBanks())
        break
      case StateStatus.succeeded:
        updateFilteredBanks(banks)
        break
    }
  }, [configState.status.listBanks])

  const onSearchBanks = (value) => {
    value = value.toLowerCase().trim()

    if (value === "") {
      updateFilteredBanks([...banks])
    } else {
      const filtered = []
      banks.forEach((item, index) => {
        if (item.label.toLowerCase().indexOf(value) > -1) {
          filtered.push(item)
        }
      })

      updateFilteredBanks(filtered)
    }
  }

  const updateBank = (bank) => {
    const newBankAccount = { ...bankAccount }
    newBankAccount.bank_code = bank.value
    newBankAccount.bank_name = bank.label
    updateBankAccount(newBankAccount)
    updateShowBanks(false)
  }

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={(values) => {
        values.bank_code = bankAccount.bank_code
        if (onSubmit) {
          onSubmit(values)
        }
        form.resetFields()
      }}
    >
      {description && (
        <div className="normal-14 modal-description">{description}</div>
      )}

      <div className="search-bank">
        <div className="normal-12">Escolha o seu banco</div>
        <Row justify="center">
          <Search
            placeholder="Busque por banco ou instituição"
            onSearch={onSearchBanks}
            delay={0.2}
            onClick={() => updateShowBanks(true)}
          />
        </Row>
      </div>
      {showBanks ? (
        <div className="list-bank">
          {filteredBanks.map((item, index) => (
            <div
              key={index}
              className="item-bank"
              onClick={() => {
                updateBank(item)
                form.setFieldsValue({ bank_name: item.label })
              }}
            >
              {item.label}
            </div>
          ))}
        </div>
      ) : (
        <div>
          <Row justify="center">
            <div className="bank-selected-box">
              {bankAccount.bank_code} - {bankAccount.bank_name}
            </div>
          </Row>
          <Row gutter={[16, 0]} justify="space-between">
            <Col span={12}>
              <Form.Item
                initialValue={bankAccount.account_type}
                name="account_type"
                label="Tipo de Conta"
                rules={[
                  {
                    required: true,
                    message: <div className="normal-12">Campo obrigatório</div>,
                  },
                ]}
              >
                <Select
                  placeholder="Selecione uma opção"
                  notFoundContent={null}
                  filterOption={false}
                >
                  {Object.keys(accountTypes).map((key, index) => (
                    <Select.Option key={key}>{accountTypes[key]}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                initialValue={bankAccount.agency}
                name="agency"
                label="Agência"
                rules={[
                  {
                    required: true,
                    message: <div className="normal-12">Campo obrigatório</div>,
                  },
                  {
                    pattern: /^(?:[\d]*)$/,
                    message: (
                      <div className="normal-12">Insira apenas números</div>
                    ),
                  },
                  {
                    pattern: /^.{0,6}$/,
                    message: (
                      <div className="normal-12">Máximo de 6 dígitos</div>
                    ),
                  },
                ]}
              >
                <FlattedInput style={{ width: "100%" }}></FlattedInput>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]} justify="space-between">
            <Col span={12}>
              <Form.Item
                initialValue={bankAccount.account_number}
                name="account_number"
                label="Conta"
                rules={[
                  {
                    required: true,
                    message: <div className="normal-12">Campo obrigatório</div>,
                  },
                  {
                    pattern: /^(?:[\d-]*)$/,
                    message: (
                      <div className="normal-12">Caracteres inválidos</div>
                    ),
                  },
                  {
                    pattern: /^.{0,20}$/,
                    message: (
                      <div className="normal-12">Máximo de 20 caracteres</div>
                    ),
                  },
                ]}
              >
                <FlattedInput style={{ width: "100%" }}></FlattedInput>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                initialValue={bankAccount.pix_key}
                name="pix_key"
                label="Chave Pix"
                rules={[
                  {
                    pattern: /^.{0,255}$/,
                    message: (
                      <div className="normal-12">Máximo de 255 caracteres</div>
                    ),
                  },
                ]}
              >
                <FlattedInput style={{ width: "100%" }}></FlattedInput>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]} justify="space-between">
            <Col span={12}>
              <Form.Item
                name="cpf_cnpj"
                label="CPF/CNPJ Favorecido"
                initialValue={bankAccount.cpf_cnpj}
                rules={[
                  () => ({
                    validator(_, value) {
                      if (value && value.length > 1) {
                        const val = value.replace(/[^0-9]+/g, "")

                        if (val.length > 11) {
                          if (cnpj.isValid(val)) return Promise.resolve()

                          return Promise.reject(new Error("CNPJ inválido"))
                        }

                        if (!cpf.isValid(val))
                          return Promise.reject(new Error("CPF inválido"))
                      }

                      return Promise.resolve()
                    },
                  }),
                ]}
              >
                <FlattedMaskedInput
                  style={{ width: "100%" }}
                  maskOptions={{
                    dispatch: function (appended, dynamicMasked) {
                      const value = dynamicMasked.unmaskedValue
                      const isCPF = value.length <= 11 || cpf.isValid(value)
                      return dynamicMasked.compiledMasks[isCPF ? 0 : 1]
                    },
                  }}
                  mask={[
                    {
                      mask: "000.000.000-000?",
                      lazy: true,
                    },
                    {
                      mask: "00.000.000/0000-00",
                      lazy: true,
                    },
                  ]}
                  placeholder="CPF ou CNPJ"
                  // onChange={(text) => {
                  //   setIsCpnj(text.length >= 18)
                  // }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                initialValue={bankAccount.owner_name}
                name="owner_name"
                label="Nome Favorecido"
              >
                <FlattedInput style={{ width: "100%" }}></FlattedInput>
              </Form.Item>
            </Col>
          </Row>
        </div>
      )}
    </Form>
  )
}

BankAccountModal.propTypes = {
  data: PropTypes.object,
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
}

BankAccountForm.propTypes = {
  data: PropTypes.object,
  onHiddenAction: PropTypes.func,
  form: PropTypes.object,
  onSubmit: PropTypes.func,
  description: PropTypes.string,
}

export { BankAccountModal, BankAccountForm }
