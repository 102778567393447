import React, { useEffect, useState } from "react"
//
import { useDispatch, useSelector } from "react-redux"
import {
  getProposals,
  selectCreateNfseSlice,
  updateData,
} from "../../createNfseSlice"
import { selectDashSlice } from "../../../dashboard/DashboardSlice"
import StateStatus from "../../../../utils/stateStatus"
//
import { Dropdown, Form, Row, Select, Upload } from "antd"
import { BlueButton } from "../../../../common/components/Button"
import FlattedInput, {
  FlattedInputCurrency,
  FlattedInputNumber,
} from "../../../../common/components/FlattedInput"
import { DatePicker } from "../../../../common/components/DatePicker"
//
import RoudedBox from "../../../../common/components/RoudedBox"
import Icons from "../../../../common/components/Icons"
import Loading from "../../../../common/components/Loading"
//
import { formatDate } from "../../../../utils/formaters"
import "./styless.scss"
import { useParams, useHistory } from "react-router-dom"

const { useForm } = Form
const { Dragger } = Upload

const InvoiceUpload = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const slice = useSelector(selectCreateNfseSlice)
  const dash = useSelector(selectDashSlice)
  const { proposalId } = useParams()
  //
  const role = dash.mode?.toUpperCase()
  const data = slice.data
  const proposal = slice.proposal
  const proposals = slice.proposals
  //
  const [form] = useForm()
  //
  const [textError, updateTextError] = useState()
  const [selectedFile, updateFile] = useState()

  const customRequest = ({ file }) => {
    try {
      if (!file || !file.type.match(/(pdf)/)) {
        throw Error("Formato inválido, selecione arquivo PDF.")
      } else if (file.size > 5000000) {
        throw Error("Arquivo deve ser menor que 5MB")
      } else {
        const newFile = {
          uid: file.name,
          name: file.name,
          url: window.URL.createObjectURL(
            new Blob([file], { type: file.type })
          ),
          binary: new Blob([file], { type: file.type }),
          status: "done",
          response: null,
          mimeType: file.type,
        }

        updateFile(newFile)
        updateTextError(null)
      }
    } catch (err) {
      updateTextError(err.message)
    }
  }

  const onFinish = (values) => {
    if (selectedFile) {
      const nfse = {
        proposal,
        proposal_id: proposal.id,
        nf_number: values.nf_number,
        value: values.value,
        emitted_at: values.emitted_at,
        service_code: null,
        description: null,
        contractor: proposal.contractor,
        contracted: proposal.contracted,
        public_url: selectedFile.url,
        file: selectedFile,
      }

      dispatch(updateData({ nfse }))
      history.push(`/dashboard/nfse/create/${proposalId}/resume-upload`)
    } else {
      updateTextError("Selecione o PDF da nota fiscal")
    }
  }

  useEffect(() => {
    dispatch(getProposals({ role }))
  }, [])

  return (
    <div className="upload-nfse-page">
      <h1 className="normal-24-500">Upload de Nota Fiscal</h1>

      <p className="normal-12">
        Importe uma nota fiscal local e tenha controle dos seus gastos/ganhos.
      </p>

      {slice.status.getProposals === StateStatus.loading && (
        <>
          <br /> <Loading />
        </>
      )}

      {(slice.status.getProposals === StateStatus.succeeded ||
        slice.status.getProposals === StateStatus.idle) && (
        <>
          <Form form={form} onFinish={onFinish} layout="vertical">
            <Dragger
              maxCount={1}
              className="item-select-file"
              customRequest={customRequest}
            >
              {!selectedFile ? (
                <div className="select-file">
                  <button>
                    <Icons icon="file" />
                    Escolher arquivo
                  </button>
                  <span className={`text ${textError ? "text-error" : ""}`}>
                    {!textError ? "Ou arraste o arquivo PDF aqui." : textError}
                  </span>
                </div>
              ) : (
                <div className="selected-file">
                  <Icons icon="file" />

                  {selectedFile.name}

                  <Icons
                    icon="close"
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()

                      updateFile(null)
                      updateTextError(null)
                    }}
                  />
                </div>
              )}
            </Dragger>

            <Row justify="start" className="text-alert">
              <Icons icon="alert" />
              Preencha com os valores corretos para um melhor controle no
              gráfico disponível na página inicial.
            </Row>

            <RoudedBox>
              <Form.Item
                label="Selecionar proposta *"
                name="proposal_id"
                className="item-proposal"
                initialValue={data.proposal_id || proposal.id}
                rules={[
                  {
                    required: true,
                    message: <div className="normal-12">Campo obrigatório</div>,
                  },
                ]}
              >
                <Select placeholder="Digite ou selecione o código do serviço">
                  {proposals.map((proposal) => (
                    <Select.Option value={proposal.id} key={proposal.id}>
                      {proposal.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="value"
                label="Valor da NF-e *"
                initialValue={data.value || proposal.total_value / 100}
                rules={[
                  {
                    required: true,
                    message: <div className="normal-12">Campo obrigatório</div>,
                  },
                ]}
              >
                <FlattedInputCurrency placeholder="Digite o valor da sua nota fiscal" />
              </Form.Item>
            </RoudedBox>

            <RoudedBox>
              <Form.Item
                name="nf_number"
                label="Número NF-e *"
                className="item-number-nf"
                initialValue={data.nf_number}
                rules={[
                  {
                    required: true,
                    message: <div className="normal-12">Campo obrigatório</div>,
                  },
                ]}
              >
                <FlattedInputNumber placeholder="Digite o número da nota fiscal" />
              </Form.Item>

              <Dropdown
                overlay={
                  <DatePicker
                    date={data.emitted_at}
                    maxDate={new Date()}
                    onChange={(date) => {
                      form.setFieldsValue({ emitted_at: formatDate(date) })
                    }}
                  />
                }
                trigger={["click"]}
                placement="bottomRight"
              >
                <Form.Item
                  initialValue={data.emitted_at}
                  name="emitted_at"
                  label="Data da emissão *"
                  rules={[
                    {
                      required: true,
                      message: (
                        <div className="normal-12">Campo obrigatório</div>
                      ),
                    },
                  ]}
                >
                  <FlattedInput readOnly placeholder="Inserir data" />
                </Form.Item>
              </Dropdown>
            </RoudedBox>
          </Form>

          <Row justify="end" className="buttons">
            <BlueButton onClick={() => form.submit()}>Continuar</BlueButton>
          </Row>
        </>
      )}
    </div>
  )
}

export default InvoiceUpload
