import api from "../../service/api"
import apiRoutes from "../../service/apiRoutes"

export const addContact = (data) => api.post(apiRoutes.contact, data)
export const editContact = (contractorId, data) =>
  api.put(`${apiRoutes.contact}/${contractorId}`, data)
export const getContact = (contractorId) =>
  api.get(`${apiRoutes.contact}/${contractorId}`)
export const getSectors = () => api.get(`${apiRoutes.contact}/sectors`)
export const fetchContact = (filter) =>
  api.post(`${apiRoutes.contact}/0`, { filter })
