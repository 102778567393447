import React from "react"

export const NavigationFlagIcon = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#323232"
        strokeWidth="1.5"
      >
        <path
          d="M9 14.205c-3.493.481-6 1.772-6 3.295 0 1.933 4.029 3.5 9 3.5s9-1.567 9-3.5c0-1.523-2.507-2.814-6-3.295M12 17V4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect x="12" y="3" width="9" height="7" rx="1" strokeLinejoin="round" />
      </svg>
    </span>
  )
}
