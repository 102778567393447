import React from "react"

export const SendMessageIcon = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#fff"
        strokeWidth="1.5"
        strokeLinejoin="round"
      >
        <path d="M11.883 13H6.39" strokeLinecap="round" />
        <path d="M6.24 4.627l14.598 7.297c.408.204.665.62.665 1.076s-.258.872-.665 1.076L6.24 21.373c-.423.212-.931.156-1.298-.143s-.525-.784-.404-1.241L6.39 13 4.539 6.011c-.121-.457.037-.943.404-1.241s.875-.354 1.298-.143z" />
      </svg>
    </span>
  )
}
