import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
//
import StateStatus from "../../../../utils/stateStatus"
import { useSelector, useDispatch } from "react-redux"
import {
  addTag,
  updateProposalTags,
  selectListProposalsState,
  listProposalTags,
  removeTag,
  allTags,
} from "../../listProposalsSlice"
//
import { LoadingOutlined } from "@ant-design/icons"
import { Menu, Dropdown, Row, Col, Checkbox, Badge } from "antd"
import Icons from "../../../../common/components/Icons"
import Avatar from "../../../../common/components/avatar"
import BaseModal from "../../../../common/components/Modal"
import TagStatus from "../../../../common/components/TagStatus"
import { Tooltip } from "../../../../common/components/Tooltip"

//
import {
  formatDate,
  formatStatusToStep,
  numberToReal,
  parseDate,
} from "../../../../utils/formaters"
//
import "./styles.scss"
import ProposalTag from "../../../../common/components/ProposalTag"
import { useHistory } from "react-router-dom"
// import InfoDateTime from "../../../../common/components/InfoDateTime"

const Card = ({
  proposal,
  onDuplicate,
  onEdit,
  onShare,
  onDelete,
  onCancel,
  // onOpenContract,
  onResendContract,
  onDownloadContract,
  onClickMessages,
  onClick,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  //
  const listProposalsState = useSelector(selectListProposalsState)
  const [showDropdownMenu, updateShowDropdownMenu] = useState(false)
  //
  const [deleteTag, updateDeleteTag] = useState()
  const [selectedTags, updateSelectedTags] = useState([])
  const [showTagInput, updateShowTagInput] = useState(false)

  const isNew =
    Math.abs(parseDate(proposal.created_at, "dd/MM/yyyy HH:mm") - new Date()) <=
    30000

  const scrollTags = listProposalsState.tags.length > 4

  const menu = (
    <Menu className="card-proposal-menu">
      <Menu.Item key="tag" disabled={true} className="disabled-tag">
        <span className="icon-menu">
          <Icons icon="tag" />
        </span>
        Adicionar marcador a proposta
      </Menu.Item>
      {/* TAGS */}
      <Menu.Item
        className={`menu-item-tags ${scrollTags ? "scroll" : ""}`}
        key="menu-item-tags"
      >
        {listProposalsState.tags.map((item) => {
          const include = selectedTags.some((tag) => tag.id === item.id)
          const blocked = selectedTags.length >= 3 && !include

          return (
            <div
              key={item.name}
              className={`menu-item-tag ${blocked ? "blocked" : ""}`}
              onClick={(event) => {
                event.preventDefault()
                event.stopPropagation()

                if (selectedTags.length < 3 && !include)
                  addNewTagToProposal(item)
                else if (include) removeTagFromProposal(item)
              }}
            >
              <Checkbox value={item.name} checked={include} disabled={blocked}>
                {item.name}
              </Checkbox>

              <Icons
                icon="delete"
                onClick={(event) => {
                  event.stopPropagation()

                  updateShowDropdownMenu(false)

                  // espera o dropdown fechar para abrir o modal
                  setTimeout(() => {
                    updateDeleteTag(item)
                  }, 200)
                }}
              />
            </div>
          )
        })}
      </Menu.Item>

      <Menu.Item key="add-tag" className="add-tag">
        {!showTagInput ? (
          <div
            className="junction"
            onClick={(event) => {
              if (!showTagInput) updateShowTagInput(true)

              event.preventDefault()
              event.stopPropagation()
            }}
          >
            <span className="icon-menu">
              <Icons icon="plus" />
            </span>
            <span className="text-new-tag">Novo marcador</span>
          </div>
        ) : (
          <>
            <input
              type="text"
              maxLength={10}
              className="input-add-tag"
              placeholder="Informar nome da tag"
              readOnly={
                listProposalsState.status.addTag === StateStatus.loading
              }
              onKeyDown={(event) => {
                if (
                  event.key === "Enter" &&
                  event.target.value &&
                  event.target.value.length > 0 &&
                  listProposalsState.status.addTag !== StateStatus.loading
                ) {
                  addNewTagToAccount(event.target.value)

                  event.preventDefault()
                  event.stopPropagation()
                  event.target.value = ""
                }
              }}
              onClick={(event) => {
                event.preventDefault()
                event.stopPropagation()
              }}
            />
            {listProposalsState.status.addTag === StateStatus.loading && (
              <LoadingOutlined />
            )}
          </>
        )}
      </Menu.Item>

      {/*  */}
      <Menu.Divider />
      <Menu.Item key="duplicate" onClick={onDuplicate}>
        <span className="icon-menu">
          <Icons icon="duplicate" />
        </span>
        Duplicar Proposta
      </Menu.Item>

      {!["draft", "canceled"].includes(proposal.status) &&
      proposal.role === proposal.created_by ? (
        <>
          <Menu.Divider />
          <Menu.Item key="share" onClick={onShare}>
            <span className="icon-menu">
              <Icons icon="share" />
            </span>
            Compartilhar Proposta
          </Menu.Item>
        </>
      ) : null}

      {/* {["waiting_sign_contract"].includes(proposal.status) ? (
        <>
          <Menu.Divider />
          <Menu.Item key="show" onClick={onOpenContract}>
            <span className="icon-menu">
              <Icons icon="show" />
            </span>
            Assinar contrato
          </Menu.Item>
        </>
      ) : null} */}

      {["waiting_sign", "signing", "expired", "canceled"].includes(
        proposal.contract?.status
      ) && proposal.role === proposal.created_by ? (
        <>
          <Menu.Divider />
          <Menu.Item
            key="send"
            onClick={onResendContract}
            disabled={["expired", "canceled"].includes(
              proposal.contract?.status
            )}
          >
            <span className="icon-menu">
              <Icons icon="send" />
            </span>
            Reenviar contrato
          </Menu.Item>
        </>
      ) : null}

      {proposal.contract?.status === "finalized" ? (
        <>
          <Menu.Divider />
          <Menu.Item key="download" onClick={onDownloadContract}>
            <span className="icon-menu">
              <Icons icon="download" />
            </span>
            Baixar contrato
          </Menu.Item>
        </>
      ) : null}

      {proposal.status === "waiting_nfs" && proposal.role === "CONTRACTED" ? (
        <>
          <Menu.Divider />
          <Menu.Item
            onClick={() => {
              history.push(`/dashboard/nfse/create/${proposal.id}`)
            }}
          >
            <span className="icon-menu">
              <Icons icon="upload-circle" />
            </span>
            Enviar nota fiscal
          </Menu.Item>
        </>
      ) : null}

      {proposal.count_nfs && proposal.count_nfs > 0 ? (
        <>
          <Menu.Divider />
          <Menu.Item
            onClick={() => {
              history.push(`/dashboard/nfse?proposal_id=${proposal.id}`)
            }}
          >
            <span className="icon-menu">
              <Icons icon="show" />
            </span>
            Ver notas fiscais
          </Menu.Item>
        </>
      ) : null}

      {["draft", "waiting_review", "waiting_accept"].includes(
        proposal.status
      ) && proposal.role === proposal.created_by ? (
        <>
          <Menu.Divider />
          <Menu.Item key="edit" onClick={onEdit}>
            <span className="icon-menu">
              <Icons icon="edit" />
            </span>
            Editar Proposta
          </Menu.Item>
        </>
      ) : null}

      {proposal.role === proposal.created_by && (
        <>
          {["draft"].includes(proposal.status) ? (
            <>
              <Menu.Divider />
              <Menu.Item key="delete" onClick={onDelete}>
                <span className="icon-menu">
                  <Icons icon="delete" />
                </span>
                Excluir Proposta
              </Menu.Item>
            </>
          ) : !["canceled", "finalized", "expired", "refused"].includes(
              proposal.status
            ) ? (
            <>
              <Menu.Divider />
              <Menu.Item key="cancel" onClick={onCancel}>
                <span className="icon-menu">
                  <Icons icon="cancel" />
                </span>
                Cancelar Proposta
              </Menu.Item>
            </>
          ) : null}
        </>
      )}
    </Menu>
  )

  const addNewTagToProposal = (tag) => {
    const list = [...selectedTags, tag]

    dispatch(
      updateProposalTags({
        id: proposal.id,
        tags: list.length <= 0 ? null : list.map((item) => item.id),
      })
    ).then(() => {
      dispatch(allTags())
      fecthProposalTags()
    })
  }

  const addNewTagToAccount = (name) => {
    dispatch(addTag({ name })).then(({ payload }) => {
      const blocked = selectedTags.length >= 3

      if (payload && !blocked) {
        addNewTagToProposal(payload.data)
      } else {
        dispatch(allTags())
      }
    })
  }

  const removeTagFromProposal = (tag) => {
    const list = selectedTags.filter((tag_) => tag_.id !== tag.id)

    dispatch(
      updateProposalTags({
        id: proposal.id,
        tags: list.length <= 0 ? null : list.map((item) => item.id),
      })
    ).then(() => fecthProposalTags())
  }

  const fecthProposalTags = () => {
    dispatch(listProposalTags(proposal.id)).then(({ payload }) => {
      const proposalTags = payload.data && payload.data.items
      if (proposalTags) {
        updateSelectedTags(proposalTags)
      }
    })
  }

  useEffect(() => {
    updateSelectedTags(proposal.tags)
  }, [])

  useEffect(() => {
    if (showDropdownMenu || deleteTag) {
      // to handle event just on current card
      if (listProposalsState.status.removeTag === StateStatus.succeeded) {
        updateDeleteTag(null)
        dispatch(allTags())
        fecthProposalTags()
      }
    }
  }, [listProposalsState.status.removeTag])

  useEffect(() => {
    if (showDropdownMenu) {
      // to handle event just on current card
      if (listProposalsState.status.addTag === StateStatus.succeeded) {
        dispatch(allTags())
      }
    }
  }, [listProposalsState.status.addTag])

  return (
    <>
      {isNew ? (
        <div className="tag-new-container">
          <div className="tag-new">Nova</div>
        </div>
      ) : null}
      <Row justify="space-between" className="card-proposal">
        <Col span={5} onClick={onClick}>
          <Row align="middle" justify="start">
            <Col className="proposal-avatar" onClick={onClick}>
              <Avatar
                src={
                  proposal.role === "CONTRACTOR"
                    ? proposal.contracted.avatar_url
                    : proposal.contractor.avatar_url
                }
              />
            </Col>
            <Col onClick={onClick}>
              <div className="box-proposal-title">
                <div className="proposal-title">{proposal.title}</div>
                <div className="proposal-counterparty not-overflow">
                  {proposal.role === "CONTRACTOR"
                    ? proposal.contracted.company_name
                    : proposal.contractor.company_name}
                </div>
                <div>
                  {/* <InfoDateTime datetimeStr={proposal.created_at} /> */}
                  {/* {proposal.formatted_created_at.includes("/")
                  ? formatDate(
                      parseDate(proposal.created_at, "dd/MM/yyyy HH:mm"),
                      "dd 'de' MMM. yyyy"
                    )
                  : proposal.formatted_created_at} */}
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={2} onClick={onClick} className="col-tags">
          {selectedTags.map((item, index) => {
            return <ProposalTag key={index} name={item.name} />
          })}
        </Col>
        <Col span={3} onClick={onClick}>
          <div className="not-overflow" style={{ paddingRight: "10px" }}>
            {proposal.role === proposal.created_by ? (
              <>
                <Icons icon="user" /> Eu
              </>
            ) : proposal.role === "CONTRACTOR" ? (
              proposal.contracted.name
            ) : (
              proposal.contractor.name
            )}
          </div>
        </Col>
        <Col span={3} onClick={onClick}>
          {proposal.formatted_created_at.includes("/")
            ? formatDate(
                parseDate(proposal.created_at, "dd/MM/yyyy HH:mm"),
                "dd 'de' MMM. yyyy"
              )
            : proposal.formatted_created_at}
        </Col>
        <Col span={5} onClick={onClick}>
          <div style={{ paddingRight: "15px" }}>
            <TagStatus status={proposal.status}>
              {proposal.status_name}
            </TagStatus>
          </div>
          <div className="normal-12">{formatStatusToStep(proposal.status)}</div>
        </Col>
        <Col span={2} className="proposal-chat-messages">
          {!["draft", "waiting_accept"].includes(proposal.status) && (
            <Badge
              count={proposal.count_unviewed_messages}
              offset={[0, 20]}
              title={`${5} mensagens não lidas`}
              onClick={onClickMessages}
            >
              <Icons
                onClick={onClickMessages}
                icon="messages"
                data-active={proposal.count_unviewed_messages > 0}
              />
            </Badge>
          )}
        </Col>
        <Col span={2} onClick={onClick}>
          <div className="normal-16-500 not-overflow" onClick={onClick}>
            <Tooltip title={numberToReal(proposal.total_value / 100)}>
              {numberToReal(proposal.total_value / 100, true)}
            </Tooltip>
          </div>
        </Col>
        <Col span={1}>
          <Dropdown
            overlay={menu}
            visible={showDropdownMenu}
            trigger={["click"]}
            placement="bottomRight"
            onVisibleChange={(visible) => {
              updateShowDropdownMenu(visible)
              if (!visible) updateShowTagInput(false)
            }}
          >
            <div className="more-button">
              <Icons icon="more" />
            </div>
          </Dropdown>
        </Col>
      </Row>

      {deleteTag && (
        <BaseModal
          isVisible={true}
          isLoading={
            listProposalsState.status.removeTag === StateStatus.loading
          }
          okButtonText={"Sim, excluir tag"}
          okButtonColor="red"
          cancelButtonText={"Não, manter tag"}
          onOk={() => dispatch(removeTag(deleteTag.id))}
          onCancel={() => updateDeleteTag(null)}
          //
          title="Excluir tag"
        >
          Ao excluir a tag, ela será removida de todas as propostas.
          <br />
          Deseja excluir a tag <b>{deleteTag.name}</b>?
        </BaseModal>
      )}

      {/* <BaseModal
        okButtonText="Fechar janela"
        onOk={() => dispatch(resetDeleteTagStatus())}
        onCancel={() => dispatch(resetDeleteTagStatus())}
        isVisible={
          listProposalsState.status.removeTag === StateStatus.succeeded ||
          listProposalsState.status.removeTag === StateStatus.failed
        }
        //
        title={
          listProposalsState.status.removeTag === StateStatus.succeeded
            ? "Tag excluída com sucesso"
            : "Erro ao excluir tag"
        }
      ></BaseModal> */}
    </>
  )
}

Card.propTypes = {
  proposal: PropTypes.object,
  onDuplicate: PropTypes.func,
  onEdit: PropTypes.func,
  onShare: PropTypes.func,
  onDelete: PropTypes.func,
  onCancel: PropTypes.func,
  // onOpenContract: PropTypes.func,
  onResendContract: PropTypes.func,
  onClickMessages: PropTypes.func,
  onDownloadContract: PropTypes.func,
  onClick: PropTypes.func,
}

export default Card
