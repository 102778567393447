import React, { useEffect, useState } from "react"
import "./styles.scss"
// import PropTypes from "prop-types"
import BaseModal from "../Modal"
//
import { statisticsSelector } from "../../../features/countersPage/countersPageSlice"
import RoudedBox from "../RoudedBox"
import { Col, Divider, Row } from "antd"
import Icons from "../Icons"
import { useSelector } from "react-redux"
import StateStatus from "../../../utils/stateStatus"
import { formatDate, numberToReal } from "../../../utils/formaters"
//
import triangleSVG from "../../assets/triangle.svg"

const InvoiceCardProgress = () => {
  const counters = useSelector(statisticsSelector)
  const [modalVisible, setModalVisible] = useState({})
  const [porcentInvoiceProgress, setPorcentInvoiceProgress] = useState(0)
  const invoicingProgress = counters.data.invoicingProgress

  useEffect(() => {
    if (
      counters.status.getInvoicingProgress === StateStatus.succeeded &&
      counters.data.invoicingProgress?.cnpj
    ) {
      let percent =
        (invoicingProgress?.invoicing_sum || 0) /
        invoicingProgress?.invoicing_max
      percent = percent >= 0.9 ? 90 : parseInt(percent * 100)

      setPorcentInvoiceProgress(percent)
    }
  }, [counters.status.exportSpreadsheet])
  return (
    <>
      <RoudedBox className="top-blank-box invoicing-progress">
        <Row justify="space-between">
          <Col className="title">
            <div className="top-box-title">
              <Icons icon="onboardingMoneyIcon" className="money-icon" />
              Acompanhar meu faturamento
            </div>
            <div>hoje, {formatDate(new Date())}</div>
          </Col>
          <Col>
            <div
              onClick={() =>
                setModalVisible({ modal: "show-info-invoicing-progress" })
              }
            >
              <Icons icon="circle-info" />
            </div>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col span={24}>
            <div className="invoicing-progress-bar">
              <div className="progress-bar">
                <div className="bar-scale">
                  <span>{/* R$ 0 */}</span>
                  <span>
                    {numberToReal(
                      (invoicingProgress?.invoicing_max ?? 0) / 100
                    )}
                  </span>
                </div>
                <div
                  className="progress-bar-point"
                  style={{
                    marginLeft: `calc(${porcentInvoiceProgress}% - 15px)`,
                  }}
                >
                  <div className="bar-point">
                    <img src={triangleSVG} height="20" />
                  </div>
                  <div className="bar-value">
                    {numberToReal(
                      (invoicingProgress?.invoicing_sum ?? 0) / 100
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </RoudedBox>
      <BaseModal
        isVisible={modalVisible?.modal === "show-info-invoicing-progress"}
        title={"Sobre a cota MEI anual"}
        okButtonText={"Fechar"}
        onClose={() => {
          setModalVisible({})
        }}
        onOk={() => {
          setModalVisible({})
        }}
      >
        <div>
          O valor apresentado aqui refere-se a notas que foram anexadas aqui no
          Propozall, não nos respondabilizamos por notas não anexadas que possam
          trazer divergência de valores do seu faturamento.
        </div>
        <Divider />
        <div>
          O atual limite de faturamento MEI é de{" "}
          {numberToReal((invoicingProgress?.invoicing_max || 0) / 100)} por ano
          — média de{" "}
          {numberToReal((invoicingProgress?.invoicing_max || 0) / 1200)} por
          mês. Excedido esse valor, é necessário solicitar o desenquadramento e
          passar a atuar como Microempresa.
        </div>
      </BaseModal>
    </>
  )
}
export default InvoiceCardProgress
