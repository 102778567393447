import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { get } from "./contractViewService"
//
import StateStatus from "../../utils/stateStatus"

export const getContract = createAsyncThunk("contract/view", async ({ id }) => {
  const response = await get(id)

  return response.data
})

const initialState = {
  status: {
    getContract: StateStatus.idle,
  },
  data: {
    contract: null,
  },
}

export const contractViewSlice = createSlice({
  name: "contractView",
  initialState,
  reducers: {},
  extraReducers: {
    [getContract.pending]: (state) => {
      state.status.getContract = StateStatus.loading
    },
    [getContract.fulfilled]: (state, action) => {
      state.data.contract = action.payload.data
      state.status.getContract = StateStatus.succeeded
    },
    [getContract.rejected]: (state) => {
      state.errMessage = "Não foi possível listar os contratos."
      state.status.getContract = StateStatus.failed
    },
  },
})

export const selectContractViewState = (state) => state.contractView
