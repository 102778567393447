import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"
//
import { useDispatch, useSelector } from "react-redux"
import StateStatus from "../../../../utils/stateStatus"
//
import { Form } from "antd"
import Input, { SimpleMaskedInput } from "../../../../common/components/Input"
//
import {
  resetStatus,
  selectRegister,
  sendAccount,
  updAccount,
  updData,
} from "../../RegisterSlice"
//
import { cpf, cnpj } from "cpf-cnpj-validator"
import { validateRecaptcha } from "../../../../service/recaptcha"
//
import "./styles.scss"
import { allDDDs, invalidPhoneNumbers } from "../../../../utils/lists"

const PersonalDataForm = ({ rel, isCreated }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  //
  const register = useSelector(selectRegister)
  //
  const [isCompany, setCompany] = useState(false)
  const [passValidation, updatePassValidation] = useState({
    value: register.data.password,
  })

  const onFinish = async (values) => {
    const cpfCnpj = values.cpf_cnpj.replace(/[^0-9]+/g, "")
    const companyName = values.company_name?.trim() || ""

    if (cpfCnpj.length > 11 && (!isCompany || companyName.length === 0)) {
      setCompany(true)
      return rel.validateFields()
    }

    dispatch(updData(values))

    const data = values
    data.phone = `+55 ${data.phone}`
    delete data.confirm_password
    delete data.confirm_email

    if (!isCreated)
      validateRecaptcha().then((token) => {
        values.recaptcha_token = token
        const entries = localStorage.getItem(
          "query_params_new_account_propozall"
        )
        values.query_params =
          entries && entries.length > 0 ? JSON.parse(entries) : null
        dispatch(sendAccount({ data: values }))
      })
    else dispatch(updAccount({ data: values }))
  }

  const passwordValidation = (value) => {
    const result = {
      value,
      hasEigthLength: value.length >= 8,
      hasOneNumber: value.search(/\d/g) !== -1,
      hasOneCapitalLetter: value.search(/[A-Z]/g) !== -1,
      hasOneLowerLetter: value.search(/[a-z]/g) !== -1,
    }

    updatePassValidation(result)

    return result
  }

  useEffect(() => {
    if (register.status.saveAccount === StateStatus.succeeded) {
      dispatch(resetStatus())
      history.push("/register/2")
    }
  }, [register.status.saveAccount])

  useEffect(() => {
    document.body.style.overflow = "auto"
  }, [])

  useEffect(() => {
    if (
      register.data.cpf_cnpj &&
      register.data.cpf_cnpj.replace(/[^0-9]+/g, "").length > 11
    ) {
      setCompany(true)
    }
  }, [register.data.cpf_cnpj])

  return (
    <>
      {/* CONTACT */}
      <div className="step-about">
        <h1 className="form-title">Cadastro</h1>
        <h2 className="form-subtitle">
          Preencha os campos a seguir para se cadastrar.
          <br />
          Seu e-mail e sua senha serão suas informações de login na plataforma.
        </h2>
        <span className="form-observation">
          Itens com (*) são obrigatórios.{" "}
        </span>
      </div>

      <Form form={rel} onFinish={onFinish} layout="vertical">
        <Form.Item
          name="name"
          label="Nome completo *"
          initialValue={register.data.name}
          rules={[
            () => ({
              validator(_, value) {
                if (!value) {
                  return Promise.reject(new Error("Informe um nome válido"))
                }

                const names = value.split(" ")
                if (
                  names.length < 2 ||
                  names[0].replace(/\s/g, "") === "" ||
                  names[1].replace(/\s/g, "") === ""
                ) {
                  return Promise.reject(
                    new Error("Informe o nome e o sobrenome")
                  )
                }

                return Promise.resolve()
              },
            }),
            {
              max: 150,
              message: "Máximo de 150 caracteres",
            },
          ]}
        >
          <Input placeholder="Digite seu nome completo" />
        </Form.Item>

        <div className="junction-inputs">
          <Form.Item
            name="email"
            label="E-mail *"
            initialValue={register.data.email}
            rules={[
              {
                required: true,
                message: (
                  <div className="normal-12">Informe um e-mail válido!</div>
                ),
              },
              {
                type: "email",
                message: (
                  <div className="normal-12">Informe um e-mail válido!</div>
                ),
              },
              () => ({
                validator(_, value) {
                  const confirmEmail = rel.getFieldValue("confirm_email")
                  if (
                    confirmEmail != null &&
                    confirmEmail !== "" &&
                    confirmEmail !== value
                  ) {
                    return Promise.reject(new Error("Os e-mails não coincidem"))
                  }
                  return Promise.resolve()
                },
              }),
            ]}
          >
            <Input
              type="email"
              placeholder="Digite seu e-mail"
              blockPaste
              isDisabled={isCreated}
            />
          </Form.Item>

          <Form.Item
            name="confirm_email"
            label="Confirmar e-mail *"
            initialValue={register.data.confirm_email ?? register.data.email}
            rules={[
              () => ({
                validator(_, value) {
                  const email = rel.getFieldValue("email")
                  if (!email || email !== value) {
                    return Promise.reject(new Error("Os e-mails não coincidem"))
                  }
                  return Promise.resolve()
                },
              }),
            ]}
          >
            <Input
              blockPaste
              isDisabled={isCreated}
              placeholder="Digite seu e-mail"
            />
          </Form.Item>
        </div>
        <div className="junction-inputs">
          <Form.Item
            name="phone"
            label="Telefone ou Celular *"
            initialValue={register.data.phone}
            rules={[
              () => ({
                validator(_, value) {
                  if (
                    value !== undefined &&
                    value !== null &&
                    value.length > 0
                  ) {
                    const val = value.replace(/[^0-9]+/g, "")
                    // const regex = /\s*(\d{2}|\d{0})[-. ]?(\d{5}|\d{4})[-. ]?(\d{4})[-. ]?\s*/
                    if (!allDDDs.includes(val.slice(0, 2))) {
                      return Promise.reject(new Error("DDD válido"))
                    }

                    if (invalidPhoneNumbers.includes(val)) {
                      return Promise.reject(
                        new Error("Este não é um número válido")
                      )
                    }

                    if (val.length < 10) {
                      return Promise.reject(
                        new Error("Informe um número válido")
                      )
                    }

                    return Promise.resolve()
                  } else {
                    return Promise.reject(new Error("Informe um número"))
                  }
                },
              }),
            ]}
          >
            <SimpleMaskedInput
              maskOptions={{
                dispatch: function (appended, dynamicMasked) {
                  const value = dynamicMasked.unmaskedValue
                  const isCellPhone = value[2] === "9"
                  return dynamicMasked.compiledMasks[isCellPhone ? 1 : 0]
                },
              }}
              mask={[
                {
                  mask: "00 0000-0000",
                  lazy: true,
                },
                {
                  mask: "00 00000-0000",
                  lazy: true,
                },
              ]}
              placeholder="Digite seu número"
            />
          </Form.Item>

          <Form.Item
            name="cpf_cnpj"
            label="CPF ou CNPJ*"
            initialValue={register.data.cpf_cnpj}
            rules={[
              () => ({
                validator(_, value) {
                  const val = value.replace(/[^0-9]+/g, "")

                  if (val.length > 11) {
                    setCompany(true)
                    if (cnpj.isValid(val)) return Promise.resolve()
                    return Promise.reject(new Error("CNPJ inválido"))
                  } else {
                    setCompany(false)
                    if (cpf.isValid(val)) return Promise.resolve()
                    return Promise.reject(new Error("CPF ou CNPJ inválido"))
                  }
                },
              }),
            ]}
          >
            <SimpleMaskedInput
              maskOptions={{
                dispatch: function (appended, dynamicMasked) {
                  const value = dynamicMasked.unmaskedValue
                  const isCPF = value.length <= 11 || cpf.isValid(value)
                  return dynamicMasked.compiledMasks[isCPF ? 0 : 1]
                },
              }}
              mask={[
                {
                  mask: "000.000.000-000?",
                  lazy: true,
                },
                {
                  mask: "00.000.000/0000-00",
                  lazy: true,
                },
              ]}
              placeholder="Digite seu CPF ou CNPJ"
            />
          </Form.Item>
        </div>

        <div>
          {isCompany ? (
            <Form.Item
              name="company_name"
              label="Razão Social *"
              initialValue={register.data.company_name}
              rules={[
                () => ({
                  validator(_, value) {
                    if (!value || value.trim().length === 0) {
                      return Promise.reject(new Error("Informe um nome válido"))
                    }
                    return Promise.resolve()
                  },
                }),
                {
                  max: 150,
                  message: "Máximo de 150 caracteres",
                },
                {
                  min: 1,
                  message: "Informe um nome válido",
                },
              ]}
            >
              <Input placeholder="Digite a razão social" />
            </Form.Item>
          ) : null}
        </div>

        {!isCreated && (
          <>
            <div className="junction-inputs">
              <Form.Item
                name="password"
                label="Senha *"
                initialValue={register.data.password}
                rules={[
                  () => ({
                    validator(_, value) {
                      const falses = Object.entries(passValidation).filter(
                        (item) => item[1] === false
                      )
                      if (falses.length > 0 || !value) {
                        return Promise.reject(
                          new Error("Informe uma senha válida")
                        )
                      }

                      return Promise.resolve()
                    },
                  }),
                ]}
              >
                <Input
                  isPassword
                  isDisabled={isCreated}
                  onChange={(event) => passwordValidation(event.target.value)}
                  placeholder={isCreated ? "" : "Digite sua senha"}
                />
              </Form.Item>

              <Form.Item
                name="confirm_password"
                label="Confirmar senha *"
                initialValue={register.data.confirm_password}
                rules={[
                  () => ({
                    validator(_, value) {
                      if (value !== passValidation.value) {
                        return Promise.reject(
                          new Error("As senhas não coincidem")
                        )
                      }

                      return Promise.resolve()
                    },
                  }),
                ]}
              >
                <Input
                  isPassword
                  blockPaste
                  isDisabled={isCreated}
                  placeholder={isCreated ? "" : "Digite sua senha"}
                />
              </Form.Item>
            </div>

            <div className="password-validation-container">
              {/* <p className="pvc-title">A senha deve conter</p> */}

              <p className="validations">
                <span
                  className={passValidation.hasEigthLength ? "checked" : ""}
                >
                  8 caracteres
                </span>
                <span
                  className={
                    passValidation.hasOneCapitalLetter ? "checked" : ""
                  }
                >
                  1 letra maiúscula
                </span>
                <span
                  className={passValidation.hasOneLowerLetter ? "checked" : ""}
                >
                  1 letra minúscula
                </span>
                <span className={passValidation.hasOneNumber ? "checked" : ""}>
                  1 número
                </span>
              </p>
            </div>
          </>
        )}
      </Form>
    </>
  )
}

PersonalDataForm.propTypes = {
  isCreated: PropTypes.bool.isRequired,
  rel: PropTypes.object.isRequired,
}

export default PersonalDataForm
