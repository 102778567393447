import { Col, message, Row } from "antd"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { BlueButton, WhiteButton } from "../../../../common/components/Button"
import Icons from "../../../../common/components/Icons"
import { selectProposalState } from "../../proposalSlice"
import PropTypes from "prop-types"
import "./styles.scss"
import { ProposalRolesEnum } from "../../../../utils/constants"
import { isMobileDevice } from "../../../../utils/mobile"

const BREAK_LINE_WHATSAPP = "%0a"

const ShareProposalOptions = ({ rootPath }) => {
  const history = useHistory()
  const [linkCopied, setLinkCopied] = useState()
  const proposalState = useSelector(selectProposalState)
  const shareLink = proposalState.data.proposal.share_link
  const phone = null
  let counterparty = null
  let owner = null

  if (
    proposalState.data.proposal?.created_by === ProposalRolesEnum.CONTRACTED
  ) {
    owner = proposalState.data.proposal?.contracted
    counterparty = proposalState.data.proposal?.contractor
  } else {
    counterparty = proposalState.data.proposal?.contracted
    owner = proposalState.data.proposal?.contractor
  }

  useEffect(() => {
    if (localStorage.getItem("link-copied") === shareLink) {
      setLinkCopied(true)
    }
  }, [])

  const copyLinkToClipboard = async () => {
    if (shareLink) {
      await navigator.clipboard.writeText(shareLink)
      localStorage.setItem("link-copied", shareLink)
      message.success("Link copiado!")
      setLinkCopied(true)
    }
  }

  const buildMessageShareProposal = ({ toName, fromName, link, fromPF }) => {
    toName = toName?.trim() ?? "-"
    fromName = fromName?.trim() ?? "-"

    const term1 = fromPF
      ? `Eu *${fromName}* me dedico`
      : `Nós da *${fromName}* nos dedicamos`

    const text = [
      `Olá *${toName}*, tudo bem?`,
      BREAK_LINE_WHATSAPP,
      "Compartilhamos aqui a proposta solicitada, que abrange todos os detalhes, descrição completa dos serviços que serão prestados, prazos e valores. Para visualizar e aceitar a proposta, basta clicar no link abaixo:",
      BREAK_LINE_WHATSAPP,
      `${link}`,
      BREAK_LINE_WHATSAPP,
      `${term1} integralmente à entrega de soluções eficazes e de excelência que atendam às suas necessidades específicas. Analise a proposta com atenção e entre em contato se tiver dúvidas ou quiser discutir detalhes extras.`,
      BREAK_LINE_WHATSAPP,
      "Estamos aguardando seu retorno e estamos disponíveis para esclarecer qualquer questão que possa surgir.",
      BREAK_LINE_WHATSAPP,
      "Atenciosamente,",
      `*${fromName}*`,
    ].join(BREAK_LINE_WHATSAPP)

    return text
  }

  const shareOnWhatsapp = () => {
    const encoded = encodeURIComponent(shareLink)

    const text = buildMessageShareProposal({
      toName: counterparty.company_name,
      fromName: owner.company_name,
      link: encoded,
      fromPF: owner.cpf_cnpj.length <= 11,
    })

    const origin = isMobileDevice()
      ? "https://api.whatsapp.com/send"
      : "https://web.whatsapp.com/send"

    let link

    if (phone) {
      link = `${origin}?phone=${phone}&text=${text}`
    } else {
      link = `${origin}?text=${text}`
    }

    window.open(link, "__blank")
  }

  return (
    <>
      <Row className="share-proposal-step" justify="center" align="middle">
        <Col span={24}>
          <Row justify="center" className="share-title">
            {counterparty?.email ? (
              <span>Gostaria de compartilhar o orçamento?</span>
            ) : (
              <span>Como quer enviar o orçamento?</span>
            )}
          </Row>
          <Row justify="center" className="share-subtitle">
            {counterparty?.email ? (
              <span>
                Um e-mail já foi enviado para
                <span className="normal-12-500"> {counterparty.email}</span>
              </span>
            ) : null}
          </Row>
          <Row className="share-options" justify="center" gutter={[16, 16]}>
            <Col span={24}>
              <WhiteButton
                icon={<Icons icon="whatsapp" className="share-btn-icon" />}
                onClick={shareOnWhatsapp}
              >
                Enviar pelo Whatsapp
              </WhiteButton>
            </Col>
            <Col span={24}>
              <WhiteButton
                icon={<Icons icon="mail" className="share-btn-icon" />}
                onClick={() => {
                  history.push("/dashboard/proposal/share/send-email")
                }}
                disabled={!!counterparty?.email}
              >
                {counterparty?.email
                  ? "Já enviado por e-mail"
                  : "Enviar pelo e-mail"}
              </WhiteButton>
            </Col>
            <Col span={24}>
              <WhiteButton
                icon={<Icons icon="link" className="share-btn-icon" />}
                onClick={copyLinkToClipboard}
                disabled={!shareLink}
              >
                {linkCopied ? "Link copiado" : "Copiar link"}
              </WhiteButton>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row justify="center" align="center">
            <Col>
              <BlueButton
                htmlType="button"
                onClick={() => {
                  history.push(`${rootPath}/success`)
                }}
              >
                Finalizar
              </BlueButton>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

ShareProposalOptions.propTypes = {
  rootPath: PropTypes.string,
}

export default ShareProposalOptions
