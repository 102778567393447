import React from "react"

export const UploadLogoType = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M11.760 2.276 C 11.613 2.334,11.147 2.782,9.240 4.696 C 7.953 5.987,6.860 7.110,6.810 7.192 C 6.682 7.403,6.692 7.620,6.839 7.853 C 7.069 8.214,7.466 8.369,7.772 8.216 C 7.842 8.180,8.651 7.403,9.569 6.487 L 11.238 4.823 11.249 10.781 C 11.260 16.649,11.261 16.742,11.340 16.900 C 11.568 17.356,12.423 17.356,12.658 16.900 C 12.740 16.741,12.740 16.707,12.751 10.781 L 12.762 4.823 14.431 6.487 C 15.349 7.403,16.158 8.180,16.228 8.216 C 16.534 8.369,16.931 8.214,17.161 7.853 C 17.308 7.620,17.318 7.403,17.190 7.192 C 17.140 7.110,16.047 5.987,14.760 4.696 C 12.092 2.019,12.195 2.102,11.760 2.276 M2.598 15.850 C 2.474 15.913,2.396 15.988,2.340 16.100 C 2.264 16.251,2.260 16.338,2.262 17.720 C 2.263 19.368,2.278 19.483,2.559 20.060 C 2.940 20.840,3.694 21.452,4.540 21.668 C 4.804 21.736,5.232 21.740,12.000 21.740 C 18.768 21.740,19.196 21.736,19.460 21.668 C 20.306 21.452,21.060 20.840,21.441 20.060 C 21.722 19.483,21.737 19.368,21.738 17.720 C 21.740 16.323,21.736 16.253,21.658 16.100 C 21.548 15.886,21.303 15.760,21.000 15.760 C 20.694 15.760,20.446 15.887,20.340 16.100 C 20.266 16.249,20.259 16.363,20.240 17.701 L 20.220 19.142 20.090 19.406 C 19.936 19.719,19.685 19.965,19.367 20.114 L 19.140 20.220 12.000 20.220 L 4.860 20.220 4.633 20.114 C 4.315 19.965,4.064 19.719,3.910 19.406 L 3.780 19.142 3.760 17.701 C 3.741 16.347,3.735 16.250,3.658 16.100 C 3.548 15.886,3.303 15.760,3.000 15.760 C 2.848 15.760,2.720 15.789,2.598 15.850 "
          stroke="none"
          fillRule="evenodd"
          fill="black"
        ></path>
      </svg>
    </span>
  )
}
