import React from "react"

export const BellIcon = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        width="18px"
        height="18px"
        viewBox="0 0 11 13"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <defs>
          <rect id="bell-path-1" x="0" y="0" width="17" height="17"></rect>
        </defs>
        <g
          id="bell-Design-Tokens"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="bell-Base-Components-2"
            transform="translate(-240.000000, -1465.000000)"
          >
            <g
              id="bell-notification-/-notification_outline"
              transform="translate(237.000000, 1463.000000)"
            >
              <mask id="bell-mask-2" fill="white">
                <use xlinkHref="#bell-path-1"></use>
              </mask>
              <g id="bell-notification-/-notification_outline-(Background/Mask)"></g>
              <path
                d="M12.21875,10.8020833 L12.21875,7.39322917 C12.21875,5.49046894 11.5183853,3.89760425 9.73958333,3.47614587 L9.73958333,2.125 L7.26041667,2.125 L7.26041667,3.47614587 C5.48781257,3.89760425 4.78125,5.48427088 4.78125,7.39322917 L4.78125,10.8020833 L3.54166667,11.421875 L3.54166667,12.6614583 L13.4583333,12.6614583 L13.4583333,11.421875 L12.21875,10.8020833 L12.21875,10.8020833 Z M8.5,14.5208333 C9.18177107,14.5208333 9.73958333,13.9630211 9.73958333,13.28125 L7.26041667,13.28125 C7.26041667,13.9630211 7.81822923,14.5208333 8.5,14.5208333 C8.95451405,14.5208333 8.95451405,14.5208333 8.5,14.5208333 Z M6.02083333,11.421875 L10.9791667,11.421875 L10.9791667,7.39322917 C10.9791667,5.85614582 10.359375,4.44921875 8.5,4.44921875 C6.640625,4.44921875 6.02083333,5.85614582 6.02083333,7.39322917 L6.02083333,11.421875 L6.02083333,11.421875 Z"
                fill="#000000"
                mask="url(#bell-mask-2)"
              ></path>
            </g>
          </g>
        </g>
      </svg>
    </span>
  )
}
