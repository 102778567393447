import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"
import "./styles.scss"
import { Col, Row } from "antd"
import Image1 from "../../../../common/assets/dashboardOnboarding/notepad.svg"
import Image2 from "../../../../common/assets/dashboardOnboarding/handshake.svg"
import Image3 from "../../../../common/assets/dashboardOnboarding/tax-illustration.svg"
import { BlueButton } from "../../../../common/components/Button"
//
import { selectRegister } from "../../RegisterSlice"
import StateStatus from "../../../../utils/stateStatus"
import { useSelector } from "react-redux"
import {
  isMobileDevice,
  redirectToMobileDashboard,
} from "../../../../utils/mobile"

const FinishedRegister = () => {
  const history = useHistory()

  const register = useSelector(selectRegister)

  const planName = (
    <span className="plan-name-style">{register.planData.name}</span>
  )
  const planNameText = (
    <span className="plan-name-style">plano {register.planData.name}</span>
  )

  const setCongratsText = () => {
    const subscription = register.planIdentifier
    if (subscription === "starter") {
      return <span>ganhou</span>
    } else {
      return <span>assinou</span>
    }
  }

  const setExplainText = () => {
    const subscription = register.planIdentifier
    if (subscription === "starter") {
      return (
        <div>
          Saiba tudo que você pode fazer
          <br /> com seu {planNameText} para
          <br /> facilitar a organização da sua vida:
        </div>
      )
    } else if (subscription === "basic") {
      return (
        <div>
          Saiba tudo que você pode fazer
          <br /> com seu {planNameText} para
          <br /> facilitar a organização da sua vida:
        </div>
      )
    } else if (subscription === "pro") {
      return (
        <div>
          Saiba tudo que você pode fazer
          <br /> com seu {planNameText} para
          <br /> facilitar a organização da sua vida:
        </div>
      )
    } else if (subscription === "enterprise") {
      return (
        <div>
          Saiba tudo que você pode fazer
          <br /> com seu {planNameText} para
          <br /> facilitar a organização da sua vida:
        </div>
      )
    }
  }

  useEffect(() => {
    if (register.status.saveAccount === StateStatus.succeeded) {
      setExplainText()
      setCongratsText()
    }
  }, [register.status.saveAccount])

  return (
    <div className="welcome-screen">
      <div className="background-image" />
      <div className="content">
        <div className="normal-36-700">Parabéns,</div>
        <div className="normal-28">
          você {setCongratsText()} um
          <br /> plano {planName}
        </div>
        <div className="normal-20-500">{setExplainText()}</div>
      </div>

      <Row className="box-container">
        <Col span={8} className="box-1">
          <img src={Image1} width="65" height="65" alt="NotePad img" />
          <p className="normal-16-600">
            Crie e envie
            <br /> orçamentos
          </p>
        </Col>
        <Col span={8} className="box-2">
          <div className="rounded-background">
            <img src={Image2} width="68" height="65" alt="Handshake img" />
          </div>
          <p className="normal-16-600">
            Assine
            <br /> contratos
          </p>
        </Col>
        <Col span={8} className="box-3">
          <img src={Image3} width="77" height="74" alt="Doc img" />
          <p className="normal-16-600">
            {" "}
            Emita e envie
            <br /> notas fiscais
          </p>
        </Col>
      </Row>
      <Row className="welcome-button">
        <BlueButton
          onClick={() => {
            if (isMobileDevice()) {
              redirectToMobileDashboard()
            } else {
              history.replace("/dashboard/")
            }
          }}
          className="welcome-button-style"
        >
          Começar a usar
        </BlueButton>
      </Row>
    </div>
  )
}
export default FinishedRegister
