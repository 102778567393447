const routes = {
  login: "/account/auth",
  loginAuth: "/account/auth/authenticator",
  register: "/account/register",
  graphStatistics: "/proposals/graph-stats",
  statistics: "/proposals/stats",
  dashboard: "/dashboard",
  graphParams: "/proposals/graph-params",
  refreshToken: "/account/refresh",
  profile: "/account",
  password: "/account/password",
  company: "/company",
  address: "/address",
  forgetPassword: "/account/forget-password",
  changePassword: "/account/change-password",
  editEmail: "/account/email",
  confirmEditEmail: "/account/confirm-new-email-code",
  notificationsSettings: "/notifications/settings",
  notifications: "/notifications",
  notification: "/notification",
  proposals: "/proposals",
  proposal: "/proposal",
  proposalCounterparty: "/counterparty/proposal",
  contact: "/contact",
  bankAccount: "/bank-account",
  banks: "/banks",
  file: "/file",
  findAddress: "/find-address",
  proposal_schema: "stored-data",
  confirmEmail: "/account/confirm-email",
  // contract view
  contractView: "/contract",
  // contract history
  contractHistory: "/contracts",
  downloadContract: "/contract/default",
  // payment
  invoices: "/payment/invoices",
  plan: "/payment/plan",
  plans: "/payment/plans",
  cancelPlan: "/payment/cancel",
  create_credit_card: "/credit_card",
  check_coupon: "/validate-coupon",
  // reports
  reports: "/reports",
  vote_satisfaction: "/vote-satisfaction",
  // nfs
  nfse: "/nfse",
  // list repots
  listInvoices: "/reports",
  // register
  create_register: "/account/register",
  create_address: "/address",
  create_payment: "/payment",
  subscription: "/payment",
  qrCodePixCreator: "/pix-creator",
}

const authorized = [
  { url: routes.login, methods: ["post"] },
  { url: routes.register, methods: ["post"] },
  { url: routes.loginAuth, methods: ["post"] },
  { url: routes.forgetPassword, methods: ["post", "put"] },
  { url: routes.changePassword, methods: ["post"] },
  { url: routes.confirmEmail, methods: ["put"] },
  { url: routes.plans, methods: ["get"] },
  { url: routes.qrCodePixCreator, methods: ["post"] },
]

const baseUrl = process.env.REACT_APP_BASEURL

export { baseUrl, authorized }
export default routes
