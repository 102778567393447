import React from "react"
import { Switch, Row } from "antd"
import PropTypes from "prop-types"
import "./styles.scss"

const StyledSwitch = (props) => {
  return (
    <Row
      align="middle"
      xs={24}
      sm={24}
      md={12}
      className={`styled-switch placement-label-${props.placement ?? "right"}`}
    >
      <Switch {...props} />
      {props?.label && <span className="switch-label">{props?.label}</span>}
    </Row>
  )
}

StyledSwitch.propTypes = {
  label: PropTypes.string,
  placement: PropTypes.string,
}

export default StyledSwitch
