import api from "../../service/api"
import apiRoutes from "../../service/apiRoutes"

export const nfses = (page, data) =>
  api.get(`${apiRoutes.nfse}/${page}`, { params: data })

export const deleteNfe = (id) => api.delete(`${apiRoutes.nfse}/${id}`)

export const proposals = (data) =>
  api.get(`${apiRoutes.nfse}${apiRoutes.proposals}`, { params: data })

export const exportNfse = (data) => api.post(`${apiRoutes.nfse}/zip`, data)

export const getExportNfseList = (data) =>
  api.get(`${apiRoutes.nfse}/zip`, { params: data })
