import { useEffect, useState } from "react"
import axios from "axios"

const useGoogleLogin = ({ clientId, onSuccess, onFailure, scope }) => {
  const [loaded, setLoaded] = useState(false)
  const [retry, setRetry] = useState(0)
  const [client, setClient] = useState()
  const [extra, setExtra] = useState()

  useEffect(() => {
    initializeGoogle()
    initClient()
  }, [])

  const initializeGoogle = () => {
    window.google?.accounts.id.initialize({
      client_id: clientId,
      callback: (_) => {
        console.log("GOOGLE AUTH INITIALIZED")
      },
    })
  }

  const initClient = () => {
    try {
      const client = window.google.accounts.oauth2.initTokenClient({
        client_id: clientId,
        callback: callbackInitClient,
        scope,
      })

      setClient(client)
      setLoaded(true)
    } catch (err) {
      if (retry <= 1) {
        console.log("GOOGLE AUTH RETRY")
        setRetry((x) => x + 1)
        setTimeout(initClient, 1000 * 2) // 2s
      }

      setLoaded(false)
      setClient(null)
      onFailure(err)
    }
  }

  const callbackInitClient = async (resp) => {
    try {
      if (!resp?.access_token) {
        throw Error("Request access token error")
      }

      const respProfile = await axios.get(
        "https://www.googleapis.com/oauth2/v2/userinfo",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${resp?.access_token}`,
            Accept: "application/json",
          },
        }
      )

      onSuccess({
        accessToken: resp?.access_token,
        profileObj: respProfile.data,
        extra,
      })
    } catch (err) {
      onFailure(err, extra)
    }
  }

  const signIn = (extra) => {
    setExtra(extra)
    if (loaded) {
      client.requestAccessToken()
    }
  }

  return { loaded, signIn }
}

export { useGoogleLogin }
