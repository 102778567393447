import React from "react"
import { Modal, Row, Col } from "antd"
import { BlueButton, OutlinedButton } from "../Button"
import "./styles.scss"
import PropTypes from "prop-types"
import Icons from "../Icons"

const BaseModal = ({
  type,
  title,
  subtitle,
  okButtonText,
  okButtonColor,
  cancelButtonText,
  children,
  onCancel,
  onClose,
  onOk,
  isVisible,
  hasButtons,
  width,
  isLoading,
  isClosable,
  className,
  featureImgSrc,
}) => {
  const getIcon = (type) => {
    switch (type) {
      case "alert":
        return <Icons icon="circle-alert" />
      case "success":
        return <Icons icon="circle-check" />
      case "error":
        return <Icons icon="circle-close" />
    }
  }

  return (
    <Modal
      className={`base-modal base-modal-${type} ${className}`}
      width={width}
      visible={isVisible}
      onCancel={onClose ?? onCancel}
      closable={isClosable}
      // closeIcon={<Icons icon="close" />} nao funcionou
      title={
        <>
          {featureImgSrc ? (
            <div className="feature-img">
              <img style={{ backgroundImage: `url("${featureImgSrc}")` }} />
            </div>
          ) : type !== "general" ? (
            <div className={"title-icon"}>{getIcon(type)}</div>
          ) : null}

          <p className="title">{title}</p>
        </>
      }
      footer={
        hasButtons ? (
          <Row
            gutter={[24, 24]}
            justify="space-between"
            className="actions-base-modal"
          >
            {onCancel && (
              <Col
                align="center"
                className={"left-btn"}
                xs={24}
                sm={24}
                md={onOk ? 12 : 24}
                lg={onOk ? 12 : 24}
              >
                <OutlinedButton disabled={isLoading} onClick={onCancel}>
                  {cancelButtonText ?? "Cancelar"}
                </OutlinedButton>
              </Col>
            )}
            {onOk ? (
              <Col
                align="center"
                className={`right-btn ${okButtonColor ?? ""}`}
                xs={24}
                sm={24}
                md={onCancel ? 12 : 24}
                lg={onCancel ? 12 : 24}
              >
                <BlueButton isLoading={isLoading} onClick={onOk}>
                  {okButtonText ?? "OK"}
                </BlueButton>
              </Col>
            ) : null}
          </Row>
        ) : null
      }
    >
      <p className="subtitle-modal">{subtitle}</p>
      <div className="content-modal">{children}</div>
    </Modal>
  )
}

BaseModal.defaultProps = {
  hasButtons: true,
  with: 520,
  closable: true,
  className: "",
  type: "general",
}

BaseModal.propTypes = {
  type: PropTypes.oneOf(["alert", "error", "success", "general"]).isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  okButtonText: PropTypes.string,
  okButtonColor: PropTypes.oneOf(["blue", "red"]),
  cancelButtonText: PropTypes.string,
  children: PropTypes.any,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  onOk: PropTypes.func,
  isVisible: PropTypes.bool,
  hasButtons: PropTypes.bool,
  width: PropTypes.number,
  isLoading: PropTypes.bool,
  isClosable: PropTypes.bool,
  className: PropTypes.string,
  featureImgSrc: PropTypes.node,
}

export default BaseModal
