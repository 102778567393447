import React from "react"

export const InvoiceIcon = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        width="33"
        height="32"
        viewBox="0 0 33 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M23 24L22.4821 24.736C22.7568 24.9294 23.1164 24.9536 23.4146 24.7988C23.7128 24.644 23.9 24.336 23.9 24H23ZM19.4474 21.5L19.9653 20.764C19.6546 20.5453 19.2401 20.5453 18.9294 20.764L19.4474 21.5ZM15.8947 24L15.3768 24.736C15.6875 24.9547 16.102 24.9547 16.4127 24.736L15.8947 24ZM12.3421 21.5L12.86 20.764C12.5494 20.5453 12.1348 20.5453 11.8242 20.764L12.3421 21.5ZM8.78947 24H7.88947C7.88947 24.336 8.07663 24.644 8.37484 24.7988C8.67304 24.9536 9.03264 24.9294 9.30742 24.736L8.78947 24ZM5 14H4.1C4.1 14.4971 4.50294 14.9 5 14.9V14ZM23.5179 23.264L19.9653 20.764L18.9294 22.236L22.4821 24.736L23.5179 23.264ZM18.9294 20.764L15.3768 23.264L16.4127 24.736L19.9653 22.236L18.9294 20.764ZM16.4127 23.264L12.86 20.764L11.8242 22.236L15.3768 24.736L16.4127 23.264ZM11.8242 20.764L8.27153 23.264L9.30742 24.736L12.86 22.236L11.8242 20.764ZM8.78947 4.9H19.4V3.1H8.78947V4.9ZM22.1 7.6V24H23.9V7.6H22.1ZM9.68947 24V14H7.88947V24H9.68947ZM9.68947 14V4H7.88947V14H9.68947ZM8.78947 13.1H5V14.9H8.78947V13.1ZM5.9 14V7.6H4.1V14H5.9ZM8.6 4.9H8.78947V3.1H8.6V4.9ZM5.9 7.6C5.9 6.10883 7.10883 4.9 8.6 4.9V3.1C6.11472 3.1 4.1 5.11472 4.1 7.6H5.9ZM19.4 4.9C20.8912 4.9 22.1 6.10883 22.1 7.6H23.9C23.9 5.11472 21.8853 3.1 19.4 3.1V4.9Z"
          fill="white"
        />
        <path
          className="line"
          d="M13.0508 8H20.0508"
          stroke="white"
          strokeLinecap="round"
        />
        <path
          className="line"
          d="M11 8H11.05"
          stroke="white"
          strokeLinecap="round"
        />
        <path
          className="line"
          d="M13 10.3H16"
          stroke="white"
          strokeLinecap="round"
        />
        <path
          className="line"
          d="M11 10.3H11.05"
          stroke="white"
          strokeLinecap="round"
        />
        <path
          className="line"
          d="M13 12.6001H19"
          stroke="white"
          strokeLinecap="round"
        />
        <path
          className="line"
          d="M11 12.6001H11.05"
          stroke="white"
          strokeLinecap="round"
        />
        <path
          className="line"
          d="M13 14.9001H20"
          stroke="white"
          strokeLinecap="round"
        />
        <path
          className="line"
          d="M11 14.9001H11.05"
          stroke="white"
          strokeLinecap="round"
        />
        <path
          className="line"
          d="M13 17.2002H17"
          stroke="white"
          strokeLinecap="round"
        />
        <path
          className="line"
          d="M11 17.2002H11.05"
          stroke="white"
          strokeLinecap="round"
        />
        <rect
          x="-0.541667"
          y="-0.541667"
          width="9.91667"
          height="9.91667"
          className="circle"
          rx="4.95833"
          transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 26.9167 26.9167)"
        />
        <path
          d="M24.3346 21.1833C24.5187 21.1833 24.668 21.0341 24.668 20.85C24.668 20.6659 24.5187 20.5167 24.3346 20.5167V21.1833ZM20.668 23.8167C20.4839 23.8167 20.3346 23.9659 20.3346 24.15C20.3346 24.3341 20.4839 24.4833 20.668 24.4833V23.8167ZM22.8346 19.75C22.8346 19.5659 22.6854 19.4167 22.5013 19.4167C22.3172 19.4167 22.168 19.5659 22.168 19.75H22.8346ZM22.168 25.25C22.168 25.4341 22.3172 25.5833 22.5013 25.5833C22.6854 25.5833 22.8346 25.4341 22.8346 25.25H22.168ZM20.3346 21.5907V21.7593H21.0013V21.5907H20.3346ZM24.0013 23.2407V23.4093H24.668V23.2407H24.0013ZM24.3346 20.5167H22.5013V21.1833H24.3346V20.5167ZM22.5013 20.5167H21.4087V21.1833H22.5013V20.5167ZM22.8346 20.85V19.75H22.168V20.85H22.8346ZM23.5939 23.8167H22.5013V24.4833H23.5939V23.8167ZM22.5013 23.8167H20.668V24.4833H22.5013V23.8167ZM22.168 24.15V25.25H22.8346V24.15H22.168ZM22.168 20.85V22.5H22.8346V20.85H22.168ZM22.5013 22.1667H21.4087V22.8333H22.5013V22.1667ZM22.168 22.5V24.15H22.8346V22.5H22.168ZM22.5013 22.8333H23.5939V22.1667H22.5013V22.8333ZM24.0013 23.4093C24.0013 23.6343 23.8189 23.8167 23.5939 23.8167V24.4833C24.1871 24.4833 24.668 24.0025 24.668 23.4093H24.0013ZM24.668 23.2407C24.668 22.6475 24.1871 22.1667 23.5939 22.1667V22.8333C23.8189 22.8333 24.0013 23.0157 24.0013 23.2407H24.668ZM20.3346 21.7593C20.3346 22.3525 20.8155 22.8333 21.4087 22.8333V22.1667C21.1837 22.1667 21.0013 21.9843 21.0013 21.7593H20.3346ZM21.0013 21.5907C21.0013 21.3657 21.1837 21.1833 21.4087 21.1833V20.5167C20.8155 20.5167 20.3346 20.9975 20.3346 21.5907H21.0013Z"
          fill="white"
          className="path"
        />
        <rect
          x="-0.541667"
          y="-0.541667"
          width="9.91667"
          className="border-circle"
          height="9.91667"
          rx="4.95833"
          transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 26.9167 26.9167)"
          stroke="white"
          strokeWidth="1.08333"
        />
      </svg>
    </span>
  )
}
