import React from "react"

export const CircleCloseIcon = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        width="104"
        height="104"
        viewBox="0 0 104 104"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_2012_2682"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="104"
          height="104"
        >
          <rect width="104" height="104" fill="white" />
        </mask>

        <g mask="url(#mask0_2012_2682)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M52.0003 8.6665C28.0803 8.6665 8.66699 28.0798 8.66699 51.9998C8.66699 75.9198 28.0803 95.3332 52.0003 95.3332C75.9203 95.3332 95.3337 75.9198 95.3337 51.9998C95.3337 28.0798 75.9203 8.6665 52.0003 8.6665C52.0003 8.6665 28.0803 8.6665 52.0003 8.6665ZM52.0003 86.6665C32.8903 86.6665 17.3337 71.1098 17.3337 51.9998C17.3337 32.8898 32.8903 17.3332 52.0003 17.3332C71.1103 17.3332 86.667 32.8898 86.667 51.9998C86.667 71.1098 71.1103 86.6665 52.0003 86.6665C52.0003 86.6665 32.8903 86.6665 52.0003 86.6665Z"
            fill="#802A2A"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M38.4853 32.8284L35.6569 30L30 35.6569L32.8284 38.4853L46.4372 52.0941L32.8289 65.7024L30.0005 68.5308L35.6574 74.1876L38.4858 71.3592L52.0941 57.7509L65.7024 71.3592L68.5308 74.1876L74.1876 68.5308L71.3592 65.7024L57.7509 52.0941L71.3597 38.4853L74.1882 35.6569L68.5313 30L65.7029 32.8284L52.0941 46.4372L38.4853 32.8284Z"
            fill="#802A2A"
          />
        </g>
      </svg>
    </span>
  )
}
