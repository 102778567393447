import api from "../../service/api"
import apiRoutes from "../../service/apiRoutes"

export const graphStatistics = (data) =>
  api.get(`${apiRoutes.graphStatistics}`, { params: data })

export const statistics = (data) =>
  api.get(`${apiRoutes.statistics}`, { params: data })

export const invoicingProgress = () =>
  api.get(`${apiRoutes.dashboard}/invoicing-progress`)

export const params = (data) =>
  api.get(`${apiRoutes.graphParams}`, { params: data })

export const spreadsheet = (data) =>
  api.post(`${apiRoutes.reports}/stats`, data)

// export const download = (data) => api.get("/download/graph", { params: data })
// api.get(`${apiRoutes.statistics}`, { params: data })
