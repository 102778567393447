import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
//
import CardPlan from "../cardPlan"
import Icons from "../../../../../../common/components/Icons"
import { BlueButton } from "../../../../../../common/components/Button"
//
import "./index.scss"
import {
  editCurrentPlan,
  getPaymentCreditCard,
  getPlans,
  getProfile,
  getSubscription,
  resetActionsStatus,
  selectConfigState,
  verifyCouponThunk,
} from "../../../../configurationsSlice"
import { useDispatch, useSelector } from "react-redux"
import StateStatus from "../../../../../../utils/stateStatus"
import Loading from "../../../../../../common/components/Loading"
import ChangePaymentMethod from "../changePaymentMethod"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min"
import { useHistory } from "react-router-dom"
import FlattedInput from "../../../../../../common/components/FlattedInput"
import { Col, Form, message, Row } from "antd"
import { numberToReal } from "../../../../../../utils/formaters"

const MenuUpdatePlan = ({ isVisible, onClose, onUpdate }) => {
  const [selectedPlan, updateSelectedPlan] = useState()
  const [hasCreditCard, updateHasCreditCard] = useState(true)
  const [coupon, setCoupon] = useState()
  const [validCoupon, setValidCoupon] = useState(true)
  const [timeoutCoupon, updateTimeoutCoupon] = useState()
  const [showAddCreditCard, updateShowAddCreditCard] = useState(false)
  const [currentPlan, updateCurrentPlan] = useState({})
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const configState = useSelector(selectConfigState)
  const subscription = configState.subscription
  const couponData = configState.data.coupon
  const profile = configState.data?.profileData
  const [form] = Form.useForm()

  useEffect(() => {
    let plan = {}
    if (configState.status.getProfile === StateStatus.succeeded) {
      plan = {
        name: profile?.plan_name,
        identifier: profile?.plan_identifier,
        proposals_limit: profile?.proposals_limit,
        ...plan,
      }
    }

    if (configState.status.getSubscription === StateStatus.succeeded) {
      plan = {
        price: subscription?.price,
        renews_at: subscription?.renews_at,
        expires_at: subscription?.expires_at,
        downgrade_scheduled_name: subscription?.downgrade_scheduled_name,
        ...plan,
      }
    }

    updateCurrentPlan(plan)
  }, [configState.status.getSubscription, configState.status.getProfile])

  useEffect(() => {
    document.body.style.overflow = isVisible ? "hidden" : "auto"
  }, [isVisible])

  useEffect(() => {
    if (!coupon || coupon?.trim()?.length === 0) {
      setValidCoupon(true)
      form.setFields([{ name: "coupon", errors: [] }])
      return
    }
    if (timeoutCoupon) clearTimeout(timeoutCoupon)

    updateTimeoutCoupon(
      setTimeout(() => {
        dispatch(verifyCouponThunk({ coupon }))
      }, 500)
    )
  }, [coupon])

  useEffect(() => {
    if (configState.status.verifyCoupon === StateStatus.failed) {
      if (!coupon || coupon?.trim()?.length === 0) {
        setValidCoupon(true)
        form.setFields([{ name: "coupon", errors: [] }])
        return
      } else {
        setValidCoupon(false)
        form.setFields([
          {
            name: "coupon",
            errors: ["Cupom inválido"],
          },
        ])
      }
    }

    if (configState.status.verifyCoupon === StateStatus.succeeded) {
      setValidCoupon(true)
      form.setFields([{ name: "coupon", errors: [] }])
    }
  }, [configState.status.verifyCoupon])

  const setCredentialsFromPath = () => {
    /* Obtem access token e refresh token via URL, usado para abrir o menu via Webview do APP */
    const paths = location.pathname.split("update-plan")
    if (paths[1]) {
      const access = paths[1].trim().split("/")
      if (access[1] && access[2]) {
        localStorage.setItem("access_token", access[1])
        localStorage.setItem("refresh_token", access[2])
        return true
      }
    }

    if (localStorage.getItem("access_token")) {
      return true
    }

    return false
  }

  useEffect(() => {
    const success = setCredentialsFromPath()
    if (success) {
      dispatch(getProfile())
      dispatch(getSubscription())
      dispatch(getPlans())
      dispatch(getPaymentCreditCard())
    }
  }, [location.pathname])

  useEffect(() => {
    if (
      configState.status.getPaymentCreditCard === StateStatus.succeeded &&
      isVisible
    ) {
      if (!configState.data.creditCard) {
        updateHasCreditCard(false)
      }
    }
  }, [isVisible, configState.status.getPaymentCreditCard])

  useEffect(() => {
    if (
      configState.status.editCurrentPlan === StateStatus.succeeded &&
      isVisible
    ) {
      dispatch(resetActionsStatus())
      dispatch(getSubscription())
      dispatch(getProfile())
      if (location.pathname.includes("/webview")) {
        history.replace("/webview/update-plan/success")
      } else {
        onUpdate(selectedPlan?.identifier)
      }
    }

    if (
      configState.status.editCurrentPlan === StateStatus.failed &&
      isVisible
    ) {
      if (location.pathname.includes("/webview")) {
        history.replace("/webview/update-plan/error")
      } else {
        message.error("Não foi possível solicitar alteração do plano.")
      }
    }
  }, [configState.status.editCurrentPlan])

  const onCloseModal = () => {
    document.body.style.overflow = "auto"
    onClose()
  }

  const class_ = isVisible && !showAddCreditCard ? "open" : "close"

  return (
    <div
      className={`aside-plans ${class_}`}
      onClick={!showAddCreditCard ? () => onCloseModal() : null}
    >
      <div
        className="content-upd-plan"
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
      >
        <div className="top-bar-upd-plan">
          <Icons
            icon="arrow-right"
            onClick={() => {
              if (location.pathname.includes("/webview")) {
                history.replace("/webview/update-plan/back")
              } else {
                onCloseModal(false)
              }
            }}
          />
          <p className="title">Plano atual</p>
        </div>
        {configState.status.getPlans === StateStatus.succeeded &&
        configState.status.getSubscription === StateStatus.succeeded ? (
          <>
            <CardPlan plan={currentPlan} />

            {hasCreditCard ? (
              <div className="warning-plan">
                <Icons icon="alert" />
                <span className="text">
                  <b>Atenção</b>: após solicitar uma alteração, o plano passará
                  a ser válido somente após a data de validade do plano atual.
                </span>
              </div>
            ) : (
              <div className="warning-plan">
                <Icons icon="alert" />
                <span className="text">
                  <b>Atenção</b>: Você precisa cadastrar um método de pagamento
                  antes de alterar o seu plano.
                </span>
              </div>
            )}

            <div className="plans">
              {configState.plans &&
                configState.plans.map((item) => (
                  <CardPlan
                    key={item.name}
                    plan={item}
                    isDisabled={item?.identifier === currentPlan?.identifier}
                    isOption={true}
                    isSelected={selectedPlan?.identifier === item.identifier}
                    onClick={(item) => {
                      updateSelectedPlan(item)
                    }}
                  />
                ))}
            </div>
            {hasCreditCard ? (
              <div>
                <Row
                  justify="start"
                  align={"top"}
                  style={{ marginBottom: "15px" }}
                >
                  <Col span={16}>
                    <Form
                      layout="horizontal"
                      form={form}
                      onFinish={(values) => {
                        dispatch(
                          editCurrentPlan({
                            planId: selectedPlan?.identifier,
                            coupon:
                              coupon && coupon?.length > 0
                                ? coupon?.trim()
                                : null,
                          })
                        )
                      }}
                    >
                      <Form.Item name="coupon" label="Cupom de desconto">
                        <FlattedInput
                          style={{
                            minWidth: "30px",
                            maxWidth: "150px",
                            textAlign: "center",
                          }}
                          placeholder="Insira o cupom"
                          onKeyUp={(e) => {
                            setCoupon(e.target.value)
                          }}
                        />
                      </Form.Item>
                    </Form>
                  </Col>
                  <Col span={3}>
                    {configState.status.verifyCoupon ===
                      StateStatus.loading && (
                      <div
                        style={{
                          position: "relative",
                          top: "15px",
                          left: "5px",
                        }}
                      >
                        <Loading className="dark small" />
                      </div>
                    )}
                  </Col>
                </Row>
                {validCoupon &&
                coupon?.length > 0 &&
                couponData?.value &&
                selectedPlan ? (
                  <Row className="coupon-info">
                    <div>
                      <div className="coupon">
                        <span className="text">Cupom aplicado: </span>
                        <span>
                          <b>{couponData.code}</b>
                        </span>
                      </div>
                      <br />
                      <div>Subtotal: {numberToReal(selectedPlan?.price)}</div>
                      <div>
                        Desconto: {" -"}
                        {couponData.type === "percentage"
                          ? numberToReal(
                              selectedPlan?.price * (couponData.value * 0.01)
                            )
                          : numberToReal(couponData.value)}
                      </div>
                      <br />
                      <div>
                        <span className="text">Valor total: </span>
                        <span className="price">
                          <b>
                            {couponData.type === "percentage"
                              ? numberToReal(
                                  selectedPlan?.price -
                                    selectedPlan?.price *
                                      (couponData.value * 0.01)
                                )
                              : numberToReal(
                                  selectedPlan?.price - couponData.value
                                )}{" "}
                            / Mês
                          </b>
                        </span>
                      </div>
                    </div>
                  </Row>
                ) : null}
              </div>
            ) : null}

            {hasCreditCard ? (
              <BlueButton
                disabled={
                  !selectedPlan ||
                  !validCoupon ||
                  configState.status.verifyCoupon === StateStatus.loading
                }
                isLoading={
                  configState.status.editCurrentPlan === StateStatus.loading
                }
                onClick={() => {
                  form.submit()
                }}
                style={{ width: "100%" }}
              >
                Solicitar alteração
              </BlueButton>
            ) : null}

            {!hasCreditCard ? (
              <BlueButton
                onClick={() => {
                  updateShowAddCreditCard(true)
                }}
                style={{ width: "100%" }}
              >
                Cadastrar cartão de crédito
              </BlueButton>
            ) : null}
          </>
        ) : (
          <Loading />
        )}
      </div>
      <ChangePaymentMethod
        isVisible={showAddCreditCard}
        onCancel={() => {
          updateShowAddCreditCard(false)
        }}
        onSave={() => {
          updateHasCreditCard(true)
          dispatch(getPaymentCreditCard())
          updateShowAddCreditCard(false)
        }}
      />
    </div>
  )
}

MenuUpdatePlan.defaultProps = {
  isVisible: true,
  onClose: () => {},
  onUpdate: () => {},
}

MenuUpdatePlan.propTypes = {
  currentPlan: PropTypes.number,
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
  onUpdate: PropTypes.func,
}

export default MenuUpdatePlan
