import React from "react"

export const LocationIcon = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <defs>
          <rect
            id="location-icon-svg-path-1"
            x="0"
            y="0"
            width="24"
            height="24"
          ></rect>
        </defs>
        <g
          id="location-icon-svg-g"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="location-icon-svg-g-2"
            transform="translate(-912.000000, -712.000000)"
          >
            <g
              id="location-icon-svg-g-3"
              transform="translate(900.000000, 254.000000)"
            >
              <g
                id="location-icon-svg-g-4"
                transform="translate(12.000000, 458.000000)"
              >
                <mask id="location-icon-svg-mask-2" fill="white">
                  <use xlinkHref="#location-icon-svg-path-1"></use>
                </mask>
                <g id="location-icon-svg-g-5"></g>
                <path
                  d="M12,13 C10.3431458,13 9,11.6568546 9,10 C9,8.34314585 10.3431458,7 12,7 C13.6568546,7 15,8.34314585 15,10 C15,11.6568546 13.6568546,13 12,13 Z M5,10 C5,13.5 7.33333333,17.1666667 12,21 C16.6666667,17.1666667 19,13.5 19,10 C19,6.13000011 15.8699999,3 12,3 C8.13000011,3 5,6.13000011 5,10 C5,13.5 5,13.5 5,10 Z M7,10 C7,7.23456955 9.23456955,5 12,5 C14.7654305,5 17,7.23456955 17,10 C17,11.8606958 15.6843081,14.1296225 13.9654369,16.1861296 C13.2713909,17.0165062 12.573236,17.7402277 12,18.2973957 C11.4267612,17.7402277 10.7286096,17.0165062 10.0345635,16.1861296 C8.31569147,14.1296225 7,11.8606958 7,10 C7,8.1563797 7,8.1563797 7,10 Z"
                  fill="#000000"
                  mask="url(#location-icon-svg-mask-2)"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </span>
  )
}
