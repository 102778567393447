import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getPayments } from "./paymentHistoryService"
//
import { urlToNewTabLink } from "../../utils/files"
import StateStatus from "../../utils/stateStatus"

export const listPayments = createAsyncThunk(
  "payments/list",
  async (_, thunkAPI) => {
    const { payments } = thunkAPI.getState()

    const response = await getPayments(payments.page, {
      starts_at: payments.startDate,
      ends_at: payments.endDate,
    })

    return {
      totalItems: response.data.data.count,
      items: response.data.data.items,
      totalPages: response.data.data.total_pages,
      actualPage: response.data.data.actual_page,
    }
  }
)

export const showPayment = createAsyncThunk("payments/show", async (url) => {
  const link = urlToNewTabLink(url, "fatura.pdf")
  link.click()

  return true
})

const initialState = {
  status: {
    listPayments: StateStatus.idle,
    showPayment: StateStatus.idle,
  },
  endDate: null,
  startDate: null,
  //
  page: 1,
  list: [],
  totalPages: 0,
  totalItems: 0,
  //
  errMessage: "",
}

export const paymentHistorySlice = createSlice({
  name: "payments",
  initialState,
  reducers: {
    updateStartDate: (state, action) => {
      state.startDate = action.payload
    },
    updateEndDate: (state, action) => {
      state.endDate = action.payload
    },
    updatePage: (state, action) => {
      state.page = action.payload
    },
  },
  extraReducers: {
    [listPayments.pending]: (state, action) => {
      state.page = action.meta.arg.page
      state.status.listPayments = StateStatus.loading
    },
    [listPayments.fulfilled]: (state, action) => {
      const { items, totalPages, actualPage, totalItems } = action.payload

      if (actualPage <= 1) {
        state.list = [...items]
      } else {
        state.list = [...state.list, ...items]
      }
      state.totalPages = totalPages

      state.totalItems = totalItems

      state.status.listPayments = StateStatus.succeeded
    },
    [listPayments.rejected]: (state, action) => {
      state.errMessage =
        action.error.message ?? "Não foi possível listar os contratos."
      state.status.listPayments = StateStatus.failed
    },

    [showPayment.pending]: (state) => {
      state.status.showPayment = StateStatus.loading
    },
    [showPayment.fulfilled]: (state) => {
      state.status.showPayment = StateStatus.succeeded
    },
    [showPayment.rejected]: (state, action) => {
      state.errMessage =
        action.error.message ?? "Não foi possível exibir faturamento."
      state.status.showPayment = StateStatus.failed
    },
  },
})

export const { updateStartDate, updateEndDate, updatePage } =
  paymentHistorySlice.actions

export const selectPaymentsState = (state) => state.payments
