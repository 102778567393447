import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import BaseModal from "../../common/components/Modal"
import { useQuery } from "../../utils/query"
import { Login } from "../login/Login"
import { logout, resetLoginState } from "../login/LoginSlice"
import { MailSentIcon } from "../../common/components/MailIcon"
import StateStatus from "../../utils/stateStatus"
import {
  confirmAccountEmail,
  selectConfirmAccountState,
} from "./confirmAccountSlice"

export const ConfirmAccount = () => {
  const query = useQuery()
  const history = useHistory()
  const dispatch = useDispatch()
  const [showModalError, updateShowModalError] = useState(false)
  const [showModalSuccess, updateShowModalSuccess] = useState(false)
  const confirmAccount = useSelector(selectConfirmAccountState)

  useEffect(() => {
    const token = query.get("token")
    const email = query.get("email")

    dispatch(logout())
    dispatch(resetLoginState())

    if (!token || !email) {
      updateShowModalError(true)
    } else {
      dispatch(confirmAccountEmail({ email, token }))
    }
  }, [])

  useEffect(() => {
    if (confirmAccount.status.confirmAccountEmail === StateStatus.succeeded) {
      localStorage.setItem("access_token", confirmAccount.data.access_token)
      localStorage.setItem("refresh_token", confirmAccount.data.refresh_token)
      updateShowModalSuccess(true)
    }

    if (confirmAccount.status.confirmAccountEmail === StateStatus.failed) {
      updateShowModalError(true)
    }
  }, [confirmAccount.status.confirmAccountEmail])

  return (
    <>
      <Login hasCheckSession={false} />
      <BaseModal
        isVisible={showModalSuccess}
        onClose={() => {
          updateShowModalSuccess(false)
          history.replace("/dashboard")
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div style={{ marginBottom: "20px" }}>
            <MailSentIcon />
          </div>
          <div className="normal-16-500">Email confirmado!</div>
          <br />
          <div className="normal-14 centralize-text">
            Entre com seu usuário e senha para criar as suas propostas.
          </div>
        </div>
      </BaseModal>
      <BaseModal
        isVisible={showModalError}
        title="Link inválido!"
        okButtonText="Ok"
        onOk={() => {
          updateShowModalError(false)
          history.replace("/")
        }}
        onCancel={() => {
          updateShowModalError(false)
          history.replace("/")
        }}
      >
        {confirmAccount.errMessage}
      </BaseModal>
    </>
  )
}
