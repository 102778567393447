import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import "./styles.scss"
import Icons from "../Icons"

const SearchAnimation = ({ onSearch, delay, value, ...props }) => {
  const [job, updateJob] = useState()
  const [isClosed, updateIsClosed] = useState(!value || value === "")
  const ref = useRef()

  useEffect(() => {
    if (ref.current.value !== value) {
      ref.current.value = value ?? ""
    }
  }, [value])

  const onChange = (event) => {
    if (job) clearTimeout(job)

    const value = event.target.value
    if (value.length === 0 || value.length >= 1) {
      const newJob = setTimeout(() => {
        if (onSearch) onSearch(value)
      }, 400)

      updateJob(newJob)
    }
  }

  const onKeyUp = (event) => {
    if (!onSearch) return

    const key = event.which || event.keyCode

    if (key === 13) {
      if (job) clearTimeout(job)
      onSearch(event.target.value)
    }
  }

  return (
    <div
      className={`search-bar-animation ${
        !isClosed ? "search-open" : "search-close"
      }`}
    >
      <input
        ref={ref}
        className="search-input"
        type="text"
        onChange={onChange}
        onKeyUp={onKeyUp}
        {...props}
      />
      <Icons icon="search" onClick={() => updateIsClosed(!isClosed)} />
      {/* <Icons className="icon" icon={`${open ? "close" : "search"}`} onClick={() => updateOpen(!open)} /> */}
    </div>
  )
}

SearchAnimation.defaultProps = {
  onSearch: () => {},
}

SearchAnimation.propTypes = {
  onSearch: PropTypes.func,
  delay: PropTypes.number,
  value: PropTypes.string,
}

export default SearchAnimation
