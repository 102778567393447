import React from "react"
import PropTypes from "prop-types"
import "./styles.scss"
import Icons from "../Icons"
import { Row, Dropdown } from "antd"

const HelpBox = ({ title, text }) => {
  return (
    <Dropdown
      overlay={
        <div className="help-info floating">
          <Row justify="end">
            <Icons className="close-icon" icon="close" />
          </Row>
          <Row className="title">
            <p>{title}</p>
          </Row>
          <Row className="text">
            <p>{text}</p>
          </Row>
        </div>
      }
      trigger={["click"]}
      placement="bottomCenter"
    >
      <Icons className="circle-help-icon" icon="circle-help" />
    </Dropdown>
  )
}

HelpBox.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
}

export { HelpBox }
