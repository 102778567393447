import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { bytesToDownloadLink } from "../../utils/files"
import StateStatus from "../../utils/stateStatus"
import {
  fulfillProposal,
  getProposal,
  invoiceDownload,
  sendCodeToEmail,
  confirmCode,
  reviewProposal,
  getContractInfo,
} from "./proposalAcceptService"

export const sendCodeToConfirm = createAsyncThunk(
  "proposal_accept/send_code",
  async (proposalId) => {
    const response = await sendCodeToEmail(proposalId)
    return response.data
  }
)

export const confirmAccessCode = createAsyncThunk(
  "proposal_accept/confirm_code",
  async ({ proposalId, data }) => {
    const response = await confirmCode(proposalId, data)
    return response.data
  }
)

export const proposalReview = createAsyncThunk(
  "proposal_accept/review",
  async ({ proposalId, data }) => {
    try {
      const response = await reviewProposal(proposalId, data)
      return response.data
    } catch (err) {
      const customError = {
        name: "Api Error",
        message: err.data.validations
          ? err.data.validations[0]
          : err.data.message,
        data: err.data,
      }
      throw customError
    }
  }
)

export const proposalFulfill = createAsyncThunk(
  "proposal_accept/fulfill",
  async (proposalId) => {
    const response = await fulfillProposal(proposalId)
    return response.data
  }
)

export const proposalGet = createAsyncThunk(
  "proposal_accept/get",
  async (proposalId) => {
    const response = await getProposal(proposalId)
    return response.data
  }
)

export const downloadInvoice = createAsyncThunk(
  "proposal_accept/download_invoice",
  async (proposalId) => {
    const response = await invoiceDownload(proposalId)
    const filename = "nota-fiscal-propozall.pdf"
    const mime = response.headers["content-type"]
    const link = bytesToDownloadLink(response.data, mime, filename)
    document.body.appendChild(link)
    link.click()
    link.remove()
    return true
  }
)

export const downloadContract = createAsyncThunk(
  "proposals/download_contract",
  async ({ contractId, doc }) => {
    const response = await getContractInfo(contractId)

    let url
    let name
    if (doc === "original") {
      url = response.data.data.original_file_public_url
      name = "contrato-original.pdf"
    } else {
      url = response.data.data.signed_file_public_url
      name = "contrato-assinado.pdf"
    }

    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", name)
    link.setAttribute("target", "__blank")
    document.body.appendChild(link)
    link.click()
    link.remove()

    return response.data
  }
)

const initialState = {
  status: {
    sendCodeToConfirm: StateStatus.idle,
    confirmAccessCode: StateStatus.idle,
    proposalReview: StateStatus.idle,
    proposalFulfill: StateStatus.idle,
    proposalGet: StateStatus.idle,
    downloadInvoice: StateStatus.idle,
    downloadContract: StateStatus.idle,
  },
  proposal: {},
  codeSentToEmail: null,
  redirectTo: null,
  errMessage: "",
}

export const proposalAcceptSlice = createSlice({
  name: "proposal_accept",
  initialState,
  reducers: {
    resetReviewState: (state, action) => {
      state.reviewProposal = StateStatus.idle
    },
    resetConfirmAccess: (state, action) => {
      state.sendCodeToConfirm = StateStatus.idle
      state.confirmAccessCode = StateStatus.idle
    },
  },
  extraReducers: {
    [proposalReview.pending]: (state, action) => {
      state.status.proposalReview = StateStatus.loading
    },
    [proposalReview.fulfilled]: (state, action) => {
      state.proposal = action.payload.data
      state.status.proposalReview = StateStatus.succeeded
    },
    [proposalReview.rejected]: (state, action) => {
      state.status.proposalReview = StateStatus.failed
      state.errMessage = action.error.message
    },
    [proposalFulfill.pending]: (state, action) => {
      state.status.proposalFulfill = StateStatus.loading
    },
    [proposalFulfill.fulfilled]: (state, action) => {
      state.status.proposalFulfill = StateStatus.succeeded
      state.status.proposalGet = StateStatus.idle
    },
    [proposalFulfill.rejected]: (state, action) => {
      state.status.proposalFulfill = StateStatus.failed
      state.errMessage = "Não foi possível finalizar proposta."
    },
    [sendCodeToConfirm.pending]: (state, action) => {
      state.status.sendCodeToConfirm = StateStatus.loading
    },
    [sendCodeToConfirm.fulfilled]: (state, action) => {
      state.codeSentToEmail = action.payload.data.email
      state.status.sendCodeToConfirm = StateStatus.succeeded
    },
    [sendCodeToConfirm.rejected]: (state, action) => {
      state.status.sendCodeToConfirm = StateStatus.failed
      state.errMessage = "Não foi enviar código de acesso."
    },
    [confirmAccessCode.pending]: (state, action) => {
      state.status.confirmAccessCode = StateStatus.loading
    },
    [confirmAccessCode.fulfilled]: (state, action) => {
      const { link, token, refresh_token: refreshToken } = action.payload.data
      localStorage.setItem("access_token_counterparty", token)
      localStorage.setItem("refresh_token_counterparty", refreshToken)

      const url = new URL(link)
      state.redirectTo = url.hash.replace("#", "")
      state.status.confirmAccessCode = StateStatus.succeeded
    },
    [confirmAccessCode.rejected]: (state, action) => {
      state.status.confirmAccessCode = StateStatus.failed
      // state.errMessage = "Não foi possível confirmar acesso."
    },
    [proposalGet.pending]: (state, action) => {
      state.status.proposalGet = StateStatus.loading
    },
    [proposalGet.fulfilled]: (state, action) => {
      state.proposal = action.payload.data
      state.status.proposalGet = StateStatus.succeeded
    },
    [proposalGet.rejected]: (state, action) => {
      state.status.proposalGet = StateStatus.failed
      state.errMessage = "Não foi possível obter informações da proposta."
    },
    [downloadInvoice.pending]: (state, action) => {
      state.status.downloadInvoice = StateStatus.loading
    },
    [downloadInvoice.fulfilled]: (state, action) => {
      state.status.downloadInvoice = StateStatus.succeeded
    },
    [downloadInvoice.rejected]: (state, action) => {
      state.status.downloadInvoice = StateStatus.failed
      state.errMessage = "Não foi possível fazer download da nota fiscal."
    },
    // downloadContract
    [downloadContract.pending]: (state) => {
      state.status.downloadContract = StateStatus.loading
    },
    [downloadContract.fulfilled]: (state, action) => {
      state.status.downloadContract = StateStatus.succeeded
    },
    [downloadContract.rejected]: (state, action) => {
      state.status.downloadContract = StateStatus.failed
      state.errMessage = action.error.message
    },
  },
})

export const { resetReviewState, resetConfirmAccess } =
  proposalAcceptSlice.actions
export const selectProposalAcceptState = (state) => state.proposalAccept
