import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  resetStatus,
  resetStatusCoupon,
  selectRegister,
  updData,
  verifyCoupon,
} from "../../RegisterSlice"
//
import Input from "../../../../common/components/Input"
//
import "./index.scss"
import Loading from "../../../../common/components/Loading"
import StateStatus from "../../../../utils/stateStatus"

const InputCoupon = () => {
  const dispatch = useDispatch()
  const register = useSelector(selectRegister)

  const [coupon, updCoupon] = useState()
  const [feedback, updFeedback] = useState()

  useEffect(() => {
    if (register.status.verifyCoupon === StateStatus.failed)
      updFeedback("error")
    else if (register.status.verifyCoupon === StateStatus.succeeded)
      updFeedback("success")
  }, [register.status.verifyCoupon])

  useEffect(() => {
    if (register.data.coupon && Object.keys(register.data.coupon).length > 0) {
      updCoupon(register.data.coupon.code)
    }
  }, [])

  return (
    <div className="input-coupon">
      <label className="ant-form-item-label">
        Possui algum cupom de desconto?
      </label>

      <Input
        className={`${feedback}`}
        value={coupon}
        placeholder="Digite o código do cupom"
        onFocus={() => {
          dispatch(resetStatusCoupon())
          updFeedback("")
        }}
        onChange={(event) => {
          const value = event.target.value
          updCoupon(value)

          if (!value || value.length < 1) {
            dispatch(updData({ coupon: null }))
            dispatch(resetStatus())
          }
        }}
      />

      <span className={`text-feedback ${feedback}`}>
        {feedback &&
          (feedback === "error"
            ? "Cupom inválido"
            : "Cupom adicionado com sucesso.")}
      </span>

      <button
        type="button"
        className={`btn-submit-coupon ${feedback}`}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()

          dispatch(verifyCoupon({ coupon }))
        }}
      >
        {register.status.verifyCoupon === StateStatus.loading ? (
          <Loading />
        ) : (
          "Enviar"
        )}
      </button>
    </div>
  )
}

export default InputCoupon
