import axios from "axios"

export const bytesToDownloadLink = (bytes, mimeType, filename) => {
  const blob = new Blob([bytes], { type: mimeType })
  const fileUrl = window.URL.createObjectURL(blob)
  const link = document.createElement("a")
  link.href = fileUrl
  link.setAttribute("download", filename)
  // document.body.appendChild(link)
  return link
}

export const urlToNewTabLink = (url, filename) => {
  // generate element <a> to open file on new tab
  const link = document.createElement("a")
  link.href = url
  link.setAttribute("download", filename)
  link.setAttribute("target", "__blank")
  // document.body.appendChild(link)
  return link
}

export const getBytesAndDownloadFile = async (url, mimeType, filename) => {
  // generate element <a> to download file
  const resp = await axios.get(url, { responseType: "arraybuffer" })
  const link = bytesToDownloadLink(resp.data, mimeType, filename)
  return link
}

export const bytesToBase64 = (bytes, mimeType) => {
  return new Promise((resolve, reject) => {
    const blob = new Blob([bytes], { type: mimeType })

    const reader = new FileReader()
    reader.readAsDataURL(blob)

    reader.onloadend = function () {
      const base64data = reader.result
      resolve(base64data)
    }

    reader.onerror = function (err) {
      reject(err)
    }
  })
}
