import React from "react"
import { Redirect, Route } from "react-router-dom"
import PropTypes from "prop-types"
import { isAuthenticated } from "../features/login/LoginSlice"

const ProtectedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        /* eslint react/prop-types: "off" */
        if (isAuthenticated()) {
          return <Component {...props} />
        } else {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      }}
    />
  )
}

ProtectedRoute.propTypes = {
  component: PropTypes.any,
}

export { ProtectedRoute }
