import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import StateStatus from "../../utils/stateStatus"
import { uploadInvoice, getInvoiceInfo } from "./invoiceUploadService"

export const invoiceUpload = createAsyncThunk(
  "invoice_upload/accept",
  async ({ proposalId, file }) => {
    const data = new FormData()
    data.append("file", file)
    const response = await uploadInvoice(proposalId, data)
    return response.data
  }
)

export const proposalGet = createAsyncThunk(
  "invoice_upload/get",
  async (proposalId) => {
    const response = await getInvoiceInfo(proposalId)
    return response.data
  }
)

const initialState = {
  status: {
    invoiceUpload: StateStatus.idle,
    proposalGet: StateStatus.idle,
  },
  proposal: {},
  errMessage: "",
}

export const invoiceUploadSlice = createSlice({
  name: "invoice_upload",
  initialState,
  reducers: {
    resetErrorMessage: (state, action) => {
      state.errMessage = null
    },
    resetUploadState: (state, action) => {
      state.status.invoiceUpload = StateStatus.idle
    },
  },
  extraReducers: {
    [invoiceUpload.pending]: (state, action) => {
      state.status.invoiceUpload = StateStatus.loading
    },
    [invoiceUpload.fulfilled]: (state, action) => {
      state.status.invoiceUpload = StateStatus.succeeded
    },
    [invoiceUpload.rejected]: (state, action) => {
      state.status.invoiceUpload = StateStatus.failed
      state.errMessage = "Não foi possível fazer upload da Nota Fiscal."
    },
    [proposalGet.pending]: (state, action) => {
      state.status.proposalGet = StateStatus.loading
    },
    [proposalGet.fulfilled]: (state, action) => {
      state.proposal = action.payload.data
      state.status.proposalGet = StateStatus.succeeded
    },
    [proposalGet.rejected]: (state, action) => {
      state.status.proposalGet = StateStatus.failed
      state.errMessage = "Não foi possível obter informações da proposta."
    },
  },
})

export const { resetErrorMessage, resetUploadState } =
  invoiceUploadSlice.actions
export const selectInvoiceUploadState = (state) => state.invoiceUpload
