import { useEffect, useState } from "react"
import { connect } from "../../service/socketio"

const useMessagesSocketIO = ({
  proposalId,
  onReceiveMessage,
  onJoinChat,
  onLeftChat,
}) => {
  const [isChatConnected, setIsChatConnected] = useState()
  const [socket, setSocket] = useState()

  useEffect(() => {
    const socket = connect()
    setSocket(socket)

    if (socket) {
      socket.on("update-messages-chat-proposal", onReceiveMessage)
      socket.on("joined-chat-proposal", onJoinChat)
      socket.on("left-chat-proposal", onLeftChat)

      return () => {
        if (isChatConnected) {
          leaveChat(proposalId, "")
        }

        socket.off("update-messages-chat-proposal")
        socket.off("alerts-chat-proposal")
        socket.off("left-chat-proposal")
        socket.disconnect()
        console.log(`socket ${proposalId} desconectado`)
      }
    }
  }, [])

  const joinChat = (userName) => {
    socket.emit("join-chat-proposal", {
      proposal_id: proposalId,
      user: userName,
    })
    setIsChatConnected(true)
  }

  const leaveChat = (userName) => {
    socket.emit("leave-chat-proposal", {
      proposal_id: proposalId,
      user: userName,
    })
    setIsChatConnected(false)
  }

  const sendMessage = (fromRole, text) => {
    socket.emit("new-message-chat-proposal", {
      proposal_id: proposalId,
      from_role: fromRole,
      text,
    })
  }

  const sendAlert = (fromRole, alert) => {
    socket.emit("new-message-chat-proposal", {
      proposal_id: proposalId,
      from_role: fromRole,
      alert,
    })
  }

  return { isConnected: !!socket, joinChat, leaveChat, sendMessage, sendAlert }
}

export { useMessagesSocketIO }
