import React from "react"

export const MoreIcon = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <defs>
          <rect
            id="propozall-more-icon-path"
            x="0"
            y="0"
            width="24"
            height="24"
          />
        </defs>
        <g
          id="propozall-more-icon-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="propozall-more-icon-2"
            transform="translate(-822.000000, -244.000000)"
          >
            <g
              id="propozall-more-icon-3"
              transform="translate(128.000000, 216.000000)"
            >
              <g
                id="propozall-more-icon-4"
                transform="translate(694.000000, 28.000000)"
              >
                <mask id="propozall-more-icon-mask" fill="white">
                  <use xlinkHref="#propozall-more-icon-path" />
                </mask>
                <g id="propozall-more-icon-5" />
                <path
                  d="M4,12 C4,13.0999999 4.89999998,14 6,14 C7.0999999,14 8,13.0999999 8,12 C8,10.9 7.0999999,10 6,10 C4.89999998,10 4,10.9 4,12 Z M16,12 C16,13.0999999 16.8999996,14 18,14 C19.1000004,14 20,13.0999999 20,12 C20,10.9 19.1000004,10 18,10 C16.8999996,10 16,10.9 16,12 Z M12,10 C13.1000004,10 14,10.9 14,12 C14,13.0999999 13.1000004,14 12,14 C10.9000001,14 10,13.0999999 10,12 C10,10.9 10.9000001,10 12,10 Z"
                  fill="#676767"
                  mask="url(#propozall-more-icon-mask)"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </span>
  )
}
