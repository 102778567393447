import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import Icons from "../Icons"
import "./styles.scss"

const BoxLink = ({ onClose, ...props }) => {
  return (
    <div className="box-link">
      <Link {...props}></Link>
      {onClose && <Icons icon="close" onClick={onClose} />}
    </div>
  )
}

BoxLink.propTypes = {
  onClose: PropTypes.func,
}

export { BoxLink }
