import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"
//
import { useDispatch } from "react-redux"
import { clearObjPayment } from "../../RegisterSlice"
//
import Icons from "../../../../common/components/Icons"
import { BlueButton } from "../../../../common/components/Button"
//
import "./index.scss"

const FeedbackPayment = ({ status, text, page }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [statusObj, setStatusObj] = useState({})

  const messages = {
    success: {
      title: "Compra efetuada",
      text: "Obrigado! Verifique seu e-mail para mais detalhes da sua compra.",
      icon: "circle-check",
    },
    loading: {
      title: "Aguardando pagamento",
      text: "A plataforma será liberada após o pagamento ter sido efetuado.",
      icon: "alert",
    },
    error: {
      title: "Compra não finalizada",
      text:
        text ??
        "Não conseguimos finalizar a sua compra, entre em contato com a sua operadora e tente novamente.",
      icon: "circle-close",
    },
  }

  useEffect(() => {
    switch (status) {
      case undefined:
      case null:
      case "pending":
        setStatusObj(messages.loading)
        break
      case "success":
        setStatusObj(messages.success)
        break
      case "failed":
      case "canceled":
        setStatusObj(messages.error)
        break
    }
  }, [status])

  return (
    <div className={`feedback-container feedback-${page} ${status}`}>
      <Icons icon={statusObj.icon} />
      <p className="title">{statusObj.title}</p>
      <p className="text">{statusObj.text}</p>

      <br />
      <br />

      {status === "success" && (
        <BlueButton onClick={() => history.replace("/register/5")}>
          Entrar na plataforma
        </BlueButton>
      )}

      {status === "failed" && (
        <BlueButton
          onClick={async () => {
            await dispatch(clearObjPayment())
            window.location.reload()
          }}
        >
          Tentar com outro cartão
        </BlueButton>
      )}
    </div>
  )
}

FeedbackPayment.defaultProps = {
  status: "pending",
  text: null,
  page: "register",
}

FeedbackPayment.propTypes = {
  status: PropTypes.string,
  text: PropTypes.string,
  page: PropTypes.string,
}

export default FeedbackPayment
