import React from "react"

export const UploadIcon = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        width="14px"
        height="14px"
        viewBox="0 0 14 14"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <defs>
          <rect
            id="propozall-upload-icon-path"
            x="0"
            y="0"
            width="14"
            height="14"
          ></rect>
        </defs>
        <g
          id="propozall-upload-icon-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="propozall-upload-icon-2"
            transform="translate(-56.000000, -1464.000000)"
          >
            <g
              id="propozall-upload-icon-3"
              transform="translate(56.000000, 1464.000000)"
            >
              <mask id="propozall-upload-icon-mask" fill="white">
                <use xlinkHref="#propozall-upload-icon-path"></use>
              </mask>
              <g id="propozall-upload-icon-4"></g>
              <path
                d="M4.66666667,7.58333333 L7,5.25 L9.33333333,7.58333333 L7.84583322,7.58333333 L7.84583322,9.33333333 L6.15416678,9.33333333 L6.15416678,7.58333333 L4.66666667,7.58333333 Z M7,2.33333333 C9.186601,2.33333333 11.0219568,3.83719605 11.5281878,5.8670435 C12.927876,6.08124677 14,7.29041886 14,8.75 C14,10.3608307 12.6941635,11.6666667 11.0833333,11.6666667 L3.5,11.6666667 C1.56700335,11.6666667 0,10.0996632 0,8.16666667 C0,6.4272716 1.26883245,4.98422817 2.93141639,4.71261704 C3.73156182,3.29244602 5.25377456,2.33333333 7,2.33333333 C8.457734,2.33333333 8.457734,2.33333333 7,2.33333333 Z M3.1195176,5.86402023 L3.67271229,5.77364655 L3.94785682,5.28529481 C4.54967272,4.21713744 5.6918509,3.5 7,3.5 C8.63838196,3.5 10.0164865,4.62683348 10.3961937,6.14935553 L10.5841007,6.9028132 L11.3516995,7.02028362 C12.190496,7.14864993 12.8333333,7.87531821 12.8333333,8.75 C12.8333333,9.71649853 12.0498319,10.5 11.0833333,10.5 L3.5,10.5 C2.21133552,10.5 1.16666667,9.45533101 1.16666667,8.16666667 C1.16666667,7.00820168 2.01205512,6.04494282 3.1195176,5.86402023 L3.1195176,5.86402023 Z"
                fill="#000000"
                mask="url(#propozall-upload-icon-mask)"
              ></path>
            </g>
          </g>
        </g>
      </svg>
    </span>
  )
}
