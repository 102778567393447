import React, { useState } from "react"
import PropTypes from "prop-types"
//
import { numberToReal } from "../../../../utils/formaters"
//
import "./index.scss"

const PlanDescription = ({ coupon, plan, isLoading }) => {
  const [open, updateOpen] = useState(true)

  if (isLoading) return <>Carregando...</>

  return (
    <div className="plan-description">
      <p className="form-title" onClick={() => updateOpen(!open)}>
        Seu pedido
        <span className={`arrow ${open ? "open" : ""}`} />
      </p>

      <div className={`request-product ${open ? "open" : ""}`}>
        <div className="plan-card">
          <p className="plan-name">Plano {plan.name}</p>
          {plan.proposals_limit && (
            <p className="plan-text">
              Até {plan.proposals_limit} proposta(s) aceita(s)
            </p>
          )}
          {/* <p className="plan-type">
            Tipo de plano: {plan.name ? plan.name.split(" ")[1] : ""}
          </p> */}

          {coupon != null && (
            <div className="upd-plan-value">
              <p className="plan-type">
                Subtotal: <b>{numberToReal(plan.price)}</b>
              </p>
              <p className="plan-type coupon-value">
                Desconto:{" "}
                <b>
                  {" "}
                  {" -"}
                  {coupon.type === "percentage"
                    ? numberToReal(plan.price * (coupon.value * 0.01))
                    : numberToReal(coupon.value)}
                </b>
              </p>
            </div>
          )}

          {!coupon ? (
            <p className="plan-value">
              <span className="text">Valor total:</span>
              <span className="price">{numberToReal(plan.price)}</span>
            </p>
          ) : (
            <>
              <p className="plan-value">
                <span className="text">Valor total:</span>
                <span className="price">
                  {coupon.type === "percentage"
                    ? numberToReal(
                        plan.price - plan.price * (coupon.value * 0.01)
                      )
                    : numberToReal(plan.price - coupon.value)}
                </span>
              </p>
              <div className="coupon">
                <span className="text">Cupom aplicado: </span>
                <span>
                  <b>{coupon.code}</b>
                </span>
              </div>
            </>
          )}
        </div>

        <span className="form-observation">
          Este site utiliza certificados SSL que protegem dados pessoais de seus
          clientes, incluindo senhas, cartões de crédito e informações privadas.
        </span>
      </div>
    </div>
  )
}

PlanDescription.propTypes = {
  coupon: PropTypes.object,
  plan: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
}

export default PlanDescription
