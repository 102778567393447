import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getReports } from "./ReportsService"
//
import StateStatus from "../../utils/stateStatus"

export const listReports = createAsyncThunk("report/list", async ({ role }) => {
  const response = await getReports({ role })

  return {
    items: response.data.data.items,
  }
})

const initialState = {
  status: {
    listReports: StateStatus.idle,
  },
  list: [],
  //
  errMessage: "",
}

export const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {},
  extraReducers: {
    [listReports.pending]: (state, action) => {
      if (!action.meta.arg.refresh) {
        state.status.listReports = StateStatus.loading
      }
    },
    [listReports.fulfilled]: (state, action) => {
      const { items } = action.payload

      if (state.list.length < 1) state.list = [...items]

      state.status.listReports = StateStatus.succeeded
    },
    [listReports.rejected]: (state, action) => {
      state.errMessage =
        action.error.message ?? "Não foi possível listar os relatórios."
      state.status.listReports = StateStatus.failed
    },
  },
})

export const selectReportsState = (state) => state.reports
