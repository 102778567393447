import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { sendVoteSatisfaction, existsVoteSatisfaction } from "./service"
//
import StateStatus from "../../utils/stateStatus"

export const sendVoteSatisfactionThunk = createAsyncThunk(
  "vote-satisfaction/send",
  async (data) => {
    const response = await sendVoteSatisfaction(data)
    return response.data
  }
)

export const existsVoteSatisfactionThunk = createAsyncThunk(
  "vote-satisfaction/exists",
  async ({ question }) => {
    const response = await existsVoteSatisfaction({ question })
    return response.data
  }
)

const initialState = {
  status: {
    sendVoteSatisfaction: StateStatus.idle,
    existsVoteSatisfaction: StateStatus.idle,
  },
  show_question: null,
  errMessage: "",
}

export const voteSatisfactionSlice = createSlice({
  name: "vote-satisfaction",
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.status.sendVoteSatisfaction = StateStatus.idle
      state.status.existsVoteSatisfaction = StateStatus.idle
    },
    resetShowQuestion: (state) => {
      state.show_question = null
    },
  },
  extraReducers: {
    [sendVoteSatisfactionThunk.pending]: (state, action) => {
      state.status.sendVoteSatisfaction = StateStatus.loading
    },
    [sendVoteSatisfactionThunk.fulfilled]: (state, action) => {
      state.status.sendVoteSatisfaction = StateStatus.succeeded
    },
    [sendVoteSatisfactionThunk.rejected]: (state, action) => {
      state.errMessage =
        action.error.message ?? "Não foi possível enviar pesquisa."
      state.status.sendVoteSatisfaction = StateStatus.failed
    },
    [existsVoteSatisfactionThunk.pending]: (state, action) => {
      state.status.existsVoteSatisfaction = StateStatus.loading
    },
    [existsVoteSatisfactionThunk.fulfilled]: (state, action) => {
      const hasVote = action.payload.data.has_vote
      state.show_question =
        !hasVote && hasVote !== undefined && hasVote !== null
      state.status.existsVoteSatisfaction = StateStatus.succeeded
    },
    [existsVoteSatisfactionThunk.rejected]: (state, action) => {
      state.errMessage =
        action.error.message ?? "Não foi possível verificar pesquisa."
      state.status.existsVoteSatisfaction = StateStatus.failed
    },
  },
})

export const { resetStatus, resetShowQuestion } = voteSatisfactionSlice.actions

export const selectVoteSatisfactionState = (state) => state.voteSatisfaction
