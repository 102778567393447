import React from "react"

export const TextDescriptionIcon = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <defs>
          <rect
            id="text-description-icon-svg-path-1"
            x="0"
            y="0"
            width="24"
            height="24"
          ></rect>
        </defs>
        <g
          id="text-description-icon-svg-g"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="text-description-icon-svg-g-1"
            transform="translate(-916.000000, -753.000000)"
          >
            <g
              id="text-description-icon-svg-g-2"
              transform="translate(900.000000, 254.000000)"
            >
              <g
                id="text-description-icon-svg-g-3"
                transform="translate(16.000000, 499.000000)"
              >
                <g
                  id="text-description-icon-svg-g-4"
                  transform="translate(12.000000, 12.000000) scale(-1, 1) translate(-12.000000, -12.000000) "
                >
                  <mask id="text-description-icon-svg-mask-2" fill="white">
                    <use xlinkHref="#text-description-icon-svg-path-1"></use>
                  </mask>
                  <g id="text-description-icon-svg-g-5"></g>
                  <path
                    d="M3,6 L21,6 L21,8 L3,8 L3,6 Z M21,13 L21,11 L3,11 L3,13 L21,13 Z M21,18 L21,16 L12,16 L12,18 L21,18 Z"
                    fill="#000000"
                    mask="url(#text-description-icon-svg-mask-2)"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </span>
  )
}
