import { configureStore } from "@reduxjs/toolkit"
import { counterSlice } from "../features/countersPage/countersPageSlice"
// import counterReducer from "../features/counter/counterSlice"
import { loginReducer } from "../features/login/LoginSlice"
import { registerSlice } from "../features/register/RegisterSlice"
import { configSlice } from "../features/configurations/configurationsSlice"
import { dashboardSlice } from "../features/dashboard/DashboardSlice"
import { listProposalsSlice } from "../features/listProposals/listProposalsSlice"
import { listNotificationsSlice } from "../features/listNotifications/listNotificationsSlice"
import { proposalAcceptSlice } from "../features/proposalAccept/proposalAcceptSlice"
import { proposalSlice } from "../features/proposal/proposalSlice"
import { contactSlice } from "../features/contact/contactSlice"
import { invoiceUploadSlice } from "../features/invoiceUpload/invoiceUploadSlice"
import { companySlice } from "../features/company/companySlice"
import { passwordSlice } from "../features/password/passwordSlice"
import { contractSlice } from "../features/contract/contractSlice"
import { paymentHistorySlice } from "../features/paymentHistory/paymentHistorySlice"
import { contractHistorySlice } from "../features/contractHistory/contractHistorySlice"
import { confirmAccountSlice } from "../features/confirmAccount/confirmAccountSlice"
import { contractViewSlice } from "../features/contractView/contractViewSlice"
import { proposalViewSlice } from "../features/proposalView/slice"
import { reportsSlice } from "../features/reports/ReportsSlice"
import { signersSlice } from "../features/SignersModal/slice"
import { nfseSlice } from "../features/nfse/nfseSlice"
import { createNfseSlice } from "../features/createNfse/createNfseSlice"
import { chatProposalSlice } from "../features/chatProposal/slice"
import { voteSatisfactionSlice } from "../features/VoteSatisfaction/slice"

export const store = configureStore({
  reducer: {
    login: loginReducer.reducer,
    register: registerSlice.reducer,
    confirmAccount: confirmAccountSlice.reducer,
    counters: counterSlice.reducer,
    config: configSlice.reducer,
    dash: dashboardSlice.reducer,
    chatProposal: chatProposalSlice.reducer,
    proposals: listProposalsSlice.reducer,
    proposal: proposalSlice.reducer,
    proposalView: proposalViewSlice.reducer,
    notifications: listNotificationsSlice.reducer,
    proposalAccept: proposalAcceptSlice.reducer,
    invoiceUpload: invoiceUploadSlice.reducer,
    contact: contactSlice.reducer,
    company: companySlice.reducer,
    password: passwordSlice.reducer,
    contract: contractSlice.reducer,
    contracts: contractHistorySlice.reducer,
    contractView: contractViewSlice.reducer,
    payments: paymentHistorySlice.reducer,
    reports: reportsSlice.reducer,
    signers: signersSlice.reducer,
    nfse: nfseSlice.reducer,
    createNfse: createNfseSlice.reducer,
    voteSatisfaction: voteSatisfactionSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})
