import Icons from "../../../../common/components/Icons"
import React, { useEffect, useState } from "react"
// import PlanUse from "../planUse"
// import { ConfigurationButton } from "../../Topbar"
// import { useDispatch } from "react-redux"
import BoxUserRoleModal from "../boxUserRoleModal"
import PropTypes from "prop-types"
import "./styles.scss"
import { useDispatch, useSelector } from "react-redux"
import {
  selectConfigState,
  // selectProfileData,
  getProfile,
} from "../../../configurations/configurationsSlice"
import StateStatus from "../../../../utils/stateStatus"

const BoxUserRole = ({ user, role, email }) => {
  const [showModal, updateShowModal] = useState(false)
  const dispatch = useDispatch()
  // const profileData = useSelector(selectProfileData)
  const configState = useSelector(selectConfigState)

  useEffect(() => {
    if (configState.status.getProfile === StateStatus.idle) {
      dispatch(getProfile())
    }
  }, [configState.status.getProfile, dispatch, getProfile])

  const roleName = role === "contracted" ? "Contratado" : "Contratante"

  return (
    <div className="topbar-container-box">
      <div
        className="icon-suitcase-box"
        onClick={() => updateShowModal(!showModal)}
      >
        {role === "contracted" ? (
          <span className="role-icon-first">
            <Icons icon={"suitcase"} />
          </span>
        ) : (
          <div className="role-icon-second">
            <Icons icon={"interpriseIcon"} />
          </div>
        )}
      </div>
      <div
        className="box-style-text"
        style={{ marginTop: "-5px" }}
        onClick={() => updateShowModal(!showModal)}
      >
        <div
          className="normal-12 not-overflow"
          style={{ paddingLeft: "13px", maxWidth: "100px", maxHeight: "50px" }}
        >
          {user ?? "..."}
        </div>
        <div
          className="style-role"
          style={{ paddingLeft: "13px", maxWidth: "100px", maxHeight: "50px" }}
        >
          {roleName}
        </div>
      </div>

      <div>
        {/* <SwitchMode state={dashState} /> */}

        {showModal ? (
          <BoxUserRoleModal
            user={user}
            email={email}
            role={role}
            onClose={() => updateShowModal(false)}
          />
        ) : null}
      </div>
    </div>
  )
}

BoxUserRole.propTypes = {
  user: PropTypes.string,
  role: PropTypes.string,
  email: PropTypes.string,
}

export default BoxUserRole
