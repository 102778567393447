import React from "react"
import PropTypes from "prop-types"
//
import { BlueButton } from "../../../../common/components/Button"
import { Col, Divider, Row, Image } from "antd"

import Avatar from "../../../../common/components/avatar"
import AudioPlayer from "../../../../common/components/AudioPlayer"
import Icons from "../../../../common/components/Icons"
import MultiPlayer from "../../../../common/components/MultiPlayer"
//
import {
  beautifyCpfCnpj,
  joinStr,
  numberToReal,
} from "../../../../utils/formaters"
//
import "./styles.scss"
import CollapseRowHeader from "../CollapseRowHeader"
import CollapseRowContent from "../CollapseRowContent"
import { useRouteMatch } from "react-router-dom"
import BoxQRCodePix from "../BoxQRCodePix"

const FileItem = ({ fileName, url, mimeType }) => {
  if (mimeType.match("image")) return <Image src={url} alt="" width={300} />

  if (mimeType.match("video"))
    return <MultiPlayer url={[{ src: url, type: mimeType }]} />

  if (mimeType.match("audio")) return <AudioPlayer url={url} info={fileName} />

  const onClickDownload = () => {
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", fileName)
    link.setAttribute("target", "__blank")
    document.body.appendChild(link)
    link.click()
  }

  return (
    <div className="download-file">
      <div onClick={onClickDownload}>{fileName}</div>
      <Icons icon="download" title="Baixar" onClick={onClickDownload} />
    </div>
  )
}

FileItem.propTypes = {
  fileName: PropTypes.string,
  mimeType: PropTypes.string,
  url: PropTypes.string,
}

// ----------------------------------------------------------------------

const ProposalResume = ({
  elements,
  values,
  id,
  activeKeys,
  openColappse,
  closeColappse,
}) => {
  const { path } = useRouteMatch()

  const getLabelItems = (items) => {
    const types = {}
    items.forEach((item) => {
      let qntUnit = `${item.quantity}`
      if (item.unit) {
        qntUnit = `${item.quantity} ${item.unit}`
      }

      if (types[item.type]) {
        types[item.type].units.push(qntUnit)
      } else {
        types[item.type] = {
          label: item.type,
          units: [qntUnit],
        }
      }
    })

    return Object.keys(types).map((key) => {
      const type = types[key]
      return `${type.label}: ${joinStr(type.units)}`
    })
  }

  const formatter = (value, format) => {
    if (!format) return value

    if (format === "currency") return numberToReal((value ?? 0) / 100)
    if (format === "cpf") return beautifyCpfCnpj(value)

    return value
  }

  const returnElement = (elem, parentElem, index) => {
    const storageValue = (fieldName) => {
      let data = values
      let value = ""

      const keys = fieldName.split(".")
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i]
        if (i === keys.length - 1) {
          if (data instanceof Object) value = data[key]
        } else if (data instanceof Object) {
          data = data[key]
        }
      }

      return value
    }

    const createKeyForElem = () => {
      let parentKey = ""
      if (parentElem && parentElem.props && parentElem.props.key)
        parentKey = parentElem.props.key

      return `${parentKey}$-${elem.type}-${index}-${new Date().getTime()}`
    }

    if (elem.conditional) {
      const keys = Object.keys(elem.conditional)
      const value = storageValue(elem.conditional.name)

      if (keys.includes("equal") && value !== elem.conditional.equal) {
        return null
      } else if (keys.includes("diff") && value === elem.conditional.diff) {
        return null
      } else if (!value) {
        return null
      }
    }

    // Create a key for props element
    const elem_ = {
      ...elem,
      props: {
        ...(elem.props ?? {}),
        key: (elem.props ?? {}).key ?? createKeyForElem(),
      },
    }

    switch (elem.type) {
      // --------------
      // ---- TAGS ----
      // --------------
      case "div":
        return (
          <div {...elem_.props}>
            {elem.elements.map((item, index) =>
              returnElement(item, elem_, index)
            )}
          </div>
        )

      case "LabelValue":
        return (
          <Row align="top" justify="space-between">
            <Col>
              <div {...elem_.props} className="label-value">
                {elem.elements.map((item, index) =>
                  returnElement(item, elem_, index)
                )}
              </div>
            </Col>
            {/* {path.includes("/dashboard/proposal/") ? (
              <Col>
                <Row
                  className="label-value-edit-btn"
                  onClick={() =>
                    console.log(id) ??
                    history.replace(id ? `${elem_.path}/${id}` : elem_.path)
                  }
                >
                  <Icons icon="pen" />
                  Editar
                </Row>
              </Col>
            ) : null} */}
          </Row>
        )

      case "a":
        return (
          <a {...elem_.props}>
            {elem.elements.map((item, index) =>
              returnElement(item, elem_, index)
            )}
          </a>
        )

      case "label":
        return (
          <label {...elem_.props}>
            {elem.elements.map((item, index) =>
              returnElement(item, elem_, index)
            )}
          </label>
        )

      case "text": {
        let value = null
        if (elem.name) {
          value = formatter(storageValue(elem.name), elem.formatter)
        }

        if (value) {
          return `${value}${elem.suffix ? elem.suffix : ""}`
        } else {
          return elem.default ?? " - "
        }
      }

      case "TextArea": {
        let value = null
        if (elem.name) {
          value = formatter(storageValue(elem.name), elem.formatter)
        }

        if (value) {
          const values = `${value}`.split("\n")

          return (
            <div key={createKeyForElem()}>
              {values.map((item, index) => (
                <div key={index}>{item}</div>
              ))}
            </div>
          )
        } else {
          return <div>{elem.default ?? " - "}</div>
        }
      }

      case "CpfCnpj": {
        let value
        if (elem.name) {
          value = storageValue(elem.name)
        }

        return value ? beautifyCpfCnpj(value) : elem.default
      }

      case "choice": {
        const value = storageValue(elem.name)
        let choiceValue = null

        for (let i = 0; i < elem.choices.length; i++) {
          const choice = elem.choices[i]
          if (value === choice[0]) {
            choiceValue = choice[1]
            break
          }
        }

        return !choiceValue ? elem.default : choiceValue
      }

      // ----------------
      // ----- ???? -----
      // ----------------
      case "options": {
        if (elem.name) {
          return joinStr(values[elem.name])
        } else return elem.default
      }

      case "ItemsLabels": {
        if (values[elem.name] && values[elem.name].length > 0) {
          return getLabelItems(values[elem.name]).map((item, index) => (
            <span key={index} className="method-types">
              <span className="info">{item}</span>
              <span className="sep"> | </span>
            </span>
          ))
        } else {
          return " - "
        }
      }

      case "ItemsDescribe": {
        if (values[elem.name]) {
          return values[elem.name].map((item, index) => (
            <Row key={index}>
              <Col span={4}>{item.quantity} </Col>
              <Col span={14}>{item.type} </Col>
              <Col span={6}>{numberToReal((item.unit_value ?? 0) / 100)}</Col>
            </Row>
          ))
        } else {
          return `(${elem.name})`
          // return " - "
        }
      }

      case "SocialMedias": {
        const values = storageValue(elem.name) ?? []
        if (values.length > 0) {
          return values.map((item, index) => (
            <div key={index}>
              {item.plataform.initials}: {item.username}
            </div>
          ))
        } else {
          return elem.default ?? `(${elem.name})`
        }
      }

      case "Address": {
        if (values[values.name]) {
          const address = values[values.name]
          return (
            <div>
              <div>
                {address.street}, {address.neighborhood}
              </div>
              <div>
                {address.city} - {address.state} | CEP: {address.cep}
              </div>
            </div>
          )
        } else {
          return elem.default ?? `(${elem.name})`
        }
      }

      case "FileItem": {
        if (values[elem.name] && values[elem.name].length > 0) {
          return values[elem.name].map((item, index) => (
            <Col key={index}>
              <FileItem
                key={index}
                fileName={item.filename ?? item.name}
                mimeType={item.mime_type ?? item.mimeType}
                url={item.link ?? item.url}
              />
            </Col>
          ))
        } else {
          return (
            <Col key={index}>
              <div className="normal-14">Nenhum arquivo foi anexado</div>
            </Col>
          )
        }
      }
      // ----------------
      // ----- ANTD -----
      // ----------------
      case "Avatar":
        return <Avatar {...elem_.props} src={storageValue(elem.name) || null} />

      case "Col":
        return (
          <Col {...elem_.props}>
            {elem.elements.map((item, index) =>
              returnElement(item, elem_, index)
            )}
          </Col>
        )

      case "Divider":
        return <Divider {...elem_.props} />

      case "Space":
        return <br></br>

      case "BoxQRCodePix":
        /* eslint no-var: off */
        var pixKey_ = storageValue(elem.name)
        var companyName_ = storageValue("contracted.company_name")
        var totalValue_ = storageValue("total_value")
        var city_ = storageValue("contracted.address.city")
        var id_ = values?.id?.replaceAll("-", "").slice(0, 15)

        return (
          <BoxQRCodePix
            name={companyName_ ?? ""}
            keyPix={pixKey_}
            totalValue={totalValue_ ?? 1}
            city={city_ ?? ""}
            transactionId={`PROPOZALL${id_ ?? ""}`}
          />
        )

      case "Row":
        return (
          <Row {...elem_.props}>
            {elem.elements.map((item, index) =>
              returnElement(item, elem_, index)
            )}
          </Row>
        )
      case "CollapseRowContent":
        return (
          <CollapseRowContent
            isActive={activeKeys?.includes(elem_.props.id)}
            {...elem_.props}
          >
            {elem.elements.map((item, index) =>
              returnElement(item, elem_, index)
            )}
          </CollapseRowContent>
        )

      case "CollapseRowHeader":
        return (
          <CollapseRowHeader
            isActive={activeKeys?.includes(elem_.props.collapseFor)}
            onOpen={openColappse}
            onClose={closeColappse}
            pathToEdit={
              path.includes("/dashboard/proposal/")
                ? id_
                  ? `${elem_.props.path}/${id_}`
                  : elem_.props.path
                : null
            }
            {...elem_.props}
          />
        )

      // ----------------
      // -- COMPONENTS --
      // ----------------
      case "BlueButton":
        return (
          <Row justify="end">
            <BlueButton {...elem_.props}>
              {elem.elements.map((item, index) =>
                returnElement(item, elem_, index)
              )}
            </BlueButton>
          </Row>
        )

      case "Icons":
        return <Icons {...elem_.props} />

      case "RoudedBox":
        return (
          <div {...elem_.props}>
            {elem.elements.map((item, index) =>
              returnElement(item, elem_, index)
            )}
          </div>
        )

      default:
        return <span>?</span>
    }
  }

  return (
    <div>
      {elements?.map &&
        elements?.map((item, index) => returnElement(item, null, index))}
    </div>
  )
}

ProposalResume.propTypes = {
  id: PropTypes.string,
  values: PropTypes.object.isRequired,
  elements: PropTypes.arrayOf(PropTypes.object).isRequired,
  activeKeys: PropTypes.array,
  openColappse: PropTypes.func,
  closeColappse: PropTypes.func,
}

export default ProposalResume
