import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { cepMask } from "../../utils/formaters"
//
import StateStatus from "../../utils/stateStatus"
import {
  createAccount,
  createPayment,
  checkCoupon,
  planDetails,
  updateAccount,
  createAddress,
  updateAddress,
  address,
  subscription,
} from "./RegisterService"

const initialState = {
  status: {
    saveAccount: StateStatus.idle,
    saveAddress: StateStatus.idle,
    sendPayment: StateStatus.idle,
    getSubscription: StateStatus.idle,
    verifyCoupon: StateStatus.idle,
    getPlanDetails: StateStatus.idle,
    getAddress: StateStatus.idle,
  },
  msg: "",
  data: {
    address: {},
    payment: {},
  },

  // form
  price: 500.0,

  // utils
  maxStep: 1,
  stepActive: 1,
  isAddress: false,
  tips: false,
  planIdentifier: null,
  planData: {
    name: null,
    price: 0,
  },
  objPayment: {},
}

export const getSubscription = createAsyncThunk(
  "get-subscription",
  async (_) => {
    const response = await subscription()

    return response.data
  }
)

// STEP 1
export const sendAccount = createAsyncThunk(
  "send-account",
  async ({ data }, _) => {
    const response = await createAccount({
      ...data,
      from_associated_id: localStorage.getItem("associated_id"),
    })

    if (response && response.data.data && response.data.data.access_token) {
      window.localStorage.setItem(
        "access_token",
        response.data.data.access_token
      )
      window.localStorage.setItem(
        "refresh_token",
        response.data.data.refresh_token
      )
    }

    return response.data
  }
)
export const updAccount = createAsyncThunk(
  "upd-account",
  async ({ data }, _) => {
    const response = await updateAccount(data)

    return response.data
  }
)

// STEP 2
export const getAddress = createAsyncThunk("get-address", async (_) => {
  const response = await address()

  return response.data
})

export const sendAddress = createAsyncThunk(
  "send-address",
  async ({ data }, _) => {
    data.country = "Brasil"

    const response = await createAddress(data)

    return response.data
  }
)

export const updAddress = createAsyncThunk(
  "upd-address",
  async ({ id, data }, thunkAPI) => {
    data.country = "Brasil"
    const response = await updateAddress(id, data)

    return response.data
  }
)

// STEP 3
export const sendPayment = createAsyncThunk(
  "send-payment",
  async (data, thunkAPI) => {
    const { register } = thunkAPI.getState()

    const response = await createPayment({
      coupon: register.data.coupon?.code ? register.data.coupon.code : null,
      credit_card_token: data.token,
      plan_identifier: register.planIdentifier,
    })

    return response
  }
)
export const verifyCoupon = createAsyncThunk(
  "verify-coupon",
  async ({ coupon }) => {
    const response = await checkCoupon(coupon)

    return response.data
  }
)

export const getPlanDetails = createAsyncThunk(
  "get-plan",
  async (planIdentifier) => {
    const response = await planDetails()
    return {
      planIdentifier,
      items: response.data.data.items,
    }
  }
)

export const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    resetStatus: (state) => {
      state.msg = null
      state.status.saveAccount = StateStatus.idle
      state.status.saveAddress = StateStatus.idle
      state.status.sendPayment = StateStatus.idle
      // state.status.verifyCoupon = StateStatus.idle
      state.status.getPlanDetails = StateStatus.idle
    },

    clearObjPayment: (state) => {
      state.objPayment = {}
    },

    updStep: (state, action) => {
      state.stepActive = action.payload

      if (action.payload + 1 > state.maxStep) state.maxStep = action.payload
    },

    setShowAddress: (state, action) => {
      state.isAddress = action.payload
    },

    updMaxStep: (state, action) => {
      state.maxStep = action.payload
    },

    updData: (state, action) => {
      state.data = {
        ...state.data,
        ...action.payload,
      }
    },

    updPlanIdentifier: (state, action) => {
      state.updPlanIdentifier = action.payload
    },

    resetData: (state) => {
      state.data = {}
      state.maxStep = 1
      state.stepActive = 1
    },

    setPaymentStatusToLoading: (state) => {
      state.status.sendPayment = StateStatus.loading
    },

    resetStatusCoupon: (state) => {
      state.status.verifyCoupon = StateStatus.idle
    },
  },
  extraReducers: {
    [getSubscription.pending]: (state) => {
      state.status.getSubscription = StateStatus.loading
    },
    [getSubscription.fulfilled]: (state, action) => {
      if (
        (!state.data.coupon || Object.keys(state.data.coupon).length < 1) &&
        Object.keys(action.payload.data).includes("coupon")
      ) {
        state.data.coupon = action.payload.data.coupon
      }

      state.objPayment = action.payload.data
      state.planIdentifier = state.objPayment.plan_identifier
      state.planData = {
        identifier: state.objPayment.plan.identifier,
        name: state.objPayment.plan.name,
        price: state.objPayment.price,
        proposals_limit: state.objPayment.plan.proposals_limit,
      }
      state.data.coupon = state.objPayment.coupon
      state.status.getSubscription = StateStatus.succeeded
    },
    [getSubscription.rejected]: (state, action) => {
      state.msg = action.error.message
      state.status.getSubscription = StateStatus.failed
    },
    // STEP 1
    [sendAccount.pending]: (state) => {
      state.status.saveAccount = StateStatus.loading
    },
    [sendAccount.fulfilled]: (state) => {
      state.status.saveAccount = StateStatus.succeeded
    },
    [sendAccount.rejected]: (state, action) => {
      state.msg = action.error.message
      state.status.saveAccount = StateStatus.failed
    },
    [updAccount.pending]: (state) => {
      state.status.saveAccount = StateStatus.loading
    },
    [updAccount.fulfilled]: (state) => {
      state.status.saveAccount = StateStatus.succeeded
    },
    [updAccount.rejected]: (state, action) => {
      state.msg = action.error.message
      state.status.saveAccount = StateStatus.failed
    },
    // STEP 2
    [getAddress.pending]: (state, action) => {
      state.status.getAddress = StateStatus.succeeded
    },
    [getAddress.fulfilled]: (state, action) => {
      const address = action.payload.data.items[0]

      if (address) {
        address.cep = cepMask(address.cep)
        state.data.address = address
      }
      state.status.getAddress = StateStatus.succeeded
    },
    [getAddress.rejected]: (state, action) => {
      state.data.address = {}
      state.status.getAddress = StateStatus.failed
    },
    [updAddress.pending]: (state) => {
      state.status.saveAddress = StateStatus.loading
    },
    [updAddress.fulfilled]: (state, action) => {
      state.data.address = action.payload.data
      state.status.saveAddress = StateStatus.succeeded
    },
    [updAddress.rejected]: (state, action) => {
      state.msg = action.error.message
      state.status.saveAddress = StateStatus.failed
    },

    [sendAddress.pending]: (state) => {
      state.status.saveAddress = StateStatus.loading
    },
    [sendAddress.fulfilled]: (state, action) => {
      state.data.address = action.payload.data
      state.status.saveAddress = StateStatus.succeeded
    },
    [sendAddress.rejected]: (state, action) => {
      state.msg = action.error.message
      state.status.saveAddress = StateStatus.failed
    },
    // STPE 3
    [sendPayment.pending]: (state) => {
      state.status.sendPayment = StateStatus.loading
    },
    [sendPayment.fulfilled]: (state) => {
      state.status.sendPayment = StateStatus.succeeded
    },
    [sendPayment.rejected]: (state, action) => {
      state.msg = action.error.message
      state.status.sendPayment = StateStatus.failed
    },
    // COUPON
    [verifyCoupon.pending]: (state) => {
      state.status.verifyCoupon = StateStatus.loading
    },
    [verifyCoupon.fulfilled]: (state, action) => {
      state.data.coupon = action.payload.data
      state.status.verifyCoupon = StateStatus.succeeded
    },
    [verifyCoupon.rejected]: (state, action) => {
      // state.msg = action.error.message
      state.msg = null
      state.status.verifyCoupon = StateStatus.failed
    },

    // plan
    [getPlanDetails.pending]: (state) => {
      state.status.getPlanDetails = StateStatus.loading
    },
    [getPlanDetails.fulfilled]: (state, action) => {
      const payload = action.payload
      let plan = {}

      if (payload.items)
        plan = payload.items.find(
          (item) => item.identifier === payload.planIdentifier
        )

      state.planData = {
        identifier: plan?.identifier,
        name: plan?.name,
        price: plan?.price,
        proposals_limit: plan?.proposals_limit,
      }
      state.planIdentifier = payload.planIdentifier
      state.status.getPlanDetails = StateStatus.succeeded
    },
    [getPlanDetails.rejected]: (state, action) => {
      state.msg = action.error.message
      state.status.getPlanDetails = StateStatus.failed
    },
  },
})

export const {
  resetData,
  setData,
  updData,
  updStep,
  updMaxStep,
  resetStatus,
  clearObjPayment,
  updPlanIdentifier,
  setPaymentStatusToLoading,
  resetStatusCoupon,
  setShowAddress,
} = registerSlice.actions

export const selectRegister = (state) => state.register
