import React, { useState } from "react"
import PropTypes from "prop-types"
import ErrorMessage from "../../../../common/components/ErrorMessage"
import FlattedInput from "../../../../common/components/FlattedInput"
import { Form } from "antd"
import { findAccount } from "../../proposalService"
import { useSelector } from "react-redux"
import { selectProfileData } from "../../../configurations/configurationsSlice"

const InputAccountEmail = ({ form, name, hasSearchAccount, ...props }) => {
  const [job, updateJob] = useState()
  const [accExists, updateAccExists] = useState(true)
  const profile = useSelector(selectProfileData)

  const onSearch = (value) => {
    findAccount({ email: value })
      .then(() => {
        updateAccExists(true)
      })
      .catch(() => {
        updateAccExists(false)
      })
  }

  const onKeyUp = (event) => {
    if (job) clearTimeout(job)

    const value = event.target.value
    if (form.getFieldError(name).length === 0) {
      if (value.length === 0 || value.length >= 3) {
        const newJob = setTimeout(() => {
          onSearch(value)
        }, 600)

        updateJob(newJob)
      }
    } else {
      updateAccExists(true)
    }
  }

  return (
    <>
      <Form.Item
        {...props}
        name={name}
        rules={[
          {
            required: true,
            message: "Campo obrigatório",
          },
          {
            type: "email",
            message: "E-mail inválido",
          },
          () => ({
            validator(_, value) {
              if (value && value.length > 1) {
                if (
                  value.trim().toLocaleLowerCase() ===
                  profile.email.trim().toLocaleLowerCase()
                ) {
                  return Promise.reject(
                    new Error("E-mail deve ser diferente do e-mail seu usuário")
                  )
                }
              }

              return Promise.resolve()
            },
          }),
        ]}
      >
        <FlattedInput
          {...props}
          type="email"
          onKeyUp={hasSearchAccount ? onKeyUp : null}
          onBlur={() => {
            if (hasSearchAccount) onSearch(form.getFieldValue(name))
          }}
        />
      </Form.Item>
      <ErrorMessage
        text={
          "Usuário não cadastrado no Propozall. Você poderá prosseguir, mas certifique-se de conferir o e-mail inserido."
        }
        isVisible={!accExists}
      />
    </>
  )
}

InputAccountEmail.defaultProps = {
  hasSearchAccount: false,
}

InputAccountEmail.propTypes = {
  form: PropTypes.object,
  name: PropTypes.string,
  hasSearchAccount: PropTypes.bool,
}

export default InputAccountEmail
