import React from "react"
import "./styles.scss"
import PropTypes from "prop-types"
import Icons from "../Icons"

const BreadMessage = ({ type, text, isVisible, ...props }) => {
  const getIcon = (type) => {
    switch (type) {
      case "alert":
        return <Icons icon="circle-alert" />
      case "success":
        return <Icons icon="circle-check" />
      case "error":
        return <Icons icon="alert" />
    }
  }

  return (
    <div
      className={`bread-message bread-message-${type}`}
      data-visible={isVisible}
      {...props}
    >
      <span>{getIcon(type)}</span>
      <span>{text}</span>
    </div>
  )
}

BreadMessage.defaultProps = {
  type: "alert",
  isVisible: true,
}

BreadMessage.propTypes = {
  type: PropTypes.oneOf(["alert", "error", "success"]).isRequired,
  text: PropTypes.string,
  isVisible: PropTypes.bool,
}

export default BreadMessage
