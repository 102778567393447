import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  listCompanies,
  selectCompaniesData,
  selectConfigState,
  searchCompanies,
  removeCompany,
  resetActionsStatus,
  favoriteCompany,
} from "../../configurationsSlice"
import StateStatus from "../../../../utils/stateStatus"
//
import { Row, Col } from "antd"
import Icons from "../../../../common/components/Icons"
import Search from "../../../../common/components/Search"
import BaseModal from "../../../../common/components/Modal"
import Loading from "../../../../common/components/Loading"
import { PlainButton } from "../../../../common/components/Button"
import { FavoriteIcon } from "../../../../common/components/FavoriteIcon"
//
import { CreateCompanyModal } from "../../../company/company"
import { beautifyCpfCnpj } from "../../../../utils/formaters"
//
import emptyImg from "../../../../common/assets/images/cnpj.png"
import "./styles.scss"

const CompaniesList = () => {
  const [showCreateModal, updateShowCreateModal] = useState(false)
  const [company, updateCompany] = useState({})
  const [showErrorModal, updateShowErrorModal] = useState(false)
  const [showDeleteModal, updateShowDeleteModal] = useState(false)
  const configState = useSelector(selectConfigState)
  const companies = useSelector(selectCompaniesData)
  const dispatch = useDispatch()

  useEffect(() => {
    document.body.style.overflow = "auto"
    dispatch(listCompanies({ filter: companies.filter }))
  }, [])

  useEffect(() => {
    switch (configState.status.listCompanies) {
      case StateStatus.idle:
        dispatch(listCompanies({ filter: companies.filter }))
        break
      case StateStatus.failed:
        updateShowErrorModal(true)
    }
  }, [configState.status.listCompanies])

  useEffect(() => {
    switch (configState.status.removeCompany) {
      case StateStatus.succeeded:
        dispatch(searchCompanies(companies.filter))
        break
      case StateStatus.failed:
        updateShowErrorModal(true)
        dispatch(resetActionsStatus())
        break
    }
  }, [configState.status.removeCompany])

  const firstLoading =
    (!companies.items || companies.items.length === 0) &&
    (configState.status.listCompanies === StateStatus.idle ||
      configState.status.listCompanies === StateStatus.loading)
  const loading = configState.status.listCompanies === StateStatus.loading
  const nodata =
    companies.items &&
    companies.items.length === 0 &&
    companies.filter === "" &&
    configState.status.listCompanies === StateStatus.succeeded

  return (
    <>
      <div className="config-tab-content">
        <div className={`root-content ${firstLoading ? "" : "visible"}`}>
          <div>
            <div className="normal-16-500">Lista de seus CNPJ cadastrados</div>
            <div className="normal-12">
              Consulte, edite, favorite ou adicione um CNPJ para deixar em sua
              lista.
            </div>
          </div>
          <Row justify="space-between">
            <Search
              placeholder="Pesquisar"
              onSearch={(value) => dispatch(searchCompanies(value))}
              delay={1}
            />
            <PlainButton
              icon={<Icons icon="plus" />}
              onClick={() => {
                updateCompany({})
                updateShowCreateModal(true)
              }}
            >
              Adicionar CNPJ
            </PlainButton>
          </Row>

          {!nodata && (
            <Row
              justify="space-between"
              gutter={[16, 16]}
              className="normal-14-500 header-row"
            >
              <Col span={6}>CNPJ</Col>
              <Col span={10}>Razão Social</Col>
              <Col span={6}>Favoritar</Col>
              <Col span={2} style={{ textAlign: "center" }}>
                Ações
              </Col>
            </Row>
          )}

          {loading ? (
            <Loading />
          ) : (
            companies.items &&
            companies.items.map((item, index) => (
              <Row
                key={index}
                justify="space-between"
                gutter={[16, 16]}
                className="normal-14 body-row"
                align="middle"
              >
                <Col span={6}>{beautifyCpfCnpj(item.cnpj)}</Col>
                <Col span={10}>{item.name}</Col>
                <Col span={6}>
                  <Row justify="start">
                    <FavoriteIcon
                      isFavorite={item.is_favorite}
                      onChange={(fav) => dispatch(favoriteCompany(item.id))}
                    />
                  </Row>
                </Col>
                <Col span={2}>
                  <Row justify="space-around">
                    <div
                      className="edit-button"
                      onClick={() => {
                        updateCompany(item)
                        updateShowCreateModal(true)
                      }}
                    >
                      <Icons icon="edit" title="Editar" />
                    </div>
                    <div
                      className="delete-button"
                      onClick={() => {
                        updateCompany(item)
                        updateShowDeleteModal(true)
                      }}
                    >
                      <Icons icon="delete" title="Excluir" />
                    </div>
                  </Row>
                </Col>
              </Row>
            ))
          )}
        </div>

        {firstLoading ? (
          <Loading />
        ) : (
          nodata && (
            <div className="empty-card">
              <img
                src={emptyImg}
                alt="imagem para simbolizar que a lista está vazia"
              />
              <span className="normal-14-400">
                Você não possui CNPJ cadastrado
              </span>
            </div>
          )
        )}
      </div>
      {showCreateModal && (
        <CreateCompanyModal
          data={company}
          onSaved={(data) => {
            dispatch(searchCompanies(companies.filter))
            updateShowCreateModal(false)
          }}
          onCancel={() => {
            updateCompany({})
            updateShowCreateModal(false)
          }}
        />
      )}
      <BaseModal
        isVisible={showErrorModal}
        title="Erro!"
        okButtonText="Ok"
        onOk={() => {
          updateShowErrorModal(false)
        }}
      >
        {configState.errMessage}
      </BaseModal>
      <BaseModal
        isVisible={showDeleteModal}
        title={"Excluir CNPJ"}
        isLoading={configState.status.removeCompany === StateStatus.loading}
        okButtonText={"Sim"}
        okButtonColor="red"
        onOk={() => {
          dispatch(removeCompany(company.id)).then(() => {
            updateShowDeleteModal(false)
          })
        }}
        cancelButtonText={"Não"}
        onCancel={() => {
          updateShowDeleteModal(false)
        }}
      >
        <Row justify="center" gutter={[8, 8]}>
          <Col span={24} style={{ textAlign: "center" }}>
            Tem certeza que deseja excluir este CNPJ?
          </Col>
          {company && (
            <Col span={24} style={{ textAlign: "center" }}>
              {beautifyCpfCnpj(company.cnpj)}
              <br />
              Razão Social: {company.name}
            </Col>
          )}
        </Row>
      </BaseModal>
    </>
  )
}

export default CompaniesList
