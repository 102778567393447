import React from "react"

export const ExitIcon = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        width="24px"
        height="24px"
        viewBox="0 0 14 14"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g
          id="propozall-exit-icon"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="propozall-exit-icon-1"
            transform="translate(-25.000000, -645.000000)"
            fill="#000000"
          >
            <g
              id="propozall-exit-icon-2"
              transform="translate(20.000000, 640.000000)"
            >
              <path d="M10.5144444,14.7922222 L11.6111111,15.8888889 L15.5,12 L11.6111111,8.11111111 L10.5144444,9.20777778 L12.5211111,11.2222222 L5,11.2222222 L5,12.7777778 L12.5211111,12.7777778 L10.5144444,14.7922222 Z M17.4444444,5 L6.55555556,5 C5.69222222,5 5,5.7 5,6.55555556 L5,9.66666667 L6.55555556,9.66666667 L6.55555556,6.55555556 L17.4444444,6.55555556 L17.4444444,17.4444444 L6.55555556,17.4444444 L6.55555556,14.3333333 L5,14.3333333 L5,17.4444444 C5,18.3 5.69222222,19 6.55555556,19 L17.4444444,19 C18.3,19 19,18.3 19,17.4444444 L19,6.55555556 C19,5.7 18.3,5 17.4444444,5 Z"></path>
            </g>
          </g>
        </g>
      </svg>
    </span>
  )
}
