import React from "react"

export const ContractorIcon = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3 21V7.125H7.125V3H16.875V11.25H21V21H13.325V16.875H10.675V21H3ZM4.5 19.5H7.125V16.875H4.5V19.5ZM4.5 15.375H7.125V12.75H4.5V15.375ZM4.5 11.25H7.125V8.625H4.5V11.25ZM8.625 15.375H11.25V12.75H8.625V15.375ZM8.625 11.25H11.25V8.625H8.625V11.25ZM8.625 7.125H11.25V4.5H8.625V7.125ZM12.75 15.375H15.375V12.75H12.75V15.375ZM12.75 11.25H15.375V8.625H12.75V11.25ZM12.75 7.125H15.375V4.5H12.75V7.125ZM16.875 19.5H19.5V16.875H16.875V19.5ZM16.875 15.375H19.5V12.75H16.875V15.375Z"
          fill="#002999"
        />
      </svg>
    </span>
  )
}
