import React from "react"

export const ContractStatusDraftIcon = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        width="30"
        height="30"
        viewBox="0 0 49 49"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22 11H12.6667C11.9594 11 11.2811 11.281 10.781 11.781C10.281 12.2811 10 12.9594 10 13.6667V35C10 35.7072 10.281 36.3855 10.781 36.8856C11.2811 37.3857 11.9594 37.6667 12.6667 37.6667H28.6667C29.3739 37.6667 30.0522 37.3857 30.5523 36.8856C31.0524 36.3855 31.3333 35.7072 31.3333 35V20.3333V14C31.3333 12.3431 29.9902 11 28.3333 11H22Z"
          stroke="#141414"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.5 22.3333H19.7941H24.5"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M14.5 19.3333H19H23"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M35 17.8855C35.1751 17.7104 35.383 17.5715 35.6117 17.4768C35.8405 17.382 36.0857 17.3333 36.3333 17.3333C36.581 17.3333 36.8262 17.382 37.0549 17.4768C37.2837 17.5715 37.4916 17.7104 37.6667 17.8855C37.8418 18.0606 37.9807 18.2685 38.0754 18.4973C38.1702 18.726 38.219 18.9712 38.219 19.2189C38.219 19.4665 38.1702 19.7117 38.0754 19.9405C37.9807 20.1692 37.8418 20.3771 37.6667 20.5522L28.6667 29.5522L25 30.5522L26 26.8855L35 17.8855Z"
          fill="#FAFAFA"
          stroke="#141414"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14 34.146C15.1219 33.2363 15.6945 31.2463 15.9662 29.8695C16.1612 28.8816 16.3594 27.8012 16.3594 26.7891C16.3594 26.0264 16.3734 26.3157 16.4332 26.9611C16.5116 27.808 16.5069 28.6517 16.5069 29.5008C16.5069 30.6431 16.4465 31.8023 16.6462 32.9007C16.6873 33.1269 16.6544 33.3831 16.6544 33.6135C16.6544 33.9608 16.8898 32.9562 16.9493 32.614C17.1445 31.4918 17.4743 33.5296 18.2437 33.5561C18.779 33.5746 19.0206 33.0634 19.4235 32.8188C19.6821 32.6617 19.9599 32.9771 20.1935 33.0809C20.5569 33.2424 21.061 32.7218 21.3405 32.5566C21.8888 32.2326 22.0715 32.6599 22.553 32.9007C23.2393 33.2438 24.0717 32.6713 24.765 32.6713"
          stroke="black"
          strokeWidth="1.7"
          strokeLinecap="round"
        />
      </svg>
    </span>
  )
}
