import React from "react"

export const ExcelIcon = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        width="18"
        height="19"
        viewBox="0 0 18 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="2.9"
          y="1.4"
          width="14.2"
          height="16.2"
          rx="1.1"
          stroke="#676767"
          strokeWidth="1.8"
        />
        <rect y="5.5" width="9" height="9" rx="1" fill="#676767" />
        <path
          d="M5.654 12.5L4.745 11.231L4.025 12.5H2.09L3.773 9.881L2.018 7.451H3.998L4.907 8.72L5.636 7.451H7.571L5.852 10.025L7.634 12.5H5.654Z"
          fill="white"
        />
        <line
          x1="8.65"
          y1="7.85"
          x2="14.35"
          y2="7.85"
          stroke="#676767"
          strokeWidth="1.3"
          strokeLinecap="round"
        />
        <line
          x1="8.65"
          y1="9.85"
          x2="14.35"
          y2="9.85"
          stroke="#676767"
          strokeWidth="1.3"
          strokeLinecap="round"
        />
        <line
          x1="8.65"
          y1="11.85"
          x2="14.35"
          y2="11.85"
          stroke="#676767"
          strokeWidth="1.3"
          strokeLinecap="round"
        />
      </svg>
    </span>
  )
}
