import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import StateStatus from "../../utils/stateStatus"
import { confirmEmail, resendConfirmEmail } from "./confirmAccountService"

export const confirmAccountEmail = createAsyncThunk(
  "confirm-account/confirm-email",
  async ({ email, token }) => {
    const response = await confirmEmail({ email, token })
    return response.data
  }
)

export const resendConfirmAccountEmail = createAsyncThunk(
  "confirm-account/resend_confirm_account_email",
  async () => {
    const response = await resendConfirmEmail()
    return response.data
  }
)

const initialState = {
  status: {
    confirmAccountEmail: StateStatus.idle,
    resendConfirmAccountEmail: StateStatus.idle,
  },
  data: {},
  errMessage: null,
}

export const confirmAccountSlice = createSlice({
  name: "confirm-account",
  initialState,
  reducers: {
    resetConfirmAccountStatus: (state) => {
      state.status.confirmAccountEmail = StateStatus.idle
    },
    resetResendConfirmAccountEmail: (state) => {
      state.status.resendConfirmAccountEmail = StateStatus.idle
    },
  },
  extraReducers: {
    [confirmAccountEmail.pending]: (state) => {
      state.status.confirmAccountEmail = StateStatus.loading
    },
    [confirmAccountEmail.fulfilled]: (state, action) => {
      state.data = action.payload.data
      state.status.confirmAccountEmail = StateStatus.succeeded
    },
    [confirmAccountEmail.rejected]: (state, action) => {
      state.status.confirmAccountEmail = StateStatus.failed
      state.errMessage = action.error.message
    },
    [resendConfirmAccountEmail.pending]: (state) => {
      state.status.resendConfirmAccountEmail = StateStatus.loading
    },
    [resendConfirmAccountEmail.fulfilled]: (state, action) => {
      state.status.resendConfirmAccountEmail = StateStatus.succeeded
    },
    [resendConfirmAccountEmail.rejected]: (state, action) => {
      state.status.resendConfirmAccountEmail = StateStatus.failed
    },
  },
})

export const { resetConfirmAccountStatus, resetResendConfirmAccountEmail } =
  confirmAccountSlice.actions

export const selectConfirmAccountState = (state) => state.confirmAccount
