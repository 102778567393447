export const translateNFSErrorReason = (errorReason) => {
  switch (errorReason) {
    case "wrong_password":
      return "Não foi possível realizar login no sistema de NF-e com as credenciais informadas anteriormente."
    case "connection_problem":
      return "Houve um erro de conexão com o Portal da Prefeitura, aguarde novas tentativas ou comunique o suporte caso o erro persista."
    case "wrong_captcha":
      return "Não foi acessar o Portal da Prefeitura no momento, novas tentativas serão realizadas."
    case "general_error":
      return "Houve um erro inesperado, aguarde novas tentativas ou comunique o suporte caso o erro persista."
    case "no_permission":
      return "Seu acesso ao Portal da Prefeitura está bloqueado ou seu CNPJ ainda não possui acesso ao sistema."
    default:
      return null
  }
}
