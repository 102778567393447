import React, { useState } from "react"
import RoundedInput from "../RoundedInput"
import "./styles.scss"
import Icons from "../Icons"
import PropTypes from "prop-types"

const RoundedInputPassword = ({ onChange, ...props }) => {
  const [isPassword, updateIsPassword] = useState(true)

  const passwordValidation = (value) => {
    const result = {
      hasEigthLength: value.length >= 8,
      hasOneNumber: value.search(/\d/g) !== -1,
      hasOneCapitalLetter: value.search(/[A-Z]/g) !== -1,
      hasOneLowerLetter: value.search(/[a-z]/g) !== -1,
    }

    return result
  }

  return (
    <div className="input-container">
      <RoundedInput
        type={isPassword ? "password" : "text"}
        onChange={(event) => {
          const result = passwordValidation(event.target.value)
          onChange(event, result)
        }}
        {...props}
      />
      {isPassword ? (
        <Icons
          icon="eye-closed"
          data-testid="ip-show-icon"
          onClick={() => {
            updateIsPassword(!isPassword)
          }}
          className="icon-button"
        />
      ) : (
        <Icons
          icon="eye-opened"
          data-testid="ip-hide-icon"
          onClick={() => {
            updateIsPassword(!isPassword)
          }}
          className="icon-button"
        />
      )}
    </div>
  )
}

RoundedInputPassword.propTypes = {
  onChange: PropTypes.func,
}

export default RoundedInputPassword
