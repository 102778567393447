import React, { useEffect, useState } from "react"
import { Form, Row, Col } from "antd"
import BaseModal from "../../common/components/Modal"
import FlattedInput, {
  FlattedMaskedInput,
} from "../../common/components/FlattedInput"
import { useDispatch, useSelector } from "react-redux"
import { FavoriteIcon } from "../../common/components/FavoriteIcon"
import { cnpj } from "cpf-cnpj-validator"
import PropTypes from "prop-types"
import "./styles.scss"
import StateStatus from "../../utils/stateStatus"
import {
  createCompany,
  resetCompanyStatus,
  selectCompanyState,
  updateCompany,
} from "./companySlice"
import RadioButton from "../../common/components/RadioButton"

const { useForm } = Form

const CreateCompanyModal = ({ data, onSaved, onCancel }) => {
  const companyState = useSelector(selectCompanyState)
  const [company, updateCompanyData] = useState(data || {})
  const [showErrorModal, updateShowErrorModal] = useState(false)
  const [isFavorite, updateIsFavorite] = useState(company.is_favorite || false)
  const [phone, updatePhone] = useState()
  const [form] = useForm()
  const dispatch = useDispatch()

  useEffect(() => {
    switch (companyState.status.saveCompany) {
      case StateStatus.succeeded:
        updateCompanyData(companyState.data)
        onSaved(companyState.data)
        dispatch(resetCompanyStatus())
        break
      case StateStatus.failed:
        updateShowErrorModal(true)
        dispatch(resetCompanyStatus())
        break
    }
  }, [companyState.status.saveCompany])

  const saveCompany = (data) => {
    const companyId = data.id
    delete data.id

    if (companyId) {
      dispatch(updateCompany({ companyId, data }))
    } else {
      dispatch(createCompany(data))
    }

    data.id = company.id
    updateCompanyData(data)
  }

  return (
    <>
      <BaseModal
        isVisible={true}
        isLoading={companyState.status.saveCompany === StateStatus.loading}
        okButtonText={"Salvar"}
        cancelButtonText={"Cancelar"}
        onOk={() => {
          if (!phone) updatePhone({ isValid: false })
          form.submit()
        }}
        onCancel={() => {
          onCancel()
          form.resetFields()
        }}
        title={company.id ? "Alterar CNPJ" : "Adicionar CNPJ"}
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={(values) => {
            values.id = company.id
            values.is_favorite = isFavorite
            saveCompany(values)
          }}
        >
          <Row gutter={[16, 0]} justify="center">
            <Col span={24}>
              <Form.Item
                initialValue={company.cnpj}
                name="cnpj"
                label="CNPJ"
                rules={[
                  {
                    required: true,
                    message: <div className="normal-12">Campo obrigatório</div>,
                  },
                  () => ({
                    validator(_, value) {
                      if (
                        value &&
                        (cnpj.isValid(value) ||
                          value.replace(/[^0]/g, "").length === 14)
                      ) {
                        return Promise.resolve()
                      } else {
                        return Promise.reject(new Error())
                      }
                    },
                    message: <div className="normal-12">CNPJ inválido</div>,
                  }),
                ]}
              >
                <FlattedMaskedInput
                  disabled={!!company.id}
                  mask="00.000.000/0000-00"
                  placeholder="00.000.000/0001-00"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                initialValue={company.name}
                name="name"
                label="Razão Social"
                rules={[
                  {
                    required: true,
                    message: <div className="normal-12">Campo obrigatório</div>,
                  },
                ]}
              >
                <FlattedInput
                  placeholder="Ex.: Airfluencers"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <div className="favorite-item">
                <RadioButton
                  checked={isFavorite}
                  onClick={() => {
                    updateIsFavorite(!isFavorite)
                  }}
                >
                  Favoritar CNPJ
                </RadioButton>
                <FavoriteIcon />
              </div>
            </Col>
          </Row>
        </Form>
      </BaseModal>
      <BaseModal
        isVisible={showErrorModal}
        title="Erro!"
        okButtonText="Ok"
        onOk={() => {
          updateShowErrorModal(false)
        }}
      >
        {companyState.errMessage}
      </BaseModal>
    </>
  )
}

CreateCompanyModal.propTypes = {
  data: PropTypes.object,
  onSaved: PropTypes.func,
  onCancel: PropTypes.func,
}

export { CreateCompanyModal }
