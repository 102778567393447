import React from "react"

export const TagIcon = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M6.62513 4.63679C6.62513 5.16387 6.41575 5.66936 6.04305 6.04207C5.67034 6.41477 5.16485 6.62416 4.63776 6.62416C4.11068 6.62416 3.60518 6.41477 3.23248 6.04207C2.85977 5.66936 2.65039 5.16387 2.65039 4.63679C2.65039 4.1097 2.85977 3.60421 3.23248 3.2315C3.60518 2.8588 4.11068 2.64941 4.63776 2.64941C5.16485 2.64941 5.67034 2.8588 6.04305 3.2315C6.41575 3.60421 6.62513 4.1097 6.62513 4.63679ZM5.30022 4.63679C5.30022 4.46109 5.23042 4.29259 5.10619 4.16836C4.98195 4.04412 4.81346 3.97433 4.63776 3.97433C4.46207 3.97433 4.29357 4.04412 4.16933 4.16836C4.0451 4.29259 3.9753 4.46109 3.9753 4.63679C3.9753 4.81248 4.0451 4.98098 4.16933 5.10521C4.29357 5.22945 4.46207 5.29924 4.63776 5.29924C4.81346 5.29924 4.98195 5.22945 5.10619 5.10521C5.23042 4.98098 5.30022 4.81248 5.30022 4.63679Z" />
        <path d="M1.32491 0H7.40097C7.75233 7.50397e-05 8.08927 0.139713 8.33768 0.3882L17.6121 9.6626C17.8605 9.91106 18 10.248 18 10.5993C18 10.9506 17.8605 11.2876 17.6121 11.536L11.536 17.6121C11.2876 17.8605 10.9506 18 10.5993 18C10.248 18 9.91106 17.8605 9.6626 17.6121L0.3882 8.33768C0.139713 8.08927 7.50397e-05 7.75233 0 7.40097V1.32491C0 0.973525 0.139589 0.636528 0.388058 0.388058C0.636528 0.139589 0.973525 0 1.32491 0ZM1.32491 7.40097L10.5993 16.6754L16.6754 10.5993L7.40097 1.32491H1.32491V7.40097Z" />
      </svg>
    </span>
  )
}
