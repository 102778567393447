import React, { useEffect, useState } from "react"
import { Row, Form, Col, Divider } from "antd"
import { BlueButton, PlainButton } from "../../common/components/Button"
import moment from "moment"
import "moment/locale/pt-br"
import "./styles.scss"
import { LogoBar } from "../topbar/Topbar"
import { useDispatch, useSelector } from "react-redux"
import {
  getContract,
  resetErrorMessage,
  selectContractState,
  signContract,
} from "./contractSlice"
import StateStatus from "../../utils/stateStatus"
import Loading from "../../common/components/Loading"
import { useQuery } from "../../utils/query"
import FlattedInput, {
  FlattedMaskedInput,
} from "../../common/components/FlattedInput"
import BaseModal from "../../common/components/Modal"
import { useHistory } from "react-router-dom"
import { cpf } from "cpf-cnpj-validator"
import PDFViewer from "../../common/components/PDFViewer"
import { beautifyCpfCnpj } from "../../utils/formaters"

const Contract = ({ ...props }) => {
  moment.locale("pt-br")
  const [form] = Form.useForm()
  const [showModal, updateShowModal] = useState()
  const [currentPage, setCurrentPage] = useState(1)
  const [pdfInfo, setPdfInfo] = useState()
  const [pdfUrl, setPdfUrl] = useState()
  const [isRedirecting, setIsRedirecting] = useState(false)
  const contractState = useSelector(selectContractState)
  const contract = contractState.contract
  const dispatch = useDispatch()
  const history = useHistory()
  const query = useQuery()

  const documentId = query.get("document")
  const signerId = query.get("signer")
  const redirectUrl = query.get("redirect_url")

  const nextPage = () => {
    if (pdfInfo && currentPage < pdfInfo.numPages) {
      setCurrentPage(currentPage + 1)
    }
  }

  const prevPage = () => {
    if (pdfInfo && currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  const redirectToUrl = () => {
    if (redirectUrl) {
      setIsRedirecting(true)
      setTimeout(() => {
        // Delay adicionado para aguardar o webhook de assinatura digital
        // comunicar a API propozall
        updateShowModal(null)
        if (!redirectUrl.startsWith("http")) {
          window.location.href = `/#${redirectUrl}`
        } else {
          window.location.href = redirectUrl
        }
      }, 500)
    } else {
      updateShowModal(null)
      window.location.reload()
    }
  }

  useEffect(() => {
    dispatch(getContract({ documentId, signerId }))
  }, [documentId, signerId])

  useEffect(() => {
    if (contractState.status.getContract === StateStatus.succeeded) {
      setPdfUrl(contract.public_url)
      form.setFieldsValue({
        cpf: beautifyCpfCnpj(contract.signer?.cpf),
        name: contract.signer?.name,
      })
    }
  }, [contractState.status.getContract])

  useEffect(() => {
    if (contractState.status.signContract === StateStatus.succeeded) {
      updateShowModal("contract-was-signed")
    }

    if (contractState.status.signContract === StateStatus.failed) {
      updateShowModal(null)
    }
  }, [contractState.status.signContract])

  return (
    <div className="body-container body-container-contract">
      <div className="top-bar" style={{ padding: 0 }}>
        <LogoBar />
      </div>
      <div className="content">
        <Row justify="center">
          {contractState.status.getContract !== StateStatus.succeeded ? (
            <div className="proposal-contract">
              <Loading />
            </div>
          ) : (
            <div className="proposal-contract">
              <Row
                gutter={[0, 16]}
                justify="center"
                style={{ margin: "10px 0" }}
              >
                <Col
                  span={24}
                  className="pdf-view"
                  style={{ textAlign: "center" }}
                >
                  <div className="main-content-top"></div>
                  {pdfUrl && (
                    <PDFViewer
                      url={pdfUrl}
                      page={currentPage}
                      onLoad={(pdfInfo) => {
                        setPdfInfo({
                          numPages: pdfInfo.numPages,
                        })
                      }}
                    />
                  )}
                </Col>
                <Col span={24} style={{ textAlign: "center" }}>
                  <PlainButton
                    onClick={prevPage}
                    disabled={pdfInfo && currentPage <= 1}
                  >
                    Página Anterior
                  </PlainButton>
                  <Divider type={"vertical"} />
                  <PlainButton
                    onClick={nextPage}
                    disabled={pdfInfo && currentPage >= pdfInfo.numPages}
                  >
                    Página Seguinte
                  </PlainButton>
                </Col>
                <Col>
                  {contract.status === "waiting_to_sign" && (
                    <Row justify="end" className="actions-content">
                      <BlueButton
                        type="submit"
                        onClick={() => updateShowModal("sign-contract")}
                      >
                        Assinar como {contract.description}
                      </BlueButton>
                    </Row>
                  )}

                  {contract.status === "signed" && (
                    <PlainButton disabled={true}>
                      Você já assinou este contrato
                    </PlainButton>
                  )}
                </Col>
              </Row>
            </div>
          )}
        </Row>
      </div>
      <BaseModal
        title="Erro!"
        isVisible={contractState.errMessage != null}
        okButtonText="Ok"
        onOk={() => {
          history.replace("/")
          dispatch(resetErrorMessage())
        }}
      >
        {contractState.errMessage}
      </BaseModal>
      <BaseModal
        title="Documento assinado"
        type="success"
        isVisible={showModal === "contract-was-signed"}
        isLoading={isRedirecting}
        okButtonText="Ok"
        onClose={() => redirectToUrl()}
        onOk={() => redirectToUrl()}
      >
        O documento foi assinado com sucesso! Aguarde o andamento do contrato na
        tela inicial e pelo e-mail.{" "}
        {/* Você será redirecionado para o Propozall. */}
      </BaseModal>
      <BaseModal
        title="Assinar documento"
        isVisible={showModal === "sign-contract"}
        isLoading={contractState.status.signContract === StateStatus.loading}
        okButtonText="Assinar"
        cancelButtonText="Cancelar"
        onCancel={() => updateShowModal(null)}
        onOk={() => {
          form.submit()
        }}
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={(values) => {
            dispatch(signContract(values))
          }}
        >
          <Form.Item name="document_id" initialValue={documentId} noStyle>
            <input type="hidden" />
          </Form.Item>
          <Form.Item name="signer" initialValue={signerId} noStyle>
            <input type="hidden" />
          </Form.Item>
          <Row gutter={[16, 0]} justify="start">
            <Col sm={14}>
              <Form.Item
                name="cpf"
                label="CPF"
                rules={[
                  {
                    required: true,
                    message: <div className="normal-12">Campo obrigatório</div>,
                  },
                  () => ({
                    validator(_, value) {
                      if (cpf.isValid(value)) {
                        return Promise.resolve()
                      } else {
                        return Promise.reject(new Error())
                      }
                    },
                    message: <div className="normal-12">CPF inválido</div>,
                  }),
                ]}
              >
                <FlattedMaskedInput
                  mask="000.000.000-00"
                  placeholder="000.000.000-00"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col sm={14}>
              <Form.Item
                name="name"
                label="Nome Completo"
                rules={[
                  {
                    required: true,
                    message: <div className="normal-12">Campo obrigatório</div>,
                  },
                  () => ({
                    validator(_, value) {
                      if (value && value.trim().split(" ").length >= 2) {
                        return Promise.resolve()
                      } else {
                        return Promise.reject(new Error())
                      }
                    },
                    message: (
                      <div className="normal-12">Insira nome e sobrenome</div>
                    ),
                  }),
                ]}
              >
                <FlattedInput placeholder="Nome" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </BaseModal>
    </div>
  )
}

export default Contract
