import React from "react"
import BaseModal from "../Modal"
import "./styles.scss"
import PropTypes from "prop-types"
import { Col, Row } from "antd"
import Icons from "../Icons"

const ShareProposalModal = ({ isVisible, proposal, onClose }) => {
  return (
    <BaseModal
      isVisible={isVisible}
      title="Compartilhar Proposta"
      okButtonText="Ok"
      onOk={onClose}
      onCancel={onClose}
    >
      <Row justify="space-around" className="share-link">
        <Col span={22} className="normal-12">
          <p>{proposal && proposal.share_link}</p>
        </Col>
        <Col span={2}>
          <Icons
            title="Copiar link"
            icon="copy"
            onClick={async () => {
              if (proposal) {
                navigator.clipboard.writeText(proposal.share_link)
              }
            }}
          />
        </Col>
      </Row>
    </BaseModal>
  )
}

ShareProposalModal.propTypes = {
  isVisible: PropTypes.bool,
  proposal: PropTypes.object,
  onClose: PropTypes.func,
}

export default ShareProposalModal
