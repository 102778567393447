import { Col, Divider, Row } from "antd"
import React, { useEffect } from "react"
import PropTypes from "prop-types"
import "./styles.scss"
import Icons from "../../../../common/components/Icons"
import { updateAux } from "../../proposalSlice"
import { useDispatch } from "react-redux"
import { ProposalTypesEnum } from "../../../../utils/constants"
import { useParams } from "react-router-dom"

const SelectProposalType = ({ goNextPage }) => {
  const dispatch = useDispatch()
  const params = useParams()

  const proposalTypes = [
    {
      icon: "proposal-type-influencer",
      name: "Influência",
      description: "Se você trabalha com marketing de Influenciador Digital",
      type: ProposalTypesEnum.INFLUENCER,
    },
    {
      icon: "proposal-type-service-provider",
      name: "Prestador de Serviço",
      description:
        "Se você presta algum serviço ou quer contratar um prestador",
      type: ProposalTypesEnum.SERVICE_PROVIDER,
    },
    {
      icon: "proposal-type-simple",
      name: "Simplificada",
      description:
        "Independente do seu tipo de serviço, essa proposta só pede dados básicos",
      type: ProposalTypesEnum.SIMPLE,
    },
    {
      icon: "proposal-type-freela",
      name: "Freela",
      description: "Se você presta serviços de T.I, design e áreas afins",
      type: ProposalTypesEnum.FREELA,
    },
  ]

  useEffect(() => {
    if (params.id) goNextPage()
  }, [params])

  return (
    <div className="step">
      <Row className="select-proposal-type" justify="center" align="top">
        <Col span={24}>
          <div className="input-title">Qual o tipo da proposta?</div>
        </Col>
        <Col span={24}>
          {proposalTypes.map((item, index) => (
            <Row
              gutter={[8, 8]}
              align="top"
              key={index}
              className="card-select-type"
              onClick={() => {
                dispatch(updateAux({ type: item.type }))
                goNextPage()
              }}
            >
              <Col>
                <Icons icon={item.icon} />
              </Col>
              <Col>
                <div className="normal-14-500">{item.name}</div>
                <div className="normal-14">{item.description}</div>
              </Col>
              <Divider />
            </Row>
          ))}
        </Col>
      </Row>
    </div>
  )
}

SelectProposalType.propTypes = {
  goNextPage: PropTypes.func,
}

export default SelectProposalType
