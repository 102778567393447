import React, { useEffect, useState } from "react"
import Icons from "../Icons"
import PropTypes from "prop-types"
import "./styles.scss"
import { Col, Row } from "antd"

const InputEditLabel = ({ onChange, value, ...props }) => {
  const [isDisabled, setIsDisabled] = useState(true)
  const [width, setWidth] = useState(190)
  const [maxWidth] = useState(500)

  const changeWidth = (value) => {
    if (value && value.length * 11 < maxWidth) {
      setWidth(value.length * 11)
    }
  }
  useEffect(() => {
    changeWidth(value)
  }, [])

  return (
    <Row className="input-edit-label-container">
      <Col>
        <input
          data-testid="input-edit-label"
          className="input-edit-label"
          disabled={isDisabled}
          value={value}
          {...props}
          style={{ width: `${width}px` }}
          onChange={(event) => {
            if (onChange) onChange(event)
          }}
          onKeyUp={(event) => {
            changeWidth(event.target.value)
          }}
          onPointerEnter={(event) => {
            setIsDisabled(false)
          }}
          onBlur={() => {
            setIsDisabled(true)
          }}
        />
      </Col>
      <Col>
        <Icons
          icon="pencil"
          disabled={!isDisabled}
          onClick={() => {
            if (isDisabled) {
              const elem =
                document.getElementsByClassName("input-edit-label")[0]
              elem.focus()
            }
            setIsDisabled(!isDisabled)
          }}
        />
      </Col>
    </Row>
  )
}

InputEditLabel.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
}

export default InputEditLabel
