import React, { useEffect, createRef, useState } from "react"
import { useHistory } from "react-router-dom"
//
import { useDispatch, useSelector } from "react-redux"
import {
  listContracts,
  selectContractsState,
  updateEndDate,
  updatePage,
  updateSearch,
  updateStartDate,
} from "./contractHistorySlice"
import { selectDashSlice } from "../dashboard/DashboardSlice"
import StateStatus from "../../utils/stateStatus"
//
import { Dropdown, Col, Row, Table, Pagination } from "antd"
import { DatePicker } from "../../common/components/DatePicker"
import Icons from "../../common/components/Icons"
import Search from "../../common/components/Search"
import RoudedBox from "../../common/components/RoudedBox"
import DownloadContractModal from "../listProposals/components/dowloadContratoModal"
//
import { formatDate, numberToReal } from "../../utils/formaters"
//
import emptyImg from "../../common/assets/images/contratos.png"
import "./styles.scss"

const contractHistory = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const slice = useSelector(selectContractsState)
  const dash = useSelector(selectDashSlice)
  //
  const role = dash.mode?.toUpperCase()
  //
  const inputStartDate = createRef(null)
  const inputEndDate = createRef(null)
  //
  const [start, updateStart] = useState()
  const [end, updateEnd] = useState()
  const [contract, updateContract] = useState(null)
  const [showModal, updateShowModal] = useState(false)

  const columns = [
    {
      title: <div className="normal-14-500">DATA</div>,
      // dataIndex: "date",
      // key: "date",
      render: function Cell(item) {
        return <div className="normal-14">{item.created_at}</div>
      },
    },
    {
      title: <div className="normal-14-500">EMPRESA</div>,
      // dataIndex: "brand",
      // key: "brand",
      render: function Cell(item) {
        return (
          <div>
            {role === "CONTRACTOR"
              ? item.proposal.contracted.company_name
              : item.proposal.contractor.company_name}
          </div>
        )
      },
    },
    {
      title: <div className="normal-14-500">PROPOSTA</div>,
      // dataIndex: "company",
      // key: "company",
      render: function Cell(item) {
        return <div className="normal-14">{item.proposal.title}</div>
      },
    },
    {
      title: (
        <div className="normal-14-500">
          {role === "CONTRACTOR" ? "CONTRATADO" : "CONTRATANTE"}
        </div>
      ),
      // dataIndex: "contracted",
      // key: "contracted",
      render: function Cell(item) {
        return (
          <div className="normal-14-500">
            {role === "CONTRACTOR"
              ? item.proposal.contracted.name
              : item.proposal.contractor.name}
          </div>
        )
      },
    },
    {
      title: <div className="normal-14-500">VALOR</div>,
      // dataIndex: "value",
      // key: "value",
      render: function Cell(item) {
        return (
          <div className="normal-14-500">
            {numberToReal(item.proposal.total_value / 100)}
          </div>
        )
      },
    },
    {
      title: <div className="normal-14-500"> AÇÕES </div>,
      // dataIndex: "actions",
      // key: "value",
      render: function Cell(item) {
        return (
          <div className="actions">
            <div
              className="show-button"
              onClick={() => {
                updateShowModal("show-contract")
                updateContract(item)
              }}
            >
              <Icons icon="show" title="Ver PDF" />
            </div>
            <div
              className="signature-button"
              onClick={() => {
                history.push(`/dashboard/contract-history/${item.id}`)
              }}
            >
              <Icons icon="signature" title="Ver assinaturas" />
            </div>
            <div
              className="download-button"
              onClick={() => {
                updateShowModal("download-contract")
                updateContract(item)
              }}
            >
              <Icons icon="download" title="Baixar" />
            </div>
          </div>
        )
      },
    },
  ]

  const clickStartDate = () => inputStartDate.current.click()

  const clickEndDate = () => inputEndDate.current.click()

  useEffect(() => {
    dispatch(listContracts({ role }))
  }, [slice.search, slice.startDate, slice.endDate, slice.page])

  return (
    <div className="root-container contract-history-page">
      {/* <LoadingCarousel /> */}
      <h1 className="normal-24-500">Histórico de Contratos</h1>
      <p className="normal-18">
        Confira o histórico de contratos, veja as assinaturas e baixe o
        documento.
      </p>

      <Row gutter={[24, 24]} justify="space-between">
        <Col span={24}>
          <RoudedBox className="filter-box">
            <span className="normal-16-500">Filtros</span>

            <div className="filter-date-box">
              <span className="normal-16-500">Período: </span>

              <Dropdown
                overlay={
                  <DatePicker
                    width="350px"
                    className="floating"
                    date={slice.startDate}
                    maxDate={end}
                    onChange={(date) => {
                      updateStart(date)
                      dispatch(updateStartDate(formatDate(date)))

                      // if(!end) clickEndDate()
                    }}
                  />
                }
                trigger={["click"]}
                placement="bottomRight"
              >
                <button ref={inputStartDate} />
              </Dropdown>
              <span className="text-date" onClick={() => clickStartDate()}>
                {slice.startDate || "--/--/--"}
              </span>

              <span className="text-date text-a">à</span>

              <Dropdown
                overlay={
                  <DatePicker
                    className="floating"
                    date={slice.endDate}
                    minDate={start}
                    onChange={(date) => {
                      updateEnd(date)
                      dispatch(updateEndDate(formatDate(date)))

                      // if(!startDateFormated) clickStartDate()
                    }}
                  />
                }
                trigger={["click"]}
                placement="bottomRight"
              >
                <button ref={inputEndDate} />
              </Dropdown>
              <span className="text-date" onClick={() => clickEndDate()}>
                {slice.endDate || "--/--/--"}
              </span>

              <Icons
                icon="calendar"
                onClick={() => {
                  if (!start || (start && end)) {
                    clickStartDate()
                  } else {
                    clickEndDate()
                  }
                }}
              />
            </div>

            <Search
              delay={0.3}
              placeholder="Pesquisar"
              onClick={() => {}}
              onSearch={(text) => {
                dispatch(updateSearch(text))
              }}
            />
          </RoudedBox>
        </Col>
      </Row>

      {slice.status.listContracts === StateStatus.succeeded &&
        slice.list.length < 1 && (
          <div className="empty-card">
            <img
              src={emptyImg}
              alt="imagem para simbolizar que a lista está vazia"
            />
            <span className="normal-14-400">Você não possui contratos</span>
          </div>
        )}

      {slice.totalItems > 0 && (
        <Table
          pagination={false}
          dataSource={slice.list ?? []}
          loading={slice.status.listContracts === StateStatus.loading}
          columns={columns}
          locale={{
            emptyText: "   ",
          }}
        />
      )}

      {slice.totalItems > 6 && (
        <Pagination
          current={slice.page}
          total={slice.totalItems}
          pageSize={6}
          onChange={(page) => dispatch(updatePage(page))}
        />
      )}

      {slice.status.listContracts === StateStatus.failed && (
        <p className="">{slice.errMessage}</p>
      )}

      {contract ? (
        <DownloadContractModal
          contractId={contract.id}
          hasSignedContract={["signed", "finalized"].includes(contract.status)}
          isShowContract={showModal === "show-contract"}
          isVisible={
            showModal === "download-contract" || showModal === "show-contract"
          }
          onClose={() => updateShowModal(null)}
        />
      ) : null}
    </div>
  )
}

export default contractHistory
