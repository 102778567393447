import React, { useEffect } from "react"
import PropTypes from "prop-types"
//
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import {
  resetStatus,
  selectRegister,
  sendPayment,
  setPaymentStatusToLoading,
  updData,
} from "../../RegisterSlice"
//
import { Form } from "antd"
import Input, { SimpleMaskedInput } from "../../../../common/components/Input"
//
import StateStatus from "../../../../utils/stateStatus"
//
import "./styles.scss"
import { createCreditCardToken } from "../../../../utils/iugu"

const PaymentForm = ({ rel }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const register = useSelector(selectRegister)

  const onFinish = async (values) => {
    dispatch(updData(values))
    if (
      values.validity &&
      values.card_name &&
      values.card_number &&
      values.cvv
    ) {
      try {
        const token = await createCreditCardToken({
          cardNumber: values.card_number,
          cardName: values.card_name,
          validity: values.validity,
          cvv: values.cvv,
        })

        // force button to change state to loading
        dispatch(setPaymentStatusToLoading())
        dispatch(sendPayment({ token }))
      } catch (err) {
        console.error(err)
        alert(err.message)
      }
    }
  }

  useEffect(() => {
    if (register.status.sendPayment === StateStatus.succeeded) {
      dispatch(resetStatus())
      history.push("/register/4")
    }
  }, [register.status.sendPayment])

  useEffect(() => {
    window.Iugu.setup()
  }, [])

  return (
    <>
      <div className="step-about">
        <h1 className="form-title">Método de pagamento</h1>
        <h2 className="form-subtitle">
          Selecione o método de pagamento e preencha com seus dados.
        </h2>
        <span className="form-observation">
          Itens com (*) são obrigatórios.{" "}
        </span>
      </div>

      <Form
        form={rel}
        onFinish={onFinish}
        layout="vertical"
        className="form-payment"
      >
        {/* card number */}
        <Form.Item
          label="Número do cartão de crédito *"
          name="card_number"
          initialValue={rel.getFieldValue("card_number")}
          rules={[
            {
              required: true,
              message: <div className="normal-12">Campo obrigatório</div>,
            },
            () => ({
              validator(_, val) {
                if (!val || !window.Iugu.utils.validateCreditCardNumber(val)) {
                  return Promise.reject(new Error("Informe um cartão válido"))
                }

                return Promise.resolve()
              },
            }),
          ]}
        >
          <SimpleMaskedInput
            mask={[
              {
                mask: "0000 0000 0000 0000",
                lazy: true,
              },
            ]}
            placeholder="Digite o número do cartão"
          />
        </Form.Item>

        {/* card name */}
        <Form.Item
          label="Nome do titular *"
          name="card_name"
          initialValue={register.data.card_name}
          rules={[
            {
              required: true,
              message: <div className="normal-12">Campo obrigatório</div>,
            },
            () => ({
              validator(_, val) {
                if (!val) {
                  return Promise.reject(new Error("Informe seu nome"))
                }

                const names = val.split(" ")

                if (
                  names.length < 2 ||
                  names[0].replace(/\s/g, "") === "" ||
                  names[1].replace(/\s/g, "") === ""
                ) {
                  return Promise.reject(
                    new Error("Informe o nome e o sobrenome")
                  )
                }

                return Promise.resolve()
              },
            }),
          ]}
        >
          <Input
            placeholder="Digite o nome do titular"
            className="input-card-name"
          />
        </Form.Item>

        {/* validation */}
        <div className="junction-validity">
          <Form.Item
            name="cvv"
            label="Código de segurança (CVV) *"
            initialValue={register.data.cvv}
            rules={[
              {
                required: true,
                message: <div className="normal-12">Campo obrigatório</div>,
              },
              () => ({
                validator(_, val) {
                  const value = (val ?? "").replace(/\D/g, "")
                  if (!value || value.length < 3 || value.length > 4) {
                    return Promise.reject(new Error("CVV inválido"))
                  }
                  return Promise.resolve()
                },
              }),
            ]}
          >
            <Input
              maxLength={4}
              className="input-cvv"
              placeholder="Digite o código de segurança"
            />
          </Form.Item>

          <Form.Item
            label="Validade do cartão *"
            name="validity"
            initialValue={register.data.validity}
            rules={[
              {
                required: true,
                message: <div className="normal-12">Campo obrigatório</div>,
              },
              {
                min: 6,
                message: (
                  <div className="normal-12">Informe uma data válida</div>
                ),
              },
              () => ({
                validator(_, value) {
                  if (
                    !value ||
                    value.replace(/[^0-9]+/g, "").length < 6 ||
                    !window.Iugu.utils.validateExpirationString(value)
                  ) {
                    return Promise.reject(
                      new Error("Data inválida ou expirada")
                    )
                  }

                  return Promise.resolve()
                },
              }),
            ]}
          >
            <SimpleMaskedInput
              mask={[
                {
                  mask: "00/0000",
                  lazy: true,
                },
              ]}
              placeholder="Digite a validade"
            />
          </Form.Item>
        </div>
      </Form>
    </>
  )
}

PaymentForm.propTypes = {
  rel: PropTypes.object.isRequired,
}

export default PaymentForm
