const createIuguPaymentToken = (creditCard) =>
  new Promise((resolve, reject) => {
    window.Iugu.createPaymentToken(creditCard, async (response) => {
      if (response.errors) {
        console.error(response)
        reject(new Error("Erro ao validar cartão, tente novamente."))
      } else if (response.id) {
        resolve(response.id)
      } else {
        console.error(response)
        reject(
          new Error(
            "Erro na geração do token do cartão. Verifique os valores informados"
          )
        )
      }
    })
  })

export const createCreditCardToken = async ({
  cardNumber,
  cardName,
  validity,
  cvv,
}) =>
  new Promise((resolve, reject) => {
    try {
      const names = cardName.split(" ")
      const firstName = names[0]

      delete names[0]
      const lastName = names.join(" ")

      const [monthExpiration, yearExpiration] = validity.split("/")

      const creditCard = window.Iugu.CreditCard(
        cardNumber.replace(/\D/g, ""),
        monthExpiration,
        yearExpiration,
        firstName,
        lastName,
        cvv
      )

      createIuguPaymentToken(creditCard)
        .then((token) => resolve(token))
        .catch((err) => {
          console.error(err)
          reject(err)
        })
    } catch (err) {
      console.error(err)
      reject(err)
    }
  })
