import React, { useEffect, useState } from "react"
//
// import { useSelector } from "react-redux"
// import { selectDashSlice } from "../dashboard/DashboardSlice"
//
import { Row } from "antd"
import ProfileData from "./components/profile"
import ContactsList from "./components/contact"
import CompaniesList from "./components/company"
import BankAccountList from "./components/bankAccount"
import Payment from "./components/payment"
import TabBarMenu from "../../common/components/TabBarMenu"
//
import "./styles.scss"

const Configurations = () => {
  // const dashState = useSelector(selectDashSlice)
  // const role = dashState.mode
  const [tab, updateTab] = useState(0)

  const menuItems = [
    { name: "Perfil" },
    { name: "CNPJ" },
    { name: "Dados bancários" },
    { name: "Contatos" },
    { name: "Meu plano" },
  ] /* .filter((item) => {
    if (role === "contractor" && item.name === "Contatos") {
      return null
    }

    return item
  }) */

  const renderTab = () => {
    if (tab === 0) {
      return <ProfileData />
    } else if (tab === 1) {
      return <CompaniesList />
    } else if (tab === 2) {
      return <BankAccountList />
      // } else if (tab === 3 && role !== "contractor") {
    } else if (tab === 3) {
      return <ContactsList />
      // } else if (tab === 4 || (tab === 3 && role === "contractor")) {
    } else if (tab === 4) {
      return <Payment />
    }
  }

  useEffect(() => {
    document.body.style.overflow = "auto"
  }, [])

  return (
    <div className="config-page">
      <Row justify="center">
        <div className="config-tab-bar">
          <TabBarMenu
            onChange={(index) => {
              updateTab(index)
            }}
            menuItems={menuItems}
          />
        </div>
      </Row>
      <Row justify="center">{renderTab()}</Row>
    </div>
  )
}

export default Configurations
