export const states = [
  { key: "AC", label: "Acre" },
  { key: "AL", label: "Alagoas" },
  { key: "AP", label: "Amapá" },
  { key: "AM", label: "Amazonas" },
  { key: "BA", label: "Bahia" },
  { key: "CE", label: "Ceará" },
  { key: "DF", label: "Distrito Federal" },
  { key: "ES", label: "Espírito Santo" },
  { key: "GO", label: "Goías" },
  { key: "MA", label: "Maranhão" },
  { key: "MT", label: "Mato Grosso" },
  { key: "MS", label: "Mato Grosso do Sul" },
  { key: "MG", label: "Minas Gerais" },
  { key: "PA", label: "Pará" },
  { key: "PB", label: "Paraíba" },
  { key: "PR", label: "Paraná" },
  { key: "PE", label: "Pernambuco" },
  { key: "PI", label: "Piauí" },
  { key: "RJ", label: "Rio de Janeiro" },
  { key: "RN", label: "Rio Grande do Norte" },
  { key: "RS", label: "Rio Grande do Sul" },
  { key: "RO", label: "Rondônia" },
  { key: "RR", label: "Roraíma" },
  { key: "SC", label: "Santa Catarina" },
  { key: "SP", label: "São Paulo" },
  { key: "SE", label: "Sergipe" },
  { key: "TO", label: "Tocantins" },
]

export const cities = []

export const allDDDs = [
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "21",
  "22",
  "24",
  "27",
  "28",
  "31",
  "32",
  "33",
  "34",
  "35",
  "37",
  "38",
  "41",
  "42",
  "43",
  "44",
  "45",
  "46",
  "47",
  "48",
  "49",
  "51",
  "53",
  "54",
  "55",
  "61",
  "62",
  "63",
  "64",
  "65",
  "66",
  "67",
  "68",
  "69",
  "71",
  "73",
  "74",
  "75",
  "77",
  "79",
  "81",
  "82",
  "83",
  "84",
  "85",
  "86",
  "87",
  "88",
  "89",
  "91",
  "92",
  "93",
  "94",
  "95",
  "96",
  "97",
  "98",
  "99",
]

export const invalidPhoneNumbers = [
  "0000000000",
  "00000000000",
  "1111111111",
  "11111111111",
  "2222222222",
  "22222222222",
  "3333333333",
  "33333333333",
  "4444444444",
  "44444444444",
  "5555555555",
  "55555555555",
  "6666666666",
  "66666666666",
  "7777777777",
  "77777777777",
  "8888888888",
  "88888888888",
  "9999999999",
  "99999999999",
]
