import React from "react"

export const LongArrowRight = (props) => {
  return (
    <span role="img" {...props}>
      <svg
        width="24"
        height="13"
        viewBox="0 0 24 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>longArrowRight</title>
        <path
          d="M23.5303 7.03033C23.8232 6.73744 23.8232 6.26257 23.5303 5.96967L18.7574 1.1967C18.4645 0.903807 17.9896 0.903807 17.6967 1.1967C17.4038 1.48959 17.4038 1.96447 17.6967 2.25736L21.9393 6.5L17.6967 10.7426C17.4038 11.0355 17.4038 11.5104 17.6967 11.8033C17.9896 12.0962 18.4645 12.0962 18.7574 11.8033L23.5303 7.03033ZM-6.55671e-08 7.25L23 7.25L23 5.75L6.55671e-08 5.75L-6.55671e-08 7.25Z"
          fill="black"
        />
      </svg>
    </span>
  )
}
