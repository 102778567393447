import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Form, Row } from "antd"
import BaseModal from "../../../../../../common/components/Modal"
import StateStatus from "../../../../../../utils/stateStatus"
import getAddressByCep from "../../../../../../service/viacep"
import PropTypes from "prop-types"
import {
  createAddress,
  listAddress,
  resetActionsStatus,
  selectConfigState,
  updateAddress,
} from "../../../../configurationsSlice"
import { findAddress } from "../../../../configureService"
import FlattedInput, {
  FlattedMaskedInput,
} from "../../../../../../common/components/FlattedInput"

const AddressModal = ({ isVisible, data, onError, onSuccess, onCancel }) => {
  const dispatch = useDispatch()
  const configState = useSelector(selectConfigState)
  const [address, updateAddressData] = useState({})
  const [form] = Form.useForm()

  useEffect(() => {
    updateAddressData(data ?? {})
    form.setFieldsValue({
      cep: data.cep,
      neighborhood: data.neighborhood,
      street: data.street,
      city: data.city,
      state: data.state,
      number: data.number,
      country: data.country,
      complement: data.complement,
    })
  }, [data, isVisible])

  useEffect(() => {
    if (isVisible && !address.cep && "geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (position && position.coords) {
          searchAddressByCoordinates(
            position.coords.latitude,
            position.coords.longitude
          )
        }
      })
    }
  }, [isVisible])

  useEffect(() => {
    if (
      configState.status.createAddress === StateStatus.succeeded ||
      configState.status.updateAddress === StateStatus.succeeded
    ) {
      dispatch(listAddress())
      onSuccess()
    }

    if (
      configState.status.createAddress === StateStatus.failed ||
      configState.status.updateAddress === StateStatus.failed
    ) {
      dispatch(resetActionsStatus())
      onError()
    }
  }, [configState.status.createAddress, configState.status.updateAddress])

  const searchAddressByCep = (value) => {
    if (value.length !== 9) return

    getAddressByCep(value).then((response) => {
      updateAddressData({
        city: response.localidade,
        state: response.uf,
      })
      form.resetFields()
      form.setFieldsValue({
        cep: value,
        neighborhood: response.bairro,
        street: response.logradouro,
        city: response.localidade,
        state: response.uf,
      })
    })
  }

  const searchAddressByCoordinates = (lat, long) => {
    findAddress({ lat, long })
      .then((response) => {
        const { items } = response.data
        if (items && items[0]) {
          const { address } = items[0]

          if (address.postalCode) {
            form.setFieldsValue({ cep: address.postalCode })
            searchAddressByCep(address.postalCode)
          } else {
            updateAddressData({
              city: address.district,
              state: address.state,
            })
            form.resetFields()
            form.setFieldsValue({
              cep: address.postalCode,
              neighborhood: "",
              street: address.street,
              city: address.district,
              state: address.state,
            })
          }
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  return (
    <BaseModal
      isVisible={isVisible}
      isLoading={
        configState.status.createAddress === StateStatus.loading ||
        configState.status.updateAddress === StateStatus.loading
      }
      okButtonText="Salvar"
      cancelButtonText="Cancelar"
      onOk={() => {
        form.submit()
      }}
      onCancel={() => {
        updateAddressData({})
        onCancel()
        form.resetFields()
      }}
      title="Adicionar Endereço"
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={(values) => {
          if (address.id) {
            dispatch(updateAddress({ addressId: address.id, data: values }))
          } else {
            dispatch(createAddress(values))
          }
          updateAddressData({})
        }}
      >
        <div style={{ marginBottom: "20px" }} className="normal-14">
          Insira os dados do novo endereço
        </div>
        <Row justify="space-between">
          <Form.Item
            initialValue={address.cep ?? ""}
            name="cep"
            label="CEP"
            rules={[
              {
                required: true,
                message: <div className="normal-12">Campo obrigatório</div>,
              },
              () => ({
                validator(_, value) {
                  if (value.length === 9) {
                    return Promise.resolve()
                  } else {
                    return Promise.reject(new Error())
                  }
                },
                message: <div className="normal-12">CEP inválido</div>,
              }),
            ]}
          >
            <FlattedMaskedInput
              mask="00000-000"
              placeholder="00000-00"
              onBlur={(event) => {
                searchAddressByCep(event.target.value)
              }}
              style={{ width: "200px" }}
            />
          </Form.Item>
          <Form.Item
            initialValue={address.neighborhood ?? ""}
            name="neighborhood"
            label="Bairro"
            rules={[
              {
                required: true,
                message: <div className="normal-12">Campo obrigatório</div>,
              },
            ]}
          >
            <FlattedInput style={{ width: "200px" }}></FlattedInput>
          </Form.Item>
        </Row>
        <Row style={{ width: "100%" }}>
          <Form.Item
            initialValue={address.street ?? ""}
            style={{ width: "100%" }}
            name="street"
            label="Logradouro"
            rules={[
              {
                required: true,
                message: <div className="normal-12">Campo obrigatório</div>,
              },
            ]}
          >
            <FlattedInput style={{ width: "100%" }}></FlattedInput>
          </Form.Item>
        </Row>
        <Row justify="space-between">
          <Form.Item
            initialValue={address.number ?? ""}
            name="number"
            label="Número"
            rules={[
              {
                required: true,
                message: <div className="normal-12">Campo obrigatório</div>,
              },
            ]}
          >
            <FlattedInput style={{ width: "100px" }}></FlattedInput>
          </Form.Item>
          <Form.Item
            initialValue={address.complement ?? ""}
            name="complement"
            label="Complemento"
          >
            <FlattedInput style={{ width: "350px" }}></FlattedInput>
          </Form.Item>
        </Row>
        <Form.Item
          initialValue={address.city ?? ""}
          name="city"
          style={{ display: "none" }}
        >
          <input type="text" />
        </Form.Item>
        <Form.Item
          initialValue={address.state ?? ""}
          name="state"
          style={{ display: "none" }}
        >
          <input type="text" />
        </Form.Item>
        <Form.Item
          initialValue="brasil"
          name="country"
          style={{ display: "none" }}
        >
          <input type="text" />
        </Form.Item>
      </Form>
      {address.city && address.state && (
        <div>
          {address.city} - {address.state}
        </div>
      )}
    </BaseModal>
  )
}

AddressModal.propTypes = {
  isVisible: PropTypes.bool,
  data: PropTypes.object,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  onCancel: PropTypes.func,
}

export { AddressModal }
