import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Row, Divider, Form } from "antd"
import BaseModal from "../../../../common/components/Modal"
import StyledSwitch from "../../../../common/components/Switch"
import "./styles.scss"
import {} from "../../listNotificationsSlice"

import StateStatus from "../../../../utils/stateStatus"
import { CardNotifiSettings } from "../../../listNotifications/components/configNotifications/components/cardNotifiSettings"
import Icons from "../../../../common/components/Icons"
import {
  selectConfigState,
  updateNotificationSettings,
  listNotificationsSettings,
} from "../../../configurations/configurationsSlice"
import { useHistory } from "react-router-dom"

const { useForm } = Form

const options = [
  {
    title: "Propostas aceitas",
    subtitle: "Escolha como receber avisos sobre propostas aceitas",
    key: "approved_proposals",
  },
  {
    title: "Propostas pendentes",
    subtitle: "Escolha como receber avisos sobre propostas pendentes",
    key: "pending_proposals",
  },
  {
    title: "Propostas vencidas",
    subtitle: "Escolha como receber avisos sobre propostas vencidas",
    key: "expired_proposals",
  },
  // {
  //   title: "Total por dia",
  //   subtitle: "Aviso do valor total diário acumulado de propostas enviadas",
  //   key: "daily_volume_proposals",
  // },
  // {
  //   title: "Total por mês",
  //   subtitle: "Aviso do valor mensal acumulado de propostas enviadas",
  //   key: "month_volume_proposals",
  // },
  {
    title: "Propostas enviadas",
    subtitle: "Avisos sobre propostas enviadas pelo contratante",
    key: "opened_proposals",
  },
  {
    title: "Novas mensagens no chat",
    subtitle: "Avisos sobre propostas enviadas pelo contratante",
    key: "new_chat_messages",
  },
  {
    title: "Contratos assinados",
    subtitle: "Avisos sobre propostas enviadas pelo contratante",
    key: "signed_contract",
  },
]

const ConfigNotifications = () => {
  const configState = useSelector(selectConfigState)
  const dispatch = useDispatch()
  const [form] = useForm()
  const [showModal, updateShowModal] = useState(false)
  const [modalData, updateModalData] = useState({
    title: "Propostas vencidas",
    subtitle:
      "Escolha como você deseja receber avisos sobre propostas que passaram da data de validade",
  })
  const history = useHistory()

  useEffect(() => {
    if (configState.status.listNotificationsSettings === StateStatus.idle) {
      dispatch(listNotificationsSettings())
    }
  }, [])

  useEffect(() => {
    if (
      configState.status.updateNotificationSettings === StateStatus.succeeded
    ) {
      dispatch(listNotificationsSettings())
    }
  }, [configState.status.updateNotificationSettings])

  const isAllChecked = () => {
    return form.getFieldValue("app") && form.getFieldValue("email")
    // && form.getFieldValue("sms")
  }

  return (
    <div className="config-page">
      <Row justify="center">
        <div className="config-tab-content">
          <div
            className="back-page-btn"
            onClick={() => history.replace("/dashboard/notifications")}
          >
            <Icons icon="arrow-right" />
          </div>
          <div className="normal-12 back-page">
            Notificações / <b>Alterar preferências</b>
          </div>
          {/* <CardNotifiSettings
            key={"checked_all"}
            label={"Permitir notificações"}
            isSwitch={"true"}
          /> */}
          <div
            className="normal-16-500 config-notification"
            style={{
              // paddingTop: 25,
              paddingBottom: 15,
            }}
          >
            Prefêrencias
          </div>
          {options.map((item) => {
            return (
              <CardNotifiSettings
                key={item.key}
                label={item.title}
                value={
                  <div style={{ paddingLeft: "20px" }}>{item.subtitle}</div>
                }
                onClick={() => {
                  updateModalData({
                    title: item.title,
                    subtitle: item.subtitle,
                    key: item.key,
                  })
                  updateShowModal(true)

                  const setting = configState.data.notificationSettings.filter(
                    (values) => values.type === item.key
                  )[0]

                  form.setFieldsValue({
                    all: setting.all,
                    app: setting.app,
                    email: setting.email,
                    sms: setting.sms,
                    type: setting.type,
                  })
                }}
              />
            )
          })}

          <Form
            form={form}
            layout="vertical"
            onFinish={(values) => {
              dispatch(updateNotificationSettings(values))
              updateShowModal(false)
            }}
          >
            <BaseModal
              isVisible={showModal}
              title={modalData.title}
              cancelButtonText="Cancelar"
              okButtonText="Salvar"
              onCancel={() => {
                updateShowModal(false)
              }}
              onOk={() => {
                form.submit()
              }}
            >
              <div style={{ marginBottom: "20px" }} className="normal-14">
                {modalData.subtitle}
              </div>

              {/* <Row justify="space-between">
                <div className="normal-16-500">
                  Permitir todas as notificações
                </div>
                <Form.Item name="all" valuePropName="checked">
                  <StyledSwitch
                    onClick={(value) => {
                      if (value) {
                        form.setFieldsValue({
                          app: true,
                          email: true,
                          sms: true,
                          type: modalData.key,
                        })
                      } else {
                        form.setFieldsValue({
                          app: false,
                          email: false,
                          sms: false,
                          type: modalData.key,
                        })
                      }
                    }}
                  />
                </Form.Item>
              </Row>
              <Divider style={{ width: "12px 0" }} /> */}
              <Row justify="space-between">
                <div className="normal-16-500">Aplicativo</div>
                <Form.Item name="app" valuePropName="checked">
                  <StyledSwitch
                    onClick={(value) => {
                      if (!value) {
                        form.setFieldsValue({ all: false })
                      } else {
                        form.setFieldsValue({ all: isAllChecked() })
                      }
                    }}
                  />
                </Form.Item>
              </Row>
              <Divider style={{ width: "12px 0" }} />
              <Row justify="space-between">
                <div className="normal-16-500">E-mail</div>
                <Form.Item name="email" valuePropName="checked">
                  <StyledSwitch
                    onClick={(value) => {
                      if (!value) {
                        form.setFieldsValue({ all: false })
                      } else {
                        form.setFieldsValue({ all: isAllChecked() })
                      }
                    }}
                  />
                </Form.Item>
              </Row>
              <Divider style={{ width: "12px 0" }} />
              <Row justify="space-between">
                {/* <div className="normal-16-500">SMS</div> */}
                <Form.Item
                  name="sms"
                  valuePropName="checked"
                  style={{ display: "none" }}
                >
                  <StyledSwitch
                    onClick={(value) => {
                      if (!value) {
                        form.setFieldsValue({ all: false })
                      } else {
                        form.setFieldsValue({ all: isAllChecked() })
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item name="type" style={{ display: "none" }}>
                  <input type="text" />
                </Form.Item>
              </Row>
              {/* <Divider /> */}
            </BaseModal>
          </Form>
        </div>
      </Row>
    </div>
  )
}

export default ConfigNotifications
